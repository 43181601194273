import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  acceptedColumnsAlert: {
    width: "80%",
    "@media (max-width: 600px)": {
      width: "100%"
    }
  }
});
