import { Box, Typography } from "@material-ui/core";
import { Alert, Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useRef, useState } from "react";
import batchService from "services/batchService";

import { AddImage } from "../../../AddImage";
import { ButtonsContent } from "../../style";

export const ResultPage = ({
  batchId,
  isApproved,
  handleNext,
  handleCloseOpenSteps
}) => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);
  const addImageRef = useRef(null);

  const showAlert = () => {
    addPopup({
      type: "info",
      title: `Imagem obrigatória`,
      description: "Adicione pelo menos duas fotos."
    });
  };

  const sendImages = async images => {
    setLoading(true);
    try {
      await batchService.postBatchImage({
        batchId,
        images
      });
      addPopup({
        type: "success",
        title: "Imagens enviadas",
        description: "Imagens enviadas com sucesso."
      });
      isApproved ? handleNext() : handleCloseOpenSteps();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao enviar imagens",
        description: err?.response?.msg ?? err?.message ?? err
      });
    } finally {
      setLoading(false);
    }
  };
  const handleClick = () => {
    const images = addImageRef.current?.images;
    images.length < 2 ? showAlert() : sendImages(images);
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        flex: 1
      }}
    >
      <Alert
        isSuccess={isApproved}
        title={`Produto ${isApproved ? "aprovado" : "recusado"}`}
      />

      <AddImage ref={addImageRef} />

      <ButtonsContent>
        <Box>
          <Button
            style={{ width: "50%", marginLeft: "auto" }}
            variant="contained"
            isLoading={loading}
            onClick={handleClick}
          >
            {isApproved ? "Avançar" : "Finalizar"}
          </Button>
        </Box>
      </ButtonsContent>
    </Box>
  );
};
