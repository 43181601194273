export const checkHistoryDiff = (index, historical, historyItem) => {
  const excludeFieldDiff = [
    "id",
    "createdTime",
    "createdBy",
    "createdDate",
    "stageId"
  ];
  const diff = {};
  if (index) {
    const previousItem = historical[index - 1];
    const previousToCompare =
      previousItem.stageId === 9 ? historical[index - 2] : previousItem;
    for (const key in historyItem) {
      if (
        !excludeFieldDiff.includes(key) &&
        historyItem[key] !== previousToCompare[key]
      ) {
        diff[key] = historyItem[key];
      }
    }
  }
  return diff;
};
