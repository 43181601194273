import { Box, Typography, styled } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const HistoryContent = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "2rem",
  justifyContent: "center",
  gap: "1rem",
  [theme.breakpoints.down("sm")]: {
    display: "flex"
  }
}));

export const BatchIdContent = styled(Box)({
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: "#353535"
});

export const CreatedByContent = styled(Typography)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  justifyContent: "flex-end",
  alignItems: "center",
  "& svg": {
    height: "0.875rem"
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem"
  }
}));

export const Content = styled(Box)(({ theme }) => ({
  width: "649px",
  height: "96px",
  background: "#fff",
  borderRadius: "10px",
  boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)",
  gap: "1rem",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%"
  }
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "10px"
  }
}));

export const ContentBoxValues = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "-1.5rem",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    marginTop: 0
  }
}));

export const BoxValue = styled(Box)(({ theme }) => ({
  width: "270px",
  height: "32px",
  borderRadius: "7px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: "#858585",
  marginBlock: "2.5rem",
  textDecoration: "none"
}));
