import { Paper, Tab, Tabs } from "./style";

export const TabBatchStorage = ({ activeTab, handleChangeTab }) => (
  <Paper square>
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_, value) => handleChangeTab(value)}
      variant="fullWidth"
      style={{ marginBottom: "2rem" }}
    >
      <Tab label="A armazenar" value={0} />
      <Tab label="Armazenado" value={1} />
    </Tabs>
  </Paper>
);
