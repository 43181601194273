import { useAuth } from "hooks/useAuth";

export const PermissionGate = ({ permissions, children }) => {
  const { permissions: userPermissions } = useAuth();
  const hasPermissions = permissions?.some(permission =>
    userPermissions.includes(permission)
  );

  if (hasPermissions) {
    return children;
  }

  return null;
};
