import { styled } from "@material-ui/core";
import { useState } from "react";

import configLayout from "./ConfigLayout";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";

const { DRAWER_WIDTH, DRAWER_WIDTH_CLOSE, APPBAR_DESKTOP, APPBAR_MOBILE } =
  configLayout;

const RootStyle = styled("div")({
  height: "100%",
  width: "100%",
  backgroundColor: "#fafafa",
  overflowX: "hidden"
});

const MainStyle = styled("main")(({ theme, open }) => ({
  marginLeft: open ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE,
  width: open
    ? `calc(100vw - ${DRAWER_WIDTH}px)`
    : `calc(100vw - ${DRAWER_WIDTH_CLOSE}px)`,
  height: `calc(100vh - ${APPBAR_DESKTOP}px)`,
  marginTop: APPBAR_DESKTOP,
  padding: theme.spacing(2, 2),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeIn
  }),
  [theme.breakpoints.down("xs")]: {
    marginTop: APPBAR_MOBILE,
    height: `calc(100vh - ${APPBAR_MOBILE})`,
    width: "100%",
    padding: theme.spacing(2),
    margin: 0
  }
}));

export const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(state => !state);

  return (
    <RootStyle>
      <Navbar isOpenSidebar={open} onSidebar={toggleOpen} />
      <Sidebar open={open} onClose={toggleOpen} />
      <MainStyle open={open}>{children}</MainStyle>
    </RootStyle>
  );
};
