import {
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Typography,
  Box
} from "@material-ui/core";
import { Button } from "components";
import React, { useState, useEffect } from "react";
import { Dialog } from "shared";

import useStyles from "./styles";

export const ConfirmationPopup = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  description,
  title = "Confirmação",
  confirmText = "Sim",
  cancelText = "Não",
  localStorageKey = "hideConfirmationPopup"
}) => {
  const classes = useStyles();
  const [hidePopup, setHidePopup] = useState(false);

  useEffect(() => {
    const hide = localStorage.getItem(localStorageKey) === "true";
    setHidePopup(hide);
    if (hide) {
      if (typeof onConfirm === "function") {
        onConfirm();
      }
      onClose();
    }
  }, [localStorageKey, onConfirm, onClose]);

  const handleConfirm = () => {
    if (hidePopup) {
      localStorage.setItem(localStorageKey, "true");
    }
    if (typeof onConfirm === "function") {
      onConfirm();
    }
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = event => {
    setHidePopup(event.target.checked);
  };

  return (
    <Dialog open={isOpen} handleClose={handleClose} title={title}>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.message}>{message}</Typography>
        {description && (
          <Box marginBottom={2}>
            <Typography variant="body2" className={classes.description}>
              {description}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <FormControlLabel
          control={
            <Checkbox
              checked={hidePopup}
              onChange={handleChange}
              color="primary"
            />
          }
          label="Não mostrar novamente"
        />
        <Box flexGrow={1} />
        <Button
          className={classes.button}
          variant={"text"}
          onClick={handleClose}
        >
          {cancelText}
        </Button>
        <Button
          className={classes.button}
          onClick={handleConfirm}
          variant="contained"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
