import { Box, Button, CircularProgress } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import purchaseForecastService from "services/purchaseForecastService";
import { Dialog, InfoCard } from "shared";

import { Text } from "../styles";

export const ConfirmDeletePopup = ({ open, handleClose, data, reloadData }) => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);

  const deleteForecast = async () => {
    try {
      const forecastId = data.id;
      setLoading(true);
      await purchaseForecastService.deletePurchaseForecast(forecastId);

      addPopup({
        type: "success",
        title: "Previsão apagada",
        description: "Previsão apagada com sucesso!"
      });
      reloadData();
      handleClose();
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar lotes",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const content = {
    true: (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin="2rem 0"
      >
        <CircularProgress color="primary" />
      </Box>
    ),
    false: (
      <>
        <Text>Deseja excluir a previsão ?</Text>

        <Box display="flex" marginTop="1rem" style={{ gap: "1rem" }}>
          <Button onClick={handleClose} fullWidth>
            Cancelar
          </Button>
          <Button onClick={deleteForecast} variant="contained" fullWidth>
            Confirmar
          </Button>
        </Box>
      </>
    )
  }[loading];

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Excluir previsão"
      maxWidth="32rem"
    >
      <InfoCard
        data={{
          Produto: {
            text: data?.product_base?.name,
            useStrong: true
          },
          Seller: {
            text: data?.seller?.name || "Não informado"
          },
          Previsão: {
            text: data?.prediction
          }
        }}
      />
      {content}
    </Dialog>
  );
};
