import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

import { BatchDueInfo } from "../../styles";

export const DueProgress = ({ daysToDude }) => {
  const valueProgress = daysToDude > 0 ? (daysToDude / 5) * 100 : 100;

  return (
    <BatchDueInfo>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="static"
          value={valueProgress}
          style={{ color: valueProgress < 100 ? "#ec9c24" : "#eb001b" }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            style={{
              color: "#353535",
              fontSize: "0.875rem",
              fontFamily: "Roboto",
              fontWeight: 500,
              wordWrap: "break-word"
            }}
          >
            {daysToDude}
          </Typography>
        </Box>
      </Box>
    </BatchDueInfo>
  );
};
