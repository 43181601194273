import {
  TextField,
  MenuItem,
  Grid,
  Box,
  CircularProgress
} from "@material-ui/core";
import { useFormik } from "formik";
import { usePopup } from "hooks/usePopup";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import depotService from "services/depotService";
import { warehouseProductBaseConfigFormatter } from "utils/depotDataFormatter";

import { LastMove } from "../LastMove";
import { Dialog, Content, Span, Strong, Info, Button } from "./styles";
import { validationSchema } from "./validationSchema";

export const Component = ({ refresh }, ref) => {
  const { addPopup } = usePopup();
  const [isOpen, setIsOpen] = useState(false);
  const [batch, setBatch] = useState(null);
  const [depots, setDepots] = useState([]);
  const [availableDepots, setAvailableDepots] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);

  const onSubmit = async data => {
    setLoading(true);
    try {
      const payload = {
        batchId: batch.batchId,
        fromWarehouseTypeId: batch.warehouseType.id,
        fromDepotId: batch.depot.id,
        fromPackagingId: batch.packagingType?.id,
        fromQuantity: data.quantity,
        toWarehouseTypeId: data.warehouseTypeId,
        toDepotId: data.depotId,
        toQuantity: data.quantity,
        toPackagingId: batch.packagingType?.id
      };
      await depotService.moveBatch(payload);
      refresh();
      handleClose();
      addPopup({
        type: "success",
        title: "Lote movido com sucesso"
      });
    } catch (error) {
      addPopup({
        type: "error",
        title: "Ocorreu um erro ao movimentar o lote"
      });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit,
    validateOnMount: true
  });

  const handleOpen = batch => {
    setBatch(batch);
    setIsOpen(true);
  };

  const handleClose = () => {
    setBatch(null);
    setDepots([]);
    setAvailableDepots([]);
    setLedgers([]);
    formik.resetForm({});
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({ handleOpen }));

  const initialize = async () => {
    setLoading(true);
    try {
      const [ledgerResults, warehousesResults, depotResults] =
        await Promise.all([
          await depotService.listLedgers({
            batchId: batch.batchId,
            paginate: false
          }),

          await depotService.getWarehouseProductBaseConfig({
            productBaseId: batch.productBaseId,
            paginate: false
          }),

          await depotService.listDepots({
            paginate: false
          })
        ]);
      setLedgers(ledgerResults.data);
      setWarehouses(
        warehouseProductBaseConfigFormatter(warehousesResults.data)
      );
      setDepots(depotResults.data);
    } catch (error) {
      addPopup({
        type: "error",
        title: "Ocorreu um erro ao buscar os dados"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeWarehouseType = event => {
    formik.setFieldValue("depotId", undefined);
    const availableDepots = depots.filter(
      depot => depot.warehouseTypeId === event.target.value
    );
    setAvailableDepots(availableDepots);
    formik.setFieldValue("warehouseTypeId", event.target.value);
  };

  useEffect(() => {
    batch && initialize();
    batch &&
      formik.setFieldValue("packagingTypeName", batch.packagingType?.name);
  }, [batch]);

  if (!batch) return null;

  const children = {
    false: (
      <Content component="form" onSubmit={formik.handleSubmit}>
        <Info>
          <div>
            <Span>ID: </Span>
            <Strong>{batch.batchId}</Strong>
          </div>
          <div>
            <Span>Produto: </Span>
            <Strong>{batch.productBaseName}</Strong>
          </div>
          <LastMove ledgers={ledgers} />
          <div>
            <Span>Qnt: </Span>
            <Strong>{batch.quantity}</Strong>
          </div>
        </Info>
        <Grid container xs={12} direction="column" style={{ gap: 16 }}>
          <Strong>Para qual armazém será movimentado o lote?</Strong>
          <TextField
            select
            fullWidth
            name="warehouseTypeId"
            onChange={handleChangeWarehouseType}
            value={formik.values.warehouseTypeId}
            variant="outlined"
            label="Selecionar"
          >
            {warehouses
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map(warehouse => (
                <MenuItem key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        {availableDepots.length > 0 && (
          <Grid container xs={12} direction="column" style={{ gap: 16 }}>
            <Strong>Para qual depósito será movimentado o lote?</Strong>
            <TextField
              select
              fullWidth
              name="depotId"
              value={formik.values.depotId}
              onChange={formik.handleChange}
              variant="outlined"
              label="Selecionar"
            >
              {availableDepots.map(depot => (
                <MenuItem key={depot.id} value={depot.id}>
                  {depot.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        <Grid container direction="column" style={{ gap: 16 }}>
          <Strong>Qual a quantidade movimentada?</Strong>
          <Grid
            container
            xs={12}
            direction="row"
            style={{ gap: 16, flexWrap: "nowrap" }}
          >
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                disabled
                name="packagingTypeName"
                value={formik.values?.packagingTypeName}
                onChange={formik.handleChange}
                variant="outlined"
                label="Embalagem"
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                fullWidth
                name="quantity"
                value={formik.values.quantity}
                onChange={e => {
                  const { value } = e.target;
                  if (parseInt(value) > batch.quantity) {
                    formik.setFieldValue("quantity", batch.quantity);
                  } else {
                    formik.handleChange(e);
                  }
                }}
                variant="outlined"
                type="number"
                placeholder="Quantidade"
                onBlur={formik.handleBlur}
                error={
                  ((!formik.values.shouldGenerateDescription &&
                    formik.touched.quantity) ||
                    formik.submitCount > 0) &&
                  !!formik.errors.quantity
                }
                helperText={
                  ((!formik.values.shouldGenerateDescription &&
                    formik.touched.quantity) ||
                    formik.submitCount > 0) &&
                  formik.errors.quantity
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          direction="row"
          style={{ gap: 16, flexWrap: "nowrap" }}
        >
          <Grid item xs={12} sm={6}>
            <Button onClick={handleClose}>Cancelar</Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              variant="contained"
              disabled={!formik.isValid}
              isLoading={formik.isSubmitting}
            >
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </Content>
    ),
    true: (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    )
  }[String(loading)];
  return (
    <Dialog open={isOpen} handleClose={handleClose} title="Movimentar lote">
      {children}
    </Dialog>
  );
};

export const MoveBatchDialog = forwardRef(Component);
