import { Box } from "@material-ui/core";
import { Button } from "components";

import { CardTitle } from "../../style";
export const ConfirmePage = ({ text, onConfirm, onCancel }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <CardTitle textAlign="center">{text}</CardTitle>
      <Box style={{ display: "flex", gap: "1rem" }}>
        <Button
          fullWidth
          variant="outlined"
          onClick={onCancel}
          color="secondary"
        >
          Não
        </Button>
        <Button fullWidth variant="contained" onClick={onConfirm}>
          Sim
        </Button>
      </Box>
    </Box>
  );
};
