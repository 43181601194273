import { TitlePage, ContainerPage } from "components";
import { CustomLineProgress } from "components/LineProgress";
import { Paginate } from "components/Paginate";
import SelectFilterMenu from "components/SelectFilterMenu";
import { useTableParams } from "hooks/useTableParams";
import { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import assetService from "services/assetsService";

import BalanceAccordion from "./components/BalanceAccordion";
import {
  ReceiptContent,
  PaginateContent,
  SearchContent,
  SearchInput
} from "./style";

export default function AssetsBalance() {
  const {
    data,
    onChange,
    params,
    refresh,
    status,
    count,
    totalPages,
    handleSearch
  } = useTableParams({
    service: params => assetService.getAssetBalance({ ...params }),
    paramsDefault: {
      multipleFilter: true,
      orderDirection: "-updated",
      excludeReasonId: 9
    }
  });
  const [page, setPage] = useState(1);

  const handleChangePage = e => {
    const selectedPage = e.selected + 1;
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth"
      });
    }
    setPage(selectedPage);
    onChange({ page: selectedPage });
  };

  const handleFilter = status => {
    if (status === "negative") {
      return onChange({ balanceStatus: "negative" });
    }
    if (status === "positive") {
      return onChange({ balanceStatus: "positive" });
    }
    return onChange({ balanceStatus: null });
  };

  const SelectFilterProps = {
    value: params.balanceStatus,
    options: [
      {
        label: "Saldos Positivos",
        id: "positive",
        color: { label: "#0a892e", background: "#e5f6ea" }
      },
      {
        label: "Saldos Negativos",
        id: "negative",
        color: { label: "#890a0a", background: "#FFDADA" }
      }
    ]
  };

  const isLoading = status === "pending";

  return (
    <ContainerPage id="back-to-top-anchor">
      <TitlePage>Saldo de Ativos por Fornecedor</TitlePage>
      <ReceiptContent>
        <SearchContent>
          <SearchInput placeholder="Fornecedor" onChange={handleSearch} />
          <SelectFilterMenu
            selectFilterProps={SelectFilterProps}
            onChange={handleFilter}
          />
        </SearchContent>
        {isLoading ? (
          <CustomLineProgress />
        ) : (
          <BalanceAccordion sellerData={data} />
        )}
        <PaginateContent>
          {totalPages > 1 && !isLoading && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={page - 1}
              onPageChange={handleChangePage}
              pageRangeDisplayed={3}
              pageCount={totalPages}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
              loading={isLoading}
            />
          )}
        </PaginateContent>
      </ReceiptContent>
    </ContainerPage>
  );
}
