/* eslint-disable react/display-name */
import { Box, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { v4 } from "uuid";

import {
  StyledIconButton,
  StyledImage,
  UploadImageContainer,
  UploadImageContent
} from "./style";

export const AddImage = forwardRef((props, ref) => {
  const inputFileRef = useRef(null);
  const [images, setImages] = useState([]);

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setImages([...images, base64String]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = index => {
    const newImages = images.filter((_, i) => i !== index);
    inputFileRef.current.value = null;
    setImages(newImages);
  };

  const upLoadingIcon = {
    true: (
      <>
        <PhotoCameraIcon
          style={{
            color: "#3EA0DE",
            fontSize: images.length < 1 ? "7rem" : "3rem"
          }}
        />
        Adicionar foto
      </>
    ),
    false: (
      <AddIcon
        style={{
          color: "#3EA0DE",
          fontSize: images.length < 1 ? "7rem" : "3rem"
        }}
      />
    )
  }[images.length < 1];

  useImperativeHandle(ref, () => ({ images }));

  return (
    <UploadImageContainer images={images}>
      {images.map((image, index) => {
        const key = v4();
        return (
          <UploadImageContent images={images} key={key}>
            <StyledIconButton onClick={() => handleDeleteImage(index)}>
              <CloseIcon />
            </StyledIconButton>
            <StyledImage src={image} alt="Imagem do produto" />
          </UploadImageContent>
        );
      })}
      <label
        htmlFor={`fileInput`}
        style={{
          display: "flex",
          flex: images.length < 1 ? 1 : 0
        }}
      >
        <UploadImageContent images={images}>{upLoadingIcon}</UploadImageContent>
        <input
          ref={inputFileRef}
          id={`fileInput`}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </label>
    </UploadImageContainer>
  );
});

export default AddImage;
