import { Box } from "@material-ui/core";
import { Alert, Button } from "components";

import { ButtonsContent } from "../../style";

export const ResultPage = ({ handleCloseOpenSteps }) => {
  return (
    <>
      <Alert isSuccess={false} title="Peso recusado" />

      <ButtonsContent>
        <Box>
          <Button
            style={{ width: "50%", marginLeft: "auto" }}
            variant="contained"
            onClick={handleCloseOpenSteps}
          >
            Finalizar
          </Button>
        </Box>
      </ButtonsContent>
    </>
  );
};
