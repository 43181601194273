import { MenuItem, TextField } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import selectionGroupsService from "services/selectionGroupsService";

export const DropdownButton = ({
  groupData,
  groupId,
  refresh,
  typePopup,
  productBaseId,
  setGroupId,
  formik,
  name
}) => {
  const { addPopup } = usePopup();
  const handleChange = event => {
    setGroupId(event.target.value);
  };

  const handleChangeProductBaseConfig = event => {
    handleGroupSelect(event.target.value);
  };

  const handleGroupSelect = async groupId => {
    try {
      await selectionGroupsService.createProductBaseConfig(groupId, {
        productBaseIds: [productBaseId]
      });
      addPopup({
        type: "success",
        title: "Produtos adiconado ao grupo com sucesso"
      });
      refresh();
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Não foi possível adicionar o produto ao grupo",
        description: errorMessage
      });
    }
  };

  return (
    <TextField
      select
      fullWidth
      name="group"
      label="Grupo"
      value={formik ? formik.values.group : groupId}
      onChange={(e, value) => {
        formik && formik.setFieldValue(name, e.target.value);
        typePopup === "update"
          ? handleChange(e, value)
          : handleChangeProductBaseConfig(e, value);
      }}
      variant="outlined"
      error={
        (formik?.touched?.group || formik?.submitCount > 0) &&
        !!formik?.errors?.group
      }
      helperText={
        (formik?.touched?.group || formik?.submitCount > 0) &&
        formik?.errors?.group
      }
    >
      {groupData.map(group => (
        <MenuItem key={group.id} value={group.id}>
          {group.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
