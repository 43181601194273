import { Box, CircularProgress } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useRef, useState } from "react";
import purchaseForecastService from "services/purchaseForecastService";
import { Drawer } from "shared";
import { formatDate } from "utils";

import { Form } from "../Form";
import { refusalInstructions } from "../utils/refusalInstructions";

export const Create = ({
  isOpen,
  setIsOpen,
  handleInactiveProduct,
  handleConfirmForecast,
  refresh
}) => {
  const { addPopup } = usePopup();
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    formRef.current?.resetForm();
  };

  const onSubmit = async data => {
    try {
      setIsLoading(true);
      await purchaseForecastService.postPurchaseForecast({
        prediction: data.prediction,
        seller: data.seller.id,
        product_base: data.productBase.id,
        combined_price: data.combinedPrice,
        to_date: formatDate(data.toDate),
        blitz_percentage: data.blitzPercentage || 0.01,
        refusal_instructions: refusalInstructions[data.refusalInstructions],
        deadline_for_analysis: data.deadlineForAnalysis || null
      });
      addPopup({ type: "success", title: "Previsão criada com sucesso" });
      refresh();
      handleClose();
      handleInactiveProduct([data]);
      await handleConfirmForecast();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao criar previsão",
        description: err.response?.data?.msg ?? ""
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      title="Nova previsão"
      open={isOpen}
      onClose={handleClose}
    >
      {isLoading ? (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Form ref={formRef} onSubmit={onSubmit} />
      )}
    </Drawer>
  );
};
