import { Button, IconButton, styled } from "@material-ui/core";

export const AddPackageButton = styled(Button)({
  backgroundColor: "#F4F4F4",
  maxHeight: "2.625rem",
  justifyContent: "left",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  "& .MuiButton-label": {
    fontFamily: "Roboto",
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#757575",
    textTransform: "none"
  },
  "& .MuiSvgIcon-root": {
    width: "1rem",
    height: "1rem",
    color: "#757575"
  },
  "&:hover": {
    backgroundColor: "#F4F4F4"
  }
});

export const RemovePackageIconButton = styled(IconButton)({
  "& svg": {
    color: "#EB001B"
  },
  "&.Mui-disabled ": {
    "& svg": {
      color: "#757575"
    }
  }
});
