/* eslint-disable react/display-name */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { forwardRef } from "react";
import { TableVirtuoso } from "react-virtuoso";

const TableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: props => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />)
};

export const ReturnedTable = ({ batchData }) => {
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Criado em</TableCell>
        <TableCell>Atualizado em</TableCell>
        <TableCell>Lote</TableCell>
        <TableCell>Produto</TableCell>
        <TableCell>Quantidade</TableCell>
        <TableCell>Motivo</TableCell>
        <TableCell>Status</TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <TableVirtuoso
      style={{ height: 400 }}
      data={batchData}
      components={{
        ...TableComponents,
        TableHead: TableHeader
      }}
      fixedHeaderContent={() => {}}
      itemContent={(_, batch) => (
        <>
          <TableCell>{batch.createdDate}</TableCell>
          <TableCell>{batch.updatedDate}</TableCell>
          <TableCell>{batch.batchId}</TableCell>
          <TableCell>{batch.productName}</TableCell>
          <TableCell>{batch.quantity}</TableCell>
          <TableCell>{batch.reason}</TableCell>
          <TableCell>{batch.status}</TableCell>
        </>
      )}
    />
  );
};
