import { useEffect, useRef } from "react";

export const Observer = ({ callback }) => {
  const observerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(([div]) => {
      if (div.isIntersecting) {
        callback();
      }
    });

    observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={observerRef} style={{ height: "40px", width: "100%" }}></div>
  );
};
