import {
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { SellerOrderStatus } from "services/sellerOrderService";
import { AutoCompleteProductBase } from "shared";

import { useStyles } from "../styles";

export const ProductLine = ({ product, index, onChange, onRemove, formik }) => {
  const classes = useStyles();

  const isFinalizedOrder =
    formik?.values?.status?.id &&
    formik.values.status.id !== SellerOrderStatus.PENDING;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" className={classes.productTitle}>
              Produto {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={2} container className={classes.deleteButton}>
            <IconButton disabled={isFinalizedOrder} onClick={onRemove}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <AutoCompleteProductBase
                name={`products[${index}].productBase`}
                value={product.productBase || ""}
                defaultValue={product.productBase || ""}
                onChange={(_, value) => onChange(index, "productBase", value)}
                disabled={isFinalizedOrder}
                label="Selecione o Produto *"
                error={
                  formik.touched.products?.[index]?.productBase &&
                  !!formik.errors.products?.[index]?.productBase
                }
                helperText={
                  formik.touched.products?.[index]?.productBase &&
                  formik.errors.products?.[index]?.productBase
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name={`products[${index}].quantity`}
                label="Quantidade *"
                type="number"
                value={product.quantity || ""}
                disabled={isFinalizedOrder}
                onChange={event =>
                  onChange(index, "quantity", event.target.value)
                }
                error={
                  formik.touched.products?.[index]?.quantity &&
                  !!formik.errors.products?.[index]?.quantity
                }
                helperText={
                  formik.touched.products?.[index]?.quantity &&
                  formik.errors.products?.[index]?.quantity
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
