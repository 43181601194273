import {
  Typography,
  Button,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { usePopup } from "hooks/usePopup";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import naboProductConfigService from "services/naboProductConfigService";

import { useStyles } from "./styles";

export const DialogUpload = () => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [fileName, setFileName] = useState("");

  const onDropAccepted = useCallback(acceptedFiles => {
    setFileName(acceptedFiles[0]?.name);
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    setFile(formData);
  }, []);

  const handleUpdatePrePickings = async () => {
    try {
      const { api, url } = await naboProductConfigService.updatePickers();

      await api.post(url, file, {
        onUploadProgress: e => {
          const newProgress = parseInt(Math.round((e.loaded * 100) / e.total));
          setProgress(newProgress);
        }
      });

      await new Promise(resolve => setTimeout(resolve, 2000));

      setProgress(null);

      addPopup({
        type: "success",
        title: "Pré-pickings atualizados com sucesso!"
      });
    } catch (error) {
      setProgress(null);
      const errorMessage =
        error?.response?.data?.msg || error?.response?.data?.message;

      addPopup({
        type: "error",
        title: "O upload falhou",
        description: errorMessage || ""
      });
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject
  } = useDropzone({
    onDropAccepted,
    accept: ".xlsx, .xls, .xml, .csv"
  });

  return (
    <>
      <div
        {...getRootProps({
          className: `${classes.dropzone}
          ${isDragActive && classes.dragActive}
          ${isDragAccept && classes.dragAccept}
          ${isDragReject && classes.dragReject}`
        })}
      >
        <input {...getInputProps()} disabled={progress !== null} />
        {progress === 100 ? (
          <Typography style={{ display: "flex", gap: 10 }}>
            <CheckCircleOutlineIcon />
            Arquivo enviado com sucesso
          </Typography>
        ) : isDragReject ? (
          <Typography>O tipo desse arquivo não é aceito</Typography>
        ) : (
          <Typography>
            {fileName === ""
              ? "Arraste e solte o arquivo aqui ou clique para selecionar o arquivo"
              : fileName}
          </Typography>
        )}
      </div>

      {progress === null || progress === 100 ? (
        <Button
          variant="contained"
          className={classes.btnUpload}
          onClick={handleUpdatePrePickings}
          disabled={progress !== null || !file}
        >
          {progress === 100 ? (
            <CircularProgress size={24} style={{ color: "#7080df" }} />
          ) : (
            "Enviar"
          )}
        </Button>
      ) : (
        <div className={classes.progress}>
          <LinearProgress variant="buffer" value={progress} valueBuffer={100} />
        </div>
      )}
    </>
  );
};
