import { CircularProgress } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import skuService from "services/skuService";

import { LoadingBlitzContent } from "../../style";
import { ActionView } from "./ActionView";
import { ConfirmView } from "./ConfirmView";
import { FormView } from "./FormView";

export const Tag = ({ handleCloseOpenSteps, updatedBatch }) => {
  const { addPopup } = usePopup();
  const [view, setView] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tagQty, setTagQty] = useState(null);
  const [action, setAction] = useState("batch");
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);

  const handleClose = () => {
    setTagQty(null);
    setAction("batch");
    setProducts([]);
    setProduct(null);
    handleCloseOpenSteps();
  };

  const getProductInfo = async () => {
    setLoading(true);
    try {
      const { data } = await skuService.getSku({
        productBaseId: updatedBatch.product.id,
        status: "active"
      });
      setProducts(data?.results);
    } catch (error) {
      const errorData = error?.response?.data;
      addPopup({
        type: "error",
        title: "Erro ao pegar SKUs",
        description: errorData?.message || errorData?.msg
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getProductInfo();
  }, []);

  const page = {
    0: (
      <ActionView
        action={action}
        setAction={setAction}
        products={products}
        onNext={() => setView(state => ++state)}
      />
    ),
    1: (
      <FormView
        action={action}
        setAction={setAction}
        tagQty={tagQty}
        setTagQty={setTagQty}
        product={product}
        setProduct={setProduct}
        products={products}
        onBack={() => setView(state => --state)}
        batch={updatedBatch}
        handleClose={handleClose}
      />
    )
  }[view];

  if (loading) {
    return (
      <LoadingBlitzContent>
        <CircularProgress color="primary" />
      </LoadingBlitzContent>
    );
  }

  return page;
};
