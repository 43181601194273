import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel
} from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import printService from "services/printService";
import { generateBatchExternalCode } from "utils/generateBatchExternalCode";

import { ButtonsContent, StyledBox, Text } from "../../../style";

export const FormView = ({
  action,
  setAction,
  tagQty,
  setTagQty,
  product,
  setProduct,
  products,
  onBack,
  batch,
  handleClose
}) => {
  const { addPopup } = usePopup();
  const isPrePicking = action === "prePicking";
  const [isLoading, setIsLoading] = useState(false);
  const [printer, setPrinter] = useState("impressora1.E3");

  const handleChangeTagQty = e => {
    const { value: inputValue } = e.target;
    const value = parseInt(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setTagQty(isValid ? value : null);
  };

  const handleChangeProduct = ({ target: { value } }) => {
    setProduct(products.find(({ sku }) => sku === value));
  };

  const getBatchPrinter = () => {
    return printer === "impressora1.E4" ? 4 : 2;
  };

  const isValid = !!tagQty && (isPrePicking ? !!product : true);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const request = {
        prePicking: () =>
          printService.printTagsV2({
            sku: product.sku,
            skuName: product.name,
            portionType: product.portionType,
            purchaseValue: product.purchaseValue,
            productBaseId: batch.product?.id,
            productBaseName: batch.product?.name,
            tagQuantity: tagQty,
            batchId: batch.id,
            batchExternalCode: generateBatchExternalCode(batch),
            uniqueTag: true,
            printer: printer
          }),
        batch: () => {
          const subBatchId = batch.sub_batch[0].id;
          return printService.printBatchTag(subBatchId, {
            qty: Number(tagQty),
            isReceipt: true,
            printer: getBatchPrinter()
          });
        }
      }[action];

      await request();

      addPopup({
        type: "success",
        title: "Tudo certo!",
        description: "Etiquetas enviadas para fila de impressão"
      });
      handleClose();
    } catch (error) {
      const errorData = error?.response?.data;
      addPopup({
        type: "error",
        title: "Erro ao imprimir",
        description: errorData?.message || errorData?.msg
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectPrinter = e => {
    setPrinter(e.target.value);
  };

  return (
    <>
      <StyledBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Text style={{ marginBottom: "0.5rem" }}>
              Quantas etiquetas gostaria de imprimir?
            </Text>
            <TextField
              variant="outlined"
              type="number"
              onChange={handleChangeTagQty}
              value={tagQty}
              label={`Quantidade`}
              fullWidth
            />
          </Grid>
          {isPrePicking && (
            <>
              <Grid item xs={12}>
                <Text style={{ marginBottom: "0.5rem" }}>
                  Qual é o porcionamento(SKU)?
                </Text>

                <Select
                  variant="outlined"
                  fullWidth
                  value={product?.sku}
                  onChange={handleChangeProduct}
                >
                  {products.map(item => (
                    <MenuItem key={item.id} value={item.sku}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>Selecione a impressora</FormLabel>
              <RadioGroup
                row
                style={{ display: "flex" }}
                value={printer}
                defaultValue="impressora1.E3"
                onChange={handleSelectPrinter}
              >
                <FormControlLabel
                  value="impressora1.E3"
                  control={<Radio style={{ color: "#8bc34a" }} />}
                  label="Recebimento"
                />
                <FormControlLabel
                  value="impressora1.E4"
                  control={<Radio style={{ color: "#8bc34a" }} />}
                  label="Folhosas"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </StyledBox>
      <ButtonsContent
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        style={{ flexDirection: "row" }}
      >
        <Button
          onClick={onBack}
          disabled={isLoading}
          fullWidth
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          disabled={!isValid}
          isLoading={isLoading}
          fullWidth
        >
          Avançar
        </Button>
      </ButtonsContent>
    </>
  );
};
