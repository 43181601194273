import { Box, makeStyles, styled, Typography } from "@material-ui/core";

export const Text = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "1.1rem",
  color: "#757575",
  margin: "1.5rem 0 2rem"
});

export const useStyles = makeStyles(theme => ({
  grid: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    }
  }
}));
