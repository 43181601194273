import { Box, styled } from "@material-ui/core";
import { Dialog as DialogShared } from "shared";

export const Info = styled(Box)({
  background: "#E8F1F8",
  padding: "1rem",
  borderRadius: "0.5rem",
  "& > p": { color: "#353535", fontWeight: 500 }
});

export const Dialog = styled(DialogShared)(({ theme }) => ({
  maxWidth: "32rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": {
    padding: 0,
    height: "90%",
    minHeight: "540px",
    maxHeight: "auto",
    width: "100%",
    margin: "0 1rem"
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      height: "100%",
      maxHeight: "95vh"
    }
  }
}));
