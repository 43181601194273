const categories = {
  Higienizados: 1,
  In_natura: 2,
  Processados: 3,
  Outros: 4
};

const subCategories = {
  Raizes: 1,
  Processados: 2,
  Mercearia: 3,
  Méis: 4,
  Legumes: 5,
  Higienizados: 6,
  Granjeiros: 7,
  Frutas: 8,
  "Frutas secas e geleias": 9,
  Folhosas: 10,
  Ervas: 11,
  Cogumelos: 12,
  Café: 13,
  Bebidas: 14,
  Outros: 15
};

const categoriesSelect = [
  { name: "Higienizados", value: 1 },
  { name: "In_natura", value: 2 },
  { name: "Processados", value: 3 },
  { name: "Outros", value: 4 }
];

const subCategoriesSelect = [
  { name: "Raizes", value: 1 },
  { name: "Processados", value: 2 },
  { name: "Mercearia", value: 3 },
  { name: "Méis", value: 4 },
  { name: "Legumes", value: 5 },
  { name: "Higienizados", value: 6 },
  { name: "Granjeiros", value: 7 },
  { name: "Frutas", value: 8 },
  { name: "Frutas secas e geleias", value: 9 },
  { name: "Folhosas", value: 10 },
  { name: "Ervas", value: 11 },
  { name: "Cogumelos", value: 12 },
  { name: "Café", value: 13 },
  { name: "Bebidas", value: 14 },
  { name: "Outros", value: 15 },
  { name: "Cerealista", value: 16 }
];

export { categories, subCategories, categoriesSelect, subCategoriesSelect };
