import {
  alpha,
  Drawer,
  styled,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { Logo } from "components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Scrollbar } from "shared";

import configLayout from "./ConfigLayout";
import navConfig from "./NavConfig";
import { NavSection } from "./NavSection";

const { DRAWER_WIDTH, DRAWER_WIDTH_CLOSE, APPBAR_DESKTOP } = configLayout;

const DrawerStyles = styled(Drawer)(({ theme, open }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#f4f4f4",
    width: open ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up("sm")]: {
      backdropFilter: "blur(6px)",
      WebkitBackdropFilter: "blur(6px)",
      backgroundColor: alpha("#ffffff", 0.72),
      height: `calc(100vh - ${APPBAR_DESKTOP}px)`,
      marginTop: APPBAR_DESKTOP
    },
    "&:hover": {
      width: DRAWER_WIDTH
    },
    "& .collapse": {
      display: open ? "block" : "none"
    },
    "& .navItem": {
      opacity: open ? 1 : 0
    },
    "&:hover .navItem": {
      opacity: 1
    },
    "&:hover .collapse": {
      display: "block"
    }
  }
}));

const ScroolbarStyle = styled(Scrollbar)({
  overflowX: "hidden",
  height: "100%"
});

export const Sidebar = ({ open, onClose }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (open && !isDesktop) {
      onClose();
    }
  }, [pathname]);

  if (isDesktop) {
    return (
      <DrawerStyles variant="permanent" open={open} onClose={onClose}>
        <ScroolbarStyle>
          <NavSection navConfig={navConfig} />
        </ScroolbarStyle>
      </DrawerStyles>
    );
  }

  return (
    <DrawerStyles variant="temporary" keepMounted open={open} onClose={onClose}>
      <ScroolbarStyle>
        <Logo style={{ margin: "1rem auto" }} />
        <NavSection navConfig={navConfig} />
      </ScroolbarStyle>
    </DrawerStyles>
  );
};
