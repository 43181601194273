import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import permissionsService from "services/permissionsService";
import userService from "services/userService";
import Input from "shared/Input";
import Modal from "shared/Modal";
import { permissionsAplication } from "utils/permissionAplication";
import * as Yup from "yup";

import { useStyles } from "./styles";

const validateSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Primeiro nome é obrigatório")
    .matches(/^[a-zA-Z]+$/, "Primeiro nome deve conter apenas letras"),
  lastName: Yup.string()
    .required("Segundo nome é obrigatória")
    .matches(/^[a-zA-Z]+$/, "Segundo nome deve conter apenas letras"),
  password: Yup.string().required("Senha é obrigatória")
});

export const ModalCreateUser = ({ open, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listPermission, setListPermission] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const { addPopup } = usePopup();
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validateSchema)
  });

  const addPermission = permission => {
    userPermissions.some(item => item === permission)
      ? setUserPermissions(state => state.filter(item => item !== permission))
      : setUserPermissions(state => [...state, permission]);
  };

  const handleGetPermissions = async () => {
    try {
      const response = await permissionsService.getGroupPermissions();

      const groupPermissions = response?.data;

      const removedPermissions = ["Tech", "Admin"];

      setListPermission(
        groupPermissions
          .filter(
            permission =>
              !removedPermissions.includes(permission.name) &&
              Object.entries(permissionsAplication).some(
                ([value]) => value === permission.name
              )
          )
          .map(permission => ({
            ...permission,
            value: permissionsAplication[permission.name]
          }))
      );
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao receber permissões"
      });
    }
  };

  useEffect(() => {
    handleGetPermissions();
  }, []);

  const handleCreateUser = async data => {
    if (userPermissions.length === 0) {
      addPopup({
        type: "error",
        title: "Selecione ao menos uma permissão"
      });
      return;
    }

    try {
      setIsLoading(true);

      await userService.createUser({
        ...data,
        groups: userPermissions
      });

      addPopup({
        type: "success",
        title: "Operador criado com sucesso"
      });

      setIsLoading(false);

      handleClose();
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao criar usuário"
      });

      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Criar Usuário"
      open={open}
      handleClose={handleClose}
      style={{
        maxHeight: "40rem",
        maxWidth: "60rem",
        textAlign: "center",
        width: "auto"
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleCreateUser)}
        className={classes.boxAlign}
      >
        <Box className={classes.boxForm}>
          <Typography className={classes.boxFormTitle}>
            Informe os dados
          </Typography>

          <Box className={classes.form}>
            <Input
              variant="outlined"
              placeholder="Digite o seu primeiro nome"
              label="Primeiro nome"
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
              {...register("firstName", { required: true })}
              style={{ marginTop: "2.5rem" }}
            />

            <Input
              variant="outlined"
              placeholder="Digite o seu segundo nome"
              label="Ultimo nome"
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message}
              {...register("lastName", { required: true })}
              style={{ marginTop: "2rem" }}
            />

            <Input
              variant="outlined"
              type="password"
              placeholder="Digite a sua senha"
              label="Senha"
              error={!!errors?.password}
              helperText={errors?.password?.message}
              {...register("password", { required: true })}
              style={{ marginTop: "2rem" }}
            />

            <Input
              variant="outlined"
              placeholder="Digite o seu email"
              type="email"
              label="Email"
              disabled
              style={{ marginTop: "2rem" }}
            />
          </Box>
        </Box>

        <Box className={classes.boxForm}>
          <Typography className={classes.boxFormTitle}>Permissões</Typography>

          <Box className={classes.form}>
            <FormGroup className={classes.formGroup}>
              {listPermission.map(permission => (
                <FormControlLabel
                  key={permission?.id}
                  checked={userPermissions.some(
                    item => item === permission?.id
                  )}
                  control={
                    <Checkbox
                      value={permission?.id}
                      onChange={e => addPermission(Number(e.target.value))}
                    />
                  }
                  label={
                    <Typography className={classes.title}>
                      {permission?.value}
                    </Typography>
                  }
                  className={classes.formControlLabel}
                />
              ))}
            </FormGroup>

            <Button
              type="submit"
              variant="contained"
              isLoading={isLoading}
              style={{ position: "absolute", bottom: "4rem" }}
            >
              Criar usuário
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
