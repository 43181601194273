import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme =>
  createStyles({
    boxAlign: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.between(0, 540)]: {
        flexDirection: "column"
      }
    },
    filter: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: "1rem",
      color: "#757575",
      fontSize: "1rem",
      cursor: "pointer"
    },
    boxPermissions: {
      padding: "1rem"
    },
    search: {
      width: "21rem",
      marginLeft: "2rem",
      [theme.breakpoints.between(0, 540)]: {
        marginTop: "1rem",
        marginLeft: "auto",
        marginBottom: "10px"
      }
    },
    noOperator: {
      position: "absolute",
      top: "45%",
      left: "50%",
      transform: "translateX(-50%)",
      color: "#757575",
      fontSize: "1.2rem",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "1rem"
    },
    addFloatButton: {
      bottom: "2.5rem",
      right: "2.5rem",
      position: "fixed"
    },
    addButton: {
      background: "#89BD23",
      "&:hover": {
        backgroundColor: "#90a955"
      }
    },
    iconButton: {
      color: "#ffffff"
    }
  })
);
