import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Button,
  IconButton,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { usePopup } from "hooks/usePopup";
import { forwardRef, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import summaryService from "services/summaryService";

import { useStyles } from "./styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogUpload = ({ isOpen, handleClose, refresh }) => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback(acceptedFiles => {
    setFileName(acceptedFiles[0]?.name);
    const formData = new FormData();
    formData.append("estoque", acceptedFiles[0]);
    setFile(formData);
  }, []);

  const handleCloseDialog = () => {
    handleClose(false);
    setProgress(null);
    setFile(null);
    setFileName("");
  };

  const handleUpdateStock = async () => {
    try {
      const { api, url } = summaryService.uploadFile();

      await api.put(url, file, {
        onUploadProgress: e => {
          const newProgress = parseInt(Math.round((e.loaded * 100) / e.total));
          setProgress(newProgress);
        }
      });

      addPopup({
        type: "success",
        title: "Estoque atualizado com sucesso!"
      });
      refresh();
      handleCloseDialog();
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "A atualização falhou",
          description: "Tente novamente ou atualize o estoque individualmente"
        });
      }
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragActive } =
    useDropzone({
      onDrop
    });

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleCloseDialog}
      keepMounted
      className={classes.dialog}
    >
      <DialogContent>
        <IconButton onClick={handleCloseDialog} className={classes.closeBtn}>
          <CloseIcon />
        </IconButton>

        <div
          {...getRootProps({
            className: `${classes.dropzone}
          ${isDragActive && classes.dragActive}
          ${isDragAccept && classes.dragAccept}`
          })}
        >
          <input {...getInputProps()} disabled={progress !== null} />
          {progress === 100 ? (
            <Typography style={{ display: "flex", gap: 10 }}>
              <CheckCircleOutlineIcon />
              Arquivo enviado com sucesso
            </Typography>
          ) : (
            <Typography>
              {fileName === ""
                ? "Arraste e solte o arquivo aqui ou clique para selecionar o arquivo"
                : fileName}
            </Typography>
          )}
        </div>

        {progress === null || progress === 100 ? (
          <Button
            variant="contained"
            className={classes.btnUpload}
            onClick={handleUpdateStock}
            disabled={progress !== null}
          >
            {progress === 100 ? (
              <CircularProgress size={24} style={{ color: "#7080df" }} />
            ) : (
              "Enviar"
            )}
          </Button>
        ) : (
          <div className={classes.progress}>
            <LinearProgress
              variant="buffer"
              value={progress}
              valueBuffer={100}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
