export const mapStates = value => {
  const state =
    {
      Ativo: 1,
      Suspenso: 2,
      "Desativado:": 3
    }[value] || null;
  return state;
};

export const mapStatesString = value => {
  const state =
    {
      active: "Ativo",
      suspended: "Suspenso",
      deactivated: "Desativado"
    }[value] || null;
  return state;
};
