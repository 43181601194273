import { Grid, Box } from "@material-ui/core";
import { Add, ArrowBack } from "@material-ui/icons";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import React, { forwardRef, useImperativeHandle } from "react";
import { SellerOrderStatus } from "services/sellerOrderService";

import { PackagingLine } from "./PackagingLine";
import { useStyles } from "./styles";

const initialPackaging = () => ({
  packaging: null,
  packagingId: null,
  quantity: 0,
  packagingName: "",
  uniqueKey: Math.random().toString(36)
});

const FormStepTwo = ({ formik, setStep, removedPackaging }, ref) => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  useImperativeHandle(ref, () => ({ resetForm: formik.resetForm }));

  const handleAddPackaging = () => {
    const newPackaging = initialPackaging();
    formik.setFieldValue("sellerOrderPackaging", [
      ...formik.values.sellerOrderPackaging,
      newPackaging
    ]);
  };

  const handleRemovePackaging = index => {
    const updatedPackaging = [...formik.values.sellerOrderPackaging];
    if (updatedPackaging.length === 1) {
      addPopup({
        type: "error",
        title: "Erro",
        description: "É necessário ter pelo menos uma embalagem."
      });
      return;
    }

    if (updatedPackaging[index].id) {
      removedPackaging.current = [
        ...removedPackaging.current,
        updatedPackaging[index].id
      ];
    }

    updatedPackaging.splice(index, 1);
    formik.setFieldValue("sellerOrderPackaging", updatedPackaging);
  };

  const handlePackagingChange = (index, field, value) => {
    const updatedPackaging = formik.values.sellerOrderPackaging.map(
      (packaging, i) =>
        i === index ? { ...packaging, [field]: value } : packaging
    );
    formik.setFieldValue("sellerOrderPackaging", updatedPackaging);
  };

  const areAllFieldsFilled = () =>
    formik.values.sellerOrderPackaging.every(
      packaging => packaging.packaging && packaging.quantity > 0
    );

  const isFinalizedOrder =
    formik?.values?.status?.id &&
    formik.values.status.id !== SellerOrderStatus.PENDING;

  return (
    <Box component="form" noValidate>
      <Grid container spacing={2}>
        {formik.values.sellerOrderPackaging.map((packaging, index) => (
          <PackagingLine
            key={packaging.uniqueKey}
            packaging={packaging}
            index={index}
            onChange={handlePackagingChange}
            onRemove={() => handleRemovePackaging(index)}
            formik={formik}
          />
        ))}
        {!isFinalizedOrder && (
          <Grid item xs={12}>
            <Button
              className={classes.button}
              variant="outlined"
              startIcon={<Add />}
              onClick={handleAddPackaging}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        <Grid item xs={12} className={classes.buttonsContainer}>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              isLoading={formik.isSubmitting}
              variant="text"
              type="button"
              className={classes.button}
              startIcon={<ArrowBack />}
              onClick={() => setStep(1)}
            >
              Voltar
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              isLoading={formik.isSubmitting}
              variant="contained"
              className={classes.button}
              disabled={
                (!formik.isValid || !areAllFieldsFilled() || !formik.dirty) &&
                !isFinalizedOrder
              }
              onClick={() => setStep(3)}
            >
              Avançar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export const PackagingForm = forwardRef(FormStepTwo);
