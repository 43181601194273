import { Box, Paper, Typography, styled } from "@material-ui/core";

export const CardsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  flex: 1,
  marginBottom: "1rem",
  gap: "1rem",
  paddingInline: "0 1rem"
});

export const StyledCardContent = styled(Paper)({
  display: "flex",
  minWidth: "15rem",
  minHeight: "5rem",
  padding: "1rem",
  alignItems: "center",
  gap: "1rem",
  flex: 1
});

export const IconContent = styled(Box)({
  display: "flex",
  borderRadius: "50%",
  width: "2.5rem",
  height: "2.5rem",
  alignItems: "center",
  justifyContent: "center"
});

export const InfoContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem"
});

export const CardTitle = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "0.75rem",
  fontWeight: 500,
  wordWrap: "break-word",
  color: "#757575"
});

export const CardValue = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontWeight: 500,
  wordWrap: "break-word",
  color: "#757575"
});
