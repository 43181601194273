import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import { Button } from "components";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";

import { storageReasonOptions } from "./storageReasonOptions";
import { ButtonsContent } from "./styles";
import { validationSchema } from "./validationsSchema";

export const InitialPage = ({
  handleNext,
  batchData,
  batchReasons,
  handleClose
}) => {
  const validation = validationSchema(batchData);
  const { combinedDevolutionId } = batchData;
  const [time, setTime] = useState(0);
  const [showReasonOption, setShowReasonOption] = useState(false);
  const initialValues = {
    quantity: "",
    storageReasonId: "",
    batchReasonId: ""
  };

  const handleSubmit = data => {
    handleNext({
      ...data,
      batchReasonId: data.storageReasonId !== 28 ? data.batchReasonId : null
    });
  };

  const handleSelectReason = e => {
    const option = e.target.value;
    const hasNoReturn = option === 17 && Number(combinedDevolutionId) === 1;
    hasNoReturn ? setTime(60) : setTime(0);
    setShowReasonOption(option !== 28);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      time ? setTime(prev => --prev) : clearInterval(intervalId);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
        >
          {!!time && (
            <Typography color="error">
              ATENÇÃO!! PRODUTO SEM DEVOLUÇÃO!!!
            </Typography>
          )}
          <Field
            as={TextField}
            fullWidth
            name="quantity"
            type="number"
            variant="outlined"
            label="Quantidade"
          />
          <ErrorMessage
            name="quantity"
            component="div"
            style={{ color: "red" }}
          />

          <Field
            as={TextField}
            select
            fullWidth
            name="storageReasonId"
            label="Tipo de movimentação"
            variant="outlined"
            onChange={e => {
              handleChange(e);
              handleSelectReason(e);
            }}
          >
            {storageReasonOptions.map(option => (
              <MenuItem
                key={option.id}
                value={option.id}
                style={{ textTransform: "none" }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Field>
          <ErrorMessage
            name="storageReasonId"
            component="div"
            style={{ color: "red" }}
          />

          {showReasonOption && (
            <>
              <Field
                as={TextField}
                select
                fullWidth
                name="batchReasonId"
                label="Motivo"
                variant="outlined"
              >
                {batchReasons.map(option => (
                  <MenuItem
                    key={option.id}
                    value={showReasonOption ? option.id : null}
                    style={{ textTransform: "none" }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Field>
              <ErrorMessage
                name="batchReasonId"
                component="div"
                style={{ color: "red" }}
              />
            </>
          )}

          <ButtonsContent>
            <Button variant="outlined" fullWidth onClick={handleClose}>
              Cancelar
            </Button>
            <Button type="submit" variant="contained" disabled={time} fullWidth>
              {time ? `Aguarde ${time} segundos` : "Avançar"}
            </Button>
          </ButtonsContent>
        </Form>
      )}
    </Formik>
  );
};
