import { Typography } from "@material-ui/core";
import ptBR from "date-fns/locale/pt-BR";
import { registerLocale } from "react-datepicker";

import { DateFilterContent, StyledDatePicker } from "./style";
registerLocale("ptBR", ptBR);

export const AlignmentDateFilter = ({ handleFilterDate, dateFrom, dateTo }) => {
  return (
    <DateFilterContent>
      <Typography>Alinhamento:</Typography>
      <StyledDatePicker
        dateFormat="dd/MM/yyyy"
        locale="ptBR"
        onChange={handleFilterDate}
        startDate={dateFrom}
        endDate={dateTo}
        selectsRange
      />
    </DateFilterContent>
  );
};
