import { Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";

import { ChipButtonFilter, ChipMenuItem, FilterContent } from "./styles";

const SelectFilterMenu = ({ onChange, selectFilterProps }) => {
  const { value, text, options } = selectFilterProps;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = statusId => {
    onChange(statusId);
    handleClose();
  };

  const selectedOption = options.find(option => option.id === value);

  return (
    <FilterContent>
      <span>{text}: </span>
      <ChipButtonFilter
        aria-controls="filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
        label={selectedOption?.label || "Todos"}
        onDelete={handleClick}
        deleteIcon={<ExpandMoreIcon />}
        colors={selectedOption?.color || {}}
      />

      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelect("")}>
          <ChipMenuItem color={{}}>Todos</ChipMenuItem>
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option.id} onClick={() => handleSelect(option.id)}>
            <ChipMenuItem color={option.color || {}}>
              {option.label}
            </ChipMenuItem>
          </MenuItem>
        ))}
      </Menu>
    </FilterContent>
  );
};

export default SelectFilterMenu;
