import { TitlePage, ContainerPage } from "components";
import { Paginate } from "components/Paginate";
import { useAuth } from "hooks/useAuth";
import { usePopup } from "hooks/usePopup";
import { useEffect, useRef, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import batchService from "services/batchService";
import { useDebaunce } from "utils";
import { unauthorizedUsers } from "utils/checkUnauthorizedUsers";

import { EditTaxEntryPopup } from "./components/EditTaxEntryPopup";
import { NewBatchPopup } from "./components/NewBatchPopup";
import { PrintPopup } from "./components/PrintPopup";
import { StepsPopup } from "./components/StepsPopup";
import { TextInfoAlert } from "./components/StepsPopup/style";
import { FinishedPage } from "./components/TabPages/FinishedPage";
import { PendingPage } from "./components/TabPages/PedingPage";
import { TabReceipt } from "./components/TabReceipt";
import { UndeliveredBatchPopup } from "./components/UndeliveredBatchPopup";
import {
  ReceiptContent,
  PaginateContent,
  StyledDialog,
  PopupContent
} from "./style";
import { formatBatchesSeller } from "./utils/formatData";

export default function Receivement() {
  const { user } = useAuth();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const batchId =
    urlParams.get("batchId") ||
    urlParams.get("blitzBatchId") ||
    urlParams.get("unloadingBatchId");

  const undeliveredBatchModalRef = useRef(null);
  const [search, setSearch] = useState(batchId);
  const toDayDate = new Date();
  const searchDateFrom = new Date(toDayDate.setHours(0, 0, 0, 1));
  const searchDateTo = new Date(toDayDate.setHours(23, 59, 59, 0));
  const [pendingData, setPendingData] = useState([]);
  const [finishedData, setFinishedData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterStatus, setFilterStatus] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const [openStepsPopup, setOpenStepsPopup] = useState(false);
  const [batchDetail, setBatchDetail] = useState({});
  const [openPrintPopup, setOpenPrintPopup] = useState(false);
  const [openNewBatchPopup, setOpenNewBatchPopup] = useState(false);
  const isUnauthorizedUser = unauthorizedUsers.batch(user);
  const [openEditTaxEntryPopup, setOpenEditTaxEntryPopup] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [openAlertBatchNotFound, setOpenAlertBatchNotFound] = useState(false);

  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });

  const { addPopup } = usePopup();

  function reloadData() {
    page === 1 ? handleGetData() : setPage(1);
  }

  function handleSearch(event) {
    page > 1 && setPage(1);
    const { value } = event.target;

    setSearch(value?.trim());
  }

  const handleGetData = async () => {
    try {
      setLoading(true);

      if (!activeTab) {
        const { data: dataPending } = await batchService.getSellerBatches({
          page,
          search,
          status: 1
        });

        const data = formatBatchesSeller(dataPending.results);
        setPendingData(data);

        setTotalPages(dataPending.pageSize);
        batchId && handleOpenSteps(Number(batchId), data);
      } else {
        const { data: dataFinished } = await batchService.getSellerBatches({
          page,
          search,
          receiptDateFrom: searchDateFrom,
          receiptDateTo: searchDateTo,
          excludeStatus: 1,
          status: filterStatus || null
        });

        setFinishedData(formatBatchesSeller(dataFinished.results));

        setTotalPages(dataFinished.pageSize);
      }
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao receber dados, tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = status => {
    setFilterStatus(status);
  };
  const handleChangePage = e => {
    const selectedPage = e.selected + 1;
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth"
      });
    }
    setPage(selectedPage);
  };

  const handleChangeTable = (event, newValue) => {
    page !== 1 && setPage(1);
    setSearch("");
    setActiveTab(newValue);
  };

  const handleOpenSteps = (batch, filteredDataById) => {
    const batches = (filteredDataById || pendingData).reduce(
      (acc, batchSeller) => [...acc, ...batchSeller.batches],
      []
    );
    const data = batches.find(({ id }) => id === batch);
    if (!data) {
      setOpenAlertBatchNotFound(true);
    } else {
      setBatchDetail(data);
      setOpenStepsPopup(true);
    }
  };

  const handleCloseOpenSteps = () => {
    batchId && setRedirect(true);
    setOpenStepsPopup(false);
  };

  const handleOpenPrintPopup = batchId => {
    const batches = finishedData.reduce(
      (acc, batchSeller) => [...acc, ...batchSeller.batches],
      []
    );
    const data = batches.find(({ id }) => id === batchId);

    setBatchDetail(data);
    setOpenPrintPopup(true);
  };

  const handleClosePrintPopup = () => {
    setOpenPrintPopup(false);
  };

  const handleOpenNewBatchPopup = batchId => {
    const batches = finishedData.reduce(
      (acc, batchSeller) => [...acc, ...batchSeller.batches],
      []
    );
    const data = batches.find(({ id }) => id === batchId);

    setBatchDetail(data);
    setOpenNewBatchPopup(true);
  };

  const handleCloseNewBatchPopup = () => {
    setOpenNewBatchPopup(false);
  };

  const handleOpenEditTaxEntryPopUp = batchId => {
    const batches = (activeTab ? finishedData : pendingData).reduce(
      (acc, batchSeller) => [...acc, ...batchSeller.batches],
      []
    );
    const data = batches.find(({ id }) => id === batchId);

    setBatchDetail(data);
    setOpenEditTaxEntryPopup(true);
  };

  const handleCloseEditTaxEntryPopup = () => {
    setOpenEditTaxEntryPopup(false);
    reloadData();
  };

  const handleCloseAlertBatchNotFound = () => {
    setOpenAlertBatchNotFound(false);
    setRedirect(true);
  };

  const activePage = {
    0: (
      <PendingPage
        debaunceSearch={debaunceSearch}
        handleOpenSteps={handleOpenSteps}
        pendingData={pendingData}
        activeTab={activeTab}
        dataLoading={loading}
        setOpenUndeliveredBatchPopup={
          undeliveredBatchModalRef.current?.handleOpen
        }
        handleOpenEditTaxEntryPopUp={handleOpenEditTaxEntryPopUp}
      />
    ),
    1: (
      <FinishedPage
        debaunceSearch={debaunceSearch}
        handleOpenSteps={handleOpenSteps}
        finishedData={finishedData}
        activeTab={activeTab}
        handleFilter={handleFilter}
        filterStatus={filterStatus}
        handleOpenPrintPopup={handleOpenPrintPopup}
        handleOpenNewBatchPopup={handleOpenNewBatchPopup}
        dataLoading={loading}
        handleOpenEditTaxEntryPopUp={handleOpenEditTaxEntryPopUp}
      />
    )
  }[activeTab];

  useEffect(() => {
    handleGetData();
  }, [activeTab, filterStatus, search, page]);

  if (isUnauthorizedUser) {
    return <Redirect to="/" />;
  }

  return (
    <ContainerPage id="back-to-top-anchor">
      <TitlePage>Recebimento </TitlePage>

      <TabReceipt activeTab={activeTab} handleChangeTable={handleChangeTable} />
      <ReceiptContent>
        {activePage}

        <PaginateContent>
          {totalPages > 1 && !loading && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={page - 1}
              onPageChange={handleChangePage}
              pageRangeDisplayed={3}
              pageCount={totalPages}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
              loading={loading}
            />
          )}
        </PaginateContent>
      </ReceiptContent>

      <StepsPopup
        openConfirm={openStepsPopup}
        handleCloseOpenSteps={handleCloseOpenSteps}
        batchData={batchDetail}
        reloadData={reloadData}
      />

      <PrintPopup
        batchData={batchDetail}
        handleClosePrintPopup={handleClosePrintPopup}
        openPrintPopup={openPrintPopup}
      />
      <NewBatchPopup
        batchData={batchDetail}
        handleCloseNewBatchPopup={handleCloseNewBatchPopup}
        openNewBatchPopup={openNewBatchPopup}
      />

      <UndeliveredBatchPopup
        reloadData={reloadData}
        ref={undeliveredBatchModalRef}
      />

      <EditTaxEntryPopup
        batchData={batchDetail}
        handleCloseEditTaxEntryPopup={handleCloseEditTaxEntryPopup}
        openEditTaxEntryPopup={openEditTaxEntryPopup}
      />

      <StyledDialog
        open={openAlertBatchNotFound}
        title="Aviso"
        handleClose={handleCloseAlertBatchNotFound}
      >
        <PopupContent>
          <TextInfoAlert>
            Aviso: Lote não está pendente de recebimento
          </TextInfoAlert>
        </PopupContent>
      </StyledDialog>
      {redirect && <Redirect to="/app/dashboard/kanban" />}
    </ContainerPage>
  );
}
