import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import RepeatIcon from "@material-ui/icons/Repeat";

export const HistoryIcons = ({ index, diff }) => {
  if (!index) {
    return <AddCircleOutlineIcon />;
  }
  return !Object.keys(diff).length ? <RepeatIcon /> : <EditIcon />;
};
