import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { mapPurchaseType } from "utils";

import { LoadingBlitzContent } from "../../style";
import { InitialPage } from "./InitialPage";
import { ResultPage } from "./ResultPage";

export const QualityBlitz = ({
  confirmedQty,
  batchData,
  blitzData,
  handleNext,
  handleChangeSampleQualityQty,
  handleChangeSampleLowQualityQty,
  sampleQualityQty,
  sampleLowQualityQty,
  handleCloseOpenSteps,
  isApproved,
  setIsApproved
}) => {
  const [activePage, setActivePage] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    sample_percentage: samplePercentage,
    min_sample: minSample,
    packaging,
    observation
  } = blitzData;
  const { purchase_type } = batchData.product;
  const boxSampleCalc = Math.ceil((confirmedQty / 100) * samplePercentage);
  const boxSampleQty = boxSampleCalc < minSample ? minSample : boxSampleCalc;
  const typeOfMeasure = mapPurchaseType(purchase_type);
  const refusalInstructions =
    batchData.purchase_item?.purchase?.refusal_instructions;
  const hasNoDevolution = refusalInstructions[0] === "1";

  const page = {
    0: (
      <InitialPage
        boxSampleQty={boxSampleQty}
        handleChangeSampleLowQualityQty={handleChangeSampleLowQualityQty}
        handleChangeSampleQualityQty={handleChangeSampleQualityQty}
        observation={observation}
        packaging={packaging}
        sampleLowQualityQty={sampleLowQualityQty}
        sampleQualityQty={sampleQualityQty}
        typeOfMeasure={typeOfMeasure}
        setLoading={setLoading}
        batchId={batchData.id}
        setActivePage={setActivePage}
        handleNext={handleNext}
        setIsApproved={setIsApproved}
        hasNoDevolution={hasNoDevolution}
      />
    ),
    1: (
      <ResultPage
        batchId={batchData.id}
        isApproved={isApproved}
        handleNext={handleNext}
        handleCloseOpenSteps={handleCloseOpenSteps}
      />
    )
  }[activePage];

  if (loading) {
    return (
      <LoadingBlitzContent>
        <CircularProgress color="primary" />
      </LoadingBlitzContent>
    );
  }

  return page;
};
