import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  seller: Yup.object().required("Fornecedor é obrigatório."),
  address: Yup.string().required("Endereço é obrigatório."),
  scheduledDate: Yup.date().required("Data de Agendamento é obrigatória."),
  scheduledTimeFrom: Yup.date().required("Horário Ideal é obrigatório."),
  scheduledTimeTo: Yup.date().required("Horário Limite é obrigatório."),
  hasEmptyBoxes: Yup.string().required(
    "É obrigatório informar se há caixas vazias."
  ),
  isCritical: Yup.string().required(
    "É obrigatório informar se há produto crítico."
  ),
  emptyBoxesCount: Yup.number().when("hasEmptyBoxes", {
    is: "yes",
    then: Yup.number()
      .min(1, "Quantidade de Caixas Vazias deve ser maior que 0.")
      .required("Quantidade de Caixas Vazias é obrigatória."),
    otherwise: Yup.number().nullable()
  })
});
