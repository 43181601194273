import { FiSearch } from "react-icons/fi";

const { TextField, InputAdornment } = require("@material-ui/core");

export const Search = ({ ...rest }) => {
  return (
    <TextField
      {...rest}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FiSearch fontSize={22} color="#C4C4C4" />
          </InputAdornment>
        )
      }}
    />
  );
};
