import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: "12rem",
    height: "12.5rem",
    background: "#ffffff"
  },
  content: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    "&:hover": {
      background: "#f1eafd"
    },
    "& .MuiTouchRipple-child": {
      backgroundColor: "#BF98FF"
    }
  },
  options: {
    position: "absolute",
    top: "5%",
    right: "0"
  },
  menuAction: {
    fontSize: "1rem",
    lineHeight: "16px",
    color: "#353535",
    marginLeft: "1rem"
  },
  avatar: {
    width: "3.75rem",
    height: "3.75rem"
  },
  name: {
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "0.875rem",
    padding: "0 0.3rem",
    textAlign: "center"
  }
});
