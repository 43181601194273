import {
  Accordion,
  AccordionDetails,
  Box,
  styled,
  Typography,
  Chip
} from "@material-ui/core";
import { Search } from "shared/Search";

export const StyledMainAccordion = styled(Accordion)({
  margin: 0,
  padding: 0,
  "& .MuiAccordionSummary-root": {
    paddingBlock: "1rem"
  },
  "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded ": {
    backgroundColor: "#E5ECD1"
  },
  "& .MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd ": {
    backgroundColor: "#E5ECD1"
  },
  "&.MuiAccordion-root.Mui-expanded": {
    margin: 0,
    padding: 0
  }
});

export const StyledInternalAccordion = styled(Accordion)(
  ({ assetBalance }) => ({
    margin: 0,
    padding: 0,
    "& .MuiAccordionSummary-root": {
      paddingBlock: "0.5rem",
      backgroundColor:
        assetBalance < 0 ? "#FFDDDD" : assetBalance > 0 ? "#DDFFDD" : "inherit"
    },
    "& .MuiAccordionDetails-root": {
      backgroundColor:
        assetBalance < 0 ? "#FFDDDD" : assetBalance > 0 ? "#DDFFDD" : "inherit"
    },
    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded ": {
      backgroundColor:
        assetBalance < 0 ? "#FFDDDD" : assetBalance > 0 ? "#DDFFDD" : "inherit"
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd ": {
      backgroundColor:
        assetBalance < 0 ? "#FFDDDD" : assetBalance > 0 ? "#DDFFDD" : "inherit"
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
      padding: 0
    }
  })
);

export const ChipStatus = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  fontWeight: 500,
  fontSize: "0.75rem"
}));

export const StyledAccordionDetails = styled(AccordionDetails)(
  ({ assetBalance }) => ({
    overflowY: "auto",
    backgroundColor:
      assetBalance < 0
        ? "#fff2f2 !important"
        : assetBalance > 0
        ? "#f2fff2 !important"
        : "inherit"
  })
);

export const BadgeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "1rem",
  height: "1rem",
  borderRadius: "50%",
  backgroundColor: "#FF0000",
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: "0.7rem",
  right: "1rem"
}));

export const StyledAccordionHeader = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

export const NegativeBalanceCount = styled(Typography)({
  fontSize: "0.875rem",
  fontWeight: 500,
  color: "#FF0000"
});

export const ReceiptContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column"
}));

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});

export const SearchInput = styled(Search)(({ theme }) => ({
  minWidth: "22rem",
  height: "3.5rem",

  [theme.breakpoints.down("sm")]: {
    minWidth: "100%"
  }
}));

export const ReceiptPageContent = styled(Box)({
  display: "flex",
  flexDirection: "column"
});

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 0 1rem",
  [theme.breakpoints.down("lg")]: {
    flex: 1,
    flexDirection: "column",
    gap: "1rem"
  }
}));
