import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import blitzService from "services/blitzService";

import ReceiptSteps from "./Step";
import { LoadingBlitzContent, PopupContent, StyledDialog } from "./style";

export const StepsPopup = ({
  openConfirm,
  handleCloseOpenSteps,
  batchData,
  reloadData
}) => {
  const [blitzData, setBlitzData] = useState();
  const [loadingBlitz, setLoadingBlitz] = useState(true);
  const [title, setTitle] = useState("Caixas");

  const handleClose = () => {
    handleCloseOpenSteps();
    reloadData();
  };

  const getBlitzRule = async productBaseId => {
    try {
      setLoadingBlitz(true);
      const { data } = await blitzService.getBlitzData({
        productBaseId
      });
      setBlitzData(data.results?.[0]);
    } catch (error) {
    } finally {
      setLoadingBlitz(false);
    }
  };

  useEffect(() => {
    openConfirm && getBlitzRule(batchData.product?.id);
  }, [openConfirm]);

  return (
    <StyledDialog open={openConfirm} handleClose={handleClose} title={title}>
      <PopupContent>
        {loadingBlitz ? (
          <LoadingBlitzContent>
            <CircularProgress color="primary" />
          </LoadingBlitzContent>
        ) : (
          <ReceiptSteps
            batchData={batchData}
            blitzData={blitzData}
            handleClose={handleClose}
            setTitle={setTitle}
          />
        )}
      </PopupContent>
    </StyledDialog>
  );
};
