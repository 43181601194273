import { Grid, Box, TextField } from "@material-ui/core";
import { Button } from "components";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";

import { useStyles } from "./styles";
import {
  validationSchemaCreate,
  validationSchemaEdit
} from "./validationSchema";

const Component = ({ initialValues, onSubmit }, ref) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: initialValues || {
      name: "",
      description: ""
    },
    validationSchema: initialValues
      ? validationSchemaEdit
      : validationSchemaCreate,
    onSubmit: onSubmit,
    validateOnChange: false
  });

  useImperativeHandle(ref, () => ({ resetForm: formik.resetForm }));

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Box display="flex" className={classes.gridImage}>
        <Grid container xs={12} sm={12}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label="Nome"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.name || formik.submitCount > 0) &&
                !!formik.errors.name
              }
              helperText={
                (formik.touched.name || formik.submitCount > 0) &&
                formik.errors.name
              }
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <TextField
              fullWidth
              multiline
              rows={2}
              name="description"
              label="Descrição do ativo"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.submitCount > 0 && !!formik.errors.description}
              helperText={formik.submitCount > 0 && formik.errors.description}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        xs={12}
        spacing={2}
        justify="center"
        justifyContent="center"
        style={{ marginTop: "1rem" }}
      >
        <Grid item container justifyContent="flex-end">
          <Button
            isLoading={formik.isSubmitting}
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Form = forwardRef(Component);
