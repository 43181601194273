import {
  alpha,
  Backdrop,
  Box,
  Container,
  IconButton,
  Modal as MuiModal,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 0.9rem"
  },
  container: {
    background: "#FFFFFF",
    maxWidth: "41.5rem",
    width: "auto",
    minHeight: "24rem",
    maxHeight: "85vh",
    padding: "0",
    borderRadius: "6px",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      background: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#A1CA4F",
      borderRadius: "9px"
    }
  },
  header: {
    position: "sticky",
    top: "0",
    left: "0",
    width: "100%",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    backgroundColor: alpha("#FFFFFF", 0.72),
    zIndex: 2,
    padding: "1.25rem 0"
  },
  closeBtn: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
    background: "#FAFAFA",
    height: "2.5rem",
    width: "2.5rem"
  },
  title: {
    fontFamily: '"Inter", sans-serif',
    fontSize: "1.25rem",
    fontWeight: 600,
    textAlign: "center"
  },
  loading: {
    margin: "5rem auto 0",
    justifyContent: "center",
    alignItems: "center",
    color: "#D70077",
    display: "block"
  },
  content: {
    width: "100%",
    height: "calc(100% - 70px)",
    padding: "0 1.25rem 1.25rem",
    position: "relative"
  }
});

const Modal = ({ title, handleClose, open, children, style }) => {
  const classes = useStyles();

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      className={classes.root}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Container className={classes.container} style={style}>
        <Box component="header" className={classes.header}>
          <IconButton className={classes.closeBtn} onClick={handleClose}>
            <CloseIcon style={{ fontSize: "1.4rem" }} />
          </IconButton>

          <Typography className={classes.title}>{title}</Typography>
        </Box>
        <Box justifyContent="center" className={classes.content}>
          {children}
        </Box>
      </Container>
    </MuiModal>
  );
};

export default Modal;
