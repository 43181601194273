import { Box, Button, InputAdornment, TextField } from "@material-ui/core";
import { ContainerPage, OptionsMenu, TitlePage } from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import { useEffect, useMemo, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import blitzService from "services/blitzService";
import productBaseService from "services/productBaseService";
import { Table } from "shared";
import { mapType } from "utils";

import { ModalCreated } from "./components/ModalCreated/indes";
import { ModalUpdated } from "./components/ModalUpdated";
import { useStyles } from "./styles";

export const BlitzRules = () => {
  const { data, onChange, params, refresh, status, handleSearch, count } =
    useTableParams({
      service: params => blitzService.getBlitzData(params)
    });

  const [openNew, setOpenNew] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [productList, setProductList] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [allBlitzRules, setAllBlitzRules] = useState([]);

  const classes = useStyles();
  const { addPopup } = usePopup();

  const options = [
    {
      title: "Editar",
      icon: FiEdit2,
      permissions: ["Estoque", "Estoque físico", "Tech", "Previsão"],
      action: data => handleUpdateData(data)
    }
  ];

  const columns = [
    {
      field: "product",
      label: "Produto",
      align: "left",
      style: { minWidth: "60px" },
      formatter: value => value.name
    },
    {
      field: "product",
      label: "Porção",
      align: "center",
      formatter: value => mapType(value.purchase_type)
    },
    {
      field: "average_weight",
      label: "Qntd. média",
      align: "center"
    },
    {
      field: "packaging",
      label: "Embalagem",
      align: "center"
    },
    {
      field: "sample_percentage",
      label: "% da amostra",
      align: "center"
    },
    {
      field: "min_sample",
      label: "Amostragem mínima",
      align: "center"
    },
    {
      field: "observation",
      label: "Obs",
      align: "left",
      style: { minWidth: "250px" }
    },
    {
      field: "",
      label: "",
      align: "center",
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  const getAllBlitzRules = async () => {
    try {
      const { data } = await blitzService.getBlitzData({
        pageSize: 4000
      });
      setAllBlitzRules(data.results);
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao carregar todas as regras de blitz."
      });
    }
  };

  const handleUpdateState = setState => {
    setState(prevState => !prevState);
  };

  const handleUpdateData = infos => {
    setSelectedProductId(infos.product.id);
    handleUpdateState(setOpenUpdate);
    setInitialValues(infos);
  };

  const init = async () => {
    try {
      const { data } = await productBaseService.getProductBase({
        status: "active",
        pageSize: 4000
      });

      setProductList(data.results);
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao carregar dados do produto base."
      });
    }
  };

  const handleSelectProduct = id => {
    setSelectedProductId(id);
  };

  const productListNotAdded = useMemo(() => {
    const blitzProductsId = allBlitzRules.reduce(
      (acc, { product }) => [...acc, product.id],
      []
    );

    return productList.filter(({ id }) => !blitzProductsId?.includes(id));
  }, [allBlitzRules, productList, data]);

  useEffect(() => {
    init();
    getAllBlitzRules();
  }, []);

  return (
    <ContainerPage>
      <Box className={classes.root}>
        <TitlePage>Regra da blitz</TitlePage>

        <Button
          variant="contained"
          startIcon={<IoMdAdd />}
          onClick={() => handleUpdateState(setOpenNew)}
          className={classes.button}
        >
          Adicionar
        </Button>
      </Box>

      <Table
        handleSearch={handleSearch}
        columns={columns}
        data={data}
        onChange={onChange}
        params={params}
        status={status}
        refresh={refresh}
        count={count}
      />

      <ModalCreated
        isOpen={openNew}
        refresh={refresh}
        productList={productListNotAdded}
        close={() => handleUpdateState(setOpenNew)}
        handleSelectProduct={handleSelectProduct}
        selectedProductId={selectedProductId}
      />

      <ModalUpdated
        initialValues={initialValues}
        isOpen={openUpdate}
        refresh={refresh}
        productList={productList}
        close={() => handleUpdateState(setOpenUpdate)}
        handleSelectProduct={handleSelectProduct}
        selectedProductId={selectedProductId}
      />
    </ContainerPage>
  );
};
