import { Box } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {
  ContainerPage,
  DialogConfirmDelete,
  PermissionGate,
  TitlePage,
  Button
} from "components";
import { OptionsMenu } from "components/OptionsMenu";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import React, { useState } from "react";
import { MdModeEditOutline, MdOutlineDelete } from "react-icons/md";
import { useHistory } from "react-router-dom";
import sellerService from "services/sellerService";
import { Table } from "shared";

import { ModalCreateAddress } from "./components/ModalCreateAddress";
import { ModalCreated } from "./components/ModalCreated";
import { ModalEdit } from "./components/ModalEdit";
import { ModalEditAddress } from "./components/ModalEditAddress";
import { ModalListAddress } from "./components/ModalListAddress";
import { mapPixKeyType } from "./utils/mapPixKeyType";

export default function Seller() {
  const history = useHistory();
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => sellerService.getSellers(params)
    });
  const { addPopup } = usePopup();

  const [openModal, setOpenModal] = useState(false);
  const [dialogConfirmDelete, setDialogConfirmDelete] = useState({
    isOpen: false,
    id: null
  });
  const [modalEdit, setModalEdit] = useState({
    isOpen: false,
    data: {}
  });
  const [addressEdit, setAddressEdit] = useState({
    isOpen: false,
    data: {}
  });
  const [addressList, setAddressList] = useState({
    isOpen: false,
    data: {}
  });
  const [addressCreate, setAddressCreate] = useState({
    isOpen: false,
    data: {}
  });

  const options = [
    {
      title: "Dashboard",
      icon: DashboardIcon,
      permissions: ["Estoque", "Estoque físico", "Tech", "Previsão"],
      action: data => {
        history.push(`fornecedores/${data.id}/dashboard`);
      }
    },
    {
      title: "Editar",
      icon: MdModeEditOutline,
      permissions: ["Estoque", "Estoque físico", "Tech", "Previsão"],
      action: data =>
        setModalEdit({
          isOpen: true,
          data
        })
    },
    {
      title: "Endereços",
      icon: LocationOnIcon,
      permissions: ["Estoque", "Estoque físico", "Tech", "Previsão"],
      action: data =>
        setAddressList({
          isOpen: true,
          data
        })
    },
    {
      title: "Excluir",
      icon: MdOutlineDelete,
      color: "red",
      permissions: ["Estoque", "Estoque físico", "Tech", "Previsão"],
      action: data => {
        setDialogConfirmDelete({
          isOpen: true,
          id: data.id
        });
      }
    }
  ];

  const columns = [
    {
      field: "code",
      label: "Código",
      align: "center",
      style: { minWidth: "2rem" }
    },
    {
      field: "name",
      label: "Nome",
      style: { minWidth: "240px" }
    },
    {
      field: "phone_number",
      label: "Celular",
      align: "center",
      style: { minWidth: "200px" }
    },
    {
      field: "bank",
      label: "Banco"
    },
    {
      field: "agency",
      label: "Agência"
    },
    {
      field: "account",
      label: "Conta"
    },
    {
      field: "pix_key",
      label: "Chave pix"
    },
    {
      field: "pix_key_type",
      label: "Tipo da chave",
      renderCell: ({ data }) => mapPixKeyType(data?.pix_key_type)
    },
    {
      field: "beneficiary",
      label: "Beneficiário"
    },

    {
      field: "delete",
      label: "",
      align: "right",
      style: { minWidth: "150px" },
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  const handleDeleteSeller = async () => {
    try {
      await sellerService.deleteSeller(dialogConfirmDelete.id);
      addPopup({
        type: "success",
        title: "Seller excluído com sucesso"
      });
      refresh();
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Desculpe, não foi possível excluir o Seller."
        });
      }
    }
  };

  return (
    <>
      <ContainerPage>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <TitlePage>Lista de fornecedores</TitlePage>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            ml="auto"
            style={{ gap: "1rem" }}
          >
            <PermissionGate
              permissions={["Estoque", "Estoque físico", "Tech", "Previsão"]}
            >
              <Button
                onClick={() => setOpenModal(true)}
                startIcon={<AddRoundedIcon />}
                ariant="contained"
                variant="contained"
              >
                Novo fornecedor
              </Button>
            </PermissionGate>
          </Box>
        </Box>

        <Table
          handleSearch={handleSearch}
          placeholder="Nome do fornecedor ou código"
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          size="small"
        />
      </ContainerPage>

      <DialogConfirmDelete
        open={dialogConfirmDelete.isOpen}
        handleClose={setDialogConfirmDelete}
        confirm={handleDeleteSeller}
      />

      <ModalEdit
        openModal={modalEdit.isOpen}
        setOpenModal={setModalEdit}
        refresh={refresh}
        dataSeller={modalEdit.data}
      />

      <ModalListAddress
        openModal={addressList.isOpen}
        setOpenModal={setAddressList}
        setCreateAddressOpenModal={setAddressCreate}
        setEditAddressOpenModal={setAddressEdit}
        refresh={refresh}
        dataSeller={addressList.data}
      />

      <ModalEditAddress
        openModal={addressEdit.isOpen}
        setOpenModal={setAddressEdit}
        refresh={refresh}
        dataSeller={addressEdit.data}
      />

      <ModalCreateAddress
        openModal={addressCreate.isOpen}
        setOpenModal={setAddressCreate}
        refresh={refresh}
        dataSeller={addressCreate.data}
      />

      <ModalCreated
        openModal={openModal}
        setOpenModal={setOpenModal}
        refresh={refresh}
        setSellerData={setAddressCreate}
      />
    </>
  );
}
