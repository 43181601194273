import * as Yup from "yup";

const validationDefault = {
  productBase: Yup.object()
    .typeError("Produto base é obrigatório")
    .required("Produto base é obrigatório"),
  seller: Yup.object()
    .typeError("Fornecedor é obrigatório")
    .required("Fornecedor é obrigatório"),
  combinedPrice: Yup.string()
    .typeError("Preço Kg/Un combinado é obrigatório")
    .required("Preço Kg/Un combinado é obrigatório"),
  prediction: Yup.string()
    .typeError("Quantidade é obrigatório")
    .required("Quantidade é obrigatório"),
  blitzPercentage: Yup.number()
    .typeError("A porcentagem deve ser um número")
    .min(1, "A porcentagem não pode ser menor que 1")
    .max(100, "A porcentagem deve estar entre 1 e 100")
    .nullable(),
  deadlineForAnalysis: Yup.number().when("refusalInstructions", {
    is: value => [3, 4].includes(Number(value)),
    then: Yup.number()
      .typeError("A quantidade deve ser um número")
      .min(1, "A quantidade não pode ser menor que 1")
      .max(30, "A quantidade não pode ser maior que 30")
      .required("A quantidade é obrigatória quando as instruções são 3 ou 4")
      .nullable(),
    otherwise: Yup.number()
      .typeError("A quantidade deve ser um número")
      .min(1, "A quantidade não pode ser menor que 1")
      .max(30, "A quantidade não pode ser maior que 30")
      .nullable()
  }),
  refusalInstructions: Yup.string()
    .typeError("Instruções de recusa é obrigatório")
    .required("Instruções de recusa é obrigatório")
};

export const validationSchemaEdit = Yup.object().shape(validationDefault);

export const validationSchemaCreate = Yup.object().shape({
  toDate: Yup.date()
    .typeError("Data prevista é obrigatório")
    .required("Data prevista é obrigatório"),
  ...validationDefault
});
