import {
  Box,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from "@material-ui/core";
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem
} from "@material-ui/lab";
import { SearchContent } from "components/Contents";

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});

export const TableContent = styled(TableContainer)({
  display: "flex",
  flexDirection: "column",
  height: "32rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0.6rem",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "9px"
  }
});

export const StyledTableHead = styled(TableHead)({
  background: "#F3F8E6"
});

export const HeadCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem"
});

export const BodyRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const BodyCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem"
});

export const DateTimeContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem"
});

export const StyledSearchContent = styled(SearchContent)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const SubBatchIdContent = styled(Box)({
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: "#353535"
});

export const HistoryContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "2rem"
});

export const TitleDetail = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginBottom: "1rem",
  color: "#353535"
});

export const CardTitle = styled(Box)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1rem",
  color: "#757575"
}));

export const CardValue = styled(Box)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1rem",
  color: "#353535"
}));

export const ContainerWithIcon = styled(Paper)(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  borderRadius: "0.5rem",
  gap: "1rem",
  flex: 1
}));

export const ContentWithIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "1rem 2rem 1rem 1rem",
  gap: "1rem",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const DetailCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const DetailCardContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.2rem"
}));

export const DetailContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  gap: "1rem",
  flexDirection: "column"
}));

export const DetailContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column"
}));

export const InfoIconContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "1rem",
  backgroundColor: "#F3F8E6",

  "& svg": {
    color: "#587916"
  }
}));

export const InfoId = styled(Box)({
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#353535"
});

export const InfoIdContent = styled(Paper)(({ theme }) => ({
  display: "flex",
  maxWidth: "fit-content",
  padding: "1rem",
  alignItems: "center",
  gap: "0.5rem",
  borderRadius: "0.5rem 0.5rem 0 0",
  backgroundColor: "#F3F8E6",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#757575"
}));

export const InfosContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1
}));

export const PaperInfoContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  borderRadius: "0.5rem",
  flex: 1,
  flexWrap: "wrap"
}));

export const PaperInfoContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minWidth: "fit-content"
}));

export const PaperInfoTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#F3F8E6",
  borderRight: "1px solid #C4C4C4",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#757575"
}));

export const PaperInfoValue = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  justifyContent: "center",
  alignItems: "center",
  borderRight: "1px solid #C4C4C4",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#353535"
}));

export const ProductInfoContent = styled(Paper)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  gap: "1rem",
  borderRadius: "0 0.5rem 0.5rem 0.5rem",
  flex: 1
}));

export const BoxNewValue = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: "#E5ECD1",
  padding: "0.5rem",
  minHeight: "2rem",
  borderRadius: "0.25rem",
  fontFamily: "Inter",
  fontSize: "0.875rem",
  justifyContent: "center"
}));

export const BoxOldValue = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: "#F5F6F7",
  padding: "0.5rem",
  minHeight: "2rem",
  borderRadius: "0.25rem",
  fontFamily: "Inter",
  fontSize: "0.875rem",
  justifyContent: "center"
}));

export const CreatedByContent = styled(Typography)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  justifyContent: "flex-end",
  alignItems: "center",
  "& svg": {
    height: "0.875rem"
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem"
  }
}));

export const CreatedDateTimeContent = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const HistoryDiffContent = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  justifyContent: "space-between",
  flex: 1,
  minWidth: "fit-content",

  [theme.breakpoints.down("lg")]: {
    alignItems: "normal",
    textAlign: "center"
  }
}));

export const HistoryTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "1rem",
  textAlign: "center",
  margin: 0,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    textAlign: "center"
  }
}));

export const StyledTimeLine = styled(Timeline)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingInline: 0
  }
}));

export const StyledTimeLineContent = styled(TimelineContent)(({ theme }) => ({
  display: "flex",
  paddingBlock: "0.5rem",
  gap: "0.5rem",
  flexWrap: "wrap",
  "& .MuiPaper-root": {
    padding: "0.5rem 1rem"
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  }
}));

export const StyledTimeLineDot = styled(TimelineDot)(({ theme, hasDiff }) => ({
  backgroundColor: hasDiff
    ? theme.palette.primary.blue
    : theme.palette.secondary.main
}));

export const StyledTimeLineItem = styled(TimelineItem)(({ theme }) => ({
  "& .MuiTimelineOppositeContent-root": {
    display: "flex",
    padding: "1rem",
    flex: 0,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      maxWidth: "35%",
      flex: 1,
      "& .MuiTypography-body2": {
        fontSize: "0.7rem"
      }
    }
  }
}));

export const ValuesContent = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  flexDirection: "column",
  alignItems: "center"
}));
