import { TextField } from "@material-ui/core";
import BackgroundImage from "assets/images/background.jpg";
import LogoImg from "assets/images/logoFrexco.png";
import { Button } from "components";
import { useAuth } from "hooks/useAuth";
import { usePopup } from "hooks/usePopup";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { useStyles } from "./styles";

export default function Login() {
  const history = useHistory();
  const classes = useStyles();
  const { signIn } = useAuth();
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    username: "",
    password: "",
    rememberMe: false
  });

  const onSubmitHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      await signIn({
        username: user.username,
        password: user.password,
        rememberMe: user.rememberMe
      });
      addPopup({
        type: "success",
        title: "Login realizado com sucesso!"
      });
      history.push("/app");
    } catch (err) {
      if (err?.response?.status === 401) {
        addPopup({
          type: "error",
          title: "Oops! Credenciais inválidas :("
        });
        return;
      }

      addPopup({
        type: "error",
        title: "Ocorreu algum erro",
        description: "Contatar o administrador!"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.overlay}></div>
      <img src={BackgroundImage} className={classes.background} />

      <div className={classes.loginBox}>
        <img src={LogoImg} alt="Frexco" className={classes.logoImg} />

        <h3 className={classes.title}>Login</h3>
        <form onSubmit={onSubmitHandler} className={classes.form}>
          <TextField
            required
            id="filled-required"
            label="Login"
            variant="outlined"
            type="text"
            name="username"
            value={user.username}
            onChange={event =>
              setUser({ ...user, username: event.target.value })
            }
            placeholder="Digite o login"
            className={classes.formObjects}
          />

          <TextField
            required
            id="filled-required"
            label="Senha"
            variant="outlined"
            type="password"
            name="password"
            value={user.password}
            onChange={event =>
              setUser({ ...user, password: event.target.value })
            }
            placeholder="Digite a senha"
            className={classes.formObjects}
          />

          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            isLoading={loading}
          >
            Entrar
          </Button>
        </form>
      </div>
    </div>
  );
}
