const Paper = theme => {
  return {
    MuiPaper: {
      root: {
        borderRadius: "0.5rem"
      },
      rounded: {
        borderRadius: "1rem"
      }
    }
  };
};

export default Paper;
