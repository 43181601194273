export const permissionsAplication = {
  Estoque: "Estoque",
  Admin: "Administrador",
  CD: "CD",
  Previsão: "Previsão",
  "Stock View": "Visualização",
  // Carambola
  Separation: "Separação",
  Check: "Conferência",
  "Box Loading": "Carregador",
  "Item Request": "Repositor",
  "Fixed Reader": "Fixed Reader",
  "Critical Order Check": "Conferência de Pedidos Críticos",
  "Critical or Beginner": "Crítico / Novato"
};
