import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { MainContent } from "components/Contents";
import { SearchInput } from "components/Inputs";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import batchService from "services/batchService";
import { useDebaunce } from "utils";

import { ClassificationFilter } from "./components/FilterSelect/ClassificationFilter";
import { ReceiptDateFilter } from "./components/FilterSelect/ReceiptDateFilter";
import { Paginator } from "./components/Paginator";
import { SubBatchTable } from "./components/SubBatchTable";
import { StyledSearchContent } from "./style";
import { formatterData } from "./utils/formatterData";

export const ProductSubBatch = () => {
  const { addPopup } = usePopup();
  const [subBatchData, setSubBatchData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [selectedFilterDate, setSelectedFilterDate] = useState(null);
  const controller = new AbortController();
  const signal = controller.signal;
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });
  const debaunceFilterDate = useDebaunce({ fn: handleFilterDate, delay: 800 });

  const getInitData = async () => {
    try {
      setLoading(true);
      setSubBatchData([]);

      const params = {
        page,
        search,
        classification: selectedClassification,
        receiptDate: selectedFilterDate,
        signal
      };

      const { data } = await batchService.getSubBatches(params);

      setSubBatchData(formatterData.subBatches(data.results));
      setTotalPages(data.pageSize);
    } catch (error) {
      if (!signal.aborted) {
        const errorMessage = error?.response?.data?.msg;
        addPopup({
          type: "error",
          title: "Erro ao carregar sublotes",
          description: errorMessage
        });
      }
    } finally {
      setLoading(false);
    }
  };

  function resetPage() {
    setPage(1);
  }

  function handleSearch(e) {
    const { value } = e.target;
    resetPage();
    setSearch(value?.trim());
  }

  function handleChangePage(e) {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
  }

  const handleFilterClassification = e => {
    const { value } = e.target;
    resetPage();
    setSelectedClassification(value);
  };

  function handleFilterDate(e) {
    const { value } = e.target;
    const formattedValue = value && new Date(value).toISOString();
    resetPage();
    setSelectedFilterDate(formattedValue);
  }

  useEffect(() => {
    getInitData();
    return () => {
      controller.abort();
    };
  }, [page, search, selectedClassification, selectedFilterDate]);

  return (
    <ContainerPage>
      <Box id="back-to-top-anchor">
        <TitlePage>Rastreio de sublote</TitlePage>
      </Box>
      <MainContent>
        <StyledSearchContent>
          <SearchInput
            placeholder="Seller, produto, código.."
            onChange={debaunceSearch}
          />
          <ClassificationFilter
            selectedClassification={selectedClassification}
            handleFilterClassification={handleFilterClassification}
          />
          <ReceiptDateFilter handleFilterDate={debaunceFilterDate} />
        </StyledSearchContent>
        <SubBatchTable loading={loading} subBatchData={subBatchData} />

        <Paginator
          handleChangePage={handleChangePage}
          loading={loading}
          page={page}
          totalPages={totalPages}
        />
      </MainContent>
    </ContainerPage>
  );
};
