import { usePopup } from "hooks/usePopup";
import { useRef } from "react";
import skuService from "services/skuService";
import { Drawer } from "shared";
import { errorsSku } from "utils";

import { Form } from "../Form";

export const Create = ({ isOpen, setIsOpen, refresh }) => {
  const formRef = useRef(null);
  const { addPopup } = usePopup();

  const handleClose = () => {
    setIsOpen(false);
    formRef.current?.resetForm();
  };

  const onSubmit = async data => {
    try {
      const { productBaseId: productBase } = data;
      await skuService.postSku({
        ...data,
        portionDescription: data.portionDescription?.trim(),
        productBaseId: productBase.id,
        category: productBase.categoryObj.id,
        subCategory: productBase.subCategoryObj.id,
        base64: data?.base64 || undefined,
        shouldGenerateDescription: !!data.shouldGenerateDescription
      });

      refresh();
      handleClose();
    } catch (err) {
      addPopup({
        type: "error",
        title: errorsSku(err.response?.data?.message)
      });
    }
  };

  return (
    <Drawer anchor="right" title="Novo Sku" open={isOpen} onClose={handleClose}>
      <Form ref={formRef} onSubmit={onSubmit} />
    </Drawer>
  );
};
