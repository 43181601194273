import { Box } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import {
  ContainerPage,
  DialogConfirmDelete,
  PermissionGate,
  TitlePage,
  Button,
  OptionsMenu
} from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import React, { useState } from "react";
import { GrFormView } from "react-icons/gr";
import { MdModeEditOutline, MdOutlineDelete } from "react-icons/md";
import sellerItemService from "services/sellerItemService";
import { Table } from "shared";
import { typeMapping } from "utils";

import { DialogCreated } from "./components/DialogCreated";
import { DialogEdit } from "./components/DialogEdit";
import { DialogInformations } from "./components/DialogInformations";

export default function Registros() {
  const {
    data,
    setData,
    params,
    onChange,
    status,
    refresh,
    count,
    handleSearch
  } = useTableParams({
    service: params => sellerItemService.getSellerItems(params)
  });
  const { addPopup } = usePopup();

  const [isOpenDialogCreated, setIsOpenDialogCreated] = useState(false);
  const [isOpenDialogInformations, setIsOpenDialogInformations] = useState({
    isOpen: false,
    data: {}
  });
  const [isOpenDialogEdit, setIsOpenDialogEdit] = useState({
    isOpen: false,
    data: {}
  });
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState({
    isOpen: false,
    id: null
  });

  const options = [
    {
      title: "Excluir",
      icon: MdOutlineDelete,
      color: "red",
      permissions: ["Tech", "Previsão"],
      action: data => {
        setIsOpenDialogDelete({ isOpen: true, id: data.id });
      }
    },
    {
      title: "Editar",
      icon: MdModeEditOutline,
      permissions: ["Tech", "Previsão"],
      action: data => setIsOpenDialogEdit({ isOpen: true, data })
    },
    {
      title: "Visualizar",
      icon: GrFormView,
      permissions: ["Tech", "Previsão"],
      action: data => {
        setIsOpenDialogInformations({
          isOpen: true,
          data
        });
      }
    }
  ];

  const columns = [
    {
      field: "seller",
      label: "Fornecedor",
      style: { minWidth: "200px" },
      orderBy: "seller__name",
      formatter: seller => {
        if (seller) return seller.name;
        return "";
      }
    },
    {
      field: "product",
      label: "Produto",
      style: { minWidth: "170px", maxWidth: "300px" },
      orderBy: "product__name",
      formatter: product => {
        if (product) return product.name;
        return "";
      }
    },
    {
      field: "portion",
      label: "Quantidade",
      align: "center",
      orderBy: "portion_type__id",
      style: { minWidth: "150px" }
    },
    {
      field: "portion_per_box",
      label: "Qtd por caixa",
      align: "center",
      style: { minWidth: "160px", maxWidth: "160px" }
    },
    {
      field: "portion_type",
      label: "Tipo de entrada",
      align: "center",
      style: { minWidth: "200px" },
      formatter: value => typeMapping(value)
    },
    {
      field: "status",
      label: "Status",
      align: "center",
      style: { minWidth: "150px" }
    },
    {
      field: "order_status",
      label: "Status de compra",
      align: "center",
      style: { minWidth: "200px" }
    },
    {
      field: "window_delivery_time",
      label: "Horário de entrega",
      align: "center",
      style: { minWidth: "200px" }
    },
    {
      field: "options",
      label: "",
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  const handleDeleteSellerItem = async () => {
    try {
      await sellerItemService.deleteSellerItem(isOpenDialogDelete.id);
      addPopup({
        type: "success",
        title: "Seller Item deletado com sucesso"
      });
      refresh();
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao deletar seller item"
        });
      }
    }
  };

  const handleUpdateData = sellerItem => {
    setData(state =>
      state.map(item =>
        item.id === sellerItem.id
          ? { ...item, ...sellerItem, portion_type: sellerItem.portion_type_id }
          : item
      )
    );
  };

  return (
    <>
      <ContainerPage>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <TitlePage>Lista de produtos por fornecedor</TitlePage>
          <PermissionGate permissions={["Tech", "Previsão"]}>
            <Button
              onClick={() => setIsOpenDialogCreated(true)}
              startIcon={<AddRoundedIcon />}
              variant="contained"
            >
              Novo Produto do Forcenedor
            </Button>
          </PermissionGate>
        </Box>

        <Table
          status={status}
          columns={columns}
          data={data}
          params={params}
          onChange={onChange}
          refresh={refresh}
          count={count}
          handleSearch={handleSearch}
          hover
        />
      </ContainerPage>

      <DialogConfirmDelete
        confirm={handleDeleteSellerItem}
        name="Seller item"
        open={isOpenDialogDelete.isOpen}
        handleClose={setIsOpenDialogDelete}
      />

      <DialogCreated
        isOpen={isOpenDialogCreated}
        refresh={refresh}
        setIsOpen={setIsOpenDialogCreated}
      />

      <DialogEdit
        isOpen={isOpenDialogEdit.isOpen}
        data={isOpenDialogEdit.data}
        confimed={handleUpdateData}
        refresh={refresh}
        setIsOpen={setIsOpenDialogEdit}
      />

      <DialogInformations
        isOpen={isOpenDialogInformations.isOpen}
        data={isOpenDialogInformations.data}
        setIsOpen={setIsOpenDialogInformations}
      />
    </>
  );
}
