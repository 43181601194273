import { styled } from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{ fontSize: "1rem", color: "#757575" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  paddingLeft: "0rem",
  backgroundColor: "#ffffff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}));

export default AccordionSummary;
