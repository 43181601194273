import {
  Chip,
  Menu as MenuMui,
  MenuItem as MenuItemMui,
  alpha,
  styled,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import { useRef, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";

export const ChipStatus = styled(Chip)(({ color }) => ({
  backgroundColor: alpha(color ?? "#757575", 0.1),
  color: color || "#757575",
  fontWeight: 500,
  fontFamily: "Roboto",
  fontSize: "0.75rem",
  fontStyle: "normal",
  lineHeight: "0.875rem"
}));

export const MenuItem = styled(MenuItemMui)(({ background, selected }) => ({
  padding: "0.7rem 3rem",
  color: background ?? "#757575",
  background: alpha(background ?? "#757575", selected ? 0.5 : 0.1),
  "& div": { height: "100%" },
  "&:hover": { background: alpha(background ?? "#757575", 0.2) }
}));

export const Menu = styled(MenuMui)({
  width: "50%",
  "& ul": { padding: 0 }
});

export const ChipButtonFilter = styled(ChipStatus)(({ color, width }) => ({
  display: "flex",
  justifyContent: "space-between",
  width,
  fontSize: "0.875rem",
  height: "3rem",
  "&:hover": { background: alpha(color ?? "#757575", 0.1) },
  "&:focus": { background: alpha(color ?? "#757575", 0.1) }
}));

const Select = ({ handleChange, selectData, value, formik, name, ...rest }) => {
  const selectRef = useRef(null);
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(
    selectData.find(
      select => select.value === (formik?.values?.[name] ?? value)
    ) ?? selectData[0]
  );

  const togglePopover = () => setSelectIsOpen(state => !state);

  const handleChangeValue = data => {
    !!formik && formik.setFieldValue(name, data.value);
    !!handleChange && handleChange(data.value);
    setSelectValue(data);
    setSelectIsOpen(false);
  };

  const error =
    formik &&
    (formik.touched?.[name] || formik.submitCount > 0) &&
    !!formik?.errors?.[name];

  return (
    <FormControl error={error} {...rest}>
      <ChipButtonFilter
        ref={selectRef}
        onClick={togglePopover}
        label={selectValue.label}
        onDelete={togglePopover}
        deleteIcon={<MdArrowDropDown />}
        statusId={"selecione"}
        aria-controls="customized-menu"
        aria-haspopup="true"
        color={selectValue.color}
        name={name}
        onBlur={e => {
          e.target.name = name;
          formik?.handleBlur(e);
        }}
      />

      {error && <FormHelperText>{formik.errors?.[name]}</FormHelperText>}

      <Menu
        anchorEl={selectRef.current}
        keepMounted
        open={selectIsOpen}
        onClose={togglePopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        elevation={0}
        getContentAnchorEl={null}
        PaperProps={{
          style: { width: selectRef.current?.offsetWidth }
        }}
      >
        {selectData.map(select => (
          <MenuItem
            key={select.value}
            background={select.color}
            selected={select.value === selectValue.value}
            onClick={() => handleChangeValue(select)}
            disabled={select.disabled}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {select.label}
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
};

export default Select;
