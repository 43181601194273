import { usePopup } from "hooks/usePopup";
import { useRef } from "react";
import depotService from "services/depotService";
import { Drawer } from "shared";

import { CreateManyDepotsForm } from "../CreateManyDepots";

export const PopupCreateManyDepots = ({
  open,
  depotData,
  warehouseData,
  warehouseTypeId,
  onClose,
  refreshData
}) => {
  const { addPopup } = usePopup();
  const formRef = useRef(null);

  const handleCreateManyDepots = async data => {
    const { abbreviation, name, from, to, warehouseTypeId } = data;
    try {
      const depotsArray = [];

      for (let i = from; i <= to; i++) {
        depotsArray.push({
          abbreviation: `${abbreviation} ${i}`.toUpperCase(),
          name: `${name} ${i}`,
          warehouseTypeId
        });
      }
      await depotService.createDepot(depotsArray);
      refreshData();
      addPopup({
        type: "success",
        title: "Depósitos criados com sucesso"
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Não foi possível criar os depósitos",
        description: errorMessage
      });
    }
  };

  const handleSubmit = values => {
    handleCreateManyDepots(values);
    onClose();
  };

  return (
    <Drawer
      title="Criar depósitos em massa"
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <CreateManyDepotsForm
        data={depotData}
        warehouseData={warehouseData}
        warehouseTypeId={warehouseTypeId}
        ref={formRef}
        onSubmit={handleSubmit}
      />
    </Drawer>
  );
};
