import {
  FormControl,
  MenuItem,
  Select,
  ListItemText,
  Checkbox,
  OutlinedInput,
  Box
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState, useEffect, useMemo } from "react";
import depotService from "services/depotService";

export const DropdownButton = ({
  warehouseData,
  productBaseWarehouses,
  productBaseId,
  refresh
}) => {
  const { addPopup } = usePopup();
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setSelectedItems(value);
  };

  const productBaseConfigPayload = useMemo(() => {
    return selectedItems.map(warehouseId => {
      return {
        productBaseId,
        warehouseId
      };
    });
  }, [selectedItems]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await depotService.postWarehouseProductBaseConfig(
        productBaseConfigPayload
      );
      addPopup({
        type: "success",
        title: "Configuração salva"
      });
      refresh();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao salvar configuração"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await depotService.deleteWarehouseProductBaseConfig(productBaseId);
      addPopup({
        type: "success",
        title: "Configurações apagada"
      });
      refresh();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao apagar configurações"
      });
    } finally {
      setLoading(false);
    }
  };

  const warehouseNames = warehouseData
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .reduce((acc, warehouse) => {
      return { ...acc, [warehouse.id]: warehouse.name };
    }, {});

  useEffect(() => {
    setSelectedItems(productBaseWarehouses?.map(item => item.warehouseId));
  }, [productBaseWarehouses]);

  return (
    <Box style={{ width: "350px", display: "flex", gap: "1rem" }}>
      <FormControl fullWidth>
        <Select
          value={selectedItems}
          multiple
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={selected => {
            if (!selected || selected.length === 0) {
              return "Nenhum selecionado";
            }

            return selected
              .map(item => warehouseNames[item])
              .sort((a, b) => a.localeCompare(b))
              .join(", ");
          }}
          style={{ width: "100%" }}
          disabled={loading}
        >
          {warehouseData.map(warehouse => (
            <MenuItem key={warehouse.id} value={warehouse.id}>
              <Checkbox
                checked={selectedItems?.some(item => item === warehouse?.id)}
                disabled={loading}
              />
              <ListItemText primary={warehouse.name} />
            </MenuItem>
          ))}
          <Button
            style={{
              marginTop: "1rem",
              marginLeft: "1rem"
            }}
            variant="contained"
            onClick={selectedItems.length ? handleSave : handleDelete}
            isLoading={loading}
            disabled={loading}
          >
            Salvar
          </Button>
        </Select>
      </FormControl>
    </Box>
  );
};
