import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class DepotService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async listWarehouseTypes(params) {
    const url = `${this.url}/api/depot/v1/warehouse-type`;
    return api.get(url, { params });
  }

  async updateWarehouseType(id, model) {
    const url = `${this.url}/api/depot/v1/warehouse-type/${id}`;
    return api.patch(url, model);
  }

  async createWarehouseType(model) {
    const url = `${this.url}/api/depot/v1/warehouse-type`;
    return api.post(url, model);
  }

  async listLedgers(params) {
    const url = `${this.url}/api/depot/v1/ledger`;
    return api.get(url, { params });
  }

  async moveBatch(data) {
    const url = `${this.url}/api/depot/v1/ledger/move`;
    return api.post(url, data);
  }

  async createLedger(data) {
    const url = `${this.url}/api/depot/v1/ledger`;
    return api.post(url, data);
  }

  async listDepots(params) {
    const url = `${this.url}/api/depot/v1/depot`;
    return api.get(url, { params });
  }

  async createDepot(model) {
    const url = `${this.url}/api/depot/v1/depot`;
    return api.post(url, model);
  }

  async updateDepot(id, model) {
    const url = `${this.url}/api/depot/v1/depot/${id}`;
    return api.patch(url, model);
  }

  async listProductBaseWarehouseTypes(params) {
    const url = `${this.url}/api/depot/v1/product-base-warehouse-type`;
    return api.get(url, { params });
  }

  async createProductBaseWarehouseType(data) {
    const url = `${this.url}/api/depot/v1/product-base-warehouse-type`;
    return api.post(url, data);
  }

  async updateProductBaseWarehouseType(id, data) {
    const url = `${this.url}/api/depot/v1/product-base-warehouse-type/${id}`;
    return await api.put(url, data);
  }

  async deleteProductBaseWarehouseType(id) {
    const url = `${this.url}/api/depot/v1/product-base-warehouse-type/${id}`;
    return api.delete(url);
  }

  async getPackaging(id) {
    const url = `${this.url}/api/depot/v1/packaging/${id}`;
    return await api.get(url);
  }

  async listPackagingType(params) {
    const url = `${this.url}/api/depot/v1/packaging`;
    return await api.get(url, { params });
  }

  async patchPackagingType(id, data) {
    const url = `${this.url}/api/depot/v1/packaging/${id}`;
    return await api.patch(url, data);
  }

  async postPackagingType(data) {
    const url = `${this.url}/api/depot/v1/packaging`;
    return await api.post(url, data);
  }

  async deletePackagingType(id) {
    const url = `${this.url}/api/depot/v1/packaging/${id}`;
    return await api.delete(url);
  }

  async getSensitiveLeves() {
    const url = `${this.url}/api/depot/v1/sensitivity`;
    return await api.get(url);
  }

  async postSensitiveLeves(data) {
    const url = `${this.url}/api/depot/v1/sensitivity`;
    return await api.post(url, data);
  }

  async getPackagingProductBaseConfig(params) {
    const url = `${this.url}/api/depot/v1/packaging/product-base-config`;
    return await api.get(url, { params });
  }

  async postPackagingProductBaseConfig(data) {
    const url = `${this.url}/api/depot/v1/packaging/product-base-config`;
    return await api.post(url, data);
  }

  async getWarehouseProductBaseConfig(params) {
    const url = `${this.url}/api/depot/v1/warehouse-type/product-base-config`;
    return await api.get(url, { params });
  }

  async postWarehouseProductBaseConfig(data) {
    const url = `${this.url}/api/depot/v1/warehouse-type/product-base-config`;
    return await api.post(url, data);
  }

  async deleteWarehouseProductBaseConfig(productBaseId) {
    const url = `${this.url}/api/depot/v1/warehouse-type/product-base-config/${productBaseId}`;
    return await api.delete(url);
  }
}

const depotService = new DepotService();
export default depotService;
