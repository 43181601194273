import { useCallback, useEffect, useState } from "react";
import { useDebaunce } from "utils";

export const useTableParams = ({
  service,
  paramsDefault,
  fetchOnlyOnSearch = false
}) => {
  const [status, setStatus] = useState("pending");
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    search: undefined,
    ...paramsDefault
  });
  const [count, setCount] = useState(0);
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 400 });

  function handleSearch(e) {
    setParams(state => ({ ...state, page: 1, search: e.target.value?.trim() }));
  }

  const onChange = newParams => {
    setParams(state => ({ ...state, ...newParams }));
  };
  const debaunceChange = useDebaunce({ fn: onChange, delay: 300 });
  const run = useCallback(async () => {
    setErrorMessage("");
    if (fetchOnlyOnSearch && !params.search) {
      setStatus("success");
      setData([]);
      return;
    }
    try {
      setStatus("pending");
      const { data } = await service({
        ...params,
        pageIndex: params.page,
        orderBy: params.orderDirection
      });
      setData(data.results);
      setTotalPages(data.pageSize);
      setCount(data?.total_count || data?.total || data?.totalCount);
      setStatus("success");
    } catch (err) {
      setErrorMessage(err?.response?.data?.msg);
      setStatus("error");
    }
  }, [service, params]);

  useEffect(() => {
    run();
  }, [params]);

  return {
    data,
    setData,
    params,
    onChange: debaunceChange,
    status,
    count,
    totalPages,
    refresh: run,
    handleSearch: debaunceSearch,
    errorMessage
  };
};
