import { Button, TextField, Typography } from "@material-ui/core";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";

import {
  ButtonsContent,
  ClassificationContainer,
  ClassificationInputContent
} from "../../style";

const validationSchema = Yup.object().shape({
  quantity: Yup.number()
    .typeError("Deve ser um número")
    .integer("Deve ser um número inteiro")
    .nullable()
});

export const PrintTag = ({ handleSubmit }) => {
  const initialValues = {
    quantity: ""
  };
  return (
    <ClassificationContainer>
      <Typography>Insira a quantidade de etiquetas</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
        >
          <Field
            as={TextField}
            fullWidth
            name="quantity"
            type="number"
            variant="outlined"
            label="Quantidade"
          />
          <ErrorMessage
            name="quantity"
            component="div"
            style={{ color: "red" }}
          />
          <ButtonsContent>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              style={{ textTransform: "none" }}
            >
              Finalizar e imprimir
            </Button>
          </ButtonsContent>
        </Form>
      </Formik>
    </ClassificationContainer>
  );
};
