import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class KanbanService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getReceipt(params, signal) {
    const url = `${this.url}/api/blueberry/v1/warehouse/kanban/receipt`;
    return await api.get(url, { params }, { signal });
  }

  async getBlitz(params, signal) {
    const url = `${this.url}/api/blueberry/v1/warehouse/kanban/blitz`;
    return await api.get(url, { params }, { signal });
  }

  async getSelection(params, signal) {
    const url = `${this.url}/api/blueberry/v1/warehouse/kanban/selection`;
    return await api.get(url, { params }, { signal });
  }

  async getDevolution(params, signal) {
    const url = `${this.url}/api/blueberry/v1/warehouse/kanban/devolution`;
    return await api.get(url, { params }, { signal });
  }

  async getDevolutionNF(params, signal) {
    const url = `${this.url}/api/blueberry/v1/warehouse/kanban/devolution/nf`;
    return await api.get(url, { params }, { signal });
  }

  async getDevolutionReposition(params, signal) {
    const url = `${this.url}/api/blueberry/v1/warehouse/kanban/devolution/reposition`;
    return await api.get(url, { params }, { signal });
  }

  async getUnloading(params, signal) {
    const url = `${this.url}/api/blueberry/v1/warehouse/kanban/unloading`;
    return await api.get(url, { params }, { signal });
  }

  async getSelectionGroup(params, signal) {
    const url = `${this.url}/api/blueberry/v1/warehouse/kanban/selection-group`;
    return await api.get(url, { params }, { signal });
  }
}

const kanbanService = new KanbanService();
export default kanbanService;
