import { ESTOQUE_BACKEND_URL, MS_API_BACKEND_URL } from "../settings";
import { api } from "./api";

class ProductBaseService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
    this.urlAwsProduct = MS_API_BACKEND_URL;
  }

  async list({ pageSize, page, productBaseId, orderBy, search }) {
    const url = `${this.url}/api/products/v1/product-base`;
    return await api.get(url, {
      params: { pageSize, page, productBaseId, orderBy, search }
    });
  }

  async postProductBase(form) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/`;
    return await api.post(url, form);
  }

  async updateProductBase(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/${data.id}`;
    return await api.put(url, data);
  }

  async postProductSample(form) {
    const url = `${this.urlAwsProduct}/api/products/v1/product-sample`;
    return await api.post(url, form);
  }

  async getProductBase(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/`;
    return await api.get(url, { params });
  }

  async getProductBaseId(id) {
    const url = `${this.url}/api/products/v1/product-base`;
    return await api.get(url, {
      params: {
        productBaseId: id
      }
    });
  }

  async updateImageProductBase({ image, id }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/${id}/image`;
    return await api.put(url, { file: image });
  }

  async getProductBaseById(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/${id}`;
    return await api.get(url);
  }
}

const productBaseService = new ProductBaseService();
export default productBaseService;
