import { mapPurchaseType } from "utils";

const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString() ?? "";
  const time = dateToFormat?.toLocaleTimeString() ?? "";
  return {
    date,
    time
  };
};

const getSelectionStepName = selectionStep => {
  if (selectionStep === 0) {
    return "Waste seleção";
  }

  if (selectionStep === 1) {
    return "Classificar seleção";
  }

  if (selectionStep === 2) {
    return "Lote selecionado";
  }

  return "Não selecionado";
};

const batches = data => {
  return data?.reduce((acc, batch) => {
    const formatted = {
      id: batch.id,
      createdDate: formatDateTime(batch?.created).date,
      createdTime: formatDateTime(batch?.created).time,
      seller: batch.seller?.name,
      product: batch.product?.name,
      stage: batch.stage?.name,
      status: batch.status?.name,
      receiptDate: formatDateTime(batch.receipt_date).date,
      receiptTime: formatDateTime(batch.receipt_date).time,
      quantity: batch.quantity,
      availableQuantity: batch.available_quantity,
      blockQuantity: batch.block_quantity
    };
    return [...acc, formatted];
  }, []);
};

const batchHistory = historical => {
  const formattedHistory = historical?.reduce((acc, historyItem) => {
    const purchaseType = mapPurchaseType(historyItem.product?.purchase_type);
    const formatQuantity = quantity =>
      `${quantity.toLocaleString()} ${purchaseType}`;

    const formattedHistoryItem = {
      id: historyItem?.id,
      createdDate: formatDateTime(historyItem.created).date,
      createdTime: formatDateTime(historyItem.created).time,
      createdBy: historyItem.created_by?.username,
      averageUnitWeight: historyItem.average_unit_weight,
      batchId: historyItem.batch,
      productName: historyItem.product?.name,
      quantity: historyItem.quantity && formatQuantity(historyItem.quantity),
      boxQuantity: historyItem.box_quantity,
      sampleQuantity:
        historyItem.sample_quantity &&
        formatQuantity(historyItem.sample_quantity),
      sampleLowQuantity:
        historyItem.sample_low_quantity &&
        formatQuantity(historyItem.sample_low_quantity),
      receiptDate: formatDateTime(historyItem.receipt_date).date,
      receiptTime: formatDateTime(historyItem.receipt_date).time,
      seller: historyItem.seller?.name,
      shelfLife: historyItem.shelf_life,
      stage: historyItem.stage?.name,
      stageId: historyItem.stage?.id,
      status: historyItem.status?.name,
      availableQuantity: formatQuantity(historyItem.available_quantity ?? 0),
      editReason: historyItem.edit_reason,
      classification: historyItem.classification,
      selectionStep: getSelectionStepName(historyItem.selection_step),
      blitzStep: historyItem.blitz_step
    };
    return [...acc, formattedHistoryItem];
  }, []);
  return formattedHistory.sort((a, b) => a.id - b.id);
};

const batchDetail = batch => {
  if (!batch) {
    return {};
  }
  const formattedCreated = formatDateTime(batch?.created);
  const formattedUpdated = formatDateTime(batch?.updated);
  const formattedReceipt = formatDateTime(batch?.receipt_date);
  const formattedBlitz = formatDateTime(batch?.quality_blitz_date);
  const formattedUnloading = formatDateTime(batch?.unloading_date);
  const formattedCreatedDateTime = `${formattedCreated.date} ${formattedCreated.time}`;
  const formattedUpdatedDateTime = `${formattedUpdated.date} ${formattedUpdated.time}`;
  const formattedReceiptDateTime = `${formattedReceipt?.date} ${formattedReceipt?.time}`;
  const formattedBlitzDateTime = `${formattedBlitz.date} ${formattedBlitz.time}`;
  const formattedUnloadingDateTime = `${formattedUnloading.date} ${formattedUnloading.time}`;

  const purchaseType = mapPurchaseType(batch.product?.purchase_type);
  const formatQuantity = quantity =>
    `${quantity.toLocaleString()} ${purchaseType}`;

  const getClassifiedQuantity = () => {
    const classifiedQty = batch.sub_batch.reduce((total, subBatch) => {
      return total + subBatch.quantity;
    }, 0);

    return classifiedQty;
  };

  const refusalPercentage = batch.refusal_percentage
    ? `${batch.refusal_percentage * 100}%`
    : "--";
  return {
    id: batch.id,
    createdDate: formattedCreated.date,
    createdTime: formattedCreated.time,
    updatedDate: formattedUpdated.date,
    updatedTime: formattedUpdated.time,
    createdDateTime: formattedCreatedDateTime,
    updatedDateTime: formattedUpdatedDateTime,
    seller: batch.seller?.name,
    productId: batch.product?.id,
    product: batch.product?.name,
    quantity: batch.quantity && formatQuantity(batch.quantity),
    quantityForecast:
      batch.purchase_item.quantity &&
      formatQuantity(batch.purchase_item.quantity),
    boxQuantity: batch.box_quantity,
    unclassifiedQty: batch.unclassified_qty,
    formattedUnclassifiedQty: formatQuantity(batch.unclassified_qty ?? 0),
    availableQuantity: formatQuantity(batch.available_quantity ?? 0),
    availableQuantityValue: batch.available_quantity ?? 0,
    sampleQuantity:
      batch.sample_quantity && formatQuantity(batch.sample_quantity),
    sampleLowQuantity:
      batch.sample_low_quantity && formatQuantity(batch.sample_low_quantity),
    averageUnitWeight: batch.average_unit_weight || "Não informado",
    shelfLife: batch.shelf_life || "Não informado",
    stage: batch.stage?.name,
    status: batch.status?.name,
    receiptDate: formattedReceipt.date,
    receiptTime: formattedReceipt.time,
    receiptDateTime: formattedReceiptDateTime,
    historical: batchHistory(batch.history),
    purchaseType,
    subBatch: batch.sub_batch.sort((prevSubBatch, nextSubBatch) =>
      prevSubBatch.classification.localeCompare(nextSubBatch.classification)
    ),
    createdBy: batch.created_by?.username,
    updatedBy: batch.updated_by?.username,
    classification: batch.classification || "Não informado",
    blockQuantity: batch.block_quantity,
    purchaseItemId: batch?.purchase_item?.reason,
    combinedDevolutionId:
      batch.purchase_item?.purchase?.refusal_instructions?.[0],
    classifiedQuantity: getClassifiedQuantity(),
    blitzPercentage: batch.blitz_percentage,
    receiptBy: batch.receipt_by?.username,
    blitzBy: batch.quality_blitz_by?.username,
    unloadingBy: batch.unloading_by?.username,
    blitzDateTime: formattedBlitzDateTime,
    unloadingDateTime: formattedUnloadingDateTime,
    refusalPercentage: refusalPercentage
  };
};

const batchWaste = wasteData => {
  return (wasteData || []).map(waste => {
    const { date, time } = formatDateTime(waste.created);
    return {
      ...waste,
      createdDateTime: `${date} ${time}`,
      createdBy: waste.created_by?.username,
      wasteType: waste.stage?.name
    };
  });
};

export const formatData = {
  batches,
  batchDetail,
  batchWaste
};
