import { Typography, styled } from "@material-ui/core";

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: "#757575",
  marginBlock: "2.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem"
  }
}));

export const TitlePage = ({ children, ...rest }) => {
  return (
    <StyledTypography variant="h1" {...rest}>
      {children}
    </StyledTypography>
  );
};
