import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export const StatusFilter = ({ selectedValue, handleChange }) => {
  return (
    <FormControl variant="outlined" style={{ minWidth: "10rem" }}>
      <InputLabel id="status-label">Status</InputLabel>
      <Select
        labelId="status-label"
        value={selectedValue}
        onChange={handleChange}
        label="Status"
      >
        <MenuItem value={null}>
          <em>Todos</em>
        </MenuItem>
        <MenuItem value={1}>A receber</MenuItem>
        <MenuItem value={2}>Recebido</MenuItem>
        <MenuItem value={4}>Recusado</MenuItem>
        <MenuItem value={5}>Não trouxe</MenuItem>
      </Select>
    </FormControl>
  );
};
