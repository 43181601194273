import { Box, Grid, InputAdornment, TextField } from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { Dialog } from "shared";
import { reasonMap } from "utils";

export const DialogEdit = ({
  data,
  open,
  handleSubmit,
  handleClose,
  refresh
}) => {
  const { addPopup } = usePopup();
  const [isBtnDisabled, setBtn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);

  const handleClear = () => {
    handleClose({
      isOpen: false,
      data: {}
    });
  };

  const handleQty = value => {
    if (value === null) return;
    setQuantity(value);
  };

  const onSubmitHandler = async e => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await handleSubmit({
        id: data.id,
        quantity
      });

      addPopup({
        type: "success",
        title: "Registro corrigido com sucesso."
      });

      refresh();
      handleClear();
    } catch (error) {
      if (error.response.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao corrigir registro."
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setQuantity(data?.quantity);
  }, [data]);

  useEffect(() => {
    quantity !== "" ? setBtn(false) : setBtn(true);
  }, [quantity]);

  return (
    <Dialog open={open} handleClose={handleClose} title="Corrigir registro">
      <Box component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Produto Base"
              value={data?.product_base?.name}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Fornecedor"
              value={data?.seller?.name}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Motivo"
              value={reasonMap(data?.reason?.reason)}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Tipo de Estoque"
              value={data?.type?.name}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              onChange={event => {
                handleQty(event.target.value);
              }}
              type="number"
              label="Movimentação"
              autoFocus
              value={quantity}
              autoComplete="off"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {data?.storageTypeName === "quilo" ? "Kg" : "Un"}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid container item justifyContent="flex-end">
            <Button
              disabled={isBtnDisabled}
              isLoading={isLoading}
              type="submit"
              variant="contained"
              size="large"
              onClick={onSubmitHandler}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
