import { alpha, darken } from "@material-ui/core";

const Button = theme => {
  return {
    MuiButtonBase: {
      root: {
        textTransform: "capitalize",
        fontSize: "1rem",
        padding: "8px",
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.1)
        }
      },
      contained: {
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: darken(theme.palette.primary.main, 0.1)
        },
        "& span": {
          color: "#ffffff"
        },
        "&:disabled": {
          backgroundColor: "rgba(0, 0, 0, 20%)"
        }
      }
    },
    MuiButton: {
      root: {
        color: theme.palette.primary.main,
        textTransform: "capitalize",
        fontSize: "1rem",
        padding: "8px",
        borderRadius: "4px",
        boxShadow: "none",
        "&:hover": {
          boxShadow: theme.shadows[1],
          backgroundColor: alpha(theme.palette.primary.main, 0.2)
        }
      },
      contained: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
        "&:hover": {
          backgroundColor: darken(theme.palette.primary.main, 0.1),
          boxShadow: theme.shadows[1]
        },
        "& span": {
          color: "#ffffff"
        },
        "&:disabled": {
          backgroundColor: "rgba(0, 0, 0, 20%)"
        }
      },
      outlined: {
        color: theme.palette.primary.main,
        "&:hover": {
          background: alpha(theme.palette.primary.main, 0.1)
        }
      }
    }
  };
};

export default Button;
