import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  styled
} from "@material-ui/core";
import { TableRow } from "shared";

const starColor = {
  1: "#DBA120",
  2: "#9A9A9A",
  3: "#BF5B00"
};

export const Content = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "2.5rem 1rem"
}));

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    justifyContent: "center"
  }
}));

export const RankingTableContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
  marginTop: "2rem"
}));

export const RankingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  marginTop: "2rem"
}));

export const RankingContent = styled(Paper)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  padding: "1.5rem 2rem",
  border: "1px solid #89BD23",
  borderRadius: "0.5rem",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "0.5rem"
  }
}));
export const PositionContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  color: "#353535",
  fontFamily: "Roboto",
  fontSize: "2rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "135%",
  flex: 1
}));

export const PositionIcon = styled(Box)(({ theme, position }) => ({
  color: starColor[position] || "rgba(0, 0, 0, 0.0)"
}));

export const SellerContent = styled(Box)(({ theme }) => ({
  display: "flex",

  fontFamily: "Roboto",
  fontSize: "1.5rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#353535",

  [theme.breakpoints.down("sm")]: {
    flex: 1
  }
}));

export const MetricsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    justifyContent: "start"
  }
}));

export const MetricDescription = styled(Box)(({ theme }) => ({
  display: "flex",
  color: "#757575",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "140%"
}));

export const MetricValue = styled(Box)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#353535"
}));

export const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: "0 0.5rem"
});

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  "& .MuiTableRow-root": {
    marginTop: "20rem"
  }
}));
export const TableRowBody = styled(TableRow)(({ theme }) => ({
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  borderBottom: "none",
  border: "none"
}));

export const TableHeadCell = styled(TableCell)(({ theme, isSeller }) => ({
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#353535",
  textAlign: isSeller ? "left" : "center"
}));

export const TableCellPosition = styled(TableCell)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#353535"
}));

export const TableCellSeller = styled(TableCell)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#353535"
}));

export const TableCellValue = styled(TableCell)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#353535",
  textAlign: "center"
}));

export const TableCellIcon = styled(TableCell)(({ theme, position }) => ({
  color: starColor[position] || "rgba(0, 0, 0, 0.0)"
}));
