import * as Yup from "yup";

const validationDefault = {
  name: Yup.string().required("Nome é obrigatório"),
  shelfLifeA: Yup.number()
    .typeError("Validade A é obrigatório")
    .required("Validade A é obrigatório"),
  shelfLifeB: Yup.number()
    .typeError("Validade B é obrigatório")
    .required("Validade B é obrigatório"),
  shelfLifeC: Yup.number()
    .typeError("Validade C é obrigatório")
    .required("Validade C é obrigatório"),
  purchaseTypeId: Yup.number()
    .typeError("Tipo de estoque obrigatório")
    .required("Tipo de estoque obrigatório"),
  category: Yup.number()
    .typeError("Categoria é obrigatório")
    .required("Categoria é obrigatório"),
  subCategory: Yup.number()
    .typeError("Subcategoria é obrigatório")
    .required("Subcategoria é obrigatório"),
  status: Yup.string()
    .typeError("Status é obrigatório")
    .required("Status é obrigatório"),
  base64: Yup.string()
    .typeError("Image obrigatória")
    .required("Image obrigatória"),
  margin: Yup.number()
    .typeError("Margem é obrigatório")
    .required("Margem é obrigatório")
    .min(0, "Margem não pode ser menor que 0")
    .max(1, "Margem não pode ser maior que 1")
};

export const validationSchemaEdit = Yup.object().shape({
  ...validationDefault
});

export const validationSchemaCreate = Yup.object().shape(validationDefault);
