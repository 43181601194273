import {
  Category,
  ShoppingBasket,
  ViewModuleRounded
} from "@material-ui/icons";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
export const cards = [
  {
    title: "Níveis de sensibilidade",
    description: "Configurar nível de sensibilidade dos armazéns",
    icon: PermDataSettingIcon,
    path: "/app/config/niveis-sensibilidade"
  },
  {
    title: "Configurar PBs",
    description: "Configurar produtos base",
    icon: Category,
    path: "/app/configurar-produtos-base"
  },
  {
    title: "Embalagens",
    description: "Configurar embalagens",
    icon: ShoppingBasket,
    path: "/app/embalagens"
  },
  {
    title: "Armazéns",
    description: "Configurar Armazéns",
    icon: ViewModuleRounded,
    path: "/app/armazens"
  }
];
