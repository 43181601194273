import { Typography, Box, TextField } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button } from "components";
import { Select } from "shared";
import { mapPurchaseType } from "utils";

export const EditSubbatch = ({ formik, subbatch, action, onBack }) => {
  const selectData = [
    {
      value: "selecione",
      label: "Selecione",
      disabled: true
    },
    ...subbatch.othersSubBatches
      .sort((a, b) => a.classification.localeCompare(b.classification))
      .map(({ id, classification }) => ({
        value: id,
        label: `Sublote ${classification}`
      }))
  ];

  const quantityText =
    {
      subBatchToSubBatch: "Qual quantidade deseja mover?",
      subBatchToWaste: "Qual quantidade de produto de waste?",
      subBatchToPrePicking: "Qual quantidade de produto que será movida?",
      subBatchToReadyBox: "Qual quantidade de produto que será movida?"
    }[action] ?? "";

  return (
    <>
      {["subBatchToSubBatch"].includes(action) && (
        <Box
          display="flex"
          flexDirection="column"
          style={{ gap: "1rem" }}
          marginTop="1rem"
        >
          <Typography style={{ color: "#757575" }}>
            Qual o sublote de destino?
          </Typography>

          <Select
            style={{ minWidth: "200px", width: "50%" }}
            formik={formik}
            name="destination"
            selectData={selectData}
          />
        </Box>
      )}

      {["subBatchToPrePicking", "subBatchToReadyBox"].includes(action) && (
        <Box
          display="flex"
          flexDirection="column"
          style={{ gap: "1rem" }}
          marginTop="1rem"
        >
          <Typography style={{ color: "#757575" }}>
            Quantas caixas serão movidas?
          </Typography>

          <TextField
            style={{ minWidth: "200px", width: "50%" }}
            variant="outlined"
            placeholder="Un"
            name="boxQuantity"
            type="number"
            value={formik.values?.boxQuantity ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched?.boxQuantity || formik.submitCount > 0) &&
              !!formik.errors?.boxQuantity
            }
            helperText={
              (formik.touched?.boxQuantity || formik.submitCount > 0) &&
              formik.errors?.boxQuantity
            }
          />
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="column"
        style={{ gap: "1rem" }}
        marginTop="1rem"
      >
        <Typography style={{ color: "#757575" }}>{quantityText}</Typography>

        <TextField
          style={{ minWidth: "200px", width: "50%" }}
          variant="outlined"
          placeholder={mapPurchaseType(subbatch.batch?.product?.purchase_type)}
          type="number"
          name="quantity"
          value={formik.values?.quantity ?? ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            (formik.touched?.quantity || formik.submitCount > 0) &&
            !!formik.errors?.quantity
          }
          helperText={
            (formik.touched?.quantity || formik.submitCount > 0) &&
            formik.errors?.quantity
          }
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        flex="1"
        style={{ gap: "1rem" }}
      >
        <Button
          fullWidth
          onClick={onBack}
          startIcon={<NavigateBeforeIcon fontSize="large" />}
        >
          Voltar
        </Button>
        <Button
          fullWidth
          variant="contained"
          endIcon={<NavigateNextIcon fontSize="large" />}
          type="submit"
          disabled={!action}
        >
          Avançar
        </Button>
      </Box>
    </>
  );
};
