import { ReceiptPageContent, SearchContent, SearchInput } from "../../style";
import { ReceiptAccordion } from "../ReceiptAccordion";

export const PendingPage = ({
  debaunceSearch,
  pendingData,
  handleOpenSteps,
  activeTab,
  dataLoading,
  setOpenUndeliveredBatchPopup,
  handleOpenEditTaxEntryPopUp
}) => {
  return (
    <ReceiptPageContent>
      <SearchContent>
        <SearchInput
          placeholder="Seller ou produto"
          onChange={debaunceSearch}
        />
      </SearchContent>

      <ReceiptAccordion
        sellerBatchData={pendingData}
        handleOpenSteps={handleOpenSteps}
        activeTab={activeTab}
        dataLoading={dataLoading}
        setOpenUndeliveredBatchPopup={setOpenUndeliveredBatchPopup}
        countBatchesToBlitz
        handleOpenEditTaxEntryPopUp={handleOpenEditTaxEntryPopUp}
      />
    </ReceiptPageContent>
  );
};
