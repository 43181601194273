import { Box, TextField, Typography } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import printService from "services/printService";

import { Button } from "../style";
import { InfoContainer, InfoText } from "./style";

export const TagView = ({ newBatch, handleClose }) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(null);

  const handlePrint = async () => {
    try {
      setIsLoading(true);
      await printService.printBatchTag(newBatch.sub_batch[0].id, {
        qty: Number(quantity),
        isReceipt: true
      });
      addPopup({ type: "success", title: "Etiqueta imprimida com sucesso" });
      handleClose();
    } catch {
      addPopup({ type: "error", title: "Erro ao imprimir etiqueta" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = ({ target: { value } }) => {
    if (value < 0) return;
    setQuantity(value);
  };

  return (
    <>
      <InfoContainer style={{ width: "100%", marginBottom: "1rem" }}>
        <InfoText style={{ marginBottom: "1.25rem" }}>
          Novo lote: <strong>{newBatch.id}</strong>
        </InfoText>
      </InfoContainer>
      <Typography style={{ color: "#757575", marginBlock: "0.6rem" }}>
        Quantas etiquetas gostaria de imprimir?
      </Typography>

      <TextField variant="outlined" value={quantity} onChange={handleChange} />
      <Box
        display="flex"
        alignItems="flex-end"
        flex="1"
        style={{ gap: "1rem" }}
      >
        <Button
          variant="contained"
          flex="1"
          disabled={!quantity}
          onClick={handlePrint}
          isLoading={isLoading}
        >
          Imprimir
        </Button>
      </Box>
    </>
  );
};
