import { Box, Chip, Tooltip } from "@material-ui/core";
import { Info } from "components";
import { Dialog } from "shared";
import { convertDate, typeMapping } from "utils";

import { useStyles } from "./styles";

export const DialogInformations = ({ isOpen, setIsOpen, data }) => {
  const classes = useStyles();

  const handleClose = () => {
    setIsOpen(state => ({ ...state, isOpen: false }));
    setTimeout(() => setIsOpen({ data: {}, isOpen: false }), 100);
  };

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title="Informações do fornecedor"
    >
      <Box display="flex" flexWrap="wrap" style={{ gap: "1rem" }} mb="1rem">
        <Info title="Fornecedor" description={data?.seller?.name} />
        <Info title="Produto" description={data?.product?.name} />
        <Info title="Tipo" description={typeMapping(data?.portion_type)} />
        <Info title="Quantidade" description={data?.portion} />
        <Info
          title="Quantidade por caixa"
          description={data?.portion_per_box}
        />
        <Info
          title="Fator de conversão"
          description={data?.conversion_factor}
        />
        <Info title="Status" description={data?.status} />
        <Info title="Status de compra" description={data?.order_status} />
        <Info
          title="Horário de entrega"
          description={data?.window_delivery_time}
        />
        <Info
          title="Criado em"
          description={data?.created && convertDate(new Date(data?.created))}
        />
        <Info
          title="Atualizado em"
          description={data?.updated && convertDate(new Date(data?.updated))}
        />
        <Info title="Dias de entrega" full>
          {!!data?.monday && (
            <Tooltip
              title={data?.monday === 1 ? "Fornecedor" : "Ceasa"}
              arrow
              placement="top"
            >
              <Chip
                label="Segunda"
                className={
                  data?.monday === 1
                    ? classes.chipCheck
                    : classes.chipCheckCeasa
                }
              />
            </Tooltip>
          )}
          {!!data?.tuesday && (
            <Tooltip
              title={data?.tuesday === 1 ? "Fornecedor" : "Ceasa"}
              arrow
              placement="top"
            >
              <Chip
                label="Terça"
                className={
                  data?.tuesday === 1
                    ? classes.chipCheck
                    : classes.chipCheckCeasa
                }
              />
            </Tooltip>
          )}
          {!!data?.wednesday && (
            <Tooltip
              title={data?.wednesday === 1 ? "Fornecedor" : "Ceasa"}
              arrow
              placement="top"
            >
              <Chip
                label="Quarta"
                className={
                  data?.wednesday === 1
                    ? classes.chipCheck
                    : classes.chipCheckCeasa
                }
              />
            </Tooltip>
          )}
          {!!data?.thursday && (
            <Tooltip
              title={data?.thursday === 1 ? "Fornecedor" : "Ceasa"}
              arrow
              placement="top"
            >
              <Chip
                label="Quinta"
                className={
                  data?.thursday === 1
                    ? classes.chipCheck
                    : classes.chipCheckCeasa
                }
              />
            </Tooltip>
          )}
          {!!data?.friday && (
            <Tooltip
              title={data?.friday === 1 ? "Fornecedor" : "Ceasa"}
              arrow
              placement="top"
            >
              <Chip
                label="Sexta"
                className={
                  data?.friday === 1
                    ? classes.chipCheck
                    : classes.chipCheckCeasa
                }
              />
            </Tooltip>
          )}
          {!!data?.saturday && (
            <Tooltip
              title={data?.saturday === 1 ? "Fornecedor" : "Ceasa"}
              arrow
              placement="top"
            >
              <Chip
                label="Sábado"
                className={
                  data?.saturday === 1
                    ? classes.chipCheck
                    : classes.chipCheckCeasa
                }
              />
            </Tooltip>
          )}
          {!!data?.sunday && (
            <Tooltip
              title={data?.sunday === 1 ? "Fornecedor" : "Ceasa"}
              arrow
              placement="top"
            >
              <Chip
                label="Domingo"
                className={
                  data?.sunday === 1
                    ? classes.chipCheck
                    : classes.chipCheckCeasa
                }
              />
            </Tooltip>
          )}
        </Info>
        {data?.description && (
          <Info title="Descrição" description={data?.description} />
        )}
      </Box>
    </Dialog>
  );
};
