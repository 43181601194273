export const generateBatchExternalCode = batchData => {
  const classification = batchData.classification
    ? `-${batchData.classification}`
    : "";
  const productBaseId = batchData.product?.id;
  const sellerId = batchData.seller?.id;
  const receiptDate = formatDate(batchData.receipt_date);
  const batchExternalCode = `S-${productBaseId}-${sellerId}-${receiptDate}${classification}`;
  return batchExternalCode;
};

function formatDate(dataString) {
  const date = new Date(dataString);
  const year = String(date.getFullYear() % 100).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}${month}${year}`;
}
