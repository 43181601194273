import {
  AccordionSummary,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

import {
  StyledMainAccordion,
  StyledAccordionDetails,
  StyledInternalAccordion,
  ChipStatus
} from "../../style";

const BalanceAccordion = ({ sellerData }) => {
  const sortedSellerData = sellerData.sort((a, b) =>
    a.sellerName.localeCompare(b.sellerName)
  );

  return (
    <div>
      {sortedSellerData.map(seller => {
        const negativeAssetCount = seller.assets.filter(
          asset => asset.balance < 0
        ).length;

        const sortedAssets = seller.assets.sort((a, b) => {
          if (a.balance < 0 && b.balance >= 0) return -1;
          if (a.balance >= 0 && b.balance < 0) return 1;
          return a.balance - b.balance;
        });

        return (
          <StyledMainAccordion key={seller.sellerId}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-content-${seller.sellerId}`}
              id={`panel-header-${seller.sellerId}`}
            >
              <Box display="flex" alignItems="center" width="100%">
                <Typography>
                  {seller.sellerCode
                    ? `${seller.sellerCode} - ${seller.sellerName}`
                    : seller.sellerName}
                </Typography>
                {negativeAssetCount > 0 && (
                  <Box ml="auto" position="relative">
                    <ChipStatus label={negativeAssetCount} />
                  </Box>
                )}
              </Box>
            </AccordionSummary>
            <StyledAccordionDetails>
              <Box display="flex" flexDirection="column" width="100%">
                {sortedAssets.map(
                  asset =>
                    asset.balance !== 0 && (
                      <StyledInternalAccordion
                        key={asset.id}
                        assetBalance={asset.balance}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel-content-${asset.id}`}
                          id={`panel-header-${asset.id}`}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Typography>{asset.assetName}</Typography>
                            <Typography>Saldo: {asset.balance}</Typography>
                          </Box>
                        </AccordionSummary>
                        <StyledAccordionDetails assetBalance={asset.balance}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                          >
                            <Typography variant="subtitle2">
                              Últimas Transações
                            </Typography>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Data</TableCell>
                                  <TableCell>Transação</TableCell>
                                  <TableCell>Quantidade</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {asset.lastFiveTransactions.map(transaction => (
                                  <TableRow key={transaction.id}>
                                    <TableCell>
                                      {new Date(
                                        transaction.created
                                      ).toLocaleString()}
                                    </TableCell>
                                    <TableCell>{transaction.id}</TableCell>
                                    <TableCell>
                                      {transaction.quantity}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </StyledAccordionDetails>
                      </StyledInternalAccordion>
                    )
                )}
              </Box>
            </StyledAccordionDetails>
          </StyledMainAccordion>
        );
      })}
    </div>
  );
};

export default BalanceAccordion;
