const Popover = theme => {
  return {
    MuiPopover: {
      paper: {
        boxShadow: theme.shadows[2]
      }
    }
  };
};

export default Popover;
