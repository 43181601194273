import * as Yup from "yup";

const validationDefault = {
  description: Yup.string().when("shouldGenerateDescription", {
    is: false,
    then: Yup.string()
      .typeError("Descrição é obrigatória")
      .max(60, "Máximo 60 caracteres")
      .required("Descrição é obrigatória")
  }),

  distributionCenter: Yup.number()
    .typeError("Centro de distribuição obrigatório")
    .required("Centro de distribuição obrigatório"),
  portionDescription: Yup.string()
    .typeError("Descrição é obrigatório")
    .required("Descrição é obrigatório"),
  portionName: Yup.string()
    .typeError("Porcionamento é obrigatório")
    .required("Porcionamento é obrigatório"),
  portionType: Yup.number()
    .typeError("Tipo de estoque obrigatório")
    .required("Tipo de estoque obrigatório"),
  status: Yup.number()
    .typeError("Status é obrigatório")
    .required("Status é obrigatório"),
  separationOrder: Yup.number()
    .typeError("Ordem de separação deve ser um número")
    .required("Ordem de separação é obrigatório")
    .positive("Ordem de separação não deve ser negativo")
    .integer("Ordem de separação deve ser um número inteiro"),
  margin: Yup.number()
    .typeError("Margem é obrigatório")
    .required("Margem é obrigatório"),
  preBoxed: Yup.boolean(),
  base64: Yup.string()
    .typeError("Imagem obrigatória")
    .required("Imagem obrigatória")
};

export const validationSchemaEdit = Yup.object().shape({
  name: Yup.string()
    .typeError("Nome é obrigatório")
    .required("Nome é obrigatório"),
  ...validationDefault
});

export const validationSchemaCreate = Yup.object().shape({
  productBaseId: Yup.object()
    .typeError("Produto base obrigatório")
    .required("Produto base obrigatório"),
  purchaseValue: Yup.number()
    .positive("Peso por unidade não deve ser negativo")
    .typeError("Peso por unidade deve ser um número")
    .required("Peso por unidade é obrigatório"),
  ...validationDefault
});
