import {
  makeStyles,
  createStyles,
  styled,
  FormControl,
  Box
} from "@material-ui/core";

export const useStyles = makeStyles(theme =>
  createStyles({
    boxAlign: {
      display: "flex",
      gap: "1rem",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.between(0, 540)]: {
        flexDirection: "column",
        marginBottom: "1rem"
      }
    },
    filter: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: "1rem",
      color: "#757575",
      fontSize: "1rem",
      cursor: "pointer"
    },
    boxPermissions: {
      padding: "1rem"
    },
    search: {
      width: "21rem",
      marginLeft: "2rem",
      [theme.breakpoints.between(0, 540)]: {
        width: "92vw",
        marginTop: "1rem",
        marginLeft: "auto"
      }
    },
    noOperator: {
      position: "absolute",
      top: "45%",
      left: "50%",
      transform: "translateX(-50%)",
      color: "#757575",
      fontSize: "1.2rem",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "1rem"
    },
    addFloatButton: {
      bottom: "2.5rem",
      right: "2.5rem",
      position: "fixed"
    },
    addButton: {
      background: "#89BD23",
      "&:hover": {
        backgroundColor: "#90a955"
      }
    },
    iconButton: {
      color: "#ffffff"
    }
  })
);

export const FormFilterStyle = styled(FormControl)(({ theme }) => ({
  maxWidth: "initial",
  [theme.breakpoints.up("sm")]: {
    width: "45%",
    maxWidth: "300px"
  }
}));

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});
