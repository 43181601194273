import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptBR from "date-fns/locale/pt-BR";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "theme";

import AppProvider from "./hooks";
import Routes from "./routes";

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <AppProvider>
            <Routes />
          </AppProvider>
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
