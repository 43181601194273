import { Box } from "@material-ui/core";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { Button } from "components";

import {
  CardInfoContainer,
  CardInfoContent,
  CardTitle,
  CardValue,
  RevertWasteContent
} from "./style";

export const CardsReverseWaste = ({ data, handleRevert }) => {
  return (
    <>
      {data.length ? (
        data.map(waste => (
          <RevertWasteContent key={waste.id} elevation={3}>
            <CardInfoContainer>
              <CardInfoContent>
                <CardTitle>Data do waste:</CardTitle>
                <CardValue>{waste.createdDateTime}</CardValue>
              </CardInfoContent>

              <CardInfoContent>
                <CardTitle>Criado por:</CardTitle>
                <CardValue>{waste.createdBy}</CardValue>
              </CardInfoContent>
            </CardInfoContainer>

            <CardInfoContainer>
              <CardInfoContent>
                <CardTitle>Quantidade:</CardTitle>
                <CardValue>{waste.quantity}</CardValue>
              </CardInfoContent>

              <CardInfoContent>
                <CardTitle>Tipo do waste:</CardTitle>
                <CardValue>{waste.wasteType}</CardValue>
              </CardInfoContent>
            </CardInfoContainer>
            <Button
              fullWidth
              variant="contained"
              startIcon={<SettingsBackupRestoreIcon />}
              onClick={() => handleRevert(waste.id)}
            >
              Reverter waste
            </Button>
          </RevertWasteContent>
        ))
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CardTitle style={{ fontSize: "1.2rem" }}>
            Ainda não temos nada por aqui...
          </CardTitle>
        </Box>
      )}
    </>
  );
};
