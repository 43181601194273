import { mapPurchaseType } from "utils";

const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return {
    date,
    time
  };
};

const formatBatches = batchSeller => {
  const { batches, id, name } = batchSeller;
  return batches?.reduce((acc, batch) => {
    const formatted = {
      ...batch,
      seller: { id, name },

      purchaseType: mapPurchaseType(batch.product?.purchase_type),
      createdDate: formatDateTime(batch.created).date,
      receiptDate: formatDateTime(batch.receipt_date).date,
      receiptTime: formatDateTime(batch.receipt_date).time
    };
    return [...acc, formatted];
  }, []);
};

export const formatBatchesSeller = batchesSeller => {
  return batchesSeller?.map(batchSeller => ({
    ...batchSeller,
    batches: formatBatches(batchSeller)
  }));
};
