const formatBatch = batchData => {
  const formatted = batchData?.reduce((acc, batch) => {
    const batchInfo = {
      id: batch.id,
      productName: batch.product.name,
      createdDate: new Date(batch.created).toLocaleDateString(),
      receiptDate:
        batch.receipt_date && new Date(batch.receipt_date).toLocaleString(),
      quantity: batch.quantity,
      status: batch.status?.name,
      stage: batch.stage?.name
    };
    return [...acc, batchInfo];
  }, []);
  return formatted;
};

const formatReturnedBatch = batchData => {
  const formatted = batchData?.reduce((acc, batch) => {
    const batchInfo = {
      id: batch.id,
      createdDate: new Date(batch.created).toLocaleDateString(),
      updatedDate: new Date(batch.created).toLocaleDateString(),
      batchId: batch.batch,
      productName: batch.product.name,
      quantity: batch.quantity,
      reason: batch.reason?.name,
      status: batch.status?.name
    };
    return [...acc, batchInfo];
  }, []);
  return formatted;
};

export const formatterData = {
  formatBatch,
  formatReturnedBatch
};
