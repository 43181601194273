import { Box, TextField } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Alert, Button } from "components";
import { usePopup } from "hooks/usePopup";
import batchServiceV3 from "services/batchServiceV3";
import productBaseService from "services/productBaseService";

import {
  ButtonsContent,
  FieldContent,
  StyledBox,
  Text,
  TextInfo
} from "../../style";
import { ConfirmContent } from "./style";

export const InitialPage = ({
  sampleWeight,
  sampleWeightQty,
  handleChangeSampleWeight,
  handleChangeSampleWeightQty,
  averageWeight,
  handleNext,
  setLoading,
  blitzData,
  batchId,
  setActivePage
}) => {
  const { addPopup } = usePopup();
  const average = `${[averageWeight || 0].toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3
  })} Kg/Un`;

  const {
    min_average_weight: minAverageWeight,
    max_average_weight: maxAverageWeight
  } = blitzData;
  const { unit_weight: actualAverageWeight, id: productId } = blitzData.product;
  const hasAverageWeightRule = !!minAverageWeight && !!maxAverageWeight;

  const validateBlitz = async () => {
    try {
      setLoading(true);
      let result = "approved";
      if (hasAverageWeightRule) {
        const isApproved =
          averageWeight >= minAverageWeight &&
          averageWeight <= maxAverageWeight;
        result = isApproved ? "approved" : "rejected";
      }

      const data = { average_unit_weight: averageWeight };

      const action = {
        true: () =>
          batchServiceV3.batchReceipt(batchId, {
            ...data,
            status: 4,
            blitz_step: 2
          }),
        false: async () => {
          await batchServiceV3.batchReceipt(batchId, {
            ...data,
            blitz_step: 3
          });
          await productBaseService.postProductSample({
            productBaseId: productId,
            quantity: sampleWeightQty,
            weight: sampleWeight
          });
        }
      }[result === "rejected"];

      await action();

      result === "approved" ? handleNext() : setActivePage(1);
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao validar blitz",
        description:
          "Não foi possível validar a blitz, tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    const isValidSampleWeight =
      sampleWeight && !isNaN(parseFloat(sampleWeight));
    const isValidSampleQty = !isNaN(parseInt(sampleWeightQty));

    if (!isValidSampleWeight || !isValidSampleQty) {
      return addPopup({
        type: "error",
        title: "Informações obrigatórias",
        description: "Insira uma quantidade válida"
      });
    }

    validateBlitz();
  };

  return (
    <>
      <StyledBox>
        <Alert isSuccess title="Qualidade aprovada" />
        <Text>Pese uma quantidade do produto e preencha os dados abaixo</Text>

        <FieldContent>
          <TextField
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            type="number"
            inputProps={{ type: "number", min: "0", step: "1" }}
            label="Quantas un. você pesou?"
            placeholder="Unidades"
            value={sampleWeightQty}
            onChange={handleChangeSampleWeightQty}
            fullWidth
          />

          <TextField
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            type="number"
            inputProps={{ type: "number", min: "0", step: "1" }}
            placeholder="Kg"
            label="Qual o peso?"
            value={sampleWeight}
            fullWidth
            onChange={handleChangeSampleWeight}
          />
        </FieldContent>
        <StyledBox justifyContent="center" alignItems="center">
          <TextInfo>Peso médio da unidade</TextInfo>
          <ConfirmContent
            style={{ width: "auto", minWidth: "10rem", borderRadius: "1.7rem" }}
          >
            {average && average}
          </ConfirmContent>
        </StyledBox>
      </StyledBox>
      <ButtonsContent>
        <Box display="flex" justifyContent="flex-end">
          <Button
            style={{ width: "50%" }}
            variant="contained"
            color="primary"
            onClick={validate}
            fullWidth
            endIcon={<NavigateNextIcon />}
          >
            Avançar
          </Button>
        </Box>
      </ButtonsContent>
    </>
  );
};
