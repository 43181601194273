import { QrReader } from "react-qr-reader";

export const QRCodeScanner = ({ onScanSuccess, ...rest }) => {
  const handleScan = result => {
    if (!result) return;
    onScanSuccess(result?.text);
  };

  return (
    <QrReader
      delay={300}
      onResult={handleScan}
      constraints={{
        facingMode: "environment"
      }}
      {...rest}
    />
  );
};
