import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    width: "12.5rem",
    background: "#ffffff",
    height: "12.5rem",
    padding: "3.125rem 0.5rem 0",
    borderRadius: "10px",
    boxShadow:
      "0px 0px 2px rgba(175, 179, 180, 0.14), 0px 2px 2px rgba(175, 179, 180, 0.12), 0px 1px 3px rgba(175, 179, 180, 0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  avatar: {
    width: "3.75rem",
    height: "3.75rem"
  },
  name: {
    textTransform: "capitalize",
    fontWeight: 500,
    fontSize: "0.875rem"
  }
});
