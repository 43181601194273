import { Chip, styled } from "@material-ui/core";

export const statusBackgroundColor = {
  1: "#FFF3CD",
  2: "#E5F6EA",
  3: "#E5F6EA",
  4: "#FFDACE",
  5: "#E9DEFE",
  6: "#dcd0fd",
  7: "#FFF3CD",
  8: "#ffbbbb"
};

const statusFontColor = {
  1: "#856404",
  2: "#0A892E",
  3: "#0A892E",
  4: "#D84315",
  5: "#331B7D",
  6: "#8360E9",
  7: "#856404",
  8: "#740202"
};

export const ChipStatus = styled(Chip)(({ statusId }) => ({
  backgroundColor: statusBackgroundColor[statusId] || "#F4F4F4",
  color: statusFontColor[statusId] || "#757575",
  fontWeight: 500,
  fontFamily: "Roboto",
  fontSize: "0.75rem",
  fontStyle: "normal",
  lineHeight: "0.875rem"
}));
