import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class PermissionsService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getPermissions() {
    const url = `${this.url}/api/blueberry/me/`;
    const response = await api.get(url);
    return response;
  }

  async getGroupPermissions() {
    const url = `${this.url}/api/blueberry/v1/warehouse/groups/`;
    const response = await api.get(url);
    return response;
  }

  async updateUserPermissions(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/user/${data.id}`;
    const response = await api.patch(url, data);
    return response;
  }

  async getPermissionsList() {
    const url = `${this.url}/api/blueberry/v1/warehouse/groups/`;
    const response = await api.get(url);
    return response?.data;
  }
}

const permissionsService = new PermissionsService();
export default permissionsService;
