import P from "prop-types";

import { InfoContainer, InfoText } from "./style";

const InfoCard = ({ data, ...rest }) => (
  <InfoContainer style={{ width: "100%" }} {...rest}>
    {Object.entries(data).map(([key, data]) => {
      const children = {
        true: <strong>{data?.text}</strong>,
        false: <span>{data?.text}</span>
      }[!!data.useStrong];

      return (
        <InfoText key={key}>
          {key}: {children}
        </InfoText>
      );
    })}
  </InfoContainer>
);

InfoCard.propTypes = {
  data: P.objectOf(
    P.shape({
      text: P.oneOfType([P.string, P.number]).isRequired,
      useStrong: P.bool
    })
  ).isRequired
};

export default InfoCard;
