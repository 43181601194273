import "react-datepicker/dist/react-datepicker.css";

import { Typography, Button, useTheme } from "@material-ui/core";
import { format, differenceInDays } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import React, { useState, useEffect } from "react";
import { registerLocale } from "react-datepicker";

import { DateFilterContent, StyledDatePicker } from "./style";

registerLocale("ptBR", ptBR);

const DateRangeFilter = ({ onChange, params }) => {
  const [selectedDates, setSelectedDates] = useState([null, null]);

  const handleChange = dates => {
    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      const daysDifference = differenceInDays(endDate, startDate);

      if (daysDifference > (params.rangeQty || 15)) {
        const adjustedEndDate = new Date(startDate);
        adjustedEndDate.setDate(startDate.getDate() + (params.rangeQty || 15));
        setSelectedDates([startDate, adjustedEndDate]);
        const formattedStartDate = format(startDate, "yyyy-MM-dd");
        const formattedEndDate = format(adjustedEndDate, "yyyy-MM-dd");
        onChange({ startDate: formattedStartDate, endDate: formattedEndDate });
        return;
      }

      setSelectedDates(dates);
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");
      onChange({ startDate: formattedStartDate, endDate: formattedEndDate });
    } else {
      setSelectedDates(dates);
    }
  };

  const handleClear = () => {
    setSelectedDates([null, null]);
    onChange({ startDate: null, endDate: null });
  };

  useEffect(() => {
    const [startDate, endDate] = selectedDates;
    setSelectedDates([startDate || new Date(), endDate || new Date()]);
  }, [params.startDate, params.endDate]);

  const theme = useTheme();

  return (
    <DateFilterContent>
      <Typography>Data: </Typography>
      <StyledDatePicker
        selected={selectedDates[0]}
        onChange={handleChange}
        startDate={selectedDates[0]}
        endDate={selectedDates[1]}
        selectsRange
        dateFormat="dd/MM/yyyy"
        locale="ptBR"
        calendarClassName="custom-calendar"
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 10px"
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={false}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                color: theme.palette.primary.main
              }}
            >
              {"<"}
            </button>
            <span>{format(monthDate, "MMMM yyyy", { locale: ptBR })}</span>
            <button
              onClick={increaseMonth}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                color: theme.palette.primary.main
              }}
            >
              {">"}
            </button>
            <Button
              onClick={handleClear}
              variant="text"
              color="secondary"
              style={{ marginLeft: "10px", fontSize: "12px" }}
            >
              Limpar
            </Button>
          </div>
        )}
      />

      <style jsx>{`
        .react-datepicker__day--selected,
        .react-datepicker__day--selected:hover,
        .react-datepicker__day--keyboard-selected {
          background-color: ${theme.palette.primary.main} !important;
        }

        .react-datepicker__day--in-selecting-range {
          background-color: ${theme.palette.primary.main} !important;
        }

        .react-datepicker__day--in-range {
          background-color: ${theme.palette.primary.main} !important;
        }

        .react-datepicker__header {
          background-color: white;
          color: ${theme.palette.primary.contrastText};
        }
      `}</style>
    </DateFilterContent>
  );
};

export default DateRangeFilter;
