import { formatDate } from "utils";

const getPayload = data => {
  const payload = { ...data };

  const finalizedByMenu = payload?.finalizedByMenu ?? true;

  if (!finalizedByMenu) {
    payload.scheduledDate = formatDate(payload.scheduledDate);

    const formatTime = date => {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    payload.scheduledTimeFrom = formatTime(payload.scheduledTimeFrom);
    payload.scheduledTimeTo = formatTime(payload.scheduledTimeTo);
    payload.hasEmptyBoxes = payload.hasEmptyBoxes === "yes";
    payload.isCritical = payload.isCritical === "yes";
    payload.hasReturnPending = payload.hasReturnPending === "yes";
  }

  payload.seller = payload.seller.id;

  payload.address = payload.address?.id || payload.address;

  payload.sellerOrderPackaging = payload.sellerOrderPackaging.map(
    packaging => ({
      id: packaging?.id,
      packagingId: packaging?.packaging?.id || packaging.packagingId,
      packagingName:
        packaging?.packaging?.name || packaging.packagingName || "",
      quantity: parseInt(packaging.quantity)
    })
  );

  payload.sellerOrderItems = payload.sellerOrderItems.map(item => ({
    id: item?.id,
    productBase: item.productBase.id,
    description: item.productBase.name || "",
    quantity: parseInt(item.quantity)
  }));

  return payload;
};

export default getPayload;
