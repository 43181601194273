import {
  Accordion,
  AccordionDetails,
  Box,
  Card,
  CardContent,
  Paper,
  Typography,
  styled
} from "@material-ui/core";
import { borderLeft, borderRight } from "@material-ui/system";

export const DashCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem"
}));
export const DashCardContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "1rem",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    gap: "2rem"
  }
}));

export const SellerNameContent = styled(Card)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  maxWidth: "fit-content",
  gap: "0.5rem",
  alignItems: "center",
  backgroundColor: "#F3F8E6",
  borderRadius: "0.5rem",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#757575",
  "& span": {
    fontFamily: "INter",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#353535"
  }
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  width: "22.5rem",
  overflow: "visible"
}));

export const IconCardContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "2.88rem",
  height: "2.88rem",
  borderRadius: "50%",
  boxShadow: "0px -3px 3px -2px rgba(0, 0, 0, 0.5)",
  borderLeft: "none",
  borderRight: "none",
  top: -18,
  left: 20,
  "& svg": {
    color: "#587916"
  }
}));
export const IconCardContent = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "#F3F8E6",
  border: "5px solid #fff"
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem"
}));

export const CardInfoContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "9.75rem",
  height: "5.56rem",
  backgroundColor: "#F5F6F7",
  padding: "0.5rem",
  borderRadius: "0.5rem",
  gap: "0.5rem",
  fontFamily: "Roboto",
  fontSize: "0.875",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "150%",
  [theme.breakpoints.down("sm")]: {
    width: "9rem"
  }
}));

export const CardInfoDescription = styled(Box)(({ theme }) => ({
  display: "flex",
  color: "#757575"
}));

export const CardInfoValue = styled(Box)(({ theme }) => ({
  display: "flex",
  color: "#353535",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 500,
  letterSpacing: "-0.01rem;"
}));

export const AccordionContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingBlock: "0.5rem"
}));

export const StyledAccordion = styled(Accordion)({
  margin: 0,
  padding: 0,
  "& .MuiAccordionSummary-root": {
    paddingBlock: "1rem"
  },
  "&:nth-of-type(even)": {
    "& .MuiAccordionSummary-root": {
      backgroundColor: "#F5F6F7"
    }
  },

  "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded ": {
    backgroundColor: "#E5ECD1"
  },
  "& .MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd ": {
    backgroundColor: "#E5ECD1"
  },
  "&.MuiAccordion-root.Mui-expanded": {
    margin: 0,
    padding: 0
  }
});

export const AccordionTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  color: "#353535",
  justifyContent: "center",
  fontSize: "1.125rem",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  gap: "1rem",
  "& svg": {
    width: "1.5rem",
    height: "1.5rem",
    color: "#D9D9D9"
  }
}));

export const StyledAccordionDetails = styled(AccordionDetails)({
  overflowY: "auto"
});

export const AccordionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  minHeight: "40rem",
  flexDirection: "column",
  gap: "1rem",
  marginTop: "3rem"
}));
