import { ESTOQUE_BACKEND_URL, ABACATE_BACKEND_URL } from "../settings";
import { api } from "./api";

class ProductApiServices {
  constructor() {
    this.url = ABACATE_BACKEND_URL;
  }

  async getCategories(params) {
    const url = `${this.url}/api/products/v2/product/category`;
    return await api.get(url, { params });
  }

  async postCategories(data) {
    const url = `${this.url}/api/products/v2/product/category`;
    return await api.post(url, data);
  }

  async patchCategories({ id, data }) {
    const url = `${this.url}/api/products/v2/product/category/${id}`;
    return await api.patch(url, data);
  }

  async getSubCategories(params) {
    const url = `${this.url}/api/products/v2/product/subcategory`;
    return await api.get(url, { params });
  }

  async postSubCategories(data) {
    const url = `${this.url}/api/products/v2/product/subcategory`;
    return await api.post(url, data);
  }

  async patchSubCategories({ id, data }) {
    const url = `${this.url}/api/products/v2/product/subcategory/${id}`;
    return await api.patch(url, data);
  }

  async getStorageStockTypes(params) {
    const url = `${this.url}/api/products/v2/product/stock-type`;
    return await api.get(url, { params });
  }

  async postStorageStockTypes(data) {
    const url = `${this.url}/api/products/v2/product/stock-type`;
    return await api.post(url, data);
  }

  async patchStorageStockTypes({ id, data }) {
    const url = `${this.url}/api/products/v2/product/stock-type/${id}`;
    return await api.patch(url, data);
  }

  async getProductBase(params) {
    const url = `${this.url}/api/products/v1/product-base`;
    return await api.get(url, { params });
  }
}

const productApiServices = new ProductApiServices();
export default productApiServices;
