import * as Yup from "yup";

export const validationSchema = batchData => {
  const { purchaseType, availableQuantityValue } = batchData;
  return Yup.object().shape({
    quantity: Yup.number()
      .typeError("Deve ser um número")
      .required("Campo obrigatório")
      .notOneOf([0], "A quantidade não pode ser 0")
      .when("storageReasonId", {
        is: value => value && value !== 28,
        then: Yup.number()
          .positive("A quantidade deve ser superior a 0")
          .max(
            availableQuantityValue,
            `O valor máximo é ${availableQuantityValue}`
          )
      })
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          return !(purchaseType === "Un" && !Number.isInteger(value));
        }
      )
      .min(
        -availableQuantityValue,
        `O valor mínimo é - ${availableQuantityValue}`
      ),
    storageReasonId: Yup.number()
      .positive()
      .typeError("Deve ser um número")
      .required("Campo obrigatório"),

    batchReasonId: Yup.number().when("storageReasonId", {
      is: value => value && value !== 28,
      then: Yup.number()
        .positive()
        .typeError("Deve ser um número")
        .required("Campo obrigatório")
    })
  });
};
