import { Box, useMediaQuery } from "@material-ui/core";
import { TitlePage, ContainerPage, Button } from "components";
import { startOfDay, endOfDay, format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import { useEffect, useMemo, useRef, useState } from "react";
import batchService from "services/batchService";
import depotService from "services/depotService";
import { Table } from "shared";
import { convertDate } from "utils";

import { Stored } from "./components/Stored";
import { TabBatchStorage } from "./components/TabBatchStorage";
import { batchFormatter } from "./utils/dataFormatter";

const BatchStorage = () => {
  const storedRef = useRef(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [dataLedger, setDataLedger] = useState([]);
  const { addPopup } = usePopup();
  const receiptDateFrom = format(
    startOfDay(new Date(2024, 2, 16)),
    "yyyy-MM-dd'T'HH:mm:ssxxx",
    {
      locale: ptBR
    }
  );
  const receiptDateTo = format(
    endOfDay(new Date()),
    "yyyy-MM-dd'T'HH:mm:ssxxx",
    { locale: ptBR }
  );

  const { data, params, count, onChange, refresh, status, handleSearch } =
    useTableParams({
      service: params => batchService.getBatchesV2(params),
      paramsDefault: {
        isStored: false,
        orderDirection: "receipt_date",
        status: 2,
        receiptDateFrom,
        receiptDateTo
      }
    });

  const columns = useMemo(() => {
    const conditionalColumn = {
      true: [
        {
          field: "button",
          label: "",
          align: "center",
          style: { minWidth: "140px" },
          disabledSort: true,
          renderCell: ({ data }) => (
            <Button
              variant="contained"
              style={{ margin: isSmallScreen ? "0.5rem auto" : "0 auto" }}
              onClick={() => storedRef.current?.handleOpen(data)}
            >
              Armazenar
            </Button>
          )
        }
      ],
      false: [
        {
          field: "first_stored_date",
          label: "Armazenado em",
          align: "center",
          disabledSort: true,
          formatter: value => {
            return (
              <Box style={{ marginBlock: isSmallScreen && "1rem" }}>
                {convertDate(new Date(value))}
              </Box>
            );
          }
        },
        {
          field: "warehouse",
          label: "Armazém",
          align: "center",
          disabledSort: true,
          formatter: value => {
            return (
              <Box style={{ marginBlock: isSmallScreen && "1rem" }}>
                {value}
              </Box>
            );
          }
        },
        {
          field: "depot",
          label: "Depósito",
          align: "center",
          disabledSort: true,
          formatter: value => {
            return (
              <Box style={{ marginBlock: isSmallScreen && "1rem" }}>
                {value}
              </Box>
            );
          }
        }
      ]
    }[!params?.isStored];

    const columns = [
      {
        field: "id",
        label: "Lote",
        align: "center",
        style: { minWidth: "50px" },
        disabledSort: true
      },
      {
        field: "receipt_date",
        label: "Recebido em",
        align: "center",
        formatter: value => convertDate(new Date(value)),
        disabledSort: true
      },
      {
        field: "productBaseName",
        label: "Produto",
        align: "center",
        disabledSort: true
      },
      {
        field: "seller",
        label: "Seller",
        style: { minWidth: "100px" },
        disabledSort: true
      },
      {
        field: "quantity",
        label: "Quantidade",
        align: "center",
        disabledSort: true
      }
    ];

    return [...columns, ...conditionalColumn];
  }, [params?.isStored, isSmallScreen]);

  const getLedgersByBatchId = async () => {
    try {
      const response = await depotService.listLedgers({
        pageSize: 1000,
        batchIds: data.map(item => item.id).join(", ")
      });
      setDataLedger(response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar Ledgers",
        description: errorMessage || "Erro ao carregar Ledgers do lote"
      });
    }
  };

  const formattedData = useMemo(() => {
    if (status === "pending" || (params.isStored && dataLedger.length === 0)) {
      return [];
    }
    return batchFormatter(data, dataLedger?.results);
  }, [data, status, dataLedger, params.isStored]);

  const handleChangeTab = value => onChange({ page: 1, isStored: !!value });

  useEffect(() => {
    if (params.isStored && data.length > 0) {
      getLedgersByBatchId();
    }
  }, [data]);

  return (
    <>
      <ContainerPage>
        <TitlePage>A Armazenar/Armazenado</TitlePage>
        <TabBatchStorage
          activeTab={params?.isStored ? 1 : 0}
          handleChangeTab={handleChangeTab}
        />
        <Table
          placeholder="Seller ou produto"
          handleSearch={handleSearch}
          columns={columns}
          data={formattedData}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
        />
      </ContainerPage>
      <Stored ref={storedRef} refresh={refresh} />
    </>
  );
};

export default BatchStorage;
