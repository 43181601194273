export const productBaseDataFormatter = (
  productBaseData,
  warehousePBConfigData,
  packagingPBConfigData
) => {
  return (productBaseData || []).map(productBase => {
    return {
      ...productBase,
      warehouses: (warehousePBConfigData || []).reduce(
        (acc, warehousePBConfig) => {
          if (warehousePBConfig.productBaseId === productBase.id) {
            acc.push({
              productBaseId: productBase.id,
              warehouseId: warehousePBConfig.warehouse?.id
            });
          }
          return acc;
        },
        []
      ),
      packages: (packagingPBConfigData || []).reduce(
        (acc, packagingPBConfig) => {
          if (packagingPBConfig.productBaseId === productBase.id) {
            acc.push({
              productBaseId: productBase.id,
              packagingId: packagingPBConfig.packaging?.id
            });
          }
          return acc;
        },
        []
      )
    };
  });
};

export const warehouseDataFormatter = warehouseData => {
  return (warehouseData || [])?.sort((a, b) => a.name.localeCompare(b.name));
};

export const packagingDataFormatter = packagingData => {
  return (packagingData || [])?.sort((a, b) => a.name.localeCompare(b.name));
};
