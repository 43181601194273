import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiPaper-root": {
      width: "464px",
      height: "492px",
      maxHeight: "80vh",
      maxWidth: ({ maxWidth = "46rem" }) => maxWidth,
      borderRadius: "0.5rem",
      margin: "0 0.5rem"
    }
  },
  inputBox: {
    width: "100%",
    borderRadius: "16px",
    background: "#F5F8F3",
    "& .MuiInputBase-root": {
      textDecoration: "none"
    }
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 0
  },
  button: {
    width: "100%",
    borderRadius: "4px"
  },
  buttonGrid: {
    display: "flex",
    alignSelf: "center",
    position: "absolute",
    bottom: 0,
    gap: "10px",
    marginBottom: "20px",
    width: "90%"
  },
  typography: {
    marginBottom: ".2rem",
    backgroundColor: "#F5F6F7",
    borderRadius: "15px",
    padding: ".5rem"
  }
}));
