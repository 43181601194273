import { TableCell } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { CustomLineProgress } from "components/LineProgress";
import { TableHead, TableRow } from "shared";

import {
  RankingTableContainer,
  StyledTable,
  StyledTableBody,
  TableCellIcon,
  TableCellPosition,
  TableCellSeller,
  TableCellValue,
  TableHeadCell,
  TableRowBody
} from "./../../style";

export const RankingTable = ({ rankingData, loading }) => {
  return (
    <RankingTableContainer>
      {!!loading && <CustomLineProgress />}
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableHeadCell isSeller={true}>Seller</TableHeadCell>
            <TableHeadCell>Qtd. Lotes</TableHeadCell>
            <TableHeadCell>Blitz</TableHeadCell>
            <TableHeadCell>Recusado</TableHeadCell>
            <TableHeadCell>Não entregue</TableHeadCell>
            <TableHeadCell>Class. B</TableHeadCell>
            <TableHeadCell>Class. C</TableHeadCell>
            <TableHeadCell>Pontuação</TableHeadCell>
          </TableRow>
        </TableHead>

        <StyledTableBody>
          <TableRow>
            <TableCell colSpan={9} style={{ borderBottom: "none" }}></TableCell>
          </TableRow>
          {rankingData?.map((ranking, index) => {
            const position = index + 1;
            return (
              <TableRowBody key={ranking.seller?.id}>
                <TableCellIcon position={position}>
                  <StarIcon />
                </TableCellIcon>
                <TableCellPosition>{position}</TableCellPosition>
                <TableCellSeller>{ranking.seller?.name}</TableCellSeller>
                <TableCellValue>{ranking.batchQuantity}</TableCellValue>
                <TableCellValue>{ranking.blitzPercentage}%</TableCellValue>
                <TableCellValue>
                  {ranking.batchRefusedPercentage}%
                </TableCellValue>
                <TableCellValue>
                  {ranking.batchUndeliveredPercentage}%
                </TableCellValue>
                <TableCellValue>
                  {ranking.classificationBPercentage}%
                </TableCellValue>
                <TableCellValue>
                  {ranking.classificationCPercentage}%
                </TableCellValue>
                <TableCellValue>{ranking.badPoints}</TableCellValue>
              </TableRowBody>
            );
          })}
        </StyledTableBody>
      </StyledTable>
    </RankingTableContainer>
  );
};
