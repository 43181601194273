function whatState() {
  if (
    window.location.href.includes("localhost") ||
    window.location.href.includes("stg")
  ) {
    return {
      ESTOQUE_BACKEND_URL: "https://stg.estoque.frexco.com.br",
      ABACATE_BACKEND_URL: "https://stg.abacate.frexco.com.br",
      PRODUCT_API_BACKEND_URL: "https://stg.ms.frexco.com.br",
      MS_API_BACKEND_URL: "https://stg.ms.frexco.com.br",
      LOCATION_API_URL: "https://stg.empresas.frexco.com.br/api/location",
      MENU_API_URL: "https://stg.empresas.frexco.com.br/api/menu",
      URL_MAP_TILE:
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGVkcm9iYXJyZXR0byIsImEiOiJjbDNha3pzbGcwMzFzM2JuMm5ieHA3MDl3In0.frapF7O0l68ZVOaPgFu__A"
    };
  }
  if (window.location.href.includes("cd.estoque")) {
    return {
      ESTOQUE_BACKEND_URL: "https://cd.estoque.frexco.com.br",
      ABACATE_BACKEND_URL: "https://abacate.frexco.com.br",
      PRODUCT_API_BACKEND_URL: "https://ms.frexco.com.br",
      MS_API_BACKEND_URL: "https://ms.frexco.com.br",
      LOCATION_API_URL: "https://empresas.frexco.com.br/api/location",
      MENU_API_URL: "https://empresas.frexco.com.br/api/menu",
      MAP_URL_TILE:
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGVkcm9iYXJyZXR0byIsImEiOiJjbDNha3pzbGcwMzFzM2JuMm5ieHA3MDl3In0.frapF7O0l68ZVOaPgFu__A"
    };
  }
  return {
    ESTOQUE_BACKEND_URL: "https://estoque.frexco.com.br",
    ABACATE_BACKEND_URL: "https://abacate.frexco.com.br",
    PRODUCT_API_BACKEND_URL: "https://ms.frexco.com.br",
    MS_API_BACKEND_URL: "https://ms.frexco.com.br",
    LOCATION_API_URL: "https://empresas.frexco.com.br/api/location",
    MENU_API_URL: "https://empresas.frexco.com.br/api/menu",
    URL_MAP_TILE:
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGVkcm9iYXJyZXR0byIsImEiOiJjbDNha3pzbGcwMzFzM2JuMm5ieHA3MDl3In0.frapF7O0l68ZVOaPgFu__A"
  };
}

const {
  ESTOQUE_BACKEND_URL,
  ABACATE_BACKEND_URL,
  PRODUCT_API_BACKEND_URL,
  MS_API_BACKEND_URL,
  LOCATION_API_URL,
  MENU_API_URL,
  URL_MAP_TILE
} = whatState();

export {
  ESTOQUE_BACKEND_URL,
  ABACATE_BACKEND_URL,
  PRODUCT_API_BACKEND_URL,
  MS_API_BACKEND_URL,
  LOCATION_API_URL,
  MENU_API_URL,
  URL_MAP_TILE
};
