import { styled, Box as BoxMui, alpha } from "@material-ui/core";
import { Button as ButtonMui } from "components";
import { Dialog as DialogCustom } from "shared";

export const Dialog = styled(DialogCustom)({
  "& .MuiPaper-root, & .MuiDialog-container": {
    width: "100%",
    maxWidth: "25.6875rem"
  }
});

export const Container = styled(BoxMui)(({ theme }) => ({
  minHeight: "23.5rem",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.between(0, 900)]: {
    minHeight: "25rem"
  }
}));

export const Button = styled(ButtonMui)(({ flex }) => ({
  height: "3rem",
  padding: "0 2.91rem",
  flex,
  "&.MuiButton-text": {
    color: "#757575",
    "&:hover": {
      background: alpha("#000000", 0.1)
    }
  }
}));
