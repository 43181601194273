import { usePopup } from "hooks/usePopup";
import { useRef } from "react";
import depotService from "services/depotService";
import { Drawer } from "shared";

import { DepotForm } from "../Form";

export const EditDepot = ({
  open,
  depotData,
  warehouseData,
  warehouseTypeId,
  onClose,
  refreshData
}) => {
  const { addPopup } = usePopup();
  const formRef = useRef(null);

  const handleCreateDepot = async data => {
    try {
      await depotService.createDepot({
        ...data,
        abbreviation: data?.abbreviation?.toUpperCase()
      });
      refreshData();
      addPopup({
        type: "success",
        title: "Depósito criado com sucesso"
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Não foi possível criar o depósito",
        description: errorMessage
      });
    }
  };
  const handleUpdateDepot = async data => {
    try {
      await depotService.updateDepot(depotData.id, {
        ...data,
        abbreviation: data?.abbreviation?.toUpperCase()
      });
      refreshData();
      addPopup({
        type: "success",
        title: "Depósito criado com sucesso"
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Não foi possível criar o depósito",
        description: errorMessage
      });
    }
  };

  const handleSubmit = values => {
    depotData ? handleUpdateDepot(values) : handleCreateDepot(values);
    onClose();
  };

  return (
    <Drawer
      title={depotData ? "Editar Depósito" : "Criar Depósito"}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <DepotForm
        data={depotData}
        warehouseData={warehouseData}
        warehouseTypeId={warehouseTypeId}
        ref={formRef}
        onSubmit={handleSubmit}
      />
    </Drawer>
  );
};
