export const historyTitleMap = title => {
  return (
    {
      productName: "Produto",
      averageUnitWeight: "Peso médio unitário",
      quantity: "Quantidade",
      receiptDate: "Data Recebimento",
      receiptTime: "Hora Recebimento",
      seller: "Seller",
      shelfLife: "Shelf Life",
      stage: "Estágio",
      status: "Status",
      boxQuantity: "Qtd. Caixa/Saco",
      sampleQuantity: "Qtd. Amostra",
      sampleLowQuantity: "Qtd. Amostra baixa qualidade",
      availableQuantity: "Qtd. Disponível",
      editReason: "Motivo",
      classification: "Classificação",
      selectionStep: "Etapa de Seleção"
    }[title] || title
  );
};
