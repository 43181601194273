import { usePopup } from "hooks/usePopup";
import React, { useEffect, useState } from "react";
import assetService from "services/assetsService";
import reporterGenerateService from "services/reporterGenerateService";
import { Dialog } from "shared";

export const DocumentDialog = props => {
  const { isOpen, setIsOpen, transactionId } = props;
  const { addPopup } = usePopup();
  const [transaction, setTransaction] = useState(null);
  const [document, setDocument] = useState(null);

  const getTransaction = async () => {
    try {
      const { data } = await assetService.getTransaction(transactionId);
      setTransaction(data);
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao buscar transação"
      });
    }
  };

  const getDocument = async () => {
    try {
      const { data } = await reporterGenerateService.assetDocument(transaction);
      setDocument(URL.createObjectURL(data));
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao buscar documento"
      });
    }
  };

  useEffect(() => {
    transactionId && getTransaction();
  }, [transactionId]);

  useEffect(() => {
    transaction && getDocument();
  }, [transaction]);

  const handleClose = () => {
    setIsOpen(false);
    setTransaction(null);
    setDocument(null);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        handleClose={handleClose}
        maxWidth="95%"
        height="100%"
        title="Documento de transporte"
      >
        <embed
          src={document}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      </Dialog>
    </>
  );
};
