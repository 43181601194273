import { Typography, Box, TextField } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import { Button } from "../style";

export const BoxesView = ({
  onBack,
  onNext,
  boxesQuantity,
  setBoxesQuantity
}) => {
  const handleChange = ({ target: { value } }) => {
    if (value < 0) return;
    setBoxesQuantity(value);
  };

  return (
    <>
      <Box marginBottom="1.4rem">
        <Typography style={{ color: "#757575" }}>
          Quantas caixas serão migradas?
        </Typography>
      </Box>
      <TextField
        variant="outlined"
        value={boxesQuantity}
        onChange={handleChange}
        type="number"
      />

      <Box
        display="flex"
        alignItems="flex-end"
        marginTop="1rem"
        flex="1"
        style={{ gap: "1rem" }}
      >
        <Button
          variant="text"
          flex="1"
          startIcon={<NavigateBeforeIcon fontSize="large" />}
          onClick={onBack}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          flex="1"
          onClick={onNext}
          disabled={!boxesQuantity}
        >
          Avançar
        </Button>
      </Box>
    </>
  );
};
