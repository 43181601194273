import { Typography, Box } from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState, forwardRef, useImperativeHandle } from "react";
import userService from "services/userService";
import { Dialog } from "shared";

const Component = (_, ref) => {
  const { addPopup } = usePopup();
  const [{ username, id }, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = data => {
    setData(data);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await userService.resetBlock(id);
      addPopup({
        type: "success",
        title: "Tentativas de login redefinidas com sucesso"
      });
      handleClose();
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao redefinir tentativas de login"
      });
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({ handleOpen }));

  return (
    <Dialog open={isOpen} title="Confirmação" handleClose={handleClose}>
      <Typography>
        Você está prestes a resetar o{" "}
        <strong>tempo de bloqueio de login</strong> para o usuário{" "}
        <strong>{username}</strong>. Esta ação removerá qualquer período
        restante de bloqueio, permitindo que o usuário tente fazer login
        imediatamente. Confirma o reset do tempo de bloqueio?
      </Typography>

      <Box
        marginTop="2rem"
        display="flex"
        justifyContent="flex-end"
        style={{ gap: "1rem" }}
      >
        <Button
          variant="contained"
          color="warning"
          fullWidth
          style={{ maxWidth: "10rem" }}
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          isLoading={isLoading}
          variant="contained"
          fullWidth
          style={{ maxWidth: "10rem" }}
          onClick={handleConfirm}
        >
          Confirmar
        </Button>
      </Box>
    </Dialog>
  );
};

export const ConfirmResetBlock = forwardRef(Component);
