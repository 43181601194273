import { styled, Chip, MenuItem } from "@material-ui/core";

export const FilterContent = styled("div")({
  display: "flex",
  alignItems: "center"
});

export const ChipButtonFilter = styled(Chip)(({ theme, colors = {} }) => ({
  margin: theme.spacing(1),
  cursor: "pointer",
  color: colors.label || "inherit",
  backgroundColor: colors.background || "#F4F4F4",
  "&:hover": {
    backgroundColor: colors.background || "#E0E0E0"
  },
  "&:focus": {
    backgroundColor: colors.background || "#E0E0E0"
  }
}));

export const ChipMenuItem = styled(MenuItem)(({ theme, color = {} }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  backgroundColor: color.background || "#F4F4F4",
  borderRadius: "30px",
  width: "100%",
  color: color.label || "inherit",
  "&:hover": {
    backgroundColor: color.background || "#E0E0E0"
  }
}));
