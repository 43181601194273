import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class LockedStockService {
  constructor(url) {
    this.url = url;
  }

  async getAll(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/stock-lock`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        total_count: data.totalCount,
        ...data
      }
    };
  }

  async lockStock(lockedStock, productBaseId) {
    const url = `${this.url}/api/blueberry/v1/warehouse/stock-lock/${productBaseId}`;
    await api.put(url, { reasonId: 20, lockedStock });
  }

  async unlockStock(lockedStock, productBaseId) {
    const url = `${this.url}/api/blueberry/v1/warehouse/stock-lock/${productBaseId}`;
    await api.put(url, { reasonId: 21, lockedStock });
  }
}

const lockedStockService = new LockedStockService(ESTOQUE_BACKEND_URL);
export default lockedStockService;
