import { Box, styled } from "@material-ui/core";

export const CardsContainer = styled(Box)({
  display: "flex",
  flex: 1,
  gap: "1rem",
  padding: "1rem",
  paddingInline: "0 1rem",
  background: "#fff"
});

export const StyledCardContent = styled(Box)({
  display: "flex",
  minWidth: "15rem",
  minHeight: "5rem",
  padding: "1rem",
  alignItems: "center",
  gap: "1rem",
  flex: 1
});
