import { Typography, Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { InfoCard, AutoCompleteProductBase } from "shared";

import { Button } from "../style";

export const ProductView = ({ productBase, setProductBase, onNext, batch }) => (
  <>
    <InfoCard
      data={{
        Produto: { text: batch?.product, useStrong: true },
        Lote: { text: String(batch?.id) }
      }}
    />
    <Typography
      style={{
        color: "#757575",
        fontFamily: "Inter",
        margin: "1.5rem 0 1rem"
      }}
    >
      Para qual produto deseja mover esse lote?
    </Typography>
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      justifyContent="space-between"
    >
      <AutoCompleteProductBase
        name="toProductBaseId"
        label="Produto de destino"
        value={productBase}
        onChange={(_, value) => setProductBase(value)}
      />
      <Box display="flex" justifyContent="flex-end" marginTop="1rem">
        <Button
          style={{ width: "48%" }}
          variant="contained"
          endIcon={<NavigateNextIcon fontSize="large" />}
          onClick={onNext}
          disabled={!productBase}
        >
          Avançar
        </Button>
      </Box>
    </Box>
  </>
);
