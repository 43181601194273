import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class SummaryService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getSummary(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/summary`;
    const { data } = await api.get(url, { params });

    return {
      data: {
        results: data,
        total_count: data.length
      }
    };
  }

  async getSummaryDownload() {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/sku`;
    return await api.get(url, {
      responseType: "blob",
      params: {
        export: "xls"
      }
    });
  }

  async updateStock(form) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/summary/${form.id}`;
    const response = await api.put(url, form);

    return response;
  }

  uploadFile() {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/summary/upload/filename=estoque.xlsx`;
    return { api, url };
  }
}

const summaryService = new SummaryService();
export default summaryService;
