import { Box, TextField } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState, useEffect } from "react";
import batchService from "services/batchService";
import batchServiceV3 from "services/batchServiceV3";
import palette from "theme/palette";
import { mapType } from "utils";

import { ButtonsContent } from "../../style";
import { StyledBox, Text, TextInfo, StyledDialog, PopupContent } from "./style";

export const InitialPage = ({
  confirmedQty,
  setConfirmedQty,
  onNext,
  batchData,
  blitzData,
  handleCloseOpenSteps,
  price,
  setPrice,
  quantity,
  initialPrice
}) => {
  const [alreadyReceipt, setAlreadyReceipt] = useState(false);
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [alertPriceDiff, setAlertPriceDiff] = useState(null);
  const [alertQuantityDiff, setAlertQuantityDiff] = useState(null);
  const [alertValueQuantity, setAlertValueQuantity] = useState(null);
  const typeOfMeasure = mapType(batchData.product?.purchase_type);

  const handleChangeBoxQty = e => {
    const { value: inputValue } = e.target;
    const isInteger = Number.isInteger(Number(inputValue));
    setAlertValueQuantity(!isInteger && typeOfMeasure === "Unidade");
    const value =
      typeOfMeasure === "Quilo"
        ? parseFloat(inputValue, 10)
        : parseInt(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setConfirmedQty(!isValid ? "" : value);
  };

  const handleChangeBoxPrice = e => {
    const { value: inputValue } = e.target;
    const value = parseFloat(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setPrice(!isValid ? "" : value);
  };

  function transformDate(date) {
    if (!date) {
      return null;
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const currentDate = `${year}-${month}-${day}`;

    return currentDate;
  }

  const handleGetData = async () => {
    try {
      const date = new Date();
      const params = {
        search: batchData.seller.name,
        status: 2
      };
      const { data } = await batchService.getBatches(params);

      const receipted = data.results?.some(batchDataSeller => {
        const toBeAccountedDate = batchDataSeller.purchase_item.to_be_accounted;
        return (
          batchDataSeller.product.id === batchData.product.id &&
          toBeAccountedDate.substring(0, 10) === transformDate(date)
        );
      });
      setAlreadyReceipt(receipted);
    } catch (err) {
      addPopup({
        type: "error",
        title: "A busca de lotes falhou"
      });
    }
  };

  const handleClose = () => {
    setAlreadyReceipt(false);
  };

  const handleConfirm = async isFinished => {
    try {
      setIsLoading(true);

      const isValid =
        !isNaN(
          typeOfMeasure === "Quilo"
            ? parseFloat(confirmedQty)
            : parseInt(confirmedQty)
        ) || !isNaN(parseFloat(price));
      if (!isValid || !confirmedQty || !price) {
        return addPopup({
          type: "error",
          title: "Informação obrigatória",
          description: "Insira um valor válido"
        });
      }
      await batchServiceV3.batchReceipt(batchData.id, {
        blitz_step: 1,
        confirmed_quantity: confirmedQty,
        confirmed_price: price
      });
      handleCloseOpenSteps();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao confirmar quantidade de caixas"
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const priceDiff = initialPrice - price;
    const quantityDiff = quantity - confirmedQty;
    const valueMsg = value => (value < 0 ? "acima" : "abaixo");
    const formattedPrice = value =>
      Math.abs(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });

    const formattedQty = value => {
      const valueAbs = Math.abs(value);
      return typeOfMeasure === "Unidade"
        ? `${valueAbs} unidade(s)`
        : `${valueAbs} kg(s)`;
    };

    setAlertPriceDiff(
      price && priceDiff
        ? `O preço está ${formattedPrice(priceDiff)} ${valueMsg(
            priceDiff
          )} do combinado`
        : null
    );

    setAlertQuantityDiff(
      confirmedQty && quantityDiff
        ? `A quantidade está ${formattedQty(quantityDiff)} ${valueMsg(
            quantityDiff
          )} do combinado`
        : null
    );
  }, [price, confirmedQty]);

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <>
      <StyledBox>
        <Text>Qual a quantidade que o seller trouxe?</Text>
        <TextField
          variant="outlined"
          type="number"
          inputProps={{ type: "number", min: "0", step: "1" }}
          placeholder={typeOfMeasure}
          fullWidth
          value={confirmedQty}
          onChange={handleChangeBoxQty}
        />
        <Text style={{ color: "#eb001b", fontWeight: 400, fontSize: "1rem" }}>
          {alertQuantityDiff}
        </Text>
        {alertValueQuantity && (
          <Text style={{ color: "#eb001b", fontWeight: 400, fontSize: "1rem" }}>
            Valor deve ser unitário
          </Text>
        )}

        <Text>Qual o preço por {typeOfMeasure}?</Text>
        <TextField
          variant="outlined"
          type="number"
          inputProps={{ type: "number", min: "0", step: "1" }}
          placeholder="R$"
          fullWidth
          value={price}
          onChange={handleChangeBoxPrice}
        />
        <Text style={{ color: "#eb001b", fontWeight: 400, fontSize: "1rem" }}>
          {alertPriceDiff}
        </Text>

        <StyledDialog
          open={alreadyReceipt}
          title="Confirmação"
          handleClose={handleClose}
        >
          <PopupContent>
            <TextInfo>
              Aviso: Já foi recebido lote desse Seller nas últimas 24 horas
            </TextInfo>
          </PopupContent>
        </StyledDialog>
      </StyledBox>
      <ButtonsContent>
        <Box>
          <Button
            style={{ width: "50%", marginLeft: "auto" }}
            variant="contained"
            onClick={() => handleConfirm(false)}
            isLoading={isLoading}
          >
            Concluir
          </Button>
        </Box>
      </ButtonsContent>
    </>
  );
};
