import { ESTOQUE_BACKEND_URL, MS_API_BACKEND_URL } from "../settings";
import { api } from "./api";

class RankingSeller {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getRanking({ productId }) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/ranking`;
    return await api.get(url, {
      params: { productId }
    });
  }
}

const rankingSellerService = new RankingSeller();
export default rankingSellerService;
