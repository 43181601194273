import { mapPurchaseType } from "utils";

const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return {
    date,
    time
  };
};

const subBatches = data => {
  return (data || [])?.map(subBatch => {
    const typeOfMeasure = mapPurchaseType(subBatch.batch.product.purchase_type);
    const quantity = subBatch.quantity;
    const formattedWeight = [quantity || 0].toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3
    });
    const subBatchQuantity =
      typeOfMeasure === "Kg" ? `${formattedWeight} Kg` : `${quantity} Un`;
    return {
      ...subBatch,
      externalCode: subBatch.external_code,
      receiptDate: formatDateTime(subBatch.batch.receipt_date).date,
      receiptTime: formatDateTime(subBatch.batch.receipt_date).time,
      productName: subBatch.batch.product.name,
      classificationName: subBatch.classification,
      subBatchQuantity,
      stageName: subBatch.stage?.name,
      stockAbbreviation:
        subBatch.batch.product?.product_subcategory?.storage_stock_type
          ?.abbreviation || "##"
    };
  });
};

const formatterHistory = historical => {
  const formattedHistory = (historical || [])?.reduce((acc, historyItem) => {
    const quantity = historyItem.quantity?.toLocaleString();
    const formattedItem = {
      id: historyItem.id,
      batchId: historyItem.batch,
      subBatchId: historyItem.sub_batch,
      createdDate: formatDateTime(historyItem.created).date,
      createdTime: formatDateTime(historyItem.created).time,
      createdBy: historyItem.created_by?.username,
      stageId: historyItem.stage?.id,
      stageName: historyItem.stage?.name,
      externalCode: historyItem.external_code,
      destination: historyItem.destination,
      source: historyItem.source,
      quantity
    };
    return [...acc, formattedItem];
  }, []);

  return formattedHistory.sort((a, b) => a.id - b.id);
};

const subBatchDetail = subBatchData => {
  if (!subBatchData) {
    return {};
  }
  const typeOfMeasure = mapPurchaseType(
    subBatchData.batch.product.purchase_type
  );
  const quantity = subBatchData.quantity;
  const formattedWeight = [quantity || 0].toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3
  });
  const subBatchQuantity =
    typeOfMeasure === "Kg" ? `${formattedWeight} Kg` : `${quantity} Un`;
  return {
    ...subBatchData,
    batchId: subBatchData.batch?.id,
    productId: subBatchData.batch?.product.id,
    externalCode: subBatchData.external_code,
    productName: subBatchData.batch?.product.name,
    sellerName: subBatchData.batch?.seller.name,
    stageName: subBatchData.stage?.name,
    createdDate: formatDateTime(subBatchData.created).date,
    createdTime: formatDateTime(subBatchData.created).time,
    updatedDate: formatDateTime(subBatchData.updated).date,
    updatedTime: formatDateTime(subBatchData.updated).time,
    receiptDate: formatDateTime(subBatchData.batch.receipt_date).date,
    receiptTime: formatDateTime(subBatchData.batch.receipt_date).time,
    createdBy: subBatchData.created_by?.username,
    updatedBy: subBatchData.updated_by?.username,
    subBatchQuantity,
    historical: formatterHistory(subBatchData.history)
  };
};

export const formatterData = {
  subBatches,
  subBatchDetail
};
