import { convertDate } from "utils";

export const columns = [
  {
    field: "created",
    label: "Criado em",
    align: "center",
    disabledSort: true,
    renderCell: value => {
      const date = convertDate(new Date(value.data.created)).split(",");
      return (
        <>
          {date[0]} <br />
          {date[1]}
        </>
      );
    }
  },
  {
    field: "batchId",
    label: "Lote",
    disabledSort: true,
    formatter: value => value
  },
  {
    field: "productBaseName",
    label: "Produto Base",
    disabledSort: true,
    formatter: value => value
  },
  {
    field: "depot",
    label: "Depósito",
    disabledSort: true,
    formatter: value => value.name
  },
  {
    field: "warehouseType",
    label: "Armazém",
    disabledSort: true,
    formatter: value => value.name
  },
  {
    field: "packagingType",
    label: "Embalagem",
    disabledSort: true,
    formatter: value => value.name
  },
  {
    field: "quantity",
    label: "Quantidade",
    disabledSort: true,
    formatter: value => value
  },
  {
    field: "reason",
    label: "Motivo",
    disabledSort: true,
    formatter: value => value.name
  },
  {
    field: "createdByName",
    label: "Criado por",
    disabledSort: true,
    formatter: value => value
  }
];
