const palette = {
  primary: {
    main: "#89bd23",
    blue: "#1976d2"
  },
  secondary: {
    main: "#416D0B"
  },
  warning: {
    main: "#DA3737",
    purple: "#854AC2"
  }
};

export default palette;
