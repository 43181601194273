import { usePopup } from "hooks/usePopup";
import { useEffect, useMemo, useRef, useState } from "react";
import productBaseService from "services/productBaseService";
import productLevelsService from "services/productLevelsService";
import { Drawer } from "shared";
import {
  errorsProductBase,
  categoriesSelect,
  subCategoriesSelect
} from "utils";

import { Form } from "../Form";

const formatInitialValues = data => ({
  name: data?.name,
  shelfLifeA: data?.shelfLifeA,
  shelfLifeB: data?.shelfLifeB,
  shelfLifeC: data?.shelfLifeC,
  purchaseTypeId: data?.purchaseType,
  span: data?.productSpan,
  spanSequence: data?.spanSequence,
  status: data?.status,
  category: categoriesSelect.find(
    item => item.name.toLowerCase() === data?.category?.toLowerCase()
  )?.value,
  subCategory: subCategoriesSelect.find(
    item => item.name.toLowerCase() === data?.subCategory?.toLowerCase()
  )?.value,
  base64: data.image,
  reopen: data.reopen,
  productCategory: data.productCategory?.id,
  productSubCategory: data.productSubcategory?.id,
  margin: data.margin,
  isDropshipping: data.isDropshipping,
  barcode: data.barcode,
  index: data.index,
  isSanitized: data.classification?.isSanitized,
  isDiverse: data.classification?.isDiverse,
  isProcessed: data.classification?.isProcessed,
  isGreen: data.classification?.isGreen
});

export const ModalUpdateProductBase = ({
  isOpen,
  refresh,
  close,
  dataProduct,
  productCategoryData,
  productSubCategoryData
}) => {
  const formRef = useRef(null);
  const [spans, setSpans] = useState([]);
  const { addPopup } = usePopup();

  const initialValues = useMemo(
    () => formatInitialValues(dataProduct),
    [dataProduct]
  );

  const handleClose = () => {
    close({ isOpen: false, data: {} });
  };

  const onSubmit = async data => {
    try {
      delete data.shelfLife;

      await productBaseService.updateProductBase({
        id: dataProduct.id,
        ...data
      });

      if (data.base64 !== dataProduct.image) {
        await productBaseService.updateImageProductBase({
          id: dataProduct.id,
          image: data.base64
        });
      }

      addPopup({
        type: "success",
        title: "Produto base atualizado com sucesso."
      });
      handleClose();
      refresh();
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: errorsProductBase(error?.response?.data?.message?.trim())
        });
      }
    }
  };

  const init = async () => {
    try {
      const { data } = await productLevelsService.getProductLeves();
      setSpans(data);
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Ocorreu um erro",
          description: "Erro ao buscar dados"
        });
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      title="Editar Produto Base"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        spans={spans}
        ref={formRef}
        productCategoryData={productCategoryData}
        productSubCategoryData={productSubCategoryData}
      />
    </Drawer>
  );
};
