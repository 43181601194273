import { useState } from "react";
import { mapPurchaseType } from "utils";
import { convertImageFileToBase64 } from "utils/convertImageFileToBase64";

import { ConfirmPage } from "./ConfirmPage";
import { InitialPage } from "./InitialPage";

export const Classification = ({
  batchData,
  handleNext,
  sampleQualityQty,
  sampleLowQualityQty,
  handleClose
}) => {
  const [activePage, setActivePage] = useState(0);
  const [subBatch, setSubBatch] = useState(
    ["A", "B", "C"].map(classification => ({
      classification,
      quantity: 0,
      image: null
    }))
  );

  const { purchase_type } = batchData.product;
  const typeOfMeasure = mapPurchaseType(purchase_type);
  const isWeight = purchase_type === 1;

  const totalClassified = subBatch.reduce(
    (acc, item) => acc + (item.quantity || 0),
    0
  );

  const unclassifiedQuantity =
    sampleQualityQty - sampleLowQualityQty - totalClassified;

  const handleChangeSubBatch = e => {
    const field = "quantity";
    const name = e.target.value;
    const value = unclassifiedQuantity;
    const change = {
      quantity: () => {
        const totalClassified = subBatch
          .filter(({ classification }) => classification !== name)
          .reduce((acc, item) => acc + (item.quantity || 0), 0);
        const inputValue = isWeight
          ? parseFloat(value, 10)
          : parseInt(value, 10);
        const isValid = !isNaN(value) && value >= 0;

        const cancChange =
          totalClassified + (inputValue || 0) <=
          sampleQualityQty - sampleLowQualityQty;
        const newValue = subBatch.map(item =>
          item.classification === name
            ? {
                ...item,
                [field]: cancChange ? inputValue : item.quantity
              }
            : {
                ...item,
                [field]: 0
              }
        );
        setSubBatch(!isValid ? "" : newValue);
      },
      image: () => {
        const file = e.target.files[0];
        convertImageFileToBase64(file).then(imgBase64 => {
          setSubBatch(prevSubBatch =>
            prevSubBatch.map(item =>
              item.classification === name
                ? { ...item, [field]: imgBase64 }
                : item
            )
          );
        });
      }
    }[field];

    change();
  };

  const page = {
    0: (
      <InitialPage
        typeOfMeasure={typeOfMeasure}
        handleChangeSubBatch={handleChangeSubBatch}
        subBatch={subBatch}
        unclassifiedQuantity={unclassifiedQuantity}
        onNext={handleNext}
        batchId={batchData.id}
        handleClose={handleClose}
      />
    ),
    1: (
      <ConfirmPage
        batchId={batchData.id}
        typeOfMeasure={typeOfMeasure}
        subBatch={subBatch}
        handleNext={handleNext}
        onBack={() => setActivePage(0)}
      />
    )
  }[activePage];

  return page;
};
