import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  warehouseTypeId: Yup.number().required("Armazém é obrigatório."),
  depotId: Yup.number().optional(),
  quantity: Yup.number()
    .when("packagingTypeName", {
      is: packagingTypeName => packagingTypeName?.toLowerCase() !== "kg",
      then: Yup.number().integer("A quantidade deve ser um número inteiro.")
    })
    .min(0, "A quantidade deve ser no mínimo 0.")
    .required("O campo de quantidade é obrigatório.")
});
