import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem
} from "@material-ui/core";
import { Button, PermissionGate } from "components";
import { useRef, useState } from "react";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { MenuPopover } from "shared";

export const OptionsMenu = ({ data, options, page }) => {
  const [popover, setPopover] = useState(false);
  const optionsRef = useRef(null);

  const togglePopover = () => setPopover(state => !state);

  return (
    <>
      <IconButton
        ref={optionsRef}
        onClick={e => {
          e.stopPropagation();
          togglePopover();
        }}
      >
        {page === "mobile" ? (
          <Button
            style={{
              backgroundColor: "#88BC23",
              color: "white",
              transition: "background 0.4s",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#7da927",
                boxShadow: "none"
              }
            }}
          >
            Opções
          </Button>
        ) : (
          <MdOutlineMoreHoriz color="#414141" size={22} />
        )}
      </IconButton>
      <MenuPopover
        open={popover}
        onClose={togglePopover}
        anchorEl={optionsRef?.current}
      >
        {options &&
          options.map(option => {
            const { icon: Icon, action, title, permissions, disabled } = option;

            return (
              <PermissionGate key={title} permissions={permissions}>
                <MenuItem
                  button
                  style={{ color: option?.color }}
                  onClick={e => action(data)}
                  disabled={option.disabled ? option.disabled(data) : false}
                >
                  <ListItemIcon style={{ minWidth: 40 }}>
                    {Icon && (
                      <Icon size={20} style={{ color: option?.color }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </MenuItem>
              </PermissionGate>
            );
          })}
      </MenuPopover>
    </>
  );
};
