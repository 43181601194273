export const errorsRegisterMap = value => {
  const text =
    {
      "Error! Quantity negative!": "A quantidade precisa ser positiva!",
      "Error! Quantity positive!": "A quantidade precisa ser negativa!",
      "Error! Seller must not be selected!":
        "Não é necessário selecionar um seller.",
      "seller_id is missing": "O seller é obrigatório!",
      "Base product does not exist": "Esse produto base não existe!",
      "Type does not exist": "Esse tipo não existe!",
      "Reason does not exist": "Esse motivo não existe!",
      "Seller does not exist": "Esse seller não existe!"
    }[value] || "Ocorreu um erro, contate o administrador.";
  return text;
};
