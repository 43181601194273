import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { MainContent } from "components/Contents";
import { SearchInput } from "components/Inputs";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import productApiServices from "services/productApiServices";
import { useDebaunce } from "utils";

import { AddButton } from "./components/AddButton";
import { DialogCreate } from "./components/DialogsPopup/DialogCreate";
import { DialogUpdate } from "./components/DialogsPopup/DialogUpdate";
import { FilterByCategory } from "./components/FilterByCategory";
import { SubCategoriesTable } from "./components/SubCategoriesTable";
import { StyledSearchContent } from "./style";

export const ProductSubCategories = () => {
  const { addPopup } = usePopup();
  const [categoriesData, setCategoriesData] = useState([]);
  const [subcategoriesData, setSubcategoriesData] = useState([]);
  const [storageStockTypesData, setStorageStockTypeData] = useState([]);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });
  const [subcategoryName, setSubcategoryName] = useState("");
  const [subcategoryAbbreviation, setSubcategoryAbbreviation] = useState("");
  const [subcategoryId, setSubcategoryId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedStockTypeId, setSelectedStockTypeId] = useState(null);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState(null);
  const [subCategoryIndex, setSubCategoryIndex] = useState(null);

  const getInitData = async () => {
    try {
      setLoading(true);
      setSubcategoriesData([]);
      const params = {
        search,
        categoryId: selectedCategoryFilter
      };

      const [categoryResp, subcategoryResp, stockTypesResp] = await Promise.all(
        [
          productApiServices.getCategories(),
          productApiServices.getSubCategories(params),
          productApiServices.getStorageStockTypes()
        ]
      );

      setCategoriesData(categoryResp.data);
      setSubcategoriesData(subcategoryResp.data);
      setStorageStockTypeData(stockTypesResp.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar subcategorias",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateSubcategory = async () => {
    try {
      setLoadingFetch(true);
      const data = {
        name: subcategoryName,
        abbreviation: subcategoryAbbreviation,
        product_category: selectedCategoryId,
        storage_stock_type: selectedStockTypeId,
        index: subCategoryIndex
      };
      await productApiServices.postSubCategories(data);
      handleCloseDialogs();
      getInitData();
      addPopup({
        type: "success",
        title: "Nova subcategoria criada com sucesso!",
        description: `Nome: ${subcategoryName} Sigla: ${subcategoryAbbreviation}`
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao criar subcategoria",
        description: errorMessage
      });
    } finally {
      setLoadingFetch(false);
    }
  };

  const handleUpdateSubCategory = async () => {
    try {
      setLoadingFetch(true);
      const data = {
        name: subcategoryName,
        abbreviation: subcategoryAbbreviation,
        product_category: selectedCategoryId,
        storage_stock_type: selectedStockTypeId,
        index: subCategoryIndex
      };
      await productApiServices.patchSubCategories({
        id: subcategoryId,
        data
      });
      handleCloseDialogs();
      getInitData();
      addPopup({
        type: "success",
        title: "Subcategoria atualizada com sucesso!",
        description: `Nome: ${subcategoryName} Sigla: ${subcategoryAbbreviation}`
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao atualizar subcategoria",
        description: errorMessage
      });
    } finally {
      setLoadingFetch(false);
    }
  };

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  function handleCloseDialogs() {
    setSubcategoryId(null);
    setSubcategoryName("");
    setSubcategoryAbbreviation("");
    setSelectedCategoryId(null);
    setSelectedStockTypeId(null);
    setOpenCreate(false);
    setOpenUpdate(false);
    setSubCategoryIndex(null);
  }

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleOpenUpdate = categoryId => {
    const subcategory = subcategoriesData.find(({ id }) => id === categoryId);
    const { name, abbreviation, id, productCategory, storageStockType, index } =
      subcategory;

    setSubcategoryId(id);
    setSubcategoryName(name);
    setSubcategoryAbbreviation(abbreviation);
    setSelectedCategoryId(productCategory.id);
    setSelectedStockTypeId(storageStockType.id);
    setOpenUpdate(true);
    setSubCategoryIndex(index);
  };

  const handleChangeSubcategory = e => {
    const { value } = e.target;
    setSubcategoryName(value);
  };

  const handleChangeAbbreviation = e => {
    const value = e.target.value?.toUpperCase().slice(0, 2);
    setSubcategoryAbbreviation(value);
  };

  const handleSelectCategory = e => {
    const { value } = e.target;
    setSelectedCategoryId(value);
  };

  const handleSelectStockType = e => {
    const { value } = e.target;
    setSelectedStockTypeId(value);
  };

  const handleFilterByCategory = e => {
    const { value } = e.target;
    setSelectedCategoryFilter(value);
  };

  const handleChangeIndex = e => {
    const { value } = e.target;
    setSubCategoryIndex(value);
  };

  useEffect(() => {
    getInitData();
  }, [search, selectedCategoryFilter]);

  return (
    <ContainerPage>
      <Box>
        <TitlePage>Subcategorias de produto </TitlePage>
      </Box>
      <MainContent>
        <StyledSearchContent>
          <SearchInput
            placeholder="ID ou subcategoria"
            onChange={debaunceSearch}
          />
          <FilterByCategory
            data={categoriesData}
            onChange={handleFilterByCategory}
            selectedCategoryFilter={selectedCategoryFilter}
          />
          <AddButton onClick={handleOpenCreate} />
        </StyledSearchContent>
        <SubCategoriesTable
          subcategoriesData={subcategoriesData}
          loading={loading}
          handleOpenUpdate={handleOpenUpdate}
        />
        <DialogCreate
          handleClose={handleCloseDialogs}
          open={openCreate}
          subcategoryAbbreviation={subcategoryAbbreviation}
          subcategoryName={subcategoryName}
          handleChangeAbbreviation={handleChangeAbbreviation}
          handleChangeSubcategory={handleChangeSubcategory}
          loading={loadingFetch}
          handleSave={handleCreateSubcategory}
          selectedCategoryId={selectedCategoryId}
          selectedStockTypeId={selectedStockTypeId}
          categoriesData={categoriesData}
          storageStockTypesData={storageStockTypesData}
          handleSelectCategory={handleSelectCategory}
          handleSelectStockType={handleSelectStockType}
          handleChangeIndex={handleChangeIndex}
          subCategoryIndex={subCategoryIndex}
        />
        <DialogUpdate
          handleClose={handleCloseDialogs}
          open={openUpdate}
          subcategoryAbbreviation={subcategoryAbbreviation}
          subcategoryName={subcategoryName}
          handleChangeAbbreviation={handleChangeAbbreviation}
          handleChangeSubcategory={handleChangeSubcategory}
          loading={loadingFetch}
          handleSave={handleUpdateSubCategory}
          selectedCategoryId={selectedCategoryId}
          selectedStockTypeId={selectedStockTypeId}
          categoriesData={categoriesData}
          storageStockTypesData={storageStockTypesData}
          handleSelectCategory={handleSelectCategory}
          handleSelectStockType={handleSelectStockType}
          handleChangeIndex={handleChangeIndex}
          subCategoryIndex={subCategoryIndex}
        />
      </MainContent>
    </ContainerPage>
  );
};
