import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class ProductLevelsService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getProductLeves(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-span`;
    return await api.get(url, {
      params
    });
  }

  async postProductLevels(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-span`;
    return await api.post(url, data);
  }

  async updateProductLevels(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-span`;
    return await api.put(url, data);
  }
}

const productLevelsService = new ProductLevelsService();
export default productLevelsService;
