import { Box, IconButton, Typography, styled } from "@material-ui/core";
import { Button as _Button } from "components";
import { Dialog as _Dialog } from "shared";

export const Dialog = styled(_Dialog)(({ theme }) => ({
  maxWidth: "32rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": {
    padding: 0,
    height: "90%",
    minHeight: "540px",
    maxHeight: "auto",
    width: "100%",
    margin: "0 1rem"
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      height: "100% !important",
      minHeight: "100% !important",
      maxHeight: "100% !important",
      width: "100% !important",
      padding: "0 !important",
      margin: "0 !important"
    }
  }
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem"
}));

export const Info = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#F5F8F3",
  padding: "1rem 1.25rem",
  borderRadius: 16,

  "& > div": {
    display: "flex",
    gap: 4
  }
}));

export const Span = styled(Typography)(() => ({
  fontFamily: "'Roboto', Arial, Helvetica",
  fontSize: "1rem",
  color: "#757575",
  fontWeight: 400
}));

export const Strong = styled(Span)(() => ({
  fontWeight: 500
}));

export const Button = styled(_Button)(() => ({
  width: "100%",
  height: "3rem"
}));

export const AddPackageButton = styled(_Button)({
  backgroundColor: "#F4F4F4",
  maxHeight: "2.625rem",
  justifyContent: "left",
  borderRadius: "0.5rem",
  padding: "0.5rem 1rem",
  "& .MuiButton-label": {
    fontFamily: "Roboto",
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#757575",
    textTransform: "none"
  },
  "& .MuiSvgIcon-root": {
    width: "1rem",
    height: "1rem",
    color: "#757575"
  },
  "&:hover": {
    backgroundColor: "#F4F4F4"
  }
});

export const RemovePackageIconButton = styled(IconButton)({
  "& svg": {
    color: "#EB001B"
  },
  "&.Mui-disabled ": {
    "& svg": {
      color: "#757575"
    }
  }
});
