import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { cards } from "./cards";
import {
  CardContent,
  CardDescription,
  CardIcon,
  CardTitle,
  CardsContainer,
  TextContent
} from "./styles";
export const ConfigCards = () => {
  return (
    <CardsContainer>
      {cards.map(card => (
        <Link key={card.path} style={{ textDecoration: "none" }} to={card.path}>
          <CardContent elevation={2}>
            <CardIcon>
              <card.icon />
            </CardIcon>
            <TextContent>
              <CardTitle>{card.title}</CardTitle>
              <CardDescription>{card.description}</CardDescription>
            </TextContent>
          </CardContent>
        </Link>
      ))}
    </CardsContainer>
  );
};
