import { Grid, Input } from "@material-ui/core";
import { Button } from "components";
import React, { useState } from "react";

const FileUpload = ({ onFileSelect }) => {
  const handleFileChange = event => {
    onFileSelect(event.target.files[0]);
  };

  return (
    <Grid container>
      <Grid item>
        <Input type="file" onChange={handleFileChange} />
      </Grid>
    </Grid>
  );
};

export default FileUpload;
