import * as Yup from "yup";

const validationDefault = {
  manualCountedQuantity: Yup.number()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório")
};

export const validationSchemaUpdateManualCounting =
  Yup.object().shape(validationDefault);
