import {
  Grid,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { Button } from "components";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import { NumericFormat } from "react-number-format";
import {
  AutoCompleteProductBaseFormik,
  AutoCompleteSellerFormik,
  KeyboardDatePicker
} from "shared";

import { refusalInstructions } from "../utils/refusalInstructions";
import {
  validationSchemaCreate,
  validationSchemaEdit
} from "./validationSchema";

const Component = ({ initialValues, onSubmit }, ref) => {
  const formik = useFormik({
    initialValues: initialValues || { toDate: null },
    validationSchema: initialValues
      ? validationSchemaEdit
      : validationSchemaCreate,
    onSubmit: onSubmit,
    validateOnChange: false
  });

  useImperativeHandle(ref, () => ({ resetForm: formik.resetForm }));

  const handleChangeRefusalInstructions = e => {
    const { value } = e.target;
    formik.setFieldValue("refusalInstructions", value);
    !!Number(value) < 3 && formik.setFieldValue("deadlineForAnalysis", "");
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AutoCompleteProductBaseFormik name="productBase" formik={formik} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AutoCompleteSellerFormik name="seller" formik={formik} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="prediction"
            defaultValue={formik.initialValues.prediction || null}
            label="Quantidade"
            type="number"
            value={formik.values.prediction || null}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.prediction || formik.submitCount > 0) &&
              !!formik.errors.prediction
            }
            helperText={
              (formik.touched.prediction || formik.submitCount > 0) &&
              formik.errors.prediction
            }
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <NumericFormat
            name="combinedPrice"
            label="Preço Kg/Un combinado"
            defaultValue={formik.initialValues.combinedPrice}
            customInput={TextField}
            fullWidth
            variant="outlined"
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            onBlur={formik.handleBlur}
            value={formik.values.combinedPrice}
            onValueChange={({ floatValue }) =>
              formik.setFieldValue("combinedPrice", floatValue)
            }
            error={
              (formik.touched.combinedPrice || formik.submitCount > 0) &&
              !!formik.errors.combinedPrice
            }
            helperText={
              (formik.touched.combinedPrice || formik.submitCount > 0) &&
              formik.errors.combinedPrice
            }
          />
        </Grid>
        {!initialValues && (
          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              label="Data prevista"
              fullWidth
              disableToolbar
              format="dd/MM/yyyy"
              inputVariant="outlined"
              defaultValue={formik.initialValues.toDate}
              value={formik.values.toDate}
              onChange={value => formik.setFieldValue("toDate", value)}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.toDate || formik.submitCount > 0) &&
                !!formik.errors.toDate
              }
              helperText={
                (formik.touched.toDate || formik.submitCount > 0) &&
                formik.errors.toDate
              }
              name="toDate"
              views={["date"]}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="blitzPercentage"
            defaultValue={formik.initialValues.blitzPercentage}
            label="% da blitz"
            placeholder="Entre 1 e 100"
            type="number"
            value={formik.values.blitzPercentage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.blitzPercentage || formik.submitCount > 0) &&
              !!formik.errors.blitzPercentage
            }
            helperText={
              (formik.touched.blitzPercentage || formik.submitCount > 0) &&
              formik.errors.blitzPercentage
            }
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            variant="outlined"
            error={
              (formik.touched.refusalInstructions || formik.submitCount > 0) &&
              !!formik.errors.refusalInstructions
            }
          >
            <InputLabel id="refusalInstructions-label">
              Devolução combinada
            </InputLabel>
            <Select
              labelId="refusalInstructions-label"
              id="refusalInstructions"
              name="refusalInstructions"
              value={formik.values.refusalInstructions}
              onChange={handleChangeRefusalInstructions}
              label="Instruções de recusa"
            >
              {Object.entries(refusalInstructions).map(([key, value]) => (
                <MenuItem
                  key={key}
                  value={key}
                  style={{ textTransform: "none" }}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {(formik.touched.refusalInstructions || formik.submitCount > 0) &&
                formik.errors.refusalInstructions}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="deadlineForAnalysis"
            defaultValue={formik.initialValues.deadlineForAnalysis}
            label="Tempo de análise"
            type="number"
            value={formik.values.deadlineForAnalysis}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={
              formik.values.refusalInstructions < 3 ||
              !formik.values.refusalInstructions
            }
            error={
              (formik.touched.deadlineForAnalysis || formik.submitCount > 0) &&
              !!formik.errors.deadlineForAnalysis
            }
            helperText={
              (formik.touched.deadlineForAnalysis || formik.submitCount > 0) &&
              formik.errors.deadlineForAnalysis
            }
            variant="outlined"
          />
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Button
            isLoading={formik.isSubmitting}
            variant="contained"
            type="submit"
            style={{ width: "10rem" }}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Form = forwardRef(Component);
