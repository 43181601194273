import { usePopup } from "hooks/usePopup";
import { useState, useEffect, useRef } from "react";
import productBaseService from "services/productBaseService";
import productLevelsService from "services/productLevelsService";
import { Drawer } from "shared";

import { Form } from "../Form";

export const ModalCreated = ({
  isOpen,
  refresh,
  close,
  productCategoryData,
  productSubCategoryData
}) => {
  const formRef = useRef(null);
  const [spans, setSpans] = useState([]);
  const { addPopup } = usePopup();

  const handleClose = () => close();

  const onSubmit = async data => {
    try {
      const { data: product } = await productBaseService.postProductBase(data);
      await productBaseService.updateImageProductBase({
        name: data.name,
        image: data.base64,
        id: product.data.id
      });

      addPopup({
        type: "success",
        title: "Produdo base criado com sucesso."
      });

      handleClose();

      refresh();
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao criar produto base."
        });
      }
    }
  };

  const init = async () => {
    try {
      const { data } = await productLevelsService.getProductLeves();
      setSpans(data);
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Ocorreu um erro",
          description: "Erro ao buscar dados"
        });
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      title="Novo produto"
    >
      <Form
        onSubmit={onSubmit}
        spans={spans}
        ref={formRef}
        productCategoryData={productCategoryData}
        productSubCategoryData={productSubCategoryData}
      />
    </Drawer>
  );
};
