import {
  Dialog,
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress
} from "@material-ui/core";
import { useState } from "react";

import { useStyles } from "./styles";

export const DialogConfirmDelete = ({ open, handleClose, confirm, name }) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleClear = () => {
    handleClose({
      isOpen: false,
      id: null
    });
  };

  const handleConfirmDialog = async () => {
    setIsLoading(true);
    await confirm();
    setIsLoading(false);
    handleClear();
  };

  return (
    <Dialog open={open} className={classes.root}>
      <Paper className={classes.paper}>
        <Grid>
          <Grid item xs={12}>
            <Typography className={classes.title}>Confirmação</Typography>
          </Grid>

          <Grid item xs={12} className={classes.box}>
            <Typography>Deseja excluir {name}?</Typography>
          </Grid>

          <Grid container item xs={12} className={classes.boxBtn}>
            <Grid item>
              <Button className={classes.buttonCancel} onClick={handleClear}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonConfirm}
                onClick={handleConfirmDialog}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} style={{ color: "#fff" }} />
                ) : (
                  "Ok"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};
