import { Box } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import printService from "services/printService";
import { InfoCard } from "shared";
import { generateBatchExternalCode } from "utils/generateBatchExternalCode";

import { BoxButton } from "../style";

export const ConfirmView = data => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const { onBack, action, batch, tagQty, product, handleClose } = data;

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      const request = {
        prePicking: () =>
          printService.printTagsV2({
            sku: product.sku,
            skuName: product.name,
            portionType: product.portionType,
            purchaseValue: product.purchaseValue,
            productBaseId: batch.product?.id,
            productBaseName: batch.product?.name,
            tagQuantity: tagQty,
            batchId: batch.id,
            batchExternalCode: generateBatchExternalCode(batch),
            uniqueTag: true,
            printer: "impressora1.E3"
          }),
        batch: () => {
          const subBatchId = batch.sub_batch[0].id;
          return printService.printBatchTag(subBatchId, {
            qty: Number(tagQty),
            isReceipt: true
          });
        }
      }[action];

      await request();

      addPopup({
        type: "success",
        title: "Tudo certo!",
        description: "Etiquetas enviadas para fila de impressão"
      });
      handleClose();
    } catch (error) {
      const errorData = error?.response?.data;
      addPopup({
        type: "error",
        title: "Erro ao imprimir",
        description: errorData?.message || errorData?.msg
      });
    } finally {
      setIsLoading(false);
    }
  };

  const typeText = {
    batch: "Lote",
    prePicking: "Pré-Picking"
  }[action];

  return (
    <>
      <InfoCard
        data={{
          Lote: { text: batch.id, useStrong: true },
          "Tipo de etiqueta": { text: typeText },
          "Quantidade de etiquetas": { text: tagQty },
          "Qual é o porcionamento(SKU)": { text: product?.name }
        }}
      />
      <BoxButton>
        <Button
          startIcon={<NavigateBeforeIcon fontSize="large" />}
          onClick={onBack}
          fullWidth
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          isLoading={isLoading}
          onClick={handleConfirm}
          fullWidth
        >
          Confirmar
        </Button>
      </BoxButton>
    </>
  );
};
