import {
  Accordion,
  AccordionSummary,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import batchServiceV3 from "services/batchServiceV3";
import { mapType } from "utils";

import { PopupContent, ProductInfo, ProductText } from "../StepsPopup/style";
import { ButtonsContent, StyledBox, StyledDialog, Text } from "./style";

export const EditTaxEntryPopup = ({
  openEditTaxEntryPopup,
  handleCloseEditTaxEntryPopup,
  batchData
}) => {
  const { addPopup } = usePopup();
  const typeOfMeasure = mapType(batchData.product?.purchase_type);

  const [alertPriceDiff, setAlertPriceDiff] = useState(null);
  const [alertQuantityDiff, setAlertQuantityDiff] = useState(null);
  const initialPrice = batchData?.purchase_item?.purchase?.combined_price;
  const quantity = batchData?.quantity;
  const [confirmedQty, setConfirmedQty] = useState(null);
  const [price, setPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeConfirmedQty = e => {
    const { value: inputValue } = e.target;
    const value = parseFloat(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setConfirmedQty(!isValid ? "" : value);
  };

  const handleChangeBoxPrice = e => {
    const { value: inputValue } = e.target;
    const value = parseFloat(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setPrice(!isValid ? "" : value);
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);

      const isValid =
        !isNaN(parseFloat(confirmedQty)) || !isNaN(parseFloat(price));
      if (!isValid || !confirmedQty || !price) {
        return addPopup({
          type: "error",
          title: "Informação obrigatória",
          description: "Insira um valor válido"
        });
      }
      await batchServiceV3.patchBatch(batchData.id, {
        confirmed_quantity: confirmedQty,
        confirmed_price: price
      });
      handleCloseEditTaxEntryPopup();
      addPopup({
        type: "success",
        title: "Informações atualizadas com sucesso"
      });
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao editar informações"
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const priceDiff = initialPrice - price;
    const quantityDiff = quantity - confirmedQty;
    const valueMsg = value => (value < 0 ? "acima" : "abaixo");
    const formattedPrice = value =>
      Math.abs(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });

    const formattedQty = value => {
      const valueAbs = Math.abs(value);
      return typeOfMeasure === "Unidade"
        ? `${valueAbs} unidade(s)`
        : `${valueAbs} kg(s)`;
    };

    setAlertPriceDiff(
      price && priceDiff
        ? `O preço está ${formattedPrice(priceDiff)} ${valueMsg(
            priceDiff
          )} do combinado`
        : null
    );

    setAlertQuantityDiff(
      confirmedQty && quantityDiff
        ? `A quantidade está ${formattedQty(quantityDiff)} ${valueMsg(
            quantityDiff
          )} do combinado`
        : null
    );
  }, [price, confirmedQty]);

  useEffect(() => {
    const { confirmed_price, confirmed_quantity } = batchData;
    setPrice(openEditTaxEntryPopup ? confirmed_price : null);
    setConfirmedQty(openEditTaxEntryPopup ? confirmed_quantity : null);
  }, [openEditTaxEntryPopup]);

  return (
    <StyledDialog
      open={openEditTaxEntryPopup}
      handleClose={handleCloseEditTaxEntryPopup}
      title="Editar entrada"
    >
      <PopupContent>
        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ background: "#F5F8F3" }}
          >
            <Typography>
              Produto: <strong>{batchData.product?.name}</strong>
            </Typography>
          </AccordionSummary>

          <ProductInfo>
            <ProductText>
              Seller: <span>{batchData.seller?.name}</span>
            </ProductText>
            <ProductText>
              Porção: <span>{mapType(batchData.product?.purchase_type)}</span>
            </ProductText>
          </ProductInfo>
        </Accordion>
        <StyledBox>
          <Text>Qual a quantidade que o seller trouxe?</Text>
          <TextField
            variant="outlined"
            type="number"
            placeholder={typeOfMeasure}
            fullWidth
            value={confirmedQty}
            onChange={handleChangeConfirmedQty}
          />
          <Text style={{ color: "#eb001b", fontWeight: 400, fontSize: "1rem" }}>
            {alertQuantityDiff}
          </Text>

          <Text>Qual o preço por {typeOfMeasure}?</Text>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            value={price}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
            onChange={handleChangeBoxPrice}
          />
          <Text style={{ color: "#eb001b", fontWeight: 400, fontSize: "1rem" }}>
            {alertPriceDiff}
          </Text>
        </StyledBox>
        <ButtonsContent>
          <Button
            variant="outlined"
            style={{ color: "#757575" }}
            onClick={handleCloseEditTaxEntryPopup}
            isLoading={isLoading}
            fullWidth
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            fullWidth
            onClick={handleConfirm}
            isLoading={isLoading}
          >
            Salvar alterações
          </Button>
        </ButtonsContent>
      </PopupContent>
    </StyledDialog>
  );
};
