import { Box, CircularProgress, TextField, useTheme } from "@material-ui/core";
import { Button } from "components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import batchServiceV3 from "services/batchServiceV3";
import { Dialog } from "shared";
import * as Yup from "yup";

import { Text } from "../style";

export const BatchUnblockPopup = ({ open, handleClose, data, refresh }) => {
  const theme = useTheme();
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    productQuantity: "",
    reason: ""
  };

  const validationSchema = Yup.object().shape({
    productQuantity: Yup.number()
      .positive()
      .typeError("Deve ser um número")
      .required("Campo obrigatório")
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          return !(data.purchaseType === "Un" && !Number.isInteger(value));
        }
      ),
    reason: Yup.string()
      .min(5, "Mínimo de 5 caracteres")
      .required("Campo obrigatório")
  });

  const onConfirm = async values => {
    setLoading(true);
    try {
      await batchServiceV3.unblockBatch(data.id, {
        quantity: values.productQuantity,
        reason: values.reason
      });
      addPopup({
        type: "success",
        title: "Quantidade disponível atualizada com sucesso!"
      });
      handleClose();
      refresh();
    } catch {
      addPopup({
        type: "error",
        title: "Houve um erro ao desbloquear o Lote"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Desbloquear Lote"
      maxWidth="32rem"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onConfirm}
      >
        <Form>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin="2rem 0"
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <>
              <Text>Qual a quantidade do produto a ser desbloqueada?</Text>

              <Field
                as={TextField}
                fullWidth
                name="productQuantity"
                placeholder={data.purchaseType}
                type="number"
                variant="outlined"
              />
              <ErrorMessage
                name="productQuantity"
                component="div"
                style={{ color: "red" }}
              />

              <Text>Qual o motivo da alteração?</Text>

              <Field
                as={TextField}
                fullWidth
                name="reason"
                placeholder="Motivo"
                variant="outlined"
              />
              <ErrorMessage
                name="reason"
                component="div"
                style={{ color: "red" }}
              />

              <Box
                display="flex"
                marginTop="1rem"
                style={{ gap: "1rem", marginTop: "80px" }}
              >
                <Button onClick={handleClose} variant="text" fullWidth>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  isLoading={loading}
                  fullWidth
                >
                  Salvar
                </Button>
              </Box>
            </>
          )}
        </Form>
      </Formik>
    </Dialog>
  );
};
