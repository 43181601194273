import { Grid, Box, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import React, { useEffect, useState } from "react";
import assetService from "services/assetsService";
import { AutoCompleteAssets, AutoCompleteSeller, Dialog } from "shared";
import { errorsRegisterMap } from "utils";

const initialAssetForm = {
  sellerId: null,
  sellerCode: "",
  sellerName: "",
  transactionId: "",
  assets: []
};

export const DialogCreateRegister = props => {
  const { isOpen, setIsOpen, refresh } = props;
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setBtn] = useState(true);
  const [startBtnDisabled, setStartBtnDisabled] = useState(true);
  const [assetForm, setAssetForm] = useState(initialAssetForm);
  const [transactionStarted, setTransactionStarted] = useState(false);
  const [assetsQuantity, setAssetsQuantity] = useState(0);

  const onSubmitHandler = async e => {
    e.preventDefault();
    if (!transactionStarted) {
      const payload = {
        sellerId: assetForm.sellerId,
        sellerName: assetForm.sellerName,
        sellerCode: assetForm.sellerCode
      };

      try {
        setIsLoading(true);
        const { data } = await assetService.postTransaction(payload);

        addPopup({
          type: "success",
          title: "Transação iniciada."
        });
        setAssetForm(prevState => ({
          ...prevState,
          transactionId: data.id,
          assets: [
            ...prevState.assets,
            {
              assetId: null,
              assetName: "",
              quantity: "",
              uniqueKey: Math.random().toString(36)
            }
          ]
        }));
        setTransactionStarted(true);
        setAssetsQuantity(1);
        return;
      } catch (err) {
        addPopup({
          type: "error",
          title: errorsRegisterMap(err?.response?.data?.message)
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      const payload = {
        assets: assetForm.assets.map(asset => ({
          ...asset,
          sellerCode: assetForm.sellerCode,
          sellerId: assetForm.sellerId,
          sellerName: assetForm.sellerName,
          transactionId: assetForm.transactionId
        }))
      };

      try {
        setIsLoading(true);

        await assetService.createOrUpdateLedger(payload.assets);

        addPopup({
          type: "success",
          title: "Transação registrada."
        });

        setIsOpen(false);
        setAssetForm(initialAssetForm);
        setTransactionStarted(false);
        setAssetsQuantity(1);
        refresh();
      } catch (err) {
        addPopup({
          type: "error",
          title: "Erro ao completar transação"
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClose = () => {
    setAssetForm(initialAssetForm);
    setTransactionStarted(false);
    setAssetsQuantity(1);
    setIsOpen(false);
    if (transactionStarted) refresh();
  };

  const checkForm = form => {
    const mainFormValid = Object.values(form).every(value => !!value);

    if (!mainFormValid) return false;

    const assetsValid = form.assets.every(asset => {
      return Object.values(asset).every(
        value => value !== undefined && value !== null && value !== ""
      );
    });

    return assetsValid;
  };

  useEffect(() => {
    setBtn(!checkForm(assetForm));
  }, [assetForm]);

  const handleAsset = (value, index) => {
    const updatedAssets = [...assetForm.assets];
    updatedAssets[index] = {
      assetId: parseInt(value?.id),
      assetName: value?.name,
      quantity: "",
      uniqueKey: updatedAssets[index]?.uniqueKey || Math.random().toString(36)
    };

    setAssetForm(prevState => ({ ...prevState, assets: updatedAssets }));
  };

  const handleSeller = value => {
    setAssetForm(prevState => ({
      ...prevState,
      sellerId: parseInt(value?.id),
      sellerCode: value?.code,
      sellerName: value?.name
    }));
    setStartBtnDisabled(false);
  };

  const handleQty = (value, index) => {
    const updatedAssets = assetForm.assets.map((asset, idx) => {
      if (idx === index) {
        return {
          ...asset,
          quantity: Number(value)
        };
      }
      return asset;
    });

    setAssetForm(prevState => ({ ...prevState, assets: updatedAssets }));
  };

  const handleAssetQuantity = () => {
    setAssetsQuantity(assetsQuantity + 1);
    setAssetForm(prevState => ({
      ...prevState,
      assets: [
        ...prevState.assets,
        {
          assetId: null,
          assetName: "",
          quantity: "",
          uniqueKey: Math.random().toString(36)
        }
      ]
    }));
  };

  const handleRemoveAsset = () => {
    if (assetForm.assets.length > 0) {
      const updatedAssets = [...assetForm.assets];
      updatedAssets.pop();

      setAssetsQuantity(prevState => prevState - 1);
      setAssetForm(prevState => ({ ...prevState, assets: updatedAssets }));
    }
  };

  return (
    <>
      <Dialog open={isOpen} handleClose={handleClose} title="Nova transação">
        <Box component="form" onSubmit={onSubmitHandler} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoCompleteSeller
                onChange={(_, newInputValue) => {
                  handleSeller(newInputValue);
                }}
                disabled={transactionStarted}
              />
            </Grid>

            {transactionStarted && (
              <>
                {assetForm.assets.map((asset, index) => (
                  <React.Fragment key={asset.uniqueKey}>
                    <Grid item xs={12} sm={6}>
                      <AutoCompleteAssets
                        onChange={(_, newInputValue) => {
                          handleAsset(newInputValue, index);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        style={{ width: "100%" }}
                        onChange={event => {
                          handleQty(event.target.value, index);
                        }}
                        type="number"
                        required
                        label="Movimentação"
                        value={assetForm.assets[index]?.quantity}
                        autoComplete="off"
                        variant="outlined"
                      />
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item justifyContent="flex-start">
                  <Button
                    variant="contained"
                    size="large"
                    color="black"
                    onClick={handleAssetQuantity}
                    style={{
                      backgroundColor: "#5a9952"
                    }}
                    startIcon={<AddIcon />}
                  >
                    Adicionar ativo
                  </Button>
                </Grid>

                <Grid item justifyContent="flex-start">
                  <Button
                    variant="contained"
                    size="large"
                    color="black"
                    onClick={handleRemoveAsset}
                    style={{
                      backgroundColor: "red"
                    }}
                  >
                    Remover ativo
                  </Button>
                </Grid>

                <Grid container item justifyContent="flex-end">
                  <Button
                    disabled={isBtnDisabled}
                    isLoading={isLoading}
                    type="submit"
                    variant="contained"
                    size="large"
                  >
                    Registrar
                  </Button>
                </Grid>
              </>
            )}
            {!transactionStarted && (
              <Grid container item justifyContent="flex-end">
                <Button
                  disabled={startBtnDisabled}
                  isLoading={isLoading}
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Iniciar transação
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};
