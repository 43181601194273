import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import depotService from "services/depotService";
import { InfoCard } from "shared";
import { packagingProductBaseConfigFormatter } from "utils/depotDataFormatter";

import { Dialog, FormContent, InputBoxContent } from "./style";

const Component = ({ refresh }, ref) => {
  const { addPopup } = usePopup();
  const [isOpen, setIsOpen] = useState(false);
  const [batch, setBatch] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [fromQuantity, setFromQuantity] = useState(null);
  const [toQuantity, setToQuantity] = useState(null);
  const [toPackagingId, setToPackagingId] = useState(null);
  const [packagesData, setPackagesData] = useState([]);

  const disableButton = !fromQuantity || !toQuantity || !toPackagingId;

  const getPackagingProductBaseConfig = async () => {
    setIsLoading(true);
    try {
      const { data } = await depotService.getPackagingProductBaseConfig({
        productBaseId: batch.productBaseId,
        paginate: false
      });

      setPackagesData(packagingProductBaseConfigFormatter(data));
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao buscar embalagens",
        description: err?.response?.msg ?? err?.message ?? err
      });
      handleClose();
    }
    setIsLoading(false);
  };

  const clearState = () => {
    setFromQuantity(null);
    setToQuantity(null);
    setToPackagingId(null);
    setBatch({});
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = batch => {
    setBatch(batch);
    setIsOpen(true);
  };

  const onSubmit = async e => {
    setIsLoading(true);
    const payload = {
      batchId: batch.batchId,
      fromWarehouseTypeId: batch.warehouseType?.id,
      fromDepotId: batch.depot?.id,
      fromPackagingId: batch.packagingType?.id,
      fromQuantity,
      toWarehouseTypeId: batch.warehouseType?.id,
      toDepotId: batch.depot?.id,
      toPackagingId,
      toQuantity
    };

    try {
      await depotService.moveBatch(payload);
      refresh();
      addPopup({
        type: "success",
        title: "Tudo certo!",
        description: "Lote convertido com sucesso."
      });
      handleClose();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao converter lote",
        description: err?.response?.msg ?? err?.message ?? err
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeFromQuantity = e => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    const isWeight = batch.packagingType?.id === 1;
    const quantity = isWeight ? parseFloat(value, 10) : parseInt(value, 10);
    const isValid =
      (!isNaN(quantity) && quantity <= batch.quantity) || !quantity;

    setFromQuantity(prev => (isValid ? quantity : prev));
  };

  const handleChangeToQuantity = e => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    const isWeight = toPackagingId === 1;
    const quantity = isWeight ? parseFloat(value, 10) : parseInt(value, 10);
    const isValid = (!isNaN(quantity) && quantity > 0) || !quantity;

    setToQuantity(prev => (isValid ? quantity : prev));
  };

  useImperativeHandle(ref, () => ({ handleOpen }));

  useEffect(() => {
    !isOpen && clearState();
  }, [isOpen]);

  useEffect(() => {
    !!isOpen && !!batch.id && getPackagingProductBaseConfig();
  }, [isOpen]);

  const children = {
    false: (
      <Box
        style={{
          display: "flex",
          height: "calc(100% - 6rem)",
          flexDirection: "column"
        }}
      >
        <InfoCard
          data={{
            ID: { text: batch?.batchId },
            Produto: { text: batch.productBaseName },
            "Embalagem atual": { text: batch.packagingType?.name },
            "Quantidade atual": { text: batch.quantity }
          }}
        />

        <Box
          mt="2rem"
          display="flex"
          flexDirection="column"
          width="100%"
          style={{ gap: "1rem" }}
        >
          <Typography style={{ color: "#757575", fontWeight: 400 }}>
            Qual é a quantidade que será convertida?
          </Typography>

          <InputBoxContent>
            <TextField
              disabled
              variant="outlined"
              fullWidth
              name="packageType"
              label="Embalagem atual"
              value={batch.packagingType?.name}
            />

            <TextField
              variant="outlined"
              fullWidth
              name="quantity"
              label="Quantidade"
              value={fromQuantity}
              onChange={handleChangeFromQuantity}
              type="number"
            />
          </InputBoxContent>
        </Box>

        <FormContent component="form" noValidate onSubmit={onSubmit}>
          <Box
            mt="2rem"
            display="flex"
            flexDirection="column"
            width="100%"
            style={{ gap: "1rem" }}
          >
            <Typography style={{ color: "#757575", fontWeight: 400 }}>
              Para qual embalagem e qual quantidade será convertida?
            </Typography>
            <InputBoxContent>
              <TextField
                select
                variant="outlined"
                fullWidth
                name="package"
                label="Tipo"
                onChange={e => {
                  setToPackagingId(e.target.value);
                  setToQuantity("");
                }}
              >
                {(packagesData || []).map(packageType => (
                  <MenuItem key={packageType.id} value={packageType.id}>
                    {packageType.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                fullWidth
                name="quantity"
                label="Quantidade"
                onChange={handleChangeToQuantity}
                value={toQuantity}
                type="number"
              />
            </InputBoxContent>
          </Box>

          <Box style={{ paddingBlock: "1rem" }}>
            <Button
              isFullScreen
              variant="contained"
              type="submit"
              style={{ padding: "0.8rem 4rem" }}
              disabled={disableButton}
              isLoading={isLoading}
            >
              Confirmar
            </Button>
          </Box>
        </FormContent>
      </Box>
    ),
    true: (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    )
  }[String(isLoading)];

  return (
    <Dialog open={isOpen} handleClose={handleClose} title="Converter lote">
      {children}
    </Dialog>
  );
};

export const ConvertBatchDialog = forwardRef(Component);
