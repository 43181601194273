const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return { date, time };
};

export const dataFormatter = data => {
  return (data || []).map(packaging => {
    const formattedCreatedDate = formatDateTime(packaging.created).date;
    const formattedCreatedTime = formatDateTime(packaging.created).time;
    const { height, width, length } = packaging;
    const packagingHeight = height ?? 0;
    const packagingWidth = width ?? 0;
    const packagingLength = length ?? 0;
    return {
      ...packaging,
      id: packaging.id,
      createdDateTime: `${formattedCreatedDate} ${formattedCreatedTime}`,

      size: `${packagingHeight} cm x ${packagingWidth} cm x ${packagingLength}`
    };
  });
};
