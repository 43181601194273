import { addDays } from "date-fns";
import { mapPurchaseType } from "utils";

const formatDateTime = dateTime => {
  const date = new Date(dateTime);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear() % 100;

  const formattedDate = `${String(day).padStart(2, "0")}/${String(
    month
  ).padStart(2, "0")}/${year}`;

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;

  return `${formattedDate} ${formattedTime}`;
};

const formatterQuantity = quantity => {
  return [quantity || 0].toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3
  });
};

const formatDate = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return {
    date,
    time
  };
};

const formatterSubBatch = (subBatch, typeOfMeasure) => {
  return (subBatch || [])
    ?.map(batch => {
      const formattedQty = formatterQuantity(batch.quantity);
      const subBatchQty =
        typeOfMeasure === "Kg" ? `${formattedQty} Kg` : `${batch.quantity} Un`;
      return {
        ...batch,
        subBatchQty,
        stageName: batch.stage?.name
      };
    })
    .sort((subBatchPrev, subBatchANext) =>
      subBatchPrev.classification.localeCompare(subBatchANext.classification)
    );
};

export const formatterData = batchData => {
  return (batchData || [])?.map(batch => {
    const typeOfMeasure = mapPurchaseType(batch.product?.purchase_type);
    const formattedBatchQty = formatterQuantity(batch.quantity);
    const formattedUnclassifiedQty = formatterQuantity(batch.unclassified_qty);

    const batchQuantity =
      typeOfMeasure === "Kg"
        ? `${formattedBatchQty} Kg`
        : `${batch.quantity} Un`;

    const formattedUnclassifiedQuantity =
      typeOfMeasure === "Kg"
        ? `${formattedUnclassifiedQty} Kg`
        : `${batch.unclassified_qty} Un`;

    const formattedAvailableQuantity =
      typeOfMeasure === "Kg"
        ? `${formatterQuantity(batch.available_quantity)} Kg`
        : `${batch.available_quantity} Un`;

    const deadlineForAnalysis =
      batch.purchase_item?.purchase?.deadline_for_analysis;
    const receiptDate = new Date(batch.receipt_date);
    const selectUntil =
      deadlineForAnalysis &&
      formatDateTime(
        receiptDate.setDate(receiptDate.getDate() + deadlineForAnalysis)
      );

    const hasDeadline = ["3", "4"].includes(
      batch.purchase_item?.purchase?.refusal_instructions?.split(" ")[0]
    );

    const addDaysToDate =
      hasDeadline &&
      addDays(
        new Date(batch.updated),
        batch.purchase_item.purchase.deadline_for_analysis
      );

    const deadlineExpired = hasDeadline && addDaysToDate <= new Date();
    const dateDeadline = hasDeadline && formatDate(addDaysToDate).date;

    return {
      ...batch,
      sellerName: batch.seller.name,
      productName: batch.product.name,
      receiptDateTime: formatDateTime(batch.receipt_date),
      batchQuantity,
      formattedUnclassifiedQuantity,
      formattedAvailableQuantity,
      unclassifiedQuantity: batch.unclassified_qty,
      availableQuantity: batch.available_quantity,
      subBatch: formatterSubBatch(batch.sub_batch, typeOfMeasure),
      selectUntil,
      hasDeadline,
      deadlineExpired: hasDeadline ? deadlineExpired : undefined,
      dateDeadline: hasDeadline ? dateDeadline : undefined
    };
  });
};
