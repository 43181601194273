import {
  Typography,
  Button,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { usePopup } from "hooks/usePopup";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import purchaseForecastService from "services/purchaseForecastService";
import { KeyboardDatePicker, Dialog } from "shared";

import { useStyles } from "./styles";

export const DialogUpload = ({
  isOpen,
  handleClose,
  handleInactiveProduct,
  handleConfirmForecast,
  refresh
}) => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [fileName, setFileName] = useState("");
  const [date, setDate] = useState(null);

  const onDropAccepted = useCallback(acceptedFiles => {
    setFileName(acceptedFiles[0]?.name);
    const formData = new FormData();
    formData.append("estoque", acceptedFiles[0]);
    setFile(formData);
  }, []);

  const handleCloseDialog = () => {
    setDate(null);
    handleClose(false);
    setProgress(null);
    setFile(null);
    setFileName("");
  };

  const handleUpdateDate = date => {
    setDate(date);
  };

  const handleUpdateStock = async () => {
    try {
      const { api, url } = purchaseForecastService.uploadFilePurchaseForecast();

      const productList = await api.put(url, file, {
        onUploadProgress: e => {
          const newProgress = parseInt(Math.round((e.loaded * 100) / e.total));
          setProgress(newProgress);
        },
        params: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      });

      addPopup({
        type: "success",
        title: "Upload realizado com sucesso!"
      });
      refresh();
      handleCloseDialog();
      handleInactiveProduct(productList.data);
      await handleConfirmForecast();
    } catch (error) {
      setProgress(null);
      const errorMessage =
        error?.response?.data?.msg || error?.response?.data?.message;

      addPopup({
        type: "error",
        title: "O upload falhou",
        description: errorMessage || ""
      });
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject
  } = useDropzone({
    onDropAccepted,
    accept: ".xlsx, .xls, .xml, .csv"
  });

  return (
    <Dialog open={isOpen} maxWidth="30rem" handleClose={handleCloseDialog}>
      <KeyboardDatePicker
        placeholder="Data da previsão"
        fullWidth
        disableToolbar
        format="dd/MM/yyyy"
        inputVariant="outlined"
        value={date}
        onChange={handleUpdateDate}
        views={["date"]}
      />

      <div
        {...getRootProps({
          className: `${classes.dropzone}
          ${isDragActive && classes.dragActive}
          ${isDragAccept && classes.dragAccept}
          ${isDragReject && classes.dragReject}`
        })}
      >
        <input {...getInputProps()} disabled={progress !== null} />
        {progress === 100 ? (
          <Typography style={{ display: "flex", gap: 10 }}>
            <CheckCircleOutlineIcon />
            Arquivo enviado com sucesso
          </Typography>
        ) : isDragReject ? (
          <Typography>O tipo desse arquivo não é aceito</Typography>
        ) : (
          <Typography>
            {fileName === ""
              ? "Arraste e solte o arquivo aqui ou clique para selecionar o arquivo"
              : fileName}
          </Typography>
        )}
      </div>

      {progress === null || progress === 100 ? (
        <Button
          variant="contained"
          className={classes.btnUpload}
          onClick={handleUpdateStock}
          disabled={progress !== null || date === null}
        >
          {progress === 100 ? (
            <CircularProgress size={24} style={{ color: "#7080df" }} />
          ) : (
            "Enviar"
          )}
        </Button>
      ) : (
        <div className={classes.progress}>
          <LinearProgress variant="buffer" value={progress} valueBuffer={100} />
        </div>
      )}
    </Dialog>
  );
};
