import { Box, Typography } from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import storageService from "services/storageService";
import { Dialog } from "shared";
import { errorsRegisterMap } from "utils";

export const DialogConfirmType = ({
  convert,
  data,
  refresh,
  setIsOpen,
  isOpen,
  name
}) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen({
      isOpen: false,
      data: {}
    });
  };

  const onSubmitHandler = async () => {
    try {
      setIsLoading(true);
      await storageService.sendStorage({
        ...data,
        confirm: true
      });

      addPopup({
        type: "success",
        title: "Registro criado com sucesso."
      });

      refresh();
      handleClose();
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: errorsRegisterMap(err?.message)
        });
      }

      setIsOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title={`O produto ${name} é do tipo ${
        data?.storageType === 2 ? "Quilo" : "Unidade"
      }!`}
    >
      <Typography>
        <strong>
          Esse produto não deve ser registrado com a medida{" "}
          {data?.storageType === 2 ? "Unidade" : "Quilo"}, o ideal é{" "}
          {data?.storageType === 2 ? "Quilo" : "Unidade"}. Podemos converter
          para um valor APROXIMADO igual a {convert && convert.toFixed(2)}{" "}
          {data?.storageType === 2 ? "Kg" : "Un"}. Esse valor não é muito
          confiável, você deseja fazer essa conversão?
        </strong>
      </Typography>

      <Box
        display="flex"
        justifyContent="flex-end"
        style={{ gap: "0.6rem" }}
        mt="1rem"
      >
        <Button
          color="warning"
          variant="contained"
          size="large"
          onClick={handleClose}
        >
          Cancelar
        </Button>

        <Button
          size="large"
          variant="contained"
          onClick={onSubmitHandler}
          isLoading={isLoading}
        >
          Confirmar
        </Button>
      </Box>
    </Dialog>
  );
};
