import { CircularProgress, Box } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { addDays, differenceInDays, startOfDay } from "date-fns";
import { useEffect, useState } from "react";

import {
  ActionContent,
  BatchInfoContent,
  BatchInfoSubText,
  BatchInfoText,
  ButtonsContent,
  LoadingContent,
  StyledButton,
  StyledDialog,
  useStyles
} from "../../style";

export const UpdateDeadlinePopup = ({
  openPopup,
  handleCloseDeadlinePopup,
  data,
  loading,
  handleUpdateDeadlineConfirm
}) => {
  const classes = useStyles();
  const [combinedReturnDeadline, setCombinedReturnDeadline] = useState(1);
  const [selectedDate, setSelectedDate] = useState(addDays(new Date(), 1));
  const textDescription = loading
    ? "Confirmando, aguarde..."
    : "Para qual data deseja alterar o prazo de devolução?";

  const handleChangeDate = date => {
    const newCombinedReturnDeadline = differenceInDays(
      startOfDay(new Date(date)),
      startOfDay(new Date())
    );
    setCombinedReturnDeadline(newCombinedReturnDeadline);
    setSelectedDate(date);
  };

  const actionContent = {
    true: (
      <LoadingContent>
        <CircularProgress color="primary" />
      </LoadingContent>
    ),
    false: (
      <ButtonsContent>
        <StyledButton
          variant="contained"
          fullWidth
          name="cancelButton"
          onClick={handleCloseDeadlinePopup}
          style={{ textTransform: "none" }}
        >
          Cancelar
        </StyledButton>

        <StyledButton
          variant="contained"
          fullWidth
          name="confirmButton"
          onClick={() => handleUpdateDeadlineConfirm(combinedReturnDeadline)}
          style={{ textTransform: "none" }}
          disabled={!combinedReturnDeadline}
        >
          Alterar prazo
        </StyledButton>
      </ButtonsContent>
    )
  }[loading];

  useEffect(() => {
    setSelectedDate(addDays(new Date(), 1));
    setCombinedReturnDeadline(1);
  }, [openPopup]);

  return (
    <StyledDialog
      open={openPopup}
      handleClose={handleCloseDeadlinePopup}
      title="Alterar prazo"
    >
      <BatchInfoContent>
        <BatchInfoText>
          Lote: <strong>{data?.batchId}</strong>
        </BatchInfoText>
        <BatchInfoText>
          Produto: <span>{data?.productName}</span>
        </BatchInfoText>
        <BatchInfoText>
          Seller: <span>{data?.seller}</span>
        </BatchInfoText>
      </BatchInfoContent>

      <ActionContent>
        <BatchInfoSubText>{textDescription}</BatchInfoSubText>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <KeyboardDatePicker
            minDate={addDays(new Date(), 1)}
            disableToolbar
            format="dd/MM/yyyy"
            margin="normal"
            value={selectedDate}
            onChange={handleChangeDate}
            className={classes.dateField}
          />
        </Box>
        {actionContent}
      </ActionContent>
    </StyledDialog>
  );
};
