import { Box, Container, IconButton, Typography } from "@material-ui/core";
import MuiDialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";

import { useStyles } from "./styles";

const Dialog = ({
  handleClose,
  open,
  title,
  children,
  className,
  maxWidth,
  height,
  titleButton,
  pd,
  pdMobile,
  ...rest
}) => {
  const classes = useStyles({ maxWidth, height, pd, pdMobile });

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      className={`${classes.root} ${className}`}
      {...rest}
    >
      <Container className={classes.container}>
        <Box className={classes.containerTitle}>
          <Typography className={classes.title}>
            {title} {titleButton}
          </Typography>

          <IconButton className={classes.closeBtn} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </Container>
    </MuiDialog>
  );
};

export default Dialog;
