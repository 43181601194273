import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import PageLoader from "components/PageLoad";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import productBaseService from "services/productBaseService";
import rankingSellerService from "services/rakingSellerService";

import { AutoCompleteProduct } from "./components/AutoCompletProduct";
import { RankingComponent } from "./components/RankingComponent";
import { Content, SearchContent } from "./style";

export const RankingSeller = () => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);
  const [loadingRanking, setLoadingRanking] = useState(false);
  const [productBaseData, setProductBaseData] = useState([]);
  const [rankingData, setRankingData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);

  const getProductBase = async () => {
    setLoading(true);
    try {
      const { data } = await productBaseService.getProductBase({
        status: "active",
        pageSize: 10000
      });
      setProductBaseData(data.results);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar produtos",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const getRankingData = async () => {
    setLoadingRanking(true);
    try {
      const { data } = await rankingSellerService.getRanking({
        productId: selectedProduct
      });
      setRankingData(data);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar ranking",
        description: errorMessage
      });
    } finally {
      setLoadingRanking(false);
    }
  };

  const handleSelectProduct = product => {
    const id = product?.id ?? 0;
    setSelectedProduct(id);
  };

  useEffect(() => {
    getProductBase();
  }, []);

  useEffect(() => {
    !!selectedProduct && getRankingData();
  }, [selectedProduct]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <ContainerPage id="back-to-top-anchor">
      <Box>
        <TitlePage>Ranking dos sellers</TitlePage>
      </Box>
      <Content>
        <SearchContent>
          <AutoCompleteProduct
            options={productBaseData}
            onChange={handleSelectProduct}
          />
        </SearchContent>
        <RankingComponent rankingData={rankingData} loading={loadingRanking} />
      </Content>
    </ContainerPage>
  );
};
