import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  userName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.18rem",
    color: "#414141",
    textAlign: "center",
    marginBottom: "1rem"
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    margin: "0.5rem"
  },
  boxInformations: {
    display: "flex"
  },
  title: {
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 400,
    fontsize: "1rem",
    lineheight: "1rem",
    color: "#353535",
    "& > span": {
      display: "flex",
      flexDirection: "column  "
    }
  },
  subTitle: {
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 400,
    fontsize: "0.8rem",
    lineheight: "0.8rem",
    color: "#757575"
  },
  formControlLabel: {
    marginBottom: "0.7rem"
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5rem"
  }
}));
