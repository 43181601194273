import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { MainContent, SearchContent } from "components/Contents";
import { SearchInput } from "components/Inputs";
import { usePopup } from "hooks/usePopup";
import { useEffect, useRef, useState } from "react";
import productApiServices from "services/productApiServices";
import { useDebaunce } from "utils";

import { AddButton } from "./components/AddButton";
import { CategoriesTable } from "./components/CategoriesTable";
import { DialogCreate } from "./components/DialogsPopup/DialogCreate";
import { DialogUpdate } from "./components/DialogsPopup/DialogUpdate";

export const ProductCategories = () => {
  const { addPopup } = usePopup();
  const [categoriesData, setCategoriesData] = useState([]);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });
  const [categoryName, setCategoryName] = useState("");
  const [categoryAbbreviation, setCategoryAbbreviation] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [categoryIndex, setCategoryIndex] = useState(null);

  const getInitData = async () => {
    try {
      setLoading(true);
      setCategoriesData([]);
      const params = {
        search
      };
      const { data } = await productApiServices.getCategories(params);
      setCategoriesData(data);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar categorias",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateCategory = async () => {
    try {
      setLoadingFetch(true);
      const data = {
        name: categoryName,
        abbreviation: categoryAbbreviation,
        index: categoryIndex
      };
      await productApiServices.postCategories(data);
      handleCloseDialogs();
      getInitData();
      addPopup({
        type: "success",
        title: "Nova categoria criada com sucesso!",
        description: `Nome: ${categoryName} Sigla: ${categoryAbbreviation}`
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao criar categoria",
        description: errorMessage
      });
    } finally {
      setLoadingFetch(false);
    }
  };

  const handleUpdateCategory = async () => {
    try {
      setLoadingFetch(true);
      const data = {
        name: categoryName,
        abbreviation: categoryAbbreviation,
        index: categoryIndex
      };
      await productApiServices.patchCategories({
        id: categoryId,
        data
      });
      handleCloseDialogs();
      getInitData();
      addPopup({
        type: "success",
        title: "Categoria atualizada com sucesso!",
        description: `Nome: ${categoryName} Sigla: ${categoryAbbreviation}`
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao atualizar categoria",
        description: errorMessage
      });
    } finally {
      setLoadingFetch(false);
    }
  };

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  function handleCloseDialogs() {
    setCategoryId(null);
    setCategoryName("");
    setCategoryAbbreviation("");
    setOpenCreate(false);
    setOpenUpdate(false);
    setCategoryIndex(null);
  }

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleOpenUpdate = categoryId => {
    const category = categoriesData.find(({ id }) => id === categoryId);
    const { name, abbreviation, id, index } = category;
    setCategoryId(id);
    setCategoryName(name);
    setCategoryAbbreviation(abbreviation);
    setOpenUpdate(true);
    setCategoryIndex(index);
  };

  const handleChangeCategory = e => {
    const { value } = e.target;
    setCategoryName(value);
  };

  const handleChangeAbbreviation = e => {
    const value = e.target.value?.toUpperCase().slice(0, 2);
    setCategoryAbbreviation(value);
  };

  const handleChangeIndex = e => {
    const { value } = e.target;
    setCategoryIndex(value);
  };

  useEffect(() => {
    getInitData();
  }, [search]);

  return (
    <ContainerPage>
      <Box>
        <TitlePage>Categorias de produto </TitlePage>
      </Box>
      <MainContent>
        <SearchContent>
          <SearchInput
            placeholder="ID ou categoria"
            onChange={debaunceSearch}
          />
          <AddButton onClick={handleOpenCreate} />
        </SearchContent>
        <CategoriesTable
          categoriesData={categoriesData}
          loading={loading}
          handleOpenUpdate={handleOpenUpdate}
        />
        <DialogCreate
          handleClose={handleCloseDialogs}
          open={openCreate}
          categoryAbbreviation={categoryAbbreviation}
          categoryName={categoryName}
          handleChangeAbbreviation={handleChangeAbbreviation}
          handleChangeCategory={handleChangeCategory}
          loading={loadingFetch}
          handleSave={handleCreateCategory}
          categoryIndex={categoryIndex}
          handleChangeIndex={handleChangeIndex}
        />
        <DialogUpdate
          handleClose={handleCloseDialogs}
          open={openUpdate}
          categoryAbbreviation={categoryAbbreviation}
          categoryName={categoryName}
          handleChangeAbbreviation={handleChangeAbbreviation}
          handleChangeCategory={handleChangeCategory}
          loading={loadingFetch}
          handleSave={handleUpdateCategory}
          categoryIndex={categoryIndex}
          handleChangeIndex={handleChangeIndex}
        />
      </MainContent>
    </ContainerPage>
  );
};
