import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { mapPurchaseType } from "utils";

import { LoadingBlitzContent } from "../../style";
import { ConfirmPage } from "./ConfirmPage";
import { InitialPage } from "./InitialPage";

export const ReceiptQuantity = ({
  batchData,
  handleNext,
  receiptQty,
  handleChangeReceiptQty,
  setUpdatedBatch,
  hasBlitz,
  handleChangeBoxQty,
  boxQty
}) => {
  const [activePage, setActivePage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { purchase_type } = batchData.product;
  const typeOfMeasure = mapPurchaseType(purchase_type);

  const page = {
    0: (
      <InitialPage
        receiptQty={receiptQty}
        handleChangeReceiptQty={handleChangeReceiptQty}
        typeOfMeasure={typeOfMeasure}
        onNext={() => setActivePage(1)}
        handleChangeBoxQty={handleChangeBoxQty}
        boxQty={boxQty}
      />
    ),
    1: (
      <ConfirmPage
        batchData={batchData}
        receiptQty={receiptQty}
        confirmedQuantity={batchData.confirmed_quantity}
        typeOfMeasure={typeOfMeasure}
        hasBlitz={hasBlitz}
        setUpdatedBatch={setUpdatedBatch}
        handleNext={handleNext}
        setLoading={setLoading}
        onBack={() => setActivePage(0)}
        boxQty={boxQty}
      />
    )
  }[activePage];

  if (loading) {
    return (
      <LoadingBlitzContent>
        <CircularProgress color="primary" />
      </LoadingBlitzContent>
    );
  }

  return page;
};
