import { Box, Button, CircularProgress } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import sellerOrderService from "services/sellerOrderService";
import { Dialog, InfoCard } from "shared";
import { formatDateToBR } from "utils";

import { Text } from "../../styles";

export const ConfirmCancelPopup = ({ open, handleClose, data, reloadData }) => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);

  const cancelOrder = async () => {
    try {
      const orderId = data.id;
      setLoading(true);
      await sellerOrderService.cancelSellerOrder(orderId);
      addPopup({
        type: "success",
        title: "Pedido cancelado com sucesso."
      });
      reloadData();
      handleClose();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao cancelar o pedido.",
        description: error.response?.data?.message ?? ""
      });
    } finally {
      setLoading(false);
    }
  };

  const content = {
    true: (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin="2rem 0"
      >
        <CircularProgress color="primary" />
      </Box>
    ),
    false: (
      <>
        <Text>Tem certeza que deseja cancelar o pedido?</Text>

        <Box display="flex" marginTop="1rem" style={{ gap: "1rem" }}>
          <Button onClick={handleClose} fullWidth>
            Cancelar
          </Button>
          <Button onClick={cancelOrder} variant="contained" fullWidth>
            Confirmar
          </Button>
        </Box>
      </>
    )
  }[loading];

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Cancelar Pedido para Fornecedor"
      maxWidth="32rem"
    >
      <InfoCard
        data={{
          Pedido: {
            text: data.id
          },
          Seller: {
            text: data?.seller?.name || "Não informado"
          },
          "Data agendada": {
            text: formatDateToBR(data?.scheduledDate) || "Não informado"
          }
        }}
      />
      {content}
    </Dialog>
  );
};
