import {
  Accordion,
  AccordionDetails,
  Box,
  Tab as TabMui,
  Button as ButtonMui,
  TableCell,
  TableRow,
  Tabs as TabsMui,
  Typography,
  darken,
  styled
} from "@material-ui/core";
import { ChipStatus, statusBackgroundColor } from "components/ChipStatus";
import { Dialog } from "shared";
import { Search } from "shared/Search";

export const ReceiptContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column"
}));

export const Tabs = styled(TabsMui)({});

export const Tab = styled(TabMui)({
  textTransform: "initial",
  fontSize: "1.125rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#757575",
  "&.Mui-selected": {
    backgroundColor: "#F3F8E6",
    color: "#353535"
  }
});

export const ReceiptPageContent = styled(Box)({
  display: "flex",
  flexDirection: "column"
});

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 0 1rem",
  [theme.breakpoints.down("lg")]: {
    flex: 1,
    flexDirection: "column",
    gap: "1rem"
  }
}));

export const FilterContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  paddingInline: "1rem",
  [theme.breakpoints.down("lg")]: {
    flex: 1,
    justifyContent: "end"
  }
}));

export const FilterLabel = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  fontSize: "0.875rem",
  color: "#757575"
});

export const ChipButtonFilter = styled(ChipStatus)(({ statusId }) => ({
  minWidth: "8rem",
  fontSize: "0.875rem",
  "&:hover": {
    backgroundColor: statusBackgroundColor[statusId] || "#F4F4F4"
  },
  "&:focus": {
    backgroundColor: statusBackgroundColor[statusId] || "#F4F4F4"
  }
}));

export const SearchInput = styled(Search)(({ theme }) => ({
  minWidth: "22rem",
  height: "3.5rem",

  [theme.breakpoints.down("sm")]: {
    minWidth: "100%"
  }
}));

export const AccordionContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingBlock: "0.5rem"
}));

export const StyledAccordion = styled(Accordion)({
  margin: 0,
  padding: 0,
  "& .MuiAccordionSummary-root": {
    paddingBlock: "1rem"
  },
  "&:nth-of-type(even)": {
    "& .MuiAccordionSummary-root": {
      backgroundColor: "#F5F6F7"
    }
  },

  "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded ": {
    backgroundColor: "#E5ECD1"
  },
  "& .MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd ": {
    backgroundColor: "#E5ECD1"
  },
  "&.MuiAccordion-root.Mui-expanded": {
    margin: 0,
    padding: 0
  }
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  overflowY: "auto"
});

export const StyledAccordionHeader = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

export const SellerTitle = styled(Typography)(({ theme }) => ({
  color: "#353535",
  fontSize: "1rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    fontWeight: 500
  }
}));

export const Button = styled(ButtonMui)(({ background = "#cfcece" }) => ({
  minWidth: "5.625rem",
  minHeight: "2rem",
  color: "#F5F6F7",
  backgroundColor: background,
  borderRadius: "0.5rem",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1rem",
  letterSpacing: "0.02857em",
  "&:hover": {
    backgroundColor: darken(background, 0.1)
  },
  "& + button": {
    marginLeft: "1rem"
  }
}));

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});

export const StyledTableRow = styled(TableRow)({
  backgroundColor: "#F9FCF4"
});

export const HeadCell = styled(TableCell)({
  fontSize: "0.875rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#757575"
});

export const ProductCell = styled(TableCell)({
  fontSize: "0.875rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#353535"
});

export const StyledCell = styled(TableCell)({
  fontSize: "0.875rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#757575"
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  maxWidth: "24rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": {
    width: "100%",
    height: "20%",
    padding: 0,
    margin: 0
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      height: "25%"
    }
  }
}));

export const PopupContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100% - 92px)",
  gap: "0.5rem"
});
