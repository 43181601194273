import {
  Typography,
  Box,
  styled,
  Button as ButtonMui,
  darken
} from "@material-ui/core";

export const Button = styled(ButtonMui)(
  ({ background, color = "#ffffff" }) => ({
    width: "50%",
    background: background,
    "& > span": { color },
    "&:hover": {
      backgroundColor: darken(background, 0.1)
    },
    "& + button": {
      marginLeft: "1rem"
    }
  })
);

export const BatchBox = styled(Box)({
  background: "#f5f8f3",
  padding: "1.06rem 1.25rem",
  width: "100%",
  borderRadius: "1rem",
  marginBottom: "2.5rem"
});

export const TextBatch = styled(Typography)({ color: "#757575" });
