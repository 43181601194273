import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class ReporterGenerateService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async excelGenerate(data) {
    const url = `${this.url}/api/reports/excel/`;
    return await api.post(url, data, {
      responseType: "blob",
      params: {
        export: "xls"
      }
    });
  }

  async assetDocument(data) {
    const url = `${this.url}/api/reports/pdf/asset`;
    return await api.post(url, data, { responseType: "blob" });
  }
}

const reporterGenerateService = new ReporterGenerateService();
export default reporterGenerateService;
