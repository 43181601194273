import {
  Box,
  Button,
  Paper,
  Switch,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  makeStyles,
  styled
} from "@material-ui/core";
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem
} from "@material-ui/lab";
import { SearchContent } from "components/Contents";
import { TableRow } from "shared";
import { Search } from "shared/Search";

export const BatchIdContent = styled(Box)({
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: "#353535"
});

export const TitleDetail = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginBottom: "1rem",
  color: "#353535"
});

export const useStyles = makeStyles(theme => ({
  responsiveButtons: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "5rem",
      marginLeft: "-5rem"
    }
  }
}));

export const CustomTableContainer = styled(TableContainer)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
});

export const StyledSearchContent = styled(SearchContent)(({ theme }) => ({
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    alignItems: "initial",
    flexDirection: "column"
  }
}));

export const TableContent = styled(TableContainer)({
  display: "flex",
  flexDirection: "column",
  height: "32rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0.6rem",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "9px"
  }
});

export const SearchInput = styled(Search)(({ theme }) => ({
  width: "100%",
  maxWidth: "21.5rem",
  height: "3.5rem",
  marginTop: "2.5rem",
  marginLeft: "2.5rem",
  [theme.breakpoints.between(0, 700)]: {
    marginLeft: 0
  }
}));

export const ConfirmButton = styled(Button)({
  minWidth: "4rem",
  minHeight: "2rem",
  color: "#ffffff",
  backgroundColor: "#89bd23",
  borderRadius: "0.5rem",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1rem",
  letterSpacing: "0.02857em",
  "&:hover": {
    backgroundColor: "#68901a"
  }
});

export const StyledTableHead = styled(TableHead)({
  background: "#F3F8E6"
});

export const HeadCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem"
});

export const BodyRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const BodyCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem"
});

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});

export const DateTimeContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem"
});

export const DetailContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  gap: "1rem",
  flexDirection: "column"
}));

export const DetailContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column"
}));

export const DetailCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const InfosContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1
}));

export const InfoIdContent = styled(Paper)(({ theme }) => ({
  display: "flex",
  maxWidth: "fit-content",
  padding: "1rem",
  alignItems: "center",
  gap: "0.5rem",
  borderRadius: "0.5rem 0.5rem 0 0",
  backgroundColor: "#F3F8E6",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#757575"
}));

export const InfoId = styled(Box)({
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#353535"
});
export const ProductInfoContent = styled(Paper)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  gap: "1rem",
  borderRadius: "0 0.5rem 0.5rem 0.5rem",
  flex: 1
}));

export const ContainerWithIcon = styled(Paper)(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  borderRadius: "0.5rem",
  gap: "1rem",
  flex: 1
}));

export const ContentWithIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  gap: "1rem",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const InfoIconContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "1rem",
  backgroundColor: "#F3F8E6",

  "& svg": {
    color: "#587916"
  }
}));

export const PaperInfoContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  borderRadius: "0.5rem",
  flex: 1,
  flexWrap: "wrap"
}));

export const PaperInfoContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minWidth: "fit-content"
}));

export const PaperInfoTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#F3F8E6",
  borderRight: "1px solid #C4C4C4",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#757575"
}));
export const PaperInfoValue = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  justifyContent: "center",
  alignItems: "center",
  borderRight: "1px solid #C4C4C4",
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#353535"
}));

export const DetailCardContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.2rem",
  minWidth: "8rem",
  flex: 1
}));

export const CardTitle = styled(Box)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1rem",
  color: "#656565"
}));

export const CardDateTime = styled(Box)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "0.8rem",
  color: "#858585"
}));

export const CardValue = styled(Box)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1rem",
  color: "#353535",
  fontWeight: 400
}));

export const StyledTimeLine = styled(Timeline)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    paddingInline: 0
  }
}));

export const HistoryContainer = styled(Box)({
  overflowY: "auto",
  height: "25rem",
  marginBottom: "8rem",
  "&::-webkit-scrollbar": {
    width: "10px",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "10px"
  }
});

export const HistoryContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "2rem"
});

export const StyledTimeLineItem = styled(TimelineItem)(({ theme }) => ({
  "& .MuiTimelineOppositeContent-root": {
    display: "flex",
    padding: "1rem",
    flex: 0,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      maxWidth: "35%",
      flex: 1,
      "& .MuiTypography-body2": {
        fontSize: "0.7rem"
      }
    }
  }
}));

export const StyledTimeLineDot = styled(TimelineDot)(({ theme, hasDiff }) => ({
  backgroundColor: hasDiff
    ? theme.palette.primary.blue
    : theme.palette.secondary.main
}));

export const StyledTimeLineContent = styled(TimelineContent)(({ theme }) => ({
  display: "flex",
  paddingBlock: "0.5rem",
  gap: "0.5rem",
  flexWrap: "wrap",
  "& .MuiPaper-root": {
    padding: "0.5rem 1rem"
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  }
}));

export const HistoryTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "1rem",
  textAlign: "center",
  margin: 0,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    textAlign: "center"
  }
}));

export const HistoryDiffContainer = styled(Paper)({
  display: "flex",
  gap: "1rem"
});

export const HistoryDiffContent = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  justifyContent: "center",
  flex: 1,
  minWidth: "fit-content",

  [theme.breakpoints.down("lg")]: {
    alignItems: "normal",
    textAlign: "center"
  }
}));

export const ValuesContent = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  flexDirection: "column",
  alignItems: "center"
}));

export const BoxOldValue = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: "#F5F6F7",
  padding: "0.5rem",
  minHeight: "2rem",
  borderRadius: "0.25rem",
  fontFamily: "Inter",
  fontSize: "0.875rem",
  justifyContent: "center"
}));

export const BoxNewValue = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: "#E5ECD1",
  padding: "0.5rem",
  minHeight: "2rem",
  borderRadius: "0.25rem",
  fontFamily: "Inter",
  fontSize: "0.875rem",
  justifyContent: "center"
}));

export const CreatedByContent = styled(Typography)(({ theme }) => ({
  display: "flex",
  textAlign: "left",
  justifyContent: "flex-end",
  alignItems: "center",
  "& svg": {
    height: "0.875rem"
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem"
  }
}));

export const CreatedDateTimeContent = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const Text = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "1.1rem",
  color: "#757575",
  margin: "1.5rem 0 2rem"
});

export const FrexcoSwitch = styled(props => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    onClick={props.onClick}
    checked={props.checked}
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#65C466" : "#4EAA68",
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));
