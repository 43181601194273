import { CircularProgress } from "@material-ui/core";
import { useMemo, useState } from "react";

import { LoadingBlitzContent } from "../../style";
import { InitialPage } from "./InitialPage";
import { ResultPage } from "./ResultPage";

export const WeightBlitz = ({
  blitzData,
  batchData,
  handleNext,
  handleCloseOpenSteps
}) => {
  const [sampleWeight, setSampleWeight] = useState(null);
  const [sampleWeightQty, setSampleWeightQty] = useState(null);

  const [activePage, setActivePage] = useState(0);
  const [loading, setLoading] = useState(false);

  const averageWeight = useMemo(
    () => sampleWeight / sampleWeightQty,
    [sampleWeight, sampleWeightQty]
  );

  const handleChangeSampleWeight = e => {
    const { value: inputValue } = e.target;
    const value = parseFloat(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setSampleWeight(!isValid ? "" : value);
  };

  const handleChangeSampleWeightQty = e => {
    const { value: inputValue } = e.target;
    const value = parseInt(inputValue, 10);
    const isValid = !isNaN(value) && value > 0;
    setSampleWeightQty(!isValid ? "" : value);
  };

  const page = {
    0: (
      <InitialPage
        sampleWeight={sampleWeight}
        sampleWeightQty={sampleWeightQty}
        handleChangeSampleWeight={handleChangeSampleWeight}
        handleChangeSampleWeightQty={handleChangeSampleWeightQty}
        averageWeight={averageWeight}
        handleNext={handleNext}
        setLoading={setLoading}
        blitzData={blitzData}
        batchId={batchData.id}
        setActivePage={setActivePage}
      />
    ),
    1: <ResultPage handleCloseOpenSteps={handleCloseOpenSteps} />
  }[activePage];

  if (loading) {
    return (
      <LoadingBlitzContent>
        <CircularProgress color="primary" />
      </LoadingBlitzContent>
    );
  }

  return page;
};
