import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  helperText: {
    lineHeight: "1.5rem",
    fontFamily: "Inter",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#000000",
    marginBottom: "1rem"
  },
  boxForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "4rem"
  },
  card: {
    maxHeight: "16rem",
    display: "block",
    margin: "auto",
    overflowY: "scroll",
    scrollBehavior: "smooth"
  },
  inputBox: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-evenly",
    gap: 1,
    [theme.breakpoints.between(0, 800)]: {
      flexDirection: "column"
    }
  },
  input: {
    minWidth: "15rem",
    marginBottom: "1rem",
    marginLeft: "1rem",
    [theme.breakpoints.between(0, 800)]: {
      marginBottom: "2rem"
    },
    [theme.breakpoints.between(0, 600)]: {
      minWidth: "80%"
    }
  },
  printButton: {
    marginTop: "3rem",
    marginBottom: "0.5rem",
    display: "block",
    margin: "0 auto"
  }
}));
