import { Box } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { ContainerPage, PermissionGate, TitlePage, Button } from "components";
import { OptionsMenu } from "components/OptionsMenu";
import { useTableParams } from "hooks/useTableParams";
import { useState } from "react";
import { MdList, MdOutlineFilePresent } from "react-icons/md";
import assetService from "services/assetsService";
import { Table } from "shared";
import { convertDate } from "utils";

import { DialogCreateRegister } from "./components/DialogCreateRegister";
import { DialogEdit } from "./components/DialogEdit";
import { DocumentDialog } from "./components/DocumentDialog";

export default function AssetsTransactions() {
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => assetService.getAssetsTransactions({ ...params }),
      paramsDefault: {
        multipleFilter: true,
        orderDirection: "-updated",
        excludeReasonId: 9
      }
    });

  const [open, setOpen] = useState(false);
  const [dialogEdit, setDialogEdit] = useState({
    isOpen: false,
    transactionId: null
  });
  const [documentDialog, setDocumentDialog] = useState({
    isOpen: false,
    transactionId: null
  });

  const options = [
    {
      title: "Editar",
      icon: MdList,
      permissions: ["Tech", "Estoque", "Estoque físico"],
      action: data => {
        setDialogEdit({
          isOpen: true,
          transactionId: data.id
        });
      }
    },
    {
      title: "Documento",
      icon: MdOutlineFilePresent,
      permissions: ["Tech", "Estoque", "Estoque físico"],
      action: data => {
        setDocumentDialog({
          isOpen: true,
          transactionId: data.id
        });
      }
    }
  ];

  const columns = [
    {
      field: "created",
      label: "Criado em",
      align: "center",
      disabledSort: true,
      style: { minWidth: "150px" },
      formatter: value => convertDate(new Date(value))
    },
    {
      field: "updated",
      label: "Atualizado em",
      align: "center",
      disabledSort: true,
      style: { minWidth: "150px" },
      formatter: value => convertDate(new Date(value))
    },
    {
      field: "id",
      label: "Transação",
      align: "center",
      disabledSort: true,
      style: { minWidth: "100px" }
    },
    {
      field: "status",
      label: "Status",
      disabledSort: true,
      style: { minWidth: "150px", maxWidth: "300px" }
    },
    {
      field: "updatedByName",
      label: "Responsável",
      disabledSort: true,
      style: { minWidth: "140px", maxWidth: "200px" }
    },
    {
      field: "sellerName",
      label: "Fornecedor",
      disabledSort: true,
      style: { minWidth: "180px", maxWidth: "300px" }
    },
    {
      field: "options",
      label: "",
      disabledSort: true,
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  return (
    <>
      <ContainerPage>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <TitlePage>Transações</TitlePage>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          ml="auto"
          flexWrap="wrap"
          style={{ gap: "1rem" }}
        >
          <PermissionGate permissions={["Tech", "Estoque", "Estoque físico"]}>
            <Button
              variant="contained"
              size="medium"
              startIcon={<AddRoundedIcon />}
              onClick={() => {
                setOpen(true);
              }}
            >
              Nova transação
            </Button>
          </PermissionGate>
        </Box>

        <Table
          handleSearch={handleSearch}
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          placeholder="Transação ou fornecedor"
          hover
          selectFilterProps={{
            value: params.status,
            options: [
              {
                id: "aberta",
                label: "Aberta",
                color: { label: "#0a892e", background: "#e5f6ea" }
              },
              {
                id: "fechada",
                label: "Fechada",
                color: { label: "#890a0a", background: "#FFDADA" }
              }
            ],
            onChange: value => {
              onChange({ status: value });
            }
          }}
        />
      </ContainerPage>

      <DialogCreateRegister
        isOpen={open}
        setIsOpen={setOpen}
        refresh={refresh}
      />

      <DialogEdit
        isOpen={dialogEdit.isOpen}
        setIsOpen={setDialogEdit}
        transactionId={dialogEdit.transactionId}
        refresh={refresh}
      />

      <DocumentDialog
        isOpen={documentDialog.isOpen}
        setIsOpen={setDocumentDialog}
        transactionId={documentDialog.transactionId}
      />
    </>
  );
}
