import { Box, CircularProgress, Typography, styled } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button } from "components";
import { useState } from "react";
import { mapPurchaseType } from "utils";

export const BoxConfirm = styled(Box)({
  borderRadius: "1rem",
  border: "2px solid #B5C9A8",
  background: "#F5F8F3",
  padding: "1.5rem"
});

export const TextConfirm = styled(Typography)({
  display: "flex",
  flexDirection: "column",
  gap: "0.2rem",
  color: "#757575",
  "& > strong": {
    color: "#353535"
  },
  "p + &": {
    marginTop: "1rem"
  }
});

export const ConfirmSubbatch = ({
  subbatch,
  action,
  quantity,
  boxQuantity,
  select,
  confirm,
  onBack
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    await confirm();
    setIsLoading(false);
  };

  const classification = subbatch.othersSubBatches.find(
    ({ id }) => id === select
  )?.classification;

  const typeMovement =
    {
      subBatchToSubBatch: "Sublote",
      subBatchToWaste: "Waste",
      subBatchToPrePicking: "Pre-picking",
      subBatchToReadyBox: "Caixa pronta"
    }[action] ?? "";

  const quantityText =
    {
      subBatchToSubBatch: "Qual quantidade deseja mover?",
      subBatchToWaste: "Qual quantidade de produto de waste?",
      subBatchToPrePicking: "Qual quantidade de produto que será movida?",
      subBatchToReadyBox: "Qual quantidade de produto que será movida?"
    }[action] ?? "";

  return (
    <>
      <BoxConfirm>
        <TextConfirm>
          Para onde deseja mover o sublote?
          <strong>{typeMovement}</strong>
        </TextConfirm>

        {["subBatchToSubBatch"].includes(action) && (
          <TextConfirm>
            Qual o sublote de destino?
            <strong>Sublote {classification} </strong>
          </TextConfirm>
        )}

        {["subBatchToPrePicking", "subBatchToReadyBox"].includes(action) && (
          <TextConfirm>
            Quantas caixas serão movidas?
            <strong>{boxQuantity}</strong>
          </TextConfirm>
        )}

        <TextConfirm>
          {quantityText}
          <strong>
            {quantity} {mapPurchaseType(subbatch.batch?.product?.purchase_type)}
          </strong>
        </TextConfirm>
      </BoxConfirm>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        flex="1"
        style={{ gap: "1rem" }}
      >
        <Button
          fullWidth
          onClick={onBack}
          startIcon={<NavigateBeforeIcon fontSize="large" />}
        >
          Voltar
        </Button>

        <Button
          fullWidth
          variant="contained"
          endIcon={<NavigateNextIcon fontSize="large" />}
          isLoading={isLoading}
          onClick={handleConfirm}
        >
          Finalizar
        </Button>
      </Box>
    </>
  );
};
