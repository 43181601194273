import { styled } from "@material-ui/core";
import { Link } from "react-router-dom";

export const ButtonLink = styled(Link)({
  textDecoration: "none",
  minWidth: "4rem",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: 1.75,
  letterSpacing: "0.02857em",
  color: "#ffffff",
  backgroundColor: "#89bd23",
  padding: "0.5rem 1rem",
  borderRadius: "0.25rem",

  "&:hover": {
    backgroundColor: "#68901a"
  }
});
