import { Box, styled } from "@material-ui/core";
import DatePicker from "react-datepicker";

export const DateFilterContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  paddingInline: "1rem",
  marginRight: "1rem",
  zIndex: 10,
  [theme.breakpoints.down("sm")]: {
    flex: 1,
    padding: "1rem 0",
    justifyContent: "center"
  }
}));

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "0.5rem 1rem",
  border: "1px solid #C5c5c5",
  borderRadius: "0.5rem",
  height: "3.4rem",
  cursor: "pointer",
  fontFamily: "Roboto",
  fontSize: "1rem"
}));
