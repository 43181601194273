import { Box, Paper, styled } from "@material-ui/core";

export const MainContent = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
});

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  gap: "1rem"
}));
