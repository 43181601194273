import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class BatchServiceV3 {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async batchReceipt(batchId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/${batchId}/receipt`;
    return await api.patch(url, data);
  }

  async editBatchQuantityReceived(batchId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/${batchId}/adjust/quantity`;
    return await api.patch(url, data);
  }

  async blockBatch(batchId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/${batchId}/block`;
    return await api.patch(url, data);
  }

  async unblockBatch(batchId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/${batchId}/unblock`;
    return await api.patch(url, data);
  }

  async patchBatch(batchId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/${batchId}`;
    return await api.patch(url, data);
  }

  async getBatchDash(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/dash`;
    return await api.get(url, {
      params
    });
  }

  async updateBatchManualCount(batchId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/${batchId}/manual-count`;
    return await api.patch(url, data);
  }

  async getBatchWitBreakageDash(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/dash/breakage`;
    return await api.get(url, {
      params
    });
  }

  async postBatchMove(batchId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/${batchId}/move`;
    return await api.post(url, data);
  }

  async selectionV3(id, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v3/${id}/selection`;
    return await api.patch(url, data);
  }
}

const batchServiceV3 = new BatchServiceV3();
export default batchServiceV3;
