import { Box } from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";
import UploadIcon from "@material-ui/icons/CloudUpload";
import {
  ContainerPage,
  PermissionGate,
  TitlePage,
  Button,
  OptionsMenu
} from "components";
import PageLoader from "components/PageLoad";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import fileDownload from "js-file-download";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { FaLock } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import reporterGenerateService from "services/reporterGenerateService";
import stockServiceV2 from "services/stockServicesV2";
import storageService from "services/storageService";
import summaryService from "services/summaryService";
import { Table } from "shared";
import { mapType, useDebaunce } from "utils";

import { DialogEdit } from "./DialogEdit";
import { DialogUpload } from "./DialogUpload";
import { Lock } from "./Lock";

const filterReason = [14, 15];

export default function Summary() {
  const lockRef = useRef(null);
  const { data, onChange, params, refresh, status } = useTableParams({
    service: params => stockServiceV2.getResumeStock()
  });
  const { addPopup } = usePopup();

  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [dialogEdit, setDialogEdit] = useState({
    isOpen: false,
    data: {}
  });
  const [search, setSearch] = useState(null);

  const options = [
    {
      title: "Editar",
      icon: MdModeEditOutline,
      permissions: ["Estoque", "Tech"],
      action: data => setDialogEdit({ isOpen: true, data })
    },
    {
      title: "Bloquear",
      icon: FaLock,
      permissions: ["Estoque", "Tech"],
      action: data => lockRef.current.handleData(data)
    }
  ];

  const columns = [
    {
      field: "product_base_name",
      label: "Produto Base",
      style: { minWidth: "170px", maxWidth: "300px" },
      disabledSort: true
    },
    {
      field: "product_base_id",
      label: "ID do Produto",
      align: "center",
      style: { minWidth: "200px" },
      disabledSort: true
    },
    {
      field: "purchase_type",
      label: "Tipo de entrada",
      align: "center",
      style: { minWidth: "200px" },
      formatter: value => mapType(value),
      disabledSort: true
    },
    {
      field: "summary",
      label: "Estoque",
      align: "center",
      style: { minWidth: "200px" },
      formatter: value => {
        if (!value) return 0;
        return new Intl.NumberFormat("pt-BR").format(value.toFixed(2));
      },

      cellStyle: data => {
        if (data.stock < 0) {
          return {
            color: "red"
          };
        }
      },
      disabledSort: true
    },
    {
      field: "options",
      label: "",
      disabledSort: true,
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });
  const getReasons = async () => {
    try {
      const { data } = await storageService.getReason();
      setReasons(data.filter(({ id }) => !filterReason.includes(id)));
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Desculpe, ocorreu um erro",
          description: "Erro ao buscar motivos."
        });
      }
    }
  };

  const handleDownloadSummary = async () => {
    try {
      const { data } = await summaryService.getSummaryDownload();
      fileDownload(data, "Dados_do_estoque.xls");
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          description: "Erro ao fazer download"
        });
      }
    }
  };

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  useEffect(() => {
    getReasons();
  }, []);

  const dataRows = useMemo(() => {
    const rows = {
      false: () =>
        (data || [])?.map(item => ({
          ...item,
          id: item?.product_base_id
        })),
      true: () =>
        (data || [])
          ?.filter(
            item =>
              item.product_base_name
                ?.toLowerCase()
                .includes(search.toLowerCase().trim()) ||
              item?.product_base_id?.toString().includes(search.trim())
          )
          .map(item => ({
            ...item,
            id: item?.product_base_id
          }))
    }[!!search];

    return rows();
  }, [data, search]);

  const handleDownloadResume = async () => {
    try {
      const dataToDownload = dataRows?.reduce((acc, row) => {
        delete row.id;
        acc.push(row);
        return acc;
      }, []);
      const { data } = await reporterGenerateService.excelGenerate(
        dataToDownload
      );

      fileDownload(data, "Dados_do_estoque.xlsx");
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          description: "Erro ao fazer download"
        });
      }
    }
  };

  if (status === "pending") {
    return (
      <PageLoader
        textInfo={
          "Estamos carregando os dados, essa ação pode levar alguns minutos"
        }
      />
    );
  }

  return (
    <>
      <ContainerPage>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <TitlePage>Consulta de Estoque</TitlePage>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            ml="auto"
            style={{ gap: "1rem" }}
            flexWrap="wrap"
          >
            <Button
              variant="contained"
              startIcon={<CloudDownload />}
              onClick={() => handleDownloadResume()}
            >
              Baixar relatório
            </Button>
          </Box>
        </Box>

        <Table
          handleSearch={debaunceSearch}
          columns={columns}
          data={dataRows}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={dataRows.length}
          size="small"
          hover
          paginationServer={false}
        />
      </ContainerPage>

      <DialogUpload
        isOpen={isUploadOpen}
        refresh={refresh}
        handleClose={setIsUploadOpen}
      />

      <DialogEdit
        isOpen={dialogEdit.isOpen}
        dataProduct={dialogEdit.data}
        refresh={refresh}
        setIsOpen={setDialogEdit}
        reasons={reasons}
      />

      <Lock ref={lockRef} refresh={refresh} />
    </>
  );
}
