import {
  Grid,
  Box,
  TextField,
  Checkbox,
  MenuItem,
  FormControlLabel
} from "@material-ui/core";
import { Button } from "components";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import { AutoCompleteProductBaseFormik, InputImage } from "shared";
import { portionNameValues } from "utils";
import { distributionCenterOptions } from "utils/constants/distributionCenter";

import { useStyles } from "./styles";
import {
  validationSchemaCreate,
  validationSchemaEdit
} from "./validationSchema";

const Component = ({ initialValues, onSubmit, productBaseId }, ref) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: initialValues || {
      shouldGenerateDescription: true,
      margin: 0.95
    },
    validationSchema: initialValues
      ? validationSchemaEdit
      : validationSchemaCreate,
    onSubmit: onSubmit,
    validateOnChange: false
  });

  useImperativeHandle(ref, () => ({ resetForm: formik.resetForm }));

  const handleChangeAutoDescription = e => {
    const { name: field, checked } = e.target;
    formik.setFieldValue(field, checked);
    !!checked && formik.setFieldValue("description", "");
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Box display="flex" className={classes.gridImage}>
        <Grid container xs={12} sm={5} justifyContent="center">
          <InputImage formik={formik} name="base64" />
        </Grid>

        <Grid container xs={12} sm={7}>
          {initialValues && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Nome"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.name || formik.submitCount > 0) &&
                  !!formik.errors.name
                }
                helperText={
                  (formik.touched.name || formik.submitCount > 0) &&
                  formik.errors.name
                }
                variant="outlined"
              />
            </Grid>
          )}

          {initialValues?.autoDescription &&
          formik.values.shouldGenerateDescription ? (
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <TextField
                disabled
                fullWidth
                multiline
                rows={1}
                name="autoDescription"
                label="Descrição automática do produto"
                value={formik.values.autoDescription}
                variant="outlined"
              />
            </Grid>
          ) : null}

          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <TextField
              fullWidth
              multiline
              rows={2}
              name="description"
              label="Descrição do produto"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                ((!formik.values.shouldGenerateDescription &&
                  formik.touched.description) ||
                  formik.submitCount > 0) &&
                !!formik.errors.description
              }
              helperText={
                ((!formik.values.shouldGenerateDescription &&
                  formik.touched.description) ||
                  formik.submitCount > 0) &&
                formik.errors.description
              }
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
            <FormControlLabel
              label="Gerar descrição baseada no peso"
              control={
                <Checkbox
                  fullWidth
                  name="shouldGenerateDescription"
                  label="Gerar descrição baseada no peso"
                  checked={formik.values.shouldGenerateDescription}
                  defaultChecked
                  onChange={handleChangeAutoDescription}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                />
              }
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
            <TextField
              select
              fullWidth
              name="distributionCenter"
              label="Centro de distribuição"
              value={formik.values.distributionCenter}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.distributionCenter || formik.submitCount > 0) &&
                !!formik.errors.distributionCenter
              }
              helperText={
                (formik.touched.distributionCenter || formik.submitCount > 0) &&
                formik.errors.distributionCenter
              }
              variant="outlined"
            >
              {distributionCenterOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <TextField
              fullWidth
              name="portionDescription"
              label="Descrição da porção"
              value={formik.values.portionDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.portionDescription || formik.submitCount > 0) &&
                !!formik.errors.portionDescription
              }
              helperText={
                (formik.touched.portionDescription || formik.submitCount > 0) &&
                formik.errors.portionDescription
              }
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        xs={12}
        spacing={2}
        justify="center"
        justifyContent="center"
        style={{ marginTop: "1rem" }}
      >
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            name="portionName"
            label="Nome da porção"
            value={formik.values.portionName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.portionName || formik.submitCount > 0) &&
              !!formik.errors.portionName
            }
            helperText={
              (formik.touched.portionName || formik.submitCount > 0) &&
              formik.errors.portionName
            }
            variant="outlined"
          >
            {portionNameValues.map(({ value, name }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {initialValues && (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="sku"
              label="Sku"
              value={formik.values.sku}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.sku || formik.submitCount > 0) &&
                !!formik.errors.sku
              }
              helperText={
                (formik.touched.sku || formik.submitCount > 0) &&
                formik.errors.sku
              }
              variant="outlined"
              disabled
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          {initialValues ? (
            <TextField
              fullWidth
              name="productBaseId"
              label="Produto base"
              InputLabelProps={{ shrink: !!formik.values.productBaseId }}
              value={productBaseId?.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.productBaseId || formik.submitCount > 0) &&
                !!formik.errors.productBaseId
              }
              helperText={
                (formik.touched.productBaseId || formik.submitCount > 0) &&
                formik.errors.productBaseId
              }
              variant="outlined"
              disabled
            />
          ) : (
            <AutoCompleteProductBaseFormik
              name="productBaseId"
              formik={formik}
              onChange={data => {
                formik.setFieldValue("category", data?.category);
                formik.setFieldValue("subCategory", data?.subCategory);
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="portionType"
            label="Tipo de estoque"
            value={formik.values.portionType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.portionType || formik.submitCount > 0) &&
              !!formik.errors.portionType
            }
            helperText={
              (formik.touched.portionType || formik.submitCount > 0) &&
              formik.errors.portionType
            }
            variant="outlined"
          >
            <MenuItem value={1}>Quilo (kg)</MenuItem>
            <MenuItem value={2}>Unidade (un)</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="category"
            label="Categoria"
            InputLabelProps={{ shrink: !!formik.values.category }}
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.category || formik.submitCount > 0) &&
              !!formik.errors.category
            }
            helperText={
              (formik.touched.category || formik.submitCount > 0) &&
              formik.errors.category
            }
            variant="outlined"
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="subCategory"
            label="Subcategoria"
            InputLabelProps={{ shrink: !!formik.values.subCategory }}
            value={formik.values.subCategory}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.subCategory || formik.submitCount > 0) &&
              !!formik.errors.subCategory
            }
            helperText={
              (formik.touched.subCategory || formik.submitCount > 0) &&
              formik.errors.subCategory
            }
            variant="outlined"
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="status"
            label="Status"
            value={formik.values.status}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.status || formik.submitCount > 0) &&
              !!formik.errors.status
            }
            helperText={
              (formik.touched.status || formik.submitCount > 0) &&
              formik.errors.status
            }
            variant="outlined"
          >
            <MenuItem value={1}>Ativo</MenuItem>
            <MenuItem value={2}>Suspenso</MenuItem>
            <MenuItem value={3}>Desativado</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="separationOrder"
            label="Ordem de separação"
            type="number"
            value={formik.values.separationOrder}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.separationOrder || formik.submitCount > 0) &&
              !!formik.errors.separationOrder
            }
            helperText={
              (formik.touched.separationOrder || formik.submitCount > 0) &&
              formik.errors.separationOrder
            }
            variant="outlined"
            disabled={!!initialValues}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="purchaseValue"
            label="Peso por unidade"
            type="number"
            value={formik.values.purchaseValue}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.purchaseValue || formik.submitCount > 0) &&
              !!formik.errors.purchaseValue
            }
            helperText={
              (formik.touched.purchaseValue || formik.submitCount > 0) &&
              formik.errors.purchaseValue
            }
            variant="outlined"
            disabled={!!initialValues}
          />
        </Grid>

        <Grid item xs={12} sm={!initialValues ? 6 : 12}>
          <TextField
            fullWidth
            name="margin"
            label="Margem"
            type="number"
            value={formik.values.margin}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.margin || formik.submitCount > 0) &&
              !!formik.errors.margin
            }
            helperText={
              (formik.touched.margin || formik.submitCount > 0) &&
              formik.errors.margin
            }
            variant="outlined"
          />
          {initialValues?.margin &&
            formik.values.margin !== initialValues?.margin && (
              <span style={{ color: "red" }}>
                Aviso: O valor é diferente do padrão.
              </span>
            )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            label="Atacado"
            control={
              <Checkbox
                name="isWholesale"
                label="Margem"
                checked={formik.values.isWholesale}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.isWholesale || formik.submitCount > 0) &&
                  !!formik.errors.isWholesale
                }
                helperText={
                  (formik.touched.isWholesale || formik.submitCount > 0) &&
                  formik.errors.isWholesale
                }
                variant="outlined"
              />
            }
          />
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Button
            isLoading={formik.isSubmitting}
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Form = forwardRef(Component);
