/* eslint-disable react/display-name */
import { CustomLineProgress } from "components/LineProgress";
import { usePopup } from "hooks/usePopup";
import { memo, useEffect, useState } from "react";
import batchWasteService from "services/batchWasteService";
import { Dialog } from "shared";

import { formatData } from "../../utils/formatData";
import { CardsReverseWaste } from "./Cards";
import { ConfirmePage } from "./ConfirmPage";
import { RevertWasteContainer } from "./style";

export const RevertWastePopup = memo(
  ({
    open,
    handleClose,
    batchId,
    refresh,
    handleNewWaste,
    isLoadingRefresh
  }) => {
    const { addPopup } = usePopup();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState({
      open: false,
      text: "",
      onConfirm: () => {},
      onCancel: () => {}
    });
    const stageId = "8,15";

    const getBatchWaste = async () => {
      setLoading(true);
      try {
        const { data: respData } = await batchWasteService.getBatchWaste({
          batchId,
          stageId
        });
        setData(formatData.batchWaste(respData.results));
      } catch (error) {
        const errorMessage = error?.response?.data?.msg;
        addPopup({
          type: "error",
          title: "Erro ao carregar waste",
          description: errorMessage
        });
      } finally {
        setLoading(false);
      }
    };

    const handleClearClose = (newWaste = false) => {
      handleClose();
      !!newWaste && handleNewWaste();
    };

    const onConfirmRevert = async batchWasteId => {
      setLoading(true);
      try {
        await batchWasteService.deleteBatchWaste(batchWasteId);
        addPopup({
          type: "success",
          title: "Waste revertido com sucesso"
        });
        refresh(false);
        setConfirm({
          open: true,
          text: "Waste revertido, deseja realizar uma nova movimentação?",
          onConfirm: () => handleClearClose(true),
          onCancel: () => handleClearClose()
        });
      } catch (error) {
        const errorMessage = error?.response?.data?.msg;
        addPopup({
          type: "error",
          title: "Erro ao reverter waste",
          description: errorMessage
        });
      } finally {
        setLoading(false);
      }
    };

    const handleRevert = async batchWasteId => {
      setConfirm({
        open: true,
        text: "Você tem certeza que deseja reverter esse waste?",
        onConfirm: () => onConfirmRevert(batchWasteId),
        onCancel: () => setConfirm({ ...confirm, open: false })
      });
    };

    const pageContent = {
      false: <CardsReverseWaste data={data} handleRevert={handleRevert} />,
      true: <ConfirmePage {...confirm} />
    }[confirm.open];

    useEffect(() => {
      !!open && getBatchWaste();
      !open && setConfirm({ ...confirm, open: false });
    }, [open]);
    return (
      <Dialog
        open={open}
        handleClose={handleClose}
        title={"Reverter Waste"}
        maxWidth={"30rem"}
      >
        <RevertWasteContainer>
          {loading || isLoadingRefresh ? <CustomLineProgress /> : pageContent}
        </RevertWasteContainer>
      </Dialog>
    );
  }
);
