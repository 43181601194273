export const mapStatusFilter = statusId => {
  const state =
    {
      0: "Todos",
      2: "Recebido",
      4: "Recusado",
      5: "Não trouxe"
    }[statusId] || null;
  return state;
};
