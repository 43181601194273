import { usePopup } from "hooks/usePopup";
import { useRef } from "react";
import migrationService from "services/migrationService";
import { Drawer } from "shared";

import { Form } from "../Form";

export const Edit = ({ isOpen, onClose, refresh, data }) => {
  const formRef = useRef(null);
  const { addPopup } = usePopup();

  const handleClose = () => {
    onClose();
    formRef.current?.resetForm();
  };

  const onSubmit = async dataForm => {
    try {
      const { source_product_base, destination_product_base } = dataForm;

      await migrationService.updateMigrationRules(dataForm.id, {
        source_product_base: source_product_base.id,
        destination_product_base: destination_product_base.id
      });

      addPopup({ type: "success", title: "Regra atualizada" });
      refresh();
      handleClose();
    } catch (err) {
      if (err.response.status !== 401) {
        addPopup({ type: "error", title: "Erro ao atualizar regra" });
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      title="Editar regra de migração"
      open={isOpen}
      onClose={handleClose}
    >
      <Form ref={formRef} initialValues={data} onSubmit={onSubmit} />
    </Drawer>
  );
};
