import { styled, Typography } from "@material-ui/core";

const TypographyStyle = styled(Typography)({
  color: "#353535",
  fontSize: "1.125rem"
});

export const Title = ({ children, ...rest }) => {
  return <TypographyStyle {...rest}>{children}</TypographyStyle>;
};
