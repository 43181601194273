import { Box, TextField } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";

import { ButtonsContent, FieldContent, StyledBox, Text } from "../../style";

export const InitialPage = ({
  receiptQty,
  handleChangeReceiptQty,
  typeOfMeasure,
  onNext,
  handleChangeBoxQty,
  boxQty
}) => {
  const { addPopup } = usePopup();

  const validate = () => {
    const isValidReceipt = receiptQty && !isNaN(parseFloat(receiptQty));
    const isValidBox = boxQty && !isNaN(parseInt(boxQty));

    if (!isValidReceipt) {
      return addPopup({
        type: "error",
        title: "Informações obrigatórias",
        description: "Insira uma quantidade válida"
      });
    }
    if (!isValidBox) {
      return addPopup({
        type: "error",
        title: "Informações obrigatórias",
        description: "Insira uma quantidade de caixas válida"
      });
    }
    onNext(1);
  };

  return (
    <>
      <StyledBox justifyContent="center">
        <Text>Quantos {typeOfMeasure} o seller trouxe?</Text>
        <FieldContent>
          <TextField
            variant="outlined"
            type="number"
            inputProps={{ type: "number", min: "0", step: "1" }}
            label={typeOfMeasure}
            value={receiptQty}
            fullWidth
            onChange={handleChangeReceiptQty}
          />
        </FieldContent>
        <Text>Quantas caixas o seller trouxe?</Text>
        <FieldContent>
          <TextField
            variant="outlined"
            type="number"
            inputProps={{ type: "number", min: "0", step: "1" }}
            label="Número"
            value={boxQty}
            fullWidth
            onChange={handleChangeBoxQty}
          />
        </FieldContent>
        <ButtonsContent>
          <Box display="flex" justifyContent="flex-end">
            <Button
              style={{ width: "50%" }}
              variant="contained"
              onClick={validate}
              endIcon={<NavigateNextIcon />}
            >
              Avançar
            </Button>
          </Box>
        </ButtonsContent>
      </StyledBox>
    </>
  );
};
