import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "components";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { mapType } from "utils";

import {
  ButtonsContent,
  FormContent,
  StyledBox,
  StyledButton,
  StyledFormControl,
  StyledFormControlLabel,
  Title
} from "../../styles";
import { useStyles } from "./styles";
import { validationSchemaCreate } from "./validationSchema";

export const Form = ({
  selectedProductId,
  initialValues,
  onSubmit,
  handleSelectProduct,
  productList
}) => {
  const { handleSubmit, formState, reset, register } = useForm({
    resolver: yupResolver(validationSchemaCreate)
  });
  const { errors } = formState;

  const [purchase_type, setPurchase_type] = useState("");
  const [packaging, setPackaging] = useState("caixa");

  const classes = useStyles();

  const handleResetFields = () => {
    setPurchase_type("");
    setPackaging("caixa");
    reset();
  };

  const onSubmitHandler = data => {
    const newData = {
      ...data,
      selectedProductId,
      packaging
    };

    onSubmit(newData);
    handleResetFields();
  };

  const handleSetValue = () => {
    const productPurchaseType = productList.find(
      ({ id }) => id === selectedProductId
    );

    setPurchase_type(mapType(productPurchaseType?.purchase_type));
  };

  const handleSelect = product => {
    handleSelectProduct(product?.id);
    setPurchase_type(mapType(product?.purchase_type));
  };

  useEffect(() => {
    handleSetValue();
  }, [initialValues]);

  return (
    <FormContent component="form" onSubmit={handleSubmit(onSubmitHandler)}>
      <Title>Dados do produto</Title>

      <StyledBox style={{ flexDirection: "row" }}>
        <StyledFormControl>
          <Autocomplete
            options={productList}
            fullWidth
            getOptionLabel={option => option.name}
            value={productList.find(({ id }) => id === selectedProductId)}
            renderInput={params => (
              <TextField
                label="Selecione um produto"
                {...params}
                variant="outlined"
              />
            )}
            onChange={(_, newValue) => {
              handleSelect(newValue);
            }}
          />
        </StyledFormControl>

        <TextField
          variant="outlined"
          style={{ maxWidth: "6rem" }}
          value={purchase_type}
          disabled
          label="Porção"
        />
      </StyledBox>

      <StyledFormControl>
        <StyledBox style={{ justifyContent: "space-between" }}>
          <TextField
            variant="outlined"
            defaultValue={initialValues?.average_weight}
            {...register("averageWeight")}
            error={!!errors?.averageWeight}
            helperText={errors?.averageWeight?.message}
            type="number"
            label="Qtd. média (caixa/saco)"
          />

          <StyledFormControl>
            <RadioGroup
              row
              value={initialValues?.packaging.toLowerCase() || packaging}
              onChange={event => setPackaging(event.target.value)}
              style={{ gap: "0.5rem" }}
            >
              <StyledFormControlLabel
                value="caixa"
                control={<Radio />}
                label="Caixa"
              />

              <StyledFormControlLabel
                value="saco"
                control={<Radio />}
                label="Saco"
              />
            </RadioGroup>
          </StyledFormControl>
        </StyledBox>
      </StyledFormControl>

      <Title>Regras da Blitz</Title>

      <StyledBox>
        <TextField
          variant="outlined"
          {...register("samplePercentage")}
          defaultValue={initialValues?.sample_percentage}
          error={!!errors?.samplePercentage}
          helperText={errors?.samplePercentage?.message}
          label="% da amostra"
          type="number"
          fullWidth
        />

        <TextField
          variant="outlined"
          {...register("minSample")}
          defaultValue={initialValues?.min_sample}
          error={!!errors?.minSample}
          helperText={errors?.minSample?.message}
          label="Amostra mín. (Caixa/Saco)"
          fullWidth
        />
      </StyledBox>

      <StyledBox>
        <TextField
          variant="outlined"
          {...register("minAverageWeight")}
          defaultValue={initialValues?.min_average_weight}
          label="Peso médio mínimo"
          inputProps={{
            type: "number",
            min: "0",
            step: "any"
          }}
          fullWidth
        />

        <TextField
          variant="outlined"
          {...register("maxAverageWeight")}
          defaultValue={initialValues?.max_average_weight}
          label="Peso médio máximo"
          inputProps={{
            type: "number",
            min: "0",
            step: "any"
          }}
          fullWidth
        />
      </StyledBox>

      <StyledBox>
        <TextField
          multiline
          rows={3}
          defaultValue={initialValues?.observation}
          {...register("observation")}
          error={!!errors?.observation}
          helperText={errors?.observation?.message}
          variant="outlined"
          label="Observação"
          fullWidth
        />
      </StyledBox>

      <ButtonsContent>
        <StyledButton
          variant="contained"
          type="submit"
          disabled={!selectedProductId}
        >
          Salvar
        </StyledButton>
      </ButtonsContent>
    </FormContent>
  );
};
