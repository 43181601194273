import { PopupContainer } from "components";
import { createContext, useCallback, useContext, useState } from "react";
import { v4 } from "uuid";

const PopupContext = createContext({});

const PupupProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addPopup = useCallback(
    ({ type, title, description }) => {
      const id = v4();

      const popup = {
        id,
        type,
        title,
        description
      };

      setMessages(state => [...state, popup]);
    },
    [messages]
  );

  const removePopup = id => {
    setMessages(state => state.filter(message => id !== message.id));
  };

  return (
    <PopupContext.Provider value={{ addPopup, removePopup }}>
      {children}
      <PopupContainer messages={messages} />
    </PopupContext.Provider>
  );
};

const usePopup = () => {
  const context = useContext(PopupContext);
  return context;
};

export { PupupProvider, usePopup };
