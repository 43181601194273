import { Box, IconButton, Tooltip } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ContainerPage, TitlePage } from "components";
import PageLoader from "components/PageLoad";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import stockServiceV2 from "services/stockServicesV2";
import { useDebaunce } from "utils";

import { SummaryStockTable } from "./components/SummaryStockTable";
import {
  MainContent,
  RefreshBtnContent,
  SearchContent,
  SearchInput
} from "./style";

export const SummaryStockSku = () => {
  const { addPopup } = usePopup();
  const [summaryStockData, setSummaryStockData] = useState([]);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });

  const getInitData = async () => {
    try {
      setLoading(true);
      setSummaryStockData([]);
      const { data } = await stockServiceV2.getResumeStockBySku();
      setSummaryStockData(data.results);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar lotes",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  useEffect(() => {
    getInitData();
  }, []);

  if (loading) {
    return (
      <PageLoader
        textInfo={
          "Estamos carregando os dados, essa ação pode levar alguns minutos"
        }
      />
    );
  }
  return (
    <ContainerPage>
      <Box>
        <TitlePage>Estoque resumo por sku </TitlePage>
      </Box>
      <MainContent>
        <SearchContent>
          <SearchInput
            placeholder="Sku ou nome do sku"
            onChange={debaunceSearch}
          />
          <RefreshBtnContent>
            <Tooltip title="Atualizar" aria-label="Atualizar" color="primary">
              <IconButton onClick={() => getInitData()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </RefreshBtnContent>
        </SearchContent>
        <SummaryStockTable stockData={summaryStockData} search={search} />
      </MainContent>
    </ContainerPage>
  );
};
