import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class PrintService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async printTags(data) {
    const url = `${this.url}/api/nabo/v1/nabo/pre-picking/print-tag`;
    return await api.post(url, data);
  }

  async printTagsV2(data) {
    const url = `${this.url}/api/nabo/v1/nabo/pre-picking/v2/print-tag`;
    return await api.post(url, data);
  }

  async printBatchTag(id, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/tag/${id}`;
    return await api.post(url, data);
  }
}

const printService = new PrintService();
export default printService;
