import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import { Button, ContainerPage, TitlePage } from "components";
import { MainContent } from "components/Contents";
import { SearchInput } from "components/Inputs";
import { Paginate } from "components/Paginate";
import { useAuth } from "hooks/useAuth";
import { usePopup } from "hooks/usePopup";
import { useEffect, useRef, useState } from "react";
import { AiFillPrinter, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Redirect } from "react-router-dom";
import batchService from "services/batchService";
import { useDebaunce } from "utils";
import { unauthorizedUsers } from "utils/checkUnauthorizedUsers";

import { BatchTable } from "./components/batchTable";
import { ReceiptDateFilter } from "./components/FilterSelect/ReceiptDateFilter";
import { StatusFilter } from "./components/FilterSelect/StatusFilter";
import { Reprint } from "./Reprint";
import { FrexcoSwitch, PaginateContent, StyledSearchContent } from "./style";
import { formatData } from "./utils/formatData";

export const ProductBatch = () => {
  const { user } = useAuth();
  const { addPopup } = usePopup();
  const reprintRef = useRef(null);
  const [batchData, setBatchData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [selectedFilterDate, setSelectedFilterDate] = useState(null);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null);
  const controller = new AbortController();
  const signal = controller.signal;
  const isUnauthorizedUser = unauthorizedUsers.batch(user);
  const [buttonWaste, setButtonWaste] = useState(false);

  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });
  const debaunceFilterDate = useDebaunce({ fn: handleFilterDate, delay: 800 });

  const getInitData = async () => {
    try {
      setLoading(true);
      setBatchData([]);

      const params = {
        page,
        search,
        status: selectedFilterStatus,
        receiptDate: selectedFilterDate,
        signal,
        wasteToClassify: buttonWaste
      };
      const { data } = await batchService.getBatches(params);

      setBatchData(formatData.batches(data.results));
      setTotalPages(data.pageSize);
    } catch (error) {
      if (!signal.aborted) {
        const errorMessage = error?.response?.data?.msg;
        addPopup({
          type: "error",
          title: "Erro ao carregar lotes",
          description: errorMessage
        });
      }
    } finally {
      setLoading(false);
    }
  };

  function resetPage() {
    setPage(1);
  }

  function handleSearch(e) {
    const { value } = e.target;
    resetPage();
    setSearch(value?.trim());
  }

  function handleChangePage(e) {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
  }

  function handleFilterDate(e) {
    const { value } = e.target;
    const formattedValue = value && new Date(value).toISOString();
    resetPage();
    setSelectedFilterDate(formattedValue);
  }

  function handleFilterStatus(e) {
    const { value } = e.target;
    resetPage();
    setSelectedFilterStatus(value);
  }

  function handleButtonWaste() {
    setButtonWaste(prevButtonWaste => !prevButtonWaste);
  }

  useEffect(() => {
    getInitData();
    return () => {
      controller.abort();
    };
  }, [page, search, selectedFilterDate, selectedFilterStatus, buttonWaste]);

  if (isUnauthorizedUser) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <ContainerPage>
        <TitlePage>Rastreio de lote</TitlePage>
        <MainContent>
          <StyledSearchContent>
            <SearchInput
              placeholder="ID, seller ou produto"
              onChange={debaunceSearch}
            />
            <StatusFilter
              handleChange={handleFilterStatus}
              selectedValue={selectedFilterStatus}
            />
            <ReceiptDateFilter
              handleFilterDate={debaunceFilterDate}
              selectedValue={selectedFilterDate}
            />
            <FormGroup style={{ margin: "1rem" }}>
              <FormControlLabel
                control={
                  <FrexcoSwitch
                    sx={{ m: 1 }}
                    onClick={handleButtonWaste}
                    checked={buttonWaste}
                  />
                }
                label="Waste a classificar"
                style={{ gap: "0.875rem" }}
              />
            </FormGroup>
            <Button
              style={{ marginLeft: "auto" }}
              onClick={() => reprintRef.current?.handleOpen()}
              endIcon={<AiFillPrinter />}
              color="primary"
              variant="contained"
            >
              Impressão
            </Button>
          </StyledSearchContent>
          <BatchTable batchData={batchData} loading={loading} />

          <PaginateContent>
            {totalPages > 1 && (
              <Paginate
                breakLabel="..."
                nextLabel={<AiOutlineRight />}
                forcePage={page - 1}
                onPageChange={handleChangePage}
                pageRangeDisplayed={3}
                pageCount={totalPages}
                previousLabel={<AiOutlineLeft />}
                renderOnZeroPageCount={null}
                loading={loading}
              />
            )}
          </PaginateContent>
        </MainContent>
      </ContainerPage>
      <Reprint ref={reprintRef} />
    </>
  );
};
