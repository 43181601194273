import { CircularProgress } from "@material-ui/core";

import { LevelsContainer, LevelsInfo, LevelsQuantity } from "../../styles";

export const Levels = ({ levels, loading }) => {
  const levelInfo = loading
    ? "Carregando os dados, aguarde..."
    : "Quantidade máxima de sensibilidade, quanto menor mais sensível";

  const levelsQuantity = loading ? <CircularProgress /> : levels;
  return (
    <LevelsContainer>
      <LevelsQuantity>{levelsQuantity}</LevelsQuantity>
      <LevelsInfo>{levelInfo}</LevelsInfo>
    </LevelsContainer>
  );
};
