import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  Box,
  IconButton
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import { CircleActive, PermissionGate } from "components";
import { ChipStatus } from "components/ChipStatus";

import {
  HeadCell,
  ProductCell,
  Button,
  StyledCell,
  StyledTableRow
} from "../../style";
import { NewBatchButton, PrintButton } from "../StepsPopup/style";

export const PendingTable = ({
  batches,
  handleOpenSteps,
  setOpenUndeliveredBatchPopup,
  handleOpenEditTaxEntryPopUp,
  blitzData
}) => {
  return (
    <Table>
      <TableHead>
        <StyledTableRow>
          <HeadCell>Lote</HeadCell>
          <HeadCell>Produto base</HeadCell>
          <HeadCell>Data</HeadCell>
          <HeadCell>Porção</HeadCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody style={{ width: "100%" }}>
        {batches?.map(batch => {
          const isFirstStep = !batch.blitz_step;
          const unloading = batch.blitz_step === 4;
          const filterProductsBlitzRules = blitzData.filter(item => {
            return item.product?.id === batch.product?.id;
          });
          const procutsWithoutBlitz =
            filterProductsBlitzRules.length === 0 && batch.blitz_step === 1;
          return (
            <StyledTableRow
              style={{
                background:
                  unloading || procutsWithoutBlitz
                    ? "#FFECD6"
                    : !isFirstStep
                    ? "#E9DEFE"
                    : ""
              }}
              key={batch.id}
            >
              <ProductCell>{batch.id}</ProductCell>
              <ProductCell>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ gap: "0.7rem" }}
                >
                  {batch.product?.name}{" "}
                  {batch.outOfStock && (
                    <CircleActive
                      color="#D84315"
                      title="Produto fora de estoque"
                    />
                  )}
                </Box>
              </ProductCell>
              <ProductCell>{batch.createdDate}</ProductCell>
              <StyledCell>{batch.purchaseType}</StyledCell>
              <StyledCell>
                {!isFirstStep ? (
                  <PermissionGate permissions={["Invoicing"]}>
                    <IconButton
                      onClick={() => handleOpenEditTaxEntryPopUp(batch.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </PermissionGate>
                ) : null}
              </StyledCell>
              <TableCell align="right">
                <Box display="flex" justifyContent="flex-end">
                  {isFirstStep && (
                    <Button
                      background="#eb001b"
                      onClick={() => setOpenUndeliveredBatchPopup(batch)}
                    >
                      Não trouxe
                    </Button>
                  )}

                  <Button
                    background={
                      unloading || procutsWithoutBlitz
                        ? "#EF8100"
                        : !isFirstStep
                        ? "#854AC2"
                        : "#89bd23"
                    }
                    onClick={() => handleOpenSteps(batch.id)}
                  >
                    {unloading || procutsWithoutBlitz
                      ? "Descarregar"
                      : !isFirstStep
                      ? "Iniciar Blitz"
                      : "Registrar"}
                  </Button>
                </Box>
              </TableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const FinishedTable = ({
  batches,
  handleOpenNewBatchPopup,
  handleOpenPrintPopup,
  handleOpenEditTaxEntryPopUp
}) => {
  return (
    <Table>
      <TableHead>
        <StyledTableRow>
          <HeadCell>Id</HeadCell>
          <HeadCell>Produto base</HeadCell>
          <HeadCell>Data</HeadCell>
          <HeadCell>Recebimento</HeadCell>
          <HeadCell>Porção</HeadCell>
          <TableCell></TableCell>
          <HeadCell>Status</HeadCell>
          <HeadCell></HeadCell>
          <HeadCell></HeadCell>
        </StyledTableRow>
      </TableHead>
      <TableBody style={{ width: "100%" }}>
        {batches?.map(batch => {
          const isReceipted = batch.status?.id === 2;
          const isFirstStep = !batch.blitz_step;
          return (
            <StyledTableRow key={batch.id}>
              <ProductCell>{batch.id}</ProductCell>
              <ProductCell>{batch.product?.name}</ProductCell>
              <StyledCell>{batch.createdDate}</StyledCell>
              <StyledCell>
                {batch.receiptDate} {batch.receiptTime}
              </StyledCell>
              <StyledCell>{batch.purchaseType}</StyledCell>
              <StyledCell>
                {!isFirstStep ? (
                  <PermissionGate permissions={["Invoicing"]}>
                    <IconButton
                      onClick={() => handleOpenEditTaxEntryPopUp(batch.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </PermissionGate>
                ) : null}
              </StyledCell>
              <TableCell>
                <ChipStatus
                  statusId={batch.status?.id}
                  label={batch.status?.name}
                />
              </TableCell>
              <TableCell align="right">
                <PrintButton
                  onClick={() => handleOpenPrintPopup(batch.id)}
                  disabled={!isReceipted}
                  color="primary"
                >
                  <PrintIcon />
                </PrintButton>
              </TableCell>
              <TableCell align="right">
                <NewBatchButton
                  onClick={() => handleOpenNewBatchPopup(batch.id)}
                  disabled={!isReceipted}
                >
                  <AddIcon />
                </NewBatchButton>
              </TableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
