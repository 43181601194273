import { Button, CircularProgress } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import batchService from "services/batchService";
import { mapType } from "utils";

import {
  ButtonsContent,
  LoadingBlitzContent,
  PopupContent,
  ProductInfo,
  ProductText,
  DialogDefault,
  Text
} from "../StepsPopup/style";

export const NewBatchPopup = ({
  openNewBatchPopup,
  handleCloseNewBatchPopup,
  batchData
}) => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);

  const registerNewBatch = async () => {
    try {
      const batchId = batchData.id;
      setLoading(true);
      await batchService.newShipment({ batchId });

      addPopup({
        type: "success",
        title: "Novo lote",
        description: "Novo lote criado com sucesso, vá para aba A Receber"
      });
      handleCloseNewBatchPopup();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro criar novo lote"
      });
    } finally {
      setLoading(false);
    }
  };

  const content = {
    true: (
      <LoadingBlitzContent>
        <CircularProgress color="primary" />
      </LoadingBlitzContent>
    ),
    false: (
      <>
        <Text>Deseja registrar um novo lote?</Text>

        <ButtonsContent style={{ flexDirection: "row" }} marginTop="1rem">
          <Button onClick={handleCloseNewBatchPopup} fullWidth>
            Cancelar
          </Button>
          <Button
            onClick={registerNewBatch}
            variant="contained"
            color="primary"
            fullWidth
          >
            Confirmar
          </Button>
        </ButtonsContent>
      </>
    )
  }[loading];

  return (
    <DialogDefault
      open={openNewBatchPopup}
      handleClose={handleCloseNewBatchPopup}
      title="Receber novo lote"
    >
      <PopupContent>
        <ProductInfo style={{ width: "100%" }}>
          <ProductText>
            Produto: <strong>{batchData?.product?.name}</strong>
          </ProductText>
          <ProductText>
            Seller: <span>{batchData?.seller?.name}</span>
          </ProductText>
          <ProductText>
            Porção: <span>{mapType(batchData?.product?.purchase_type)}</span>
          </ProductText>
        </ProductInfo>
        {content}
      </PopupContent>
    </DialogDefault>
  );
};
