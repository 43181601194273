import {
  Box,
  Breadcrumbs,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import {
  Button,
  ContainerPage,
  OptionsMenu,
  Paginate,
  TitlePage
} from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlinePlus, AiOutlineRight } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import depotService from "services/depotService";
import { Search } from "shared/Search";

import { EditDepot } from "./components/Edit";
import { PopupCreateManyDepots } from "./components/Edit/createManyDepots";
import {
  PaginateContent,
  SearchContainer,
  SearchContent,
  StyledLink,
  StyledTableHead,
  StyledTableRow
} from "./styles";

const Depots = () => {
  const [editDepot, setEditDepot] = useState({
    open: false,
    data: null
  });
  const [createManyDepots, setCreateManyDepots] = useState({
    open: false,
    data: null
  });
  const [warehouseName, setWarehouseName] = useState("");
  const { warehouseTypeId } = useParams();
  const { addPopup } = usePopup();

  const { data, params, onChange, status, refresh, totalPages, handleSearch } =
    useTableParams({
      service: params => depotService.listDepots(params),
      paramsDefault: {
        search: "",
        warehouseTypeId,
        pageSize: 10,
        orderDirection: "created"
      }
    });

  const warehouseData = useTableParams({
    service: params => depotService.listWarehouseTypes(params),
    paramsDefault: {
      search: "",
      pageSize: 100
    }
  });

  const handleOpenEditDepot = (data = null) => {
    setEditDepot({ open: true, data });
  };

  const handleCloseEditDepot = () => {
    setEditDepot({ open: false, data: null });
  };

  const handleOpenCreateManyDepots = (data = null) => {
    setCreateManyDepots({ open: true, data });
  };

  const handleCloseCreateManyDepots = () => {
    setCreateManyDepots({ open: false, data: null });
  };

  const handlePagination = e => {
    const page = e.selected + 1;
    onChange({ page });
  };

  const handleDepotData = () => {
    data?.status === "error" &&
      addPopup({
        type: "error",
        title: "Houve um erro ao obter os dados dos depósitos"
      });
  };

  const handleWarehouseData = () => {
    warehouseData?.status === "error" &&
      addPopup({
        type: "error",
        title: "Houve um erro ao obter dados dos armazéns"
      });
    warehouseData?.status === "success" &&
      warehouseData?.data?.length &&
      setWarehouseName(
        () =>
          warehouseData.data.find(
            warehouse => warehouse.id === Number(warehouseTypeId)
          ).name || ""
      );
  };

  useEffect(() => {
    handleWarehouseData();
  }, [warehouseData]);

  useEffect(() => {
    handleDepotData();
  }, [data]);

  const options = [
    {
      title: "Editar",
      icon: MdModeEditOutline,
      permissions: ["Admin", "Tech", "Estoque"],
      action: handleOpenEditDepot
    }
  ];

  const depotData = [...data].sort((a, b) => {
    const numA = parseInt(a.abbreviation.replace(/[^0-9]/g, ""));
    const numB = parseInt(b.abbreviation.replace(/[^0-9]/g, ""));
    return numA - numB;
  });

  return (
    <ContainerPage>
      <Breadcrumbs separator={<KeyboardArrowRight />}>
        <StyledLink to="/app/armazens">Armazéns</StyledLink>
        <TitlePage>{warehouseName}</TitlePage>
      </Breadcrumbs>
      <Box
        display="flex"
        flexDirection="column"
        padding="1rem"
        component={Card}
        variant="outlined"
      >
        <SearchContainer>
          <SearchContent>
            <Search
              placeholder="ID ou Nome..."
              defaultValue={params.search}
              onChange={handleSearch}
              autofocus
              fullWidth
            />
          </SearchContent>
          <Box style={{ display: "flex", gap: "1rem" }}>
            <SearchContent>
              <Button
                variant="contained"
                startIcon={<AiOutlinePlus />}
                onClick={() => handleOpenEditDepot()}
                fullWidth
              >
                Criar um depósito
              </Button>
            </SearchContent>
            <SearchContent>
              <Button
                variant="contained"
                startIcon={<AiOutlinePlus />}
                onClick={() => handleOpenCreateManyDepots()}
                fullWidth
              >
                Criar depósitos em massa
              </Button>
            </SearchContent>
          </Box>
        </SearchContainer>
        <TableContainer>
          <Table>
            <StyledTableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Depósito</TableCell>
                <TableCell>Sigla</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {depotData?.map(depot => (
                <StyledTableRow key={depot.id}>
                  <TableCell>{depot.id}</TableCell>
                  <TableCell>{depot.name}</TableCell>
                  <TableCell>{depot.abbreviation}</TableCell>
                  <TableCell align="right">
                    <OptionsMenu data={depot} options={options} />
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginateContent>
          {totalPages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={params.page - 1}
              onPageChange={handlePagination}
              pageRangeDisplayed={3}
              pageCount={totalPages}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
              loading={status === "pending"}
            />
          )}
        </PaginateContent>
      </Box>

      <EditDepot
        depotData={editDepot.data}
        warehouseData={warehouseData.data}
        warehouseTypeId={warehouseTypeId}
        open={editDepot.open}
        action={editDepot.action}
        onClose={handleCloseEditDepot}
        refreshData={refresh}
      />

      <PopupCreateManyDepots
        depotData={createManyDepots.data}
        warehouseData={warehouseData.data}
        warehouseTypeId={warehouseTypeId}
        open={createManyDepots.open}
        action={createManyDepots.action}
        onClose={handleCloseCreateManyDepots}
        refreshData={refresh}
      />
    </ContainerPage>
  );
};

export default Depots;
