import { Tabs, Tab } from "pages/private/Receivement/style";

import { Paper } from "./styles";

export const TabConfirmBatch = ({ activeTab, handleChangeTable }) => (
  <Paper square>
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_, value) => handleChangeTable(value)}
      variant="fullWidth"
      style={{ marginBottom: "2rem" }}
    >
      <Tab label="Entrada" />
      <Tab label="Recusa" />
    </Tabs>
  </Paper>
);
