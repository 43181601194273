import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  toggleGroup: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  },
  toggleButton: {
    border: "none",
    "&.Mui-selected": {
      background: "transparent"
    }
  },
  chip: {
    border: "1px solid #88BC23",
    color: "#88BC23",
    background: "#ffffff",
    cursor: "pointer"
  },
  chipCheck: {
    border: "1px solid #88BC23",
    color: "#ffffff",
    background: "#88BC23",
    cursor: "pointer"
  },
  chipCheckCeasa: {
    border: "1px solid #a7b1eb",
    color: "#ffffff",
    background: "#a7b1eb",
    cursor: "pointer"
  },
  titleDays: {
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },
  iconHelp: {
    color: "#88BC23",
    cursor: "pointer"
  },
  spanError: {
    color: "#f44336",
    fontSize: "0.75rem",
    fontWeight: "lighter"
  }
});
