import { Box, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Button, ContainerPage, Paginate, TitlePage } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState, useMemo, useRef } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import selectionGroupsService from "services/selectionGroupsService";
import storageService from "services/storageService";
import { Search } from "shared/Search";
import { useDebaunce } from "utils";

import { DrawerGroupForm } from "./components/CreateGroup";
import { GroupTable } from "./components/GroupTable";
import { FormFilterStyle, PaginateContent, useStyles } from "./styles";

const mergeArrays = (productBase, productBaseConfig) => {
  return productBase.reduce((acc, item) => {
    const duplicateProductId = productBaseConfig.find(
      config => config.productBaseId === item.id
    );
    if (duplicateProductId) {
      return [...acc, duplicateProductId];
    }
    return [
      ...acc,
      {
        productBaseName: item.name,
        productBaseId: item.id,
        groupId: null
      }
    ];
  }, []);
};

export default function SelectionGroups() {
  const groupFormRef = useRef(null);
  const { addPopup } = usePopup();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [filterSelect, setFilterSelect] = useState("all");
  const [formatedData, setFormatedData] = useState([]);
  const [page, setPage] = useState(1);

  const getInitData = async () => {
    try {
      setIsLoading(true);
      setPage(1);
      const params = {
        pageSize: 1000,
        status: "active"
      };

      const [productConfig, productResp, groupResp] = await Promise.all([
        selectionGroupsService.getProductBaseConfig(params),
        storageService.getBaseProducts(params),
        selectionGroupsService.getGroups(params)
      ]);

      setGroups(groupResp.results);
      setFormatedData(
        mergeArrays(productResp.data.results, productConfig.results)
      );
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Não foi possível obter dados dos grupos",
        description: errorMessage
      });
    } finally {
      setIsLoading(false);
    }
  };

  const searchDebaunce = useDebaunce({ fn: handleSearch, delay: 300 });

  function handleSearch(value) {
    setPage(1);
    setSearchParams(value);
  }

  function handleFilter(value) {
    setPage(1);
    setFilterSelect(value);
  }

  function handleChangePage(e) {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
  }

  const [filteredData, totalPages] = useMemo(() => {
    const isFilter = filterSelect !== "all";
    const initialPage = (page - 1) * 15;
    const finalPage = initialPage + 15;

    const data =
      searchParams || isFilter
        ? (formatedData || [])
            .filter(item => {
              return item.productBaseName
                ?.toLowerCase()
                .includes(searchParams.toLocaleLowerCase());
            })
            .filter(item => {
              if (isFilter && filterSelect === "noGroup") {
                return !item.groupId;
              }
              return !isFilter ? item : item.groupId === filterSelect;
            })
        : formatedData;
    return [data.slice(initialPage, finalPage), Math.ceil(data.length / 15)];
  }, [searchParams, formatedData, filterSelect, page]);

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <>
      <ContainerPage>
        <TitlePage>Grupos de Seleção</TitlePage>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          style={{ marginBottom: "1rem" }}
        >
          <Box className={classes.boxAlign}>
            <Search
              placeholder="Qual produto você busca?"
              onChange={e => searchDebaunce(e.target.value)}
              defaultValue={searchParams}
              className={classes.search}
              autoFocus
            />
            <FormFilterStyle variant="outlined" fullWidth>
              <InputLabel>Filtrar por grupo</InputLabel>
              <Select
                label="Filtrar por grupo"
                value={filterSelect}
                defaultValue="all"
                onChange={e => handleFilter(e.target.value)}
              >
                <MenuItem value="all">Todos</MenuItem>
                <MenuItem value="noGroup">Sem grupo</MenuItem>
                {groups.map(group => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormFilterStyle>
          </Box>

          <Box display="flex" style={{ gap: "0.5rem" }}>
            <Button
              style={{
                gap: "0.5rem",
                borderRadius: "0.75rem",
                background: "#89BD23",
                color: "#FFF"
              }}
              onClick={() => groupFormRef.current?.handleOpen("create")}
            >
              <IoMdAdd size={24} style={{ marginBottom: "-5px" }} />
              Criar grupo
            </Button>
            <Button
              style={{
                gap: "0.5rem",
                borderRadius: "0.75rem",
                background: "#89BD23",
                color: "#FFF"
              }}
              onClick={() => groupFormRef.current?.handleOpen("update")}
            >
              Editar grupo
            </Button>
          </Box>
          <DrawerGroupForm
            ref={groupFormRef}
            groups={groups}
            refresh={getInitData}
          />
        </Box>
        <GroupTable
          groupData={groups}
          loading={isLoading}
          refresh={getInitData}
          data={filteredData}
        />
        <PaginateContent>
          {totalPages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={page - 1}
              onPageChange={handleChangePage}
              pageRangeDisplayed={3}
              pageCount={totalPages}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </ContainerPage>
    </>
  );
}
