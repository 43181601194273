import { Paginate } from "components/Paginate";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import { PaginateContent } from "../../style";

export const Paginator = ({ totalPages, page, handleChangePage, loading }) => {
  return (
    <PaginateContent>
      {totalPages > 1 && (
        <Paginate
          breakLabel="..."
          nextLabel={<AiOutlineRight />}
          forcePage={page - 1}
          onPageChange={handleChangePage}
          pageRangeDisplayed={3}
          pageCount={totalPages}
          previousLabel={<AiOutlineLeft />}
          renderOnZeroPageCount={null}
          loading={loading}
        />
      )}
    </PaginateContent>
  );
};
