import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import { Button } from "components";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";

import { ButtonsContent } from "../../style";
import { validationSchema } from "./validationsSchema";

export const storageReasonOptions = [
  { id: 17, name: "Alinhamento com o seller" },
  { id: 19, name: "Waste Devolução para o seller" },
  { id: 3, name: "Waste operacional" }
];

const batchReasons = [
  { id: 1, name: "Qualidade ruim" },
  { id: 2, name: "Peso médio" }
];

export const WastePage = ({ handleSubmit, batchData }) => {
  const validation = validationSchema(batchData);
  const { combinedDevolutionId } = batchData;
  const [time, setTime] = useState(0);
  const initialValues = {
    quantity: "",
    storageReasonId: "",
    batchReasonId: ""
  };

  const handleSelectReason = e => {
    const option = e.target.value;
    const hasNoReturn = option === 17 && Number(combinedDevolutionId) === 1;
    hasNoReturn ? setTime(60) : setTime(0);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      time ? setTime(prev => --prev) : clearInterval(intervalId);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
        >
          <Field
            as={TextField}
            fullWidth
            name="quantity"
            type="number"
            variant="outlined"
            label="Quantidade de Waste"
          />
          <ErrorMessage
            name="quantity"
            component="div"
            style={{ color: "red" }}
          />

          <Field
            as={TextField}
            select
            fullWidth
            name="storageReasonId"
            label="Tipo de movimentação"
            variant="outlined"
            onChange={e => {
              handleChange(e);
              handleSelectReason(e);
            }}
            disabled={values.quantity === 0}
          >
            {storageReasonOptions.map(option => (
              <MenuItem
                key={option.id}
                value={option.id}
                style={{ textTransform: "none" }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Field>
          <ErrorMessage
            name="storageReasonId"
            component="div"
            style={{ color: "red" }}
          />

          <Field
            as={TextField}
            select
            fullWidth
            name="batchReasonId"
            label="Motivo"
            variant="outlined"
            disabled={values.quantity === 0}
          >
            {batchReasons.map(option => (
              <MenuItem
                key={option.id}
                value={option.id}
                style={{ textTransform: "none" }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Field>
          <ErrorMessage
            name="batchReasonId"
            component="div"
            style={{ color: "red" }}
          />
          <ButtonsContent>
            <Button
              variant="contained"
              style={{ backgroundColor: "#854AC2" }}
              fullWidth
              onClick={() => handleSubmit(values, true)}
            >
              Salvar e Finalizar
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={time}
              fullWidth
              onClick={() => handleSubmit(values, false)}
            >
              {time ? `Aguarde ${time} segundos` : "Classificar"}
            </Button>
          </ButtonsContent>
        </Form>
      )}
    </Formik>
  );
};
