import { DialogConfirmDelete } from "components";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import depotService from "services/depotService";

import { StyledDrawer } from "../../styles";
import { PackagingForm } from "../Form";

export const ModalEdit = ({ open, handleClose, data, refresh }) => {
  const { addPopup } = usePopup();

  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const submit = async formData => {
    try {
      setLoading(true);
      await depotService.patchPackagingType(data?.id, formData);
      addPopup({
        type: "success",
        title: "Embalagem atualizada com sucesso."
      });
      handleClose();
      refresh();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao editar embalagem",
        description: error.response?.data?.msg ?? ""
      });
    } finally {
      setLoading(false);
    }
  };

  const submitDelete = async () => {
    try {
      setLoading(true);
      await depotService.deletePackagingType(data?.id);
      addPopup({
        type: "success",
        title: "Embalagem apagada com sucesso."
      });
      handleClose();
      refresh();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao apagar embalagem",
        description: error.response?.data?.msg ?? ""
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    setOpenConfirm(true);
  };
  return (
    <StyledDrawer
      anchor="right"
      open={open}
      onClose={handleClose}
      title="Editar embalagem"
    >
      <PackagingForm
        initialValues={data}
        onsubmit={submit}
        loading={loading}
        handleDelete={handleDelete}
      />

      <DialogConfirmDelete
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        confirm={submitDelete}
      />
    </StyledDrawer>
  );
};
