import batchService from "services/batchService";
import { generateBatchExternalCode } from "utils/generateBatchExternalCode";

export const checkBatch = async ({ batchId, productBaseId }) => {
  try {
    const { data: batchData } = await batchService.getBatchDetail(batchId);
    const batchProductBaseId = batchData.product?.id;
    const shelfLife = batchData.product?.shelf_life + 1;
    const BatchReceiptDate = batchData.receipt_date;
    const dateNow = new Date();
    const receiptDate = BatchReceiptDate && new Date(BatchReceiptDate);
    const isInvalidProductBase = productBaseId !== batchProductBaseId;
    const isInvalidStatus = batchData.status?.id !== 2;

    if (isInvalidProductBase) {
      throw new Error("O produto base do lote é diferente do sku selecionado", {
        status: 400
      });
    }

    if (isInvalidStatus) {
      throw new Error("O lote informado não foi recebido", {
        status: 400
      });
    }

    const shelfLifeDate = receiptDate.setDate(
      receiptDate.getDate() + shelfLife
    );

    const batchExternalCode = generateBatchExternalCode(batchData);
    const isProductExpired = dateNow >= shelfLifeDate;
    const classification = batchData.classification;
    return { batchExternalCode, isProductExpired, classification };
  } catch (error) {
    const notFound = error.response?.status === 404;
    const errorMessage = notFound
      ? `Lote ${batchId} não encontrado`
      : error.message;
    throw new Error(errorMessage);
  }
};
