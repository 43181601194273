import { Divider, useMediaQuery } from "@material-ui/core";

export const CustomDivider = () => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <Divider
      orientation={isSmallScreen ? "horizontal" : "vertical"}
      flexItem={!isSmallScreen}
    />
  );
};
