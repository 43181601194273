import { Grid, Box } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloudDownload from "@material-ui/icons/CloudDownload";
import MapIcon from "@material-ui/icons/Map";
import { ContainerPage, Button, TitlePage, OptionsMenu } from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import fileDownload from "js-file-download";
import { useState, useRef, useCallback } from "react";
import { FaPlus } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import sellerOrderService, {
  SellerOrderStatus
} from "services/sellerOrderService";
import { Table } from "shared";
import { ConfirmationPopup } from "shared/ConfirmationPopup";
import { formatDateToBR, convertDate } from "utils";

import { ConfirmCancelPopup } from "./components/ConfirmCancelPopup";
import { Create } from "./components/Create";
import { Edit } from "./components/Edit";
import getPayload from "./utils/getPayload";

const SellerOrder = () => {
  const { addPopup } = usePopup();
  const [cancelSellerOrderPopup, setCancelSellerOrderPopup] = useState({
    isOpen: false,
    data: {}
  });
  const [editSellerOrderPopup, setEditSellerOrderPopup] = useState({
    isOpen: false,
    data: {}
  });
  const [isLoading, setIsLoading] = useState(false);
  const [createSellerOrderPopup, setCreateSellerOrderPopup] = useState(false);
  const { data, params, onChange, status, refresh, count, handleSearch } =
    useTableParams({
      service: params => sellerOrderService.getSellerOrder({ ...params }),
      paramsDefault: {
        orderDirection: "-updated"
      }
    });

  const [confirmationPopupOpen, setPopupConfirmationOpen] = useState({
    isOpen: false,
    handleConfirm: () => {}
  });

  const openConfirmationPopup = confirmAction => {
    setPopupConfirmationOpen({
      isOpen: true,
      handleConfirm: confirmAction
    });
  };

  const [step, setStep] = useState(1);

  const formRef = useRef(null);
  const removedItems = useRef([]);
  const removedPackaging = useRef([]);

  const handleCreateSellerOrder = useCallback(async data => {
    setIsLoading(true);
    try {
      const payload = getPayload(data);
      await sellerOrderService.postSellerOrder(payload);
      addPopup({ type: "success", title: "Pedido criado com sucesso" });
      refresh();
      handleClose();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao criar pedido",
        description: err.response?.data?.message || ""
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleUpdateSellerOrder = useCallback(async data => {
    try {
      setIsLoading(true);
      const payload = getPayload(data);
      await sellerOrderService.updateSellerOrder(payload);
      if (removedItems.current.length > 0) {
        await sellerOrderService.deleteSellerOrderItems(removedItems.current);
      }
      if (removedPackaging.current.length > 0) {
        await sellerOrderService.deleteSellerOrderPackaging(
          removedPackaging.current
        );
      }
      addPopup({
        type: "success",
        title: "Pedido atualizado com sucesso."
      });
      refresh();
      handleClose();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao atualizar pedido",
        description: err.response?.data?.message ?? ""
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleClose = () => {
    setEditSellerOrderPopup({ isOpen: false, data: {} });
    setCreateSellerOrderPopup(false);
    setStep(1);
    formRef.current?.resetForm();
    removedItems.current = [];
    removedPackaging.current = [];
  };

  const options = [
    {
      title: "Editar",
      icon: FiEdit2,
      permissions: ["Estoque", "Estoque físico", "Tech", "Previsão"],
      action: data => setEditSellerOrderPopup({ isOpen: true, data })
    },
    {
      title: "Finalizar",
      icon: CheckCircleOutlineIcon,
      color: "green",
      permissions: ["Tech", "Estoque", "Estoque físico"],
      disabled: data =>
        data?.status?.id === SellerOrderStatus.CANCELED ||
        data?.status?.id === SellerOrderStatus.FINISHED ||
        data?.status?.id === SellerOrderStatus.CONFIRMED,
      action: data =>
        setPopupConfirmationOpen({
          isOpen: true,
          handleConfirm: () =>
            handleUpdateSellerOrder(
              {
                ...data,
                status: SellerOrderStatus.CONFIRMED
              },
              true
            )
        })
    },
    {
      title: "Cancelar",
      icon: BlockIcon,
      color: "red",
      permissions: ["Tech", "Estoque", "Estoque físico"],
      disabled: data =>
        data?.status?.id === SellerOrderStatus.CANCELED ||
        data?.status?.id === SellerOrderStatus.FINISHED,
      action: data => setCancelSellerOrderPopup({ isOpen: true, data })
    }
  ];

  const columns = [
    {
      field: "id",
      label: "Id",
      disabled: true,
      style: { minWidth: "8rem" }
    },
    {
      field: "created",
      label: "Criado em",
      disabled: true,
      align: "center",
      formatter: value => convertDate(new Date(value))
    },
    {
      field: "createdBy",
      label: "Comprador",
      disabled: true,
      align: "center"
    },
    {
      field: "seller",
      label: "Fornecedor",
      align: "center",
      disabled: true,
      formatter: data => data?.name
    },
    {
      field: "scheduledDate",
      label: "Data Agendada",
      align: "center",
      disabled: true,
      formatter: value => formatDateToBR(value)
    },
    {
      field: "all",
      label: "Janela de Retirada",
      align: "center",
      disabled: true,
      formatter: data => `${data?.scheduledTimeFrom} - ${data?.scheduledTimeTo}`
    },
    {
      field: "status",
      label: "Status",
      align: "center",
      disabled: true,
      formatter: data => data?.status
    },
    {
      field: "all",
      label: "Localização",
      align: "center",
      disabled: true,
      formatter: data => (
        <Button
          variant="contained"
          startIcon={<MapIcon />}
          color="primary"
          size="medium"
          onClick={() =>
            window.open(
              `https://www.google.com/maps?q=${data?.address?.lat},${data?.address?.lng}`,
              "_blank"
            )
          }
        >
          Ver no Mapa
        </Button>
      )
    },
    {
      field: "options",
      label: "",
      align: "right",
      style: { minWidth: "50px" },
      disabled: true,
      renderCell: ({ data, page }) => (
        <OptionsMenu data={data} options={options} page={page} />
      )
    }
  ];

  const handleDownloadSellerOrder = async () => {
    try {
      const { data } = await sellerOrderService.exportSellerOrderSheet(params);

      const formattedDate = new Date()
        .toLocaleString()
        .replace(/[:.,\s]/g, "_");

      const fileName = `resumo_de_pedidos_de_fornecedores${formattedDate}.xlsx`;

      fileDownload(data, fileName);
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          description: "Erro ao fazer download"
        });
      }
    }
  };

  return (
    <>
      <ContainerPage>
        <TitlePage>Pedidos para Fornecedores</TitlePage>

        <Grid container wrap="wrap" style={{ marginTop: 0 }}>
          <Grid
            container
            item
            xs={12}
            sm={6}
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ gap: "1rem" }}
          >
            <Button
              startIcon={<CloudDownload />}
              onClick={handleDownloadSellerOrder}
              isFullScreen
              variant="contained"
            >
              Download
            </Button>
          </Grid>
        </Grid>

        <Table
          filters={
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flex="1"
            >
              <Button
                onClick={() => setCreateSellerOrderPopup(true)}
                variant="contained"
                startIcon={<FaPlus size={15} />}
              >
                Novo pedido
              </Button>
            </Box>
          }
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          paginationServer={true}
          handleSearch={handleSearch}
          placeholder="Id do pedido ou fornecedor"
          size="small"
          dateRangeFilter
          selectFilterProps={{
            text: "Status",
            value: params.status,
            options: [
              {
                id: 1,
                label: "Pendente",
                color: { label: "#c69500", background: "#fff5d5" }
              },
              {
                id: 2,
                label: "Confirmado",
                color: { label: "#007bff", background: "#cce5ff" }
              },
              {
                id: 3,
                label: "Concluído",
                color: { label: "#0a892e", background: "#e5f6ea" }
              },
              {
                id: 4,
                label: "Cancelado",
                color: { label: "#890a0a", background: "#FFDADA" }
              }
            ],
            onChange: value => {
              onChange({ status: value });
            }
          }}
        />
      </ContainerPage>

      <ConfirmCancelPopup
        open={cancelSellerOrderPopup.isOpen}
        handleClose={() =>
          setCancelSellerOrderPopup({ isOpen: false, data: {} })
        }
        reloadData={refresh}
        data={cancelSellerOrderPopup.data}
      />

      <Edit
        refresh={refresh}
        isOpen={editSellerOrderPopup.isOpen}
        setIsOpen={() => setEditSellerOrderPopup({ isOpen: false, data: {} })}
        data={editSellerOrderPopup.data}
        openConfirmationPopup={openConfirmationPopup}
        onSubmit={handleUpdateSellerOrder}
        isLoading={isLoading}
        removedItems={removedItems}
        removedPackaging={removedPackaging}
        handleClose={handleClose}
        formRef={formRef}
        step={step}
        setStep={setStep}
      />

      <Create
        refresh={refresh}
        isOpen={createSellerOrderPopup}
        setIsOpen={() => setCreateSellerOrderPopup(false)}
        openConfirmationPopup={openConfirmationPopup}
        onSubmit={handleCreateSellerOrder}
        isLoading={isLoading}
        handleClose={handleClose}
        formRef={formRef}
        setStep={setStep}
        step={step}
      />

      <ConfirmationPopup
        isOpen={confirmationPopupOpen.isOpen}
        onClose={() => setPopupConfirmationOpen(false)}
        onConfirm={confirmationPopupOpen.handleConfirm}
        message="Você tem certeza que deseja finalizar o pedido?"
        description="Ao finalizar o pedido, ele não poderá mais ser alterado."
        title="Confirmação de Finalização"
        confirmText="Sim"
        cancelText="Não"
        localStorageKey="hideOrderConfirmationPopup"
      />
    </>
  );
};

export default SellerOrder;
