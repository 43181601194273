import printService from "services/printService";

export const printTag = async ({ sku, data, batchExternalCode, printer }) => {
  const uniqueTag = data.prePicking === 1 || data.prePicking === 2;
  const {
    sku: skuId,
    name: skuName,
    portionType,
    purchaseValue,
    product_base: productBase
  } = sku;
  try {
    await printService.printTagsV2({
      sku: skuId,
      skuName,
      portionType: sku.portionType,
      purchaseValue,
      productBaseId: productBase.id,
      productBaseName: productBase.name,
      tagQuantity: data.amountOfTags,
      batchId: data.batchId,
      batchExternalCode,
      uniqueTag,
      printer
    });
  } catch (error) {
    const errorData = error?.response?.data;
    throw new Error(errorData?.message || errorData?.msg);
  }
};
