import {
  TextField,
  Grid,
  Box,
  CircularProgress,
  MenuItem
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { usePopup } from "hooks/usePopup";
import { forwardRef, useImperativeHandle, useState } from "react";
import depotService from "services/depotService";

import {
  Dialog,
  Content,
  Span,
  Strong,
  Info,
  Button,
  AddPackageButton
} from "./styles";

const initialOptions = [
  {
    id: 3,
    name: "separado"
  },
  {
    id: 4,
    name: "ceasa"
  },
  {
    id: 5,
    name: "waste"
  },
  {
    id: 6,
    name: "extraOrder"
  }
];

export const Component = ({ refresh }, ref) => {
  const { addPopup } = usePopup();
  const [isOpen, setIsOpen] = useState(false);
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [classifications, setClassifications] = useState([
    { id: 0, reason: "", quantity: "" }
  ]);
  const [disabled, setDisabled] = useState(true);
  const [availableQty, setAvailableQty] = useState(0);

  const handleAddClassification = () => {
    const newId = classifications.length;
    classifications.length < 4 &&
      setClassifications([
        ...classifications,
        { id: newId, reason: "", quantity: "" }
      ]);
  };

  const handleChange = (id, field, value, index) => {
    setClassifications(prevState => {
      const classificationIndex = prevState.findIndex(
        classification => classification.id === id
      );
      let newState = prevState;
      if (classificationIndex === -1) {
        newState = [...prevState, { id, [field]: value }];
      } else {
        newState[classificationIndex] = {
          ...newState[classificationIndex],
          [field]: value
        };
      }
      const total = newState.reduce((total, classification) => {
        return total + Number(classification.quantity ?? 0);
      }, 0);
      setAvailableQty(batch.quantity - total);
      const valueIsEmpty = newState.some(
        classification => !classification.reason || !classification.quantity
      );
      const isGreater = total > batch.quantity;
      setDisabled(valueIsEmpty || isGreater);
      return newState;
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      function createPayload(toQuantity, reasonId) {
        return {
          batchId: batch.batchId,
          warehouseTypeId: batch.warehouseType.id,
          depotId: batch.depot.id,
          quantity: -toQuantity,
          reasonId: reasonId,
          productBaseId: batch.productBaseId,
          packagingTypeId: batch.packagingType.id
        };
      }

      const reasonIds = {
        separado: 3,
        ceasa: 4,
        waste: 5,
        extraOrder: 6
      };

      const payload = classifications.reduce((acc, classification) => {
        const { reason, quantity } = classification;
        if (reason in reasonIds && quantity > 0) {
          acc.push(createPayload(quantity, reasonIds[reason]));
        }
        return acc;
      }, []);
      await depotService.createLedger(payload);
      refresh();
      handleClose();
      addPopup({
        type: "success",
        title: "Lote classicado com sucesso"
      });
    } catch (error) {
      addPopup({
        type: "error",
        title: "Ocorreu um erro ao classificar o lote"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = batch => {
    setBatch(batch);
    setIsOpen(true);
  };

  const handleClose = () => {
    setBatch(null);
    setIsOpen(false);
    setClassifications([{ id: 0, reason: "", quantity: "" }]);
    setDisabled(true);
    setAvailableQty(0);
  };

  useImperativeHandle(ref, () => ({ handleOpen }));

  if (!batch) return null;

  const children = {
    false: (
      <Content component="form">
        <Info>
          <div>
            <Span>ID: </Span>
            <Strong>{batch.batchId}</Strong>
          </div>
          <div>
            <Span>Produto: </Span>
            <Strong>{batch.productBaseName}</Strong>
          </div>
          <div>
            <Span>Embalagem: </Span>
            <Strong>{batch.packagingType?.name}</Strong>
          </div>
          <div>
            <Span>Qnt: </Span>
            <Strong>{batch.quantity}</Strong>
          </div>
        </Info>
        {availableQty < 0 && (
          <span style={{ color: "#D84315" }}>
            O valor ultrapassou a quantidade disponível
          </span>
        )}

        {classifications.map((classification, index) => (
          <Grid container key={classification.id} spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                variant="outlined"
                fullWidth
                value={classification.reason}
                onChange={e =>
                  handleChange(classification.id, "reason", e.target.value)
                }
                label="Classificação"
              >
                {initialOptions
                  .filter(option => {
                    const selectedOptions = classifications
                      .slice(0, index)
                      .map(item => item.reason);
                    return !selectedOptions.includes(option.name);
                  })
                  .map(option => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={classification.quantity}
                onChange={e => {
                  handleChange(classification.id, "quantity", e.target.value);
                }}
                variant="outlined"
                type="number"
                label="Quantidade"
              />
            </Grid>
          </Grid>
        ))}
        <AddPackageButton
          startIcon={<AddIcon />}
          fullWidth
          variant={"contained"}
          onClick={handleAddClassification}
        >
          Adicionar classificação
        </AddPackageButton>

        <Grid
          container
          xs={12}
          direction="row"
          style={{ gap: 16, flexWrap: "nowrap" }}
        >
          <Grid item xs={12} sm={6}>
            <Button onClick={handleClose}>Cancelar</Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              variant="contained"
              onClick={onSubmit}
              disabled={disabled}
            >
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </Content>
    ),
    true: (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    )
  }[String(loading)];
  return (
    <Dialog open={isOpen} handleClose={handleClose} title="Classificar lote">
      {children}
    </Dialog>
  );
};

export const MoveBatchReasonDialog = forwardRef(Component);
