import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  background: {
    width: "100%",
    position: "fixed",
    right: 0,
    bottom: 0,
    minWidth: "100vh",
    minHeight: "100vh",
    zIndex: 0
  },
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: 1,
    background: "black",
    opacity: 0.7
  },
  loginBox: {
    height: "100vh",
    minHeight: "500px",
    width: "50%",
    maxWidth: "580px",
    padding: "0 2%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    zIndex: 2,
    [theme.breakpoints.between("0", "768")]: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      maxWidth: "1000px"
    }
  },
  logoImg: {
    width: "80%",
    maxWidth: "200px",
    position: "absolute",
    top: "10%"
  },
  title: {
    paddingBottom: "2%",
    textAlign: "center",
    width: "100%",
    fontSize: "clamp(1.2rem, 2vw, 2vw)",
    color: "#343434",
    fontWeight: 600,
    fontFamily: '"Roboto", open-sans'
  },
  form: {
    width: "95%",
    display: "flex",
    flexDirection: "column"
  },
  formObjects: {
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
    marginBottom: "3%",
    background: "#fff",
    borderRadius: "5px"
  }
}));
