import { Grid, Box } from "@material-ui/core";
import { Button } from "components";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import { AutoCompleteProductBaseFormik } from "shared";

import {
  validationSchemaCreate,
  validationSchemaEdit
} from "./validationSchema";

const Component = ({ initialValues, onSubmit }, ref) => {
  const formik = useFormik({
    initialValues: initialValues || {},
    validationSchema: initialValues
      ? validationSchemaEdit
      : validationSchemaCreate,
    onSubmit: onSubmit,
    validateOnChange: false
  });

  useImperativeHandle(ref, () => ({ resetForm: formik.resetForm }));

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Box display="flex">
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12} sm={6}>
            <AutoCompleteProductBaseFormik
              name="source_product_base"
              formik={formik}
              label="Produto de origem"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoCompleteProductBaseFormik
              name="destination_product_base"
              formik={formik}
              label="Produto de destino"
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "flex-end",
              minHeight: "calc(90vh - 155px)"
            }}
          >
            <Button
              isLoading={formik.isSubmitting}
              variant="contained"
              type="submit"
              style={{ width: "10rem" }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export const Form = forwardRef(Component);
