import { Box } from "@material-ui/core";
import { useFormik } from "formik";
import { usePopup } from "hooks/usePopup";
import { forwardRef, useState, useImperativeHandle } from "react";
import batchService from "services/batchService";
import { Dialog } from "shared";
import * as Yup from "yup";

import { ActionSubbatch } from "./ActionSubbatch";
import { ConfirmSubbatch } from "./ConfirmSubbatch";
import { EditSubbatch } from "./EditSubbatch";

const generateValidationSchema = (maxQuantity, action) => {
  const quantityValidade = {
    quantity: Yup.number()
      .positive("Valor não pode ser negativo")
      .max(maxQuantity, `A quantidade deve ser menor ou igual a ${maxQuantity}`)
      .typeError("Campo obrigatório")
      .required("Campo obrigatório")
  };

  const boxQuantityValidade = {
    boxQuantity: Yup.number()
      .positive("Valor não pode ser negativo")
      .typeError("Campo obrigatório")
      .required("Campo obrigatório")
  };

  const validation =
    {
      subBatchToSubBatch: {
        destination: Yup.number()
          .typeError("Campo obrigatório")
          .required("Campo obrigatório"),
        ...quantityValidade
      },
      subBatchToWaste: quantityValidade,
      subBatchToPrePicking: {
        ...quantityValidade,
        ...boxQuantityValidade
      },
      subBatchToReadyBox: {
        ...quantityValidade,
        ...boxQuantityValidade
      }
    }[action] ?? {};

  return Yup.object().shape(validation);
};

const Component = ({ subbatch, refresh }, ref) => {
  const { addPopup } = usePopup();
  const [isOpen, setIsOpen] = useState(false);
  const [pageView, setPageView] = useState(1);
  const [action, setAction] = useState(null);

  const validationSchema = generateValidationSchema(subbatch.quantity, action);

  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: () => setPageView(3),
    isInitialValid: false
  });

  const handleConfirm = async () => {
    try {
      await batchService.moveSubbatch(subbatch.id, {
        action,
        ...formik.values
      });
      addPopup({ type: "success", title: "Quantidade movida com sucesso!" });
      handleClose();
      await refresh();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao mover quantidade",
        description: err.response?.data?.msg ?? ""
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    formik.resetForm();
    setPageView(1);
    setAction(null);
  };

  const handleChangeAction = async value => {
    formik.resetForm();
    setAction(value);
  };

  const handleOpen = () => setIsOpen(true);

  const children = {
    1: (
      <ActionSubbatch
        action={action}
        setAction={handleChangeAction}
        onNext={() => setPageView(2)}
      />
    ),
    2: (
      <EditSubbatch
        formik={formik}
        subbatch={subbatch}
        action={action}
        setAction={handleChangeAction}
        onBack={() => setPageView(1)}
        onNext={() => setPageView(3)}
      />
    ),
    3: (
      <ConfirmSubbatch
        subbatch={subbatch}
        quantity={formik.values?.quantity}
        select={formik.values?.destination}
        boxQuantity={formik.values?.boxQuantity}
        action={action}
        onBack={() => setPageView(2)}
        confirm={handleConfirm}
      />
    )
  }[pageView];

  useImperativeHandle(ref, () => ({ handleOpen }));

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title="Movimentar sublote"
      maxWidth="32rem"
    >
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        minHeight="60vh"
        onSubmit={formik.handleSubmit}
      >
        {children}
      </Box>
    </Dialog>
  );
};

export const Movement = forwardRef(Component);
