import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  Grid,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import returnAnalysisService from "services/returnAnalysisService";
import storageService from "services/storageService";
import { Dialog } from "shared";
import { reasonMap } from "utils";
import * as Yup from "yup";

const reasonsReturnAnalysis = [3, 4, 6, 19];

export default function ConfirmReturnAnalysis({
  data,
  setIsOpen,
  refresh,
  open
}) {
  const { addPopup } = usePopup();
  const [reasons, setReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const schema = Yup.object().shape({
    reasonId: Yup.number()
      .typeError("Motivo obrigatório")
      .required("Motivo obrigatório")
  });

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const { errors } = formState;

  const handleClose = () => {
    setIsOpen({
      isOpen: false,
      data: {}
    });
    reset({
      reasonId: null
    });
  };

  const getReasons = async () => {
    try {
      const { data } = await storageService.getReason();

      setReasons(data.filter(({ id }) => reasonsReturnAnalysis.includes(id)));
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Desculpe, ocorreu um erro",
          description: "Erro ao buscar motivos."
        });
      }
    }
  };

  const onSubmitHandler = async dataForm => {
    try {
      const { reasonId } = dataForm;

      setIsLoading(true);
      await returnAnalysisService.returnAnalysisConfirmed(
        {
          evaluation: reasonId
        },
        data?.id
      );

      addPopup({
        type: "success",
        title: "Confirmação realizada com sucesso."
      });

      refresh();
      handleClose();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Desculpe, ocorreu um erro",
        description: "Erro ao confirmar."
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReasons();
  }, []);

  return (
    <Dialog open={open} handleClose={handleClose} title="Confirmando devolução">
      <Alert severity="info" style={{ margin: "0 0 1rem" }}>
        <Typography>
          <strong>Produto:</strong> {data?.product_base?.name} <br></br>
        </Typography>
        <Typography>
          <strong>Seller:</strong> {data?.seller?.name}
        </Typography>
        <Typography>
          <strong>Total a receber:</strong>{" "}
          {`${data?.quantity} ${
            data?.storageTypeName === "unidade" ? "Un" : "kg"
          }`}
        </Typography>
      </Alert>

      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              error={!!errors?.reasonId}
            >
              <InputLabel>Motivo</InputLabel>
              <Select label="Motivo" {...register("reasonId")}>
                {reasons?.map(reason => {
                  return (
                    <MenuItem key={reason} value={reason?.id}>
                      {reasonMap(reason?.reason)}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{errors?.reasonId?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid container item justifyContent="flex-end">
            <Button type="submit" variant="contained" isLoading={isLoading}>
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
