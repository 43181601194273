import { forwardRef, useImperativeHandle, useState } from "react";
import { Dialog } from "shared";

import { BoxesView } from "./BoxesView";
import { ConfirmView } from "./ConfirmView";
import { ProductView } from "./ProductView";
import { Container } from "./style";
import { QuantityView } from "./SubBatchView";
import { TagView } from "./TagView";

const subBatchInitialState = {
  subBatchAQty: "",
  subBatchBQty: "",
  subBatchCQty: ""
};

const Component = ({ refresh, batch }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pageView, setPageView] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [productBase, setProductBase] = useState(null);
  const [boxesQuantity, setBoxesQuantity] = useState("");
  const [newBatch, setNewBatch] = useState({});

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setNewBatch({});
      setBoxesQuantity("");
      setProductBase(null);
      setPageView(0);
      setQuantity("");
    }, 200);
  };

  const handleOpen = () => setIsOpen(true);

  useImperativeHandle(ref, () => ({ handleOpen }));

  const view = {
    0: (
      <ProductView
        onNext={() => setPageView(state => ++state)}
        batch={batch}
        productBase={productBase}
        setProductBase={setProductBase}
      />
    ),
    1: (
      <QuantityView
        onNext={() => setPageView(state => ++state)}
        onBack={() => setPageView(state => --state)}
        batch={batch}
        quantity={quantity}
        setQuantity={setQuantity}
      />
    ),
    2: (
      <BoxesView
        onNext={() => setPageView(state => ++state)}
        onBack={() => setPageView(state => --state)}
        setBoxesQuantity={setBoxesQuantity}
        boxesQuantity={boxesQuantity}
      />
    ),
    3: (
      <ConfirmView
        onNext={() => setPageView(state => ++state)}
        onBack={() => setPageView(state => --state)}
        batch={batch}
        boxesQuantity={boxesQuantity}
        productBase={productBase}
        setNewBatch={setNewBatch}
        quantity={quantity}
        refresh={refresh}
      />
    ),
    4: (
      <TagView
        onNext={() => setPageView(state => ++state)}
        onBack={() => setPageView(state => --state)}
        newBatch={newBatch}
        handleClose={handleClose}
      />
    )
  }[pageView];

  return (
    <Dialog
      open={isOpen}
      title="Migrar lote"
      handleClose={handleClose}
      maxWidth="33rem"
    >
      <Container>{view}</Container>
    </Dialog>
  );
};

export const Migration = forwardRef(Component);
