import { Box } from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { ContainerPage, TitlePage, Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import fileDownload from "js-file-download";
import assetService from "services/assetsService";
import { Table } from "shared";
import { convertDate } from "utils";

export default function AssetsLedger() {
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => assetService.getAssetsLedger({ ...params }),
      paramsDefault: {
        multipleFilter: true,
        orderDirection: "-updated",
        excludeReasonId: 9
      }
    });

  const { addPopup } = usePopup();

  const columns = [
    {
      field: "created",
      label: "Criado em",
      align: "center",
      disabledSort: true,
      style: { minWidth: "150px" },
      formatter: value => convertDate(new Date(value))
    },
    {
      field: "assetName",
      label: "Ativo",
      disabledSort: true,
      style: { minWidth: "170px", maxWidth: "300px" }
    },
    {
      field: "quantity",
      label: "Movimentação",
      align: "center",
      disabledSort: true,
      style: { minWidth: "100px" },
      formatter: value => {
        if (!value) return 0;
        return new Intl.NumberFormat("pt-BR").format(value.toFixed(2));
      },
      cellStyle: data => {
        if (data.quantity < 0) {
          return {
            color: "red"
          };
        }
      }
    },
    {
      field: "createdByName",
      label: "Responsável",
      disabledSort: true,
      style: { minWidth: "140px", maxWidth: "200px" }
    },
    {
      field: "sellerName",
      label: "Fornecedor",
      disabledSort: true,
      style: { minWidth: "180px", maxWidth: "300px" }
    },
    {
      field: "transactionId",
      label: "Transação",
      align: "center",
      disabledSort: true,
      style: { minWidth: "100px" }
    }
  ];

  const handleDownloadLedger = async () => {
    try {
      const { data } = await assetService.exportLedgerSheet(params);

      const formattedDate = new Date()
        .toLocaleString()
        .replace(/[:.,\s]/g, "_");

      const fileName = `extrato_de_ativos${formattedDate}.xlsx`;

      fileDownload(data, fileName);
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          description: "Erro ao fazer download"
        });
      }
    }
  };

  return (
    <>
      <ContainerPage>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <TitlePage>Extrato de Ativos</TitlePage>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            ml="auto"
            style={{ gap: "1rem" }}
            flexWrap="wrap"
          >
            <Button
              variant="contained"
              startIcon={<CloudDownload />}
              onClick={() => handleDownloadLedger()}
            >
              Baixar relatório
            </Button>
          </Box>
        </Box>

        <Table
          handleSearch={handleSearch}
          placeholder={"Transação ou fornecedor"}
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          dateRangeFilter
          hover
        />
      </ContainerPage>
    </>
  );
}
