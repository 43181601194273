import { Button, Grid, InputLabel, Select, TextField } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { forwardRef } from "react";
import { MenuItem } from "shared/Select";

import { depotSchema } from "./validationSchema";

const Component = ({ data, warehouseData, warehouseTypeId, onSubmit }) => {
  const initialValues = {
    name: data?.name,
    warehouseTypeId: data?.warehouseTypeId || warehouseTypeId,
    abbreviation: data?.abbreviation
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={depotSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Field
              as={TextField}
              label="Nome do Depósito"
              name="name"
              variant="outlined"
              placeholder="Nome"
              fullWidth
            />
            <ErrorMessage
              name="name"
              component="div"
              style={{ color: "red" }}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              as={TextField}
              label="Sigla do Depósito"
              name="abbreviation"
              variant="outlined"
              placeholder="Ex.: CF-1"
              fullWidth
              inputProps={{
                style: {
                  textTransform: "uppercase"
                },
                maxLength: 10
              }}
            />
            <ErrorMessage
              name="abbreviation"
              component="div"
              style={{ color: "red" }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="warehouse-label">Selecione um Armazém</InputLabel>
            <Field
              as={Select}
              variant="outlined"
              name="warehouseTypeId"
              defaultValue={data?.warehouseTypeId}
              fullWidth
            >
              {warehouseData?.map(warehouse => (
                <MenuItem key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Field>
            <ErrorMessage
              name="warehouse"
              component="div"
              style={{ color: "red" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ minHeight: "calc(90vh - 230px)" }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{ width: "10rem" }}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export const DepotForm = forwardRef(Component);
