import { Box } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import {
  ContainerPage,
  PermissionGate,
  TitlePage,
  Button,
  OptionsMenu
} from "components";
import { useTableParams } from "hooks/useTableParams";
import { useState } from "react";
import { MdModeEditOutline, MdOutlineDelete } from "react-icons/md";
import skuService from "services/skuService";
import { Table } from "shared";
import { mapStatesString } from "utils";

import { Create } from "./Components/Create";
import { DialogConfirmDelete } from "./Components/DialogConfirmDelete";
import { Edit } from "./Components/Edit";

const Sku = () => {
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => skuService.getSku(params)
    });
  const [dialogCreateSku, setDialogCreateSku] = useState(false);
  const [dialogEdit, setDialogEdit] = useState({
    isOpen: false,
    data: {}
  });

  const handleSetDialogCreateSku = () => {
    setDialogCreateSku(state => !state);
  };

  const [dialogConfirmDelete, setDialogConfirmDelete] = useState({
    isOpen: false,
    data: {}
  });

  const options = [
    {
      title: "Editar sku",
      icon: MdModeEditOutline,
      permissions: ["Tech", "Estoque", "Estoque físico", "CD", "Logistic"],
      action: data =>
        setDialogEdit({
          isOpen: true,
          data
        })
    },
    {
      title: "Excluir sku",
      icon: MdOutlineDelete,
      color: "red",
      permissions: ["Tech", "Estoque", "Estoque físico", "CD", "Logistic"],
      action: data => {
        setDialogConfirmDelete({
          isOpen: true,
          data
        });
      }
    }
  ];

  const columns = [
    {
      field: "image",
      label: "Imagem",
      align: "center",
      style: { minWidth: "50px" },
      renderCell: ({ data }) => (
        <img
          style={{ width: 50, height: 50 }}
          src={data.image}
          title={data.name}
        />
      )
    },
    {
      field: "name",
      label: "Nome",
      style: { minWidth: "200px" }
    },
    {
      field: "sku",
      label: "SKU",
      align: "center",
      style: { minWidth: "150px" }
    },
    {
      field: "status",
      label: "Status",
      align: "center",
      style: { minWidth: "140px" },
      formatter: value => mapStatesString(value)
    },
    {
      field: "portionType",
      label: "Tipo",
      align: "center",
      style: { minWidth: "150px" },
      orderBy: "portion_type"
    },
    {
      field: "purchaseValue",
      label: "Peso",
      align: "center",
      style: { minWidth: "130px" },
      orderBy: "purchase_value"
    },
    {
      field: "margin",
      label: "Margem",
      align: "center",
      style: { minWidth: "130px" }
    },
    {
      field: "separationOrder",
      label: "Ordem de separação",
      align: "center",
      style: { minWidth: "220px" },
      orderBy: "separation_order"
    },
    {
      field: "options",
      label: "",
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  return (
    <>
      <ContainerPage>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <TitlePage>Últimos Skus Adicionados</TitlePage>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            ml="auto"
            style={{ gap: "1rem", marginRight: "1rem" }}
          >
            <PermissionGate
              permissions={[
                "Tech",
                "Estoque",
                "Estoque físico",
                "CD",
                "Logistic"
              ]}
            >
              <Button
                onClick={handleSetDialogCreateSku}
                startIcon={<AddRoundedIcon />}
                variant="contained"
              >
                Sku Individual
              </Button>
            </PermissionGate>
          </Box>
        </Box>

        <Table
          handleSearch={handleSearch}
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          size="small"
        />
      </ContainerPage>

      <Create
        isOpen={dialogCreateSku}
        setIsOpen={setDialogCreateSku}
        refresh={refresh}
      />

      <Edit
        isOpen={dialogEdit.isOpen}
        setIsOpen={setDialogEdit}
        refresh={refresh}
        data={dialogEdit.data}
      />

      <DialogConfirmDelete
        open={dialogConfirmDelete.isOpen}
        handleClose={setDialogConfirmDelete}
        data={dialogConfirmDelete.data}
        refresh={refresh}
      />
    </>
  );
};

export default Sku;
