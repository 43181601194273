import {
  alpha,
  Box,
  Button,
  styled,
  Typography,
  useTheme
} from "@material-ui/core";
import { BiErrorAlt } from "react-icons/bi";
import { MdSearchOff } from "react-icons/md";

const BoxStyle = styled(Box)({
  height: "15rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "1rem"
});

const message = {
  success: {
    title: "Nenhum dado encontrado",
    icon: MdSearchOff
  },
  error: {
    title: "Erro ao buscar dados",
    icon: BiErrorAlt
  }
};

const NoData = ({ status, refresh, customTitle, customIcon }) => {
  const { title, icon: Icon } =
    !customTitle && !customIcon
      ? message[status]
      : { title: customTitle, icon: customIcon };
  const theme = useTheme();

  return (
    <BoxStyle>
      <Icon
        color={alpha(
          status === "error"
            ? theme.palette.warning.main
            : theme.palette.secondary.main,
          0.3
        )}
        size="5rem"
      />
      <Typography
        component="strong"
        style={{ fontWeight: "bold", color: "#757575" }}
      >
        {title}
      </Typography>

      {status === "error" && (
        <Button
          variant="outlined"
          style={{
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main
          }}
          onClick={refresh}
        >
          Tentar novamente
        </Button>
      )}
    </BoxStyle>
  );
};

export default NoData;
