import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class BatchConferenceService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getBatchesConference(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/conference`;
    return await api.get(url, { params });
  }

  async getBatcheConference(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/conference/${id}`;
    return await api.get(url);
  }

  async getConferenceStatus() {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/conference/status`;
    return await api.get(url);
  }

  async updateBatchConference(id, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/conference/${id}`;
    return await api.patch(url, data);
  }
}

const batchConferenceService = new BatchConferenceService();
export default batchConferenceService;
