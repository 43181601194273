import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { refusalInstructions } from "pages/private/PurchaseForecast/utils/refusalInstructions";

export const ReasonFilter = ({ selected, handleFilter }) => (
  <FormControl variant="outlined" style={{ minWidth: "14rem" }}>
    <InputLabel id="classification-label">Devolução combinada</InputLabel>
    <Select
      labelId="classification-label"
      value={selected}
      onChange={handleFilter}
      label="Devolução combinada"
    >
      <MenuItem value="" style={{ textTransform: "none" }}>
        Todos
      </MenuItem>
      {Object.entries(refusalInstructions)
        .filter(([key]) => ["3", "4"].includes(key))
        .map(([key, value]) => (
          <MenuItem key={key} value={key} style={{ textTransform: "none" }}>
            {value.split(" - ")[1]}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
);
