import Button from "@material-ui/core/Button";
import { ContainerPage, PermissionGate, TitlePage } from "components";
import { useTableParams } from "hooks/useTableParams";
import { useState } from "react";
import storageService from "services/storageService";
import { Table } from "shared";
import { convertDate, reasonMap } from "utils";

import ConfirmReturnAnalysis from "./components/ConfirmReturnAnalysis";

export default function ReturnAnalysis() {
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => storageService.getPendingData(params),
      paramsDefault: { reasonId: 17 }
    });

  const [popUpData, setPopUpData] = useState({ isOpen: false, data: {} });

  const columns = [
    {
      field: "created",
      label: "Data",
      align: "center",
      style: { minWidth: "150px" },
      formatter: value => convertDate(new Date(value))
    },
    {
      field: "product_base",
      label: "Produto Base",
      orderBy: "product_base__name",
      style: { minWidth: "170px", maxWidth: "300px" },
      formatter: value => value?.name
    },
    {
      field: "quantity",
      label: "Movimentação",
      align: "center",
      style: { minWidth: "150px" }
    },
    {
      field: "type",
      label: "Tipo",
      align: "center",
      orderBy: "type__id",
      style: { minWidth: "150px" },
      formatter: value => value?.name
    },
    {
      field: "reason",
      label: "Motivo",
      style: { minWidth: "150px" },
      formatter: value => reasonMap(value?.reason)
    },
    {
      field: "seller",
      label: "Fornecedor",
      style: { minWidth: "170px", maxWidth: "300px" },
      orderBy: "seller__name",
      formatter: value => value?.name
    },
    {
      field: "",
      label: "",
      align: "center",
      style: { minWidth: "150px" },
      renderCell: ({ data }) => (
        <PermissionGate
          permissions={["Tech", "Estoque", "Estoque físico", "Previsão"]}
        >
          <Button
            variant="contained"
            size="small"
            onClick={event => {
              setPopUpData({ isOpen: true, data });
            }}
          >
            Confirmar
          </Button>
        </PermissionGate>
      )
    }
  ];

  return (
    <ContainerPage>
      <TitlePage>Análise de devolução</TitlePage>

      <Table
        handleSearch={handleSearch}
        columns={columns}
        data={data}
        onChange={onChange}
        params={params}
        status={status}
        refresh={refresh}
        count={count}
        hover
      />

      <ConfirmReturnAnalysis
        open={popUpData.isOpen}
        data={popUpData.data}
        setIsOpen={setPopUpData}
        refresh={refresh}
      />
    </ContainerPage>
  );
}
