import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class BlitzService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getBlitzData(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/blitz/`;
    return await api.get(url, { params });
  }

  async validateBlitz(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/blitz/validate`;
    return await api.post(url, data);
  }

  async createBlitzLaw(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/blitz/`;
    return await api.post(url, data);
  }

  async updateBlitzLaw(id, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/blitz/${id}`;
    return await api.patch(url, data);
  }
}

const blitzService = new BlitzService();
export default blitzService;
