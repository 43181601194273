import { AccordionSummary, Box, Chip, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CircleActive } from "components";
import { CustomLineProgress } from "components/LineProgress";
import { useEffect, useState } from "react";
import blitzService from "services/blitzService";

import {
  AccordionContent,
  SellerTitle,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionHeader
} from "../../style";
import { FinishedTable, PendingTable } from "../ReceiptTable";

export const ReceiptAccordion = ({
  sellerBatchData,
  handleOpenSteps,
  handleNewBatch,
  handlePrintTag,
  activeTab,
  handleOpenPrintPopup,
  handleOpenNewBatchPopup,
  setOpenUndeliveredBatchPopup,
  dataLoading,
  countBatchesToBlitz,
  handleOpenEditTaxEntryPopUp
}) => {
  const [blitzData, setBlitzData] = useState([]);

  const getBlitzRule = async () => {
    try {
      const { data } = await blitzService.getBlitzData({ pageSize: 1000 });
      setBlitzData(data.results);
    } catch (error) {}
  };

  useEffect(() => {
    getBlitzRule();
  }, []);

  const getBatchesToBlitz = sellerData => {
    const allProductsBlitzRules = blitzData.map(data => data.product?.id);

    const amount = sellerData?.batches?.filter(
      batch =>
        allProductsBlitzRules.includes(batch.product?.id) &&
        batch.blitz_step > 0 &&
        batch.blitz_step < 4
    )?.length;

    const amountUnloading = sellerData?.batches?.filter(
      batch =>
        (!allProductsBlitzRules.includes(batch.product?.id) &&
          batch.blitz_step === 1) ||
        batch.blitz_step === 4
    )?.length;

    return !amount && !amountUnloading ? (
      <></>
    ) : (
      <div style={{ display: "flex", gap: "1rem" }}>
        {amount > 0 && (
          <Chip
            style={{
              backgroundColor: "#854AC2",
              color: "white"
            }}
            label={amount}
          />
        )}
        {amountUnloading > 0 && (
          <Chip
            style={{
              backgroundColor: "#EF8100",
              color: "white"
            }}
            label={amountUnloading}
          />
        )}
      </div>
    );
  };

  return (
    <AccordionContent>
      <Paper elevation={2} style={{ overflow: "hidden" }}>
        {dataLoading && <CustomLineProgress />}
        {sellerBatchData?.map(sellerBatch => {
          const qtyProductOutOfStock = sellerBatch.batches.filter(
            ({ outOfStock }) => outOfStock
          ).length;

          return (
            <StyledAccordion key={sellerBatch.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-content-${sellerBatch.id}`}
                id={`panel-header-${sellerBatch.id}`}
              >
                <StyledAccordionHeader>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: "1rem" }}
                  >
                    <SellerTitle>{sellerBatch.name}</SellerTitle>
                    {!!qtyProductOutOfStock && (
                      <CircleActive
                        color="#D84315"
                        title={`${qtyProductOutOfStock} produto${
                          qtyProductOutOfStock > 1 ? "s" : ""
                        } fora de estoque`}
                      />
                    )}
                  </Box>
                  {countBatchesToBlitz && getBatchesToBlitz(sellerBatch)}
                </StyledAccordionHeader>
              </AccordionSummary>
              <StyledAccordionDetails>
                {!activeTab ? (
                  <PendingTable
                    batches={sellerBatch.batches}
                    handleOpenSteps={handleOpenSteps}
                    setOpenUndeliveredBatchPopup={setOpenUndeliveredBatchPopup}
                    handleOpenEditTaxEntryPopUp={handleOpenEditTaxEntryPopUp}
                    blitzData={blitzData}
                  />
                ) : (
                  <FinishedTable
                    batches={sellerBatch.batches}
                    handleNewBatch={handleNewBatch}
                    handlePrintTag={handlePrintTag}
                    handleOpenPrintPopup={handleOpenPrintPopup}
                    handleOpenNewBatchPopup={handleOpenNewBatchPopup}
                    handleOpenEditTaxEntryPopUp={handleOpenEditTaxEntryPopUp}
                  />
                )}
              </StyledAccordionDetails>
            </StyledAccordion>
          );
        })}
      </Paper>
    </AccordionContent>
  );
};
