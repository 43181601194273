import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class BatchWasteService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getBatchWaste(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/waste`;
    return await api.get(url, {
      params
    });
  }

  async deleteBatchWaste(batchWasteId) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/waste/${batchWasteId}`;
    return await api.delete(url);
  }
}

const batchWasteService = new BatchWasteService();
export default batchWasteService;
