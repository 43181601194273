const isValidCoordinates = (lat, lng) => {
  return (
    isValidDecimalPlaces(lat, lng) &&
    isWithinBrazil(lat, lng) &&
    isNumber(lat, lng)
  );
};

const isNumber = (lat, lng) => {
  return !isNaN(lat) && !isNaN(lng);
};

const isValidDecimalPlaces = (lat, lng) => {
  const latDecimalPlaces = (lat.toString().split(".")[1] || "").length;
  const lngDecimalPlaces = (lng.toString().split(".")[1] || "").length;

  return latDecimalPlaces >= 5 && lngDecimalPlaces >= 5;
};

const isWithinBrazil = (lat, lng) => {
  return lat >= -33.75 && lat <= 5.2718 && lng >= -73.9999 && lng <= -35.0;
};

export default isValidCoordinates;
