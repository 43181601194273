import { Button, Typography } from "@material-ui/core";

import {
  ButtonsContent,
  ConfirmContent,
  LabelConfirmDescription,
  LabelConfirmValue,
  StyledBox
} from "../../style";

export const ConfirmPage = ({
  classification,
  changeActivePage,
  handleSubmit
}) => {
  return (
    <StyledBox>
      <Typography>A classificação está correta?</Typography>
      <ConfirmContent
        style={{
          flexDirection: "row",
          justifyContent: "left",
          minHeight: "3.25rem",
          paddingInline: "1rem"
        }}
      >
        <LabelConfirmDescription>
          Classificação Selecionada:
        </LabelConfirmDescription>
        <LabelConfirmValue>{classification}</LabelConfirmValue>
      </ConfirmContent>
      <ButtonsContent>
        <Button
          variant="outlined"
          onClick={() => changeActivePage(1)}
          fullWidth
        >
          Voltar
        </Button>
        <Button variant="contained" onClick={handleSubmit} fullWidth>
          Confirmar
        </Button>
      </ButtonsContent>
    </StyledBox>
  );
};
