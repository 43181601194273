import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import storageFisicService from "services/storageFisicService";
import storageService from "services/storageService";
import { AutoCompleteProductBase, AutoCompleteSeller, Dialog } from "shared";
import { errorsConvertType, errorsRegisterMap } from "utils";
const removedReasons = [
  22, 21, 20, 19, 17, 16, 15, 14, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1
];

export const DialogCreateRegister = props => {
  const { handleConfirmType, isOpen, setIsOpen, refresh, reasons } = props;
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setBtn] = useState(true);
  const [storageForm, setStorageForm] = useState({
    productBaseId: "",
    quantity: "",
    storageType: "",
    storageReason: "",
    seller: null,
    description: ""
  });
  const [nameProduct, setNameProduct] = useState("");
  const [notImpossible, setNotImpossible] = useState(false);

  const onSubmitHandler = async e => {
    e.preventDefault();
    const payload = {
      productBaseId: storageForm.productBaseId,
      quantity: parseFloat(storageForm.quantity),
      storageType: storageForm.storageType,
      storageReason: storageForm.storageReason,
      seller: storageForm.seller
    };

    try {
      setIsLoading(true);
      const { data } = await storageService.sendStorage(payload);

      if (payload.storageReason === 13) {
        await storageFisicService.registerExplanation({
          storageId: data.storage.id,
          explanation: storageForm.description
        });
      }

      addPopup({
        type: "success",
        title: "Registro criado com sucesso."
      });

      setIsOpen(false);
      setStorageForm({
        productBaseId: "",
        quantity: "",
        storageType: "",
        storageReason: undefined,
        seller: "",
        description: ""
      });
      setNameProduct("");
      refresh();
    } catch (err) {
      if (err?.response?.status !== 401) {
        if (err?.response?.data?.alert) {
          if (errorsConvertType(err?.response?.data?.message)) {
            addPopup({
              type: "error",
              title: errorsConvertType(err?.response?.data?.message)
            });
            return;
          }

          if (
            err?.response?.data?.message === "Error! Convertion is impossible!"
          ) {
            setNotImpossible(true);
            return;
          }

          if (err?.response?.data?.message === null) {
            handleConfirmType({
              isOpen: true,
              data: payload,
              nameProduct,
              convert: err?.response?.data?.convert
            });

            return;
          }
        }

        addPopup({
          type: "error",
          title: errorsRegisterMap(err?.response?.data?.message)
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setStorageForm({
      productBaseId: "",
      quantity: "",
      storageType: "",
      storageReason: undefined,
      seller: "",
      description: ""
    });
    setIsOpen(false);
  };

  const handleCloseNotImpossible = () => {
    setNotImpossible(false);
  };

  const handleBaseProd = value => {
    setNameProduct(value?.name);
    setStorageForm({
      ...storageForm,
      productBaseId: parseInt(value?.id),
      storageType: value?.purchase_type
    });
  };

  const handleSeller = value => {
    setStorageForm({
      ...storageForm,
      seller: parseInt(value?.id)
    });
  };

  const handleReason = value => {
    setStorageForm({
      ...storageForm,
      storageReason: value
    });
  };

  const handleStorageType = value => {
    setStorageForm({
      ...storageForm,
      storageType: parseInt(value)
    });
  };

  const handleQty = value => {
    if (value === null) return;
    setStorageForm({
      ...storageForm,
      quantity: value
    });
  };

  const handleDescription = value => {
    if (value === null) return;
    setStorageForm({
      ...storageForm,
      description: value
    });
  };

  useEffect(() => {
    if (storageForm.storageReason === 13) {
      if (
        storageForm.description.length < 20 ||
        !storageForm.productBaseId ||
        storageForm.quantity === "" ||
        !storageForm.storageType
      ) {
        setBtn(true);
      } else {
        setBtn(false);
      }
    } else {
      !!storageForm.productBaseId &&
      storageForm.quantity !== "" &&
      !!storageForm.storageType &&
      storageForm.storageReason !== ""
        ? setBtn(false)
        : setBtn(true);
    }
  }, [storageForm]);

  return (
    <>
      <Dialog
        open={isOpen}
        handleClose={handleClose}
        title="Movimentação de estoque"
      >
        <Box component="form" onSubmit={onSubmitHandler} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <AutoCompleteProductBase
                onChange={(_, newInputValue) => {
                  handleBaseProd(newInputValue);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutoCompleteSeller
                onChange={(_, newInputValue) => {
                  handleSeller(newInputValue);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <InputLabel>Motivo</InputLabel>
                <Select
                  label="Motivo"
                  value={storageForm?.storageReason}
                  onChange={event => {
                    handleReason(event?.target?.value);
                  }}
                >
                  {reasons
                    .filter(({ id }) => !removedReasons.includes(id))
                    ?.map(reason => {
                      return (
                        <MenuItem key={reason} value={reason?.id}>
                          {reason.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <InputLabel>Tipo de Estoque</InputLabel>
                <Select
                  label="Tipo de Estoque"
                  value={storageForm?.storageType}
                  onChange={event => handleStorageType(event.target.value)}
                >
                  <MenuItem value={1}>Quilo (kg)</MenuItem>
                  <MenuItem value={2}>Unidade (un)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ width: "100%" }}
                onChange={event => {
                  handleQty(event.target.value);
                }}
                type="number"
                name="storageChange"
                required
                label="Movimentação"
                value={storageForm?.quantity}
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            {storageForm.storageReason === 13 && (
              <Grid item xs={12}>
                <TextField
                  value={storageForm.description}
                  onChange={event => {
                    handleDescription(event.target.value);
                  }}
                  style={{ width: "100%" }}
                  helperText={"Mínimo 20 caracteres"}
                  type="text"
                  label="Descrição *"
                  multiline
                  rows={2}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid container item justifyContent="flex-end">
              <Button
                disabled={isBtnDisabled}
                isLoading={isLoading}
                type="submit"
                variant="contained"
                size="large"
              >
                Registrar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        open={notImpossible}
        handleClose={handleCloseNotImpossible}
        title="Aviso"
      >
        <Typography>
          <strong>
            Esse produto não deve ser registrado com a medida{" "}
            {storageForm?.storageType === 2 ? "Unidade" : "Quilo"}, o ideal é
            {storageForm?.storageType === 2 ? " Quilo" : " Unidade"}. Não é
            possível realizar essa conversão pelo sistema. Procure o(a)
            responsável caso persista essa medida.
          </strong>
        </Typography>

        <Box display="flex" justifyContent="center" mt="1.4rem">
          <Button onClick={handleCloseNotImpossible} variant="contained">
            Ok
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
