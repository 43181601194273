import { addDays, startOfDay, isSameDay, isAfter } from "date-fns";

export const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return { date, time };
};

export const formatDateDeadline = (date, combinedDate) => {
  const newDate = startOfDay(new Date(date));
  const deadlineFormat = addDays(newDate, combinedDate);
  const today = startOfDay(new Date());
  const isToday = isSameDay(today, deadlineFormat);
  const passedTheDeadline = isAfter(today, deadlineFormat);

  return {
    deadlineFormat: deadlineFormat.toLocaleDateString(),
    deadlineFormatHour: formatDateTime(date).time,
    isToday,
    passedTheDeadline
  };
};

export const formatBatch = (batch, title = "") => {
  const data = {
    ...batch,
    receiptDateFormatted:
      batch.receiptDate && formatDateTime(batch.receiptDate).date,
    created: formatDateTime(batch.created).date
  };

  if (!["Alinhamento", "Abatimento", "Reposição", "Seleção"].includes(title)) {
    return data;
  }

  const date =
    {
      Alinhamento: data.receiptDate || data.created,
      Abatimento: data.updated,
      Reposição: data.updated,
      Seleção: data.receiptDate || data.created
    }[title] || data.updated;

  const combinedDate =
    {
      Alinhamento: data.deadlineForAnalysis || data.shelfLife,
      Reposição: data.combinedReturnDeadline,
      Seleção: data.deadlineForAnalysis
    }[title] || 1;

  const formattedDate = formatDateDeadline(date, combinedDate);

  return { ...data, ...formattedDate };
};

export const groupBatchData = data => {
  return data.reduce((batchs, batch) => {
    if (batchs[batch.sellerId]) {
      batchs[batch.sellerId] = [...batchs[batch.sellerId], batch];
      return batchs;
    }
    batchs[batch.sellerId] = [batch];
    return batchs;
  }, {});
};
