import { Box, Typography, styled } from "@material-ui/core";

export const InfoContainer = styled(Box)({
  padding: "1rem 1.25rem",
  background: "#F5F8F3",
  borderRadius: "1rem"
});

export const InfoText = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#757575",
  "& span": {
    fontWeight: 600
  },
  "& strong": {
    color: "#000000",
    fontSize: "1.125rem"
  },
  "div + &": {
    marginBottom: "0.5rem"
  }
});
