import Button from "./Button";
import Input from "./Input";
import Paper from "./Paper";
import Popover from "./Popover";
import Select from "./Select";

const Overrides = theme =>
  Object.assign(
    Input(theme),
    Button(theme),
    Select(theme),
    Popover(theme),
    Paper(theme)
  );

export default Overrides;
