import { Tab, Tabs } from "pages/private/Receivement/style";

import { Paper } from "./style";

export const TabReceipt = ({ activeTab, handleChangeTable }) => (
  <Paper square>
    <Tabs
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChangeTable}
      variant="fullWidth"
      style={{ marginBottom: "2rem" }}
    >
      <Tab label="A receber" />
      <Tab label="Finalizado" />
    </Tabs>
  </Paper>
);
