import { CircularProgress, TextField } from "@material-ui/core";
import { Button } from "components";

import {
  DialogBtnContent,
  DialogContent,
  DialogInputContent,
  LoadingContent,
  StyledDialog
} from "../../style";

export const DialogUpdate = ({
  open,
  handleClose,
  handleSave,
  stockTypeName,
  stockTypeAbbreviation,
  handleChangeStockType,
  handleChangeAbbreviation,
  loading
}) => {
  const btnDisabled = !stockTypeName || !stockTypeAbbreviation;
  const content = {
    true: (
      <LoadingContent>
        <CircularProgress color="primary" />
      </LoadingContent>
    ),
    false: (
      <DialogContent>
        <DialogInputContent>
          <TextField
            label="Nome do estoque"
            variant="outlined"
            placeholder="Ex.: Seco"
            fullWidth
            value={stockTypeName}
            onChange={handleChangeStockType}
          />
          <TextField
            label="Sigla"
            variant="outlined"
            placeholder="Ex.: SC"
            value={stockTypeAbbreviation}
            onChange={handleChangeAbbreviation}
            inputProps={{ maxLength: 2 }}
          />
        </DialogInputContent>
        <DialogBtnContent>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={btnDisabled}
          >
            Salvar
          </Button>
        </DialogBtnContent>
      </DialogContent>
    )
  }[loading];
  return (
    <StyledDialog
      open={open}
      handleClose={handleClose}
      title="Editar tipo de estocagem"
    >
      {content}
    </StyledDialog>
  );
};
