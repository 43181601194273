import jwt_decode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { api } from "services/api";
import authenticationService from "services/authenticationService";
import permissionsService from "services/permissionsService";

import { usePopup } from "./usePopup";
const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const cookies = new Cookies();
  const { addPopup } = usePopup();
  const history = useHistory();
  const [permissions, setPermissions] = useState(() => {
    const rulesCookies = cookies.get("rules");
    if (rulesCookies) return rulesCookies;

    return [];
  });
  const [user, setUser] = useState("");
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(() => {
    const token = cookies.get("access");
    if (token) return token;

    return false;
  });

  const signIn = async ({ username, password, rememberMe }) => {
    const response = await authenticationService.login(username, password);
    const { access, refresh } = response.data;
    api.defaults.headers.Authorization = `Bearer ${access}`;
    if (rememberMe) {
      const date = new Date();
      date.setHours(date.getHours() + 1);
      cookies.set("access", access, {
        expires: date
      });
    } else {
      cookies.set("access", access);
    }
    await getRules();
    setToken(access);
  };

  const signOut = () => {
    cookies.remove("access");
    cookies.remove("refresh");
    cookies.remove("rules");

    api.defaults.headers.Authorization = "";
    setPermissions([]);
    setToken(false);
    history.push("/login");
  };

  const getRules = async () => {
    try {
      const response = await permissionsService.getPermissions();
      const resPermissions = response?.data?.user_groups_names ?? [];
      setPermissions(resPermissions);
      setUser(response?.data?.user_login);
      cookies.set("rules", resPermissions);
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao procurar permissões"
      });
    }
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwt_decode(token);
        const currentDate = new Date();
        setUserId(decodedToken.user_id);

        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          signOut();
          addPopup({
            type: "info",
            title: "Login expirado"
          });
          return;
        }

        getRules();
      } catch (err) {
        signOut();
        addPopup({
          type: "info",
          title: "Login expirado"
        });
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ token, user, userId, permissions, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export { AuthProvider, useAuth };
