import { ContainerPage, TitlePage } from "components";
import { OptionsMenu } from "components/OptionsMenu";
import { useTableParams } from "hooks/useTableParams";
import React, { useRef } from "react";
import { MdModeEditOutline } from "react-icons/md";
import lockedStockService from "services/lockedStockService";
import { Table } from "shared";
import { mapType } from "utils";

import { Unlock } from "./Unlock";

export default function BlockedStock() {
  const unlockRef = useRef(null);
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => lockedStockService.getAll(params)
    });

  const options = [
    {
      title: "Desbloquear",
      icon: MdModeEditOutline,
      permissions: ["Estoque", "Estoque físico", "Tech", "Previsão"],
      action: data => unlockRef.current.handleData(data)
    }
  ];

  const columns = [
    {
      field: "product_base_id",
      label: "Id",
      align: "center",
      style: { minWidth: "120px" }
    },
    {
      field: "product_base_name",
      label: "Produto",
      style: { minWidth: "240px" }
    },
    {
      field: "locked_stock",
      label: "Qty bloqueada",
      align: "center",
      style: { minWidth: "170px" },
      formatter: value => {
        if (!value) return 0;
        return new Intl.NumberFormat("pt-BR").format((value * -1).toFixed(2));
      }
    },
    {
      field: "input_type_id",
      label: "Tipo de entrada",
      align: "center",
      style: { minWidth: "180px" },
      formatter: value => mapType(value)
    },
    {
      field: "delete",
      label: "",
      align: "right",
      style: { minWidth: "150px" },
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  return (
    <>
      <ContainerPage>
        <TitlePage>Estoque bloqueado</TitlePage>

        <Table
          handleSearch={handleSearch}
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          size="small"
        />
      </ContainerPage>

      <Unlock refresh={refresh} ref={unlockRef} />
    </>
  );
}
