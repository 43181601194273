import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  TextField
} from "@material-ui/core";
import { GridCloseIcon, composeClasses } from "@material-ui/data-grid";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useRef, useState } from "react";
import { BiBarcodeReader } from "react-icons/bi";
import { FiCamera } from "react-icons/fi";
import batchService from "services/batchService";

import { QRCode, useStyles } from "./styles";

const Reader = ({ processQRCodeData }) => {
  const inputRef = useRef(null);

  const onSubmit = e => {
    e.preventDefault();
    processQRCodeData(inputRef.current.value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      inputRef.current?.focus();
    }, 300);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <input style={{ opacity: 0 }} ref={inputRef} />
      <h1 style={{ textAlign: "center" }}>
        Para prosseguir, escaneie o código com o leitor.
      </h1>
    </form>
  );
};

export const ModalScanner = ({
  onClose,
  isOpen,
  processQRCodeData,
  onBack,
  onNext
}) => {
  const { addPopup } = usePopup();
  const classes = useStyles();
  const [view, setView] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const handleClose = () => {
    processQRCodeData({});
    onBack();
  };

  const validateBatchId = async batchId => {
    setIsFetching(true);
    try {
      const { data } = await batchService.getBatchDetail(batchId);
      return data;
    } catch (error) {
      const response = error?.response;
      if (response && response?.status === 404) {
        addPopup({
          type: "error",
          title: "Lote inválido.",
          description: `Erro ao consultar lote: "${batchId}". Erro: ${response?.data?.msg}`
        });
        return;
      }
      addPopup({
        type: "error",
        title: "Erro ao consultar lote.",
        description: `Lote de ID: "${batchId}" não encontrado. Erro: ${error}`
      });
    } finally {
      setIsFetching(false);
    }
  };

  const onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      onScanSuccess(e.target.value);
    }
  };

  const onScanSuccess = async QRCode => {
    const batchData = await validateBatchId(QRCode);
    if (!batchData) {
      return;
    }
    processQRCodeData(batchData);
    onNext();
  };

  const children = {
    0: (
      <QRCode onScanSuccess={onScanSuccess} videoStyle={{ height: "20rem" }} />
    ),
    1: <Reader processQRCodeData={onScanSuccess} />
  }[view];
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={isOpen}
      handleClose={handleClose}
      onClose={handleClose}
      title="Scanear QRCode"
    >
      <Box>
        <Box
          maxWidth="15rem"
          margin="10px auto"
          display="flex"
          style={{ gap: "1rem", padding: "10px" }}
        >
          <Button
            variant="contained"
            fullWidth
            disabled={view === 0}
            onClick={() => setView(0)}
            endIcon={<FiCamera />}
          >
            Câmera
          </Button>
          <Button
            variant="contained"
            fullWidth
            disabled={view === 1}
            onClick={() => setView(1)}
            endIcon={<BiBarcodeReader />}
          >
            Leitor
          </Button>
          <IconButton
            aria-label="Fechar"
            color="inherit"
            onClick={handleClose}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <GridCloseIcon />
          </IconButton>
        </Box>
        {view === 0 && (
          <Box className={classes.inputBatchBox}>
            <TextField
              style={{ width: "100%" }}
              type="number"
              title="Insira o lote"
              onKeyDown={onKeyDownHandler}
              variant="outlined"
              label="Digite o lote e pressione enter"
            />
          </Box>
        )}
        <Box height="22rem">
          {isFetching && (
            <CircularProgress
              style={{ margin: "auto", display: "flex", height: "90%" }}
              color="primary"
            />
          )}
          {!isFetching && children}
        </Box>
      </Box>
    </Dialog>
  );
};
