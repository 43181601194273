const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return {
    date,
    time
  };
};

const formatDate = date => {
  const dateFormatted = date && new Date(date).toLocaleString();
  return dateFormatted;
};

const formatStorageData = storageData => {
  return (storageData || [])
    ?.map(storage => {
      const quantity = Intl.NumberFormat("pt-BR").format(
        storage.summary.toFixed(2)
      );
      const purchaseType = {
        1: "Kg",
        2: "Un"
      }[storage.purchase_type];

      return {
        ...storage,
        quantity: `${quantity} ${purchaseType}`
      };
    })
    .sort((a, b) => a.summary - b.summary);
};

const formatBatchData = batchData => {
  const batches = (batchData || []).sort(
    (a, b) => a.days_to_dude - b.days_to_dude
  );

  const dueBatches = batches.reduce((acc, batch) => {
    return batch.days_to_dude < 0 ? (acc += 1) : acc;
  }, 0);

  const batchesToDue = batches.length - dueBatches;
  return { batches, dueBatches, batchesToDue };
};

const formatSellerPendingData = data => {
  const result = data?.reduce(
    (acc, seller) => {
      const hasReceivable = seller.batches.some(batch => !batch.blitz_step);
      const hasPending = seller.batches.some(batch => batch.blitz_step > 0);

      hasReceivable && acc.suppliersReceivable++;
      hasPending && acc.suppliersPending++;

      return acc;
    },
    { suppliersReceivable: 0, suppliersPending: 0 }
  );

  return result || { suppliersReceivable: 0, suppliersPending: 0 };
};

const formatSellerReceiptData = data => {
  const finalizedSuppliers = data.length ?? 0;

  return { finalizedSuppliers };
};

const formatConfirmPendingData = data => {
  const unconfirmed = data.length ?? 0;

  return { unconfirmed };
};

const formatBatchBreakageData = data => {
  const batches = data.batches;
  const complaints = data.complaints;
  const batchBreakageQty = batches.length ?? 0;
  const batchData = (batches || [])
    .map(batch => {
      return {
        id: batch.id,
        classification: batch.classification,
        productBaseName: batch.product?.name,
        productBaseShelfLife: batch.product?.shelf_life,
        receiptDate: formatDateTime(batch.receipt_date).date,
        receiptTime: formatDateTime(batch.receipt_date).time,
        complaintReasons: batch.complaint_reasons
      };
    })
    .sort((a, b) => a.productBaseName.localeCompare(b.productBaseName));

  const complaintsData = (complaints || []).flatMap(complaint => {
    const complaintItems = (complaint.items || []).map(item => {
      const receiptDates = (item.batches || []).map(batch => {
        return new Date(batch?.created) > new Date(batch?.receipt_date)
          ? formatDate(batch?.created)
          : formatDate(batch?.receipt_date);
      });
      const receiptDatesString = receiptDates && receiptDates.join(" | ");

      const batchIds = (item.batches || []).map(batch => batch?.id);
      const batchIdsString = batchIds && batchIds.join(" | ");

      const batchClassifications = (item.batches || []).map(
        batch => batch?.classification
      );
      const batchClassificationsString =
        batchClassifications && batchClassifications.join(" | ");

      const formatPrePickingDate = prePickingDates => {
        if (prePickingDates) {
          const dates = prePickingDates.split("/");
          const formatted = dates.map(date => formatDate(date));
          return formatted.join(" | ");
        }
      };

      const itemComplaint = {
        orderId: complaint.order?.id,
        scheduleDate: formatDateTime(complaint.order?.scheduled_date).date,
        skuName: item.product?.name,
        reasonName: item.reason?.name,
        originalQuantity: item.quantity_original,
        complaintQuantity: item.quantity_complaint,
        ItemObservation: item.observation,
        itemImage: item.item_image,
        batchId: batchIdsString,
        batchClassification: batchClassificationsString,
        batchReceiptDate: receiptDatesString,
        prePickingCreated: formatPrePickingDate(item.prePickingCreated),
        shelfLife: item.batches?.[0]?.product?.shelf_life
      };
      return itemComplaint;
    });
    return complaintItems;
  });

  return { batchBreakageQty, batchData, complaintsData };
};

export const formatData = {
  formatStorageData,
  formatBatchData,
  formatSellerPendingData,
  formatSellerReceiptData,
  formatConfirmPendingData,
  formatBatchBreakageData
};
