import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useRef
} from "react";
import depotService from "services/depotService";
import { InfoCard } from "shared";
import {
  packagingProductBaseConfigFormatter,
  warehouseProductBaseConfigFormatter
} from "utils/depotDataFormatter";

import { Packaging } from "../Packaging";
import { Dialog, Info } from "./style";

const Component = ({ refresh }, ref) => {
  const packagingRef = useRef(null);
  const { addPopup } = usePopup();
  const [isOpen, setIsOpen] = useState(false);
  const [batch, setBatch] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [depots, setDepots] = useState([]);
  const [depotId, setDepotId] = useState(null);
  const [warehouseId, setWarehouseId] = useState(null);
  const [packagesData, setPackagesData] = useState([]);

  const disableSubmit = !depotId || !warehouseId;

  const clearState = () => {
    setWarehouses([]);
    setDepots([]);
    setDepotId(null);
    setBatch({});
    setWarehouseId(null);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const getProductBaseWarehouseType = async () => {
    setIsLoadingData(true);
    try {
      const { data } = await depotService.getWarehouseProductBaseConfig({
        productBaseId: batch.productBaseId,
        paginate: false
      });

      const formattedData = warehouseProductBaseConfigFormatter(data);

      setWarehouses(formattedData);
      setWarehouseId(formattedData[0]?.id);
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao buscar dados de armazenamento",
        description: err?.response?.msg ?? err?.message ?? err
      });
      handleClose();
    }
    setIsLoadingData(false);
  };

  const getDepotsAndPackaging = async () => {
    setIsLoadingData(true);
    try {
      const [depotsResp, packagingResp] = await Promise.all([
        depotService.listDepots({
          warehouseTypeId: warehouseId,
          paginate: false
        }),
        depotService.getPackagingProductBaseConfig({
          productBaseId: batch.productBaseId,
          paginate: false
        })
      ]);
      setDepots(depotsResp.data);
      setPackagesData(packagingProductBaseConfigFormatter(packagingResp.data));
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao buscar dados de depósitos",
        description: err?.response?.msg ?? err?.message ?? err
      });
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleOpen = batch => {
    setBatch(batch);
    setIsOpen(true);
  };

  const onSubmit = async e => {
    e.preventDefault();
    const packagesType = packagingRef.current?.options;
    const hasInvalidPackaging = packagesType.some(
      ({ quantity, packagingTypeId }) => !quantity || !packagingTypeId
    );

    if (hasInvalidPackaging) {
      addPopup({
        type: "info",
        title: "Tipo de embalagem",
        description:
          "Informe o tipo de embalagem e a quantidade ou exclua o que não estiver utilizando"
      });
      return;
    }
    setIsLoading(true);
    const payload = packagesType.reduce((acc, packaging) => {
      return [
        ...acc,
        {
          batchId: batch.id,
          depotId,
          warehouseTypeId: warehouseId,
          quantity: packaging.quantity,
          reasonId: 1,
          productBaseId: batch.productBaseId,
          packagingTypeId: packaging.packagingTypeId
        }
      ];
    }, []);
    try {
      await depotService.createLedger(payload);
      refresh();
      addPopup({
        type: "success",
        title: "Lote armazenado com sucesso"
      });
      handleClose();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao registrar armazenamento",
        description: err?.response?.msg ?? err?.message ?? err
      });
    }
    setIsLoading(false);
  };

  useImperativeHandle(ref, () => ({ handleOpen }));

  useEffect(() => {
    !!warehouseId && getDepotsAndPackaging();
  }, [warehouseId]);

  useEffect(() => {
    !!isOpen && !!batch.id && getProductBaseWarehouseType();
    !isOpen && clearState();
  }, [isOpen]);

  const children = {
    false: !warehouses.length ? (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          textAlign: "center"
        }}
      >
        <Typography style={{ color: "#D84315" }}>
          Não existe armazém configurado para esse produto base
        </Typography>
      </Box>
    ) : (
      <>
        <InfoCard
          data={{
            Produto: { text: batch?.productBaseName, useStrong: true },
            Seller: { text: batch?.seller },
            Porção: { text: batch.purchaseType }
          }}
          marginBottom="1rem"
        />

        <Info>
          <Typography>
            <span style={{ color: "#757575" }}>Armazenar em:</span>{" "}
            {warehouses
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map(warehouse => warehouse.name)
              .join(", ")}
          </Typography>
        </Info>

        <Box
          mt="2rem"
          display="flex"
          flexDirection="column"
          width="100%"
          style={{ gap: "1rem" }}
        >
          <Typography style={{ color: "#757575", fontWeight: 500 }}>
            Em qual armazém o lote foi armazenado ?
          </Typography>

          <TextField
            select
            variant="outlined"
            fullWidth
            name="warehouseType"
            label="Armazém"
            value={warehouseId}
            onChange={e => setWarehouseId(e.target.value)}
            disabled={warehouses.length <= 0}
          >
            {warehouses
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map(warehouse => (
                <MenuItem key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
          </TextField>
        </Box>

        <Box
          component="form"
          noValidate
          onSubmit={onSubmit}
          height="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexDirection="column"
        >
          <Box
            mt="2rem"
            display="flex"
            flexDirection="column"
            width="100%"
            style={{ gap: "1rem" }}
          >
            <Typography style={{ color: "#757575", fontWeight: 500 }}>
              Em qual depósito o lote foi armazenado?
            </Typography>

            <TextField
              select
              variant="outlined"
              fullWidth
              name="depotId"
              label="Depósito"
              onChange={e => setDepotId(e.target.value)}
              disabled={depots?.length <= 0}
            >
              {depots?.map(depotId => (
                <MenuItem key={depotId.id} value={depotId.id}>
                  {depotId.abbreviation}
                </MenuItem>
              ))}
            </TextField>
            {depots?.length <= 0 && (
              <Typography style={{ color: "#D84315" }}>
                Não existe depósito vinculado a este armazém
              </Typography>
            )}
            <Typography style={{ color: "#757575", fontWeight: 500 }}>
              Quantas embalagens o seller trouxe ?
            </Typography>
            <Packaging packagesData={packagesData} ref={packagingRef} />
          </Box>

          <Box style={{ paddingBlock: "1rem" }}>
            <Button
              variant="contained"
              type="submit"
              style={{ padding: "0.8rem 4rem" }}
              disabled={disableSubmit}
              isLoading={isLoading}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </>
    ),
    true: (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    )
  }[String(isLoadingData)];

  return (
    <Dialog open={isOpen} handleClose={handleClose} title="Armazenar lote">
      <Box height="calc(100% - 92px)" display="flex" flexDirection="column">
        {children}
      </Box>
    </Dialog>
  );
};

export const Stored = forwardRef(Component);
