export const errorsSku = value => {
  const text =
    {
      "Product base not found!": "Produto base não encontrado",
      "Product already exists": "Esse produto já existe",
      "Error! The name of the product is required.":
        "O nome do produto é obrigatório",
      "Error! The unit type of the product is required.":
        "O tipo de unidade do produto é obrigatório",
      "Error! The product base is required.": "O produto base é obrigatório",
      "Error! The weigth is required.": "O peso é obrigatório",
      "Error! The category of the product is required.":
        "A categoria do produto é obrigatória",
      "Error! The sub-category of the product base is required.":
        "A subcategoria do produto é obrigatória",
      "Error! The status of the product base is required.":
        "O status do produto é obrigatório",
      "Error! The separation order of the product base is required.":
        "A ordem de separação do produto é obrigatória",
      "Error! The extra order of the product base is required.":
        "A quantidade extra do produto é obrigatório",
      "Product not found.": "Produto não encontrado",
      "Product still has stock": "Produto ainda tem em estoque",
      "Sku not found": "Sku não encontrado",
      "SKU quantity not found.": "Quantidade de SKU não encontrada",
      "Product not found": "Produto não encontrado",
      "Product not deleted as there is still stock":
        "O produto não foi excluído porque ainda há em estoque",
      "Error! Name already in use": "Atualmente esse nome já está em uso"
    }[value] || "Ocorreu um erro, contate o administrador.";
  return text;
};
