import { forwardRef, useImperativeHandle, useState } from "react";
import { Drawer } from "shared";

import { Form } from "../Form";

const Component = ({ refresh }, ref) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setData(null);
  };

  const handleOpen = data => {
    setData(data);
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({ handleOpen }));

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      title={data ? "Editar armazém" : "Criar armazém"}
      maxWidth={"34.9375rem"}
      height={"100%"}
    >
      <Form initialValues={data} refresh={refresh} handleClose={handleClose} />
    </Drawer>
  );
};

export const WarehouseTypeCreateUpdate = forwardRef(Component);
