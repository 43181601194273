export const mapPixKeyType = value => {
  const pixType =
    {
      cpf: "CPF",
      cnpj: "CNPJ",
      email: "E-mail",
      phone: "Celular",
      random: "Chave aleatória"
    }[value] || value;

  return pixType;
};
