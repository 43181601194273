import {
  Box,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled
} from "@material-ui/core";
import { Search } from "shared/Search";

export const MainContent = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
});

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingBlock: "1rem",

  [theme.breakpoints.down("sm")]: {
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export const RefreshBtnContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "2.5rem",
  [theme.breakpoints.between(0, 700)]: {
    marginRight: "1rem"
  }
}));

export const SearchInput = styled(Search)(({ theme }) => ({
  width: "100%",
  maxWidth: "21.5rem",
  height: "3.5rem",
  marginLeft: "2.5rem",
  [theme.breakpoints.between(0, 700)]: {
    marginLeft: "1rem"
  }
}));

export const TableContent = styled(TableContainer)({
  display: "flex",
  flexDirection: "column",
  height: "32rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "12px",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "9px"
  }
});

export const StyledTableHead = styled(TableHead)({
  background: "#F3F8E6"
});

export const HeadCell = styled(TableCell)(({ align, width }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem",
  backgroundColor: "#F3F8E6",
  textAlign: align || "left",
  width: width || "auto"
}));

export const BodyRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const BodyCell = styled(TableCell)(({ align, width }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem",
  textAlign: align || "left",
  width: width || "auto"
}));

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});
