import { IconButton } from "@material-ui/core";
import { MdModeEditOutline } from "react-icons/md";

export const tableColumns = handleEdit => {
  return [
    {
      field: "id",
      label: "ID",
      align: "left",
      sorted: false,
      disabledSort: true
    },
    {
      field: "name",
      label: "Tipo de embalagem",
      align: "left",
      style: { minWidth: "120px" },
      disabledSort: true
    },
    {
      field: "quantity",
      label: "Capacidade",
      align: "left",
      style: { minWidth: "40x" },
      disabledSort: true
    },
    {
      field: "typeOfMeasure",
      label: "Unidade de medida",
      align: "left",
      style: { minWidth: "40px" },
      disabledSort: true
    },
    {
      field: "size",
      label: "Tamanho",
      align: "left",
      style: { minWidth: "120px" },
      disabledSort: true
    },
    {
      field: "button",
      label: "",
      align: "center",
      style: { minWidth: "140px" },
      disabledSort: true,
      renderCell: ({ data }) => (
        <IconButton onClick={() => handleEdit(data)}>
          <MdModeEditOutline />
        </IconButton>
      )
    }
  ];
};
