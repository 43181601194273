import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { useState } from "react";

import { EntryTable } from "./EntryTable";
import { RefusalTable } from "./RefusalTable";
import { TabConfirmBatch } from "./TabConfirmBatch";

const ConfirmBatch = () => {
  const [page, setPage] = useState(0);

  const children = {
    0: <EntryTable />,
    1: <RefusalTable />
  }[page];

  return (
    <ContainerPage>
      <Box>
        <TitlePage>Confirmação de lotes</TitlePage>
      </Box>
      <TabConfirmBatch activeTab={page} handleChangeTable={setPage} />
      {children}
    </ContainerPage>
  );
};

export default ConfirmBatch;
