import { Box, Paper, Table, TableBody } from "@material-ui/core";
import { CustomLineProgress } from "components/LineProgress";

import {
  BodyCell,
  BodyRow,
  ConfirmButton,
  HeadCell,
  StyledTableHead,
  TableContent
} from "../../style";

export const BatchTable = ({
  batchData,
  loading,
  isRefusal,
  handleOpenConfirm
}) => {
  return (
    <Box>
      {!!loading && <CustomLineProgress />}
      <TableContent component={Paper}>
        <Table stickyHeader>
          <StyledTableHead>
            <HeadCell>ID</HeadCell>
            <HeadCell>Produto</HeadCell>
            <HeadCell>Quantidade</HeadCell>
            <HeadCell>Seller</HeadCell>
            <HeadCell>Data recebimento</HeadCell>
            <HeadCell>Atualizado por</HeadCell>
            <HeadCell>Estágio</HeadCell>
            {isRefusal && (
              <>
                <HeadCell>Motivo</HeadCell>
                <HeadCell>Obs</HeadCell>
              </>
            )}
            <HeadCell></HeadCell>
          </StyledTableHead>
          <TableBody>
            {batchData?.map(batch => {
              return (
                <BodyRow key={batch.id}>
                  <BodyCell>{batch.id}</BodyCell>
                  <BodyCell>{batch.product}</BodyCell>
                  <BodyCell>{batch.quantity}</BodyCell>
                  <BodyCell>{batch.seller}</BodyCell>
                  <BodyCell>{batch.receiptDate}</BodyCell>
                  <BodyCell>{batch.updatedBy}</BodyCell>
                  <BodyCell>{batch.stage}</BodyCell>
                  {isRefusal && (
                    <>
                      <BodyCell>{batch.reasonName}</BodyCell>
                      <BodyCell>{batch.reasonQuantity}</BodyCell>
                    </>
                  )}
                  <BodyCell>
                    <ConfirmButton onClick={() => handleOpenConfirm(batch.id)}>
                      {isRefusal ? "Alterar" : "Confirmar"}
                    </ConfirmButton>
                  </BodyCell>
                </BodyRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContent>
    </Box>
  );
};
