import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    "& .MuiDialog-paper": {
      margin: 0
    }
  },
  paper: {
    padding: 15,
    width: "100%"
  },
  box: {
    margin: "10px 0 15px"
  },
  title: {
    fontSize: "clamp(1.1rem, 2.5vw, 1.4rem)",
    fontWeight: "600"
  },
  boxBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  buttonConfirm: {
    width: "100%",
    minWidth: "100px",
    maxWidth: "110px",
    backgroundColor: "#d46e67",
    color: "white",
    transition: "background 0.4s",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#d05a52",
      boxShadow: "none"
    }
  },
  buttonCancel: {
    width: "100%",
    minWidth: "100px",
    maxWidth: "110px",
    backgroundColor: "#88BC23",
    color: "white",
    transition: "background 0.4s",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#7da927",
      boxShadow: "none"
    }
  }
});
