import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  boxAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.between("0", "800")]: {
      flexDirection: "column",
      alignItems: "center"
    }
  },
  boxForm: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.7rem",
    flexDirection: "column",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 3px rgba(160, 160, 160, 0.14), 0px 3px 4px rgba(160, 160, 160, 0.12), 0px 1px 8px rgba(160, 160, 160, 0.2)",
    borderRadius: "0.625rem",
    width: "24.5rem",
    minHeight: "30rem",
    height: "auto",
    marginLeft: "2rem"
  },
  boxFormTitle: {
    marginTop: "2rem",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1.1rem",
    lineHeight: "1.3rem",
    color: "#353535"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    margin: "0 auto"
  },
  formGroup: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    margin: "1rem"
  },
  title: {
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 400,
    fontsize: "1rem",
    lineheight: "1rem",
    color: "#353535",
    "& > span": {
      display: "flex",
      flexDirection: "column  "
    }
  },
  formControlLabel: {
    marginBottom: "0.7rem"
  }
}));
