import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class StorageService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async migrationStorage(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/migration`;
    return await api.put(url, data);
  }

  async getStorageData(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        results: data.results ? data.results : data,
        total_count:
          data?.totalCount !== undefined ? data.totalCount : data.length
      }
    };
  }

  async getPendingData(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        results: data.results,
        total_count: data.totalCount
      }
    };
  }

  async confirmStorageReceived(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/confirm`;
    return await api.post(url, data);
  }

  async sendStorage(storageForm) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage`;
    return await api.post(url, storageForm);
  }

  async getBaseProducts(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/`;
    return await api.get(url, { params });
  }

  async registerUpdate(model) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/${model.id}`;
    return await api.put(url, {
      quantity: model.quantity
    });
  }

  uploadFile() {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/upload-repos/estoque.xlsx`;
    return { api, url };
  }

  async registerDelete(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/${id}`;
    return await api.delete(url);
  }

  async getReason() {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage-reason`;
    return await api.get(url);
  }
}

const storageService = new StorageService();
export default storageService;
