import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import depotService from "services/depotService";

import { StyledDrawer } from "../../styles";
import { PackagingForm } from "../Form";

export const ModalCreate = ({ open, handleClose, refresh }) => {
  const { addPopup } = usePopup();

  const [loading, setLoading] = useState(false);
  const submit = async formData => {
    try {
      setLoading(true);
      await depotService.postPackagingType(formData);
      addPopup({
        type: "success",
        title: "Embalagem criada com sucesso."
      });
      handleClose();
      refresh();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao criar embalagem",
        description: error.response?.data?.msg ?? ""
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <StyledDrawer
      anchor="right"
      open={open}
      onClose={handleClose}
      title="Editar embalagem"
    >
      <PackagingForm onsubmit={submit} loading={loading} />
    </StyledDrawer>
  );
};
