import { Box, CircularProgress } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useEffect, useMemo, useRef, useState } from "react";
import productBaseService from "services/productBaseService";
import skuService from "services/skuService";
import { Drawer } from "shared";
import { errorsSku } from "utils";

import { Form } from "../Form";

const getType = type => ({ unidade: 2, peso: 1 }[type] || null);
const getStatus = status => ({ active: 1, suspended: 2 }[status] || 3);

const formatInitialValues = data => ({
  ...data,
  portionType: getType(data.portionType),
  status: getStatus(data.status),
  portionName: data.portionName,
  category: data?.productBaseId?.category,
  subCategory: data?.productBaseId?.subCategory,
  productBaseId: data?.productBaseId?.name,
  base64: data?.image
});

export const Edit = ({ isOpen, setIsOpen, refresh, data }) => {
  const formRef = useRef(null);
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(true);
  const [productBaseId, setProductBaseId] = useState();
  const initialValues = useMemo(() => {
    return formatInitialValues({ ...data, productBaseId });
  }, [data, productBaseId]);

  const handleClose = () => {
    setIsOpen({ isOpen: false, data: {} });
    formRef.current?.resetForm();
  };

  const getProductBase = async id => {
    try {
      const { data } = await productBaseService.getProductBaseId(id);
      setProductBaseId(data);
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao buscar Produto Base do Sku"
        });
        handleClose();
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async dataForm => {
    try {
      const {
        productBaseId: productBase,
        purchaseValue,
        subCategory,
        category,
        ...rest
      } = dataForm;

      await skuService.updateSku({
        ...rest,
        portionDescription: dataForm.portionDescription?.trim(),
        isPreboxed: dataForm?.preBoxed,
        base64: dataForm.base64 !== data.image ? dataForm.base64 : undefined
      });

      addPopup({
        type: "success",
        title: "SKU editado com sucesso"
      });
      refresh();
      handleClose();
    } catch (err) {
      if (err.response.status !== 401) {
        addPopup({
          type: "error",
          title: errorsSku(err.response?.data?.message)
        });
      }
    }
  };

  useEffect(() => {
    if (data.productBaseId) getProductBase(data.productBaseId);
  }, [data.productBaseId, data.id]);

  return (
    <Drawer
      anchor="right"
      title="Editar Sku"
      open={isOpen}
      onClose={handleClose}
    >
      {loading ? (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Form
          ref={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          productBaseId={productBaseId}
        />
      )}
    </Drawer>
  );
};
