import { Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";

import {
  ChipButtonFilter,
  ChipMenuItem,
  FilterContent,
  FilterLabel
} from "../../style";
import { mapStatusFilter } from "../../utils/mapStatusFilter";

export const StatusFilter = ({ handleFilter, statusId, stageId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (statusId, stageId) => {
    handleFilter(Number(statusId), Number(stageId));
    handleClose();
  };
  return (
    <FilterContent>
      <FilterLabel>Filtrar:</FilterLabel>

      <ChipButtonFilter
        aria-controls="filter-menu"
        aria-haspopup="true"
        onClick={handleClick}
        label={mapStatusFilter(statusId, stageId)}
        onDelete={handleClick}
        deleteIcon={<ExpandMoreIcon />}
        statusId={statusId}
      />

      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelect(0)}>
          <ChipMenuItem label="Todos" />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(7)}>
          <ChipMenuItem label="A devolver" statusId={7} />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(8)}>
          <ChipMenuItem label="Apagados" statusId={8} />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(6, 15)}>
          <ChipMenuItem label="Waste devolução" statusId={6} />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(6)}>
          <ChipMenuItem label="Todos waste" statusId={6} />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(3, 13)}>
          <ChipMenuItem label="Reposição" statusId={3} />
        </MenuItem>
        <MenuItem onClick={() => handleSelect(3, 12)}>
          <ChipMenuItem label="NF/Reembolso" statusId={3} />
        </MenuItem>
      </Menu>
    </FilterContent>
  );
};
