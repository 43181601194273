import { TitlePage, ContainerPage } from "components";

export default function Default() {
  return (
    <ContainerPage>
      <TitlePage>Quadro de avisos</TitlePage>
      <p
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translateX(-50%)",
          color: "#88BC23",
          fontWeight: "bold",
          fontSize: "clamp(0.75rem, 2.5vw, 1.2rem)"
        }}
      >
        Nenhum aviso encontrado
      </p>
    </ContainerPage>
  );
}
