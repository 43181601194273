import { Box, Paper, Table, TableBody } from "@material-ui/core";
import { CustomLineProgress } from "components/LineProgress";

import {
  BodyCell,
  BodyRow,
  HeadCell,
  StyledTableHead,
  TableContent
} from "../../styles";

export const StorageTable = ({ storageOutOfStock, loading }) => {
  return (
    <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      {!!loading && <CustomLineProgress />}
      <TableContent component={Paper}>
        <Table stickyHeader>
          <StyledTableHead>
            <HeadCell>ID</HeadCell>
            <HeadCell>Produto</HeadCell>
            <HeadCell>Quantidade em estoque</HeadCell>
          </StyledTableHead>
          <TableBody>
            {storageOutOfStock?.map(storage => {
              return (
                <BodyRow key={storage.product_base_id}>
                  <BodyCell>{storage.product_base_id}</BodyCell>
                  <BodyCell style={{ fontWeight: 500, fontSize: "1rem" }}>
                    {storage.product_base_name}
                  </BodyCell>
                  <BodyCell style={{ color: storage.summary < 0 && "#EB001B" }}>
                    {storage.quantity}
                  </BodyCell>
                </BodyRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContent>
    </Box>
  );
};
