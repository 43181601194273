import { LOCATION_API_URL, MENU_API_URL } from "settings";

import { api } from "./api";

const url = LOCATION_API_URL;

const menu_api_url = MENU_API_URL;

class NewLocationService {
  async getCoordinatesByCep(cep) {
    try {
      const response = await api.get(`${url}/searchByPostalCode/${cep}`);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async searchLocation(query) {
    try {
      const response = await api.get(`${menu_api_url}/search?query=${query}`);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async validateZipCode(zipCode) {
    const response = await api.post(`${url}/validateZipCode`, { zipCode });
    return response.data;
  }

  async checkIfDelivery(postalCode) {
    const response = await api.get(`${url}/${postalCode}`);
    return response.data;
  }

  async checkLatLng(cords) {
    const response = await api.get(`${url}/area/${cords.lat}/${cords.lng}`);
    return response.data;
  }

  async getDeliveryWeek() {
    const response = await api.get(`${url}/area/deliveryWeekDay`);
    return response.data;
  }

  async getCepInfo(cep) {
    const response = await api.get(`${url}/cep/${cep}`);
    return response.data;
  }
}

const newLocationService = new NewLocationService();
export default newLocationService;
