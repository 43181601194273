import {
  Accordion,
  AccordionDetails,
  Box,
  Paper,
  TableCell,
  Typography,
  InputLabel,
  styled
} from "@material-ui/core";
import { ButtonLink as ButtonLinkStyled } from "components/ButtonLink";
import { SearchContent } from "components/Contents";
import { Dialog } from "shared";

export const AccordionContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingBlock: "0.5rem"
}));

export const StyledAccordion = styled(Accordion)({
  margin: 0,
  padding: 0,
  "& .MuiAccordionSummary-root": {
    paddingBlock: "1rem"
  },
  "&:nth-of-type(even)": {
    "& .MuiAccordionSummary-root": {
      backgroundColor: "#F5F6F7"
    }
  },

  "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded ": {
    backgroundColor: "#E5ECD1"
  },
  "& .MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd ": {
    backgroundColor: "#E5ECD1"
  },
  "&.MuiAccordion-root.Mui-expanded": {
    margin: 0,
    padding: 0
  }
});

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});

export const BatchTitleContainer = styled(Box)({
  display: "flex",
  flex: 1,
  gap: "1rem"
});

export const BatchTitleContent = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "0.5rem"
});

export const BatchTitleDescription = styled(Typography)(({ theme }) => ({
  color: "#757575",
  fontSize: "1rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem"
  }
}));

export const BatchTitleValue = styled(Typography)(({ theme }) => ({
  color: "#353535",
  fontSize: "1rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem"
  }
}));

export const StyledAccordionDetails = styled(AccordionDetails)({
  overflowY: "auto"
});

export const HeadCell = styled(TableCell)({
  fontSize: "0.875rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#757575",
  backgroundColor: "#F9FCF4"
});

export const BodyCell = styled(TableCell)({
  fontSize: "0.875rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#353535"
});
export const AccordionDetailContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "1rem"
});

export const BatchInformationContent = styled(Paper)({
  display: "flex",
  flex: 1,
  gap: "0.5rem",
  padding: "1rem",
  borderRadius: "0.5rem"
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  maxWidth: "35rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": {
    maxHeight: "90vh"
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      maxHeight: "95vh",
      width: "95vw",
      padding: 0,
      margin: 0
    }
  }
}));

export const PopupContent = styled(Box)({
  display: "flex",
  position: "relative",
  flex: 1,
  flexDirection: "column",
  gap: "0.5rem"
});

export const ProductInfo = styled(Box)({
  display: "flex",
  width: "100%",
  minHeight: "7.5rem",
  textAlign: "left",
  padding: "0.5rem",
  marginBottom: "1rem",
  background: "#F5F8F3",
  borderRadius: "0.5rem",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "0.5rem"
});

export const Image = styled("img")({
  width: "100px",
  height: "100px"
});

export const ProductText = styled(Typography)({
  marginTop: "0.4rem",
  marginLeft: "1.5rem",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#757575",
  "& span": {
    fontWeight: 600
  },
  "& strong": {
    color: "#000000"
  }
});

export const ClassificationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
}));

export const ClassificationInputContent = styled(Paper)(
  ({ theme, classification }) => {
    const topColor = {
      A: "#4EAA68",
      B: "#B4C932",
      C: "#E3DB24",
      Waste: "#DC004E"
    }[classification];
    return {
      position: "relative",
      display: "flex",
      flex: 1,
      gap: "1rem",
      padding: "2rem 1rem 1rem 1rem",
      "&.MuiPaper-root": {
        borderRadius: "1rem 1rem 0.5rem 0.5rem",
        margin: 0
      },
      "&::before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        borderTop: `0.7rem solid ${topColor}`,
        borderRadius: "0.5rem 0.5rem 0 0",
        pointerEvents: "none",
        zIndex: 1
      }
    };
  }
);

export const ButtonsContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  gap: "0.5rem",
  width: "100%",
  paddingBlock: "1rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
});

export const ConfirmContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  background: "#F5F8F3",
  border: "2px solid #B5C9A8",
  fontSize: "1rem",
  fontWeight: 500,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "1rem",
  padding: "0.5rem",
  gap: "0.5rem"
});

export const LabelConfirmDescription = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#757575"
});

export const LabelConfirmValue = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 600,
  fontSize: "1.1rem"
});

export const LoadingContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "20vh",
  height: "100%",
  gap: "1rem"
});

export const StyledSearchContent = styled(SearchContent)(({ theme }) => ({
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    "& > div": {
      width: "100%",
      maxWidth: "100%"
    },
    "& > div > div": {
      width: "100%"
    }
  }
}));

export const StyledInputLabel = styled(InputLabel)(({ hasImage }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "3rem",
  gap: "0.5rem",
  color: hasImage ? "#4EAA68" : "#757575",
  cursor: "pointer",
  "&.Mui-disabled": {
    cursor: "not-allowed",
    color: "#CFCECE"
  }
}));

export const ButtonLink = styled(ButtonLinkStyled)({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  marginLeft: "auto"
});
