import { Typography, Box, CircularProgress } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import { Button } from "../style";
import { BoxConfirm } from "./style";

export const ConfirmView = ({ option, onBack, handleConfirm, isLoading }) => {
  const style = { color: "#353535" };
  const confirmText = {
    5: (
      <>
        Confirma que o <span style={style}>seller não trouxe o produto?</span>
      </>
    ),
    returnBatch: (
      <>
        Deseja mesmo <span style={style}>recusar o lote?</span>
      </>
    ),
    next: (
      <>
        Deseja <span style={style}>aceitar assim mesmo?</span>
      </>
    ),
    reset: (
      <>
        Deseja mesmo <span style={style}>resetar etapas?</span>
      </>
    )
  }[option];

  const textConfirm = {
    true: <CircularProgress size={25} style={{ color: "#BF98FF" }} />,
    false: "Confirmar"
  }[isLoading];

  return (
    <>
      <BoxConfirm>
        <Typography style={{ color: "#757575" }}>{confirmText}</Typography>
      </BoxConfirm>

      <Box
        display="flex"
        alignItems="flex-end"
        marginTop="1rem"
        flex="1"
        style={{ gap: "1rem" }}
      >
        <Button
          variant="text"
          flex="1"
          startIcon={<NavigateBeforeIcon fontSize="large" />}
          onClick={onBack}
          disabled={isLoading}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          flex="1"
          onClick={handleConfirm}
          disabled={isLoading}
        >
          {textConfirm}
        </Button>
      </Box>
    </>
  );
};
