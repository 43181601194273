import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import productLevelsService from "services/productLevelsService";
import summaryFisicService from "services/storageFisicService";
import { Dialog } from "shared";
import * as Yup from "yup";

const schema = Yup.object().shape({
  quantity: Yup.string().required("Campo obrigatório"),
  destinyOriginId: Yup.string().required("Campo obrigatório")
});

export const DialogMigration = ({
  isOpen,
  setIsOpen,
  refresh,
  dataProduct
}) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [sequence, setSequence] = useState([]);
  const { handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(schema)
  });

  const { errors } = formState;

  const handleClose = () => {
    setIsOpen({
      isOpen: false,
      data: {}
    });
    reset({});
  };

  const onSubmitHandler = async dataForm => {
    try {
      setIsLoading(true);

      await summaryFisicService.summaryMigration({
        baseOriginId: dataProduct.product_base_id,
        baseDestinyId: Number(dataForm.destinyOriginId),
        quantity: Number(dataForm.quantity)
      });

      addPopup({
        type: "success",
        title: "Estoque migrado",
        description: `Estoque do produto ${dataProduct?.product_base_name} migrado com sucesso!`
      });
      refresh();
      handleClose();
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao atualizar estoque"
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const init = async () => {
    try {
      const { data } = await productLevelsService.getProductLeves({
        spanId: dataProduct.span_id
      });

      const spanSequence = data.products.find(
        product => product.id === dataProduct.product_base_id
      ).span_sequence;

      setSequence(
        data.products.filter(product => product.span_sequence > spanSequence)
      );
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Ocorreu um erro",
          description: "Erro ao buscar dados"
        });
      }
    }
  };

  useEffect(() => {
    if (dataProduct.span_id) {
      init();
    }
  }, [dataProduct]);

  return (
    <Dialog open={isOpen} handleClose={handleClose} title="Migrar estoque">
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              render={({ field: { onChange, ...props } }) => (
                <TextField
                  onChange={(e, ...rest) => {
                    if (e.target.value <= dataProduct?.stock) {
                      onChange(e, ...rest);
                    }
                  }}
                  {...props}
                  error={!!errors?.quantity}
                  helperText={errors?.quantity?.message}
                  fullWidth
                  variant="outlined"
                  label="Quantidade"
                  type="number"
                />
              )}
              name="quantity"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              error={!!errors?.destinyOriginId}
            >
              <InputLabel>Sequência</InputLabel>
              <Controller
                render={({ field }) => (
                  <Select label="Sequência" {...field}>
                    {sequence?.map(item => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                      >{`${item.span_sequence} - ${item.name}`}</MenuItem>
                    ))}
                  </Select>
                )}
                control={control}
                name="destinyOriginId"
              />

              <FormHelperText>
                {errors?.destinyOriginId?.message}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid container item justifyContent="flex-end">
            <Button
              isLoading={isLoading}
              type="submit"
              variant="contained"
              size="large"
            >
              Migrar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
