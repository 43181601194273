import { Switch, Redirect, Route } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { usePopup } from "../hooks/usePopup";
import Login from "../pages/public/Login";
import setupInterceptors from "../services/api";
import RoutesPrivate from "./private/RoutesPrivate";
import RouteAplication from "./Route";

const Routes = () => {
  const { addPopup } = usePopup();
  const { signOut } = useAuth();

  setupInterceptors(addPopup, signOut);

  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <RouteAplication
        path="/app"
        component={RoutesPrivate}
        permissionsRoute={[]}
        isPrivate
      />
      <Redirect from="*" to="/app/dashboard" />
    </Switch>
  );
};

export default Routes;
