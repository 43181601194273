import { mapPurchaseType, mapType } from "utils";

const formatDateTime = dateTime => {
  const date = new Date(dateTime);
  const formattedDateTime = `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
  return formattedDateTime;
};

const getReasonName = (blitzStep, status) => {
  if (blitzStep === 1 && status === 4) {
    return "Qualidade";
  }

  if (blitzStep === 2 && status === 4) {
    return "Peso";
  }

  return "Não trouxe";
};

const formatUnitWeight = averageUnitWeight => {
  const portion = averageUnitWeight < 1 ? "g" : " Kg";
  if (averageUnitWeight < 1)
    return `${Math.round(averageUnitWeight * 1000)}${portion}`;
  if (Number.isInteger(averageUnitWeight))
    return `${parseInt(averageUnitWeight)}${portion}`;
  return `${parseFloat(averageUnitWeight).toFixed(1)}${portion}`;
};

const formatSampleLowQuantity = batch => {
  const quantity = (batch.sample_low_quantity * 100) / batch.sample_quantity;
  return `${quantity.toFixed(1)}%`;
};

const getReasonQuantity = (batch, reasonName) => {
  return {
    Qualidade: formatSampleLowQuantity(batch),
    Peso: formatUnitWeight(batch.average_unit_weight),
    "Não trouxe": ""
  }[reasonName];
};

export const formatData = data => {
  return data?.reduce((acc, batch) => {
    const typeOfMeasure = mapPurchaseType(batch.product?.purchase_type);
    const batchQuantity = batch.quantity;
    const formattedWeight = [batchQuantity || 0].toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3
    });
    const reasonName = getReasonName(batch.blitz_step, batch.status.id);

    const quantity =
      typeOfMeasure === "Kg" ? `${formattedWeight} Kg` : `${batchQuantity} Un`;
    const formatted = {
      id: batch.id,
      product: batch.product?.name,
      quantity,
      seller: batch.seller?.name,
      receiptDate: formatDateTime(batch.receipt_date),
      updatedBy: batch.updated_by?.username,
      stage: batch.stage?.name,
      purchaseType: mapType(batch.product?.purchase_type),
      status: batch.status,
      reasonName,
      reasonQuantity: getReasonQuantity(batch, reasonName),
      refusalInstructions: batch.purchase_item?.purchase?.refusal_instructions,
      deadlineForAnalysis: batch.purchase_item?.purchase?.deadline_for_analysis
    };
    return [...acc, formatted];
  }, []);
};
