/* eslint-disable react/display-name */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { forwardRef } from "react";
import { TableVirtuoso } from "react-virtuoso";

const TableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: props => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />)
};

export const ProductTable = ({ productData }) => {
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Produto</TableCell>
      </TableRow>
    </TableHead>
  );
  return (
    <TableVirtuoso
      style={{ height: 400 }}
      data={productData}
      components={{ ...TableComponents, TableHead: TableHeader }}
      fixedHeaderContent={() => {}}
      itemContent={(_, item) => (
        <>
          <TableCell>{item.product?.id}</TableCell>
          <TableCell>{item.product?.name}</TableCell>
        </>
      )}
    />
  );
};
