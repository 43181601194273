import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class PurchaseForecastService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getAll(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/purchase/v2`;
    const { data } = await api.get(url, { params });
    return {
      data: { results: data, total_count: data.length }
    };
  }

  async getDownloadPurchaseForecast(form) {
    const url = `${this.url}/api/blueberry/v1/warehouse/purchase/download`;
    return await api.get(url, { responseType: "blob", params: form });
  }

  async deletePurchaseForecast(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/purchase/v2/${id}`;
    return await api.delete(url);
  }

  async generatePurchaseForecast(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/purchase/generate`;
    return await api.post(url, data);
  }

  async postPurchaseForecast(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/purchase/v2`;
    return await api.post(url, data);
  }

  async updatePurchaseForecast(id, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/purchase/v2/${id}`;
    return await api.patch(url, data);
  }

  uploadFilePurchaseForecast() {
    const url = `${this.url}/api/blueberry/v1/warehouse/purchase/upload/filename`;
    return { api, url };
  }
}

const purchaseForecastService = new PurchaseForecastService();
export default purchaseForecastService;
