import { Box, Paper, Table, TableBody } from "@material-ui/core";
import { ButtonLink } from "components/ButtonLink";
import { CustomLineProgress } from "components/LineProgress";
import { AiFillLock } from "react-icons/ai";

import {
  BodyCell,
  BodyRow,
  DateTimeContent,
  HeadCell,
  StyledTableHead,
  TableContent
} from "../style";

export const BatchTable = ({ batchData, loading }) => {
  return (
    <Box>
      {!!loading && <CustomLineProgress />}
      <TableContent component={Paper}>
        <Table stickyHeader>
          <StyledTableHead>
            <HeadCell>ID</HeadCell>
            <HeadCell>Data</HeadCell>
            <HeadCell>Seller</HeadCell>
            <HeadCell>Produto</HeadCell>
            <HeadCell>Estágio</HeadCell>
            <HeadCell>Status</HeadCell>
            <HeadCell>Recebimento</HeadCell>
            <HeadCell></HeadCell>
          </StyledTableHead>
          <TableBody>
            {batchData?.map(batch => (
              <BodyRow key={batch.id}>
                <BodyCell>
                  <Box display="flex" style={{ gap: "10px" }}>
                    <Box width="1rem">
                      {batch.blockQuantity > 0 && (
                        <AiFillLock color="#bf2222" />
                      )}
                    </Box>
                    {batch.id}
                  </Box>
                </BodyCell>
                <BodyCell>
                  <DateTimeContent>
                    <Box>{batch.createdDate}</Box>
                    <Box>{batch.createdTime}</Box>
                  </DateTimeContent>
                </BodyCell>
                <BodyCell>{batch.seller}</BodyCell>
                <BodyCell>{batch.product}</BodyCell>
                <BodyCell>{batch.stage}</BodyCell>
                <BodyCell>{batch.status}</BodyCell>
                <BodyCell>
                  <DateTimeContent>
                    <Box>{batch.receiptDate}</Box>
                    <Box>{batch.receiptTime}</Box>
                  </DateTimeContent>
                </BodyCell>
                <BodyCell>
                  <ButtonLink to={`lotes/${batch.id}`}>Detalhes</ButtonLink>
                </BodyCell>
              </BodyRow>
            ))}
          </TableBody>
        </Table>
      </TableContent>
    </Box>
  );
};
