import { usePopup } from "hooks/usePopup";
import { useRef } from "react";
import assetService from "services/assetsService";
import { Drawer } from "shared";

import { Form } from "../Form";

export const Create = ({ isOpen, setIsOpen, refresh }) => {
  const formRef = useRef(null);
  const { addPopup } = usePopup();

  const handleClose = () => {
    setIsOpen(false);
    formRef.current?.resetForm();
  };

  const onSubmit = async data => {
    try {
      await assetService.postAsset({
        ...data,
        name: data.name?.trim(),
        description: data.description?.trim()
      });

      refresh();
      handleClose();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao criar ativo"
      });
    }
  };

  return (
    <Drawer
      anchor="right"
      title="Novo Ativo"
      open={isOpen}
      onClose={handleClose}
    >
      <Form ref={formRef} onSubmit={onSubmit} />
    </Drawer>
  );
};
