import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import stockService from "services/stockService";
import storageFisicService from "services/storageFisicService";
import { Dialog } from "shared";
import { reasonMap } from "utils";
import * as Yup from "yup";

import { useStyles } from "./styles";

const initialValidation = {
  correctStock: Yup.string().required("Quantidade é obrigatória"),
  reasonId: Yup.string().required("Motivo obrigatório")
};

const returnValidation = reasonId => {
  if (reasonId === 13) {
    return Yup.object().shape({
      ...initialValidation,
      description: Yup.string()
        .min(20, "Mínimo 20 caracteres")
        .required("Campo obrigatório")
    });
  }

  return Yup.object().shape(initialValidation);
};

const filterReasons = [1, 2, 7, 9, 10, 11];

export const DialogEdit = ({
  isOpen,
  setIsOpen,
  refresh,
  dataProduct,
  reasons
}) => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [validators, setValidators] = useState(
    Yup.object().shape(initialValidation)
  );
  const { register, handleSubmit, formState, reset, watch } = useForm({
    resolver: yupResolver(validators)
  });
  const { errors } = formState;
  const reasonId = watch("reasonId");

  const handleClose = () => {
    setIsOpen({
      isOpen: false,
      data: {}
    });
    reset({
      correctStock: null,
      reasonId: null
    });
  };

  const onSubmitHandler = async dataForm => {
    try {
      setIsLoading(true);

      const { data } = await stockService.updateStock({
        id: dataProduct.product_base_id,
        correctStock: Number(dataForm.correctStock),
        reasonId: Number(dataForm.reasonId),
        stockVersion: "summary"
      });

      if (reasonId === 13) {
        await storageFisicService.registerExplanation({
          storageId: data.storage.id,
          explanation: dataForm.description
        });
      }

      addPopup({
        type: "success",
        title: `Estoque do produto ${dataProduct?.product_base_name} atualizado!`
      });
      refresh();
      handleClose();
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao atualizar estoque"
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValidators(returnValidation(reasonId));
  }, [reasonId]);

  useEffect(() => {
    reset({ correctStock: dataProduct?.summary?.toFixed(2) });
  }, [dataProduct]);

  return (
    <Dialog open={isOpen} handleClose={handleClose} title="Editar estoque">
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register("correctStock")}
              error={!!errors?.correctStock}
              helperText={errors?.correctStock?.message}
              style={{ width: "100%" }}
              variant="outlined"
              label="Estoque"
              type="number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              style={{ width: "100%" }}
              variant="outlined"
              error={!!errors?.reasonId}
            >
              <InputLabel>Motivo</InputLabel>
              <Select label="Motivo" {...register("reasonId")}>
                {reasons
                  .filter(reason => !filterReasons.includes(reason.id))
                  .map(reason => (
                    <MenuItem key={reason} value={reason?.id}>
                      {reasonMap(reason?.reason)}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{errors?.reasonId?.message}</FormHelperText>
            </FormControl>
          </Grid>
          {reasonId === 13 && (
            <Grid item xs={12}>
              <TextField
                {...register("description")}
                error={!!errors?.description}
                helperText={errors?.description?.message}
                style={{ width: "100%" }}
                multiline
                rows={2}
                variant="outlined"
                label="Descrição"
                type="string"
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.containerBtn}>
            <Button
              isLoading={isLoading}
              type="submit"
              variant="contained"
              size="large"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
