import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme
} from "@material-ui/core";
import * as React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

const MultipleSelect = ({ nameField, values, onChange }) => {
  const theme = useTheme();
  const [valueSelect, setValueSelect] = React.useState([]);

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setValueSelect(typeof value === "string" ? value.split(",") : value);
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl style={{ width: "100%" }} variant="outlined">
      <InputLabel>{nameField}</InputLabel>
      <Select
        multiple
        value={valueSelect}
        onChange={handleChange}
        input={<OutlinedInput label={nameField} />}
        renderValue={selected => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {values.map(value => (
          <MenuItem
            key={value.id}
            value={value.id}
            style={getStyles(value.name, valueSelect, theme)}
          >
            {value.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelect;
