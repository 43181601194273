import { Typography, Box, CircularProgress } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { forwardRef, useState, useImperativeHandle } from "react";
import batchServiceV3 from "services/batchServiceV3";
import { Dialog } from "shared";

import { BatchBox, Button, TextBatch } from "./style";

const Component = ({ reloadData }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [batch, setBatch] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { addPopup } = usePopup();

  const handleClose = () => {
    setIsOpen(false);
    setBatch({});
  };

  const handleOpen = batch => {
    setIsOpen(true);
    setBatch(batch);
  };

  const updateUndeliveredBatch = async () => {
    try {
      setIsLoading(true);
      await batchServiceV3.batchReceipt(batch.id, {
        blitz_step: 1,
        stage: 1,
        status: 5
      });
      addPopup({
        type: "success",
        title: "Lote atualizado",
        description: `O lote ${batch.id} foi atualizado para não entregue`
      });
      handleClose();
      reloadData();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao atualizar lote",
        description: "Não foi possível atualizar o lote para não entregue"
      });
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({ handleOpen }));

  const childrenButton = {
    true: <CircularProgress style={{ color: "#BF98FF" }} size={25} />,
    false: "Confirmar"
  }[String(isLoading)];

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title="Seller não trouxe o lote"
      maxWidth="34rem"
    >
      <Box>
        <BatchBox>
          <TextBatch>
            Produto:{" "}
            <strong style={{ color: "#353535" }}>{batch.product?.name}</strong>
          </TextBatch>
          <TextBatch>
            Seller: <strong>{batch.seller?.name}</strong>
          </TextBatch>
          <TextBatch>
            Porção: <strong>{batch.purchaseType}</strong>
          </TextBatch>
        </BatchBox>

        <Typography style={{ color: "#757575" }}>
          Confirma que o seller não trouxe o produto?
        </Typography>
        <Box display="flex" marginTop="2.5rem">
          <Button
            onClick={handleClose}
            variant="contained"
            background="#ffffff"
            color="#757575"
          >
            Cancelar
          </Button>
          <Button
            onClick={updateUndeliveredBatch}
            variant="contained"
            background="#eb001b"
            disabled={isLoading}
          >
            {childrenButton}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export const UndeliveredBatchPopup = forwardRef(Component);
