import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class NaboProductConfigService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getSku(params) {
    const url = `${this.url}/api/nabo/v1/nabo/product-config`;
    return await api.get(url, { params });
  }

  async updatePickers() {
    const url = `${this.url}/api/nabo/v1/nabo/pre-picking/set-picker/file`;
    return { api, url };
  }

  async getPrePickingById(params) {
    const url = `${this.url}/api/nabo/v1/nabo/pre-picking/v2`;
    return await api.get(url, { params });
  }
}

const naboProductConfigService = new NaboProductConfigService();
export default naboProductConfigService;
