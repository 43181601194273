import { Box } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ContainerPage, TitlePage } from "components";
import PageLoader from "components/PageLoad";
import { usePopup } from "hooks/usePopup";
import { useEffect, useMemo, useState } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { RiPlantLine } from "react-icons/ri";
import { useParams, useHistory } from "react-router-dom";
import sellerService from "services/sellerService";

import { CardDash } from "./components/CardDash";
import { DashAccordion } from "./components/DashAccordion";
import { BatchTable } from "./components/Tables/BatchTable";
import { ProductTable } from "./components/Tables/productTable";
import { ReturnedTable } from "./components/Tables/ReturnedTable";
import { AccordionContainer } from "./style";
import { formatterData } from "./utils/fomatterData";

export const SellerDashboard = () => {
  const { sellerId } = useParams();
  const history = useHistory();
  const { addPopup } = usePopup();
  const [dashBoardData, setDashBoardData] = useState({});
  const [loading, setLoading] = useState(false);

  const getDashBoard = async () => {
    setLoading(true);
    try {
      const { data } = await sellerService.getSellersDashboard(sellerId);
      setDashBoardData(data);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar dashboard",
        description: errorMessage
      });
      history.push("/app/404");
    } finally {
      setLoading(false);
    }
  };

  const { batches, returnedBatches } = useMemo(() => {
    const batches = formatterData.formatBatch(dashBoardData.batches);
    const returnedBatches = formatterData.formatReturnedBatch(
      dashBoardData.batchesReturn
    );
    return { batches, returnedBatches };
  }, [dashBoardData]);

  useEffect(() => {
    getDashBoard();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <ContainerPage id="back-to-top-anchor">
      <Box>
        <TitlePage>Dashboard</TitlePage>
      </Box>
      <Box>
        <CardDash dashBoardData={dashBoardData} />
      </Box>
      <AccordionContainer>
        <DashAccordion
          title={"Produtos"}
          table={<ProductTable productData={dashBoardData.products} />}
          icon={<RiPlantLine />}
        />
        <DashAccordion
          title={"Lotes"}
          table={<BatchTable batchData={batches} />}
          icon={<BsBoxSeam />}
        />
        <DashAccordion
          title={"Devoluções"}
          table={<ReturnedTable batchData={returnedBatches} />}
          icon={<DeleteForeverIcon />}
        />
      </AccordionContainer>
    </ContainerPage>
  );
};
