import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import MapIcon from "@material-ui/icons/Map";
import SearchIcon from "@material-ui/icons/Search";
import { Alert } from "@material-ui/lab";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState, useEffect, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import newLocationService from "services/locationService";
import sellerService from "services/sellerService";
import { Dialog } from "shared";
import { MenuItem } from "shared/Select";
import * as Yup from "yup";

import MapDialog from "./../../components/MapDialog";
import isValidCoordinates from "./../../utils/coordinatesValidator";
import stateList from "./../../utils/stateList";

const schema = Yup.object().shape({
  street: Yup.string()
    .max(50, "Máximo de 50 caracteres")
    .required("Rua obrigatória"),
  number: Yup.string()
    .max(10, "Máximo de 10 caracteres")
    .required("Número é obrigatório"),
  complement: Yup.string().max(50, "Máximo de 50 caracteres"),
  neighborhood: Yup.string().max(50, "Máximo de 50 caracteres"),
  city: Yup.string()
    .max(50, "Máximo de 50 caracteres")
    .required("Cidade é obrigatória"),
  state: Yup.string().required("Estado obrigatório"),
  zip_code: Yup.string().required("CEP obrigatório"),
  observation: Yup.string().max(500, "Máximo de 500 caracteres")
});
export const ModalCreateAddress = ({
  openModal,
  setOpenModal,
  refresh,
  dataSeller
}) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const { register, handleSubmit, formState, reset, setValue, watch } = useForm(
    {
      resolver: yupResolver(schema),
      defaultValues: {
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
        zip_code: "",
        lat: "",
        lng: ""
      }
    }
  );
  const { errors } = formState;
  const [isFetchingCep, setIsFetchingCep] = useState(false);
  const {
    onChange: zipCodeOnChange,
    onBlur: zipCodeOnBlur,
    ref: zipCodeRef,
    name: zipCodeName
  } = register("zip_code");
  const oldZipCode = useRef();
  const oldData = useRef();
  const zipCode = watch("zip_code");

  const fetchCepInfo = async isSearch => {
    if (zipCode?.length === 9 && (!isFetchingCep || isSearch)) {
      setIsFetchingCep(true);
      try {
        setIsLoading(true);
        const data = await newLocationService.getCepInfo(zipCode);
        setValue("street", data.street || "");
        setValue("neighborhood", data.neighborhood || "");
        setValue("city", data.city || "");
        setValue("state", data.state || "");
        oldZipCode.current = zipCode;
      } catch {
        addPopup({ type: "error", title: "CEP não encontrado." });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (/^\d{5}-\d{3}$/.test(zipCode)) {
      fetchCepInfo(false);
    }
  }, [zipCode]);

  const handleClose = () => {
    reset({});
    setOpenModal(false);
    setIsFetchingCep(false);
    oldData.current = {};
    oldZipCode.current = "";
  };

  const submitAddressForm = async data => {
    try {
      await sellerService.postAddress(data);
      addPopup({ type: "success", title: "Endereço cadastrado com sucesso." });
      handleClose();
      refresh();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao cadastrar endereço."
      });
    }
  };

  const onSubmit = async data => {
    try {
      setIsLoading(true);
      data.lat = coordinates[0];
      data.lng = coordinates[1];

      data.zip_code = data.zip_code.replace("-", "");
      data.seller = dataSeller.id;

      if (!isValidCoordinates(data.lat, data.lng)) {
        addPopup({
          type: "error",
          title: "Coordenadas inválidas.",
          message: "Verifique se as coordenadas estão corretas."
        });
        setIsLoading(false);
        return;
      }

      await submitAddressForm(data);
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao obter coordenadas do endereço."
      });
    } finally {
      setIsLoading(false);
    }
  };

  const mapCoordinatesAdjust = async () => {
    try {
      const data = watch();

      const address = `${data.street}, ${data.number}, ${data.neighborhood}, ${data.city}, ${data.state}, ${data.zip_code}, Brazil`;

      const dataWithoutCoords = { ...data };
      const oldDataWithoutCoords = { ...oldData.current };

      delete dataWithoutCoords.latitude;
      delete dataWithoutCoords.longitude;
      delete oldDataWithoutCoords.latitude;
      delete oldDataWithoutCoords.longitude;

      if (!coordinates || coordinates.some(coord => coord === "")) {
        const coords = await newLocationService.searchLocation(address);
        setCoordinates([coords.lat, coords.lng]);
        oldData.current = data;
      }

      setIsMapOpen(true);
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao obter coordenadas do endereço."
      });
    }
  };

  const handleCoordinatesChange = (e, coordType) => {
    let value = e.target.value;

    value = value.replace(/[^0-9-]/g, "");

    const isNegative = value.charAt(0) === "-";

    if (isNegative) {
      value = "-" + value.replace(/[^0-9.-]/g, "").slice(1);
    }

    if (value.length > 2 && value.indexOf(".") === -1) {
      value =
        value.slice(0, isNegative ? 3 : 2) +
        "." +
        value.slice(isNegative ? 3 : 2);
    }

    setValue(coordType, value);
    setCoordinates([watch("lat"), watch("lng")]);
  };

  const handleSearchCEP = useCallback(async () => {
    if (!zipCode || zipCode.length !== 9) {
      addPopup({ type: "error", title: "CEP inválido." });
      return;
    }
    if (zipCode === oldZipCode.current) {
      return;
    }
    fetchCepInfo(true);
  }, [zipCode, addPopup]);

  const onCoordinatesChange = coords => {
    setCoordinates(coords);
    setValue("lat", coords[0]);
    setValue("lng", coords[1]);
  };

  const canUseMap =
    watch("street") &&
    watch("number") &&
    watch("city") &&
    watch("state") &&
    watch("zip_code");

  const canFinish =
    !isLoading &&
    !Object.keys(errors).length &&
    canUseMap &&
    watch("lat") &&
    watch("lng") &&
    JSON.stringify(oldData.current) !== JSON.stringify(watch());

  return (
    <>
      <MapDialog
        open={isMapOpen}
        setOpen={setIsMapOpen}
        initialPosition={coordinates}
        onCoordinateChange={onCoordinatesChange}
      />
      <Dialog
        open={openModal}
        handleClose={handleClose}
        title="Registro de Endereço"
      >
        <Box component="form" onSubmit={handleSubmit()}>
          <Box display="flex" justifyContent="center" width="100%">
            <Alert severity="info">
              Caso o fornecedor não possua um endereço, insira o endereço mais
              próximo e ajuste as coordenadas no mapa.
            </Alert>
          </Box>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <InputMask
                mask="99999-999"
                maskChar=" "
                onChange={zipCodeOnChange}
                onBlur={zipCodeOnBlur}
              >
                {() => (
                  <TextField
                    fullWidth
                    error={!!errors?.zip_code}
                    helperText={errors?.zip_code?.message}
                    variant="outlined"
                    label="CEP *"
                    ref={zipCodeRef}
                    name={zipCodeName}
                    onKeyPress={e => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSearchCEP();
                      }
                    }}
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleSearchCEP} edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("street")}
                fullWidth
                error={!!errors?.street}
                helperText={errors?.street?.message}
                disabled={isLoading}
                variant="outlined"
                label="Rua *"
                autoComplete="off"
                InputLabelProps={{ shrink: !!watch("street") }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register("number")}
                fullWidth
                error={!!errors?.number}
                helperText={errors?.number?.message}
                disabled={isLoading}
                variant="outlined"
                label="Número *"
                autoComplete="off"
                InputLabelProps={{ shrink: !!watch("number") }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register("complement")}
                fullWidth
                error={!!errors?.complement}
                helperText={errors?.complement?.message}
                disabled={isLoading}
                variant="outlined"
                label="Complemento"
                autoComplete="off"
                InputLabelProps={{ shrink: !!watch("complement") }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register("neighborhood")}
                fullWidth
                error={!!errors?.neighborhood}
                helperText={errors?.neighborhood?.message}
                disabled={isLoading}
                variant="outlined"
                label="Bairro"
                autoComplete="off"
                InputLabelProps={{ shrink: !!watch("neighborhood") }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register("city")}
                fullWidth
                error={!!errors?.city}
                helperText={errors?.city?.message}
                disabled={isLoading}
                variant="outlined"
                label="Cidade *"
                autoComplete="off"
                InputLabelProps={{ shrink: !!watch("city") }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register("state")}
                select
                fullWidth
                value={watch("state")}
                error={!!errors?.state}
                helperText={errors?.state?.message}
                variant="outlined"
                disabled={isLoading}
                label="Estado *"
                autoComplete="off"
                InputLabelProps={{ shrink: !!watch("state") }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "300px"
                      }
                    }
                  }
                }}
              >
                {stateList.map(state => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={mapCoordinatesAdjust}
                fullWidth
                disabled={!canUseMap}
                startIcon={<MapIcon />}
              >
                Ajustar coordenadas no mapa
              </Button>
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register("lat")}
                fullWidth
                error={!!errors?.lat}
                onChange={e => handleCoordinatesChange(e, "lat")}
                helperText={errors?.lat?.message}
                variant="outlined"
                label="Latitude *"
                inputProps={{ maxLength: 20 }}
                InputLabelProps={{ shrink: !!watch("lat") }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register("lng")}
                onChange={e => handleCoordinatesChange(e, "lng")}
                fullWidth
                error={!!errors?.lng}
                helperText={errors?.lng?.message}
                variant="outlined"
                label="Longitude *"
                inputProps={{ maxLength: 20 }}
                InputLabelProps={{ shrink: !!watch("lng") }}
                autoComplete="off"
              />
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Button
                isLoading={isLoading}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={!canFinish}
              >
                Concluir
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};
