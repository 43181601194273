import {
  FormControl,
  MenuItem,
  Select,
  ListItemText,
  Checkbox,
  OutlinedInput,
  Box
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState, useEffect, useMemo } from "react";
import depotService from "services/depotService";

export const DropdownButtonPackaging = ({
  packagingData,
  productBasePackages,
  productBaseId,
  refresh
}) => {
  const { addPopup } = usePopup();
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setSelectedItems(value);
  };

  const productBaseConfigPayload = useMemo(() => {
    return selectedItems.map(packagingId => {
      return {
        productBaseId,
        packagingId
      };
    });
  }, [selectedItems]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await depotService.postPackagingProductBaseConfig(
        productBaseConfigPayload
      );
      addPopup({
        type: "success",
        title: "Configuração salva"
      });
      refresh();
    } catch (error) {
      addPopup("error", "Erro ao salvar configuração");
    } finally {
      setLoading(false);
    }
  };

  const packagesNames = packagingData
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .reduce((acc, packaging) => {
      return { ...acc, [packaging.id]: packaging.name };
    }, {});

  useEffect(() => {
    setSelectedItems(productBasePackages?.map(item => item.packagingId));
  }, [productBasePackages]);

  return (
    <Box style={{ width: "350px", display: "flex", gap: "1rem" }}>
      <FormControl fullWidth>
        <Select
          value={selectedItems}
          multiple
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={selected => {
            if (!selected || selected.length === 0) {
              return "Nenhum selecionado";
            }

            return selected
              .map(item => packagesNames[item])
              .sort((a, b) => a.localeCompare(b))
              .join(", ");
          }}
          style={{ width: "100%" }}
          disabled={loading}
        >
          {packagingData.map(packaging => {
            return ![1, 2].includes(packaging.id) ? (
              <MenuItem key={packaging.id} value={packaging.id}>
                <Checkbox
                  checked={selectedItems?.some(item => item === packaging?.id)}
                  disabled={loading}
                />
                <ListItemText primary={packaging.name} />
              </MenuItem>
            ) : null;
          })}
          <Button
            style={{
              marginTop: "1rem",
              marginLeft: "1rem"
            }}
            variant="contained"
            onClick={handleSave}
            isLoading={loading}
            disabled={loading || !selectedItems.length}
          >
            Salvar
          </Button>
        </Select>
      </FormControl>
    </Box>
  );
};
