import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { useFormik } from "formik";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import batchServiceV3 from "services/batchServiceV3";
import { Dialog } from "shared";

import { validationSchemaUpdateManualCounting } from "./schema";
import { useStyles } from "./style";

const formatInitialValues = data => ({
  manualCountedQuantity: data?.manual_counted_quantity
});

export const ModalCount = ({ isOpen, onClose, onBack, data, reload }) => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const { id, available_quantity } = data;
  const [view, setView] = useState(0);

  const onSubmitHandler = async () => {
    await handleManualCountUpdate();
    formik.setSubmitting(false);
    reload && reload();
  };

  const initialValues = formatInitialValues(data);
  const isInitialValid =
    validationSchemaUpdateManualCounting.isValidSync(initialValues);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaUpdateManualCounting,
    onSubmit: onSubmitHandler,
    validateOnChange: false,
    isInitialValid: isInitialValid
  });

  const handleManualCountUpdate = async () => {
    try {
      await batchServiceV3.updateBatchManualCount(id, formik.values);
      addPopup({
        type: "success",
        title: `Lote ${id} atualizado com sucesso!`,
        describe: `Contagem manual registrada para o Lote ${id}.`
      });
      handleClose();
    } catch (error) {
      const response = error?.response;
      addPopup({
        type: "error",
        title: `Erro ao registrar contagem do lote "${id}"`,
        description: `Erro: ${response?.data?.msg || error}.`
      });
    }
  };

  const handleClose = () => {
    setView(0);
    onClose();
  };

  const childDefaultPage = () => (
    <>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Grid style={{ maxWidth: "100%" }} item xs={12} sm={6}>
          <Typography className={classes.typography}>
            <p style={{ color: "#757575" }}>
              Lote: <strong style={{ color: "black" }}>{id}</strong>
              <br />
              Qnt disponível:{" "}
              <strong style={{ color: "black" }}>
                {available_quantity} Kg/Un
              </strong>
            </p>
          </Typography>
        </Grid>
        <Grid style={{ maxWidth: "100%" }} item xs={12} sm={6}>
          <FormControl style={{ width: "100%" }} variant="outlined">
            <Typography>Quantos Kg/Un possui este lote?</Typography>
            <TextField
              className={classes.inputBox}
              type="number"
              name="manualCountedQuantity"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.manualCountedQuantity}
              error={
                (formik.touched.manualCountedQuantity ||
                  formik.submitCount > 0) &&
                !!formik.errors.manualCountedQuantity
              }
              helperText={
                (formik.touched.manualCountedQuantity ||
                  formik.submitCount > 0) &&
                formik.errors.manualCountedQuantity
              }
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.buttonGrid}>
          <Button
            onClick={onBack}
            type="button"
            variant="outlined"
            size="large"
            className={classes.button}
          >
            Voltar
          </Button>
          <Button
            onClick={() => setView(state => ++state)}
            type="button"
            variant="contained"
            size="large"
            className={classes.button}
            disabled={!formik.isValid}
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const childModalCountConfirm = () => (
    <>
      <Grid
        container
        spacing={2}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Grid style={{ maxWidth: "100%" }} item xs={12} sm={6}>
          <Typography className={classes.typography}>
            <p style={{ color: "#757575" }}>
              Lote: <strong style={{ color: "black" }}>{id}</strong>
              <br />
              Qnt disponível:{" "}
              <strong style={{ color: "black" }}>
                {available_quantity} Kg/Un
              </strong>
            </p>
          </Typography>
        </Grid>
        <Grid style={{ maxWidth: "100%" }} item xs={12} sm={6}>
          <Typography>Os dados inseridos estão corretos?</Typography>
          <Typography
            style={{
              marginBottom: "24px",
              padding: "2px 0.3rem 2px 0.3rem",
              borderRadius: "16px",
              border: "2px solid #B5C9A8",
              background: "#F5F8F3"
            }}
          >
            <p style={{ color: "#757575" }}>
              Quantidade contada:{" "}
              <strong style={{ color: "black" }}>
                {formik.values.manualCountedQuantity} Kg/Un
              </strong>
            </p>
          </Typography>
        </Grid>
        <Grid item className={classes.buttonGrid}>
          <Button
            onClick={() => setView(state => --state)}
            type="button"
            variant="outlined"
            size="large"
            className={classes.button}
          >
            Voltar
          </Button>
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
            variant="contained"
            className={classes.button}
            size="large"
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const children = {
    0: childDefaultPage,
    1: childModalCountConfirm
  }[view];

  useEffect(() => {
    formik.setValues({
      manualCountedQuantity: data?.manual_counted_quantity
    });
  }, [data]);

  return (
    <>
      <Dialog
        open={isOpen}
        handleClose={handleClose}
        onClose={handleClose}
        title={view === 0 ? "Inserir quantidade" : "Confirmar dados"}
        className={classes.root}
      >
        <Box
          className={classes.mainBox}
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          {children}
        </Box>
      </Dialog>
    </>
  );
};
