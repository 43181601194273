import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class AuthenticationService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async login(username, password) {
    const url = `${ESTOQUE_BACKEND_URL}/api/auth-internal/v2/token`;
    return await api.post(url, { username, password });
  }

  async refresh(refresh) {
    const url = `${ESTOQUE_BACKEND_URL}/api/auth-internal/v2/token/refresh`;
    return await api.post(url, { refresh });
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
