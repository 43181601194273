import { Breadcrumbs } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import { TitlePage } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom";
import depotService from "services/depotService";

import { EditPopup } from "./components/EditPopup";
import { Levels } from "./components/Levels";
import { EditButton, StyledContainerPage } from "./styles";

export const SensitivityLevelsPage = () => {
  const { addPopup } = usePopup();
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(null);
  const [sensitivityLevelsData, setSensitivityLevelsData] = useState(null);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await depotService.getSensitiveLeves();
      setSensitivityLevelsData(data);
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao carregar dados",
        description: error.response?.data?.msg ?? ""
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <StyledContainerPage>
      <Breadcrumbs separator={<KeyboardArrowRight />}>
        <Link to="/app/config" style={{ textDecoration: "none" }}>
          <TitlePage>Configurações</TitlePage>
        </Link>
        <TitlePage>Níveis de sensibilidade</TitlePage>
      </Breadcrumbs>
      <EditButton
        variant="outlined"
        startIcon={<FiSettings />}
        onClick={() => setOpenEdit(!openEdit)}
      >
        Editar
      </EditButton>
      <Levels loading={loading} levels={sensitivityLevelsData?.levels ?? 0} />
      <EditPopup
        open={openEdit}
        handleClose={handleCloseEdit}
        levels={sensitivityLevelsData?.levels ?? 0}
      />
    </StyledContainerPage>
  );
};
