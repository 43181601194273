export const warehouseProductBaseConfigFormatter = data => {
  return (data || [])?.reduce((acc, productBaseConfig) => {
    return [
      ...acc,
      {
        id: productBaseConfig.warehouse?.id,
        name: productBaseConfig.warehouse?.name,
        abbreviation: productBaseConfig.warehouse?.abbreviation
      }
    ];
  }, []);
};

export const packagingProductBaseConfigFormatter = data => {
  return (data || [])?.reduce((acc, productBaseConfig) => {
    return [
      ...acc,
      {
        id: productBaseConfig.packaging?.id,
        name: productBaseConfig.packaging?.name
      }
    ];
  }, []);
};
