import * as Yup from "yup";

const validationDefault = {
  description: Yup.string()
    .typeError("Descrição é obrigatória")
    .max(60, "Máximo 60 caracteres")
    .required("Descrição é obrigatória")
};

export const validationSchemaEdit = Yup.object().shape({
  name: Yup.string()
    .typeError("Nome é obrigatório")
    .required("Nome é obrigatório"),
  ...validationDefault
});

export const validationSchemaCreate = Yup.object().shape({
  name: Yup.string()
    .typeError("Nome é obrigatório")
    .required("Nome é obrigatório"),
  ...validationDefault
});
