import { Box, Typography } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import batchConferenceService from "services/bachConferenceService";
import { Drawer } from "shared";
import { mapType } from "utils";

import { Form } from "../Form";
import { conferenceDataStyles } from "./styles";

export const ModalUpdate = ({
  conferenceData,
  isOpen,
  close,
  conferenceStatus,
  setPageToInitial
}) => {
  const { batchInfos, received, quantityInvoice, batchConferenceId } =
    conferenceData;
  const classes = conferenceDataStyles();
  const { addPopup } = usePopup();

  const productUnit = mapType(batchInfos?.product?.purchase_type) || "unidade";

  const handleClose = () => {
    close();
  };

  const onSubmit = async data => {
    try {
      await batchConferenceService.updateBatchConference(
        batchConferenceId,
        data
      );

      addPopup({
        type: "success",
        title: "Conferência atualizada com sucesso."
      });

      setPageToInitial();
      handleClose();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao atualizar regra da blitz."
      });
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      title="Conferência"
    >
      <Box className={classes.boxStyle}>
        <Box>
          <Typography className={classes.conferenceDataInfo}>
            Produto: <strong>{batchInfos?.product?.name}</strong>
          </Typography>
          <Typography className={classes.conferenceDataInfo}>
            Seller: <span>{batchInfos?.seller?.name}</span>
          </Typography>
          <Typography className={classes.conferenceDataInfo}>
            porção: <span>{productUnit}</span>
          </Typography>
        </Box>

        <hr style={{ width: "94%", marginTop: "1rem" }} />

        <Box>
          <Typography className={classes.conferenceDataInfo}>
            Recebido:{" "}
            <span>
              {received || 0} {productUnit}
            </span>
          </Typography>
          <Typography className={classes.conferenceDataInfo}>
            Devolvido:{" "}
            <span>
              {quantityInvoice || 0} {productUnit}
            </span>
          </Typography>
        </Box>
      </Box>

      <Form
        productUnit={productUnit}
        conferenceData={conferenceData}
        onSubmit={onSubmit}
        conferenceStatus={conferenceStatus}
      />
    </Drawer>
  );
};
