import { yupResolver } from "@hookform/resolvers/yup";
import { Box, TextField, Typography } from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import userService from "services/userService";
import Modal from "shared/Modal";
import { permissionsAplication } from "utils/permissionAplication";
import * as Yup from "yup";

import { useStyles } from "./styles";

const validateSchema = Yup.object().shape({
  password: Yup.string().required("Senha é obrigatória")
});

export const ModalEditUserPassword = ({ open, handleOpenModal, separator }) => {
  const { addPopup } = usePopup();
  const [userPermissions, setUserPermissions] = useState([]);
  const { id, groups, username } = separator;
  const {
    register,
    handleSubmit,
    getValues,
    resetField,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validateSchema)
  });

  const classes = useStyles();

  const handleUpdatePassword = async () => {
    try {
      const filterName = username.replace(/\s/g, ".");

      const permissionFiltered = groups.filter(group =>
        userPermissions.push(group.id)
      );

      const beforePermissions = groups
        .filter(
          group =>
            !Object.entries(permissionsAplication).some(
              ([key]) => key === group.name
            )
        )
        .map(group => group.id);

      await userService.updateUserInfo({
        groupIds: [...beforePermissions, ...userPermissions],
        username: filterName,
        password: getValues("password"),
        id
      });

      addPopup({
        type: "success",
        title: "Senha alteradas com sucesso"
      });

      return handleOpenModal();
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao alterar senha"
      });
    }
  };

  useEffect(() => {
    resetField("password");
  }, [open]);

  return (
    <Modal
      title="Alterar senha"
      open={open}
      handleClose={handleOpenModal}
      style={{ width: "30rem" }}
    >
      <Typography className={classes.userName}>{username}</Typography>

      <Box component="form" onSubmit={handleSubmit(handleUpdatePassword)}>
        <Box className={classes.content}>
          <Typography className={classes.text}>
            Sua senha será alterada, digite a nova no campo abaixo.
          </Typography>

          <Box className={classes.boxPassword}>
            <TextField
              label="Digite a sua senha"
              variant="outlined"
              error={!!errors?.password}
              helperText={errors?.password?.message}
              {...register("password")}
            />
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Button
            variant="contained"
            onClick={handleOpenModal}
            style={{ background: "#D13D34" }}
            className={classes.button}
          >
            Cancelar
          </Button>

          <Button variant="contained" type="submit" className={classes.button}>
            Confirmar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
