import { Box } from "@material-ui/core";
import { Button, ContainerPage, OptionsMenu, TitlePage } from "components";
import { useTableParams } from "hooks/useTableParams";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineDelete } from "react-icons/md";
import migrationService from "services/migrationService";
import { Table } from "shared";
import { convertDate } from "utils";

import { Create } from "./Create";
import { DialogConfirmDelete } from "./DialogConfirmDelete";
import { Edit } from "./Edit";

const MigrationRules = () => {
  const { data, onChange, params, refresh, status, handleSearch, count } =
    useTableParams({
      service: params => migrationService.getMigrationRules(params)
    });
  const [dialogCreateMigrationRule, setDialogCreateMigrationRule] =
    useState(false);
  const [dialogEdit, setDialogEdit] = useState({
    isOpen: false,
    data: {}
  });
  const [dialogConfirmDelete, setDialogConfirmDelete] = useState({
    isOpen: false,
    data: {}
  });

  const options = [
    {
      title: "Excluir",
      icon: MdOutlineDelete,
      color: "red",
      permissions: ["Tech", "Admin"],
      action: data => setDialogConfirmDelete({ isOpen: true, data })
    },
    {
      title: "Editar",
      icon: FiEdit2,
      permissions: ["Tech", "Admin"],
      action: data => setDialogEdit({ isOpen: true, data })
    }
  ];

  const columns = [
    {
      field: "id",
      label: "ID",
      align: "left",
      style: { minWidth: "60px" }
    },
    {
      field: "source_product_base",
      label: "Produto de origem",
      formatter: value => value.name
    },
    {
      field: "destination_product_base",
      label: "Produto de destino",
      formatter: value => value.name
    },
    {
      field: "created_by",
      label: "Criado por",
      formatter: value => value.username
    },
    {
      field: "updated_by",
      label: "Editado por",
      formatter: value => value.username
    },
    {
      field: "updated",
      label: "Atualizado Em",
      formatter: value => convertDate(new Date(value))
    },
    {
      field: "options",
      label: "",
      align: "right",
      style: { minWidth: "150px" },
      disabled: true,
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  return (
    <>
      <ContainerPage>
        <TitlePage>Regra de Migração</TitlePage>

        <Table
          handleSearch={handleSearch}
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          filters={
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flex="1"
            >
              <Button
                onClick={() => setDialogCreateMigrationRule(true)}
                startIcon={<IoMdAdd />}
                variant="contained"
              >
                Adicionar
              </Button>
            </Box>
          }
        />
      </ContainerPage>

      <DialogConfirmDelete
        open={dialogConfirmDelete.isOpen}
        handleClose={() => setDialogConfirmDelete({ isOpen: false, data: {} })}
        refresh={refresh}
        data={dialogConfirmDelete.data}
      />
      <Create
        isOpen={dialogCreateMigrationRule}
        refresh={refresh}
        onClose={() => setDialogCreateMigrationRule(false)}
      />
      <Edit
        isOpen={dialogEdit.isOpen}
        refresh={refresh}
        data={dialogEdit.data}
        onClose={() => setDialogEdit({ isOpen: false, data: {} })}
      />
    </>
  );
};

export default MigrationRules;
