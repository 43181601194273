import { Box, Zoom } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import { Button } from "components";
import React, { useState, useRef, useEffect } from "react";
import { BiAnalyse } from "react-icons/bi";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "inline-block",
    paddingBlock: theme.spacing(1)
  },
  fab: {
    marginRight: theme.spacing(1),
    transition: "bottom 0.3s ease"
  },
  fabIcon: {
    color: "white",
    transition: "transform 0.3s ease"
  },
  rotate: {
    transition: "transform 0.3s ease",
    transform: "rotate(45deg)"
  },
  fabOption: {
    display: "flex",
    width: "100%",
    padding: "0.5rem 1rem",
    alignItems: "center",
    gap: theme.spacing(1),
    fontFamily: "Roboto"
  },
  leftButton: {
    position: "absolute",
    bottom: "15%",
    left: "-110%",
    transform: "translate(-50%, 0)",
    display: "flex",
    flexDirection: "column-reverse",
    gap: theme.spacing(1),
    transition: "opacity 0.3s ease, visibility 0.3s ease"
  },
  centerButton: {
    position: "absolute",
    bottom: "100%",
    left: "50%",
    transform: "translate(-50%, 0)",
    display: "flex",
    flexDirection: "column-reverse",
    gap: theme.spacing(1)
  },
  rightButton: {
    position: "absolute",
    bottom: "15%",
    left: "200%",
    transform: "translate(-50%, 0)",
    display: "flex",
    flexDirection: "column-reverse",
    gap: theme.spacing(1),
    transition: "opacity 0.3s ease, visibility 0.3s ease"
  },
  bottomButton: {
    position: "absolute",
    top: "95%",
    left: "50%",
    transform: "translate(-50%, 0)",
    display: "flex",
    flexDirection: "column-reverse",
    gap: theme.spacing(1),
    transition: "opacity 0.3s ease, visibility 0.3s ease"
  }
}));

export default function FloatingActionButtons({
  onClickMove,
  onClickConvert,
  onClickHistory,
  onClickReason
}) {
  const classes = useStyles();
  const [showExtraButtons, setShowExtraButtons] = useState(false);
  const containerRef = useRef(null);

  const onClick = func => {
    setShowExtraButtons(false);
    func();
  };

  const toggleExtraButtons = () => {
    setShowExtraButtons(!showExtraButtons);
  };

  const handleClickOutside = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowExtraButtons(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} className={classes.root}>
      <Fab color="primary" className={classes.fab} onClick={toggleExtraButtons}>
        <AddIcon
          className={`${classes.fabIcon} ${
            showExtraButtons ? classes.rotate : ""
          }`}
        />
      </Fab>
      {showExtraButtons && (
        <>
          <div className={classes.leftButton}>
            <Zoom
              in={showExtraButtons}
              style={{
                transitionDelay: showExtraButtons ? "100ms" : "0ms"
              }}
            >
              <Box className={classes.fabOption}>
                <Button
                  startIcon={<BiAnalyse />}
                  variant="contained"
                  onClick={() => onClick(onClickConvert)}
                >
                  Converter
                </Button>
              </Box>
            </Zoom>
          </div>

          <div className={classes.centerButton}>
            <Zoom
              in={showExtraButtons}
              style={{
                transitionDelay: showExtraButtons ? "150ms" : "0ms"
              }}
            >
              <Box className={classes.fabOption}>
                <Button
                  startIcon={<SyncAltIcon />}
                  variant="contained"
                  onClick={() => onClick(onClickMove)}
                >
                  Movimentar
                </Button>
              </Box>
            </Zoom>
          </div>

          <div className={classes.rightButton}>
            <Zoom
              in={showExtraButtons}
              style={{
                transitionDelay: showExtraButtons ? "200ms" : "0ms"
              }}
            >
              <Box className={classes.fabOption}>
                <Button
                  startIcon={<WatchLaterIcon />}
                  variant="contained"
                  onClick={() => onClick(onClickHistory)}
                >
                  Histórico
                </Button>
              </Box>
            </Zoom>
          </div>

          <div className={classes.bottomButton}>
            <Zoom
              in={showExtraButtons}
              style={{
                transitionDelay: showExtraButtons ? "200ms" : "0ms"
              }}
            >
              <Box className={classes.fabOption}>
                <Button
                  startIcon={<WatchLaterIcon />}
                  variant="contained"
                  onClick={() => onClick(onClickReason)}
                >
                  Classificar
                </Button>
              </Box>
            </Zoom>
          </div>
        </>
      )}
    </div>
  );
}
