import { Box, Button } from "@material-ui/core";
import ImgConference from "assets/images/conference.svg";
import { ContainerPage, TitlePage } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";

import { ModalCount } from "./components/ModalCount";
import { ModalScanner } from "./components/ModalScanner";
import { Content, Image } from "./style";

export const BatchCount = () => {
  const [openScan, setOpenScan] = useState(false);
  const [openModalCount, setModalCount] = useState(false);
  const [batchData, setBatchData] = useState({});
  const [view, setView] = useState(0);

  const resetView = () => {
    setBatchData({});
    setModalCount(false);
    setOpenScan(false);
    setView(0);
  };

  const openScanner = () => {
    setView(1);
    setOpenScan(true);
  };

  const handleScannerOnNext = () => {
    setView(state => ++state);
    setModalCount(true);
  };

  /* Child Components */
  const childDefaultPage = () => (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <h1 style={{ textAlign: "center" }}>
        Bipe um lote para iniciar a contagem de caixas/sacos
      </h1>
      <Image src={ImgConference} />
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={openScanner}
        style={{ marginBottom: "10px" }}
      >
        Bipar Lote
      </Button>
    </Box>
  );

  const childModalScanner = () => (
    <ModalScanner
      isOpen={openScan}
      onClose={resetView}
      onBack={() => setView(state => --state)}
      onNext={handleScannerOnNext}
      processQRCodeData={setBatchData}
    />
  );

  const childModalCount = () => (
    <ModalCount
      onBack={() => setView(state => --state)}
      onClose={resetView}
      isOpen={openModalCount}
      data={batchData}
    />
  );

  const children = {
    0: childDefaultPage,
    1: childModalScanner,
    2: childModalCount
  }[view];

  useEffect(() => {}, []);
  return (
    <ContainerPage id="back-to-top-anchor">
      <Box>
        <TitlePage style={{ fontSize: "2rem" }}>
          <span
            style={{
              maxWidth: "0.9rem",
              height: "3.125rem",
              display: "block",
              background: "#89BD23",
              borderRadius: "4px",
              marginRight: "10px"
            }}
          />
          Conferência
        </TitlePage>
      </Box>
      <Content>{children}</Content>
    </ContainerPage>
  );
};
