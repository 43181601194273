import { Box, styled } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import { IoIosQrScanner } from "react-icons/io";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const position = "initial !important";

const ContainerCam = styled(Box)(({ border, maxWidth = "230px" }) => ({
  width: maxWidth,
  height: maxWidth,
  overflow: "hidden",
  display: "flex",
  borderRadius: "20px",
  justifyContent: "center",
  position: "relative",
  zIndex: 1,
  margin: "0 auto",
  background: "#D9D9D9",
  "& > svg": {
    position: "absolute",
    width: `calc(${maxWidth} + 10%)`,
    height: `calc(${maxWidth} + 10%)`,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 3,
    opacity: 0.8
  },
  "& video": {
    width: `calc(${maxWidth} + 100px)`,
    borderRadius: "10px",
    height: "auto !important",
    position: position,
    margin: "0 auto"
  }
}));

const QRCode = ({ onSubmit, maxWidth, ...rest }) => {
  const [, setdisabledScanner] = useState(false);
  const [border, setBorder] = useState(false);
  const { addPopup } = usePopup();

  const middleSubmit = result => {
    if (!result || !result?.text) return;
    setdisabledScanner(state => {
      if (!state) {
        onSubmit(result?.text);
        setBorder(true);
        navigator.vibrate(200);
        setTimeout(() => {
          setdisabledScanner(false);
          setBorder(false);
        }, 1000);
      }
      return true;
    });
  };

  const onError = () => {
    addPopup({
      type: "error",
      title: "Erro ao ativar a camera do dispositivo"
    });
  };

  return (
    <ContainerCam border={border} maxWidth={maxWidth} {...rest}>
      <IoIosQrScanner color={border ? "#89bd23" : "#fff"} />
      <BarcodeScannerComponent
        onError={onError}
        onUpdate={(_, result) => {
          middleSubmit(result);
        }}
      />
    </ContainerCam>
  );
};

export default QRCode;
