import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class SeparatorService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getSeparator(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/user/${id}`;
    const response = await api.get(url, id);
    return response;
  }

  async getSeparators(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/user/`;
    const response = await api.get(url, { params });
    return response;
  }
}

const separatorService = new SeparatorService();
export default separatorService;
