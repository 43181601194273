import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    width: props => props.width || "0.8rem",
    height: props => props.width || "0.8rem",
    borderRadius: "50%",
    backgroundColor: props => props.color || "#89BD23",
    position: "relative",
    display: "flex"
  },
  circle: {
    width: props => props.width || "0.8rem",
    height: props => props.width || "0.8rem",
    position: "absolute",
    display: "block",
    borderRadius: "50%",
    backgroundColor: props => props.color || "#89BD23",
    opacity: 1,
    animation: "$scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32)",
    "&:nth-of-type(0)": {
      animationDelay: "0"
    },
    "&:nth-of-type(1)": {
      animationDelay: "1s"
    },
    "&:nth-of-type(2)": {
      animationDelay: "2s"
    },
    "&:nth-of-type(3)": {
      animationDelay: "3s"
    }
  },
  "@keyframes scaleIn": {
    from: { transform: "scale(.5,.5)", opacity: 0.9 },
    to: { transform: "scale(2.5, 2.5)", opacity: 0 }
  }
});
