import { Box, IconButton, styled } from "@material-ui/core";

export const UploadImageContainer = styled(Box)(({ images }) => ({
  display: "flex",
  gap: "1rem",
  padding: "0.5rem",
  flexWrap: "wrap",
  flex: images.length < 1 ? 1 : 0
}));

export const UploadImageContent = styled(Box)(({ images }) => {
  const hasImages = images?.length > 0;
  const { width, height } = hasImages
    ? { width: "8.125rem", height: "8.125rem" }
    : { width: "100%", height: "100%" };
  return {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    borderRadius: "0.5rem",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.75rem",
    cursor: "pointer",
    backgroundColor: "#E9EFF8",
    border: "1px dashed #3EA0DE",
    color: "#3EA0DE",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "1rem",
    height,
    width
  };
});

export const StyledIconButton = styled(IconButton)({
  "&.MuiIconButton-root": {
    position: "absolute",
    backgroundColor: "#F5F7F5",
    color: "#757575",
    width: "2rem",
    height: "2rem",
    top: "-10px",
    right: "-10px"
  }
});

export const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
  objectFit: "contain",
  overflow: "hidden",
  borderRadius: "0.5rem"
});
