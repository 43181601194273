import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";

import { usePopup } from "../../hooks/usePopup";
import depotService from "../../services/depotService";

const AutoCompletePackaging = props => {
  const { defaultValue, className, onChange, disabled } = props;
  const { addPopup } = usePopup();
  const [loadingSearchPackagings, setLoadingSearchPackagings] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const fetchPackagings = async () => {
    try {
      setLoadingSearchPackagings(true);
      const response = await depotService.listPackagingType();
      setOptions(response.data.results);
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao buscar embalagens",
        description: err.response?.data?.message ?? ""
      });
    } finally {
      setLoadingSearchPackagings(false);
    }
  };

  const fetchPackagingById = async id => {
    try {
      setLoadingSearchPackagings(true);
      const response = await depotService.getPackaging(id);
      setSelectedValue(response.data);
      onChange(null, response.data);
    } catch (err) {
      addPopup({
        type: "error",
        title: `Erro ao buscar embalagem ${id}`,
        description: err.response?.data?.message ?? ""
      });
    } finally {
      setLoadingSearchPackagings(false);
    }
  };

  useEffect(() => {
    if (typeof defaultValue === "number") {
      fetchPackagingById(defaultValue);
    } else {
      setSelectedValue(defaultValue);
      fetchPackagings();
    }
  }, [defaultValue]);

  return (
    <Autocomplete
      loading={loadingSearchPackagings}
      options={options}
      getOptionLabel={option => option.name}
      onChange={onChange}
      className={className}
      noOptionsText="Embalagem não encontrada"
      loadingText="Procurando embalagem..."
      value={selectedValue || null}
      defaultValue={selectedValue || null}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Embalagem"
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingSearchPackagings && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default AutoCompletePackaging;
