import { Box, Paper, Table, TableBody } from "@material-ui/core";
import { ButtonLink } from "components/ButtonLink";
import { CustomLineProgress } from "components/LineProgress";

import {
  BodyCell,
  BodyRow,
  DateTimeContent,
  HeadCell,
  StyledTableHead,
  TableContent
} from "../../style";

export const SubBatchTable = ({ subBatchData, loading }) => {
  return (
    <Box>
      {!!loading && <CustomLineProgress />}
      <TableContent component={Paper}>
        <Table stickyHeader>
          <StyledTableHead>
            <HeadCell>ID</HeadCell>
            <HeadCell>Código</HeadCell>
            <HeadCell>Recebimento</HeadCell>
            <HeadCell>Produto</HeadCell>
            <HeadCell>Classificação</HeadCell>
            <HeadCell>Quantidade</HeadCell>
            <HeadCell>Estágio</HeadCell>
            <HeadCell>Estoque</HeadCell>
            <HeadCell></HeadCell>
          </StyledTableHead>
          <TableBody>
            {subBatchData?.map(batch => (
              <BodyRow key={batch.id}>
                <BodyCell>{batch.id}</BodyCell>
                <BodyCell style={{ minWidth: "15rem" }}>
                  {batch.externalCode}
                </BodyCell>
                <BodyCell>
                  <DateTimeContent>
                    <Box>{batch.receiptDate}</Box>
                    <Box>{batch.receiptTime}</Box>
                  </DateTimeContent>
                </BodyCell>
                <BodyCell>{batch.productName}</BodyCell>
                <BodyCell>{batch.classificationName}</BodyCell>
                <BodyCell>{batch.subBatchQuantity}</BodyCell>
                <BodyCell>{batch.stageName}</BodyCell>
                <BodyCell>{batch.stockAbbreviation}</BodyCell>
                <BodyCell>
                  <ButtonLink to={`sub-lotes/${batch.id}`}>Detalhes</ButtonLink>
                </BodyCell>
              </BodyRow>
            ))}
          </TableBody>
        </Table>
      </TableContent>
    </Box>
  );
};
