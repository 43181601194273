import { CircularProgress } from "@material-ui/core";

import { StyledCardContent } from "./styles";

export const CardContent = ({ loading, children, onClick, flexDirection }) => {
  return (
    <StyledCardContent
      onClick={onClick}
      style={{ cursor: onClick && "pointer" }}
      flexDirection={flexDirection}
    >
      {loading ? (
        <CircularProgress style={{ margin: "0 auto" }} />
      ) : (
        <>{children}</>
      )}
    </StyledCardContent>
  );
};
