import { Box, Grid, RadioGroup } from "@material-ui/core";
import { Button } from "components";
import { ButtonRadio } from "shared";

import { ButtonsContent, StyledBox, Text } from "../../../style";

export const ActionView = ({ products, action, setAction, onNext }) => (
  <>
    <StyledBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text>Tipo de etiqueta</Text>
          <RadioGroup value={action} onChange={(_, value) => setAction(value)}>
            <Box
              display="flex"
              flexDirection="column"
              marginTop="1rem"
              style={{ gap: "1rem" }}
            >
              <ButtonRadio
                style={{ flex: 1 }}
                checked={action === "batch"}
                value="batch"
                label="Lote"
              />
              {!!products.length && (
                <ButtonRadio
                  style={{ flex: 1 }}
                  checked={action === "prePicking"}
                  value="prePicking"
                  label="Pré-picking / Caixa-pronta"
                />
              )}
            </Box>
          </RadioGroup>
        </Grid>
      </Grid>
    </StyledBox>
    <ButtonsContent
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      style={{ flexDirection: "row" }}
    >
      <Button
        style={{ width: "50%" }}
        variant="contained"
        onClick={onNext}
        fullWidth
      >
        Avançar
      </Button>
    </ButtonsContent>
  </>
);
