import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  dropzone: {
    position: "relative",
    height: "310px",
    background: "rgb(139,195,74,20%)",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "15px 10px",
    cursor: "pointer",
    width: "100%",
    maxWidth: "250px",
    border: "#8bc34a dashed 2px",
    outline: "none",
    textAlign: "center",
    color: "#a7b1eb",
    transition: "background 0.2s",
    "& p": {
      fontWeight: "bold"
    }
  },
  dragActive: { background: "rgb(167, 177, 235, 40%);" },
  dragAccept: { background: "rgb(139, 195, 74, 40%)" },
  dragReject: {
    color: "rgb(255, 0, 0, 40%)",
    border: "rgb(255, 0, 0, 40%) dashed 2px",
    background: "rgb(255, 0, 0, 10%)"
  },
  image: { width: "100%" },
  description: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%"
  }
});
