import { Box } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import batchServiceV3 from "services/batchServiceV3";

import { ButtonsContent, StyledBox, Text } from "../../style";
import {
  ConfirmContent,
  LabelConfirmQuality,
  LabelSampleQuantity
} from "./style";

export const ConfirmPage = ({
  typeOfMeasure,
  batchId,
  handleNext,
  subBatch,
  onBack
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addPopup } = usePopup();

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await batchServiceV3.batchReceipt(batchId, {
        blitz_step: 4,
        sub_batch: subBatch
      });
      handleNext();
    } catch (err) {
      addPopup({ type: "error", title: "Erro ao registrar classificação" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <StyledBox>
        <Text>Os dados inseridos estão corretos?</Text>
        {subBatch
          .filter(({ quantity }) => quantity)
          .map(item => (
            <ConfirmContent
              key={item.classification}
              style={{
                flexDirection: "row",
                justifyContent: "left",
                minHeight: "3.25rem",
                paddingInline: "1rem"
              }}
            >
              <LabelConfirmQuality>
                Quantidade total - {item.classification} ({typeOfMeasure}):
              </LabelConfirmQuality>
              <LabelSampleQuantity>{item.quantity}</LabelSampleQuantity>
            </ConfirmContent>
          ))}
      </StyledBox>
      <ButtonsContent>
        <Box>
          <Button
            variant="text"
            onClick={onBack}
            fullWidth
            startIcon={<NavigateBeforeIcon />}
            disabled={isLoading}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            isLoading={isLoading}
            fullWidth
          >
            Confirmar
          </Button>
        </Box>
      </ButtonsContent>
    </>
  );
};
