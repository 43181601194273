import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class SellerService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async postSeller(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/`;
    const response = await api.post(url, data);
    return response;
  }

  async postAddress(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/address`;
    const response = await api.post(url, data);
    return response;
  }

  async updateAddress(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/address/${data.id}`;
    const response = await api.put(url, data);
    return response;
  }

  async deleteAddress(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/address/${id}`;
    const response = await api.delete(url);
    return response;
  }

  async updateSeller(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/${data.id}`;
    const response = await api.patch(url, data);
    return response;
  }

  async deleteSeller(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/${id}`;
    const response = await api.delete(url);
    return response;
  }

  async getSellers(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/`;
    return await api.get(url, { params });
  }

  async getSellersDashboard(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/${id}/dashboard`;
    return await api.get(url);
  }
}

const sellerService = new SellerService();
export default sellerService;
