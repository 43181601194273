import * as Yup from "yup";

const validationDefault = {
  averageWeight: Yup.number()
    .positive("Quantidade media deve ser positivo")
    .typeError("Campo deve ser um número")
    .max(500, "Valor deve ser menor que 500")
    .required("Campo obrigatório"),
  samplePercentage: Yup.number()
    .positive("Valor deve ser positivo")
    .typeError("Campo deve ser um número")
    .max(100, "Valor deve ser menor que 100")
    .required("Campo obrigatório"),
  minSample: Yup.number()
    .integer("Valor deve ser inteiro")
    .positive("Valor deve ser positivo")
    .typeError("Campo deve ser um número")
    .max(400, "Valor deve ser menor que 400")
    .required("Campo obrigatório"),
  observation: Yup.string()
    .required("Observação é obrigatório")
    .typeError("Campo deve ser uma string")
    .max(255)
};

export const validationSchemaEdit = Yup.object().shape({
  ...validationDefault
});

export const validationSchemaCreate = Yup.object().shape(validationDefault);
