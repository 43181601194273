import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiPaper-root": {
      width: "100%",
      maxHeight: "80vh",
      maxWidth: ({ maxWidth = "46rem" }) => maxWidth,
      height: ({ height = "auto" }) => height,
      borderRadius: "0.5rem",
      margin: ({ fullScreen }) => (fullScreen ? "0 0.5rem" : "0"),
      [theme.breakpoints.down("xs")]: {
        maxHeight: "100vh"
      }
    }
  },
  container: {
    background: "#FFFFFF",
    width: "100%",
    padding: ({ pd = "0 2rem 2rem" }) => pd,
    borderRadius: "6px",
    height: "100%",
    overflowX: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: ({ pdMobile = "0 2rem 2rem" }) => pdMobile
    },
    "&::-webkit-scrollbar": {
      width: "8px",
      background: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#A1CA4F",
      borderRadius: "9px"
    }
  },
  containerTitle: {
    position: "sticky",
    top: "0",
    left: "0",
    width: "100%",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    backgroundColor: alpha("#FFFFFF", 0.72),
    zIndex: 5,
    padding: "1.5rem 0",
    paddingRight: "1rem",
    marginBottom: "0.8rem"
  },
  closeBtn: {
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translateY(-50%)",
    height: "2.5rem",
    width: "2.5rem",
    "& svg": {
      fontSize: "1.6rem"
    }
  },
  title: {
    fontFamily: '"Inter", sans-serif',
    fontSize: "1.25rem",
    fontWeight: 600,
    fontStyle: "normal",
    color: "#353535"
  }
}));
