import { useRef } from "react";

export const useDebaunce = ({ fn, delay }) => {
  const timeoutRef = useRef(null);

  function debaunceFn(...params) {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      fn(...params);
    }, delay);
  }

  return debaunceFn;
};
