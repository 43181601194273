import { Box } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";

import { Span, Strong } from "../MoveBatchDialog/styles";

export const LastMove = ({ ledgers }) => {
  const isFirstStored = useMemo(() => {
    if (ledgers.length <= 1) return true;

    const firstLedger = ledgers[0];

    const firstDateTimeCreated = firstLedger.created.slice(0, 16);
    const allHasSameCreatedDateTime = ledgers.every(
      ledger => ledger.created.slice(0, 16) === firstDateTimeCreated
    );
    return allHasSameCreatedDateTime;
  }, [ledgers]);

  return (
    <Box>
      <Span>Última movimentação: </Span>
      <Strong>
        {isFirstStored
          ? `Recebimento - ${ledgers[0]?.warehouseType.name}`
          : `${ledgers[1]?.warehouseType.name} ➔ ${ledgers[0]?.warehouseType.name}`}
      </Strong>
    </Box>
  );
};
