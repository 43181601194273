import { Box, TextField, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import {
  ContainerPage,
  Button,
  CustomLoading,
  CustomNoRowsOverlay,
  optionDataGrid,
  useDataGridStyles,
  TitlePage
} from "components";
import { usePopup } from "hooks/usePopup";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { FaDownload, FaFilter } from "react-icons/fa";
import stockHistoryService from "services/stockHistoryService";
import { useDebaunce, mapStatesString, mapType } from "utils";

import { Filter } from "./Components/Filter";
import { useStyles } from "./styles";

const columns = typeStock => {
  const formatValue = value => {
    return value.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };
  return [
    {
      field: "product_base_name",
      headerName: "Produto Base",
      width: 260,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "input_type_id",
      headerName: "Tipo de entrada",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueFormatter: params => {
        return mapType(params.value);
      }
    },
    {
      field: "sku",
      headerName: "SKU",
      width: 200,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "sku_name",
      headerName: "Nome do SKU",
      width: 200,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "stock",
      headerName: "Estoque",
      width: 200,
      type: "number",
      align: "center",
      headerAlign: "center",
      valueFormatter: params => {
        if (typeStock === "physical_stock" && params?.row?.physical) {
          return formatValue(params?.row?.physical);
        }
        if (typeStock === "storage_summary") {
          if (params?.row?.stock) {
            return formatValue(params?.row?.stock);
          }
          if (params?.row?.summary) {
            return formatValue(params?.row?.summary);
          }
        }
        return 0;
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueFormatter: params => {
        return mapStatesString(params?.value);
      }
    }
  ];
};

const typeStock = {
  physical_stock: "Estoque físico",
  storage_summary: "Estoque resumo"
};

const StockHistory = () => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const [popover, setPopover] = useState(() => {
    const date = new Date();

    return {
      isOpen: false,
      element: null,
      params: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate() - 1,
        hour: date.getHours(),
        stock: "physical_stock",
        status: "active"
      }
    };
  });
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });
  const stylesDataGrid = useDataGridStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [params, setParams] = useState({
    pageSize: 15,
    page: 1,
    search: ""
  });

  function handleSearch(search) {
    setParams(state => ({ ...state, page: 1, search }));
  }

  const handleChangeParams = newParams => {
    setParams(state => ({ ...state, ...newParams }));
  };

  const handleDownloadSummaryFisic = async () => {
    try {
      const { data } = await stockHistoryService.getStockHistoryDownload(
        popover.params
      );
      fileDownload(data, "Histórico_do_estoque.xls");
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao fazer download"
        });
      }
    }
  };

  const initialize = async () => {
    setIsLoading(true);
    try {
      const response = await stockHistoryService.getStockHistory(
        popover.params
      );
      const data = response.data[0][popover.params.stock];

      setRows(data.map((item, index) => ({ id: index, ...item })));
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const refresh = () => {
    initialize();
  };

  useEffect(() => {
    setRows([]);
    initialize();
  }, [popover.params]);

  return (
    <ContainerPage>
      <TitlePage>Histórico do estoque</TitlePage>

      <Box component="header" className={classes.header}>
        <Box className={classes.containerSearch}>
          <TextField
            onChange={e => debaunceSearch(e.target.value)}
            name="product-search"
            variant="outlined"
            fullWidth
            id="product-search"
            label="Pesquisa de produto"
            autoFocus
            autoComplete="off"
          />
          <Button
            startIcon={<FaFilter fontSize={25} />}
            variant="contained"
            size="large"
            onClick={e => {
              setPopover(state => ({
                ...state,
                isOpen: true,
                element: e.currentTarget
              }));
            }}
          >
            Filtro
          </Button>
          <Filter
            anchorEl={popover.element}
            isOpen={popover.isOpen}
            setFilter={setPopover}
            handleClose={() => setPopover({ isOpen: false, element: null })}
          />
        </Box>

        <Box className={classes.containerBtn}>
          <Button
            variant="contained"
            startIcon={<FaDownload />}
            className={classes.btn}
            onClick={handleDownloadSummaryFisic}
          >
            Download do histórico
          </Button>
        </Box>
      </Box>

      <Typography className={classes.titleStock}>
        {typeStock[popover.params.stock]}
      </Typography>

      <DataGrid
        className={stylesDataGrid.root}
        rows={rows
          .filter(value => {
            if (value?.status === popover.params.status) return true;
            if (popover.params.status === "active and suspended") return true;
            return false;
          })
          .filter(value => {
            if (params.search === "") return true;
            return value?.product_base_name
              .toLowerCase()
              .includes(params.search.toLowerCase());
          })}
        columns={columns(popover.params.stock)}
        pageSize={params.pageSize}
        disableSelectionOnClick
        autoHeight
        page={params.page - 1}
        rowsPerPageOptions={optionDataGrid.rowsPerPageOptions}
        onPageChange={newPage => handleChangeParams({ page: newPage.page + 1 })}
        onPageSizeChange={newPageSize =>
          handleChangeParams({ page: 1, pageSize: newPageSize.pageSize })
        }
        loading={isLoading}
        components={{
          LoadingOverlay: CustomLoading,
          NoRowsOverlay: () => <CustomNoRowsOverlay refresh={refresh} />
        }}
      />
    </ContainerPage>
  );
};

export default StockHistory;
