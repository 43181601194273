import { Box, Paper, Typography, styled } from "@material-ui/core";

export const CardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const CardContent = styled(Paper)(({ theme }) => ({
  display: "flex",
  width: "25.5rem",
  height: "8rem",
  padding: "2rem 1rem",
  gap: "0.5rem",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    flex: 1,
    width: "auto",
    height: "auto"
  }
}));

export const CardIcon = styled(Box)({
  display: "flex",
  width: "4rem",
  height: "4rem",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#89bd23",
  borderRadius: "50%",
  padding: "0.8rem",
  "& .MuiSvgIcon-root": {
    width: "100%",
    height: "auto",
    color: "white"
  }
});

export const TextContent = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "0.2rem"
});

export const CardTitle = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "1.1rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "1.37rem",
  letterSpacing: "0.01rem",
  color: "#353535"
});

export const CardDescription = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.35rem",
  letterSpacing: "0.01rem",
  color: "#757575"
});
