import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { Button } from "components";
import { useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MenuPopover } from "shared";

import { useStyles } from "./styles";

export const Filter = ({ isOpen, setFilter, handleFilter, ...rest }) => {
  const classes = useStyles();
  const [filterData, setFilterData] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    date.setMinutes(0);

    return {
      status: "active",
      hours: date,
      date: date,
      stock: "physical_stock"
    };
  });

  const handleClose = () => {
    setFilter(state => ({
      ...state,
      isOpen: false
    }));
  };

  const handleFilterData = newFilter => {
    setFilterData(state => ({ ...state, ...newFilter }));
  };

  const handleFilterDate = async () => {
    const params = {};

    if (filterData.date !== null) {
      params.year = filterData.date.getFullYear();
      params.month = filterData.date.getMonth() + 1;
      params.day = filterData.date.getDate();
    }

    if (filterData.hours !== null) {
      params.hour = filterData.hours.getHours();
    }

    setFilter(state => ({
      ...state,
      isOpen: false,
      params: {
        ...state.params,
        stock: filterData.stock,
        status: filterData.status,
        ...params
      }
    }));
  };

  return (
    <MenuPopover open={isOpen} onClose={handleClose} {...rest}>
      <Box className={classes.root}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel>Estoque</InputLabel>
          <Select
            label="Status"
            value={filterData.stock}
            onChange={e => handleFilterData({ stock: e.target.value })}
          >
            <MenuItem value="physical_stock">Físico</MenuItem>
            <MenuItem value="storage_summary">Resumo</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ width: "100%" }}>
          <InputLabel>Status do produto</InputLabel>
          <Select
            label="Status"
            value={filterData.status}
            onChange={e => handleFilterData({ status: e.target.value })}
          >
            <MenuItem value="active and suspended">Ativo e Suspenso</MenuItem>
            <MenuItem value="active">Ativo</MenuItem>
            <MenuItem value="suspended">Suspenso</MenuItem>
          </Select>
        </FormControl>

        <KeyboardDatePicker
          label="Data"
          value={filterData.date}
          onChange={date => handleFilterData({ date })}
          format="MM/dd/yyyy"
        />

        <KeyboardTimePicker
          keyboardIcon={<AiOutlineClockCircle />}
          ampm={false}
          label="Hora"
          views={["hours"]}
          mask="__:__ Hrs"
          value={filterData.hours}
          onChange={hours => {
            hours.setMinutes(0);
            handleFilterData({ hours });
          }}
        />

        <Button onClick={handleFilterDate}>Filtrar</Button>
      </Box>
    </MenuPopover>
  );
};
