import { Box, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Button } from "components";

import { BoxButton, Text } from "../style";

export const FormView = ({
  action,
  tagQty,
  setTagQty,
  product,
  setProduct,
  products,
  onNext,
  onBack
}) => {
  const isPrePicking = action === "prePicking";

  const handleChangeTagQty = e => {
    const { value: inputValue } = e.target;
    const value = parseInt(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setTagQty(isValid ? value : null);
  };

  const handleChangeProduct = ({ target: { value } }) => {
    setProduct(products.find(({ sku }) => sku === value));
  };

  const isValid = !!tagQty && (isPrePicking ? !!product : true);

  return (
    <>
      <Box display="flex" flexDirection="column" style={{ gap: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Text style={{ marginBottom: "0.5rem" }}>
              Quantas etiquetas gostaria de imprimir?
            </Text>
            <TextField
              variant="outlined"
              type="number"
              onChange={handleChangeTagQty}
              value={tagQty}
              label={`Quantidade`}
              fullWidth
            />
          </Grid>
          {isPrePicking && (
            <Grid item xs={12}>
              <Text style={{ marginBottom: "0.5rem" }}>
                Qual é o porcionamento(SKU)?
              </Text>

              <Select
                variant="outlined"
                fullWidth
                value={product?.sku}
                onChange={handleChangeProduct}
              >
                {products.map(item => (
                  <MenuItem key={item.id} value={item.sku}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        </Grid>
      </Box>
      <BoxButton>
        <Button onClick={onBack} fullWidth startIcon={<NavigateBeforeIcon />}>
          Voltar
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          disabled={!isValid}
          fullWidth
        >
          Avançar
        </Button>
      </BoxButton>
    </>
  );
};
