import {
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { useDebaunce } from "utils";

import sellerService from "../../services/sellerService";

const AutoCompleteSellerFormik = ({
  className,
  formik,
  name,
  onChange,
  label,
  disabled
}) => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const value = formik.values[name];
  const hasError =
    (formik.touched[name] || formik.submitCount > 0) && !!formik.errors[name];

  const handleProductBaseData = async (search = undefined) => {
    if (disabled) return;

    try {
      setLoading(true);
      const { data } = await sellerService.getSellers({
        page: 1,
        pageSize: 15,
        search
      });

      setOptions(data.results);
    } catch (err) {
      if (err?.response?.status) {
        addPopup({
          type: "error",
          title: "Desculpe, ocorreu um erro",
          description: "Erro ao pesquisar produto base."
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const debaunce = useDebaunce({ fn: handleProductBaseData, delay: 500 });

  const handleProductBase = (e, value) => debaunce(value?.trim());

  useEffect(() => {
    if (disabled) return;
    handleProductBaseData();
  }, []);

  return (
    <FormControl
      fullWidth
      disabled={disabled || formik?.isSubmitting}
      error={hasError}
    >
      <Autocomplete
        loading={loading}
        options={options}
        getOptionLabel={option => option.name}
        getOptionSelected={(item, current) => item?.value === current?.value}
        className={className}
        noOptionsText="Fornecedor não encontrado"
        loadingText="Procurando fornecedor..."
        value={value}
        disabled={disabled || formik?.isSubmitting}
        onChange={(_, value) => {
          formik.setFieldValue(name, value);
          onChange && onChange(value);
        }}
        onBlur={formik.handleBlur}
        onInputChange={handleProductBase}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label || "Fornecedor"}
            error={hasError}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
      <FormHelperText>{hasError && formik.errors[name]}</FormHelperText>
    </FormControl>
  );
};

export default AutoCompleteSellerFormik;
