import { MS_API_BACKEND_URL } from "../settings";
import { api } from "./api";

class SelectionGroups {
  constructor() {
    this.url = MS_API_BACKEND_URL;
  }

  async getGroups(params) {
    const url = `${this.url}/api/selection-groups/v1/group`;
    const { data } = await api.get(url, { params });
    return data;
  }

  async getProductBaseConfig(params) {
    const url = `${this.url}/api/selection-groups/v1/product-base-config`;
    const { data } = await api.get(url, { params });
    return data;
  }

  async createGroup(data) {
    const url = `${this.url}/api/selection-groups/v1/group`;
    return await api.post(url, data);
  }

  async updateGroup(id, data) {
    const url = `${this.url}/api/selection-groups/v1/group/${id}`;
    return await api.patch(url, data);
  }

  async createProductBaseConfig(groupId, data) {
    const url = `${this.url}/api/selection-groups/v1/group/${groupId}/product-base-config`;
    return await api.post(url, data);
  }

  async updateProductBaseConfig(groupId, data) {
    const url = `${this.url}/api/selection-groups/v1/group/${groupId}/product-base-config`;
    return await api.patch(url, data);
  }
}

const selectionGroupsService = new SelectionGroups();
export default selectionGroupsService;
