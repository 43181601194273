import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class StorageFisicService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getAllStorageFisic(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/physical-stock`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        results: data,
        total_count: data.length
      }
    };
  }

  async storageFisicUpdate(model) {
    const url = `${this.url}/api/blueberry/v1/warehouse/physical-stock/${model.id}`;
    return await api.put(url, model);
  }

  async getSummaryFisicDownload() {
    const url = `${this.url}/api/blueberry/v1/warehouse/physical-stock/download`;
    return await api.get(url, {
      responseType: "blob",
      params: {
        export: "xls"
      }
    });
  }

  async summaryMigration(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/migration`;
    return await api.put(url, data);
  }

  async registerExplanation(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/explanation`;
    return await api.post(url, data);
  }
}

const storageFisicService = new StorageFisicService();
export default storageFisicService;
