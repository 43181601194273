import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class DepotBlueberryService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getDepotBatches(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/depot/batches`;
    return await api.get(url, {
      params
    });
  }
}

const depotBlueberryService = new DepotBlueberryService();
export default depotBlueberryService;
