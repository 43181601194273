export const columns = [
  {
    label: "Seller",
    align: "left"
  },
  {
    label: "Data",
    align: "left"
  }
];

export const childrenColumns = [
  {
    id: 0,
    label: "Produto Base",
    align: "left"
  },
  {
    id: 1,
    label: "Horário",
    align: "center"
  },
  {
    id: 2,
    label: "Previsão",
    align: "center"
  },
  {
    id: 3,
    label: "Recebido",
    align: "center"
  },
  {
    id: 4,
    label: "Devolvido",
    align: "center"
  },
  {
    id: 5,
    label: "Entrada",
    align: "center"
  },
  {
    id: 6,
    label: "Qntd NF",
    align: "center"
  },
  {
    id: 7,
    label: "Nota fiscal",
    align: "center"
  },
  {
    id: 8,
    label: "Obs",
    align: "center"
  },
  {
    id: 9,
    label: "Status",
    align: "center"
  },
  {
    id: 10,
    label: "",
    align: "center"
  }
];
