import {
  Box,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  styled
} from "@material-ui/core";
import { TableRow } from "shared";
import { Search } from "shared/Search";

export const CustomTableContainer = styled(TableContainer)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
});

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    justifyContent: "center"
  }
}));

export const TableContent = styled(TableContainer)({
  display: "flex",
  flexDirection: "column",
  height: "32rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "4px",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "9px"
  }
});

export const SearchInput = styled(Search)(({ theme }) => ({
  width: "100%",
  maxWidth: "21.5rem",
  height: "3.5rem",
  marginTop: "2.5rem",
  marginLeft: "2.5rem",
  [theme.breakpoints.between(0, 700)]: {
    marginLeft: 0
  }
}));

export const ConfirmButton = styled(Button)({
  minWidth: "4rem",
  minHeight: "2rem",
  color: "#ffffff",
  backgroundColor: "#89bd23",
  borderRadius: "0.5rem",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1rem",
  letterSpacing: "0.02857em",
  "&:hover": {
    backgroundColor: "#68901a"
  }
});

export const StyledTableHead = styled(TableHead)({
  background: "#F3F8E6"
});

export const HeadCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem",
  background: "#F3F8E6"
});

export const BodyRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const BodyCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem"
});

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});
