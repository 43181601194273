import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { useTableParams } from "hooks/useTableParams";
import depotService from "services/depotService";
import { Table } from "shared";

import { columns } from "./columns";

export const DepotLedger = () => {
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => depotService.listLedgers(params),
      paramsDefault: { orderDirection: "-created" }
    });

  return (
    <ContainerPage>
      <Box>
        <TitlePage>Extrato de armazéns</TitlePage>
      </Box>

      <Table
        handleSearch={handleSearch}
        columns={columns}
        data={data}
        onChange={onChange}
        params={params}
        status={status}
        count={count}
        refresh={refresh}
      />
    </ContainerPage>
  );
};
