import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class PdfService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async downloadPDF(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/product-base/pdf-download`;
    return await api.get(url, { responseType: "blob", params: params });
  }
}

const pdfService = new PdfService();
export default pdfService;
