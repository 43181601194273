import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import CachedIcon from "@material-ui/icons/Cached";
import PersonIcon from "@material-ui/icons/Person";
import { TimelineConnector, TimelineSeparator } from "@material-ui/lab";
import { ContainerPage, TitlePage } from "components";
import { usePopup } from "hooks/usePopup";
import { CreatedDateTimeContent } from "pages/private/ProductBatch/style";
import { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import batchService from "services/batchService";
import depotService from "services/depotService";

import {
  BatchIdContent,
  BoxValue,
  Content,
  ContentBox,
  ContentBoxValues,
  CreatedByContent,
  HistoryContent,
  StyledLink
} from "./styles";

const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return {
    date,
    time
  };
};

export const HistoryStoredBatches = () => {
  const { addPopup } = usePopup();
  const { packagingTypeId, batchId } = useParams();
  const [data, setData] = useState([]);
  const [dataBatch, setDataBatch] = useState([]);
  const search = new URLSearchParams(location.search).get("search");

  const getLedgersByBatchId = async () => {
    try {
      const response = await depotService.listLedgers({
        pageSize: 1000,
        batchId
      });

      const dataFormated = response.data?.results
        .sort((a, b) => new Date(a.created) - new Date(b.created))
        .reduce((acc, item, index, list) => {
          if (index % 2) return acc;
          return [
            ...acc,
            {
              oldValue: item,
              newValue: list[index + 1] || (list.length === 1 ? item : null)
            }
          ];
        }, []);

      setData(dataFormated);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: `Erro ao carregar histórico do lote ${batchId}`,
        description: errorMessage || "Erro ao buscar dados de histórico"
      });
    }
  };

  const getBatchData = async () => {
    try {
      const response = await batchService.getBatchDetail(batchId);
      setDataBatch(response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: `Erro ao carregar dados do lote ${batchId}`,
        description: errorMessage || "Erro ao buscar dados de lote"
      });
    }
  };

  useEffect(() => {
    getBatchData();
    getLedgersByBatchId();
  }, []);

  const firstDate = data[0]?.oldValue?.created.substring(0, 19);

  return (
    <ContainerPage>
      <Breadcrumbs separator={<KeyboardArrowRight />}>
        <StyledLink to={`/app/lotes-armazenados?batchSearch=${search}`}>
          Lotes Armazenados
        </StyledLink>
        <TitlePage>
          <BatchIdContent>Histórico / Lote {batchId}</BatchIdContent>
        </TitlePage>
      </Breadcrumbs>

      {data
        .filter(item => item?.oldValue?.created.substring(0, 19) === firstDate)
        .map((dataFormated, index) => {
          return (
            <>
              <HistoryContent key={dataFormated.id}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <CreatedDateTimeContent>
                    <Typography variant="body2" color="textSecondary">
                      {formatDateTime(dataFormated.oldValue.created).date}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {formatDateTime(dataFormated.oldValue.created).time}
                    </Typography>
                  </CreatedDateTimeContent>
                  <CreatedByContent color="textSecondary">
                    <PersonIcon />
                    {dataFormated.oldValue.createdByName}
                  </CreatedByContent>
                </Box>
                <TimelineSeparator style={{ gap: "1rem" }}>
                  <FaCircle color="#8146FF" size={24} />
                  <TimelineConnector />
                </TimelineSeparator>
                <Content>
                  <Box style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                    <Typography>Movimentação</Typography>
                  </Box>
                  <ContentBox>
                    <BoxValue style={{ background: "#F5F6F7" }}>
                      Recebimento
                    </BoxValue>
                    <CachedIcon />
                    <Box display="flex" flexDirection="column">
                      <BoxValue style={{ background: "#E9DEFE" }}>
                        {dataFormated?.newValue?.warehouseType.name}{" "}
                      </BoxValue>
                    </Box>
                  </ContentBox>
                </Content>
              </HistoryContent>
              <HistoryContent key={dataFormated.id}>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <CreatedDateTimeContent>
                    <Typography variant="body2" color="textSecondary">
                      {formatDateTime(dataFormated.oldValue.created).date}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {formatDateTime(dataFormated.oldValue.created).time}
                    </Typography>
                  </CreatedDateTimeContent>
                  <CreatedByContent color="textSecondary">
                    <PersonIcon />
                    {dataFormated.oldValue.createdByName}
                  </CreatedByContent>
                </Box>
                <TimelineSeparator style={{ gap: "1rem" }}>
                  <FaCircle color="#208B9B" size={24} />
                  <TimelineConnector />
                </TimelineSeparator>
                <Content>
                  <Box style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                    <Typography>Conversão</Typography>
                  </Box>
                  <ContentBox>
                    <BoxValue style={{ background: "#F5F6F7" }}>
                      {`${dataBatch.box_quantity} ${
                        dataBatch.box_quantity > 1 ? "unidades" : "unidade"
                      }`}
                    </BoxValue>
                    <CachedIcon />
                    <ContentBoxValues>
                      <BoxValue style={{ background: "#C6E8ED" }}>
                        {Math.abs(dataFormated?.oldValue.quantity)}{" "}
                        {dataFormated?.oldValue?.packagingType.name}{" "}
                      </BoxValue>
                      <BoxValue style={{ background: "#C6E8ED" }}>
                        {Math.abs(dataFormated?.newValue?.quantity)}{" "}
                        {dataFormated?.newValue?.packagingType.name}{" "}
                      </BoxValue>
                    </ContentBoxValues>
                  </ContentBox>
                </Content>
              </HistoryContent>
            </>
          );
        })}

      {data
        .filter(item => item?.oldValue?.created.substring(0, 19) !== firstDate)
        .map((dataFormated, index) => {
          const isPackagingTypeId =
            dataFormated.oldValue?.packagingType.id ===
              Number(packagingTypeId) ||
            dataFormated.newValue?.packagingType.id === Number(packagingTypeId);
          const reasonIds = [3, 4, 5, 6];
          const isClassification =
            reasonIds.includes(dataFormated.oldValue.reason.id) ||
            reasonIds.includes(dataFormated?.newValue?.reason.id);

          const isEqualId =
            dataFormated?.oldValue?.id === dataFormated?.newValue?.id;

          if (!isClassification && (!isPackagingTypeId || isEqualId)) {
            return null;
          }

          const isDifferentWarehouseType =
            dataFormated.oldValue.warehouseType?.name !==
            dataFormated?.newValue?.warehouseType?.name;
          const previousValue = index > 0 ? data[index - 1].newValue : null;

          return (
            <HistoryContent key={dataFormated.id}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <CreatedDateTimeContent>
                  <Typography variant="body2" color="textSecondary">
                    {formatDateTime(dataFormated.oldValue.created).date}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {formatDateTime(dataFormated.oldValue.created).time}
                  </Typography>
                </CreatedDateTimeContent>
                <CreatedByContent color="textSecondary">
                  <PersonIcon />
                  {dataFormated.oldValue.createdByName}
                </CreatedByContent>
              </Box>
              <TimelineSeparator style={{ gap: "1rem" }}>
                <FaCircle
                  color={
                    isDifferentWarehouseType && !isClassification
                      ? "#8146FF"
                      : isClassification
                      ? "#F79400"
                      : "#208B9B"
                  }
                  size={24}
                />
                <TimelineConnector />
              </TimelineSeparator>
              {isClassification ? (
                <Content
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Box style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                    <Typography>Classificação</Typography>
                  </Box>
                  <ContentBox
                    style={{
                      marginTop: dataFormated.newValue ? "-3rem" : "-1.5rem"
                    }}
                  >
                    <BoxValue style={{ background: "#F5F6F7" }}>
                      {Math.abs(dataFormated.oldValue.quantity)}{" "}
                      {dataFormated.oldValue?.packagingType.name}
                      {` - ${dataFormated.oldValue?.reason.name}`}
                    </BoxValue>
                  </ContentBox>
                  <ContentBox>
                    {dataFormated.newValue && (
                      <BoxValue style={{ background: "#F5F6F7" }}>
                        {Math.abs(dataFormated.newValue.quantity)}{" "}
                        {dataFormated.newValue?.packagingType.name}
                        {` - ${dataFormated.newValue?.reason.name}`}
                      </BoxValue>
                    )}
                  </ContentBox>
                </Content>
              ) : (
                <Content>
                  <Box style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                    <Typography>
                      {isDifferentWarehouseType ? "Movimentação" : "Conversão"}
                    </Typography>
                  </Box>
                  <ContentBox>
                    <BoxValue style={{ background: "#F5F6F7" }}>
                      {Math.abs(dataFormated.oldValue.quantity)}{" "}
                      {dataFormated.newValue
                        ? dataFormated.oldValue.packagingType.name
                        : previousValue?.packagingType.name}{" "}
                      {isDifferentWarehouseType &&
                        `- ${
                          dataFormated.newValue
                            ? dataFormated.oldValue.warehouseType.name
                            : previousValue?.warehouseType.name
                        }`}
                    </BoxValue>
                    <CachedIcon />
                    <BoxValue
                      style={{
                        background: isDifferentWarehouseType
                          ? "#E9DEFE"
                          : "#C6E8ED"
                      }}
                    >
                      {Math.abs(
                        dataFormated.newValue
                          ? dataFormated.newValue.quantity
                          : dataFormated.oldValue.quantity
                      )}{" "}
                      {dataFormated.newValue
                        ? dataFormated.newValue.packagingType.name
                        : dataFormated.oldValue.packagingType.name}{" "}
                      {isDifferentWarehouseType &&
                        `- ${
                          dataFormated.newValue
                            ? dataFormated.newValue.warehouseType.name
                            : dataFormated.oldValue.warehouseType.name
                        }`}
                    </BoxValue>
                  </ContentBox>
                </Content>
              )}
            </HistoryContent>
          );
        })}
    </ContainerPage>
  );
};
