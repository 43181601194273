import { Box } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import batchServiceV3 from "services/batchServiceV3";

import { ButtonsContent, StyledBox, Text } from "../../style";
import {
  ConfirmContent,
  LabelConfirmQuality,
  LabelSampleQuantity
} from "./style";

const formatNumber = value =>
  (value || 0).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3
  });

const isInvalidDiff = (receiptQty, confirmedQuantity) => {
  const maxPercentageDiff = 5;
  const maxQtyDiff = 50;

  const diff = Math.abs(Number(confirmedQuantity) - Number(receiptQty));

  const diffPercentage = Math.abs((diff / confirmedQuantity) * 100);

  const isInvalidPercentage = diffPercentage > maxPercentageDiff;
  const isInvalidQty = diff > maxQtyDiff;

  const isInvalid = diff && (isInvalidPercentage || isInvalidQty);

  return isInvalid;
};

export const ConfirmPage = ({
  batchData,
  receiptQty,
  confirmedQuantity,
  typeOfMeasure,
  hasBlitz,
  setUpdatedBatch,
  handleNext,
  setLoading,
  onBack,
  boxQty
}) => {
  const { addPopup } = usePopup();
  const formattedWeight = formatNumber(receiptQty);
  const quantity = typeOfMeasure === "Kg" ? formattedWeight : receiptQty;

  const hasInvalidDiff = isInvalidDiff(receiptQty, confirmedQuantity);

  const receiveBatch = async () => {
    try {
      setLoading(true);
      const data = {
        quantity: receiptQty,
        stage: 3,
        status: 2,
        box_quantity: boxQty
      };

      const action = {
        true: () =>
          batchServiceV3.batchReceipt(batchData.id, { ...data, blitz_step: 5 }),
        false: () =>
          batchServiceV3.batchReceipt(batchData.id, {
            ...data,
            blitz_step: 2,
            sub_batch: ["A", "B", "C"].map(classification => ({
              id: batchData?.sub_batch?.find(
                subBatch => subBatch.classification === classification
              )?.id,
              classification,
              quantity: 0,
              image: null
            }))
          })
      }[hasBlitz];

      const { data: resp } = await action();
      setUpdatedBatch(resp);
      handleNext();
      addPopup({
        type: "success",
        title: "Lote recebido",
        description: `O lote ${batchData.id} foi recebido com sucesso`
      });
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro atualizar lote",
        description:
          "Não foi possível receber o lote, tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledBox>
        <Text>Os dados inseridos estão corretos?</Text>
        <ConfirmContent
          style={{
            flexDirection: "column",
            justifyContent: "left",
            minHeight: "3.25rem",
            paddingInline: "1rem"
          }}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <LabelConfirmQuality>
              Quantidade total ({typeOfMeasure}):
            </LabelConfirmQuality>
            <LabelSampleQuantity>{quantity}</LabelSampleQuantity>
            <LabelConfirmQuality>Quantidade caixas:</LabelConfirmQuality>
            <LabelSampleQuantity>{boxQty}</LabelSampleQuantity>
          </Box>
          <LabelConfirmQuality style={{ marginTop: "2rem" }}>
            Peso/Un médio por caixa:
          </LabelConfirmQuality>
          <LabelSampleQuantity>
            {(quantity / boxQty).toFixed(1)}
          </LabelSampleQuantity>
        </ConfirmContent>
        {!!hasInvalidDiff && (
          <Text style={{ fontSize: "1rem", color: "#EB001B" }}>
            A quantidade recebida informada é inferior/superior à quantidade
            informada pelo faturamento. Por favor, avise o seu supervisor.
          </Text>
        )}
      </StyledBox>

      <ButtonsContent>
        <Box>
          <Button onClick={onBack} fullWidth startIcon={<NavigateBeforeIcon />}>
            Voltar
          </Button>
          <Button
            variant="contained"
            onClick={receiveBatch}
            fullWidth
            disabled={hasInvalidDiff}
          >
            Confirmar
          </Button>
        </Box>
      </ButtonsContent>
    </>
  );
};
