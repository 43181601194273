import { Typography, RadioGroup, Box, TextField } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useState } from "react";
import { InfoCard } from "shared";

import { Button, ButtonRadio } from "../style";

export const ActionView = ({
  option,
  setOption,
  onNext,
  batch,
  setComment,
  comment
}) => {
  const [open, setOpen] = useState(false);
  const showButtons =
    batch?.refusalInstructions &&
    (batch?.refusalInstructions.includes("3") ||
      batch?.refusalInstructions.includes("4"));
  const label = batch?.refusalInstructions;

  const handleClose = () => {
    setOpen(false);
  };

  const btns = {
    4: (
      <>
        {showButtons && (
          <ButtonRadio
            checked={option === "returnBatch"}
            value="returnBatch"
            label={`${label?.substring(3)}`}
            onClick={handleClose}
          />
        )}
        <ButtonRadio
          checked={option === "next"}
          value="next"
          label="Aceitar assim mesmo, seguir para próxima etapa"
          onClick={() => setOpen(true)}
        />
      </>
    )
  }[batch?.status?.id];

  const reasonQuantity = {
    Qualidade: "Qualidade",
    Peso: "Peso médio"
  }[batch.reasonName];

  return (
    <>
      <InfoCard
        data={{
          ID: {
            text: String(batch?.id),
            useStrong: true
          },
          Produto: { text: batch?.product },
          Seller: { text: batch?.seller },
          Motivo: { text: batch?.reasonName },
          [reasonQuantity]: { text: batch?.reasonQuantity }
        }}
      />
      <Typography
        style={{
          color: "#757575",
          fontFamily: "Inter",
          margin: "1.5rem 0 1rem"
        }}
      >
        O que deseja fazer
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        justifyContent="space-between"
      >
        <RadioGroup value={option} onChange={(_, value) => setOption(value)}>
          {btns}
          {open && (
            <>
              <TextField
                label="Escreva aqui o motivo do recebimento"
                multiline
                rows={2}
                variant="outlined"
                value={comment}
                onChange={e => setComment(e.target.value)}
                style={{ marginTop: "5px", marginBottom: "5px" }}
              />
            </>
          )}
          <ButtonRadio
            checked={option === "reset"}
            value="reset"
            label="Resetar etapas"
            onClick={handleClose}
          />
        </RadioGroup>
        <Box display="flex" justifyContent="flex-end" marginTop="1rem">
          <Button
            style={{ width: "48%" }}
            variant="contained"
            endIcon={<NavigateNextIcon fontSize="large" />}
            disabled={!option || (option === "next" && !comment)}
            onClick={onNext}
          >
            Avançar
          </Button>
        </Box>
      </Box>
    </>
  );
};
