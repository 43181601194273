/* eslint-disable react/display-name */
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow
} from "@material-ui/core";
import { forwardRef } from "react";
import { TableVirtuoso } from "react-virtuoso";

import { BodyCell, HeadCell, StyledTableHead, TableContent } from "../../style";

const TableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContent component={Paper} {...props} ref={ref} />
  )),
  Table: props => (
    <Table stickyHeader {...props} style={{ borderCollapse: "separate" }} />
  ),
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />)
};

export const SummaryStockTable = ({ stockData, search }) => {
  const TableHeader = () => (
    <StyledTableHead>
      <HeadCell width={"10rem"}>SKU</HeadCell>
      <HeadCell width={"25rem"}>Nome</HeadCell>
      <HeadCell width={"15rem"} align="center">
        ID Produto base
      </HeadCell>
      <HeadCell width={"10rem"} align="center">
        Margem
      </HeadCell>
      <HeadCell align="right">Estoque</HeadCell>
    </StyledTableHead>
  );

  const filteredData = !search
    ? stockData
    : (stockData || [])?.filter(
        stock =>
          stock.sku_name?.toLowerCase().includes(search.toLowerCase().trim()) ||
          stock.sku?.includes(search.trim())
      );

  return (
    <TableVirtuoso
      style={{ height: 500 }}
      data={filteredData}
      components={{
        ...TableComponents,
        TableHead: TableHeader
      }}
      fixedHeaderContent={() => <TableHeader />}
      itemContent={(_, stock) => (
        <>
          <BodyCell>{stock.sku}</BodyCell>
          <BodyCell>{stock.sku_name}</BodyCell>
          <BodyCell align="center">{stock.product_base_id}</BodyCell>
          <BodyCell align="center">{stock.margin}</BodyCell>
          <BodyCell align="right">{stock.available}</BodyCell>
        </>
      )}
    />
  );
};
