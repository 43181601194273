export const reasonMap = value => {
  const text =
    {
      buy: "Compra",
      sell: "Venda",
      "return seller": "Devolução para o fornecedor",
      "return CD": "Devolução para o CD",
      "warehouse waste": "Waste",
      "operation waste": "Operação",
      others: "Outros",
      "prediction confirmed": "Previsão confirmada",
      "prediction correction": "Previsão corrigida",
      ifood: "IFood",
      predicted: "Previsto",
      "client restitution": "Reposição para o cliente",
      "stock adjustment": "Ajuste de estoque",
      "physical stock adjustment": "Ajuste de estoque físico",
      "storage summary adjustment": "Ajuste de estoque resumo",
      "stock migration": "Migração de estoque",
      "evaluate restitution": "Avaliar devolução",
      "operational waste": "Waste operacional",
      "waste customer return": "Waste devolução cliente",
      "waste return seller": "Waste devolução fornecedor",
      lock: "Bloquear estoque",
      unlock: "Desbloquear estoque",
      "pre extra order": "Pré-extra order",
      "ajuste extra order": "Ajuste extra order"
    }[value] || value;
  return text;
};
