import { CircularProgress } from "@material-ui/core";

import {
  ActionContent,
  BatchInfoContent,
  BatchInfoSubText,
  BatchInfoText,
  ButtonsContent,
  LoadingContent,
  StyledButton,
  StyledDialog
} from "../../style";

export const FinishPopup = ({
  openFinish,
  handleCloseFinish,
  handleFinish,
  data,
  loading,
  handleReset,
  handleOpenPopupDeadline
}) => {
  const textDescription = loading
    ? "Confirmando, aguarde..."
    : "Deseja confirmar a devolução ?";

  const isReposition = data.stageId === 13;

  const actionContent = {
    true: (
      <LoadingContent>
        <CircularProgress color="primary" />
      </LoadingContent>
    ),
    false: (
      <ButtonsContent>
        {isReposition ? (
          <StyledButton
            variant="contained"
            fullWidth
            name="updateBtn"
            onClick={handleOpenPopupDeadline}
            style={{ textTransform: "none" }}
          >
            Alterar Prazo
          </StyledButton>
        ) : (
          <StyledButton
            variant="contained"
            fullWidth
            name="updateBtn"
            onClick={handleCloseFinish}
            style={{ textTransform: "none" }}
          >
            Cancelar
          </StyledButton>
        )}

        <StyledButton
          variant="contained"
          fullWidth
          name="updateBtn"
          onClick={handleReset}
          style={{ textTransform: "none" }}
        >
          Voltar p/ alinhamento
        </StyledButton>

        <StyledButton
          variant="contained"
          fullWidth
          name="confirmButton"
          onClick={() => handleFinish(3, 11)}
          style={{ textTransform: "none" }}
        >
          Confirmar
        </StyledButton>
      </ButtonsContent>
    )
  }[loading];
  return (
    <StyledDialog
      open={openFinish}
      handleClose={handleCloseFinish}
      title="Finalizar devolução"
    >
      <BatchInfoContent>
        <BatchInfoText>
          Lote: <strong>{data?.batchId}</strong>
        </BatchInfoText>
        <BatchInfoText>
          Produto: <span>{data?.productName}</span>
        </BatchInfoText>
        <BatchInfoText>
          Seller: <span>{data?.seller}</span>
        </BatchInfoText>
        <BatchInfoText>
          Instruções: <span>{data?.instructions}</span>
        </BatchInfoText>
      </BatchInfoContent>
      <ActionContent>
        <BatchInfoSubText>{textDescription}</BatchInfoSubText>
        {actionContent}
      </ActionContent>
    </StyledDialog>
  );
};
