import {
  styled,
  Box as BoxMui,
  alpha,
  Button as ButtonMui
} from "@material-ui/core";
import {
  Dialog as DialogCustom,
  ButtonRadio as ButtonRadioStyled
} from "shared";

export const Dialog = styled(DialogCustom)({
  "& .MuiPaper-root, & .MuiDialog-container": {
    width: "100%",
    maxWidth: "25.6875rem"
  }
});

export const Container = styled(BoxMui)(({ theme }) => ({
  minHeight: "29.4375rem",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.between(0, 900)]: {
    minHeight: "25rem"
  }
}));

export const Button = styled(ButtonMui)(({ flex }) => ({
  height: "3rem",
  padding: "0 2.91rem",
  flex,
  "&.MuiButton-text": {
    color: "#757575",
    "&:hover": {
      background: alpha("#000000", 0.1)
    }
  }
}));

export const ButtonRadio = styled(ButtonRadioStyled)({
  "label + &": {
    marginTop: "0.62rem"
  }
});
