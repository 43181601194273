import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { usePopup } from "hooks/usePopup";
import { useState, useEffect } from "react";
import depotService from "services/depotService";
import storageService from "services/storageService";
import { Search } from "shared/Search";
import { useDebaunce } from "utils";

import { Paginator } from "../AlignmentWithSeller/components/Paginator";
import { TablePBs } from "./Table";
import {
  packagingDataFormatter,
  productBaseDataFormatter,
  warehouseDataFormatter
} from "./utils/dataFormatter";

export const ConfigureProductsBase = () => {
  const { addPopup } = usePopup();
  const [productBaseData, setProductBaseData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [warehouseData, setWarehouseData] = useState([]);
  const [packagingData, setPackagingData] = useState([]);
  const [warehousePBConfigData, setWarehousePBConfigData] = useState([]);
  const [packagingPBConfigData, setPackagingPBConfigData] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const getInitData = async () => {
    setLoading(true);
    try {
      const [
        warehouseResp,
        packagingResp,
        warehousePBConfigResp,
        packagingPBConfigResp,
        productBaseResp
      ] = await Promise.all([
        depotService.listWarehouseTypes({
          paginate: false
        }),
        depotService.listPackagingType({
          paginate: false
        }),
        depotService.getWarehouseProductBaseConfig({
          paginate: false
        }),
        depotService.getPackagingProductBaseConfig({
          paginate: false
        }),
        storageService.getBaseProducts({
          page,
          pageSize: 20,
          status: "active",
          search: searchParams
        })
      ]);

      const warehouses = warehouseResp.data;
      setWarehouseData(warehouseDataFormatter(warehouses));

      const packages = packagingResp.data;
      setPackagingData(packagingDataFormatter(packages));

      const warehousePBConfig = warehousePBConfigResp.data;
      setWarehousePBConfigData(warehousePBConfig);

      const packagingPBConfig = packagingPBConfigResp.data;
      setPackagingPBConfigData(packagingPBConfig);

      const productsBase = productBaseResp.data?.results;
      setProductBaseData(
        productBaseDataFormatter(
          productsBase,
          warehousePBConfig,
          packagingPBConfig
        )
      );
      const pages = Math.floor(
        productBaseResp.data.total_count / productBaseResp.data.page_size
      );
      setTotalPages(pages);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao buscar dados iniciais",
        description:
          errorMessage || "Ocorreu um erro ao buscar os dados iniciais"
      });
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const getProductBaseData = async (pageNumber = page) => {
    setLoading(true);
    try {
      const { data } = await storageService.getBaseProducts({
        page: pageNumber,
        pageSize: 20,
        status: "active",
        search: searchParams
      });

      setProductBaseData(
        productBaseDataFormatter(
          data.results,
          warehousePBConfigData,
          packagingPBConfigData
        )
      );
      const pages = Math.floor(data.total_count / data.page_size);
      setTotalPages(pages);
    } catch (err) {
      if (err?.response?.status) {
        addPopup({
          type: "error",
          title: "Desculpe, ocorreu um erro",
          description: "Erro ao pesquisar produto base."
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const searchDebaunce = useDebaunce({ fn: handleSearch, delay: 300 });

  function handleSearch(value) {
    setProductBaseData([]);
    setPage(1);
    setTotalPages(0);
    setSearchParams(value);
  }

  const refreshData = async () => {
    setProductBaseData([]);
    await getInitData();
  };

  const handleChangePage = e => {
    const anchor = document.querySelector("#back-to-top-anchor");
    !!anchor && anchor.scrollIntoView({ behavior: "smooth" });

    const selectedPage = e.selected + 1;
    setPage(selectedPage);
    getProductBaseData(selectedPage);
  };

  useEffect(() => {
    !firstLoad && getProductBaseData();
  }, [searchParams]);

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <ContainerPage>
      <Box>
        <TitlePage>Configurar PBs</TitlePage>
        <Box style={{ marginBottom: "2rem" }}>
          <Search
            placeholder="Qual produto você busca?"
            onChange={e => searchDebaunce(e.target.value)}
            defaultValue={searchParams}
            autoFocus
          />
        </Box>
      </Box>

      <TablePBs
        loading={loading}
        productBaseData={productBaseData}
        warehouseData={warehouseData}
        refresh={refreshData}
        packagingData={packagingData}
      />
      <Paginator
        handleChangePage={handleChangePage}
        page={page}
        totalPages={totalPages}
        loading={loading}
      />
    </ContainerPage>
  );
};
