import { Typography } from "@material-ui/core";
import { ContainerPage } from "components";

import mirtilo from "./../../../assets/images/mirtilo.png";
import { Content, NotFoundText } from "./style";
export const NotFound = () => {
  return (
    <Content>
      <NotFoundText>Página não encontrada | 404</NotFoundText>
      <img src={mirtilo} alt="Mirtilo" />
    </Content>
  );
};
