import { Box, MenuItem, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { forwardRef, useImperativeHandle, useState } from "react";

import { AddPackageButton, RemovePackageIconButton } from "./styles";

export const Packaging = forwardRef(({ packagesData }, ref) => {
  Packaging.displayName = "Packaging";

  const [options, setOptions] = useState([
    { id: 1, packagingTypeId: null, quantity: null }
  ]);

  const handleAddOption = () => {
    const maxId = Math.max(...options.map(option => option.id), 0);
    const newId = maxId === -Infinity ? 1 : maxId + 1;
    const newOption = {
      id: newId,
      packagingTypeId: "",
      quantity: ""
    };
    setOptions([...options, newOption]);
  };

  const handleRemoveOption = optionId => {
    options.length > 1 &&
      setOptions(options.filter(({ id }) => id !== optionId));
  };

  const handleChangeType = (e, optionId) => {
    const { value } = e.target;
    const newOptions = options.map(option =>
      option.id === optionId
        ? { ...option, packagingTypeId: value, quantity: "" }
        : option
    );
    setOptions(newOptions);
  };

  const handleChangeQuantity = (e, optionId) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");

    const { packagingTypeId } = options.find(({ id }) => id === optionId);
    const isWeight = packagingTypeId === 1;
    const quantity = isWeight ? parseFloat(value, 10) : parseInt(value, 10);
    const isValid = (!isNaN(quantity) && quantity >= 0) || !quantity;

    setOptions(prev =>
      !isValid
        ? prev
        : prev.map(option =>
            option.id === optionId ? { ...option, quantity } : option
          )
    );
  };
  useImperativeHandle(ref, () => ({ options }));
  return (
    <Box
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      ref={ref}
    >
      {options.map(option => (
        <Box
          key={option.id}
          style={{
            display: "flex",
            flex: 1,
            gap: "1rem",
            alignItems: "center"
          }}
        >
          <TextField
            select
            variant="outlined"
            fullWidth
            name="package"
            label="Tipo"
            onChange={e => handleChangeType(e, option.id)}
          >
            {(packagesData || []).map(packageType => (
              <MenuItem key={packageType.id} value={packageType.id}>
                {packageType.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            fullWidth
            name="quantity"
            label="Quantidade"
            onChange={e => handleChangeQuantity(e, option.id)}
            type="number"
            value={option.quantity}
            disabled={!option.packagingTypeId}
          />

          <RemovePackageIconButton
            disabled={options.length <= 1}
            onClick={() => handleRemoveOption(option.id)}
            style={{ width: "2rem", height: "2rem" }}
          >
            <RemoveCircleIcon />
          </RemovePackageIconButton>
        </Box>
      ))}
      <AddPackageButton
        startIcon={<AddIcon />}
        fullWidth
        variant={"contained"}
        onClick={handleAddOption}
      >
        Adicionar embalagens
      </AddPackageButton>
    </Box>
  );
});
