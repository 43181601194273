import { Typography, Box, RadioGroup } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button } from "components";
import { ButtonRadio } from "shared";

export const ActionSubbatch = ({ action, setAction, onNext }) => (
  <>
    <Typography style={{ color: "#757575" }}>
      Para onde deseja mover o sublote?
    </Typography>

    <RadioGroup value={action} onChange={(_, value) => setAction(value)}>
      <Box
        display="flex"
        marginTop="1rem"
        flexDirection="column"
        style={{ gap: "1rem" }}
      >
        <ButtonRadio
          checked={action === "subBatchToSubBatch"}
          value="subBatchToSubBatch"
          label="Sublote"
        />
        <ButtonRadio
          checked={action === "subBatchToPrePicking"}
          value="subBatchToPrePicking"
          label="Pré-picking"
        />
        <ButtonRadio
          checked={action === "subBatchToReadyBox"}
          value="subBatchToReadyBox"
          label="Caixa pronta"
        />
        <ButtonRadio
          checked={action === "subBatchToWaste"}
          value="subBatchToWaste"
          label="Waste"
        />
      </Box>
    </RadioGroup>

    <Box
      display="flex"
      marginTop="0.9rem"
      justifyContent="flex-end"
      alignItems="flex-end"
      flex="1"
    >
      <Button
        variant="contained"
        endIcon={<NavigateNextIcon fontSize="large" />}
        style={{ width: "48%" }}
        disabled={!action}
        onClick={onNext}
      >
        Avançar
      </Button>
    </Box>
  </>
);
