import { TextField } from "@material-ui/core";
import { useFormik } from "formik";
import { usePopup } from "hooks/usePopup";
import depotService from "services/depotService";
import * as Yup from "yup";

import { Container, TextFieldWrapper, SaveButton } from "./style";

const validationSchema = Yup.object({
  name: Yup.string().min(1).max(100).required(),
  abbreviation: Yup.string().min(1).max(10).required()
});

export const Form = ({ initialValues, refresh, handleClose }) => {
  const { addPopup } = usePopup();

  const onSubmit = async model => {
    try {
      if (model.id) {
        await depotService.updateWarehouseType(model.id, model);
      } else {
        await depotService.createWarehouseType(model);
      }
      refresh();
      handleClose();
      addPopup({
        type: "success",
        title: "Armazém salvo com sucesso"
      });
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao salvar armazém"
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues ?? { name: "", abbreviation: "" },
    validationSchema,
    onSubmit
  });

  return (
    <Container component="form" onSubmit={formik.handleSubmit}>
      <div style={{ flex: 1 }}>
        <TextFieldWrapper item xs={12}>
          <TextField
            name="name"
            label="Nome do armazém"
            placeholder="Ex: Câmara Fria 1"
            value={formik.values.name}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.name || formik.submitCount > 0) &&
              !!formik.errors.name
            }
          />
        </TextFieldWrapper>
        <TextFieldWrapper item xs={12}>
          <TextField
            name="abbreviation"
            label="Abreviação"
            placeholder="Ex: CF - 1"
            value={formik.values.abbreviation}
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.abbreviation || formik.submitCount > 0) &&
              !!formik.errors.abbreviation
            }
          />
        </TextFieldWrapper>
      </div>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <SaveButton
          isLoading={formik.isSubmitting}
          variant="contained"
          type="submit"
        >
          Salvar
        </SaveButton>
      </div>
    </Container>
  );
};
