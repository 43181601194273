import { Box, Paper, Table, TableBody } from "@material-ui/core";
import { CustomLineProgress } from "components/LineProgress";

import { DropdownButton } from "../Dropdown";
import {
  BodyCell,
  BodyRow,
  HeadCell,
  StyledTableHead,
  TableContent
} from "./styles";

export const GroupTable = ({ groupData, loading, refresh, data }) => {
  return (
    <>
      {!data.length ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p>Sem dados</p>
        </div>
      ) : (
        <Box>
          {!!loading && <CustomLineProgress />}
          <TableContent component={Paper}>
            <Table stickyHeader>
              <StyledTableHead>
                <HeadCell>Produto base</HeadCell>
                <HeadCell>Produto</HeadCell>
                <HeadCell>Nº grupo</HeadCell>
                <HeadCell>Nome grupo</HeadCell>
              </StyledTableHead>
              <TableBody>
                {data.map(item => (
                  <BodyRow key={item.id}>
                    <BodyCell>{item.productBaseId || item.id}</BodyCell>
                    <BodyCell>{item.productBaseName || item.name}</BodyCell>
                    <BodyCell>{item.groupId || "Sem grupo"}</BodyCell>
                    <BodyCell>
                      <DropdownButton
                        groupData={groupData}
                        groupId={item.groupId || ""}
                        productBaseId={item.productBaseId || item.id}
                        refresh={refresh}
                      />
                    </BodyCell>
                  </BodyRow>
                ))}
              </TableBody>
            </Table>
          </TableContent>
        </Box>
      )}
    </>
  );
};
