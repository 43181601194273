import * as Yup from "yup";

export const validationSchema = batchData => {
  const { purchaseType, unclassifiedQuantity } = batchData;

  return Yup.object().shape({
    quantity: Yup.number()
      .min(0, "Deve ser um número positivo")
      .typeError("Deve ser um número")
      .required("Campo obrigatório")
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          return !(purchaseType === "Un" && !Number.isInteger(value));
        }
      )
      .max(unclassifiedQuantity, `O valor máximo é ${unclassifiedQuantity}`),
    storageReasonId: Yup.number().when("quantity", {
      is: 0,
      then: Yup.number().optional(),
      otherwise: Yup.number()
        .positive()
        .typeError("Deve ser um número")
        .required("Campo obrigatório")
    }),
    batchReasonId: Yup.number().when("quantity", {
      is: 0,
      then: Yup.number().optional(),
      otherwise: Yup.number()
        .positive()
        .typeError("Deve ser um número")
        .required("Campo obrigatório")
    })
  });
};
