import { Box, Typography, styled } from "@material-ui/core";
import { Dialog } from "shared";

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem"
});

export const Text = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "1.1rem",
  color: "#757575"
});

export const TextInfo = styled("p")({
  textAlign: "center",
  fontSize: "1rem",
  color: "#fb0303c4"
});

export const TextNotDeliveredInfo = styled("p")({
  textAlign: "center",
  fontSize: "1rem",
  color: "#fb0303c4"
});

export const NotDeliveredContent = styled(Box)({
  display: "flex",
  width: "100%",
  height: "7.5rem",
  background: "#F5F8F3",
  border: "2px solid #B5C9A8",
  color: "#EB001B",
  fontSize: "1rem",
  fontWeight: 500,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "1rem"
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  maxWidth: "22rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": {
    width: "100%",
    height: "20%",
    padding: 0,
    margin: 0
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      height: "25%"
    }
  }
}));

export const PopupContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100% - 92px)",
  gap: "0.5rem"
});
