import { TextField, Input, Paper, InputLabel, styled } from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import FlipCameraIosIcon from "@material-ui/icons/FlipCameraIos";

export const ClassificationInputContent = styled(Paper)(
  ({ theme, classification }) => {
    const topColor = {
      A: "#4EAA68",
      B: "#B4C932",
      C: "#E3DB24",
      Waste: "#DC004E"
    }[classification];
    return {
      position: "relative",
      display: "flex",
      flex: 1,
      gap: "1rem",
      padding: "2rem 1rem 1rem 1rem",
      "&.MuiPaper-root": {
        borderRadius: "1rem 1rem 0.5rem 0.5rem",
        margin: 0
      },
      "&::before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        borderTop: `0.7rem solid ${topColor}`,
        borderRadius: "0.5rem 0.5rem 0 0",
        pointerEvents: "none",
        zIndex: 1
      }
    };
  }
);

export const StyledInputLabel = styled(InputLabel)(({ hasImage }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "3rem",
  gap: "0.5rem",
  color: hasImage ? "#4EAA68" : "#757575",
  cursor: "pointer",
  "&.Mui-disabled": {
    cursor: "not-allowed",
    color: "#CFCECE"
  }
}));

export const ClassificationInput = ({
  classification,
  quantity,
  image,
  hasImage,
  handleChange,
  ...rest
}) => {
  return (
    <ClassificationInputContent
      key={classification}
      elevation={3}
      classification={classification}
    >
      <TextField
        variant="outlined"
        name={classification}
        onChange={handleChange}
        value={String(quantity || "")}
        label={`Sublote - ${classification}`}
        fullWidth
        {...rest}
        type="number"
      />
      {classification !== "Waste" && hasImage && !!quantity && (
        <StyledInputLabel hasImage={!!image} disabled={!quantity}>
          {!image ? <AddAPhotoIcon /> : <FlipCameraIosIcon />}
          <Input
            disabled={!quantity}
            type="file"
            name={classification}
            onChange={handleChange}
            inputProps={{
              accept: "image/*",
              field: "image"
            }}
            style={{ display: "none" }}
          />
        </StyledInputLabel>
      )}
    </ClassificationInputContent>
  );
};
