import { Typography } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import PersonIcon from "@material-ui/icons/Person";
import {
  TimelineConnector,
  TimelineOppositeContent,
  TimelineSeparator
} from "@material-ui/lab";

import {
  BoxNewValue,
  BoxOldValue,
  CreatedByContent,
  CreatedDateTimeContent,
  HistoryDiffContent,
  HistoryTitle,
  StyledTimeLine,
  StyledTimeLineContent,
  StyledTimeLineDot,
  StyledTimeLineItem,
  ValuesContent
} from "../../style";
import { checkHistoryDiff } from "../../utils/checkHistoryDiff";
import { historyTitleMap } from "../../utils/historyTitleMap";
import { HistoryIcons } from "../HistoryIcons";

export const HistoryTimeLine = ({ historical }) => {
  return (
    <StyledTimeLine>
      {historical?.map((historyItem, index) => {
        const diff = !index
          ? {}
          : checkHistoryDiff(index, historical, historyItem);
        const hasDiff = Object.keys(diff).length;
        return (
          <StyledTimeLineItem key={historyItem.id}>
            <TimelineOppositeContent>
              <CreatedDateTimeContent>
                <Typography variant="body2" color="textSecondary">
                  {historyItem.createdDate}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {historyItem.createdTime}
                </Typography>
              </CreatedDateTimeContent>

              <CreatedByContent color="textSecondary">
                <PersonIcon />
                {historyItem.createdBy}
              </CreatedByContent>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <StyledTimeLineDot hasDiff={hasDiff}>
                <HistoryIcons index={index} diff={diff} />
              </StyledTimeLineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <StyledTimeLineContent>
              {!index && (
                <HistoryDiffContent elevation={3}>
                  <HistoryTitle variant="h6" component="h1">
                    SubLote criado
                  </HistoryTitle>
                </HistoryDiffContent>
              )}
              {Object.entries(diff).map(([key, value]) => {
                const previousItem = historical[index - 1];
                const previousValue =
                  previousItem.stageId === 9
                    ? historical[index - 2]
                    : previousItem;

                return (
                  <HistoryDiffContent elevation={3} key={key}>
                    <HistoryTitle variant="h6" component="h1">
                      {historyTitleMap(key)}
                    </HistoryTitle>

                    <ValuesContent>
                      {!["destination", "source"].includes(key) && (
                        <>
                          <BoxOldValue>
                            {previousValue[key] || "--"}
                          </BoxOldValue>
                          <CachedIcon />
                        </>
                      )}
                      <BoxNewValue>{value}</BoxNewValue>
                    </ValuesContent>
                  </HistoryDiffContent>
                );
              })}
            </StyledTimeLineContent>
          </StyledTimeLineItem>
        );
      })}
    </StyledTimeLine>
  );
};
