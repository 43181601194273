const root = {
  "& .MuiInputBase-input::-webkit-inner-spin-button": {
    display: "none"
  },
  "& .MuiFormHelperText-root": {
    marginLeft: "0px",
    fontSize: "0.9rem",
    fontWeight: "300"
  },
  "& .MuiInputBase-input.Mui-disabled": {
    background: "#FAFAFA"
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#ffffff",
    "& fieldset": {
      border: "1px solid #C4C4C4"
    },
    "&:hover fieldset": {
      border: "1px solid #C4C4C4"
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #416D0B"
    }
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#416D0B"
  }
};

const Select = theme => {
  return {
    MuiSelect: { root },
    MuiFormControl: { root }
  };
};

export default Select;
