import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { MainContent, SearchContent } from "components/Contents";
import { SearchInput } from "components/Inputs";
import { usePopup } from "hooks/usePopup";
import { useEffect, useRef, useState } from "react";
import productApiServices from "services/productApiServices";
import { useDebaunce } from "utils";

import { AddButton } from "./components/AddButton";
import { CategoriesTable, StockTypeTable } from "./components/CategoriesTable";
import { DialogCreate } from "./components/DialogsPopup/DialogCreate";
import { DialogUpdate } from "./components/DialogsPopup/DialogUpdate";

export const ProductStorageStockType = () => {
  const { addPopup } = usePopup();
  const [storageStockTypesData, setStorageStockTypeData] = useState([]);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });
  const [stockTypeName, setStockTypeName] = useState("");
  const [stockTypeAbbreviation, setStockTypeAbbreviation] = useState("");
  const [stockTypeId, setStockTypeId] = useState(null);

  const getInitData = async () => {
    try {
      setLoading(true);
      setStorageStockTypeData([]);
      const params = {
        search
      };
      const { data } = await productApiServices.getStorageStockTypes(params);
      setStorageStockTypeData(data);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar tipos de estocagem",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateStockType = async () => {
    try {
      setLoadingFetch(true);
      const data = {
        name: stockTypeName,
        abbreviation: stockTypeAbbreviation
      };
      await productApiServices.postStorageStockTypes(data);
      handleCloseDialogs();
      getInitData();
      addPopup({
        type: "success",
        title: "Novo tipo de estocagem criado com sucesso!",
        description: `Nome: ${stockTypeName} Sigla: ${stockTypeAbbreviation}`
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao criar tipo de estocagem",
        description: errorMessage
      });
    } finally {
      setLoadingFetch(false);
    }
  };

  const handleUpdateStockType = async () => {
    try {
      setLoadingFetch(true);
      const data = {
        name: stockTypeName,
        abbreviation: stockTypeAbbreviation
      };
      await productApiServices.patchStorageStockTypes({
        id: stockTypeId,
        data
      });
      handleCloseDialogs();
      getInitData();
      addPopup({
        type: "success",
        title: "Tipo de estocagem atualizado com sucesso!",
        description: `Nome: ${stockTypeName} Sigla: ${stockTypeAbbreviation}`
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao atualizar tipo de estocagem",
        description: errorMessage
      });
    } finally {
      setLoadingFetch(false);
    }
  };

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  function handleCloseDialogs() {
    setStockTypeId(null);
    setStockTypeName("");
    setStockTypeAbbreviation("");
    setOpenCreate(false);
    setOpenUpdate(false);
  }

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleOpenUpdate = categoryId => {
    const stockType = storageStockTypesData.find(({ id }) => id === categoryId);
    const { name, abbreviation, id } = stockType;
    setStockTypeId(id);
    setStockTypeName(name);
    setStockTypeAbbreviation(abbreviation);
    setOpenUpdate(true);
  };

  const handleChangeStockType = e => {
    const { value } = e.target;
    setStockTypeName(value);
  };

  const handleChangeAbbreviation = e => {
    const value = e.target.value?.toUpperCase().slice(0, 2);
    setStockTypeAbbreviation(value);
  };

  useEffect(() => {
    getInitData();
  }, [search]);

  return (
    <ContainerPage>
      <Box>
        <TitlePage>Tipo de estocagem</TitlePage>
      </Box>
      <MainContent>
        <SearchContent>
          <SearchInput placeholder="ID ou estoque" onChange={debaunceSearch} />
          <AddButton onClick={handleOpenCreate} />
        </SearchContent>
        <StockTypeTable
          storageStockTypesData={storageStockTypesData}
          loading={loading}
          handleOpenUpdate={handleOpenUpdate}
        />
        <DialogCreate
          handleClose={handleCloseDialogs}
          open={openCreate}
          stockTypeAbbreviation={stockTypeAbbreviation}
          stockTypeName={stockTypeName}
          handleChangeAbbreviation={handleChangeAbbreviation}
          handleChangeStockType={handleChangeStockType}
          loading={loadingFetch}
          handleSave={handleCreateStockType}
        />
        <DialogUpdate
          handleClose={handleCloseDialogs}
          open={openUpdate}
          stockTypeAbbreviation={stockTypeAbbreviation}
          stockTypeName={stockTypeName}
          handleChangeAbbreviation={handleChangeAbbreviation}
          handleChangeStockType={handleChangeStockType}
          loading={loadingFetch}
          handleSave={handleUpdateStockType}
        />
      </MainContent>
    </ContainerPage>
  );
};
