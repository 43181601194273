import { ContainerPage, TitlePage } from "components";
import { useTableParams } from "hooks/useTableParams";
import { useRef } from "react";
import { FaPen } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { Link } from "react-router-dom/cjs/react-router-dom";
import depotService from "services/depotService";

import { WarehouseTypeCreateUpdate } from "./components/WarehouseTypeCreateUpdate";
import {
  Header,
  AddButton,
  Content,
  WarehouseCard,
  EditButton,
  WarehouseTypeTitle
} from "./style";

export const WarehouseType = () => {
  const refWarehouseType = useRef(null);
  const { data, refresh } = useTableParams({
    service: params => depotService.listWarehouseTypes(params),
    paramsDefault: {
      orderDirection: "created",
      pageSize: 100
    }
  });

  return (
    <>
      <ContainerPage>
        <Header>
          <TitlePage>Armazéns</TitlePage>
          <AddButton
            onClick={() => refWarehouseType.current?.handleOpen(null)}
            variant="contained"
            startIcon={<IoMdAdd />}
          >
            Adicionar
          </AddButton>
        </Header>
        <Content>
          {data.map(warehouseType => (
            <Link
              key={warehouseType.id}
              to={`./depositos/${warehouseType.id}`}
              style={{
                textDecoration: "none"
              }}
            >
              <WarehouseCard>
                <WarehouseTypeTitle>
                  {warehouseType.abbreviation}
                </WarehouseTypeTitle>
                <EditButton
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    refWarehouseType.current?.handleOpen(warehouseType);
                  }}
                >
                  <FaPen size={16} color="#757575" />
                </EditButton>
              </WarehouseCard>
            </Link>
          ))}
        </Content>
      </ContainerPage>

      <WarehouseTypeCreateUpdate ref={refWarehouseType} refresh={refresh} />
    </>
  );
};
