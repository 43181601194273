const { makeStyles, styled, FormControlLabel } = require("@material-ui/core");

export const useStyles = makeStyles(theme => ({
  gridImage: {
    flexDirection: "row-reverse",
    gap: "1rem",
    paddingRight: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "1rem"
    }
  }
}));

export const StyledFormControlLabel = styled(FormControlLabel)({
  "& span": {
    paddingBlock: "0.1rem"
  }
});
