import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Chip,
  Tooltip,
  Zoom
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import PaletteIcon from "@material-ui/icons/Palette";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import sellerItemService from "services/sellerItemService";
import { Dialog, AutoCompleteProductBase, AutoCompleteSeller } from "shared";
import { getHours } from "utils/getHours";
import * as Yup from "yup";

import { useStyles } from "./styles";

export const DialogCreated = ({ isOpen, setIsOpen, refresh }) => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [days, setDays] = useState([]);
  const [initialHours, setInitialHours] = useState(null);
  const [endHours, setEndHours] = useState(null);

  const schema = Yup.object().shape({
    seller_id: Yup.mixed().required("Seller obrigatório"),
    product_id: Yup.mixed().required("Produto base obrigatório"),
    portion_type_id: Yup.number()
      .typeError("Tipo de estoque obrigatório")
      .required("Tipo de estoque obrigatório"),
    status: Yup.string()
      .typeError("Status é obrigatório")
      .required("Status é obrigatório"),
    order_status: Yup.string()
      .typeError("Status da compra é obrigatório")
      .required("Status da compra é obrigatório"),
    startTime: Yup.string()
      .typeError("Horário inicial é obrigatório")
      .required("Horário inicial é obrigatório"),
    endTime: Yup.string()
      .typeError("Horário final é obrigatório")
      .required("Horário final é obrigatório"),
    portion_per_box: Yup.number()
      .typeError("Quantidade por caixa obrigatório")
      .required("Quantidade por caixa obrigatório"),
    portion: Yup.number()
      .typeError("Quantidade obrigatória")
      .required("Quantidade obrigatória"),
    conversion_factor: Yup.number()
      .typeError("Fator de conversão obrigatório")
      .required("Fator de conversão obrigatório"),
    days: Yup.mixed().test(
      "days",
      "Obrigatório pelo menos 1 dia da semana",
      value => days.length !== 0
    )
  });

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(schema)
  });

  const handleClose = () => {
    reset();
    setDays([]);
    setIsOpen(false);
  };

  const { errors } = formState;

  const verifyDayValue = dayValue => {
    if (days.includes(dayValue) && days.includes(`${dayValue}-Ceasa`)) {
      return 2;
    } else if (days.includes(dayValue)) {
      return 1;
    }

    return 0;
  };

  const formatData = data => {
    const { endTime, startTime } = data;
    const product_id = Number(data.product_id.id);
    const seller_id = Number(data.seller_id.id);

    return {
      ...data,
      seller_id,
      product_id,
      monday: verifyDayValue("Segunda"),
      tuesday: verifyDayValue("Terça"),
      wednesday: verifyDayValue("Quarta"),
      thursday: verifyDayValue("Quinta"),
      friday: verifyDayValue("Sexta"),
      saturday: verifyDayValue("Sábado"),
      sunday: verifyDayValue("Domingo"),
      window_delivery_time: `${startTime}h as ${endTime}h`
    };
  };

  const onSubmitHandler = async data => {
    try {
      setIsLoading(true);

      await sellerItemService.postSellerItem(formatData(data));

      addPopup({
        type: "success",
        title: "Seller Item criado com sucesso"
      });
      refresh();
      handleClose();
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao registrar seller item"
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDays = dayValue => {
    setDays(state => {
      if (days.includes(dayValue) && days.includes(`${dayValue}-Ceasa`)) {
        const values = state.filter(day => {
          if (day === dayValue) return false;
          if (day === `${dayValue}-Ceasa`) return false;
          return true;
        });

        return values;
      } else if (days.includes(dayValue)) {
        return [...state, `${dayValue}-Ceasa`];
      }

      return [...state, dayValue];
    });
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        handleClose={handleClose}
        title="Registrar produto do fornecedor"
      >
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <AutoCompleteSeller
                control={control}
                name="seller_id"
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AutoCompleteProductBase
                control={control}
                name="product_id"
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors?.portion_type_id}
              >
                <InputLabel>Tipo de Estoque</InputLabel>
                <Select
                  label="Tipo de Estoque"
                  {...register("portion_type_id")}
                >
                  <MenuItem value={1}>Quilo (kg)</MenuItem>
                  <MenuItem value={2}>Unidade (un)</MenuItem>
                </Select>
                <FormHelperText>
                  {errors?.portion_type_id?.message}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors?.status}
              >
                <InputLabel>Status</InputLabel>
                <Select label="Status" {...register("status")}>
                  <MenuItem value="Ativo">Ativo</MenuItem>
                  <MenuItem value="Pausa">Pausa</MenuItem>
                  <MenuItem value="Descontinuado">Descontinuado</MenuItem>
                </Select>
                <FormHelperText>{errors?.status?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                label="Quantidade"
                {...register("portion")}
                error={!!errors?.portion}
                helperText={errors?.portion?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                label="Quantidade por caixa"
                {...register("portion_per_box")}
                error={!!errors?.portion_per_box}
                helperText={errors?.portion_per_box?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                label="Fator de conversão"
                {...register("conversion_factor")}
                error={!!errors?.conversion_factor}
                helperText={errors?.conversion_factor?.message}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors?.order_status}
              >
                <InputLabel>Status de compra</InputLabel>
                <Select label="Status de compra" {...register("order_status")}>
                  <MenuItem value="Compra">Compra</MenuItem>
                  <MenuItem value="Não Compra">Não compra</MenuItem>
                </Select>
                <FormHelperText>{errors?.order_status?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Descrição"
                multiline
                rows={2}
                {...register("description")}
                error={!!errors?.weight}
                helperText={errors?.weight?.message}
              />
            </Grid>

            <Grid container item spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={6}>
                <Typography style={{ marginBottom: "7px" }}>
                  Horário de entrega
                </Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors?.startTime}
                >
                  <InputLabel>Horário inicial</InputLabel>
                  <Select
                    label="Horário inicial"
                    {...register("startTime", {
                      onChange: e => setInitialHours(Number(e.target.value))
                    })}
                  >
                    {getHours()
                      .filter(hour => {
                        return endHours > Number(hour) ? hour : false;
                      })
                      .map(hour => (
                        <MenuItem key={hour} value={hour}>
                          {hour + ":00"}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{errors?.startTime?.message}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors?.endTime}
                >
                  <InputLabel>Horário final</InputLabel>
                  <Select
                    label="Horário final"
                    {...register("endTime", {
                      onChange: e => setEndHours(Number(e.target.value))
                    })}
                  >
                    {getHours()
                      .filter(hour => {
                        return initialHours < Number(hour) ? hour : false;
                      })
                      .map(hour => (
                        <MenuItem key={hour} value={hour}>
                          {hour + ":00"}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{errors?.endTime?.message}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center" style={{ gap: "0.8rem" }}>
                <Typography className={classes.titleDays}>
                  Dias de entrega
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      <Box>
                        <Typography className={classes.titleDays}>
                          <PaletteIcon
                            fontSize="small"
                            style={{ color: "#88BC23" }}
                          />
                          Fornecedor
                        </Typography>
                        <Typography className={classes.titleDays}>
                          <PaletteIcon
                            fontSize="small"
                            style={{ color: "#a7b1eb" }}
                          />
                          Ceasa
                        </Typography>
                      </Box>
                    }
                    arrow
                    placement="top"
                  >
                    <HelpIcon className={classes.iconHelp} fontSize="small" />
                  </Tooltip>
                </Typography>

                {errors?.days?.message && (
                  <span className={classes.spanError}>
                    {errors?.days?.message}
                  </span>
                )}
              </Box>
              <ToggleButtonGroup
                value={days}
                onChange={(e, newDays) => {
                  handleDays(e.target.textContent);
                }}
                className={classes.toggleGroup}
              >
                <ToggleButton value="Segunda" className={classes.toggleButton}>
                  <Chip
                    size="small"
                    label="Segunda"
                    className={
                      days.includes("Segunda-Ceasa")
                        ? classes.chipCheckCeasa
                        : days.includes("Segunda")
                        ? classes.chipCheck
                        : classes.chip
                    }
                  />
                </ToggleButton>
                <ToggleButton value="Terça" className={classes.toggleButton}>
                  <Chip
                    size="small"
                    label="Terça"
                    className={
                      days.includes("Terça-Ceasa")
                        ? classes.chipCheckCeasa
                        : days.includes("Terça")
                        ? classes.chipCheck
                        : classes.chip
                    }
                  />
                </ToggleButton>
                <ToggleButton value="Quarta" className={classes.toggleButton}>
                  <Chip
                    size="small"
                    label="Quarta"
                    className={
                      days.includes("Quarta-Ceasa")
                        ? classes.chipCheckCeasa
                        : days.includes("Quarta")
                        ? classes.chipCheck
                        : classes.chip
                    }
                  />
                </ToggleButton>
                <ToggleButton value="Quinta" className={classes.toggleButton}>
                  <Chip
                    size="small"
                    label="Quinta"
                    className={
                      days.includes("Quinta-Ceasa")
                        ? classes.chipCheckCeasa
                        : days.includes("Quinta")
                        ? classes.chipCheck
                        : classes.chip
                    }
                  />
                </ToggleButton>
                <ToggleButton value="Sexta" className={classes.toggleButton}>
                  <Chip
                    size="small"
                    label="Sexta"
                    className={
                      days.includes("Sexta-Ceasa")
                        ? classes.chipCheckCeasa
                        : days.includes("Sexta")
                        ? classes.chipCheck
                        : classes.chip
                    }
                  />
                </ToggleButton>
                <ToggleButton value="Sábado" className={classes.toggleButton}>
                  <Chip
                    size="small"
                    label="Sábado"
                    className={
                      days.includes("Sábado-Ceasa")
                        ? classes.chipCheckCeasa
                        : days.includes("Sábado")
                        ? classes.chipCheck
                        : classes.chip
                    }
                  />
                </ToggleButton>
                <ToggleButton value="Domingo" className={classes.toggleButton}>
                  <Chip
                    size="small"
                    label="Domingo"
                    className={
                      days.includes("Domingo-Ceasa")
                        ? classes.chipCheckCeasa
                        : days.includes("Domingo")
                        ? classes.chipCheck
                        : classes.chip
                    }
                  />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid container justifyContent="flex-end">
              <Button
                isLoading={isLoading}
                type="submit"
                size="large"
                variant="contained"
              >
                Registrar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};
