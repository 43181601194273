import {
  styled,
  Radio as RadioMui,
  FormControlLabel as FormControlLabelMui
} from "@material-ui/core";

const Radio = styled(RadioMui)({
  color: "default",
  "&$checked": {
    color: "#587916"
  }
});

const FormControlLabel = styled(FormControlLabelMui)(({ checked }) => ({
  margin: 0,
  padding: "0.2rem",
  background: checked ? "#f3f8e6" : "#f5f6f7",
  borderRadius: "0.5rem",
  border: "2px solid #f5f6f7"
}));

const ButtonRadio = ({ value, onChange, checked, label, ...rest }) => (
  <FormControlLabel
    control={
      <Radio
        value={value}
        onChange={onChange}
        icon={
          <span
            style={{
              border: "2px solid #CFCECE",
              padding: "10px",
              borderRadius: "50%"
            }}
          />
        }
        checkedIcon={
          <span
            style={{
              display: "block",
              position: "relative",
              border: "2px solid #CFCECE",
              padding: "10px",
              borderRadius: "50%",
              width: "16px",
              height: "16px"
            }}
          >
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "50%",
                width: "14px",
                height: "14px",
                backgroundColor: "#587916"
              }}
            />
          </span>
        }
      />
    }
    label={label}
    checked={checked}
    {...rest}
  />
);

export default ButtonRadio;
