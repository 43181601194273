const { makeStyles, alpha } = require("@material-ui/core");

export const useStyles = makeStyles(theme => ({
  paper: {
    boxSizing: "border-box",
    maxWidth: ({ maxWidth = "800px" }) => maxWidth,
    width: "100%",
    padding: "1rem"
  },
  header: {
    position: "relative"
  },
  closeBtn: {
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translateY(-50%)",
    height: "2.5rem",
    width: "2.5rem",
    "& svg": {
      fontSize: "1.6rem"
    }
  },
  title: {
    fontFamily: '"Inter", sans-serif',
    fontSize: "1.25rem",
    lineHeight: "2.37rem",
    fontWeight: 600,
    color: "#353535"
  },
  dialogContent: {
    flex: "initial",
    height: ({ height = "initial" }) => height,
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "10px",
      background: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      background: alpha(theme.palette.grey[600], 0.3),
      borderRadius: "9px"
    }
  }
}));
