import { Box, Typography } from "@material-ui/core";

import { useStyles } from "./styles";

export const Info = ({ title, description, children, full }) => {
  const classes = useStyles();

  return (
    <Box style={full && { width: "100%" }}>
      <Typography className={classes.title}>{title}:</Typography>
      {children ? (
        <Box className={classes.box}>{children}</Box>
      ) : (
        <Typography>
          {description === 0
            ? String(description)
            : description || "Não informado"}
        </Typography>
      )}
    </Box>
  );
};
