import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  content: {
    marginTop: "2rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  userName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: "1.18rem",
    color: "#414130",
    textAlign: "center",
    marginBottom: "1rem"
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.1rem",
    lineHeight: "1.5rem"
  },
  boxPassword: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "3rem",
    minWidth: "10rem",
    margin: "2rem"
  },
  password: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1.4rem",
    lineHeight: "1.5rem",
    color: "#388E3C"
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "3rem"
  },
  button: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5rem"
  }
}));
