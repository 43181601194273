import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class StockHistoryService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getStockHistory(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/screenshot`;
    const response = await api.get(url, {
      params
    });
    return response;
  }

  async getStockHistoryDownload(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/screenshot/download`;
    return await api.get(url, {
      responseType: "blob",
      params: {
        export: "xls",
        ...params
      }
    });
  }
}

const stockHistoryService = new StockHistoryService();
export default stockHistoryService;
