import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { RiDonutChartFill } from "react-icons/ri";
import batchServiceV3 from "services/batchServiceV3";

import { formatData } from "../../../utils/formatData";
import { BatchBreakageModal } from "../../BatchBreakageModal";
import { CardContent } from "../CardContent";
import {
  CardTitle,
  CardValue,
  CardsContainer,
  IconContent,
  InfoContent
} from "../styles";

export const HorDashCards = ({
  batchData,
  storageData,
  batchLoading,
  stockLoading
}) => {
  const { addPopup } = usePopup();
  const [batchBreakageData, setBatchBreakageData] = useState({});
  const [loadingBatchBreakage, setLoadingBatchBreakage] = useState(false);
  const [openBatchBreakage, setOpenBatchBreakage] = useState(false);
  const [scheduleDateFrom, setScheduleDateFrom] = useState(new Date());
  const [scheduleDateTo, setScheduleDateTo] = useState(new Date());

  const getBatchBreakageData = async () => {
    try {
      setLoadingBatchBreakage(true);
      const params = {
        scheduleDateFrom,
        scheduleDateTo
      };
      const { data } = await batchServiceV3.getBatchWitBreakageDash(params);

      setBatchBreakageData(formatData.formatBatchBreakageData(data));
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar lotes com quebra",
        description: errorMessage
      });
    } finally {
      setLoadingBatchBreakage(false);
    }
  };

  const handleOpenBatchBreakage = () => {
    setOpenBatchBreakage(true);
  };

  const handleCloseBatchBreakage = () => {
    setOpenBatchBreakage(false);
  };

  const handleChangeScheduleDate = dates => {
    const [start, end] = dates;
    setScheduleDateFrom(start);
    setScheduleDateTo(end);
  };

  useEffect(() => {
    !!scheduleDateFrom && !!scheduleDateTo && getBatchBreakageData();
  }, [scheduleDateFrom, scheduleDateTo]);

  return (
    <CardsContainer gridArea="cards">
      <CardContent loading={batchLoading}>
        <IconContent style={{ background: "#EB001B" }} />
        <InfoContent>
          <CardTitle>Lotes Vencidos</CardTitle>
          <CardValue>{batchData.dueBatches}</CardValue>
        </InfoContent>
      </CardContent>

      <CardContent loading={batchLoading}>
        <IconContent style={{ background: "#FFC84E" }}>
          <AiFillClockCircle size={20} color="#FFF" />
        </IconContent>
        <InfoContent>
          <CardTitle>Lotes a Vencer</CardTitle>
          <CardValue> {batchData.batchesToDue || 0}</CardValue>
        </InfoContent>
      </CardContent>

      <CardContent loading={stockLoading}>
        <IconContent style={{ background: "#854AC2" }} />
        <InfoContent>
          <CardTitle>Estoque Negativo</CardTitle>
          <CardValue>{storageData.length ?? 0}</CardValue>
        </InfoContent>
      </CardContent>

      <CardContent
        loading={loadingBatchBreakage}
        onClick={!loadingBatchBreakage && handleOpenBatchBreakage}
      >
        <IconContent style={{ background: "#2E656A" }}>
          <RiDonutChartFill size={20} color="#FFF" />
        </IconContent>
        <InfoContent>
          <CardTitle>Lotes com quebras</CardTitle>
          <CardValue>{batchBreakageData.batchBreakageQty}</CardValue>
        </InfoContent>
      </CardContent>
      <BatchBreakageModal
        open={openBatchBreakage}
        handleClose={handleCloseBatchBreakage}
        batchData={batchBreakageData.batchData}
        complaintsData={batchBreakageData.complaintsData}
        scheduleDateFrom={scheduleDateFrom}
        scheduleDateTo={scheduleDateTo}
        handleChangeScheduleDate={handleChangeScheduleDate}
        loading={loadingBatchBreakage}
      />
    </CardsContainer>
  );
};
