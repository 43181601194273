import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: "20px 10px"
  },
  message: {
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: theme.spacing(2)
  },
  description: {
    color: theme.palette.text.secondary
  },
  dialogActions: {
    justifyContent: "flex-start"
  },
  button: {
    width: "2.5rem",
    height: "2.5rem",
    padding: "8px 16px"
  }
}));

export default useStyles;
