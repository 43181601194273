import { Box, makeStyles, styled } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  boxAlign: {
    display: "flex",
    alignItems: "center"
  },
  input: {
    width: "30%",
    height: "3.9rem",
    marginTop: "1rem",
    [theme.breakpoints.between(0, 700)]: {
      marginLeft: 0
    }
  },
  dateField: {
    width: "12rem",
    height: "3.9rem",
    marginLeft: "1rem",
    border: "3px solid #CFCECE",
    borderRadius: "5px",
    "& div": {
      height: "100%",
      paddingLeft: "1rem"
    },
    "& button": {
      background: "#E5ECD1",
      borderRadius: "3px",
      color: "#587916",
      height: "178%",
      "&:hover": {
        background: "#c8db93"
      }
    },
    [theme.breakpoints.between(0, 750)]: {
      minWidth: "11.5rem"
    }
  },
  filterText: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "1.1rem",
    color: "#757575",
    marginLeft: "2rem"
  },
  boxItemPopover: {
    width: "10rem",
    height: "3rem",
    textAlign: "center",
    padding: "0.5rem",
    "&:hover": {
      cursor: "pointer",
      background: "#F4F4F4"
    }
  },
  listItemText: {
    fontSize: "1.1rem",
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "0.9rem"
  }
}));

export const BoxPaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});
