import { usePopup } from "hooks/usePopup";
import { forwardRef, useImperativeHandle, useState } from "react";
import batchService from "services/batchService";
import { Dialog } from "shared";

import { ActionView } from "./ActionView";
import { ConfirmView } from "./ConfirmView";
import { Container } from "./style";

const Component = ({ refresh }, ref) => {
  const { addPopup } = usePopup();
  const [isOpen, setIsOpen] = useState(false);
  const [pageView, setPageView] = useState("actionView");
  const [batch, setBatch] = useState(null);
  const [option, setOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");

  const onNext = () => setPageView("confirmView");

  const onBack = () => {
    setOption(null);
    setPageView("actionView");
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setBatch({});
      setOption(null);
      setPageView("actionView");
    }, 200);
  };

  const handleOpen = batch => {
    setBatch(batch);
    setIsOpen(true);
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      const action = {
        4: () => batchService.confirmEntryV2(batch.id),
        5: () => batchService.confirmEntryV2(batch.id),
        reset: () => batchService.confirmBatch(batch.id, { action: "reset" }),
        next: () =>
          batchService.confirmBatch(batch.id, {
            action: "next",
            observation: comment
          }),
        returnBatch: () =>
          batchService.createBatchReturn({
            batch: batch.id,
            isPartial: false
          })
      }[option];
      await action();
      handleClose();
      refresh();
      addPopup({ type: "success", title: "Lote confirmado com sucesso" });
    } catch (err) {
      addPopup({ type: "error", title: "Erro ao confirmar lote" });
    }
    setIsLoading(false);
  };

  useImperativeHandle(ref, () => ({ handleOpen }));

  const view = {
    actionView: (
      <ActionView
        option={option}
        setOption={setOption}
        onNext={onNext}
        batch={batch}
        setComment={setComment}
        comment={comment}
      />
    ),
    confirmView: (
      <ConfirmView
        option={option}
        onBack={onBack}
        handleConfirm={handleConfirm}
        isLoading={isLoading}
      />
    )
  }[pageView];

  return (
    <Dialog
      open={isOpen}
      title="Confirmação"
      handleClose={handleClose}
      maxWidth="33rem"
    >
      <Container>{view}</Container>
    </Dialog>
  );
};

export const Confirm = forwardRef(Component);
