import { TextField, InputAdornment } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FiSearch } from "react-icons/fi";

export const AutoCompleteProduct = ({ options, onChange }) => {
  return (
    <Autocomplete
      id="combo-product-base"
      options={options}
      getOptionLabel={option => option?.name}
      style={{ width: 300 }}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Produto"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <FiSearch fontSize={22} color="#C4C4C4" />
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};
