import { AccordionSummary, Button, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CustomLineProgress } from "components/LineProgress";

import {
  AccordionContent,
  AccordionDetailContainer,
  BatchInformationContent,
  BatchTitleContainer,
  BatchTitleContent,
  BatchTitleDescription,
  BatchTitleValue,
  StyledAccordion,
  StyledAccordionDetails
} from "../../style";
import { SubBatchTable } from "../SubBatchTable";

export const SelectionAccordion = ({
  batchData,
  loading,
  handleOpenSelectionPopup
}) => {
  return (
    <AccordionContent>
      <Paper elevation={2} style={{ overflow: "hidden" }}>
        {loading && <CustomLineProgress />}
        {batchData?.map(batch => (
          <StyledAccordion key={batch.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-content-${batch.id}`}
              id={`panel-header-${batch.id}`}
            >
              <BatchTitleContainer>
                <BatchTitleContent>
                  <BatchTitleDescription>ID</BatchTitleDescription>
                  <BatchTitleValue>{batch.id}</BatchTitleValue>
                </BatchTitleContent>

                <BatchTitleContent>
                  <BatchTitleDescription>Seller</BatchTitleDescription>
                  <BatchTitleValue>{batch.sellerName}</BatchTitleValue>
                </BatchTitleContent>

                <BatchTitleContent>
                  <BatchTitleDescription>Produto</BatchTitleDescription>
                  <BatchTitleValue>{batch.productName}</BatchTitleValue>
                </BatchTitleContent>

                <BatchTitleContent>
                  <BatchTitleDescription>Recebimento</BatchTitleDescription>
                  <BatchTitleValue>{batch.receiptDateTime}</BatchTitleValue>
                </BatchTitleContent>
                <BatchTitleContent>
                  <BatchTitleDescription>Selecionar até</BatchTitleDescription>
                  <BatchTitleValue>{batch.selectUntil}</BatchTitleValue>
                </BatchTitleContent>
              </BatchTitleContainer>
            </AccordionSummary>
            <StyledAccordionDetails>
              <AccordionDetailContainer>
                <BatchInformationContent elevation={3}>
                  <BatchTitleContent>
                    <BatchTitleDescription>Qtd. Total</BatchTitleDescription>
                    <BatchTitleValue>{batch.batchQuantity}</BatchTitleValue>
                  </BatchTitleContent>
                  <BatchTitleContent>
                    <BatchTitleDescription>
                      Qtd. Disponível
                    </BatchTitleDescription>
                    <BatchTitleValue>
                      {batch.formattedAvailableQuantity}
                    </BatchTitleValue>
                  </BatchTitleContent>
                  <BatchTitleContent>
                    <BatchTitleDescription>
                      Qtd. Não classificada
                    </BatchTitleDescription>
                    <BatchTitleValue>
                      {batch.formattedUnclassifiedQuantity}
                    </BatchTitleValue>
                  </BatchTitleContent>

                  <Button
                    variant="contained"
                    onClick={() => handleOpenSelectionPopup(batch.id)}
                    disabled={loading}
                  >
                    Classificar
                  </Button>
                </BatchInformationContent>
                <SubBatchTable subBatchData={batch.subBatch} />
              </AccordionDetailContainer>
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </Paper>
    </AccordionContent>
  );
};
