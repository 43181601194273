import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker as KeyboardTimePickerComponent,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import { useEffect, useState } from "react";

const KeyboardTimePicker = ({ name, value, onChange, ...props }) => {
  const [localTime, setLocalTime] = useState(value ? new Date(value) : null);
  const [pageSize, setPageSize] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (value !== undefined) {
      setLocalTime(value ? new Date(value) : null);
    }
  }, [value]);

  const handleTimeChange = newValue => {
    if (newValue && !isNaN(newValue.getTime())) {
      setLocalTime(newValue);
      onChange(newValue);
    } else {
      setLocalTime(null);
      onChange(null);
    }
  };

  useEffect(() => {
    const updatePageSize = e => {
      setPageSize(e.target.innerWidth);
    };

    window.addEventListener("resize", updatePageSize);
    return () => {
      window.removeEventListener("resize", updatePageSize);
    };
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <KeyboardTimePickerComponent
        views={["hours", "minutes"]}
        format="HH:mm"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        ampm={false}
        value={localTime}
        {...props}
        variant={pageSize > 700 ? "inline" : "dialog"}
        onChange={handleTimeChange}
        mask="__:__"
        invalidDateMessage="Formato de hora inválido."
        inputVariant="outlined"
        placeholder="__:__"
      />
    </MuiPickersUtilsProvider>
  );
};

export default KeyboardTimePicker;
