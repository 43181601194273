import { usePopup } from "hooks/usePopup";
import blitzService from "services/blitzService";
import { Drawer } from "shared";

import { StyledModal } from "../../styles";
import { Form } from "../Form";

export const ModalCreated = ({
  selectedProductId,
  handleSelectProduct,
  productList,
  isOpen,
  refresh,
  close
}) => {
  const { addPopup } = usePopup();

  const handleClose = () => {
    handleSelectProduct(null);
    close();
  };

  const onSubmit = async data => {
    try {
      const {
        packaging,
        averageWeight,
        samplePercentage,
        observation,
        minSample,
        minAverageWeight,
        maxAverageWeight
      } = data;

      await blitzService.createBlitzLaw({
        product: selectedProductId,
        packaging,
        average_weight: averageWeight,
        sample_percentage: samplePercentage,
        observation,
        min_sample: minSample,
        min_average_weight: minAverageWeight || null,
        max_average_weight: maxAverageWeight || null
      });

      addPopup({
        type: "success",
        title: "Regra da Blitz criada com sucesso."
      });

      handleClose();
      refresh();
    } catch (error) {
      if (error.response.data.product[0]) {
        return addPopup({
          type: "error",
          title: "Erro ao criar regra da blitz, essa regra ja existe."
        });
      }

      addPopup({
        type: "error",
        title: "Erro ao criar regra da blitz."
      });
    }
  };

  return (
    <StyledModal
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      title="Novo Regra da blitz"
    >
      <Form
        onSubmit={onSubmit}
        productList={productList}
        handleSelectProduct={handleSelectProduct}
        selectedProductId={selectedProductId}
      />
    </StyledModal>
  );
};
