export const portionNameValues = [
  { name: "Kg", value: "Kg" },
  { name: "g", value: "g" },
  { name: "Bdj", value: "Bdj" },
  { name: "Maço", value: "Maço" },
  { name: "Un", value: "Un" },
  { name: "ml", value: "ml" },
  { name: "L", value: "L" },
  { name: "Caixa(s)", value: "caixa" }
];
