export const mapStatusFilter = (statusId, stageId) => {
  const index = stageId ? `${statusId}${stageId}` : statusId;
  const state =
    {
      0: "Todos",
      3: "Devolvido",
      6: "Todos waste",
      7: "A devolver",
      312: "NF/Reembolso",
      313: "Reposição",
      615: "Waste devolução"
    }[index] || "Todos";
  return state;
};
