import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import assetService from "services/assetsService";

import { usePopup } from "../../hooks/usePopup";
import { useDebaunce } from "../../utils/debaunce";

const AutoCompleteAssets = props => {
  const { errors, name, control, defaultValue, className, onChange, disabled } =
    props;
  const { addPopup } = usePopup();
  const [loadingSearchAssets, setLoadingSearchAssets] = useState(false);
  const [options, setOptions] = useState([]);
  const handleAssetsData = async (search = undefined) => {
    try {
      setLoadingSearchAssets(true);
      const response = await assetService.getAsset({
        page: 1,
        pageSize: 15,
        search,
        status: "active"
      });

      const simplifiedOptions = response.data.results.map(asset => ({
        id: asset.id,
        name: asset.name
      }));

      setOptions(simplifiedOptions);
    } catch (err) {
      if (err?.response?.status) {
        addPopup({
          type: "error",
          title: "Desculpe, ocorreu um erro",
          description: "Erro ao pesquisar ativos."
        });
      }
    } finally {
      setLoadingSearchAssets(false);
    }
  };

  const debaunce = useDebaunce({ fn: handleAssetsData, delay: 500 });

  const handleAsset = e => {
    debaunce(e.target.value);
  };

  useEffect(() => {
    handleAssetsData();
  }, []);

  if (control) {
    return (
      <Controller
        defaultValue={defaultValue || null}
        render={props => (
          <Autocomplete
            loading={loadingSearchAssets}
            options={options}
            getOptionLabel={option => option.name}
            {...props}
            onChange={(_, data) => {
              props.field.onChange(data);
              onChange(data);
            }}
            value={props.field.value}
            className={className}
            noOptionsText="Ativo não encontrado"
            loadingText="Procurando ativo..."
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Ativo"
                error={!!errors?.[name]}
                helperText={errors?.[name]?.message}
                onChange={handleAsset}
                InputProps={{
                  ...params.InputProps,
                  className: className,
                  clearButton: false,
                  endAdornment: (
                    <>
                      {loadingSearchAssets && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
        name={name}
        control={control}
      />
    );
  }

  return (
    <Autocomplete
      loading={loadingSearchAssets}
      options={options}
      getOptionLabel={option => option.name}
      onChange={onChange}
      className={className}
      disabled={disabled}
      defaultValue={defaultValue}
      noOptionsText="Ativo não encontrado"
      loadingText="Procurando ativo..."
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Ativo"
          onChange={handleAsset}
          InputProps={{
            ...params.InputProps,
            className: className,
            clearButton: false,
            endAdornment: (
              <>
                {loadingSearchAssets && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default AutoCompleteAssets;
