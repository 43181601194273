import { Box } from "@material-ui/core";
import { forwardRef, useState, useImperativeHandle } from "react";
import { InfoCard } from "shared";

import { ActionView } from "./ActionView";
import { ConfirmView } from "./ConfirmView";
import { FormView } from "./FormView";
import { InitialView } from "./InitialView";
import { Dialog } from "./style";

const Component = (_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState(0);

  const [batch, setBatch] = useState({});
  const [tagQty, setTagQty] = useState(null);
  const [action, setAction] = useState("batch");
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);

  const clearState = () => {
    setTagQty(null);
    setAction("batch");
    setProducts([]);
    setProduct(null);
    setView(0);
    setBatch({});
  };

  const handleClose = () => {
    clearState();
    setIsOpen(false);
  };

  const handleOpen = () => setIsOpen(true);

  useImperativeHandle(ref, () => ({ handleOpen }));

  const page = {
    0: (
      <InitialView
        setBatch={setBatch}
        setProducts={setProducts}
        setProduct={setProduct}
        setAction={setAction}
        onNext={setView}
      />
    ),
    1: (
      <ActionView
        action={action}
        setAction={setAction}
        products={products}
        onBack={() => {
          setView(state => --state);
          clearState();
        }}
        onNext={() => setView(state => ++state)}
      />
    ),
    2: (
      <FormView
        action={action}
        tagQty={tagQty}
        setTagQty={setTagQty}
        product={product}
        setProduct={setProduct}
        products={products}
        onBack={() => setView(state => --state)}
        onNext={() => setView(state => ++state)}
      />
    ),
    3: (
      <ConfirmView
        action={action}
        batch={batch}
        tagQty={tagQty}
        product={product}
        handleClose={handleClose}
        onBack={() => setView(state => --state)}
        onNext={() => setView(state => ++state)}
      />
    )
  }[view];

  return (
    <Dialog open={isOpen} handleClose={handleClose} title="Imprimir etiquetas">
      <Box height="calc(100% - 92px)" display="flex" flexDirection="column">
        {![0, 3].includes(view) && (
          <InfoCard
            data={{
              Lote: { text: batch?.id, useStrong: true },
              Produto: { text: batch?.product?.name },
              Seller: { text: batch?.seller?.name }
            }}
            marginBottom="1rem"
          />
        )}
        {page}
      </Box>
    </Dialog>
  );
};

export const Reprint = forwardRef(Component);
