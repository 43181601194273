import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ContainerPage, TitlePage } from "components";
import PageLoader from "components/PageLoad";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import batchService from "services/batchService";

import { BatchReceivedPopup } from "../components/batchReceivedPopup";
import { HistoryTimeLine } from "../components/HistoryTimeLine";
import { BatchIdContent, HistoryContent, TitleDetail } from "../style";
import { formatData } from "../utils/formatData";
import { Detail } from "./Detail";

export const ProductBatchDetail = () => {
  const { batchId } = useParams();
  const history = useHistory();
  const [batchData, setBatchData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const controller = new AbortController();
  const [batchReasons, setBatchReasons] = useState([]);

  const getInitData = async (isLoading = true) => {
    try {
      setLoading(isLoading);
      setIsLoadingRefresh(true);
      const [batchResp, batchReasonsResp] = await Promise.all([
        batchService.getBatchDetail(batchId),
        batchService.getBatchReason({ page_size: 100 })
      ]);
      setBatchData(formatData.batchDetail(batchResp.data));
      setBatchReasons(batchReasonsResp.data);
    } catch (error) {
      history.push("/app/404");
    } finally {
      setLoading(false);
      setIsLoadingRefresh(false);
    }
  };

  useEffect(() => {
    getInitData();
    return () => {
      controller.abort();
    };
  }, [batchId]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <ContainerPage>
      <TitlePage>
        Rastreio de lote <NavigateNextIcon />
        <BatchIdContent>{batchData.id}</BatchIdContent>
      </TitlePage>
      <Detail
        batchData={batchData}
        refresh={getInitData}
        isLoadingRefresh={isLoadingRefresh}
        batchReasons={batchReasons}
      />
      <HistoryContent>
        <TitleDetail>Histórico de movimentações</TitleDetail>
        <HistoryTimeLine batchHistorical={batchData.historical} />
      </HistoryContent>
    </ContainerPage>
  );
};
