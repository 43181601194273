import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class StockService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getAllStock(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/stock-master`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        results: data,
        total_count: data.length
      }
    };
  }

  async getResumeStock(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/stock/resume`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        results: data,
        total_count: data.length
      }
    };
  }

  async getSummaryStockSku(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/stock/resume/sku`;
    return await api.get(url, { params });
  }

  async updateStock(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/stock-master/${data.id}`;
    return await api.put(url, data);
  }
}

const stockService = new StockService();
export default stockService;
