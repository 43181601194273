import { makeStyles } from "@material-ui/core";

export const receiptConferenceStyles = makeStyles(theme => ({
  table: {
    maxWidth: "95%",
    marginLeft: "1rem",
    marginTop: "1.3rem"
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "1.1rem",
    fontWeight: "500",
    color: "#757575"
  }
}));

export const rowConferenceStyles = makeStyles(theme => ({
  root: {
    marginTop: "2.5rem",
    background: props => (props.index % 2 === 0 ? "#F5F6F7" : "#FFFFFF"),
    "& > *": {
      borderBottom: "unset"
    }
  },
  sellerName: {
    fontFamily: "Roboto",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#000000"
  },
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    background: "#F9FCF4"
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: "0.899rem",
    fontWeight: "500",
    color: "#757575",
    lineHeight: "1rem"
  },
  producName: {
    fontFamily: "Roboto",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#161616",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    maxWidth: "5rem"
  },
  iconDesign: {
    cursor: "pointer"
  },
  menuPopover: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
    maxWidth: "27rem",
    minWidth: "20rem",
    minHeight: "9.8rem",
    background: "#FFFFFF",
    border: "1px solid #CFCECE",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px"
  },
  observationText: {
    display: "flex",
    flexGrow: 1,
    padding: "0.5rem",
    position: "relative",
    width: "100%",
    wordBreak: "break-word",
    wrap: "wrap",
    fontFamily: "Roboto",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#353535",
    border: "1px solid #cfcecea8",
    borderRadius: "8px"
  },
  popoverAction: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));
