import {
  Avatar,
  Card as MuiCard,
  IconButton,
  MenuItem,
  Typography,
  Box
} from "@material-ui/core";
import { memo, useRef, useState } from "react";
import { AiOutlineMore } from "react-icons/ai";
import { FaPen, FaWrench } from "react-icons/fa";
import { MdBlock } from "react-icons/md";
import { MenuPopover } from "shared";
import { stringAvatar, stringToColor } from "utils";

import { useStyles } from "./styles";

export const Card = ({
  username,
  handleOpenModal,
  handleUpdatePassword,
  handleResetBlock
}) => {
  const classes = useStyles();
  const userRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleTalkModal = () => {
    setOpen(state => !state);
    handleOpenModal();
  };

  const handleTalkModalPassword = () => {
    setOpen(state => !state);
    handleUpdatePassword();
  };

  const handleClick = () => {
    setOpen(state => !state);
  };

  return (
    <>
      <MuiCard className={classes.root}>
        <Box className={classes.content}>
          <IconButton ref={userRef} className={classes.options}>
            <AiOutlineMore size="2rem" onClick={handleClick} />
          </IconButton>
          <MenuPopover
            open={open}
            onClose={handleClick}
            anchorEl={userRef.current}
            arrow
          >
            <MenuItem onClick={handleTalkModal}>
              <FaPen />
              <Typography className={classes.menuAction}>
                Editar Permissões
              </Typography>
            </MenuItem>
            <MenuItem
              style={{ marginTop: "0.3rem" }}
              onClick={handleTalkModalPassword}
            >
              <FaWrench />
              <Typography className={classes.menuAction}>
                Alterar senha
              </Typography>
            </MenuItem>
            <MenuItem
              style={{ marginTop: "0.3rem" }}
              onClick={handleResetBlock}
            >
              <MdBlock />
              <Typography className={classes.menuAction}>
                Resetar bloqueio
              </Typography>
            </MenuItem>
          </MenuPopover>

          <Avatar
            className={classes.avatar}
            style={{ background: stringToColor(username) }}
          >
            {stringAvatar(username)}
          </Avatar>
          <strong className={classes.name}>{username}</strong>
        </Box>
      </MuiCard>
    </>
  );
};

const CardOperator = memo(Card, (prevProps, nextProps) => {
  return prevProps.id === nextProps.id;
});

export { CardOperator };
