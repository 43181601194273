import { useMediaQuery } from "@material-ui/core";

import { RankingCard } from "./rankingCard";
import { RankingTable } from "./rankingTable";

export const RankingComponent = ({ rankingData, loading }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const renderComponent = {
    false: <RankingTable loading={loading} rankingData={rankingData} />,
    true: <RankingCard loading={loading} rankingData={rankingData} />
  }[isSmallScreen];
  return renderComponent;
};
