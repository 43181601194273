const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return { date, time };
};

export const batchesFormatter = data => {
  return (data || []).map(depotBatch => {
    const formattedReceipt = formatDateTime(depotBatch.receiptDate);
    const formatFirstStored = formatDateTime(depotBatch.firstStoredDate);

    return {
      ...depotBatch,
      id: depotBatch.id,
      batchId: depotBatch.batchId,
      receiptDateTime: `${formattedReceipt.date} ${formattedReceipt.time}`,
      firstStoredDateTime: `${formatFirstStored.date} ${formatFirstStored.time}`,
      quantity: depotBatch.quantity ?? 0,
      classification: depotBatch.classification,
      depotName: depotBatch.depot?.name,
      warehouseTypeName: depotBatch.warehouseType?.name,
      packaging: depotBatch.packagingType?.name
    };
  });
};
