import { Box, Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, DialogConfirmDelete } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { MdModeEditOutline, MdOutlineDelete } from "react-icons/md";
import sellerService from "services/sellerService";
import { Dialog } from "shared";

export const ModalListAddress = ({
  openModal,
  setOpenModal,
  setCreateAddressOpenModal,
  setEditAddressOpenModal,
  refresh,
  dataSeller
}) => {
  const { addPopup } = usePopup();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogConfirmDelete, setDialogConfirmDelete] = useState({
    isOpen: false,
    id: null
  });
  const [addressData, setAddressData] = useState([]);

  const handleClose = () => {
    setOpenModal({ isOpen: false });
  };

  const handleCreateAddress = () => {
    handleClose();
    setCreateAddressOpenModal({ isOpen: true, data: dataSeller });
  };

  const handleDelete = async () => {
    try {
      await sellerService.deleteAddress(dialogConfirmDelete.id);
      setAddressData(prevData =>
        prevData.filter(address => address.id !== dialogConfirmDelete.id)
      );
      addPopup({
        type: "success",
        title: "Endereço deletado com sucesso."
      });
      refresh();
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao deletar endereço."
      });
    }
  };

  const handleEdit = address => {
    setEditAddressOpenModal({ isOpen: true, data: address });
    handleClose();
  };

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAddressData(dataSeller?.addresses);
  }, [dataSeller]);

  return (
    openModal && (
      <Dialog
        open={openModal}
        handleClose={handleClose}
        title="Gerenciar Endereços"
      >
        <DialogConfirmDelete
          open={dialogConfirmDelete.isOpen}
          handleClose={setDialogConfirmDelete}
          confirm={handleDelete}
        />
        <Box>
          {addressData?.length > 0 ? (
            <Grid container spacing={2}>
              {addressData.map(address => (
                <Grid item xs={12} key={address.id}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    border={1}
                    borderColor="grey.300"
                    borderRadius={4}
                    padding={2}
                    mb={2}
                  >
                    <div>
                      <strong>Rua:</strong> {address.street},{" "}
                      <strong>Número:</strong> {address.number}
                      {address.complement && (
                        <>
                          , <strong>Complemento:</strong> {address.complement}
                        </>
                      )}
                      <br />
                      <strong>Bairro:</strong> {address.neighborhood},{" "}
                      <strong>Cidade:</strong> {address.city} -{" "}
                      <strong>Estado:</strong> {address.state}
                      <br />
                      <strong>CEP:</strong> {address.zip_code}
                    </div>
                    <div>
                      <IconButton onClick={handleMenuClick}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem
                          onClick={() => {
                            handleEdit(address);
                            handleCloseMenu();
                          }}
                        >
                          <MdModeEditOutline style={{ marginRight: "8px" }} />
                          Editar
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setDialogConfirmDelete({
                              isOpen: true,
                              id: address.id
                            });
                            handleCloseMenu();
                          }}
                          style={{ color: "red" }}
                        >
                          <MdOutlineDelete style={{ marginRight: "8px" }} />
                          Deletar
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                height="300px"
                style={{ marginBottom: "1rem" }}
              >
                <h3 style={{ textAlign: "center" }}>
                  Não há nenhum endereço cadastrado para esse fornecedor.
                </h3>
                <p>Clique no botão abaixo para adicionar.</p>
              </Box>
            </Grid>
          )}
          <Grid
            container
            item
            justifyContent="flex-end"
            style={{ marginTop: "20px" }}
          >
            <Button variant="contained" onClick={handleCreateAddress}>
              Novo Endereço
            </Button>
          </Grid>
        </Box>
      </Dialog>
    )
  );
};
