import {
  Tab as TabMui,
  Paper as PaperMui,
  Tabs as TabsMui,
  styled
} from "@material-ui/core";

export const Paper = styled(PaperMui)({
  flexGrow: 1,
  maxWidth: "33.5625rem",
  margin: "0 auto",
  borderRadius: 0
});

export const Tabs = styled(TabsMui)({});

export const Tab = styled(TabMui)({
  textTransform: "initial",
  fontSize: "1.125rem",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#757575",
  "&.Mui-selected": {
    backgroundColor: "#F3F8E6",
    color: "#353535"
  }
});
