import { Box, Typography } from "@material-ui/core";
import { GiConfirmed } from "react-icons/gi";
import { VscError } from "react-icons/vsc";

import { useStyles } from "./styles";

export const Alert = ({ isSuccess, title }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.successBox}
      style={isSuccess ? { background: "#4EAA68" } : { background: "#EB001B" }}
    >
      {isSuccess ? (
        <GiConfirmed className={classes.icon} />
      ) : (
        <VscError className={classes.icon} />
      )}
      <Typography className={classes.text}>{title}</Typography>
    </Box>
  );
};
