import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Chip,
  IconButton,
  ListItemText,
  TextField,
  Typography
} from "@material-ui/core";
import { Button } from "components";
import { ChipStatus } from "components/ChipStatus";
import { useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { BsChevronDown } from "react-icons/bs";
import { MenuPopover } from "shared";

import { formStyles } from "./styles";
import { validationSchemaCreate } from "./validSchema";

export const Form = ({
  conferenceStatus,
  productUnit,
  conferenceData,
  onSubmit
}) => {
  const { handleSubmit, formState, reset, register } = useForm({
    resolver: yupResolver(validationSchemaCreate)
  });
  const { errors } = formState;

  const userRef = useRef(null);

  const [selectedStatusId, setSelectedStatusId] = useState(1);
  const [popover, setPopover] = useState(false);
  const classes = formStyles();

  const handleResetFields = () => {
    reset();
  };

  const togglePopover = () => setPopover(state => !state);

  const onSubmitHandler = data => {
    const form = new FormData();

    const newData = {
      ...data,
      status: selectedStatusId
    };

    Object.keys(newData).forEach(key => form.append(key, newData[key]));

    onSubmit(newData);
    handleResetFields();
  };

  const handleSelectSatus = event => {
    const value = event.currentTarget.getAttribute("value");

    setSelectedStatusId(value);
  };

  const statusName = useMemo(
    () =>
      conferenceStatus?.find(({ id }) => id === Number(selectedStatusId))?.name,
    [conferenceStatus, selectedStatusId]
  );

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
      <Box className={classes.boxAlign}>
        <Typography
          style={{
            marginRight: "1.3rem"
          }}
          className={classes.genericLabel}
        >
          Entrada
        </Typography>

        <Chip
          label={`${conferenceData?.batchInfos?.quantity} ${productUnit}s`}
          className={classes.chip}
        />
      </Box>

      <Box className={classes.boxAlign}>
        <Typography
          style={{
            marginRight: "1rem"
          }}
          className={classes.genericLabel}
        >
          Qntd. NF
        </Typography>

        <TextField
          variant="outlined"
          label="Inserir"
          type="number"
          {...register("quantity_invoice")}
          error={!!errors?.quantity_invoice}
          defaultValue={conferenceData?.quantityInvoice}
        />

        {errors?.quantity_invoice?.message && (
          <Typography className={classes.error}>
            {errors?.quantity_invoice?.message}
          </Typography>
        )}
      </Box>

      <Box style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <Typography className={classes.genericLabel}>Status:</Typography>

        <ChipStatus
          label={statusName}
          statusId={selectedStatusId}
          style={{
            margin: "1rem 0 1.2rem 0",
            fontSize: "0.9rem",
            minWidth: "4rem"
          }}
        />

        <IconButton onClick={togglePopover} ref={userRef}>
          <BsChevronDown />
        </IconButton>

        <MenuPopover
          open={popover}
          onClose={togglePopover}
          anchorEl={userRef.current}
          style={{ marginTop: "1rem" }}
        >
          {conferenceStatus?.map(status => (
            <Box
              className={classes.boxItemPopover}
              key={status.id}
              value={status.id}
              onClick={handleSelectSatus}
            >
              <ListItemText
                className={classes.listItemText}
                style={
                  status.id === 1 ? { color: "#C98400" } : { color: "#0A892E" }
                }
              >
                {status.name}
              </ListItemText>
            </Box>
          ))}
        </MenuPopover>
      </Box>

      <Box className={classes.boxAlign}>
        <TextField
          variant="outlined"
          label="Url da nota fiscal"
          type="url"
          {...register("url_invoice")}
          error={!!errors?.url_invoice}
          defaultValue={conferenceData?.conferenceInvoice}
          style={{ width: "90%", marginBottom: "0.5rem" }}
        />

        {errors?.url_invoice?.message && (
          <Typography className={classes.error}>
            {errors?.url_invoice?.message}
          </Typography>
        )}
      </Box>

      <Box>
        <label
          className={classes.label}
          style={{
            display: "flex",
            justifyContent: "left",
            marginTop: "0.5rem"
          }}
        >
          Observação
        </label>

        <TextField
          multiline
          rows={3}
          defaultValue={conferenceData?.observation}
          {...register("observation")}
          error={!!errors?.observation}
          helperText={errors?.observation?.message}
          variant="outlined"
          className={classes.bigInput}
        />
      </Box>

      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" type="submit" className={classes.button}>
          Salvar
        </Button>
      </Box>
    </Box>
  );
};
