import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 600
  },
  box: {
    display: "flex",
    gap: "5px",
    justifyContent: "center",
    flexWrap: "wrap",
    paddingTop: "5px"
  }
}));
