import { InitialPage } from "./InitialPage";

export const ReceiptBox = ({
  handleNext,
  batchData,
  confirmedQty,
  setConfirmedQty,
  blitzData,
  handleCloseOpenSteps,
  price,
  setPrice
}) => {
  return (
    <InitialPage
      confirmedQty={confirmedQty}
      setConfirmedQty={setConfirmedQty}
      onNext={handleNext}
      batchData={batchData}
      blitzData={blitzData}
      handleCloseOpenSteps={handleCloseOpenSteps}
      price={price}
      setPrice={setPrice}
      initialPrice={batchData?.purchase_item?.purchase?.combined_price}
      quantity={batchData?.quantity}
    />
  );
};
