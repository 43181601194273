import { styled, Typography } from "@material-ui/core";

export const Divider = styled(Typography)({
  position: "relative",
  textAlign: "center",
  color: "#A3A3A3",
  margin: "1.5rem 0",
  "&::before": {
    content: '""',
    width: "45%",
    height: "1px",
    background: "#A3A3A3",
    position: "absolute",
    top: "50%",
    left: "0"
  },
  "&::after": {
    content: '""',
    width: "45%",
    height: "1px",
    background: "#A3A3A3",
    position: "absolute",
    top: "50%",
    right: "0"
  }
});
