import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled
} from "@material-ui/core";
import { Button } from "components";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { Dialog } from "shared";
import { Search } from "shared/Search";

export const BodyCell = styled(TableCell)(({ align, width }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem",
  textAlign: align || "left",
  width: width || "auto"
}));

export const HeadCell = styled(TableCell)(({ align, width }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem",
  backgroundColor: "#F3F8E6",
  textAlign: align || "left",
  width: width || "auto"
}));

export const StyledTableHead = styled(TableHead)({
  background: "#F3F8E6"
});

export const TableContent = styled(TableContainer)({
  maxHeight: "40vh !important",
  overflowY: "hidden",
  "&::-webkit-scrollbar": {
    width: "9px",
    height: "9px",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "8px"
  }
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiContainer-root": {
    overflowX: "hidden"
  }
}));

export const styledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const StyledLink = styled(Link)({
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontWeight: 500,
  wordWrap: "break-word",
  color: "#353535",
  textDecoration: "none"
});

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem 0 1rem 1rem",
  gap: "1em",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const SearchInput = styled(Search)(({ theme }) => ({
  width: "100%",
  maxWidth: "25rem",
  height: "3.5rem",

  [theme.breakpoints.between(0, 700)]: {}
}));

export const DataPickerContent = styled(Box)(({ theme }) => ({
  display: "flex",
  "& .react-datepicker-popper": {
    zIndex: 5
  },
  [theme.breakpoints.down("sm")]: {
    "& .react-datepicker-wrapper": {
      display: "flex",
      flex: 1,
      minWidth: "100%",
      "& input": {
        width: "100%"
      }
    }
  }
}));

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  display: "flex",
  padding: "0.5rem 1rem",
  border: "1px solid #C5c5c5",
  borderRadius: "0.5rem",
  height: "3.4rem",
  cursor: "pointer",
  fontFamily: "Roboto",
  fontSize: "1rem"
}));

export const ButtonContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  justifyContent: "end",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "start"
  }
}));

export const DownloadButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}));
