import * as Yup from "yup";

const validationDefault = {
  name: Yup.string()
    .required("Tipo de embalagem é obrigatório")
    .typeError("Campo deve ser uma string")
    .max(255),
  height: Yup.number()
    .nullable(true)
    .typeError()
    .transform((_, val) => (val ? Number(val) : null))
    .positive("O valor deve ser positivo"),
  width: Yup.number()
    .nullable(true)
    .typeError()
    .transform((_, val) => (val ? Number(val) : null))
    .positive("O valor deve ser positivo"),
  length: Yup.number()
    .nullable(true)
    .typeError()
    .transform((_, val) => (val ? Number(val) : null))
    .positive("O valor deve ser positivo"),
  quantity: Yup.number()
    .positive("Valor deve ser positivo")
    .typeError("Campo deve ser um número")
    .required("Campo obrigatório"),
  typeOfMeasure: Yup.string()
    .required("Unidade de medida é obrigatória")
    .typeError("Campo deve ser uma string")
    .max(255)
};

export const validationSchema = Yup.object().shape({
  ...validationDefault
});
