import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "1.35rem",
    color: "#353535",
    marginTop: "0.2rem",
    marginBottom: "1.89rem"
  },
  alignBox: {
    display: "flex",
    flexDirection: "row"
  },
  label: {
    fontFamily: "Inter",
    fontWeight: 550,
    fontSize: "0.9rem",
    lineHeight: "1.35rem",
    color: "#757575",
    [theme.breakpoints.between(0, 500)]: {
      fontSize: "0.8rem"
    }
  },
  formControl: {
    minWidth: "35rem",
    [theme.breakpoints.between(530, 750)]: {
      minWidth: "22rem"
    },
    [theme.breakpoints.between(0, 530)]: {
      minWidth: "10rem"
    }
  },
  formControlLabel: {
    width: "8rem",
    height: "3rem",
    background: "#F5F6F7",
    border: "2px solid #F5F6F7",
    borderRadius: "0.5rem",
    paddingLeft: "0.8rem",
    fontFamily: "Roboto",
    fontSize: "0.9rem",
    fontWeight: 400,
    lineHeight: "1.35rem",
    color: "#757575",
    marginRight: "1rem"
  },
  bigInput: {
    width: "100%",
    [theme.breakpoints.between(0, 800)]: {
      width: "80%"
    }
  },
  button: {
    marginTop: "2rem",
    width: "7rem",
    height: "3rem",
    borderRadius: "0.3rem"
  }
}));
