import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import MapMarker from "assets/icons/Address/MapMarker.svg";
import MapMarkerShadow from "assets/icons/Address/MapMarkerShadow.svg";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import L from "leaflet";
import React, { useEffect, useRef } from "react";
import "leaflet/dist/leaflet.css";
import { URL_MAP_TILE } from "settings";
import { Dialog } from "shared";

const markerIcon = new L.Icon({
  iconUrl: MapMarker,
  shadowUrl: MapMarkerShadow,
  iconAnchor: [13, 33],
  iconSize: [24, 32],
  shadowSize: [16, 9],
  shadowAnchor: [9, 5]
});

const MapDialog = ({
  open,
  initialPosition,
  radius = 60000,
  setOpen,
  onCoordinateChange
}) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const { addPopup } = usePopup();
  const [isMounted, setIsMounted] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsMounted(false);
  };

  const mountMap = () => {
    if (open && mapRef.current && !isMounted) {
      setIsMounted(true);

      const map = L.map(mapRef.current).setView(initialPosition, 18);

      L.tileLayer(URL_MAP_TILE, {
        id: "mapbox/streets-v11",
        maxZoom: 20
      }).addTo(map);

      const marker = L.marker(initialPosition, {
        draggable: true,
        icon: markerIcon
      }).addTo(map);
      markerRef.current = marker;

      marker.on("dragend", () => {
        const { lat, lng } = marker.getLatLng();
        const distance = map.distance(initialPosition, [lat, lng]);

        if (distance > radius) {
          marker.setLatLng(initialPosition);
          addPopup({
            type: "error",
            title: "Limite de raio excedido",
            message: "Arraste o marcador dentro do limite."
          });
        }

        map.setView([lat, lng]);
      });

      L.circle(initialPosition, {
        radius,
        color: "blue",
        opacity: 0.5,
        fillOpacity: 0.1
      }).addTo(map);

      return () => {
        map.off();
        map.remove();
      };
    }
  };

  const handleFinish = () => {
    if (markerRef.current) {
      const { lat, lng } = markerRef.current.getLatLng();
      onCoordinateChange([lat, lng]);
      addPopup({
        type: "success",
        title: "Localização ajustada com sucesso.",
        message: "A nova coordenada foi salva."
      });
      handleClose();
    }
  };

  useEffect(() => {
    if (open && initialPosition && !isMounted) {
      const intervalId = setInterval(() => {
        if (mapRef.current) {
          const cleanup = mountMap();
          clearInterval(intervalId);
          return cleanup;
        }
      }, 100);
    }
  }, [open, initialPosition, radius, onCoordinateChange]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth
      title={"Selecione a localização exata"}
    >
      <DialogContent style={{ padding: "0" }}>
        <div ref={mapRef} style={{ height: "450px", width: "100%" }} />
      </DialogContent>
      <DialogActions style={{ marginTop: "16px", padding: "16px 0" }}>
        <Button
          onClick={handleFinish}
          variant="contained"
          fullWidth
          style={{ fontSize: "1rem", padding: "12px 0" }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapDialog;
