import { Box, CircularProgress, Typography } from "@material-ui/core";
import { addDays } from "date-fns";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import batchServiceV3 from "services/batchServiceV3";
import printService from "services/printService";
import { mapPurchaseType, mapType } from "utils";

import {
  LoadingContent,
  ProductInfo,
  ProductText,
  StyledDialog,
  Image
} from "../../style";
import { ConfirmPage } from "./ConfirmPage";
import { PrintTag } from "./PrintTag";
import { SelectionPage } from "./SelectionPage";
import { WastePage } from "./WastePage";

export const SelectionPopup = ({
  openSelectionPopup,
  handleCloseSelectionPopup,
  detailBatchData,
  reloadData
}) => {
  const { addPopup } = usePopup();
  const [activePage, setActivePage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [batchClassification, setBatchClassification] = useState("");

  const purchaseType = detailBatchData.product?.purchase_type;
  const typeOfMeasure = mapPurchaseType(purchaseType);

  const changeActivePage = page => {
    setActivePage(page);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const batchId = detailBatchData.id;

      const selectionData = {
        classification: batchClassification
      };

      await batchServiceV3.selectionV3(batchId, selectionData);

      reloadData();
      changeActivePage(3);
      addPopup({
        type: "success",
        title: "Seleção salva com sucesso",
        description: "Lote selecionado e classificado com sucesso"
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao salvar seleção",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePrintTag = async data => {
    const subBatchId = detailBatchData.sub_batch[0].id;
    try {
      setLoading(true);
      const qty = data.quantity;
      if (!qty) {
        handleCloseSelectionPopup();
        return;
      }
      const tagData = {
        qty,
        isReceipt: true
      };
      await printService.printBatchTag(subBatchId, tagData);

      addPopup({
        type: "success",
        title: "Tudo certo!",
        description: "Etiquetas enviadas para fila de impressão"
      });
      handleCloseSelectionPopup();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro de impressão",
        description: "Erro ao imprimir, tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveWaste = async (formData, closeOnSave) => {
    try {
      setLoading(true);
      if (formData.quantity > 0) {
        await batchServiceV3.postBatchMove(detailBatchData.id, formData);
      } else {
        await batchServiceV3.patchBatch(detailBatchData.id, {
          selection_step: 1
        });
      }
      addPopup({
        type: "success",
        title: "Movimentação realizada com sucesso"
      });
      reloadData();
      closeOnSave ? handleCloseSelectionPopup() : changeActivePage(1);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao realizar movimentação",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const page = {
    0: (
      <WastePage
        batchData={detailBatchData}
        handleSubmit={handleSaveWaste}
        changeActivePage={changeActivePage}
        close={handleCloseSelectionPopup}
      />
    ),
    1: (
      <SelectionPage
        batchData={detailBatchData}
        changeActivePage={changeActivePage}
        close={handleCloseSelectionPopup}
        batchClassification={batchClassification}
        setBatchClassification={setBatchClassification}
      />
    ),
    2: (
      <ConfirmPage
        classification={batchClassification}
        typeOfMeasure={typeOfMeasure}
        changeActivePage={changeActivePage}
        handleSubmit={handleSubmit}
      />
    ),
    3: <PrintTag handleSubmit={handlePrintTag} />
  }[activePage];

  useEffect(() => {
    setActivePage(detailBatchData?.selection_step);
    setBatchClassification("");
  }, [openSelectionPopup]);

  const popupTitle =
    {
      0: "Inserir Waste",
      1: "Seleção",
      2: "Confirmação",
      3: "Imprimir etiqueta"
    }[activePage] || "Seleção";

  return (
    <StyledDialog
      open={openSelectionPopup}
      handleClose={handleCloseSelectionPopup}
      title={popupTitle}
    >
      <ProductInfo>
        <Box>
          <ProductText>
            Lote: <strong>{detailBatchData.id}</strong>
          </ProductText>
          <ProductText>
            Produto: <strong>{detailBatchData.productName}</strong>
          </ProductText>
          <ProductText>
            Seller: <span>{detailBatchData.seller?.name}</span>
          </ProductText>
          <ProductText>
            Porção:{" "}
            <span>{mapType(detailBatchData.product?.purchase_type)}</span>
          </ProductText>
          {popupTitle === "Inserir Waste" && (
            <ProductText>
              <span>
                {detailBatchData?.hasDeadline &&
                  `${
                    detailBatchData.deadlineExpired
                      ? "Prazo de devolução vencido"
                      : `Devolução até ${detailBatchData.dateDeadline}`
                  }`}
              </span>
            </ProductText>
          )}
        </Box>
        <Image src={detailBatchData.product?.image} />
      </ProductInfo>
      {loading ? (
        <LoadingContent>
          <Typography>
            Registrando as informações, por favor aguarde...
          </Typography>
          <CircularProgress color="primary" />
        </LoadingContent>
      ) : (
        page
      )}
    </StyledDialog>
  );
};
