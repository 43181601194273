import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
    [theme.breakpoints.between(0, 600)]: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem"
    }
  },
  containerSearch: {
    display: "flex",
    flex: 1,
    maxWidth: "470px",
    gap: "0.5rem"
  },
  containerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1
  },
  titleStock: {
    color: "#333333",
    fontSize: "1.2rem",
    margin: "10px 0 -20px"
  }
}));
