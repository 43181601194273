import * as Yup from "yup";

const validationDefault = {
  quantity_invoice: Yup.number()
    .positive("Quantidade media deve ser positivo")
    .typeError("Campo deve ser um número"),
  url_invoice: Yup.string().typeError("Campo deve ser uma string"),
  observation: Yup.string().typeError("Campo deve ser uma string").max(255)
};

export const validationSchemaCreate = Yup.object().shape(validationDefault);
