import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { CustomLineProgress } from "components/LineProgress";
import { Observer } from "components/Observer";
import { ModalCount } from "pages/private/BatchCount/components/ModalCount";
import { Fragment, useEffect, useState } from "react";

import {
  BatchInfo,
  BatchTableContainer,
  BatchTableContent,
  BatchTitle
} from "../../styles";
import { DueProgress } from "../DueProgress";

export const DashBatchTable = ({ batchData, loading, reload }) => {
  const [visibleItems, setVisibleItems] = useState(5);
  const [isModalCountOpen, setIsModalCountOpen] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState({});

  const loadMoreItems = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + 5);
  };

  const handleBatchQuantityCount = batch => {
    setSelectedBatch(batch);
    setIsModalCountOpen(true);
  };

  const handleOnCloseBatchQuantityCount = () => {
    setIsModalCountOpen(false);
  };

  useEffect(() => {
    setVisibleItems(5);
  }, batchData);

  return (
    <>
      <BatchTableContainer>
        {!!loading && <CustomLineProgress />}
        <BatchTableContent>
          <BatchTitle>Dias p/ vencimento</BatchTitle>

          <BatchTitle>Lote</BatchTitle>
          <BatchTitle style={{ justifyContent: "start" }}>Produto</BatchTitle>
          <BatchTitle>Classe</BatchTitle>
          <BatchTitle>Shelf life</BatchTitle>
          <BatchTitle></BatchTitle>

          {batchData?.slice(0, visibleItems).map(batch => (
            <Fragment key={batch.id}>
              <DueProgress daysToDude={batch.days_to_dude} />

              <BatchInfo
                style={{ borderRadius: "0.5rem 0 0 0.5rem", fontWeight: 500 }}
              >
                {batch.id}
              </BatchInfo>
              <BatchInfo style={{ justifyContent: "start" }}>
                {batch.product?.name}
              </BatchInfo>
              <BatchInfo>{batch.classification}</BatchInfo>
              <BatchInfo style={{ borderRadius: "0 0.5rem 0.5rem 0" }}>
                {batch.product?.shelf_life}
              </BatchInfo>
              <BatchInfo>
                <Button
                  style={{ color: "black" }}
                  onClick={() => handleBatchQuantityCount(batch)}
                >
                  <EditIcon />
                </Button>
              </BatchInfo>
            </Fragment>
          ))}
          <Observer callback={loadMoreItems} />
        </BatchTableContent>
      </BatchTableContainer>
      {selectedBatch && (
        <ModalCount
          isOpen={isModalCountOpen}
          onBack={() => setIsModalCountOpen(false)}
          onClose={handleOnCloseBatchQuantityCount}
          data={selectedBatch}
          reload={reload}
        />
      )}
    </>
  );
};
