import { Table, TableBody, TableHead } from "@material-ui/core";
import { TableRow } from "shared";

import { BodyCell, HeadCell } from "../../style";

export const SubBatchTable = ({ subBatchData }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <HeadCell>ID</HeadCell>
          <HeadCell>Código</HeadCell>
          <HeadCell>Classificação</HeadCell>
          <HeadCell>Quantidade</HeadCell>
          <HeadCell>Estágio</HeadCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ width: "100%" }}>
        {subBatchData?.map(subBatch => (
          <TableRow key={subBatch.id}>
            <BodyCell>{subBatch.id}</BodyCell>
            <BodyCell style={{ minWidth: "10rem" }}>
              {subBatch.external_code}
            </BodyCell>
            <BodyCell>{subBatch.classification}</BodyCell>
            <BodyCell>{subBatch.subBatchQty}</BodyCell>
            <BodyCell>{subBatch.stageName}</BodyCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
