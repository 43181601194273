import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";

import { AuthProvider } from "./useAuth";
import { PupupProvider } from "./usePopup";

const AppProvider = ({ children }) => (
  <PupupProvider>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <AuthProvider>{children}</AuthProvider>
    </MuiPickersUtilsProvider>
  </PupupProvider>
);

export default AppProvider;
