import { Box } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { ContainerPage, Button, TitlePage, PermissionGate } from "components";
import { OptionsMenu } from "components/OptionsMenu";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import React, { useEffect, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { MdModeEditOutline } from "react-icons/md";
import blitzService from "services/blitzService";
import productApiServices from "services/productApiServices";
import { Table } from "shared";
import { mapStatesString, typeMapping } from "utils";

import { ModalPrint } from "../ProductBase/components/ModalPrint/Index";
import { ModalCreated } from "./components/ModalCreated";
import { ModalDownloadPdf } from "./components/ModalDownloadPdf";
import { ModalUpdateProductBase } from "./components/ModalUpdateProductBase";

export default function ProductBase() {
  const { addPopup } = usePopup();
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => productApiServices.getProductBase(params)
    });

  const [openNew, setOpenNew] = useState(false);
  const [dialogEdit, setDialogEdit] = useState({
    isOpen: false,
    data: {}
  });
  const [dialogPrint, setDialogPrint] = useState({
    isOpen: false,
    data: {}
  });
  const [dialogDownloadPdf, setDialogDownloadPdf] = useState({
    isOpen: false,
    data: {}
  });
  const [productCategoryData, setProductCategoryData] = useState([]);
  const [productSubCategoryData, setProductSubCategoryData] = useState([]);
  const [blitzData, setBlitzData] = useState([]);

  const getInitData = async () => {
    try {
      const [categoryResp, subcategoryResp] = await Promise.all([
        productApiServices.getCategories(),
        productApiServices.getSubCategories()
      ]);
      setProductCategoryData(categoryResp.data);
      setProductSubCategoryData(subcategoryResp.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar subcategorias",
        description: errorMessage
      });
    }
  };

  const getBlitzData = async () => {
    try {
      const { data } = await blitzService.getBlitzData({
        pageSize: 4000
      });
      setBlitzData(data.results);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao verificar regras de blitz dos lotes",
        description: errorMessage
      });
    }
  };

  const handleSetOpenNew = () => {
    setOpenNew(false);
  };

  const options = [
    {
      title: "Editar",
      icon: MdModeEditOutline,
      permissions: [
        "CD",
        "Tech",
        "Stock View",
        "Previsão",
        "Estoque",
        "Estoque físico",
        "Logistic"
      ],
      action: data =>
        setDialogEdit({
          isOpen: true,
          data
        })
    },
    {
      title: "Imprimir etiqueta",
      icon: AiFillPrinter,
      permissions: [
        "CD",
        "Tech",
        "Stock View",
        "Previsão",
        "Estoque",
        "Estoque físico",
        "Logistic"
      ],
      action: data =>
        setDialogPrint({
          isOpen: true,
          data
        })
    },
    {
      title: "Baixar PDF",
      icon: FiDownload,
      permissions: [
        "CD",
        "Tech",
        "Stock View",
        "Previsão",
        "Estoque",
        "Estoque físico",
        "Logistic"
      ],
      action: data =>
        setDialogDownloadPdf({
          isOpen: true,
          data
        })
    }
  ];

  const columns = [
    {
      field: "image",
      label: "Imagem",
      align: "center",
      style: { minWidth: "50px" },
      renderCell: ({ data }) => (
        <img
          style={{ width: 50, height: 50 }}
          src={data.image}
          title={data.name}
        />
      )
    },
    {
      field: "id",
      label: "Id",
      align: "center",
      style: { minWidth: "70px" }
    },
    {
      field: "name",
      label: "Nome",
      style: { minWidth: "250px" }
    },
    {
      field: "status",
      label: "Status",
      align: "center",
      style: { minWidth: "130px" },
      formatter: value => mapStatesString(value)
    },
    {
      field: "shelfLife",
      label: "Validade",
      align: "center",
      style: { minWidth: "160px" }
    },
    {
      field: "unitWeight",
      label: "Peso Unitário",
      align: "center",
      style: { minWidth: "120px" },
      formatter: value => {
        return new Intl.NumberFormat("pt-BR").format(value?.toFixed(2));
      }
    },
    {
      field: "purchaseType",
      label: "Tipo de entrada",
      align: "center",
      style: { minWidth: "160px" },
      formatter: value => typeMapping(value)
    },
    {
      field: "reopen",
      label: "Reabrir",
      formatter: value => (value ? "Sim" : "Não")
    },
    {
      field: "",
      label: "",
      align: "center",
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  const newData = data.map(item => {
    return {
      ...item,
      shelfLife: `
         A: ${item.shelfLifeA ?? 0} |
         B: ${item.shelfLifeB ?? 0} |
         C: ${item.shelfLifeC ?? 0}
      `
    };
  });

  useEffect(() => {
    getInitData();
    getBlitzData();
  }, []);

  return (
    <>
      <ContainerPage>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <TitlePage>Produtos</TitlePage>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            ml="auto"
            style={{ gap: "1rem", marginRight: "1rem" }}
          >
            <PermissionGate
              permissions={["CD", "Tech", "Previsão", "Estoque", "Logistic"]}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setOpenNew(true);
                }}
                startIcon={<AddRoundedIcon />}
              >
                Novo Produto
              </Button>
            </PermissionGate>
            <Button
              variant="contained"
              onClick={() => {
                setDialogPrint({ isOpen: true });
              }}
            >
              Ler QRCode
            </Button>
          </Box>
        </Box>

        <Table
          title="Últimos Produtos Adicionados"
          handleSearch={handleSearch}
          columns={columns}
          data={newData}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          filterProps={[
            {
              parameter: "reopen",
              label: "Reabertura",
              values: [
                { id: "false", label: "Não" },
                { id: "true", label: "Sim" }
              ]
            },
            {
              parameter: "isDropshipping",
              label: "Dropshipping",
              values: [
                { id: "false", label: "Não" },
                { id: "true", label: "Sim" }
              ]
            }
          ]}
        />
      </ContainerPage>
      <ModalCreated
        isOpen={openNew}
        refresh={refresh}
        close={handleSetOpenNew}
        productCategoryData={productCategoryData}
        productSubCategoryData={productSubCategoryData}
      />
      <ModalUpdateProductBase
        isOpen={dialogEdit.isOpen}
        refresh={refresh}
        close={setDialogEdit}
        dataProduct={dialogEdit.data}
        productCategoryData={productCategoryData}
        productSubCategoryData={productSubCategoryData}
      />

      <ModalPrint
        close={setDialogPrint}
        isOpen={dialogPrint.isOpen}
        dataProduct={dialogPrint.data}
        blitzData={blitzData}
      />

      <ModalDownloadPdf
        isOpen={dialogDownloadPdf.isOpen}
        close={setDialogDownloadPdf}
        data={dialogDownloadPdf.data}
      />
    </>
  );
}
