import { Box, Paper, Typography, styled } from "@material-ui/core";

export const CardContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  paddingBlock: "1rem"
});

export const CardContent = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "1rem"
});

export const CardInfoContainer = styled(Box)({
  display: "flex"
});

export const CardInfoContent = styled(Box)({
  flex: 1
});

export const CardTitle = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "0.875rem",
  color: "#757575"
});

export const CardValue = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.875rem",
  color: "#757575"
});

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});
