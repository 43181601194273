import { LinearProgress, makeStyles } from "@material-ui/core";
import { GridOverlay } from "@material-ui/data-grid";

const useStyles = makeStyles({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#225249"
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#c4c4c4"
    }
  }
});

export const CustomLoading = () => {
  const classes = useStyles();

  return (
    <GridOverlay>
      <div
        style={{ position: "absolute", top: 0, width: "100%" }}
        className={classes.root}
      >
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};
