import {
  styled,
  Box,
  Typography,
  IconButton,
  withStyles,
  StepConnector,
  Step as StepMui
} from "@material-ui/core";
import { Dialog } from "shared";

export const ColorlibConnector = withStyles({
  root: {
    zIndex: 0,
    left: "calc(-50% + 11px)",
    right: "calc(50% + 11px)"
  },
  active: {
    "& $line": {
      background: "#587916"
    }
  },
  completed: {
    "& $line": {
      background: "#587916"
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#e0e0e0"
  }
})(StepConnector);

export const Step = styled(StepMui)({
  "& .MuiStepLabel-iconContainer": {
    position: "relative",
    zIndex: 2
  },

  "& text": {
    fill: "#757575",
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600
  },
  "& .MuiSvgIcon-root": {
    color: "#e0e0e0"
  },
  "& .MuiStepIcon-active, & .MuiStepIcon-completed": {
    color: "#587916"
  },
  "& .MuiStepIcon-active text, & .MuiStepIcon-completed text": {
    fill: "#ffffff"
  }
});

export const ProductInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  background: "#f5f8f3",
  padding: "1.06rem 0"
});

export const ProductText = styled(Typography)({
  marginLeft: "1.5rem",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#757575",
  "& span": {
    fontWeight: 600
  },
  "& strong": {
    color: "#000000"
  }
});

export const DialogDefault = styled(Dialog)(({ theme }) => ({
  maxWidth: "32rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": {
    width: "100%",
    padding: 0,
    margin: 0
  }
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  maxWidth: "32rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": {
    width: "100%",
    height: "73%",
    padding: 0,
    margin: 0
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      height: "100%"
    }
  }
}));

export const PopupContent = styled(Box)({
  display: "flex",
  position: "relative",
  height: "calc(100% - 92px)",
  flexDirection: "column",
  gap: "0.5rem"
});

export const ButtonsContent = styled(Box)({
  display: "flex",
  gap: "0.5rem",
  width: "100%",
  paddingBlock: "1rem",
  marginTop: "auto",
  flexDirection: "column",
  justifyContent: "flex-end",
  "& > div": {
    display: "flex",
    gap: "6px"
  }
});

export const LoadingContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

export const LoadingBlitzContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "20vh",
  height: "100%"
});

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
});

export const Text = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "1.1rem",
  color: "#757575"
});

export const TextInfoAlert = styled("p")({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#fb0303c4"
});

export const TextInfo = styled("p")({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#757575"
});

export const RuleContent = styled(Box)({
  flexDirection: "column",
  borderRadius: "16px",
  textAlign: "left",
  paddingTop: "0.7rem",
  background: "#E8F1F8"
});

export const RuleQty = styled(Typography)({
  marginTop: "0.4rem",
  marginLeft: "1.5rem",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#757575",
  "& span": {
    fontWeight: 600
  },
  "& strong": {
    color: "#000000"
  }
});

export const RuleInformation = styled(Typography)({
  marginTop: "0.4rem",
  marginLeft: "1.5rem",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#0D47A1",
  "& p": {
    marginTop: "0.4rem",
    fontWeight: 500
  }
});

export const FieldContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const FieldContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  gap: "1rem"
}));

export const UploadImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  maxHeight: "14.5rem",
  "& .MuiBox-root": {
    display: "flex",
    maxWidth: "100%",
    width: "100%",
    maxHeight: "14.5rem",
    "& img": {
      maxWidth: "7rem"
    }
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiBox-root": {
      maxWidth: "100%",
      width: "100%",
      "& img": {
        maxWidth: "100%"
      }
    }
  }
}));

export const PrintButton = styled(IconButton)({
  backgroundColor: "#E5ECD1",
  "&:hover": {
    backgroundColor: "#E5ECD1",
    opacity: 0.8
  },
  "&:disabled": {
    opacity: 0
  }
});

export const NewBatchButton = styled(IconButton)({
  backgroundColor: "#89BD23",
  "& svg": {
    color: "#FFF"
  },
  "&:hover": {
    backgroundColor: "#89BD23",
    opacity: 0.8
  },
  "&:disabled": {
    opacity: 0
  }
});

export const StepTitle = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "1rem",
  color: "#353535",
  textAlign: "center"
});
