import { Box, Button, CircularProgress } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import React, { useState } from "react";
import { Dialog, InfoCard } from "shared";

import { Text } from "../styles";

const InactiveProductDialog = ({ open, handleClose, data }) => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);

  const handleOperation = async () => {
    handleClose();
  };

  const content = {
    true: (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin="2rem 0"
      >
        <CircularProgress color="primary" />
      </Box>
    ),
    false: (
      <>
        <Box display="flex" marginTop="1rem" style={{ gap: "1rem" }}>
          <Button onClick={handleOperation} variant="contained" fullWidth>
            Entendi
          </Button>
        </Box>
      </>
    )
  }[loading];

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Produtos Inativos"
      maxWidth="32rem"
    >
      <Text style={{ marginBottom: "1rem" }}>
        Um ou mais produtos enviados estão inativos, é recomendado que sejam
        ativados antes de prosseguir com a operação.
      </Text>
      <Box
        display="flex"
        style={{
          gap: "1rem",
          flexDirection: "column",
          overflowY: "auto",
          maxHeight: "35vh"
        }}
      >
        {Object.values(data).map(product => (
          <InfoCard
            data={{
              Produto: {
                text: product?.product_base?.name || product?.productBase?.name,
                useStrong: true
              },
              Seller: {
                text: product?.seller?.name || "Não informado"
              },
              Previsão: {
                text: product?.prediction
              }
            }}
            key={product.id}
          />
        ))}
      </Box>
      {content}
    </Dialog>
  );
};

export default InactiveProductDialog;
