import {
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Button,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { forwardRef, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { usePopup } from "../../hooks/usePopup";
import { useStyles } from "./styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogUpload = ({
  isOpen,
  handleClose,
  refresh,
  errorMessage,
  successMessage,
  service
}) => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [fileName, setFileName] = useState("");

  const onDropAccepted = useCallback(acceptedFiles => {
    setFileName(acceptedFiles[0]?.name);
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    setFile(formData);
  }, []);

  const handleCloseDialog = () => {
    handleClose(false);
    setProgress(null);
    setFile(null);
    setFileName("");
  };

  const handleUpdateStock = async () => {
    try {
      const { api, url } = service.uploadFile();

      await api.put(url, file, {
        onUploadProgress: e => {
          const newProgress = parseInt(Math.round((e.loaded * 100) / e.total));
          setProgress(newProgress);
        }
      });

      addPopup({
        type: "success",
        title: successMessage
      });
      refresh();
      handleCloseDialog();
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "A atualização falhou",
          description: errorMessage
        });
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject
  } = useDropzone({
    onDropAccepted,
    accept: ".xlsx, .xls, .xml, .csv"
  });

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleCloseDialog}
      keepMounted
      className={classes.dialog}
    >
      <DialogContent>
        <IconButton onClick={handleCloseDialog} className={classes.closeBtn}>
          <CloseIcon />
        </IconButton>

        <div
          {...getRootProps({
            className: `${classes.dropzone}
          ${isDragActive && classes.dragActive}
          ${isDragAccept && classes.dragAccept}
          ${isDragReject && classes.dragReject}`
          })}
        >
          <input {...getInputProps()} disabled={progress !== null} />
          {progress === 100 ? (
            <Typography style={{ display: "flex", gap: 10 }}>
              <CheckCircleOutlineIcon />
              Arquivo enviado com sucesso
            </Typography>
          ) : isDragReject ? (
            <Typography>O tipo desse arquivo não é aceito</Typography>
          ) : (
            <Typography>
              {fileName === ""
                ? "Arraste e solte o arquivo aqui ou clique para selecionar o arquivo"
                : fileName}
            </Typography>
          )}
        </div>

        {progress === null || progress === 100 ? (
          <Button
            variant="contained"
            className={classes.btnUpload}
            onClick={handleUpdateStock}
            disabled={progress !== null}
          >
            {progress === 100 ? (
              <CircularProgress size={24} style={{ color: "#7080df" }} />
            ) : (
              "Enviar"
            )}
          </Button>
        ) : (
          <div className={classes.progress}>
            <LinearProgress
              variant="buffer"
              value={progress}
              valueBuffer={100}
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
