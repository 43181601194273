import { Box } from "@material-ui/core";
import { TitlePage, ContainerPage, Button } from "components";
import { useTableParams } from "hooks/useTableParams";
import { useMemo, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import depotService from "services/depotService";
import { Table } from "shared";

import { ModalCreate } from "./components/ModalCreate";
import { ModalEdit } from "./components/ModalEdit";
import { tableColumns } from "./components/tableColumns";
import { dataFormatter } from "./utils/dataFormatter";

export const PackagingTypesPage = () => {
  const columns = tableColumns(handleOpenEdit);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [packagingDetail, setPackagingDetail] = useState(null);
  const { data, params, count, onChange, refresh, status, handleSearch } =
    useTableParams({
      service: params => depotService.listPackagingType(params)
    });

  const formattedData = useMemo(() => {
    if (status === "pending") return [];
    return dataFormatter(data);
  }, [data, status]);

  function handleOpenEdit(data) {
    setPackagingDetail(data);
    setOpenEdit(true);
  }

  function handleCloseEdit(data) {
    setPackagingDetail(null);
    setOpenEdit(false);
  }

  function handleOpenCreate() {
    setPackagingDetail(null);
    setOpenCreate(true);
  }

  function handleCloseCreate(data) {
    setOpenCreate(false);
  }

  return (
    <>
      <ContainerPage>
        <TitlePage>Embalagens</TitlePage>

        <Table
          placeholder="ID ou tipo de embalagem"
          handleSearch={handleSearch}
          columns={columns}
          data={formattedData}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={count}
          filters={
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flex: "1"
              }}
            >
              <Button
                variant="contained"
                startIcon={<IoMdAdd />}
                onClick={handleOpenCreate}
              >
                Adicionar
              </Button>
            </Box>
          }
        />

        <ModalCreate
          handleClose={handleCloseCreate}
          open={openCreate}
          refresh={refresh}
        />
        <ModalEdit
          data={packagingDetail}
          handleClose={handleCloseEdit}
          open={openEdit}
          refresh={refresh}
        />
      </ContainerPage>
    </>
  );
};
