import { mapPurchaseType } from "utils";

const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return {
    date,
    time
  };
};

const formatDeadlineForAnalysis = batch => {
  const receiptDate = new Date(batch?.batch.receipt_date);
  receiptDate.setDate(
    receiptDate.getDate() + (batch.deadline_for_analysis ?? 0)
  );
  const formatted = formatDateTime(receiptDate);
  return `${formatted.date} ${formatted.time}`;
};

const formatCombinedReturnDeadline = batch => {
  const updatedDate = new Date(batch?.updated);
  updatedDate.setDate(
    updatedDate.getDate() + (batch.combined_return_deadline ?? 0)
  );
  const formatted = formatDateTime(updatedDate);
  return `${formatted.date} ${formatted.time}`;
};

export const formatData = data => {
  return (data || []).reduce((acc, batch) => {
    const typeOfMeasure = mapPurchaseType(batch.product?.purchase_type);
    const batchQuantity = batch.quantity;
    const formattedWeight = [batchQuantity || 0].toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3
    });

    const quantity =
      typeOfMeasure === "Kg" ? `${formattedWeight} Kg` : `${batchQuantity} Un`;
    const formatted = {
      id: batch.id,
      batchId: batch.batch?.id,
      createdDate: formatDateTime(batch?.created).date,
      createdTime: formatDateTime(batch?.created).time,
      updatedDate: formatDateTime(batch?.updated).date,
      updatedTime: formatDateTime(batch?.updated).time,
      receiptDate: formatDateTime(batch?.batch.receipt_date).date,
      receiptTime: formatDateTime(batch?.batch.receipt_date).time,
      seller: batch.seller?.name,
      productName: batch.product?.name,
      quantity,
      reason: batch.reason?.name,
      status: batch.status?.name,
      stageId: batch.stage?.id,
      stage: batch.stage?.name,
      statusId: batch.status?.id,
      createdBy: batch.created_by?.username,
      updatedBy: batch.updated_by?.username,
      instructions: batch.refusal_instructions,
      combinedReturnDeadline: formatCombinedReturnDeadline(batch),
      fomattedCombinedReturnQty:
        batch.combined_return_qty &&
        `${batch.combined_return_qty} ${typeOfMeasure}`,
      sellerWillPickup: batch.seller_will_pickup ? "Sim" : "Não",
      instructionsEditedBy: batch.instructions_edited_by,
      deadlineForAnalysis:
        !!batch.deadline_for_analysis && formatDeadlineForAnalysis(batch),
      purchaseItem: batch.batch.purchase_item
    };
    return [...acc, formatted];
  }, []);
};
