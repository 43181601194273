import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class ReturnAnalysisService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async returnAnalysisConfirmed(data, id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storage/confirm/${id}`;
    return await api.put(url, data);
  }
}

const returnAnalysisService = new ReturnAnalysisService();
export default returnAnalysisService;
