import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  dialog: {
    "& .MuiPaper-root": {
      padding: "5px",
      width: "100%",
      maxWidth: "400px"
    }
  },
  dropzone: {
    background: "rgb(139,195,74,20%)",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    padding: "15px 10px",
    cursor: "pointer",
    width: "100%",
    maxWidth: "350px",
    border: "#8bc34a dashed 2px",
    outline: "none",
    textAlign: "center",
    marginBottom: "15px",
    color: "#a7b1eb",
    transition: "background 0.2s",
    "& p": {
      fontWeight: "bold"
    }
  },
  dragActive: {
    background: "rgb(167, 177, 235, 40%);"
  },
  dragAccept: {
    background: "rgb(139, 195, 74, 40%)"
  },
  btnUpload: {
    width: "100%",
    backgroundColor: "#8bc34a",
    color: "#fff",
    padding: "10px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#7ab633",
      boxShadow: "none"
    }
  },
  closeBtn: {
    background: "#f1f1f5",
    position: "absolute",
    top: 8,
    right: 8,
    width: 25,
    height: 25
  },
  progress: {
    margin: "25px 0 20px"
  }
});
