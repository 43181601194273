import { Paper } from "@material-ui/core";
import { Paginate } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import batchService from "services/batchService";
import { useDebaunce } from "utils";

import { formatData } from "../utils/formatData";
import { BatchTable } from "./BatchTable";
import { ConfirmPopup } from "./ConfirmPopup";
import {
  CustomTableContainer,
  PaginateContent,
  SearchContent,
  SearchInput
} from "./style";

export const EntryTable = () => {
  const { addPopup } = usePopup();

  const [batchData, setBatchData] = useState([]);
  const [batchDetail, setBatchDetail] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const controller = new AbortController();
  const signal = controller.signal;

  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });

  const getInitData = async () => {
    try {
      setLoading(true);
      setBatchData([]);

      const params = {
        status: 2,
        purchaseItemReasonId: 9,
        page,
        search,
        signal
      };
      const { data } = await batchService.getBatches(params);

      setBatchData(formatData(data.results));
      setTotalPages(data.pageSize);
    } catch (error) {
      if (!signal.aborted) {
        const errorMessage = error?.response?.data?.msg;
        addPopup({
          type: "error",
          title: "Erro ao carregar lotes",
          description: errorMessage
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmEntry = async data => {
    try {
      setUpdateLoading(true);
      const { id } = batchDetail;
      await batchService.confirmEntryV2(id, data);
      addPopup({
        type: "success",
        title: "Entrada confirmada",
        description: `Lote ${id} atualizado com sucesso`
      });
      resetPage();
      getInitData();
      setOpenConfirm(false);
    } catch (error) {
      if (!signal.aborted) {
        const errorMessage = error?.response?.data?.msg;
        addPopup({
          type: "error",
          title: "Erro confirmar entrada",
          description: errorMessage
        });
      }
    } finally {
      setUpdateLoading(false);
    }
  };

  function resetPage() {
    setPage(1);
  }
  function handleChangePage(e) {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
  }

  function handleSearch(e) {
    const { value } = e.target;
    resetPage();
    setSearch(value);
  }

  const handleOpenConfirm = batchId => {
    const data = batchData.find(({ id }) => id === batchId);
    setBatchDetail(data);
    setOpenConfirm(true);
  };

  const handleConfirm = e => {
    const toConfirm = e.currentTarget.name;
    const data = {
      toConfirm
    };
    confirmEntry(data);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  useEffect(() => {
    getInitData();
    return () => {
      controller.abort();
    };
  }, [page, search]);

  return (
    <>
      <CustomTableContainer component={Paper}>
        <SearchContent>
          <SearchInput
            placeholder="Seller ou produto"
            onChange={debaunceSearch}
          />
        </SearchContent>

        <BatchTable
          batchData={batchData}
          loading={loading}
          handleOpenConfirm={handleOpenConfirm}
        />

        <PaginateContent>
          {totalPages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={page - 1}
              onPageChange={handleChangePage}
              pageRangeDisplayed={3}
              pageCount={totalPages}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
              loading={loading}
            />
          )}
        </PaginateContent>
      </CustomTableContainer>
      <ConfirmPopup
        data={batchDetail}
        handleCloseConfirm={handleCloseConfirm}
        openConfirm={openConfirm}
        handleConfirm={handleConfirm}
        loading={updateLoading}
      />
    </>
  );
};
