import { Box } from "@material-ui/core";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PersonIcon from "@material-ui/icons/Person";
import { Button, PermissionGate } from "components";
import { BiTransfer } from "react-icons/bi";

import { CustomDivider } from "../components/CustomDivider";
import {
  CardTitle,
  CardValue,
  ContainerWithIcon,
  ContentWithIcon,
  DetailCardContainer,
  DetailCardContent,
  DetailContainer,
  DetailContent,
  InfoIconContent,
  InfoId,
  InfoIdContent,
  TitleDetail
} from "../style";

export const Detail = ({ subBatchData, handleOpenMovement }) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <TitleDetail>Dados do lote</TitleDetail>
        <PermissionGate permissions={["Admin", "Tech"]}>
          <Button
            variant="contained"
            startIcon={<BiTransfer />}
            onClick={handleOpenMovement}
          >
            Movimentar
          </Button>
        </PermissionGate>
      </Box>
      <DetailContainer>
        <DetailContent>
          <InfoIdContent elevation={3}>
            Código: <InfoId>{subBatchData.externalCode}</InfoId>
          </InfoIdContent>
          <DetailCardContainer>
            <ContainerWithIcon
              elevation={3}
              style={{ borderRadius: "0 0.5rem 0.5rem 0.5rem" }}
            >
              <InfoIconContent>
                <DonutLargeIcon />
              </InfoIconContent>
              <ContentWithIcon>
                <DetailCardContent>
                  <CardTitle>Classificação</CardTitle>
                  <CardValue>{subBatchData.classification}</CardValue>
                </DetailCardContent>
                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Produto</CardTitle>
                  <CardValue>
                    {subBatchData.productId} - {subBatchData.productName}
                  </CardValue>
                </DetailCardContent>
                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Seller</CardTitle>
                  <CardValue>{subBatchData?.sellerName}</CardValue>
                </DetailCardContent>
                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Quantidade</CardTitle>
                  <CardValue>{subBatchData?.subBatchQuantity}</CardValue>
                </DetailCardContent>

                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Lote</CardTitle>
                  <CardValue>{subBatchData?.batchId}</CardValue>
                </DetailCardContent>
                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Estágio</CardTitle>
                  <CardValue>{subBatchData?.stageName}</CardValue>
                </DetailCardContent>
              </ContentWithIcon>
            </ContainerWithIcon>
          </DetailCardContainer>
        </DetailContent>
        <DetailContent>
          <DetailCardContainer>
            <ContainerWithIcon elevation={3}>
              <InfoIconContent>
                <EventNoteIcon />
              </InfoIconContent>
              <ContentWithIcon>
                <DetailCardContent>
                  <CardTitle>Criado em</CardTitle>
                  <CardValue>
                    {subBatchData?.createdDate} {subBatchData?.createdTime}
                  </CardValue>
                </DetailCardContent>
                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Atualizado em</CardTitle>
                  <CardValue>
                    {subBatchData?.updatedDate} {subBatchData?.updatedTime}
                  </CardValue>
                </DetailCardContent>
                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Recebido em</CardTitle>
                  <CardValue>
                    {subBatchData?.receiptDate} {subBatchData?.receiptTime}
                  </CardValue>
                </DetailCardContent>
              </ContentWithIcon>
            </ContainerWithIcon>
            <ContainerWithIcon elevation={3}>
              <InfoIconContent>
                <PersonIcon />
              </InfoIconContent>
              <ContentWithIcon>
                <DetailCardContent>
                  <CardTitle>Criado por</CardTitle>
                  <CardValue>{subBatchData?.createdBy}</CardValue>
                </DetailCardContent>
                <CustomDivider />

                <DetailCardContent>
                  <CardTitle>Editado por</CardTitle>
                  <CardValue>{subBatchData?.updatedBy}</CardValue>
                </DetailCardContent>
              </ContentWithIcon>
            </ContainerWithIcon>
          </DetailCardContainer>
        </DetailContent>
      </DetailContainer>
    </>
  );
};
