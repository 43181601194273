import {
  MenuItem,
  TextField,
  Grid,
  Box,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Button } from "components";
import { useFormik } from "formik";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { InputImage } from "shared";
import { categoriesSelect, subCategoriesSelect } from "utils";
import { mapStatesString } from "utils/mapStates";
import { v4 } from "uuid";

import { StyledFormControlLabel, useStyles } from "./styles";
import {
  validationSchemaCreate,
  validationSchemaEdit
} from "./validationSchema";

const Component = (
  {
    initialValues,
    onSubmit,
    spans,
    productCategoryData,
    productSubCategoryData
  },
  ref
) => {
  const classes = useStyles();
  const [sequences, setSequences] = useState([]);

  const formik = useFormik({
    initialValues: initialValues || {
      margin: 0.95
    },
    validationSchema: initialValues
      ? validationSchemaEdit
      : validationSchemaCreate,
    onSubmit: onSubmit,
    validateOnChange: false
  });

  useImperativeHandle(ref, () => ({ resetForm: formik.resetForm }));

  const handleChangeSequence = value => {
    const span = spans.find(state => state.id === value);
    const sequencesData = Array.from({
      length: span.products.length + 2
    })
      .map((_, index) => {
        return span.products.find(item => item.span_sequence === index + 1)
          ? false
          : {
              id: index + 1,
              value: index + 1,
              key: v4()
            };
      })
      .filter(value => value);
    setSequences(sequencesData);
  };

  useEffect(() => {
    const span = spans.find(state => state.id === initialValues?.span);
    const sequencesData = Array.from({ length: span?.products.length + 2 })
      .map((_, index) => {
        if (index + 1 === initialValues.spanSequence) {
          return { id: index + 1, value: index + 1, key: v4() };
        }

        return span.products.find(item => item.span_sequence === index + 1)
          ? false
          : { id: index + 1, value: index + 1, key: v4() };
      })
      .filter(value => value);
    setSequences(sequencesData);
  }, [initialValues]);

  const filteredSubCategory = useMemo(() => {
    const selectedCategoryId = formik.values.productCategory;
    return productSubCategoryData.filter(
      ({ productCategory }) => productCategory.id === selectedCategoryId
    );
  }, [formik.values.productCategory]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Box display="flex" className={classes.gridImage}>
        <Grid container xs={12} sm={5} justifyContent="center">
          <InputImage formik={formik} name="base64" />
        </Grid>
        <Grid container xs={12} sm={7}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label="Nome"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.name || formik.submitCount > 0) &&
                !!formik.errors.name
              }
              helperText={
                (formik.touched.name || formik.submitCount > 0) &&
                formik.errors.name
              }
              variant="outlined"
            />
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "0.5rem" }}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="shelfLifeA"
                label="Validade - A"
                placeholder="Ex: 60 dias"
                type="number"
                value={formik.values.shelfLifeA}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.shelfLifeA || formik.submitCount > 0) &&
                  !!formik.errors.shelfLifeA
                }
                helperText={
                  (formik.touched.shelfLifeA || formik.submitCount > 0) &&
                  formik.errors.shelfLifeA
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="shelfLifeB"
                label="Validade - B"
                placeholder="Ex: 60 dias"
                type="number"
                value={formik.values.shelfLifeB}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.shelfLifeB || formik.submitCount > 0) &&
                  !!formik.errors.shelfLifeB
                }
                helperText={
                  (formik.touched.shelfLifeB || formik.submitCount > 0) &&
                  formik.errors.shelfLifeB
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                name="shelfLifeC"
                label="Validade - C"
                placeholder="Ex: 60 dias"
                type="number"
                value={formik.values.shelfLifeC}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.shelfLifeC || formik.submitCount > 0) &&
                  !!formik.errors.shelfLifeC
                }
                helperText={
                  (formik.touched.shelfLifeC || formik.submitCount > 0) &&
                  formik.errors.shelfLifeC
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <TextField
              select
              fullWidth
              name="purchaseTypeId"
              label="Tipo de estoque"
              value={formik.values.purchaseTypeId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.purchaseTypeId || formik.submitCount > 0) &&
                !!formik.errors.purchaseTypeId
              }
              helperText={
                (formik.touched.purchaseTypeId || formik.submitCount > 0) &&
                formik.errors.purchaseTypeId
              }
              variant="outlined"
            >
              <MenuItem value={1}>Quilo (kg)</MenuItem>
              <MenuItem value={2}>Unidade (un)</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <TextField
              select
              fullWidth
              name="category"
              label="Categoria"
              value={formik.values.category}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.category || formik.submitCount > 0) &&
                !!formik.errors.category
              }
              helperText={
                (formik.touched.category || formik.submitCount > 0) &&
                formik.errors.category
              }
              variant="outlined"
            >
              {categoriesSelect.map(({ name, value }) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        xs={12}
        spacing={2}
        justify="center"
        justifyContent="center"
        style={{ marginTop: "1rem" }}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="subCategory"
            label="Subcategoria"
            value={formik.values.subCategory}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.subCategory || formik.submitCount > 0) &&
              !!formik.errors.subCategory
            }
            helperText={
              (formik.touched.subCategory || formik.submitCount > 0) &&
              formik.errors.subCategory
            }
            variant="outlined"
          >
            {subCategoriesSelect.map(({ name, value }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="status"
            label="Status"
            value={formik.values.status}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.status || formik.submitCount > 0) &&
              !!formik.errors.status
            }
            helperText={
              (formik.touched.status || formik.submitCount > 0) &&
              formik.errors.status
            }
            variant="outlined"
          >
            <MenuItem value={"active"}>{mapStatesString("active")}</MenuItem>
            <MenuItem value={"deactivated"}>
              {mapStatesString("deactivated")}
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="productCategory"
            label="Categoria - (V2)"
            value={formik.values.productCategory}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
          >
            {productCategoryData?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="productSubCategory"
            label="Subcategoria - (V2)"
            value={formik.values.productSubCategory}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
          >
            {filteredSubCategory?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="span"
            label="Migração (Opcional)"
            value={formik.values.span}
            onChange={(e, ...rest) => {
              handleChangeSequence(e.target.value);
              formik.handleChange(e, ...rest);
            }}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.span || formik.submitCount > 0) &&
              !!formik.errors.span
            }
            helperText={
              (formik.touched.span || formik.submitCount > 0) &&
              formik.errors.span
            }
            variant="outlined"
          >
            {spans.map(item => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="spanSequence"
            label="Sequência (Opcional)"
            value={formik.values.spanSequence}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.spanSequence || formik.submitCount > 0) &&
              !!formik.errors.spanSequence
            }
            helperText={
              (formik.touched.spanSequence || formik.submitCount > 0) &&
              formik.errors.spanSequence
            }
            variant="outlined"
          >
            {sequences.map(item => (
              <MenuItem value={item.id} key={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="reopen"
            label="Reabertura infinita"
            value={formik.values.reopen}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
            defaultValue={false}
          >
            <MenuItem value={false}>Não</MenuItem>
            <MenuItem value={true}>Sim</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="isDropshipping"
            label="Dropshipping"
            value={formik.values.isDropshipping}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
            defaultValue={false}
          >
            <MenuItem value={false}>Não</MenuItem>
            <MenuItem value={true}>Sim</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="margin"
            label="Margem"
            type="number"
            value={formik.values.margin}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.margin || formik.submitCount > 0) &&
              !!formik.errors.margin
            }
            helperText={
              (formik.touched.margin || formik.submitCount > 0) &&
              formik.errors.margin
            }
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="barcode"
            label="Código de barras (Opcional)"
            value={formik.values.barcode}
            onChange={(e, ...rest) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
              formik.handleChange(e, ...rest);
            }}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.barcode || formik.submitCount > 0) &&
              !!formik.errors.barcode
            }
            helperText={
              (formik.touched.barcode || formik.submitCount > 0) &&
              formik.errors.barcode
            }
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="index"
            label="Index (Opcional)"
            type="number"
            value={formik.values.index}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              (formik.touched.index || formik.submitCount > 0) &&
              !!formik.errors.index
            }
            helperText={
              (formik.touched.index || formik.submitCount > 0) &&
              formik.errors.index
            }
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <StyledFormControlLabel
            control={<Checkbox name="isSanitized" />}
            label="Higienizado"
            checked={formik.values.isSanitized}
            onChange={formik.handleChange}
          />
          <StyledFormControlLabel
            control={<Checkbox name="isDiverse" />}
            label="Diverso"
            checked={formik.values.isDiverse}
            onChange={formik.handleChange}
          />
          <StyledFormControlLabel
            control={<Checkbox name="isProcessed" />}
            label="Processado"
            checked={formik.values.isProcessed}
            onChange={formik.handleChange}
          />
          <StyledFormControlLabel
            control={<Checkbox name="isGreen" />}
            label="Verde"
            checked={formik.values.isGreen}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Button
            isLoading={formik.isSubmitting}
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Form = forwardRef(Component);
