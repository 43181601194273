import { Button, Box, RadioGroup, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ButtonRadio } from "shared";

import { ButtonsContent, ClassificationContainer } from "../../style";

const ClassificationInput = ({ classification, batchClass }) => {
  const isChecked = () => {
    return batchClass && batchClass === classification;
  };

  return (
    <Box display="flex" gap="3rem">
      <ButtonRadio
        style={{ flex: 1 }}
        value={classification}
        checked={isChecked()}
        label={`Classe ${classification}`}
      />
    </Box>
  );
};

export const SelectionPage = ({
  handleChangeImage,
  batchClassification,
  setBatchClassification,
  changeActivePage,
  close
}) => {
  const selectionGroupBatchId = new URLSearchParams(location.search).get(
    "selectionGroupBatchId"
  );

  const onSubmit = e => {
    e.preventDefault();
    changeActivePage(2);
  };

  const handleChange = e => {
    setBatchClassification(e.target.value);
  };

  const title = {
    true: "Classifique a quantidade do lote",
    false: "Classifique a quantidade restante no lote"
  }[!!selectionGroupBatchId];

  return (
    <ClassificationContainer component="form" onSubmit={onSubmit} noValidate>
      <RadioGroup value={batchClassification} onChange={handleChange}>
        <Box
          display="flex"
          flexDirection="column"
          marginTop="1rem"
          style={{ gap: "1rem" }}
        >
          <Typography>{title}</Typography>
          <ClassificationInput
            classification="A"
            handleChangeImage={handleChangeImage}
            batchClass={batchClassification}
          />
          <ClassificationInput
            classification="B"
            handleChangeImage={handleChangeImage}
            batchClass={batchClassification}
          />
          <ClassificationInput
            classification="C"
            handleChangeImage={handleChangeImage}
            batchClass={batchClassification}
          />
        </Box>
      </RadioGroup>

      <ButtonsContent>
        <Button variant="outlined" onClick={close} fullWidth>
          Cancelar
        </Button>
        <Button
          variant="contained"
          endIcon={<NavigateNextIcon />}
          disabled={!batchClassification}
          type="submit"
          fullWidth
        >
          Avançar
        </Button>
      </ButtonsContent>
    </ClassificationContainer>
  );
};
