import { MS_API_BACKEND_URL } from "../settings";
import { api } from "./api";

class AssetService {
  constructor() {
    this.url = MS_API_BACKEND_URL;
  }

  async getAsset(params) {
    const url = `${this.url}/api/asset/v1/`;
    return await api.get(url, { params });
  }

  async postAsset(form) {
    const url = `${this.url}/api/asset/v1/`;
    return await api.post(url, form);
  }

  async updateAsset(form) {
    const url = `${this.url}/api/asset/v1/${form.id}`;
    return await api.patch(url, form);
  }

  async deleteAsset(id) {
    const url = `${this.url}/api/asset/v1/${id}`;
    return await api.delete(url);
  }

  async getAssetsSummary(params) {
    const url = `${this.url}/api/asset-storage/v1/summary/asset`;
    return await api.get(url, { params });
  }

  async getAssetsTransactions(params) {
    const url = `${this.url}/api/asset-storage/v1/transaction`;
    return await api.get(url, { params });
  }

  async getTransaction(id) {
    const url = `${this.url}/api/asset-storage/v1/transaction/${id}`;
    return await api.get(url);
  }

  async postTransaction(form) {
    const url = `${this.url}/api/asset-storage/v1/transaction`;
    return await api.post(url, form);
  }

  async updateTransaction(form, id) {
    const url = `${this.url}/api/asset-storage/v1/transaction/${id}`;

    const formData = new FormData();
    for (const key in form) {
      if (form[key] instanceof File) {
        formData.append(key, form[key], form[key].name);
      } else {
        formData.append(key, form[key]);
      }
    }

    return await api.patch(url, formData);
  }

  async getAssetsLedger(params) {
    const url = `${this.url}/api/asset-storage/v1/ledger`;
    return await api.get(url, { params });
  }

  async getAssetBalance(params) {
    const url = `${this.url}/api/asset-storage/v1/ledger/balance`;
    return await api.get(url, { params });
  }

  async exportLedgerSheet(params) {
    const url = `${this.url}/api/asset-storage/v1/ledger/file`;
    return await api.get(url, { responseType: "arraybuffer", params });
  }

  async createOrUpdateLedger(form) {
    const url = `${this.url}/api/asset-storage/v1/ledger`;
    return await api.patch(url, form);
  }

  async deleteLedgers(ids) {
    const url = `${this.url}/api/asset-storage/v1/ledger?${ids
      .map(id => `ids=${id}`)
      .join("&")}`;
    return await api.delete(url);
  }
}

const assetService = new AssetService();
export default assetService;
