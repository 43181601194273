import { Box } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import {
  ContainerPage,
  PermissionGate,
  TitlePage,
  Button,
  OptionsMenu
} from "components";
import { useTableParams } from "hooks/useTableParams";
import { useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import assetService from "services/assetsService";
import { Table } from "shared";

import { Create } from "./Components/Create";
import { Edit } from "./Components/Edit";

const AssetsList = () => {
  const { data, onChange, params, refresh, status, count, handleSearch } =
    useTableParams({
      service: params => assetService.getAsset({ ...params })
    });
  const [dialogCreateAsset, setDialogCreateAsset] = useState(false);
  const [dialogEdit, setDialogEdit] = useState({
    isOpen: false,
    data: {}
  });

  const handleSetDialogCreateAsset = () => {
    setDialogCreateAsset(state => !state);
  };

  const options = [
    {
      title: "Editar",
      icon: MdModeEditOutline,
      permissions: ["Tech", "Estoque", "Estoque físico", "CD"],
      action: data =>
        setDialogEdit({
          isOpen: true,
          data
        })
    }
  ];

  const columns = [
    {
      field: "",
      label: "",
      align: "center",
      style: { minWidth: "50px" },
      disabledSort: true
    },
    {
      field: "name",
      label: "Nome",
      style: { minWidth: "200px" }
    },
    {
      field: "description",
      label: "Descrição",
      align: "center",
      style: { minWidth: "140px" }
    },
    {
      field: "id",
      label: "Ativo",
      align: "center",
      style: { minWidth: "150px" }
    },
    {
      field: "options",
      label: "",
      disabledSort: true,
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  return (
    <>
      <ContainerPage>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <TitlePage>Listagem de ativos</TitlePage>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            ml="auto"
            style={{ gap: "1rem", marginRight: "1rem" }}
          >
            <PermissionGate
              permissions={[
                "Tech",
                "Estoque",
                "Estoque físico",
                "CD",
                "Logistic"
              ]}
            >
              <Button
                onClick={handleSetDialogCreateAsset}
                startIcon={<AddRoundedIcon />}
                variant="contained"
              >
                Novo ativo
              </Button>
            </PermissionGate>
          </Box>
        </Box>

        <Table
          handleSearch={handleSearch}
          columns={columns}
          data={data}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          placeholder="Ativo (Nome ou ID)"
          count={count}
          size="small"
        />
      </ContainerPage>

      <Create
        isOpen={dialogCreateAsset}
        setIsOpen={setDialogCreateAsset}
        refresh={refresh}
      />

      <Edit
        isOpen={dialogEdit.isOpen}
        setIsOpen={setDialogEdit}
        refresh={refresh}
        data={dialogEdit.data}
      />
    </>
  );
};

export default AssetsList;
