import { Box, Typography } from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";

import { useStyles } from "./styles";

const InputImage = ({ image, setImage, formik, name }) => {
  const classes = useStyles();
  const [url, setUrl] = useState(() => image || formik?.values?.[name]);
  const { addPopup } = usePopup();
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject
  } = useDropzone({
    onDropAccepted,
    accept: "image/png, image/jpeg"
  });
  const hasError =
    (formik?.touched[name] || formik?.submitCount > 0) &&
    !!formik?.errors[name];

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        80,
        0,
        uri => {
          resolve(uri);
        },
        "base64"
      );
    });

  const loadFile = async file => {
    try {
      const base64 = await resizeFile(file);
      if (!base64) throw new Error("Erro ao carregar imagem");
      setUrl(base64);
      setImage && setImage(base64);
      formik && formik.setFieldValue(name, base64);
    } catch (error) {
      addPopup({ type: "error", title: error.message });
    }
  };

  function onDropAccepted(files) {
    const file = files[0];
    if (file) loadFile(file);
  }

  return (
    <Box
      {...getRootProps({
        className: `${classes.dropzone}
        ${isDragActive && classes.dragActive}
        ${isDragAccept && classes.dragAccept}
        ${(isDragReject || hasError) && classes.dragReject}`
      })}
    >
      {url ? (
        <img src={url} className={classes.image} />
      ) : hasError ? (
        <Typography className={classes.description}>
          {formik.errors?.[name]}
        </Typography>
      ) : isDragReject ? (
        <Typography className={classes.description}>
          Esse tipo de imagem não é aceita
        </Typography>
      ) : (
        <Typography className={classes.description}>
          Arraste e solte a imagem aqui ou clique para selecionar
        </Typography>
      )}

      <Button
        color="secondary"
        variant="contained"
        style={{ width: "100%", textTransform: "initial" }}
      >
        Escolher uma foto
      </Button>

      <input {...getInputProps()} />
    </Box>
  );
};

export default InputImage;
