import { Box, CircularProgress } from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useRef, useState } from "react";
import { BiBarcodeReader } from "react-icons/bi";
import { FiCamera } from "react-icons/fi";
import batchService from "services/batchService";

import { checkBatch } from "../utils/checkBatch";
import { QRCode } from "./styles";

const Reader = ({ processQRCodeData }) => {
  const inputRef = useRef(null);

  const onSubmit = e => {
    e.preventDefault();
    processQRCodeData(inputRef.current.value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      inputRef.current?.focus();
    }, 300);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <input style={{ opacity: 0 }} ref={inputRef} />
      <h1 style={{ textAlign: "center" }}>
        Para prosseguir, escaneie o código com o leitor.
      </h1>
    </form>
  );
};

export const Scanner = ({ setProductBase, dataProduct, onNext }) => {
  const [view, setView] = useState(0);
  const { addPopup } = usePopup();
  const [isFetching, setIsFetching] = useState(false);

  const isAlpha = str => {
    const alphaRegex = /^[A-Za-z]+$/;
    return alphaRegex.test(str);
  };

  const validateBatchId = async batchId => {
    setIsFetching(true);
    try {
      const { data } = await batchService.getBatchDetail(batchId);
      const productData = data.product;
      productData.batchIdFromScan = data.id;
      return productData;
    } catch (error) {
      const response = error?.response;
      if (response?.status === 404) {
        addPopup({
          type: "error",
          title: "Lote inválido.",
          description: `Erro ao consultar lote: "${batchId}". Erro: ${response?.data?.msg}`
        });
        return;
      }
      addPopup({
        type: "error",
        title: "Erro ao consultar lote.",
        description: `Lote de ID: "${batchId}" não encontrado. Erro: ${error}`
      });
    } finally {
      setIsFetching(false);
    }
  };

  const handleCheckBatch = async (batchId, product_base_data) => {
    if (Object.entries(dataProduct).length) {
      await checkBatch({ batchId: batchId, productBaseId: dataProduct?.id });
    }
    setProductBase(product_base_data);
    onNext();
  };

  const processScannedData = async QRCode => {
    try {
      setIsFetching(true);
      if (isAlpha(QRCode)) {
        addPopup({
          type: "error",
          title: "QRCode Inválido",
          description: `O QRCode a seguir é inválido: "${QRCode}". Por favor tente novamente com um válido ou fale com seu supervisor.`
        });
        return;
      }
      const product_base_data = await validateBatchId(QRCode);
      !!product_base_data &&
        (await handleCheckBatch(QRCode, product_base_data));
    } catch (error) {
      addPopup({
        type: "error",
        title: "Não foi possível obter dados do QRCode",
        description: `${error}`
      });
    } finally {
      setIsFetching(false);
    }
  };

  const children = {
    0: (
      <QRCode
        onScanSuccess={processScannedData}
        videoStyle={{ height: "20rem" }}
      />
    ),
    1: <Reader processQRCodeData={processScannedData} />
  }[view];
  return (
    <Box>
      <Box
        maxWidth="15rem"
        margin="10px auto"
        display="flex"
        style={{ gap: "1rem" }}
      >
        <Button
          variant="contained"
          fullWidth
          disabled={view === 0}
          onClick={() => setView(0)}
          endIcon={<FiCamera />}
        >
          Câmera
        </Button>
        <Button
          variant="contained"
          fullWidth
          disabled={view === 1}
          onClick={() => setView(1)}
          endIcon={<BiBarcodeReader />}
        >
          Leitor
        </Button>
      </Box>
      <Box height="22rem">
        {isFetching && (
          <CircularProgress
            style={{ margin: "auto", display: "flex", height: "90%" }}
            color="primary"
          />
        )}
        {!isFetching && children}
      </Box>
    </Box>
  );
};
