import { Box, Typography, styled } from "@material-ui/core";
import { Button } from "components";
import { Dialog } from "shared";

export const StyledContainerPage = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  maxWidth: "100rem",
  margin: "0 auto"
});

export const EditButton = styled(Button)({
  color: "#353535",
  alignSelf: "end",
  padding: "0.5rem 1rem"
});

export const LevelsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  flexGrow: 1,
  gap: "1rem"
}));

export const LevelsQuantity = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "10rem",
  fontStyle: "normal",
  fontWeight: 500,
  letterSpacing: "0.01rem",
  color: "#81d719",
  [theme.breakpoints.down("sm")]: {
    fontSize: "5rem"
  }
}));

export const LevelsInfo = styled(Typography)(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "1.5rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "1.2rem",
  letterSpacing: "0.01rem",
  color: "#353535",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
    textAlign: "center"
  }
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "30rem"
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiTypography-root": {
      fontSize: "1rem"
    }
  }
}));

export const SaveButton = styled(Button)({
  color: "#353535",
  alignSelf: "end",
  padding: "0.5rem 1rem"
});
