import { Box } from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { ContainerPage, TitlePage, Button } from "components";
import PageLoader from "components/PageLoad";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import fileDownload from "js-file-download";
import React, { useState, useMemo } from "react";
import assetService from "services/assetsService";
import reporterGenerateService from "services/reporterGenerateService";
import { Table } from "shared";
import { useDebaunce } from "utils";

export default function AssetsSummary() {
  const { data, onChange, params, refresh, status } = useTableParams({
    service: () => assetService.getAssetsSummary()
  });
  const { addPopup } = usePopup();

  const [search, setSearch] = useState(null);

  const columns = [
    {
      field: "",
      label: "",
      align: "center",
      style: { minWidth: "50px" },
      disabledSort: true
    },
    {
      field: "name",
      label: "Ativo",
      style: { minWidth: "170px", maxWidth: "300px" },
      disabledSort: true
    },
    {
      field: "id",
      label: "ID do Ativo",
      align: "center",
      style: { minWidth: "200px" },
      disabledSort: true
    },
    {
      field: "quantity",
      label: "Estoque",
      align: "center",
      style: { minWidth: "200px" },
      formatter: value => {
        if (!value) return 0;
        return new Intl.NumberFormat("pt-BR").format(value.toFixed(2));
      },

      cellStyle: data => {
        if (data.stock < 0) {
          return {
            color: "red"
          };
        }
      },
      disabledSort: true
    }
  ];
  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  const dataRows = useMemo(() => {
    const rows = {
      false: () => data || [],
      true: () =>
        (data || []).filter(
          item =>
            item.name?.toLowerCase().includes(search.toLowerCase().trim()) ||
            item?.id?.toString().includes(search.trim())
        )
    }[!!search];

    return rows();
  }, [data, search]);

  const handleDownloadResume = async () => {
    try {
      const dataToDownload = dataRows?.reduce((acc, row) => {
        delete row.id;
        acc.push(row);
        return acc;
      }, []);
      const { data } = await reporterGenerateService.excelGenerate(
        dataToDownload
      );

      fileDownload(data, "Dados_de_ativos.xlsx");
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          description: "Erro ao fazer download"
        });
      }
    }
  };

  if (status === "pending") {
    return (
      <PageLoader
        textInfo={
          "Estamos carregando os dados, essa ação pode levar alguns minutos"
        }
      />
    );
  }

  return (
    <>
      <ContainerPage>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <TitlePage>Consulta de Ativos</TitlePage>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            ml="auto"
            style={{ gap: "1rem" }}
            flexWrap="wrap"
          >
            <Button
              variant="contained"
              startIcon={<CloudDownload />}
              onClick={() => handleDownloadResume()}
            >
              Baixar relatório
            </Button>
          </Box>
        </Box>

        <Table
          handleSearch={debaunceSearch}
          columns={columns}
          data={dataRows}
          onChange={onChange}
          params={params}
          status={status}
          refresh={refresh}
          count={dataRows.length}
          placeholder="Ativo (Nome ou ID)"
          size="small"
          hover
          paginationServer={false}
        />
      </ContainerPage>
    </>
  );
}
