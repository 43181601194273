import { usePopup } from "hooks/usePopup";
import blitzService from "services/blitzService";
import { Drawer } from "shared";

import { StyledModal } from "../../styles";
import { Form } from "../Form";

export const ModalUpdated = ({
  selectedProductId,
  handleSelectProduct,
  productList,
  initialValues,
  isOpen,
  refresh,
  close
}) => {
  const { addPopup } = usePopup();

  const handleClose = () => {
    handleSelectProduct(0);
    close();
  };

  const onSubmit = async data => {
    try {
      const {
        packaging,
        averageWeight,
        samplePercentage,
        observation,
        minSample,
        minAverageWeight,
        maxAverageWeight
      } = data;

      await blitzService.updateBlitzLaw(initialValues.id, {
        product: selectedProductId,
        packaging,
        average_weight: averageWeight,
        sample_percentage: samplePercentage,
        observation,
        min_sample: minSample,
        min_average_weight: minAverageWeight || null,
        max_average_weight: maxAverageWeight || null
      });

      addPopup({
        type: "success",
        title: "Regra da Blitz atualizada com sucesso."
      });

      handleClose();
      refresh();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao atualizar regra da blitz."
      });
    }
  };

  return (
    <StyledModal
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      title="Atualizar Regra da blitz"
    >
      <Form
        handleSelectProduct={handleSelectProduct}
        initialValues={initialValues}
        onSubmit={onSubmit}
        productList={productList}
        selectedProductId={selectedProductId}
      />
    </StyledModal>
  );
};
