import { Box } from "@material-ui/core";

import { DropDownComponent } from "../DropDownComponent";

export const FilterByCategory = ({
  data,
  onChange,
  selectedCategoryFilter
}) => {
  return (
    <Box minWidth={"12rem"}>
      <DropDownComponent
        data={data}
        label="Filtrar por categoria"
        onChange={onChange}
        value={selectedCategoryFilter}
        noneTitle={"Todos"}
      />
    </Box>
  );
};
