import { makeStyles } from "@material-ui/core";

export const formStyles = makeStyles(theme => ({
  boxAlign: {
    marginTop: "1.3rem",
    background: "none",
    display: "flex",
    textAlign: "center",
    border: "none",
    alignItems: "center"
  },
  genericLabel: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "0.9rem",
    lineHeight: "1.35rem",
    color: "#757575"
  },
  error: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "0.9rem",
    lineHeight: "1.35rem",
    color: "#FF0000",
    marginLeft: "0.5rem"
  },
  chip: {
    width: "8.86rem",
    height: "3rem",
    background: "#F5F8F3",
    border: "2px solid #E5ECD1",
    borderRadius: "1.68rem",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "1.125rem",
    color: "#757575"
  },
  inputWithRadius: {
    border: "2px solid #E5ECD1",
    borderRadius: "1.68"
  },
  fiscalNoteText: {
    margin: "0 0.8rem",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "1.1rem",
    color: "#3EA0DE"
  },
  boxItemPopover: {
    width: "10rem",
    height: "3rem",
    textAlign: "center",
    padding: "0.5rem",
    "&:hover": {
      cursor: "pointer",
      background: "#F4F4F4"
    }
  },
  listItemText: {
    fontSize: "1.1rem",
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "0.9rem"
  },
  label: {
    fontFamily: "Inter",
    fontWeight: 550,
    fontSize: "0.9rem",
    lineHeight: "1.35rem",
    color: "#757575",
    marginBottom: "1rem",
    [theme.breakpoints.between(0, 500)]: {
      fontSize: "0.8rem"
    }
  },
  bigInput: {
    width: "100%",
    [theme.breakpoints.between(0, 800)]: {
      width: "80%"
    }
  },
  button: {
    marginTop: "3rem",
    width: "7rem",
    height: "3rem",
    borderRadius: "0.3rem"
  }
}));
