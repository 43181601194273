import axios from "axios";

import { ESTOQUE_BACKEND_URL, ABACATE_BACKEND_URL } from "../settings";
import { api } from "./api";

class SkuService {
  constructor() {
    this.url = ABACATE_BACKEND_URL;
  }

  async getSku(params) {
    const url = `${ESTOQUE_BACKEND_URL}/api/products/v2/product`;
    return await api.get(url, { params });
  }

  async postSku(form) {
    const url = `${this.url}/api/products/v2/product`;
    return await api.post(url, form);
  }

  async updateSku(form) {
    const url = `${this.url}/api/products/v2/product/${form.id}`;
    return await api.put(url, form);
  }

  async deleteSku(id) {
    const url = `${this.url}/api/products/v2/product/${id}`;
    return await api.delete(url);
  }

  async updateSkuImage(params) {
    const url = `${this.url}/api/abacate/v1/public/product/${params.idSku}/image`;
    await api.put(url, { file: params.file });
  }

  async downloadSku(form) {
    const url = `${this.url}/api/abacate/v1/public/product/`;
    return await api.delete(url, form);
  }

  async getDownloadSku() {
    const url = `${ESTOQUE_BACKEND_URL}/api/blueberry/v1/warehouse/storage/sku`;
    return await api.get(url, {
      responseType: "blob",
      params: {
        export: "xls"
      }
    });
  }
}

const skuService = new SkuService();
export default skuService;
