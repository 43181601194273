import { Button, makeStyles, Typography } from "@material-ui/core";
import { GridOverlay } from "@material-ui/data-grid";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px"
  },
  text: {
    color: "#88bc23",
    fontSize: "clamp(0.9rem, 2vw, 1.2rem)"
  }
});

export const CustomNoRowsOverlay = ({ refresh }) => {
  const classes = useStyles();

  return (
    <GridOverlay>
      <div className={classes.root}>
        <Typography className={classes.text}>Nenhum dado encontrado</Typography>
        <Button variant="outlined" onClick={refresh}>
          Recarregar
        </Button>
      </div>
    </GridOverlay>
  );
};
