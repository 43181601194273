import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class StockServiceV2 {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getResumeStock(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storages/resume`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        results: data,
        total_count: data.length
      }
    };
  }

  async getVirtualResumeStock(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storages/resume/virtual`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        results: data,
        total_count: data.length
      }
    };
  }

  async getResumeStockBySku(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storages/resume/sku`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        results: data,
        total_count: data.length
      }
    };
  }

  async getPhysicalStock(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/storages/physical`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        results: data,
        total_count: data.length
      }
    };
  }
}

const stockServiceV2 = new StockServiceV2();
export default stockServiceV2;
