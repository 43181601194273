import {
  Box,
  Button,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  makeStyles
} from "@material-ui/core";
import { ChipStatus, statusBackgroundColor } from "components/ChipStatus";
import { Dialog } from "shared";
import { Search } from "shared/Search";

export const Content = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
}));

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingInline: "1rem",
    justifyContent: "center"
  }
}));

export const SearchInput = styled(Search)(({ theme }) => ({
  width: "100%",
  maxWidth: "21.5rem",
  height: "3.5rem",
  marginTop: "2.5rem",
  marginLeft: "2.5rem",
  [theme.breakpoints.between(0, 700)]: {
    marginLeft: 0
  }
}));

export const FilterContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingTop: "2rem",
  gap: "1rem",
  paddingInline: "1rem",
  marginRight: "1rem",
  [theme.breakpoints.down("sm")]: {
    flex: 1,
    padding: "1rem 0",
    justifyContent: "end"
  }
}));

export const FilterLabel = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  fontSize: "0.875rem",
  color: "#757575"
});

export const ChipButtonFilter = styled(ChipStatus)(({ statusId }) => ({
  minWidth: "8rem",
  fontSize: "0.875rem",
  "&:hover": {
    backgroundColor: statusBackgroundColor[statusId] || "#F4F4F4"
  },
  "&:focus": {
    backgroundColor: statusBackgroundColor[statusId] || "#F4F4F4"
  }
}));

export const ChipMenuItem = styled(ChipStatus)({
  display: "flex",
  flex: 1,
  cursor: "pointer"
});

export const TableContent = styled(TableContainer)({
  display: "flex",
  flexDirection: "column",
  height: "32rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "12px",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "12px"
  }
});

export const StyledTable = styled(Table)({});

export const StyledTableHead = styled(TableHead)({
  backgroundColor: "#F3F8E6"
});

export const HeadCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  backgroundColor: "#F3F8E6"
});

export const BodyRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const BodyCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem"
});

export const ConfirmButton = styled(Button)({
  minWidth: "4rem",
  minHeight: "2rem",
  color: "#ffffff",
  backgroundColor: "#89bd23",
  borderRadius: "0.5rem",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "1rem",
  letterSpacing: "0.02857em",
  "&:hover": {
    backgroundColor: "#68901a"
  },
  "&:disabled": {
    backgroundColor: "#757575"
  }
});

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});

export const LoadingContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

export const ButtonsContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  gap: "0.5rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const StyledButton = styled(Button)(({ name }) => {
  const isConfirmBtn = name === "confirmButton";
  const isUpdateBtn = name === "updateBtn";
  return {
    background: isConfirmBtn
      ? "#89BD23"
      : isUpdateBtn
      ? "transparent"
      : "#8360E9",
    color: isConfirmBtn ? "#FFF" : isUpdateBtn ? "#757575" : "#FFF",
    border: isUpdateBtn && "1px solid #757575",

    "& .MuiButton-label": {
      color: isConfirmBtn ? "#FFF" : isUpdateBtn ? "#757575" : "#FFF"
    },
    "&:hover": {
      background: isConfirmBtn
        ? "rgba(76, 110, 10, 0.8) "
        : isUpdateBtn
        ? "rgba(0, 0, 0, 0.1)"
        : "rgba(131, 96, 233, 0.8)",
      "& .MuiButton-label": {
        color: isUpdateBtn ? "#000" : "#FFF"
      }
    }
  };
});

export const StyledDialog = styled(Dialog)({
  maxWidth: "35rem",
  position: "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0
});

export const BatchInfoContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "8.75rem",
  borderRadius: "1rem",
  textAlign: "left",
  paddingTop: "0.7rem",
  marginBottom: "1rem",
  background: "#F5F8F3",
  [theme.breakpoints.down("sm")]: {
    height: "100%"
  }
}));

export const BatchInfoText = styled(Typography)({
  marginTop: "0.4rem",
  marginLeft: "1.5rem",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#757575",
  "& span": {
    fontWeight: 600
  },
  "& strong": {
    color: "#000000"
  }
});

export const BatchInfoSubText = styled(Typography)({
  textAlign: "center",
  paddingBlock: "1rem",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#757575",
  "& span": {
    fontWeight: 600
  },
  "& strong": {
    color: "#000000"
  }
});

export const ActionContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem"
});

export const useStyles = makeStyles(theme => ({
  dateField: {
    width: "15rem",
    height: "3.9rem",
    marginLeft: "1rem",
    border: "3px solid #CFCECE",
    borderRadius: "5px",
    "& div": {
      height: "100%",
      paddingLeft: "1rem"
    },
    "& button": {
      background: "#E5ECD1",
      borderRadius: "3px",
      color: "#587916",
      height: "178%",
      "&:hover": {
        background: "#c8db93"
      }
    },
    [theme.breakpoints.between(0, 750)]: {
      minWidth: "11.5rem"
    }
  }
}));

export const StyledAlertDialog = styled(Dialog)(({ theme }) => ({
  maxWidth: "24rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": {
    width: "100%",
    height: "20%",
    padding: 0,
    margin: 0
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      height: "25%"
    }
  }
}));

export const PopupContent = styled(Box)({
  display: "flex",
  position: "relative",
  height: "calc(100% - 92px)",
  flexDirection: "column",
  gap: "0.5rem"
});

export const TextInfoAlert = styled("p")({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#fb0303c4",
  textAlign: "center"
});
