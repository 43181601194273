import {
  Box,
  FormControl,
  FormControlLabel,
  Typography,
  styled
} from "@material-ui/core";
import { Button } from "components";
import { Drawer } from "shared";

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "35rem",
    [theme.breakpoints.between(0, 800)]: {
      width: "100%",
      padding: 0
    }
  }
}));

export const FormContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  gap: "1.5rem"
});

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: "flex",
  flexGrow: 1
}));

export const StyledFormControlLabel = styled(FormControlLabel)({
  display: "flex",
  flexGrow: 1,
  height: "3.5rem",
  background: "#F5F6F7",
  border: "2px solid #F5F6F7",
  paddingInline: "0.5rem",
  borderRadius: "0.5rem",
  fontFamily: "Roboto",
  fontSize: "0.9rem",
  fontWeight: 400,
  lineHeight: "1.35rem",
  color: "#757575",
  margin: 0
});

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  gap: "1rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const ButtonsContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",

  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-start",
    marginBottom: "1rem"
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "7rem",
  height: "3rem",
  borderRadius: "0.3rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}));

export const Title = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "1rem",
  color: "#757575"
});
