import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { ButtonRadio } from "shared";
import * as yup from "yup";

import {
  ActionContent,
  BatchInfoContent,
  BatchInfoSubText,
  BatchInfoText,
  BoxInput,
  ButtonsContent,
  LoadingContent,
  StyledButton,
  StyledDialog
} from "../../style";

const validationDefault = yup.object({
  stage: yup.number().required("Campo obrigatório"),
  combinedReturnQty: yup.number().when("stage", {
    is: 1,
    then: yup
      .number()
      .positive("Valor deve ser positivo")
      .required("Campo obrigatório")
      .test(
        "is-combined-quantity-valid",
        "A quantidade combinada não pode ser maior que a disponível",
        function (combinedQuantity) {
          const { quantity } = this.parent;
          return combinedQuantity <= quantity;
        }
      )
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          const { typeOfMeasure } = this.parent;
          return !(typeOfMeasure === "Un" && !Number.isInteger(value));
        }
      )
  }),
  sellerWillPickup: yup.boolean().when("stage", {
    is: 1,
    then: yup
      .boolean()
      .oneOf([true, false], "Selecione Sim ou Não")
      .required("Selecione Sim ou Não")
  }),
  combinedReturnDeadline: yup
    .number()
    .when(["instruction", "refusalInstructions", "stage"], {
      is: (instruction, refusalInstructions, stage) =>
        (instruction === 4 || refusalInstructions === "4") && stage === 1,
      then: yup
        .number()
        .positive("Valor deve ser positivo")
        .integer("Deve ser um número inteiro")
        .required("Campo obrigatório")
    }),
  refusalInstructions: yup.string().when(["instruction", "stage"], {
    is: (instruction, stage) => instruction === 1 && stage === 1,
    then: yup
      .string("Instrução é obrigatória")
      .required("Instrução é obrigatória")
  })
});

export const ConfirmPopup = ({
  openConfirm,
  handleCloseConfirm,
  handleConfirm,
  data,
  loading,
  handleDelete
}) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const instruction = data.instructions?.[0];

  const onSubmit = dataForm => {
    const instruction =
      dataForm.instruction === 1 && dataForm.stage === 1
        ? Number(dataForm.refusalInstructions)
        : dataForm.instruction;
    const devolutionStages =
      {
        3: 12,
        4: 13
      }[instruction] || 1;
    const data = {
      1: { status: 3, stage: devolutionStages },
      2: { status: 6, stage: 8 },
      3: { status: 6, stage: 14 },
      15: { status: 6, stage: 15 }
    }[dataForm.stage];
    const refusal_instructions =
      {
        3: "3 - Devolver e ter valor restituído",
        4: "4 - Devolver e ter mercadoria reposta"
      }[dataForm.stage === 1 ? dataForm.refusalInstructions : null] ??
      undefined;
    const isSuccess = handleConfirm({
      ...data,
      combined_return_qty:
        dataForm.stage === 1 ? dataForm.combinedReturnQty : undefined,
      seller_will_pickup:
        dataForm.stage === 1
          ? JSON.parse(dataForm.sellerWillPickup)
          : undefined,
      combined_return_deadline:
        instruction === 4 && dataForm.stage === 1
          ? dataForm.combinedReturnDeadline
          : undefined,
      refusal_instructions
    });
    isSuccess && formik.resetForm({});
  };

  const formik = useFormik({
    initialValues: {
      instruction: Number(data.instructions?.[0]),
      quantity: data.quantity,
      typeOfMeasure: data.typeOfMeasure
    },
    validationSchema: validationDefault,
    onSubmit,
    validateOnChange: false
  });

  const textDescription = isConfirm
    ? "Quer mesmo apagar está análise?"
    : "Como deseja finalizar está análise?";

  const handleClose = () => {
    handleCloseConfirm();
    formik.resetForm({});
  };

  const actionContent = {
    true: (
      <ButtonsContent>
        <StyledButton
          variant="contained"
          fullWidth
          name="cancelButton"
          onClick={() => setIsConfirm(false)}
          style={{ textTransform: "none" }}
        >
          Cancelar
        </StyledButton>

        <StyledButton
          variant="contained"
          fullWidth
          name="confirmButton"
          onClick={handleDelete}
          style={{ textTransform: "none" }}
        >
          Confirmar exclusão
        </StyledButton>
      </ButtonsContent>
    ),
    false: (
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        display="flex"
        flexDirection="column"
        style={{ gap: "1.5rem" }}
      >
        <TextField
          select
          variant="outlined"
          fullWidth
          name="stage"
          value={formik.values.stage}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            (formik.touched.stage || formik.submitCount > 0) &&
            !!formik.errors.stage
          }
          helperText={
            (formik.touched.stage || formik.submitCount > 0) &&
            formik.errors.stage
          }
        >
          <MenuItem value={1}>Devolvido para o fornecedor</MenuItem>
          <MenuItem value={2}>Waste operacional</MenuItem>
          <MenuItem value={3}>Waste compras</MenuItem>
          <MenuItem value={15}>Waste devolução</MenuItem>
        </TextField>

        {instruction === "1" && formik.values.stage === 1 && (
          <FormControl fullWidth>
            <FormLabel style={{ color: "#f44336" }}>
              Essa instrução está diferente do que foi definido. Como deseja
              prosseguir?
            </FormLabel>
            <RadioGroup
              value={formik.values.refusalInstructions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="refusalInstructions"
              defaultValue=""
            >
              <BoxInput marginTop="1rem">
                <ButtonRadio
                  style={{ flex: 1 }}
                  checked={formik.values.refusalInstructions === "3"}
                  value="3"
                  label="3 - Devolver e ter valor restituído"
                />

                <ButtonRadio
                  style={{ flex: 1 }}
                  checked={formik.values.refusalInstructions === "4"}
                  value="4"
                  label="4 - Devolver e ter mercadoria reposta"
                />
              </BoxInput>
            </RadioGroup>
            {(formik.touched.refusalInstructions || formik.submitCount > 0) &&
              formik.errors.refusalInstructions && (
                <FormHelperText error>
                  {formik.errors.refusalInstructions}
                </FormHelperText>
              )}
          </FormControl>
        )}

        {formik.values.stage === 1 && (
          <>
            <BoxInput>
              <Box flex="1">
                <TextField
                  fullWidth
                  type="number"
                  name="combinedReturnQty"
                  variant="outlined"
                  label="Qtd. que será devolvida ao seller"
                  value={formik.values.combinedReturnQty}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    (formik.touched.combinedReturnQty ||
                      formik.submitCount > 0) &&
                    !!formik.errors.combinedReturnQty
                  }
                  helperText={
                    (formik.touched.combinedReturnQty ||
                      formik.submitCount > 0) &&
                    formik.errors.combinedReturnQty
                  }
                />
                {!!formik.values.combinedReturnQty &&
                  formik.values.combinedReturnQty < formik.values.quantity && (
                    <BatchInfoText>
                      <span style={{ color: "#f13535" }}>
                        Aviso: O restante de{" "}
                        {formik.values.quantity -
                          formik.values.combinedReturnQty}{" "}
                        será classificado como Waste devolução
                      </span>
                    </BatchInfoText>
                  )}
              </Box>

              {(formik.values.instruction === 4 ||
                formik.values.refusalInstructions === "4") && (
                <TextField
                  style={{ flex: "1" }}
                  fullWidth
                  type="number"
                  name="combinedReturnDeadline"
                  variant="outlined"
                  label="Prazo de reposição"
                  value={formik.values.combinedReturnDeadline}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    (formik.touched.combinedReturnDeadline ||
                      formik.submitCount > 0) &&
                    !!formik.errors.combinedReturnDeadline
                  }
                  helperText={
                    (formik.touched.combinedReturnDeadline ||
                      formik.submitCount > 0) &&
                    formik.errors.combinedReturnDeadline
                  }
                />
              )}
            </BoxInput>

            <FormControl fullWidth>
              <FormLabel>Seller vai retirar o produto?</FormLabel>
              <RadioGroup
                row
                style={{ display: "flex" }}
                name="sellerWillPickup"
                value={String(formik.values.sellerWillPickup ?? "")}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio style={{ color: "#8bc34a" }} />}
                  label="Sim"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio style={{ color: "#8bc34a" }} />}
                  label="Não"
                />
              </RadioGroup>
              {(formik.touched.sellerWillPickup || formik.submitCount > 0) &&
                formik.errors.sellerWillPickup && (
                  <FormHelperText error>
                    {formik.errors.sellerWillPickup}
                  </FormHelperText>
                )}
            </FormControl>
          </>
        )}

        <ButtonsContent>
          <StyledButton
            variant="contained"
            fullWidth
            name="cancelButton"
            onClick={handleClose}
            style={{ textTransform: "none" }}
          >
            Cancelar
          </StyledButton>

          <StyledButton
            variant="contained"
            fullWidth
            type="submit"
            name="confirmButton"
            style={{ textTransform: "none" }}
          >
            Confirmar
          </StyledButton>
        </ButtonsContent>
      </Box>
    )
  }[isConfirm];

  useEffect(() => {
    formik.setFieldValue("instruction", Number(data.instructions?.[0]));
    formik.setFieldValue("quantity", Number(data.quantity));
    formik.setFieldValue("typeOfMeasure", data.typeOfMeasure);
    setIsConfirm(false);
  }, [openConfirm]);

  return (
    <StyledDialog
      open={openConfirm}
      handleClose={handleClose}
      title={isConfirm ? "Confirmar exclusão" : "Confirmar análise"}
      titleButton={
        isConfirm ? undefined : (
          <IconButton onClick={() => setIsConfirm(true)}>
            <MdDeleteForever color={"red"} />
          </IconButton>
        )
      }
    >
      <BatchInfoContent>
        <BatchInfoText>
          Lote: <strong>{data?.batchId}</strong>
        </BatchInfoText>
        <BatchInfoText>
          Produto: <span>{data?.productName}</span>
        </BatchInfoText>
        <BatchInfoText>
          Seller: <span>{data?.seller}</span>
        </BatchInfoText>
        {!!data?.instructions && (
          <BatchInfoText>
            Instruções: <span>{data?.instructions}</span>
          </BatchInfoText>
        )}
      </BatchInfoContent>
      <ActionContent>
        {loading ? (
          <>
            <BatchInfoSubText>Por favor aguarde...</BatchInfoSubText>
            <LoadingContent>
              <CircularProgress color="primary" />
            </LoadingContent>
          </>
        ) : (
          <>
            <BatchInfoSubText>{textDescription}</BatchInfoSubText>
            {actionContent}
          </>
        )}
      </ActionContent>
    </StyledDialog>
  );
};
