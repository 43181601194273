import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Box, TextField } from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import lockedStockService from "services/lockedStockService";
import { Dialog } from "shared";
import * as Yup from "yup";

export const Component = ({ refresh }, ref) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});

  const schema = Yup.object().shape({
    lockedStock: Yup.number()
      .positive("Quantidade não pode ser negativa")
      .max(
        data?.locked_stock * -1,
        `A quantidade deve ser menor ou igual a ${new Intl.NumberFormat(
          "pt-BR"
        ).format(data?.locked_stock?.toFixed(2) * -1)}`
      )
      .typeError("Quantidade é obrigatória")
      .required("Quantidade é obrigatória")
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ resolver: yupResolver(schema) });

  const toggleOpen = () => setIsOpen(state => !state);

  const handleData = data => {
    setData(data);
    toggleOpen();
  };

  useImperativeHandle(ref, () => ({ toggleOpen, handleData }));

  const handleClose = () => {
    toggleOpen();
    reset({ lockedStock: null });
  };

  const onSubmitHandler = async formData => {
    try {
      setIsLoading(true);
      await lockedStockService.unlockStock(
        Number(formData.lockedStock),
        data?.product_base_id
      );
      refresh();
      handleClose();
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao desbloquear estoque"
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} handleClose={handleClose} title="Desbloquear estoque">
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register("lockedStock")}
              error={!!errors?.lockedStock}
              helperText={errors?.lockedStock?.message}
              style={{ width: "100%" }}
              variant="outlined"
              label="Quantidade"
              type="number"
            />
          </Grid>

          <Grid container item xs={12} justifyContent="flex-end">
            <Button
              isLoading={isLoading}
              type="submit"
              variant="contained"
              size="large"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export const Unlock = forwardRef(Component);
