import { Box, InputLabel, Typography, styled } from "@material-ui/core";

export const ConfirmContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  background: "#F5F8F3",
  border: "2px solid #B5C9A8",
  fontSize: "1rem",
  fontWeight: 500,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "1rem",
  padding: "0.5rem",
  gap: "0.5rem"
});

export const LabelConfirmQuality = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#757575"
});

export const LabelConfirmLowQuality = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#D84315"
});

export const LabelSampleQuantity = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 600,
  fontSize: "1.1rem"
});

export const StyledInputLabel = styled(InputLabel)(({ hasImage }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "3rem",
  gap: "0.5rem",
  color: hasImage ? "#0A892E" : "#3EA0DE",
  cursor: "pointer",
  "&.Mui-disabled": {
    cursor: "not-allowed"
  },
  border: "1px solid green"
}));
