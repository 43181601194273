import { Box, LinearProgress, Typography } from "@material-ui/core";
import { Paginate } from "components";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { MdSearch } from "react-icons/md";
import { Search } from "shared/Search";

import MoreButton from "./MoreButton";
import {
  CardContainer,
  CardContent,
  CardInfoContainer,
  CardInfoContent,
  CardTitle,
  CardValue,
  PaginateContent
} from "./styles";

export const BatchesCard = ({
  batches,
  onClickMove,
  onClickConvert,
  onClickHistory,
  onClickReason,
  params,
  totalPages,
  handlePagination,
  status,
  debaunceBatchSearch
}) => {
  const isLoading = status === "pending";
  const showPaginate = totalPages > 1 && params.search && !isLoading;
  return (
    <CardContainer>
      <Box>
        <Search
          fullWidth
          placeholder="Produto base ou lote"
          defaultValue={params.search}
          onChange={debaunceBatchSearch}
        />
        {isLoading && <LinearProgress />}
      </Box>
      {!batches.length > 0 && !isLoading ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh"
          }}
        >
          <MdSearch size={50} />
          <Typography variant="h6" color="textSecondary">
            Pesquise um produto base ou lote
          </Typography>
        </Box>
      ) : (
        (batches || []).map(batch => (
          <CardContent elevation={3} key={batch.id}>
            <CardInfoContainer>
              <CardInfoContent>
                <CardTitle>Lote</CardTitle>
                <CardValue>{batch.batchId}</CardValue>
              </CardInfoContent>

              <CardInfoContent>
                <CardTitle>Classificação</CardTitle>
                <CardValue>{batch.classification}</CardValue>
              </CardInfoContent>
            </CardInfoContainer>

            <CardInfoContainer>
              <CardInfoContent>
                <CardTitle>Recebimento</CardTitle>
                <CardValue>{batch.receiptDateTime}</CardValue>
              </CardInfoContent>

              <CardInfoContent>
                <CardTitle>Armazenamento</CardTitle>
                <CardValue>{batch.firstStoredDateTime}</CardValue>
              </CardInfoContent>
            </CardInfoContainer>

            <CardInfoContainer>
              <CardInfoContent>
                <CardTitle>Produto</CardTitle>
                <CardValue>{batch.productBaseName}</CardValue>
              </CardInfoContent>
            </CardInfoContainer>

            <CardInfoContainer>
              <CardInfoContent>
                <CardTitle>Shelf life</CardTitle>
                <CardValue>{batch.shelfLife}</CardValue>
              </CardInfoContent>

              <CardInfoContent>
                <CardTitle>% Abaixo da qualidade</CardTitle>
                <CardValue>{batch.refusalPercentage}</CardValue>
              </CardInfoContent>
            </CardInfoContainer>

            <CardInfoContainer>
              <CardInfoContent>
                <CardTitle>Armazém</CardTitle>
                <CardValue>{batch.warehouseTypeName}</CardValue>
              </CardInfoContent>

              <CardInfoContent>
                <CardTitle>Depósito</CardTitle>
                <CardValue>{batch.depotName}</CardValue>
              </CardInfoContent>
            </CardInfoContainer>

            <CardInfoContainer>
              <CardInfoContent>
                <CardTitle>Quantidade</CardTitle>
                <CardValue>{batch.quantity}</CardValue>
              </CardInfoContent>

              <CardInfoContent>
                <CardTitle>Embalagem</CardTitle>
                <CardValue>{batch.packaging}</CardValue>
              </CardInfoContent>
            </CardInfoContainer>

            <Box
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center"
              }}
            >
              <MoreButton
                onClickConvert={() => onClickConvert(batch)}
                onClickMove={() => onClickMove(batch)}
                onClickHistory={() => onClickHistory(batch)}
                onClickReason={() => onClickReason(batch)}
              />
            </Box>
          </CardContent>
        ))
      )}
      <PaginateContent>
        {showPaginate && (
          <Paginate
            breakLabel="..."
            nextLabel={<AiOutlineRight />}
            forcePage={params.page - 1}
            onPageChange={handlePagination}
            pageRangeDisplayed={3}
            pageCount={totalPages}
            previousLabel={<AiOutlineLeft />}
            renderOnZeroPageCount={null}
            loading={status === "pending"}
          />
        )}
      </PaginateContent>
    </CardContainer>
  );
};
