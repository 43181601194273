import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ChipStatus } from "components/ChipStatus";
import { useState } from "react";
import { BiMessageAltDetail } from "react-icons/bi";
import { CgNotes } from "react-icons/cg";
import { FaEllipsisV } from "react-icons/fa";
import { MenuPopover } from "shared";

import { receiptConferenceStyles, rowConferenceStyles } from "./styles";

const Row = ({ row, childrenColumns, index, setConferenceData }) => {
  const { conferences, seller, receiptDate } = row;

  const [anchorEl, setAnchorEl] = useState(null);
  const [observationPopup, setObservationPopup] = useState(false);
  const [openObservationPopup, setOpenObservationPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = rowConferenceStyles({ index });

  const handleOpenPopover = (event, observation) => {
    setOpenObservationPopup(true);
    setObservationPopup(observation);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenObservationPopup(false);
    setObservationPopup("");
    setAnchorEl(null);
  };

  return (
    <>
      <MenuPopover
        open={openObservationPopup}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
      >
        <Box className={classes.menuPopover}>
          <Typography className={classes.observationText}>
            {observationPopup}
          </Typography>

          <Box className={classes.popoverAction}>
            <IconButton onClick={handleClosePopover}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </MenuPopover>

      <TableRow
        className={classes.root}
        style={open ? { background: "#E5ECD1" } : null}
      >
        <TableCell className={classes.sellerName}>{seller?.name}</TableCell>
        <TableCell className={classes.sellerName}>{receiptDate}</TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.tableCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {childrenColumns.map(column => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.subtitle}
                        >
                          {column.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {conferences.map(conference => (
                    <TableRow
                      className={classes.tableRow}
                      key={conference.batchConferenceId}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={classes.producName}
                      >
                        {conference.batchInfos?.product.name}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.producName}
                      >
                        {conference.receiptTime}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.producName}
                      >
                        {conference.forecast}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.producName}
                      >
                        {conference.received}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.producName}
                      >
                        {conference.returnedQuantity}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.producName}
                      >
                        {conference.batchInfos?.quantity}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.producName}
                      >
                        {conference.quantityInvoice}
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        {conference.invoice && (
                          <a
                            href={conference.invoice}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <CgNotes
                              size="1.6rem"
                              color="#6C8932"
                              className={classes.iconDesign}
                            />
                          </a>
                        )}
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        {conference.observation && (
                          <BiMessageAltDetail
                            size="1.7rem"
                            color="#6C8932"
                            onClick={event =>
                              handleOpenPopover(event, conference.observation)
                            }
                            className={classes.iconDesign}
                          />
                        )}
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        <ChipStatus
                          label={conference.status?.name}
                          statusId={conference.status?.id === 1 ? 3 : 2}
                        />
                      </TableCell>

                      <TableCell component="th" scope="row" align="right">
                        <FaEllipsisV
                          size="1.2rem"
                          color="#757575"
                          onClick={() =>
                            setConferenceData({
                              batchInfos: conference.batchInfos,
                              received: conference.received,
                              quantityInvoice: conference.quantityInvoice,
                              observation: conference.observation,
                              returnedQuantity: conference.returnedQuantity,
                              conferenceInvoice: conference.invoice,
                              batchConferenceId: conference.batchConferenceId,
                              status: conference.status
                            })
                          }
                          className={classes.iconDesign}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const ReceiptConferenceTable = ({
  columns,
  childrenColumns,
  data,
  setConferenceData
}) => {
  const classes = receiptConferenceStyles();

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                align={column.align}
                className={classes.title}
              >
                {column.label}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((seller, index) => (
            <Row
              index={index}
              key={index}
              row={seller}
              childrenColumns={childrenColumns}
              setConferenceData={setConferenceData}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
