import { Box, Paper, Table, TableBody } from "@material-ui/core";
import { CustomLineProgress } from "components/LineProgress";

import { DropdownButton } from "../Dropdown";
import { DropdownButtonPackaging } from "../DropdownPackaging";
import {
  BodyCell,
  BodyRow,
  HeadCell,
  StyledTableHead,
  TableContent
} from "./styles";

export const TablePBs = ({
  loading,
  warehouseData,
  productBaseData,
  packagingData,
  refresh
}) => {
  return (
    <>
      <Box>
        {!!loading && <CustomLineProgress />}
        <TableContent component={Paper}>
          <Table stickyHeader>
            <StyledTableHead>
              <HeadCell>Produto base</HeadCell>
              <HeadCell>Produto</HeadCell>
              <HeadCell>Armazém</HeadCell>
              <HeadCell>Embalagem</HeadCell>
            </StyledTableHead>
            <TableBody>
              {productBaseData.map(productBase => {
                return (
                  <BodyRow key={productBase.id}>
                    <BodyCell>{productBase.id}</BodyCell>
                    <BodyCell>{productBase.name}</BodyCell>
                    <BodyCell>
                      <DropdownButton
                        warehouseData={warehouseData}
                        productBaseWarehouses={productBase.warehouses}
                        productBaseId={productBase.id}
                        refresh={refresh}
                      />
                    </BodyCell>
                    <BodyCell>
                      <DropdownButtonPackaging
                        packagingData={packagingData}
                        productBasePackages={productBase.packages}
                        productBaseId={productBase.id}
                        refresh={refresh}
                      />
                    </BodyCell>
                  </BodyRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContent>
      </Box>
    </>
  );
};
