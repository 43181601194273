import { Box } from "@material-ui/core";
import ContentLoader from "react-content-loader";

import { useStyles } from "./styles";

export const CardLoading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} title="Carregando...">
      <ContentLoader
        speed={1}
        style={{ width: "100%", height: "120px" }}
        viewBox="0 0 200 200"
        backgroundColor="#e8e8e8"
        foregroundColor="#d6d6d6"
        title="Carregando..."
      >
        <rect x="50" y="0" rx="60" ry="60" width="105" height="105" />
        <rect x="30" y="127" rx="5" ry="5" width="140" height="35" />
      </ContentLoader>
    </Box>
  );
};
