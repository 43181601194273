import { Box, Paper, Typography, styled } from "@material-ui/core";
import { Dialog } from "shared";

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem"
});

export const Text = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "1.1rem",
  color: "#757575"
});

export const ButtonsContent = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  width: "100%",
  paddingBlock: "1rem",
  marginTop: "auto",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  maxWidth: "32rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": {
    width: "100%",
    padding: 0,
    margin: 0
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      height: "100%"
    }
  }
}));
