import { Box, TextField } from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import depotService from "services/depotService";

import { StyledDialog } from "../../styles";

export const EditPopup = ({ levels, open, handleClose }) => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);
  const [newLevels, setNewLevels] = useState(levels);
  const disableButton = !newLevels || Number(levels) === Number(newLevels);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await depotService.postSensitiveLeves({ levels: Number(newLevels) });
      addPopup({
        type: "success",
        title: "Tudo certo",
        description: "Níveis atualizados com sucesso."
      });
      handleClose();
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao salvar dados",
        description: error.response?.data?.msg ?? ""
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeLevels = e => {
    const { value: inputValue } = e.target;
    const value = parseInt(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setNewLevels(!isValid ? "" : value);
  };

  useEffect(() => {
    setNewLevels(levels);
  }, [open]);

  return (
    <StyledDialog
      open={open}
      handleClose={handleClose}
      title={"Editar níveis de sensibilidade"}
      style={{ root: { width: "2rem" } }}
    >
      <TextField
        variant="outlined"
        fullWidth
        type="number"
        value={newLevels}
        onChange={handleChangeLevels}
      />
      <Box
        style={{ display: "flex", padding: "1rem 0", justifyContent: "end" }}
      >
        <Button
          variant="contained"
          isLoading={loading}
          onClick={handleSubmit}
          disabled={disableButton}
        >
          Salvar
        </Button>
      </Box>
    </StyledDialog>
  );
};
