import { Box, CircularProgress, TextField } from "@material-ui/core";
import { Button } from "components";

import {
  DialogBtnContent,
  DialogContent,
  DialogInputContent,
  LoadingContent,
  StyledDialog
} from "../../style";

export const DialogCreate = ({
  open,
  handleClose,
  handleSave,
  categoryName,
  categoryAbbreviation,
  handleChangeCategory,
  handleChangeAbbreviation,
  loading,
  categoryIndex,
  handleChangeIndex
}) => {
  const btnDisabled = !categoryName || !categoryAbbreviation;

  const content = {
    true: (
      <LoadingContent>
        <CircularProgress color="primary" />
      </LoadingContent>
    ),
    false: (
      <DialogContent>
        <DialogInputContent>
          <Box>
            <TextField
              label="Nome da categoria"
              variant="outlined"
              placeholder="Ex.: In natura"
              fullWidth
              value={categoryName}
              onChange={handleChangeCategory}
            />
          </Box>
          <Box style={{ display: "flex", gap: "1rem" }}>
            <TextField
              label="Sigla"
              variant="outlined"
              placeholder="Ex.: IN"
              fullWidth
              value={categoryAbbreviation}
              onChange={handleChangeAbbreviation}
            />
            <TextField
              label="Index (Opcional)"
              variant="outlined"
              placeholder="Ex.: 1"
              type="number"
              fullWidth
              value={categoryIndex}
              onChange={handleChangeIndex}
            />
          </Box>
        </DialogInputContent>
        <DialogBtnContent>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={btnDisabled}
          >
            Salvar
          </Button>
        </DialogBtnContent>
      </DialogContent>
    )
  }[loading];

  return (
    <StyledDialog open={open} handleClose={handleClose} title="Criar categoria">
      {content}
    </StyledDialog>
  );
};
