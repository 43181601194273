import { Box, CircularProgress, TextField } from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import batchService from "services/batchService";
import naboProductConfigService from "services/naboProductConfigService";
import skuService from "services/skuService";
import { QRCode } from "shared";

import { BoxButton, LoadingBlitzContent, Text } from "../style";
import { Divider } from "./style";

export const InitialView = ({
  setBatch,
  setProducts,
  setProduct,
  setAction,
  onNext
}) => {
  const { addPopup } = usePopup();
  const [tag, setTag] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let isFetchBatch = false;

  const handleChange = ({ target: { value } }) => {
    if (value < 0) return;
    setTag(value);
  };

  const handleTagIdentification = async value => {
    if (isFetchBatch) return;
    isFetchBatch = true;
    const data = value.split(";");
    if (data.length === 1) {
      setAction("batch");
      await getBatchId(data[0]);
      return;
    }
    setAction("prePicking");
    const prePickingId = data[0];
    await getBatchIdFromPrePicking(prePickingId);
    isFetchBatch = false;
  };

  const getBatchId = async (batchId, view = 2) => {
    try {
      setIsLoading(true);
      const { data } = await batchService.getBatchDetail(batchId);
      await getProductInfo(data);
      setBatch(data);
      onNext(view);
    } catch (err) {
      addPopup({
        type: "error",
        title:
          err?.response?.status === 404
            ? `Lote ${batchId} não encontrado`
            : "Erro ao buscar lote"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getProductInfo = async batch => {
    setIsLoading(true);
    try {
      const { data } = await skuService.getSku({
        productBaseId: batch.product.id,
        status: "active"
      });
      setProducts(data?.results);
    } catch (error) {
      const errorData = error?.response?.data;
      addPopup({
        type: "error",
        title: "Erro ao pegar SKUs",
        description: errorData?.message || errorData?.msg
      });
    }
    setIsLoading(false);
  };

  const getBatchIdFromPrePicking = async prePickingId => {
    try {
      setIsLoading(true);
      const { data } = await naboProductConfigService.getPrePickingById({
        prePickingId: prePickingId
      });
      const naboPrePicking = data?.data[0];
      if (!naboPrePicking || !naboPrePicking?.batch_id) {
        const { title, description } = {
          true: {
            title: "Nenhum Lote associado a essa etiqueta",
            description: `O Pré-picking "${prePickingId}" não tem ID do Lote registrado`
          },
          false: {
            title: "Pré-picking não encontrado",
            description: `Pré-picking de ID "${prePickingId}" não encontrado`
          }
        }[!!naboPrePicking];
        addPopup({ type: "error", title, description });
        return;
      }
      setProduct(naboPrePicking.sku);
      await getBatchId(naboPrePicking?.batch_id);
    } catch (error) {
      setIsLoading(false);
      addPopup({
        type: "error",
        title: "Não foi possível obter dados do Pré-picking",
        description: `Erro ao consultar dados do Pré-picking ID "${prePickingId}"`
      });
    }
  };

  const onSubmit = () => {
    setAction("batch");
    getBatchId(tag, 1);
  };

  const view = {
    true: (
      <LoadingBlitzContent>
        <CircularProgress color="primary" />
      </LoadingBlitzContent>
    ),
    false: (
      <>
        <Box>
          <Text style={{ marginBottom: "0.5rem" }}>Insira o lote</Text>
          <TextField
            variant="outlined"
            type="number"
            onChange={handleChange}
            value={tag}
            label={`Lote`}
            fullWidth
          />
        </Box>
        <Divider>ou</Divider>
        <Box>
          <Text style={{ marginBottom: "0.5rem" }}>Bipe o QR code</Text>
          <QRCode onSubmit={handleTagIdentification} maxWidth="300px" />
        </Box>
        <BoxButton>
          <Button
            style={{ width: "50%" }}
            variant="contained"
            disabled={String(tag).length <= 2}
            onClick={onSubmit}
          >
            Avançar
          </Button>
        </BoxButton>
      </>
    )
  }[isLoading];

  return view;
};
