import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  teste: {
    position: "absolute",
    right: "0",
    top: "0",
    padding: "30px",
    overflow: "hidden",
    [theme.breakpoints.between("0", "600")]: {
      width: "100%"
    }
  }
}));
