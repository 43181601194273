import { Box, Typography, styled } from "@material-ui/core";
import { Dialog as DialogShared } from "shared";

export const Text = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "1.1rem",
  color: "#757575"
});

export const BoxButton = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  gap: "1rem",
  flex: 1
});

export const LoadingBlitzContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "20vh",
  height: "100%"
});

export const Dialog = styled(DialogShared)(({ theme }) => ({
  maxWidth: "32rem",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  "& .MuiDialog-paper": { padding: 0, margin: 0, width: "100%" },
  [theme.breakpoints.up("sm")]: {
    "& .MuiDialog-paper": { height: "73%" }
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": { height: "100%", maxHeight: "100%" }
  }
}));
