import { Box, Typography, styled } from "@material-ui/core";

export const Content = styled(Box)({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem"
});

export const NotFoundText = styled(Typography)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "1.5rem",
  fontWeight: 500,
  lineHeight: "1rem",
  letterSpacing: "0.02857em"
});
