import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const api = axios.create({
  headers: {
    Authorization: `Bearer ${cookies.get("access")}`
  }
});

let isAuthError = false;

const setupInterceptors = (addPopup, signOut) => {
  api.interceptors.response.use(
    response => {
      isAuthError = false;
      return response;
    },
    error => {
      if (
        error.response.status === 401 &&
        error.response.data.detail !==
          "No active account found with the given credentials"
      ) {
        if (!isAuthError) {
          isAuthError = true;
          signOut();
          addPopup({
            type: "info",
            title: "Token expirado ou inválido."
          });
        }
      }
      return Promise.reject(error);
    }
  );
};

export default setupInterceptors;
