import { useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { ContentAddBtn, NewCategoryBtn } from "../../style";

export const AddButton = ({ onClick }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  return (
    <ContentAddBtn>
      <NewCategoryBtn onClick={onClick}>
        <AddIcon /> {!isSmallScreen && "Adicionar"}
      </NewCategoryBtn>
    </ContentAddBtn>
  );
};
