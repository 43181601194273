import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import pdfService from "services/pdfService";
import skuService from "services/skuService";
import Modal from "shared/Modal";
import * as Yup from "yup";

import { useStyles } from "./styles";

const schema = Yup.object().shape({
  portionType: Yup.string()
    .typeError("Tipo de porção deve ser um texto")
    .required("Tipo de porção é obrigatório")
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250
    }
  }
};

export const ModalDownloadPdf = ({ isOpen, close, data }) => {
  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema)
  });
  const { name, id, purchaseType } = data;
  const [values, setValues] = useState([]);
  const [listValues, setListValues] = useState([]);
  const { addPopup } = usePopup();
  const { errors } = formState;
  const classes = useStyles();

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    setValues(typeof value === "string" ? value.split(",") : value);
  };

  const handleClose = () => {
    close({
      isOpen: false,
      data: {}
    });
    setValues([]);
  };

  const handleDownloadPDF = async data => {
    try {
      if (values.length !== 0) {
        purchaseType === 1
          ? (data.portionType = "kg")
          : (data.portionType = "un");

        await pdfService
          .downloadPDF({
            productBaseId: id,
            portionings: values.toString(),
            portionType: data.portionType
          })
          .then(({ data }) => {
            fileDownload(data, id + ".pdf");
          });

        return addPopup({
          type: "success",
          title: "Sucesso ao baixar pdf"
        });
      }
      return addPopup({
        type: "error",
        title: "Selecione pelo menos uma porção (Quantidade)"
      });
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao baixar PDF"
      });

      return reset();
    }
  };

  const getPortionings = async productBaseId => {
    try {
      const { data } = await skuService.getSku({
        productBaseId,
        status: "active"
      });
      setListValues(
        data.results.map(sku => String(sku.purchaseValue)).sort((a, b) => a - b)
      );
    } catch (error) {
      const errorData = error?.response?.data;
      addPopup({
        type: "error",
        title: "Erro ao pegar SKUs",
        description: errorData?.message || errorData?.msg
      });
    }
  };

  useEffect(() => {
    if (id) getPortionings(id);
  }, [data]);

  return (
    <Modal
      title="Baixar PDF"
      open={isOpen}
      handleClose={handleClose}
      style={{
        width: "75%",
        textAlign: "center"
      }}
    >
      <Typography className={classes.helperText}>
        Selecione o porcionamento do(a) <strong>{name}</strong> que você deseja
        baixar em PDF
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit(handleDownloadPDF)}
        className={classes.boxForm}
      >
        <Box className={classes.inputBox}>
          <Box variant="standard">
            <InputLabel>Quantidade (Peso)</InputLabel>
            <Select
              multiple
              value={values}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={selected => selected.join(", ")}
              MenuProps={MenuProps}
              className={classes.input}
            >
              {listValues.map(value => (
                <MenuItem key={value} value={value}>
                  <Checkbox checked={values.indexOf(value) > -1} />
                  <ListItemText
                    primary={purchaseType === 1 ? `${value} kg` : `${value} un`}
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box variant="standard">
            <InputLabel>Tipo de porção</InputLabel>
            <Select
              placeholder="Tipo da porção"
              defaultValue={purchaseType}
              {...register("portionType")}
              error={!!errors?.portionType}
              helperText={errors?.portionType?.message}
              className={classes.input}
              disabled
              style={{ marginTop: "1rem" }}
            >
              <MenuItem value={1}>Kg</MenuItem>
              <MenuItem value={2}>Un</MenuItem>
            </Select>
          </Box>
        </Box>

        <Button
          variant="contained"
          type="submit"
          className={classes.printButton}
        >
          Baixar PDF
        </Button>
      </Box>
    </Modal>
  );
};
