import { Box, Button, Typography, styled } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Dialog } from "shared";

const StyledDialog = styled(Dialog)({
  maxWidth: "35rem",
  position: "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0
});

const ProductInfo = styled(Box)({
  display: "felx",
  flexDirection: "column",
  width: "450px",
  height: "120px",
  borderRadius: "16px",
  textAlign: "left",
  paddingTop: "0.7rem",
  marginBottom: "1rem",
  background: "#F5F8F3"
});

const ProductText = styled(Typography)({
  marginTop: "0.4rem",
  marginLeft: "1.5rem",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1rem",
  color: "#757575",
  "& span": {
    fontWeight: 600
  },
  "& strong": {
    color: "#000000"
  }
});

const ActionContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem"
});

const ButtonsContent = styled(Box)({
  display: "flex",
  flexGrow: 1,
  gap: "0.5rem"
});

const LoadingContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const StyledButton = styled(Button)(({ name }) => ({
  textTransform: "none",
  background: name === "now" ? "#8360E9" : "#EF8100",
  "&:hover": {
    background:
      name === "now" ? "rgba(131, 96, 233, 0.8) " : "rgba(239, 129, 0, 0.8)"
  }
}));

export const ConfirmPopup = ({
  openConfirm,
  handleCloseConfirm,
  handleConfirm,
  data,
  loading
}) => {
  const textDescription = loading
    ? "Confirmando entrada, aguarde..."
    : "Que horas você deseja dar entrada do produto no estoque?";

  const actionContent = {
    true: (
      <LoadingContent>
        <CircularProgress color="primary" />
      </LoadingContent>
    ),
    false: (
      <ButtonsContent>
        <StyledButton
          variant="contained"
          fullWidth
          name="now"
          onClick={handleConfirm}
        >
          Agora
        </StyledButton>
        <StyledButton
          variant="contained"
          fullWidth
          name="after"
          onClick={handleConfirm}
        >
          Após o fechamento
        </StyledButton>
      </ButtonsContent>
    )
  }[loading];
  return (
    <StyledDialog
      open={openConfirm}
      handleClose={handleCloseConfirm}
      title="Confirmar entrada"
    >
      <ProductInfo>
        <ProductText>
          Produto: <strong>{data?.product}</strong>
        </ProductText>
        <ProductText>
          Seller: <span>{data?.seller}</span>
        </ProductText>
        <ProductText>
          Porção: <span>{data?.purchaseType}</span>
        </ProductText>
      </ProductInfo>
      <ActionContent>
        <ProductText>{textDescription}</ProductText>
        {actionContent}
      </ActionContent>
    </StyledDialog>
  );
};
