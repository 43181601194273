import { ContainerPage, TitlePage } from "components";

import { ConfigCards } from "./components/ConfigCards";

export const ConfigurationsPage = () => {
  return (
    <ContainerPage>
      <TitlePage>Configurações</TitlePage>
      <ConfigCards />
    </ContainerPage>
  );
};
