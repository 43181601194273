import { yupResolver } from "@hookform/resolvers/yup";
import { Radio, RadioGroup, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { BsXLg } from "react-icons/bs";

import {
  ButtonsContent,
  FormContent,
  StyledBox,
  StyledButton,
  StyledFormControl,
  StyledFormControlLabel,
  Title
} from "../../styles";
import { validationSchema } from "./validationSchema";

export const PackagingForm = ({
  initialValues,
  onsubmit,
  loading,
  handleDelete
}) => {
  const { handleSubmit, formState, register, watch, setValue } = useForm({
    defaultValues: {
      name: initialValues?.name,
      height: initialValues?.height,
      width: initialValues?.width,
      length: initialValues?.length,
      quantity: initialValues?.quantity,
      typeOfMeasure: initialValues?.typeOfMeasure
    },
    resolver: yupResolver(validationSchema)
  });
  const { errors } = formState;

  const typeOfMeasure = watch("typeOfMeasure");

  return (
    <FormContent component="form" onSubmit={handleSubmit(onsubmit)}>
      <TextField
        variant="outlined"
        {...register("name")}
        defaultValue={initialValues?.name}
        error={!!errors?.name}
        helperText={errors?.name?.message}
        label="Tipo da embalagem"
        fullWidth
      />

      <TextField
        variant="outlined"
        {...register("quantity")}
        defaultValue={initialValues?.quantity}
        error={!!errors?.quantity}
        helperText={errors?.quantity?.message}
        label="Capacidade"
        type="number"
      />

      <Title>Qual porcionamento?</Title>
      <StyledFormControl>
        <RadioGroup
          row
          onChange={e => {
            setValue("typeOfMeasure", e.target.value);
          }}
          value={typeOfMeasure}
          style={{ gap: "0.5rem" }}
          error={!!errors?.typeOfMeasure}
          helperText={errors?.typeOfMeasure?.message}
        >
          <StyledFormControlLabel value="Un" control={<Radio />} label="Un" />
          <StyledFormControlLabel value="Kg" control={<Radio />} label="Kg" />
        </RadioGroup>
      </StyledFormControl>

      <Title>Qual tamanho da embalagem em? (cm)</Title>
      <StyledBox>
        <TextField
          variant="outlined"
          {...register("height")}
          defaultValue={initialValues?.height}
          error={!!errors?.height}
          helperText={errors?.height?.message}
          label="Altura"
          type="number"
        />
        <BsXLg color="#D9D9D9" size={"1rem"} style={{ alignSelf: "center" }} />
        <TextField
          variant="outlined"
          {...register("width")}
          defaultValue={initialValues?.width}
          error={!!errors?.width}
          helperText={errors?.width?.message}
          label="Largura"
          type="number"
        />
        <BsXLg
          color="#D9D9D9"
          size={"1rem"}
          style={{ alignSelf: "center", padding: 0, margin: 0 }}
        />
        <TextField
          variant="outlined"
          {...register("length")}
          defaultValue={initialValues?.length}
          error={!!errors?.length}
          helperText={errors?.width?.length}
          label="Comprimento"
          type="number"
        />
      </StyledBox>

      <ButtonsContent
        style={{
          justifyContent: initialValues?.id ? "space-between" : "flex-end"
        }}
      >
        {initialValues?.id && (
          <StyledButton
            variant="contained"
            type="button"
            isLoading={loading}
            style={{ background: "#EB001B" }}
            onClick={handleDelete}
          >
            Apagar
          </StyledButton>
        )}

        <StyledButton variant="contained" type="submit" isLoading={loading}>
          Salvar
        </StyledButton>
      </ButtonsContent>
    </FormContent>
  );
};
