import { Box, useTheme } from "@material-ui/core";
import { Button } from "components";
import { Dialog } from "shared";

import { Text } from "../style";

export const BatchConfirmPopup = ({
  open,
  values,
  handleClose,
  handleConfirm
}) => {
  const theme = useTheme();

  const onConfirm = () => {
    handleConfirm(values);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Confirmar Alterações"
      maxWidth="32rem"
    >
      <Text>
        A quantidade digitada é menor que a quantidade bloqueada no lote atual,
        deseja continuar mesmo assim?
      </Text>
      <Box display="flex" marginTop="1rem" style={{ gap: "1rem" }}>
        <Button
          color="warning"
          variant="contained"
          onClick={handleClose}
          fullWidth
        >
          Não
        </Button>
        <Button variant="contained" onClick={onConfirm} fullWidth>
          Sim
        </Button>
      </Box>
    </Dialog>
  );
};
