import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  gridImage: {
    flexDirection: "row-reverse",
    gap: "1rem",
    paddingRight: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "1rem"
    }
  },
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    width: "100%"
  },
  productTitle: {
    flexGrow: 1
  },
  deleteButton: {
    justifyContent: "flex-end"
  },
  button: {
    width: "100%",
    height: "2.5rem",
    padding: "8px 16px"
  },
  addButton: {
    width: "100%",
    height: "2rem"
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "4rem",
    gap: "1rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse"
    }
  }
}));
