import { mapType } from "utils";

import { Tag } from "../StepsPopup/stepsBody/Tag";
import {
  PopupContent,
  ProductInfo,
  ProductText,
  StyledDialog
} from "../StepsPopup/style";

export const PrintPopup = ({
  openPrintPopup,
  handleClosePrintPopup,
  batchData
}) => {
  return (
    <StyledDialog
      open={openPrintPopup}
      handleClose={handleClosePrintPopup}
      title="Imprimir etiquetas"
    >
      <PopupContent>
        <ProductInfo style={{ width: "100%" }}>
          <ProductText>
            Produto: <strong>{batchData?.product?.name}</strong>
          </ProductText>
          <ProductText>
            Seller: <span>{batchData?.seller?.name}</span>
          </ProductText>
          <ProductText>
            Porção: <span>{mapType(batchData?.product?.purchase_type)}</span>
          </ProductText>
        </ProductInfo>
        <Tag
          handleCloseOpenSteps={handleClosePrintPopup}
          updatedBatch={batchData}
        />
      </PopupContent>
    </StyledDialog>
  );
};
