import { Box, Grid, styled, Typography } from "@material-ui/core";
import { Button } from "components";

export const Container = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column"
}));

export const Title = styled(Typography)(() => ({
  fontFamily: "'Inter', Arial, Helvetica",
  fontSize: "1.25rem",
  color: "#353535",
  fontWeight: 600,
  marginBottom: "1.5rem"
}));

export const TextFieldWrapper = styled(Grid)(() => ({
  width: "100%",
  marginTop: "1rem",

  "& > .MuiTextField-root": {
    width: "100%"
  }
}));

export const SaveButton = styled(Button)(() => ({
  width: "7rem",
  height: "3rem",
  fontFamily: "'Roboto', Arial, Helvetica",
  fontSize: "1rem",
  color: "#fff",
  borderRadius: 4
}));
