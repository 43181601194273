import { useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import { Dialog } from "shared";

import { Form } from "./Form";
import { Scanner } from "./Scanner";

export const ModalPrint = ({ isOpen, close, dataProduct, blitzData }) => {
  const [productBase, setProductBase] = useState({});
  const [batchExternalCode, setBatchExternalCode] = useState("");
  const [popUpPage, setPopUpPage] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const location = useLocation();
  const batchId = new URLSearchParams(location.search).get("productBaseId");

  const handleClose = () => {
    close({ isOpen: false, data: {} });
    setPopUpPage(0);
    setBatchExternalCode("");
    setProductBase({});

    const removeParams = window.location.pathname;
    history.push(removeParams);
  };

  const updateProductBase = () => {
    setProductBase(dataProduct);
    setPopUpPage(1);
  };

  const children = {
    0: (
      <Scanner
        setProductBase={setProductBase}
        dataProduct={productBase}
        onNext={() => setPopUpPage(state => ++state)}
      />
    ),
    1: (
      <Form
        dataProduct={productBase}
        handleClose={handleClose}
        onBack={() => setPopUpPage(state => --state)}
        batchExternalCode={batchExternalCode}
        setBatchExternalCode={setBatchExternalCode}
        blitzData={blitzData}
      />
    )
  }[popUpPage];

  useEffect(() => {
    !!Object.entries(dataProduct ?? {}).length && updateProductBase();
  }, [dataProduct]);

  useEffect(() => {
    if (isOpen) {
      history.push(`/app/produtos?productBaseId=${productBase?.id}`);
    }
  }, [productBase]);

  useEffect(() => {
    if (!batchId) {
      handleClose();
    }
  }, [batchId]);

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title="Imprimir etiqueta"
      fullScreen={isMobile}
    >
      {children}
    </Dialog>
  );
};
