import { Box, Typography, styled } from "@material-ui/core";
import { Button as _Button } from "components";
import { Dialog as _Dialog } from "shared";

export const Dialog = styled(_Dialog)(() => ({
  maxWidth: "34.9375rem",
  maxHeight: "42.1875rem",
  margin: "auto",
  "& .MuiDialog-paper": {
    width: "100%",
    height: "100%",
    padding: 0,
    margin: 0
  }
}));

export const Content = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem"
}));

export const Info = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#F5F8F3",
  padding: "1rem 1.25rem",
  borderRadius: 16,

  "& > div": {
    display: "flex",
    gap: 4
  }
}));

export const Span = styled(Typography)(() => ({
  fontFamily: "'Roboto', Arial, Helvetica",
  fontSize: "1rem",
  color: "#757575",
  fontWeight: 400
}));

export const Strong = styled(Span)(() => ({
  fontWeight: 500
}));

export const Button = styled(_Button)(() => ({
  width: "100%",
  height: "3rem"
}));
