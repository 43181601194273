import { Typography } from "@material-ui/core";

import { useStyles } from "./styles";

export const Loading = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} data-cy="loading-order">
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};
