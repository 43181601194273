import { usePopup } from "hooks/usePopup";
import { useMemo, useRef } from "react";
import assetService from "services/assetsService";
import { Drawer } from "shared";

import { Form } from "../Form";

export const Edit = ({ isOpen, setIsOpen, refresh, data }) => {
  const formRef = useRef(null);
  const { addPopup } = usePopup();

  const handleClose = () => {
    setIsOpen({ isOpen: false, data: {} });
    formRef.current?.resetForm();
  };

  const onSubmit = async dataForm => {
    try {
      const { description, ...rest } = dataForm;

      await assetService.updateAsset({
        ...rest,
        description: dataForm.description?.trim()
      });

      addPopup({
        type: "success",
        title: "Ativo editado com sucesso"
      });
      refresh();
      handleClose();
    } catch (err) {
      if (err.response.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao atualizar ativo"
        });
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      title="Editar Ativo"
      open={isOpen}
      onClose={handleClose}
    >
      <Form ref={formRef} initialValues={data} onSubmit={onSubmit} />
    </Drawer>
  );
};
