import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column"
  },
  loading: {
    marginTop: "4rem",
    marginBottom: "4rem",
    color: "#757575"
  },
  assistantText: {
    margin: "1rem 0",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "1.1rem",
    color: "#757575"
  },
  content: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center"
  },
  weightBox: {
    width: "100%",
    height: "3.2rem",
    borderRadius: "16px",
    background: "#F5F8F3",
    border: "2px solid #B5C9A8"
  },
  productText: {
    marginTop: "0.4rem",
    marginLeft: "1.5rem",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "1rem",
    color: "#757575",
    "& span": {
      fontWeight: 600
    },
    "& strong": {
      color: "#000000"
    }
  },
  buttonAllignBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  backButton: {
    marginTop: "2.5rem",
    width: "30%",
    background: "#ffffff",
    border: "1px solid #757575",
    "& span": {
      color: "#757575"
    },
    "&:hover": {
      background: "#efefef"
    }
  },
  button: {
    marginTop: "2.5rem",
    minWidth: "30%",
    width: "auto"
  },
  buttonBox: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center"
  }
}));
