import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class SellerItemService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getSellerItems(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller-item/`;
    const { data } = await api.get(url, { params });
    return {
      data: {
        ...data,
        total_count: data.totalCount
      }
    };
  }

  async postSellerItem(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller-item/`;
    const response = await api.post(url, data);
    return response;
  }

  async updateSellerItem(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller-item/${data.id}`;
    const response = await api.put(url, data);
    return response;
  }

  async deleteSellerItem(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller-item/${id}`;
    const response = await api.delete(url);
    return response;
  }
}

const sellerItemService = new SellerItemService();
export default sellerItemService;
