import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useRef, useState } from "react";

import {
  ChipButtonFilter,
  FilterContent,
  FilterLabel,
  ReceiptPageContent,
  SearchContent,
  SearchInput
} from "../../../style";
import { mapStatusFilter } from "../../../utils/mapStatusFilter";
import { ReceiptAccordion } from "../../ReceiptAccordion";
import { Menu, MenuItem, ChipMenuItem } from "./style";

export const FinishedPage = ({
  debaunceSearch,
  finishedData,
  handleOpenSteps,
  activeTab,
  handleFilter,
  filterStatus,
  handleOpenPrintPopup,
  handleOpenNewBatchPopup,
  dataLoading,
  handleOpenEditTaxEntryPopUp
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(null);
  const menuRef = useRef(null);

  const togglePopover = () => setMenuIsOpen(state => !state);

  const handleSelect = statusId => {
    handleFilter(Number(statusId));
    togglePopover();
  };

  return (
    <ReceiptPageContent>
      <SearchContent>
        <SearchInput
          placeholder="Seller ou produto"
          onChange={debaunceSearch}
        />
        <FilterContent>
          <FilterLabel>Filtrar:</FilterLabel>

          <ChipButtonFilter
            ref={menuRef}
            onClick={togglePopover}
            label={mapStatusFilter(filterStatus)}
            onDelete={togglePopover}
            deleteIcon={<ExpandMoreIcon />}
            statusId={filterStatus}
            aria-controls="customized-menu"
            aria-haspopup="true"
          />

          <Menu
            anchorEl={menuRef.current}
            keepMounted
            open={menuIsOpen}
            onClose={togglePopover}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            elevation={0}
            getContentAnchorEl={null}
            style={{ padding: 0 }}
          >
            <MenuItem onClick={() => handleSelect(0)}>
              <ChipMenuItem label="Todos" />
            </MenuItem>
            <MenuItem onClick={() => handleSelect(2)}>
              <ChipMenuItem label="Recebidos" statusId={2} />
            </MenuItem>
            <MenuItem onClick={() => handleSelect(5)}>
              <ChipMenuItem label="Não trouxe" statusId={5} />
            </MenuItem>
            <MenuItem onClick={() => handleSelect(4)}>
              <ChipMenuItem label="Recusado" statusId={4} />
            </MenuItem>
          </Menu>
        </FilterContent>
      </SearchContent>

      <ReceiptAccordion
        sellerBatchData={finishedData}
        handleOpenSteps={handleOpenSteps}
        activeTab={activeTab}
        handleOpenPrintPopup={handleOpenPrintPopup}
        handleOpenNewBatchPopup={handleOpenNewBatchPopup}
        dataLoading={dataLoading}
        handleOpenEditTaxEntryPopUp={handleOpenEditTaxEntryPopUp}
      />
    </ReceiptPageContent>
  );
};
