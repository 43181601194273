import {
  Dialog,
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress
} from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import migrationService from "services/migrationService";
import { errorsSku } from "utils/errorsSku";

import { useStyles } from "./styles";

export const DialogConfirmDelete = ({ data, open, handleClose, refresh }) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleClear = () => {
    handleClose({
      isOpen: false,
      fn: handleConfirmDialog
    });
  };

  const handleConfirmDialog = async () => {
    try {
      setIsLoading(true);
      await migrationService.deleteMigrationRules(data.id);
      addPopup({ type: "success", title: "Regra excluída com sucesso." });
      refresh();
      handleClear();
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({ type: "error", title: "Erro ao excluir regra" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} className={classes.root}>
      <Paper className={classes.paper}>
        <Grid>
          <Grid item xs={12}>
            <Typography className={classes.title}>Confirmação</Typography>
          </Grid>

          <Grid item xs={12} className={classes.box}>
            <Typography>Deseja excluir essa regra?</Typography>
          </Grid>

          <Grid container item xs={12} className={classes.boxBtn}>
            <Grid item>
              <Button className={classes.buttonCancel} onClick={handleClear}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonConfirm}
                onClick={handleConfirmDialog}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} style={{ color: "#fff" }} />
                ) : (
                  "Confirmar"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};
