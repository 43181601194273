import { TableCell, styled } from "@material-ui/core";
import { TableContainer, TableHead, TableRow } from "shared";

export const HeadCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem"
});

export const BodyRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const StyledTableHead = styled(TableHead)({
  background: "#F3F8E6"
});

export const TableContent = styled(TableContainer)({
  display: "flex",
  flexDirection: "column",
  maxHeight: "40rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0.6rem",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "9px"
  }
});

export const BodyCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem"
});
