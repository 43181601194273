import {
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { SellerOrderStatus } from "services/sellerOrderService";
import AutoCompletePackaging from "shared/AutoCompletePackaging";

import { useStyles } from "../styles";

export const PackagingLine = ({
  packaging,
  index,
  onChange,
  onRemove,
  formik
}) => {
  const classes = useStyles();

  const isFinalizedOrder =
    formik?.values?.status?.id &&
    formik.values.status.id !== SellerOrderStatus.PENDING;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" className={classes.packagingTitle}>
              Embalagem {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={2} container className={classes.deleteButton}>
            <IconButton disabled={isFinalizedOrder} onClick={onRemove}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <AutoCompletePackaging
                name={`packaging[${index}].packaging`}
                value={packaging?.packaging || packaging?.packagingId || ""}
                defaultValue={
                  packaging?.packaging || packaging?.packagingId || ""
                }
                onChange={(_, value) => onChange(index, "packaging", value)}
                disabled={isFinalizedOrder}
                label="Selecione a Embalagem *"
                error={
                  formik.touched.packaging?.[index]?.packaging &&
                  !!formik.errors.packaging?.[index]?.packaging
                }
                helperText={
                  formik.touched.packaging?.[index]?.packaging &&
                  formik.errors.packaging?.[index]?.packaging
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name={`packaging[${index}].quantity`}
                label="Quantidade *"
                type="number"
                value={packaging?.quantity || ""}
                disabled={isFinalizedOrder}
                onChange={event =>
                  onChange(index, "quantity", event.target.value)
                }
                error={
                  formik.touched.packaging?.[index]?.quantity &&
                  !!formik.errors.packaging?.[index]?.quantity
                }
                helperText={
                  formik.touched.packaging?.[index]?.quantity &&
                  formik.errors.packaging?.[index]?.quantity
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
