import { styled } from "@material-ui/core";
import ReactPaginate from "react-paginate";

export const Paginate = styled(ReactPaginate)(({ loading }) => ({
  display: "flex",
  gap: "0.5rem",
  listStyleType: "none",

  "& li": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "2rem",
    minHeight: "2rem",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    cursor: "pointer",
    pointerEvents: loading ? "none" : "auto"
  },

  "& a": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "2rem",
    minHeight: "2rem",
    lineHeight: "1rem"
  },

  "& .previous, .next": {
    color: "#C4C4C4",
    cursor: "pointer",
    "& a": {
      color: "#C4C4C4",
      textDecoration: "none"
    }
  },

  "& .selected": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "2rem",
    minHeight: "2rem",
    borderRadius: "50%",
    backgroundColor: "#618619",
    color: "#FFFFFF"
  },
  cursor: loading ? "wait" : "auto"
}));
