import {
  Menu as MenuMui,
  MenuItem as MenuItemMui,
  styled
} from "@material-ui/core";
import { ChipStatus } from "components/ChipStatus";

export const MenuItem = styled(MenuItemMui)({
  padding: 0,
  "& div": {
    height: "100%"
  }
});

export const Menu = styled(MenuMui)({
  "& ul": {
    padding: 0
  }
});

export const ChipMenuItem = styled(ChipStatus)({
  display: "flex",
  padding: "1rem",
  flex: 1,
  cursor: "pointer",
  borderRadius: 0,
  "& .MuiButtonBase-root": {
    borderRadius: 0
  }
});
