export const convertUTCDateToLocalDate = date => {
  const dateLocal = new Date(date);
  const newDate = new Date(dateLocal.getTime()); // - dateLocal.getTimezoneOffset()*60*1000);
  return newDate;
};

export const convertDate = date => {
  const newDate = date.toLocaleString("pt-BR", {
    day: "2-digit",
    year: "2-digit",
    month: "2-digit",
    hour: "numeric",
    minute: "numeric"
  });

  return newDate;
};
