import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class MigrationService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async getMigrationRules(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/migration/rules`;
    return await api.get(url, { params });
  }

  async getMigrationRulesDetail(ruleId) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/migration/rules/${ruleId}`;
    return await api.get(url);
  }

  async createMigrationRules(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/migration/rules`;
    return await api.post(url, data);
  }

  async deleteMigrationRules(ruleId) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/migration/rules/${ruleId}`;
    return await api.delete(url);
  }

  async updateMigrationRules(ruleId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/migration/rules/${ruleId}`;
    return await api.patch(url, data);
  }

  async migration(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/migration`;
    return await api.post(url, data);
  }
}

const migrationService = new MigrationService();
export default migrationService;
