import { useStyles } from "./styles";

export const ContainerPage = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...rest}>
      {children}
    </div>
  );
};
