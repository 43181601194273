export const errorsProductBase = value => {
  const errors =
    {
      "Error! The unit type of the product is required.":
        "O tipo de entrada é obrigatório.",
      "Error! The name of the product is required.":
        "O nome do produto é obrigatório.",
      "Error! There is still active SKU": "Esse produto base tem SKU ativo",
      "Error! This value cannot be zero.": "A validade não pode ser 0",
      "A product with this same span and sequence already exists!":
        "Já existe um produto com essa mesma migração e sequência!",
      "Existe ao menos um SKU ativo com esse produto base, desative-o antes.":
        "Existe ao menos um SKU ativo com esse produto base, desative-o antes."
    }[value] || "Erro ao criar produto base";

  return errors;
};
