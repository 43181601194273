import { Box, styled, Button, Typography } from "@material-ui/core";

export const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

export const AddButton = styled(Button)(() => ({
  width: "8.3125rem",
  height: "2.5rem",
  fontFamily: "'Roboto', Arial, Helvetica",
  fontSize: "1rem",
  color: "#fff",
  borderRadius: 8
}));

export const Content = styled(Box)(() => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "1.5rem"
}));

export const WarehouseCard = styled(Box)(() => ({
  borderRadius: 16,
  padding: "1.6875rem 1.25rem",
  background: "#fff",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
}));

export const WarehouseTypeTitle = styled(Typography)(() => ({
  fontFamily: "'Roboto', Arial, Helvetica",
  fontSize: "0.875rem",
  color: "#353535",
  fontWeight: 500
}));

export const EditButton = styled("button")(() => ({
  background: "transparent",
  lineHeight: 0,
  border: "none",
  cursor: "pointer"
}));
