import { Box, CircularProgress, TextField } from "@material-ui/core";
import { Button } from "components";

import {
  DialogBtnContent,
  DialogContent,
  DialogInputContent,
  LoadingContent,
  StyledDialog
} from "../../style";
import { DropDownComponent } from "../DropDownComponent";

export const DialogUpdate = ({
  open,
  handleClose,
  handleSave,
  subcategoryName,
  subcategoryAbbreviation,
  handleChangeSubcategory,
  handleChangeAbbreviation,
  categoriesData,
  handleSelectCategory,
  handleSelectStockType,
  storageStockTypesData,
  selectedCategoryId,
  selectedStockTypeId,
  loading,
  subCategoryIndex,
  handleChangeIndex
}) => {
  const btnDisabled =
    !subcategoryName || !subcategoryAbbreviation || !selectedCategoryId;

  const content = {
    true: (
      <LoadingContent>
        <CircularProgress color="primary" />
      </LoadingContent>
    ),
    false: (
      <DialogContent>
        <TextField
          label="Nome da subcategoria"
          variant="outlined"
          placeholder="Ex.: Frutas"
          fullWidth
          value={subcategoryName}
          onChange={handleChangeSubcategory}
        />

        <Box style={{ display: "flex", gap: "1rem" }}>
          <TextField
            label="Sigla"
            variant="outlined"
            placeholder="Ex.: FR"
            fullWidth
            value={subcategoryAbbreviation}
            onChange={handleChangeAbbreviation}
          />
          <TextField
            label="Index (Opcional)"
            variant="outlined"
            placeholder="Ex.: 1"
            type="number"
            fullWidth
            value={subCategoryIndex}
            onChange={handleChangeIndex}
          />
        </Box>

        <DropDownComponent
          label="Categoria"
          data={categoriesData}
          onChange={handleSelectCategory}
          value={selectedCategoryId}
        />

        <DropDownComponent
          label="Tipo de estocagem"
          data={storageStockTypesData}
          onChange={handleSelectStockType}
          value={selectedStockTypeId}
        />

        <DialogBtnContent>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={btnDisabled}
          >
            Salvar
          </Button>
        </DialogBtnContent>
      </DialogContent>
    )
  }[loading];

  return (
    <StyledDialog
      open={open}
      handleClose={handleClose}
      title="Editar subcategoria"
    >
      {content}
    </StyledDialog>
  );
};
