import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import batchServiceV3 from "services/batchServiceV3";
import { Dialog } from "shared";

import { ConfirmPage } from "./ConfirmPage";
import { InitialPage } from "./InitialPage";

export const WastePopup = ({
  open,
  handleClose,
  batchData,
  batchReasons,
  refresh
}) => {
  const { addPopup } = usePopup();
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleNext = data => {
    setPage(state => ++state);
    setFormData(data);
  };

  const handleBack = () => {
    setPage(state => --state);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await batchServiceV3.postBatchMove(batchData.id, formData);
      addPopup({
        type: "success",
        title: "Movimentação realizada com sucesso"
      });
      refresh();
      handleClose();
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao realizar movimentação",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const pageRender = {
    0: (
      <InitialPage
        handleNext={handleNext}
        batchData={batchData}
        batchReasons={batchReasons}
        handleClose={handleClose}
      />
    ),
    1: (
      <ConfirmPage
        data={formData}
        batchReasons={batchReasons}
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    )
  }[page];

  useEffect(() => {
    setPage(0);
  }, [open]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title="Movimentar"
      maxWidth="32rem"
    >
      {pageRender}
    </Dialog>
  );
};
