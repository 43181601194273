import { CircularProgress, Divider, Typography } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { IoTime } from "react-icons/io5";
import { RiDonutChartFill, RiUserReceived2Fill } from "react-icons/ri";
import batchService from "services/batchService";

import { formatData } from "../../../utils/formatData";
import { CardTitle, CardValue, IconContent, InfoContent } from "../styles";
import { CardContent } from "./CardContent";
import { CardsContainer } from "./styles";

export const VertDashCards = () => {
  const { addPopup } = usePopup();
  const [batchSellerPendingData, setBatchSellerPendingData] = useState({});
  const [batchSellerReceiptData, setBatchSellerReceiptData] = useState({});
  const [batchConfirmPendingData, setBatchConfirmPendingData] = useState({});
  const [loadingSellerPending, setLoadingSellerPending] = useState(false);
  const [loadingSellerReceipt, setLoadingSellerReceipt] = useState(false);
  const [loadingConfirmPending, setLoadingConfirmPending] = useState(false);
  const [lastUpdated, setLastUpdated] = useState();

  const getBatchSellerPending = async () => {
    try {
      setLoadingSellerPending(true);
      const params = {
        status: 1,
        pageSize: 1000
      };
      const { data } = await batchService.getSellerBatches(params);
      const formattedData = formatData.formatSellerPendingData(data.results);
      setBatchSellerPendingData(formattedData);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar lotes",
        description: errorMessage
      });
    } finally {
      setLoadingSellerPending(false);
    }
  };

  const getBatchSellerReceipt = async () => {
    try {
      const toDayDate = new Date();
      setLoadingSellerReceipt(true);
      const params = {
        status: 2,
        receiptDateFrom: new Date(toDayDate.setHours(0, 0, 0, 1)),
        receiptDateTo: new Date(toDayDate.setHours(23, 59, 59, 0)),
        pageSize: 1000
      };
      const { data } = await batchService.getSellerBatches(params);
      const formattedData = formatData.formatSellerReceiptData(data.results);
      setBatchSellerReceiptData(formattedData);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar lotes",
        description: errorMessage
      });
    } finally {
      setLoadingSellerReceipt(false);
    }
  };

  const getBatchConfirmPending = async () => {
    try {
      setLoadingConfirmPending(true);
      const params = {
        status: 2,
        purchaseItemReasonId: 9,
        pageSize: 1000
      };
      const { data } = await batchService.getBatches(params);
      const formattedData = formatData.formatConfirmPendingData(data.results);
      setBatchConfirmPendingData(formattedData);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar lotes",
        description: errorMessage
      });
    } finally {
      setLoadingConfirmPending(false);
    }
  };

  const getLastUpdated = () => {
    const date = new Date();
    setLastUpdated(
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    );
  };

  useState(() => {
    getBatchSellerPending();
    getBatchSellerReceipt();
    getBatchConfirmPending();
    getLastUpdated();
  }, []);

  return (
    <CardsContainer flexDirection="column" height="fit-content" gridArea="side">
      <CardContent loading={loadingSellerPending}>
        <IconContent style={{ background: "#2E656A" }}>
          <RiUserReceived2Fill size={20} color="#FFF" />
        </IconContent>
        <InfoContent>
          <CardTitle>Fornecedores a receber</CardTitle>
          <CardValue>{batchSellerPendingData.suppliersReceivable}</CardValue>
        </InfoContent>
      </CardContent>

      <Divider variant="middle" />

      <CardContent loading={loadingSellerPending}>
        <IconContent style={{ background: "#FFC84E" }}>
          <IoTime size={20} color="#FFF" />
        </IconContent>
        <InfoContent>
          <CardTitle>Fornecedores pendentes</CardTitle>
          <CardValue> {batchSellerPendingData.suppliersPending}</CardValue>
        </InfoContent>
      </CardContent>

      <Divider variant="middle" />

      <CardContent loading={loadingSellerReceipt}>
        <IconContent style={{ background: "#4EAA68" }}>
          <FaCheck size={20} color="#FFF" />
        </IconContent>
        <InfoContent>
          <CardTitle>Fornecedores finalizados</CardTitle>
          <CardValue>{batchSellerReceiptData.finalizedSuppliers}</CardValue>
        </InfoContent>
      </CardContent>

      <Divider variant="middle" />

      <CardContent loading={loadingConfirmPending}>
        <IconContent style={{ background: "#3EA0DE" }}>
          <RiDonutChartFill size={20} color="#FFF" />
        </IconContent>
        <InfoContent>
          <CardTitle>Pendentes de confirmação</CardTitle>
          <CardValue>{batchConfirmPendingData.unconfirmed}</CardValue>
        </InfoContent>
      </CardContent>

      <Divider variant="middle" />

      <CardContent loading={loadingConfirmPending}>
        <InfoContent>
          <CardTitle>Última atualização</CardTitle>
          <CardValue>{lastUpdated}</CardValue>
        </InfoContent>
      </CardContent>
    </CardsContainer>
  );
};
