import { Box, Typography, styled } from "@material-ui/core";

export const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "10rem",
  gap: "1rem"
});

export const ConfirmContent = styled(Box)({
  display: "flex",
  flexDirection: "row",
  minHeight: "3.25rem",
  width: "100%",
  background: "#F5F8F3",
  border: "2px solid #B5C9A8",
  fontSize: "1rem",
  fontWeight: 500,
  justifyContent: "left",
  alignItems: "center",
  borderRadius: "1rem",
  paddingInline: "1rem",
  gap: "0.5rem"
});

export const LabelConfirmDescription = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#757575"
});

export const LabelConfirmValue = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 600,
  fontSize: "1.1rem"
});

export const ButtonsContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  gap: "0.5rem",
  width: "100%",
  paddingBlock: "1rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}));
