import { makeStyles } from "@material-ui/core";

export const conferenceDataStyles = makeStyles(theme => ({
  boxStyle: {
    width: "35rem",
    height: "13rem",
    background: "#F5F8F3",
    borderRadius: "1rem",
    [theme.breakpoints.between(0, 700)]: {
      width: "auto"
    }
  },
  conferenceDataInfo: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.2rem",
    color: "#757575",
    paddingTop: "0.8rem",
    paddingLeft: "1rem",
    "& strong": {
      color: "#161616"
    },
    "& span": {
      fontWeight: 500
    }
  }
}));
