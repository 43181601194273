import {
  Dialog,
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress
} from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import skuService from "services/skuService";
import { errorsSku } from "utils/errorsSku";

import { useStyles } from "./styles";

export const DialogConfirmDelete = ({ data, open, handleClose, refresh }) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleClear = () => {
    handleClose({
      isOpen: false,
      fn: handleConfirmDialog
    });
  };

  const handleConfirmDialog = async () => {
    try {
      setIsLoading(true);
      await skuService.deleteSku(data.sku);

      addPopup({
        type: "success",
        title: "Sku excluido com sucesso."
      });

      refresh();
      handleClear();
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: errorsSku(error.response?.data?.message)
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} className={classes.root}>
      <Paper className={classes.paper}>
        <Grid>
          <Grid item xs={12}>
            <Typography className={classes.title}>Confirmação</Typography>
          </Grid>

          <Grid item xs={12} className={classes.box}>
            <Typography>Deseja excluir esse Sku?</Typography>
          </Grid>

          <Grid container item xs={12} className={classes.boxBtn}>
            <Grid item>
              <Button className={classes.buttonCancel} onClick={handleClear}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonConfirm}
                onClick={handleConfirmDialog}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} style={{ color: "#fff" }} />
                ) : (
                  "Ok"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};
