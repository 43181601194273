import { Box, Typography, Tooltip } from "@material-ui/core";
import { RiErrorWarningLine } from "react-icons/ri";

import { useStyles } from "./styles";

export const CircleActive = ({ className, width, color, title }) => {
  const classes = useStyles({ width, color });

  return (
    <Tooltip title={title} arrow>
      <Box className={`${classes.root} ${className}`}>
        <span className={classes.circle} />
        <span className={classes.circle} />
        <span className={classes.circle} />
        <span className={classes.circle} />
        <Typography
          style={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
            fontSize: "0.9rem",
            fontWeight: 700
          }}
        >
          !
        </Typography>
      </Box>
    </Tooltip>
  );
};
