import { Grid } from "@material-ui/core";

import { CardLoading } from "../CardLoading";

export const LoadingPage = () => {
  return (
    <>
      {Array.from({ length: 20 }).map((_, index) => (
        <Grid
          key={index}
          container
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          justifyContent="center"
        >
          <CardLoading />
        </Grid>
      ))}
    </>
  );
};
