import { Grid, Box } from "@material-ui/core";
import { Add, ArrowBack } from "@material-ui/icons";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import React, { useCallback, useImperativeHandle, forwardRef } from "react";
import { SellerOrderStatus } from "services/sellerOrderService";

import { ProductLine } from "./ProductLine";
import { useStyles } from "./styles";

const initialProduct = () => ({
  productBase: null,
  description: "",
  quantity: 0,
  uniqueKey: Math.random().toString(36)
});

const FormStepThree = (
  { formik, setStep, removedItems, openConfirmationPopup },
  ref
) => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  useImperativeHandle(ref, () => ({ resetForm: formik.resetForm }));

  const handleAddProduct = useCallback(() => {
    const newProduct = initialProduct();
    const updatedProducts = [...formik.values.sellerOrderItems, newProduct];
    formik.setFieldValue("sellerOrderItems", updatedProducts);
  }, [formik]);

  const handleRemoveProduct = useCallback(
    index => {
      if (formik.values.sellerOrderItems.length === 1) {
        addPopup({
          type: "error",
          title: "Erro",
          description: "É necessário ter pelo menos um produto."
        });
        return;
      }

      const updatedProducts = [...formik.values.sellerOrderItems];
      const removedProduct = updatedProducts.splice(index, 1)[0];
      if (removedProduct.id) {
        removedItems.current = [...removedItems.current, removedProduct.id];
      }
      formik.setFieldValue("sellerOrderItems", updatedProducts);
    },
    [formik, removedItems, addPopup]
  );

  const handleProductChange = useCallback(
    (index, field, value) => {
      const updatedProducts = formik.values.sellerOrderItems.map((product, i) =>
        i === index ? { ...product, [field]: value } : product
      );
      formik.setFieldValue("sellerOrderItems", updatedProducts);
    },
    [formik]
  );

  const areAllFieldsFilled = useCallback(() => {
    return formik.values.sellerOrderItems.every(
      product => product.productBase && product.quantity > 0
    );
  }, [formik]);

  const handleSubmit = useCallback(
    async finalize => {
      formik.setFieldValue(
        "status",
        finalize ? SellerOrderStatus.CONFIRMED : SellerOrderStatus.PENDING
      );
      await formik.handleSubmit();
    },
    [formik]
  );

  const onFinalizingOrder = useCallback(
    finalize => {
      if (!areAllFieldsFilled()) {
        addPopup({
          type: "error",
          title: "Erro",
          description: "Preencha todos os campos dos produtos."
        });
        return;
      }

      if (finalize) {
        openConfirmationPopup(() => handleSubmit(true));
      } else {
        handleSubmit(false);
      }
    },
    [areAllFieldsFilled, addPopup, openConfirmationPopup, handleSubmit]
  );

  const isFinalizedOrder =
    formik?.values?.status?.id &&
    formik.values.status.id !== SellerOrderStatus.PENDING;

  return (
    <Box component="form" noValidate>
      <Grid container spacing={2}>
        {formik.values.sellerOrderItems.map((product, index) => (
          <ProductLine
            key={product.uniqueKey}
            product={product}
            index={index}
            onChange={handleProductChange}
            onRemove={() => handleRemoveProduct(index)}
            formik={formik}
          />
        ))}
        {!isFinalizedOrder && (
          <Grid item xs={12}>
            <Button
              className={classes.button}
              variant="outlined"
              startIcon={<Add />}
              onClick={handleAddProduct}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        <Grid item xs={12} className={classes.buttonsContainer}>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              isLoading={formik.isSubmitting}
              variant="text"
              type="button"
              className={classes.button}
              startIcon={<ArrowBack />}
              onClick={() => setStep(2)}
            >
              Voltar
            </Button>
          </Grid>
          {!isFinalizedOrder && (
            <>
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  isLoading={formik.isSubmitting}
                  variant="contained"
                  type="button"
                  onClick={() => onFinalizingOrder(false)}
                  className={classes.button}
                  disabled={
                    !formik.isValid || !areAllFieldsFilled() || !formik.dirty
                  }
                >
                  Salvar
                </Button>
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Button
                  isLoading={formik.isSubmitting}
                  variant="contained"
                  type="button"
                  className={classes.button}
                  onClick={() => onFinalizingOrder(true)}
                  disabled={!formik.isValid || !areAllFieldsFilled()}
                >
                  Finalizar Pedido
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export const OrderItemForm = forwardRef(FormStepThree);
