export const mapType = value => (value === 1 ? "Quilo" : "Unidade");
export const mapPurchaseType = value => (value === 1 ? "Kg" : "Un");
export const mapStrings = value =>
  value === "Quantidade atual"
    ? "Qnt. atual"
    : value === "Preço combinado"
    ? "Preço comb."
    : value === "Devolução combinada"
    ? "Devolução comb."
    : value === "Previsão Final"
    ? "Prev. final"
    : value === "Estoque físico"
    ? "Est. físico"
    : value;
