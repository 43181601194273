/* eslint-disable react/display-name */
import {
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableHead
} from "@material-ui/core";
import ptBR from "date-fns/locale/pt-BR";
import { usePopup } from "hooks/usePopup";
import fileDownload from "js-file-download";
import { forwardRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import { TableVirtuoso } from "react-virtuoso";
import reporterGenerateService from "services/reporterGenerateService";
import { useDebaunce } from "utils";

import "react-datepicker/dist/react-datepicker.css";

import {
  BodyCell,
  ButtonContent,
  DataPickerContent,
  DownloadButton,
  HeadCell,
  SearchContent,
  SearchInput,
  StyledDatePicker,
  StyledDialog,
  StyledLink,
  StyledTableHead,
  TableContent,
  styledTableRow
} from "./styles";
registerLocale("ptBR", ptBR);

const TableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContent component={Paper} {...props} ref={ref} />
  )),
  Table: props => (
    <Table stickyHeader {...props} style={{ borderCollapse: "separate" }} />
  ),
  TableHead: TableHead,
  TableRow: styledTableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />)
};

export const BatchBreakageModal = ({
  open,
  handleClose,
  batchData,
  complaintsData,
  scheduleDateFrom,
  scheduleDateTo,
  handleChangeScheduleDate,
  loading
}) => {
  const { addPopup } = usePopup();
  const [search, setSearch] = useState("");
  const [loadingDownload, setLoadingDownload] = useState(false);

  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });

  const TableHeader = () => (
    <StyledTableHead>
      <HeadCell style={{ minWidth: "10rem" }}>Lote</HeadCell>
      <HeadCell style={{ minWidth: "20rem" }}>Produto</HeadCell>
      <HeadCell style={{ minWidth: "15rem" }}>Recebido em</HeadCell>
      <HeadCell style={{ minWidth: "10rem" }} align="center">
        Shelf life
      </HeadCell>
      <HeadCell style={{ minWidth: "15rem" }}>Motivo</HeadCell>
    </StyledTableHead>
  );

  const handleDownload = async () => {
    try {
      setLoadingDownload(true);
      const { data } = await reporterGenerateService.excelGenerate(
        complaintsData
      );

      const currentDate = new Date();
      const formattedDate = currentDate
        .toLocaleString()
        .replace(/[:.,\s]/g, "_");
      const fileName = `lotes_com_quebras_${formattedDate}.xlsx`;

      fileDownload(data, fileName);
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          description: "Erro ao fazer download"
        });
      }
    } finally {
      setLoadingDownload(false);
    }
  };

  function handleSearch(e) {
    const { value } = e.target;
    setSearch(value);
  }

  const filteredData = !search?.trim()
    ? batchData
    : (batchData || []).filter(
        batch =>
          batch.productBaseName
            ?.toLowerCase()
            .includes(search.toLowerCase().trim()) ||
          batch.id?.toString().includes(search.trim())
      );

  return (
    <StyledDialog
      title="Lotes com quebra"
      open={open}
      handleClose={handleClose}
      maxWidth={"80vw"}
    >
      <SearchContent>
        <SearchInput placeholder="Lote ou produto" onChange={debaunceSearch} />
        <DataPickerContent>
          <StyledDatePicker
            dateFormat="dd/MM/yyyy"
            locale="ptBR"
            selected={scheduleDateFrom}
            onChange={handleChangeScheduleDate}
            startDate={scheduleDateFrom}
            endDate={scheduleDateTo}
            selectsRange
          />
        </DataPickerContent>

        <ButtonContent>
          <DownloadButton
            variant="contained"
            isLoading={loadingDownload}
            onClick={handleDownload}
          >
            Download excel
          </DownloadButton>
        </ButtonContent>
      </SearchContent>

      <Box style={{ paddingInline: "1rem" }}>
        {loading && <LinearProgress />}
      </Box>
      <TableVirtuoso
        style={{ height: 500 }}
        data={filteredData}
        components={{
          ...TableComponents,
          TableHead: TableHeader
        }}
        fixedHeaderContent={() => <TableHeader />}
        itemContent={(_, batch) => {
          const batchText = batch.classification
            ? `${batch.id}-${batch.classification}`
            : batch.id;
          return (
            <>
              <BodyCell width={"15rem"}>
                <StyledLink
                  to={`lotes/${batch.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {batchText}
                </StyledLink>
              </BodyCell>
              <BodyCell style={{ minWidth: "20rem" }}>
                {batch.productBaseName}
              </BodyCell>
              <BodyCell>{`${batch.receiptDate} ${batch.receiptTime}`}</BodyCell>
              <BodyCell align="center">{batch.productBaseShelfLife}</BodyCell>
              <BodyCell>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem"
                  }}
                >
                  {batch.complaintReasons?.map(reason => (
                    <Box key={reason}>{reason}</Box>
                  ))}
                </Box>
              </BodyCell>
            </>
          );
        }}
      />
    </StyledDialog>
  );
};
