import { makeStyles, createStyles, alpha } from "@material-ui/core";

export const useDataGridStyles = (color = undefined) => {
  return makeStyles(theme =>
    createStyles({
      root: {
        minHeight: 400,
        width: "100%",
        height: 500,
        maxWidth: "1800px",
        margin: "30px auto",
        border: 0,
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        borderRadius: "10px",
        color: theme.palette.mode === "light" ? "#fff" : "#fff",
        fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        "& .MuiDataGrid-columnHeader": {
          borderRight: "1px solid #fff"
        },
        "& ": {
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "10px",
            background: "transparent"
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
          },
          "&::-webkit-scrollbar-thumb": {
            background: alpha(theme.palette.grey[600], 0.3),
            borderRadius: "9px"
          }
        },
        "& .MuiDataGrid-columnsContainer": {
          background: alpha(theme.palette.primary.main, 0.1),
          borderRadius: "10px 10px 0px 0px"
        },
        "& .MuiDataGrid-columnsContainer .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
          color: "#757575"
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none"
        },
        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
          borderBottom: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#baf1a9"
          }`
        },
        "& .MuiDataGrid-row:hover": {
          "&:hover": {
            backgroundColor: color ? `${color}47` : "#e0f7b1"
          }
        },
        "& .MuiDataGrid-cell": {
          color: theme.palette.mode === "light" ? "#fff" : "rgba(0,0,0,.85)",
          fontWeight: 500,
          "& + .MuiDataGrid-cell": {
            borderLeft: "1px solid #cccccc"
          }
        },
        "& .MuiPaginationItem-root": {
          borderRadius: 0
        },
        "& .MuiDataGrid-columnHeader--alignCenter .MuiSvgIcon-root": {
          color: "#225249"
        }
      }
    })
  )();
};
