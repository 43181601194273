import { Box, Paper, Typography } from "@material-ui/core";
import { Button, ContainerPage, TitlePage } from "components";
import { ButtonLink } from "components/ButtonLink";
import { usePopup } from "hooks/usePopup";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { BsKanban } from "react-icons/bs";
import batchService from "services/batchService";
import batchServiceV3 from "services/batchServiceV3";
import reporterGenerateService from "services/reporterGenerateService";
import stockServiceV2 from "services/stockServicesV2";
import { useDebaunce } from "utils";

import { DashBatchTable } from "./components/DashBatchTable";
import { DashCards } from "./components/DashCards";
import { HorDashCards } from "./components/DashCards/HorCards";
import { VertDashCards } from "./components/DashCards/VertCards";
import { StorageTable } from "./components/StorageTable";
import {
  ButtonReportContent,
  Content,
  CustomTableContainer,
  MainContent,
  SearchContent,
  SearchInput,
  SectionTitle,
  useStyles
} from "./styles";
import { formatData } from "./utils/formatData";

export const Dashboard = () => {
  const { addPopup } = usePopup();
  const [loadingStock, setLoadingStock] = useState(false);
  const [loadingBatch, setLoadingBatch] = useState(false);
  const [storageOutOfStock, setStorageOutOfStock] = useState([]);
  const [batchData, setBatchData] = useState({});
  const [batchSellerData, setBatchSellerData] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);
  const classes = useStyles();

  const debaunceBatchSearch = useDebaunce({
    fn: handleBatchSearch,
    delay: 800
  });

  const debaunceStorageSearch = useDebaunce({
    fn: handleStorageSearch,
    delay: 800
  });

  const getStorageData = async search => {
    try {
      setLoadingStock(true);
      const params = {
        outOfStockOnly: true,
        search
      };
      const { data } = await stockServiceV2.getVirtualResumeStock(params);
      setStorageOutOfStock(formatData.formatStorageData(data.results));
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar produtos",
        description: errorMessage
      });
    } finally {
      setLoadingStock(false);
    }
  };

  const getBatchData = async search => {
    try {
      setLoadingBatch(true);
      setBatchData([]);
      const params = {
        search
      };
      const { data } = await batchServiceV3.getBatchDash(params);

      setBatchData(formatData.formatBatchData(data));
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar lotes",
        description: errorMessage
      });
    } finally {
      setLoadingBatch(false);
    }
  };

  function handleStorageSearch(e) {
    const { value } = e.target;
    getStorageData(value?.trim());
  }

  function handleBatchSearch(e) {
    const { value } = e.target;
    getBatchData(value?.trim());
  }

  const handleDownloadOutOfStock = async () => {
    try {
      setReportLoading(true);
      const dataToReport = storageOutOfStock.map(storage => {
        return {
          id: storage.product_base_id,
          name: storage.product_base_name,
          quantity: storage.quantity
        };
      });
      const { data } = await reporterGenerateService.excelGenerate(
        dataToReport
      );

      const currentDate = new Date();
      const formattedDate = currentDate
        .toLocaleString()
        .replace(/[:.,\s]/g, "_");
      const fileName = `fora_de_estoque_${formattedDate}.xlsx`;

      fileDownload(data, fileName);
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          description: "Erro ao fazer download"
        });
      }
    } finally {
      setReportLoading(false);
    }
  };

  useEffect(() => {
    getStorageData();
    getBatchData();
  }, []);

  return (
    <ContainerPage>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        marginBottom="0.5rem"
      >
        <TitlePage>Dashboard</TitlePage>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          style={{ gap: "1rem " }}
        >
          <ButtonLink className={classes.button} to="/app/dashboard/kanban">
            <BsKanban size={24} />
            Acessar quadro
          </ButtonLink>
        </Box>
      </Box>
      <MainContent>
        <HorDashCards
          batchData={batchData}
          storageData={storageOutOfStock}
          batchLoading={loadingBatch}
          stockLoading={loadingStock}
        />
        <VertDashCards />
        <Content gridArea="storage">
          <SectionTitle>Produtos fora de estoque</SectionTitle>
          <CustomTableContainer component={Paper}>
            <SearchContent>
              <SearchInput
                placeholder="ID ou produto"
                onChange={debaunceStorageSearch}
              />
              <ButtonReportContent>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleDownloadOutOfStock}
                  isLoading={reportLoading}
                  disabled={!storageOutOfStock?.length}
                >
                  Baixar Relatório
                </Button>
              </ButtonReportContent>
            </SearchContent>

            <StorageTable
              loading={loadingStock}
              storageOutOfStock={storageOutOfStock}
            />
          </CustomTableContainer>
        </Content>
        <Content gridArea="batches">
          <SectionTitle>Produtos perto do vencimento</SectionTitle>
          <CustomTableContainer component={Paper}>
            <SearchContent>
              <SearchInput
                placeholder="Lote, seller ou produto"
                onChange={debaunceBatchSearch}
              />
            </SearchContent>

            <DashBatchTable
              reload={getBatchData}
              loading={loadingBatch}
              batchData={batchData.batches}
            />
          </CustomTableContainer>
        </Content>
      </MainContent>
    </ContainerPage>
  );
};
