import { Redirect, Route as ReactRoute } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

const Route = ({
  isPrivate = false,
  component: Component,
  permissionsRoute,
  ...rest
}) => {
  const { token, permissions } = useAuth();

  const isPermission =
    permissionsRoute.length === 0
      ? true
      : permissionsRoute.some(permission => permissions.includes(permission));

  return (
    <ReactRoute
      {...rest}
      render={({ location }) => {
        if (isPrivate === !!token && isPermission) {
          return <Component />;
        } else if (isPrivate === !!token && isPermission === false) {
          return (
            <Redirect
              to={{
                pathname: "/app",
                state: { from: location }
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: isPrivate ? "/login" : "/app",
                state: { from: location }
              }}
            />
          );
        }
      }}
    />
  );
};

export default Route;
