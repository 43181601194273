import StarIcon from "@material-ui/icons/Star";
import { CustomLineProgress } from "components/LineProgress";

import {
  MetricDescription,
  MetricValue,
  MetricsContainer,
  PositionContent,
  PositionIcon,
  RankingContainer,
  RankingContent,
  SellerContent
} from "../../style";
export const RankingCard = ({ rankingData, loading }) => {
  return (
    <RankingContainer>
      {!!loading && <CustomLineProgress />}
      {rankingData?.map((ranking, index) => {
        const position = index + 1;
        return (
          <RankingContent elevation={1} key={ranking.seller?.id}>
            <PositionContent>
              <PositionIcon position={position}>
                <StarIcon />
              </PositionIcon>
              {position}º
            </PositionContent>
            <SellerContent>{ranking.seller?.name}</SellerContent>
            <MetricsContainer>
              <MetricDescription>Qtd. Lotes:</MetricDescription>
              <MetricValue>{ranking.batchQuantity}</MetricValue>
            </MetricsContainer>
            <MetricsContainer>
              <MetricDescription>Blitz:</MetricDescription>
              <MetricValue>{ranking.blitzPercentage}%</MetricValue>
            </MetricsContainer>
            <MetricsContainer>
              <MetricDescription>Recusado:</MetricDescription>
              <MetricValue>{ranking.batchRefusedPercentage}%</MetricValue>
            </MetricsContainer>
            <MetricsContainer>
              <MetricDescription>Não entregue:</MetricDescription>
              <MetricValue>{ranking.batchUndeliveredPercentage}%</MetricValue>
            </MetricsContainer>
            <MetricsContainer>
              <MetricDescription>Class. B:</MetricDescription>
              <MetricValue>{ranking.classificationBPercentage}%</MetricValue>
            </MetricsContainer>
            <MetricsContainer>
              <MetricDescription>Class. C:</MetricDescription>
              <MetricValue>{ranking.classificationCPercentage}%</MetricValue>
            </MetricsContainer>
            <MetricsContainer>
              <MetricDescription>Pontuação:</MetricDescription>
              <MetricValue>{ranking.badPoints}</MetricValue>
            </MetricsContainer>
          </RankingContent>
        );
      })}
    </RankingContainer>
  );
};
