import { Box } from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { ContainerPage, Button, PermissionGate, TitlePage } from "components";
import { OptionsMenu } from "components/OptionsMenu";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import fileDownload from "js-file-download";
import React, { useEffect, useState, useMemo } from "react";
import { BiTransferAlt } from "react-icons/bi";
import { MdModeEditOutline } from "react-icons/md";
import stockService from "services/stockService";
import storageFisicService from "services/storageFisicService";
import storageService from "services/storageService";
import { Table } from "shared";
import { mapType } from "utils";

import { DialogEdit } from "./components/DialogEdit";
import { DialogMigration } from "./components/DialogMigration";

const filterReason = [14, 15];

export default function SummaryFisic() {
  const { data, onChange, params, refresh, status, handleSearch } =
    useTableParams({
      service: params => stockService.getAllStock(params)
    });

  const { addPopup } = usePopup();
  const [reasons, setReasons] = useState([]);
  const [dialogEdit, setDialogEdit] = useState({
    isOpen: false,
    data: {}
  });
  const [dialogMigration, setDialogMigration] = useState({
    isOpen: false,
    data: {}
  });

  const options = [
    {
      title: "Migrar estoque",
      permissions: ["Estoque", "Estoque físico", "Tech"],
      icon: BiTransferAlt,
      action: data => {
        setDialogMigration({ isOpen: true, data });
      }
    },
    {
      title: "Editar",
      permissions: ["Estoque", "Estoque físico", "Tech"],
      icon: MdModeEditOutline,
      action: data => setDialogEdit({ isOpen: true, data })
    }
  ];

  const columns = [
    {
      field: "product_base_name",
      label: "Produto Base",
      style: { minWidth: "170px", maxWidth: "300px" }
    },
    {
      field: "product_base_id",
      label: "ID do Produto",
      align: "center",
      style: { minWidth: "140px" }
    },
    {
      field: "input_type_id",
      label: "Tipo de entrada",
      align: "center",
      style: { minWidth: "200px" },
      formatter: value => mapType(value)
    },
    {
      field: "physical",
      label: "Estoque",
      align: "center",
      style: { minWidth: "200px" },
      formatter: value => {
        if (!value) return 0;
        return new Intl.NumberFormat("pt-BR").format(value.toFixed(2));
      },
      cellStyle: data => {
        if (data.stock < 0) {
          return {
            color: "red"
          };
        }
      }
    },
    {
      field: "options",
      label: "",
      disabledSort: true,
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ];

  const handleDownloadSummaryFisic = async () => {
    try {
      const { data } = await storageFisicService.getSummaryFisicDownload();
      fileDownload(data, "Dados_do_estoque_fisico.xls");
    } catch (err) {
      if (err?.response?.status !== 401) {
        addPopup({
          type: "error",
          description: "Erro ao fazer download"
        });
      }
    }
  };

  const getReasons = async () => {
    try {
      const { data } = await storageService.getReason();
      setReasons(data.filter(({ id }) => !filterReason.includes(id)));
    } catch (error) {
      if (error?.response?.status !== 401) {
        addPopup({
          type: "error",
          title: "Desculpe, ocorreu um erro",
          description: "Erro ao buscar motivos."
        });
      }
    }
  };

  const dataRows = useMemo(
    () => data.map(item => ({ ...item, id: item?.product_base_id })),
    [data]
  );

  useEffect(() => {
    getReasons();
  }, []);

  return (
    <>
      <ContainerPage>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <TitlePage>Estoque Físico</TitlePage>

          <Button
            startIcon={<CloudDownload />}
            variant="contained"
            onClick={() => handleDownloadSummaryFisic()}
          >
            Download Estoque
          </Button>
        </Box>

        <Table
          handleSearch={handleSearch}
          columns={columns}
          data={dataRows}
          onChange={onChange}
          params={params}
          size="small"
          status={status}
          refresh={refresh}
          count={dataRows.length}
          hover
          paginationServer={false}
        />
      </ContainerPage>

      <DialogEdit
        isOpen={dialogEdit.isOpen}
        dataProduct={dialogEdit.data}
        refresh={refresh}
        setIsOpen={setDialogEdit}
        reasons={reasons}
      />

      <DialogMigration
        isOpen={dialogMigration.isOpen}
        dataProduct={dialogMigration.data}
        setIsOpen={setDialogMigration}
        refresh={refresh}
      />
    </>
  );
}
