import {
  Avatar,
  Button,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  styled,
  alpha
} from "@material-ui/core";
import { useAuth } from "hooks/useAuth";
import { usePopup } from "hooks/usePopup";
import { useRef, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { MenuPopover } from "shared";

const ButtonStyle = styled(Button)(({ theme }) => ({
  borderRadius: "3rem"
}));

const NameUser = styled(Typography)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingLeft: "0.5rem",
  lineHeight: "1.1rem",
  color: "#757575",
  "& strong": {
    color: "#353535"
  }
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  background: theme.palette.primary.main
}));

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
  minWidth: "12rem",
  "&:hover": {
    background: alpha(theme.palette.primary.main, 0.1)
  }
}));

export const UserNav = ({ style }) => {
  const history = useHistory();
  const userRef = useRef(null);
  const { signOut, user } = useAuth();
  const { addPopup } = usePopup();
  const [popover, setPopover] = useState(false);

  const logOut = () => {
    signOut();
    addPopup({
      type: "success",
      title: "Deslogado com sucesso"
    });

    history.push("/");
  };

  const togglePopover = () => setPopover(state => !state);

  return (
    <>
      <ButtonStyle onClick={togglePopover} style={style}>
        <AvatarStyle ref={userRef}>
          <FaUserAlt />
        </AvatarStyle>
        <NameUser>
          Olá, <br />
          <strong>{user}</strong>
        </NameUser>
      </ButtonStyle>

      <MenuPopover
        open={popover}
        onClose={togglePopover}
        anchorEl={userRef.current}
        style={{ marginTop: "1rem" }}
      >
        <MenuItemStyle onClick={logOut}>
          <ListItemIcon>
            <MdLogout size={22} />
          </ListItemIcon>
          <ListItemText>Sair da conta</ListItemText>
        </MenuItemStyle>
      </MenuPopover>
    </>
  );
};
