import { Box, Paper, Typography, styled } from "@material-ui/core";

export const RevertWasteContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
});

export const RevertWasteContent = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "1rem"
});

export const CardInfoContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: "0.5rem"
});

export const CardInfoContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1
});

export const CardTitle = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "0.875rem"
});

export const CardValue = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.875rem",
  color: "#757575"
});
