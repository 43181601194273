import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { Alert, Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import batchServiceV3 from "services/batchServiceV3";

import { ButtonsContent, StyledBox, Text } from "../../style";
import { ClassificationContainer } from "./style";

export const InitialPage = ({
  subBatch,
  handleChangeSubBatch,
  unclassifiedQuantity,
  batchId,
  handleClose
}) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClasse, setSelectedClasse] = useState(null);

  const handleSelectClasse = e => {
    setSelectedClasse(e.target.value);
    handleChangeSubBatch(e);
  };

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await batchServiceV3.batchReceipt(batchId, {
        blitz_step: 4,
        sub_batch: subBatch
      });
      handleClose();
    } catch (err) {
      addPopup({ type: "error", title: "Erro ao registrar classificação" });
    } finally {
      setIsLoading(false);
    }
  };

  const validate = () => {
    const isValidReceipt = !unclassifiedQuantity;

    if (!isValidReceipt) {
      return addPopup({
        type: "error",
        title: "Informações obrigatórias",
        description: "Classifique todas a quantidade e insira as imagens"
      });
    }
    handleConfirm();
  };

  return (
    <>
      <StyledBox>
        <Alert isSuccess title="Peso aprovado" />
        <Text>A maior parte do lote está em qual classificação?</Text>
        <ClassificationContainer>
          <FormControl component="fieldset">
            <RadioGroup value={selectedClasse} onChange={handleSelectClasse}>
              <FormControlLabel
                value="A"
                control={<Radio />}
                label="Classe A"
                style={{ backgroundColor: "#F4F4F4", borderRadius: "0.5rem" }}
              />

              <FormControlLabel
                value="B"
                control={<Radio />}
                label="Classe B"
                style={{ backgroundColor: "#F4F4F4", borderRadius: "0.5rem" }}
              />
              <FormControlLabel
                value="C"
                control={<Radio />}
                label="Classe C"
                style={{ backgroundColor: "#F4F4F4", borderRadius: "0.5rem" }}
              />
            </RadioGroup>
          </FormControl>
        </ClassificationContainer>
      </StyledBox>
      <ButtonsContent>
        <Box display="flex" justifyContent="flex-end">
          <Button
            style={{ width: "50%", marginLeft: "auto" }}
            variant="contained"
            onClick={validate}
            isLoading={isLoading}
          >
            Concluir
          </Button>
        </Box>
      </ButtonsContent>
    </>
  );
};
