import { useMediaQuery } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import { TitlePage, ContainerPage, OptionsMenu } from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import { useMemo, useRef } from "react";
import { BiAnalyse } from "react-icons/bi";
import { MdSearch, MdCategory } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import depotBlueberryService from "services/depotBlueberryService";
import { Table } from "shared";
import { useDebaunce } from "utils";

import { BatchesCard } from "./components/BatchesCard";
import { ConvertBatchDialog } from "./components/ConvertBatchDialog";
import { MoveBatchDialog } from "./components/MoveBatchDialog";
import { MoveBatchReasonDialog } from "./components/MoveBatchReasonDialog";
import { batchesFormatter } from "./utils/batchesFormatter";

export const StoredBatches = () => {
  const batchMoveRef = useRef(null);
  const batchConvertRef = useRef(null);
  const batchMoveReasonRef = useRef(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const history = useHistory();
  const batchSearch = new URLSearchParams(location.search).get("batchSearch");

  const { addPopup } = usePopup();
  const {
    data,
    params,
    count,
    onChange,
    refresh,
    status,
    handleSearch,
    errorMessage,
    totalPages
  } = useTableParams({
    service: params => depotBlueberryService.getDepotBatches(params),
    fetchOnlyOnSearch: true,
    paramsDefault: {
      search: batchSearch
    }
  });

  const debaunceBatchSearch = useDebaunce({
    fn: handleSearch,
    delay: 800
  });

  const handlePagination = e => {
    const page = e.selected + 1;
    onChange({ page });
  };

  const options = [
    {
      title: "Converter",
      icon: BiAnalyse,
      permissions: ["Admin", "Tech", "Estoque"],
      action: data => batchConvertRef.current?.handleOpen(data)
    },
    {
      title: "Movimentar",
      icon: SyncAltIcon,
      permissions: ["Admin", "Tech", "Estoque"],
      action: data => batchMoveRef.current?.handleOpen(data)
    },
    {
      title: "Histórico",
      icon: WatchLaterIcon,
      permissions: ["Admin", "Tech", "Estoque"],
      action: data => {
        const searchParam = `search=${params.search}`;
        const toPath = `/app/lotes-armazenados/${data.packagingType.id}/${data.batchId}?${searchParam}`;
        history.push(toPath);
      }
    },
    {
      title: "Classificar",
      icon: MdCategory,
      permissions: ["Admin", "Tech", "Estoque"],
      action: data => batchMoveReasonRef.current?.handleOpen(data)
    }
  ];

  const columns = useMemo(() => [
    {
      field: "batchId",
      label: "Lote",
      align: "center",
      sorted: false,
      disabledSort: true
    },
    {
      field: "receiptDateTime",
      label: "Recebido em",
      disabledSort: true
    },
    {
      field: "firstStoredDateTime",
      label: "Armazenado em",
      disabledSort: true
    },
    {
      field: "productBaseName",
      label: "Produto",
      align: "left",
      disabledSort: true
    },
    {
      field: "shelfLife",
      label: "Shelf Life",
      align: "center",
      disabledSort: true
    },
    {
      field: "classification",
      label: "Classificação",
      align: "center",
      disabledSort: true
    },
    {
      field: "refusalPercentage",
      label: "% abaixo da qualidade",
      align: "center",
      disabledSort: true,
      formatter: value => (value != null ? (value * 100).toFixed(0) + "%" : "")
    },
    {
      field: "warehouseTypeName",
      label: "Armazém",
      align: "left",
      disabledSort: true
    },
    {
      field: "depotName",
      label: "Depósito",
      align: "left",
      disabledSort: true
    },
    {
      field: "quantity",
      label: "Quantidade",
      align: "center",
      disabledSort: true
    },
    {
      field: "packaging",
      label: "Embalagem",
      align: "center",
      disabledSort: true
    },
    {
      field: "options",
      label: "",
      align: "right",
      disabled: true,
      renderCell: ({ data }) => <OptionsMenu data={data} options={options} />
    }
  ]);

  const handleDataError = () => {
    addPopup({
      type: "error",
      title: "Houve um erro ao buscar os dados dos lotes",
      description: errorMessage
    });
  };

  const formattedData = useMemo(() => {
    if (status === "pending") return [];
    if (status === "error") handleDataError();
    return batchesFormatter(data.filter(item => item.quantity !== 0));
  }, [data, status]);

  return (
    <>
      <ContainerPage>
        <TitlePage>Lotes armazenados</TitlePage>
        {isSmallScreen ? (
          <BatchesCard
            batches={formattedData}
            onClickMove={batch => batchMoveRef.current?.handleOpen(batch)}
            onClickConvert={batch => batchConvertRef.current?.handleOpen(batch)}
            onClickHistory={batch =>
              history.push(
                `/app/lotes-armazenados/${batch.packagingType.id}/${batch.batchId}`
              )
            }
            onClickReason={batch =>
              batchMoveReasonRef.current?.handleOpen(batch)
            }
            handlePagination={handlePagination}
            totalPages={totalPages}
            status={status}
            params={params}
            debaunceBatchSearch={debaunceBatchSearch}
          />
        ) : (
          <Table
            placeholder="Produto base ou lote"
            handleSearch={handleSearch}
            columns={columns}
            data={formattedData}
            onChange={onChange}
            params={params}
            status={status}
            refresh={refresh}
            count={count}
            noDataCustomTitle="Pesquise um produto base ou lote"
            noDataCustomIcon={MdSearch}
          />
        )}

        <MoveBatchDialog ref={batchMoveRef} refresh={refresh} />
        <ConvertBatchDialog ref={batchConvertRef} refresh={refresh} />
        <MoveBatchReasonDialog ref={batchMoveReasonRef} refresh={refresh} />
      </ContainerPage>
    </>
  );
};
