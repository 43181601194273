import { CardHeader } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { BsBoxSeam } from "react-icons/bs";
import { FaWpforms } from "react-icons/fa";

import {
  CardInfoContent,
  CardInfoDescription,
  CardInfoValue,
  DashCardContainer,
  DashCardContent,
  IconCardContainer,
  IconCardContent,
  SellerNameContent,
  StyledCard,
  StyledCardContent
} from "../../style";

export const CardDash = ({ dashBoardData }) => {
  const {
    seller,
    batchQuantity,
    batchProductQuantity,
    batchRefused,
    batchRefusedPercentage,
    batchUndelivered,
    batchUndeliveredPercentage,
    batchReturned,
    batchReturnedPercentage,
    totalSample,
    totalLowSample,
    blitzPercentage,
    batchWaste,
    batchWastePercentage
  } = dashBoardData;
  return (
    <DashCardContainer>
      <SellerNameContent>
        Seller: <span>{seller?.name}</span>
      </SellerNameContent>
      <DashCardContent>
        <StyledCard elevation={3}>
          <IconCardContainer>
            <IconCardContent>
              <BsBoxSeam />
            </IconCardContent>
          </IconCardContainer>
          <CardHeader title={"Lotes"}></CardHeader>
          <StyledCardContent>
            <CardInfoContent>
              <CardInfoDescription>Total:</CardInfoDescription>
              <CardInfoValue>{batchQuantity}</CardInfoValue>
            </CardInfoContent>

            <CardInfoContent>
              <CardInfoDescription>Recusados:</CardInfoDescription>
              <CardInfoValue>
                {`${batchRefused} (${batchRefusedPercentage}%)`}
              </CardInfoValue>
            </CardInfoContent>

            <CardInfoContent>
              <CardInfoDescription>Não entregues:</CardInfoDescription>
              <CardInfoValue>
                {`${batchUndelivered} (${batchUndeliveredPercentage}%)`}
              </CardInfoValue>
            </CardInfoContent>

            <CardInfoContent>
              <CardInfoDescription>Devolvidos:</CardInfoDescription>
              <CardInfoValue>
                {`${batchReturned} (${batchReturnedPercentage}%)`}
              </CardInfoValue>
            </CardInfoContent>
          </StyledCardContent>
        </StyledCard>

        <StyledCard elevation={3}>
          <IconCardContainer>
            <IconCardContent>
              <FaWpforms />
            </IconCardContent>
          </IconCardContainer>
          <CardHeader title={"Blitz"}></CardHeader>
          <StyledCardContent>
            <CardInfoContent>
              <CardInfoDescription>Total amostra:</CardInfoDescription>
              <CardInfoValue>{totalSample}</CardInfoValue>
            </CardInfoContent>

            <CardInfoContent>
              <CardInfoDescription>
                Total abaixo da qualidade:
              </CardInfoDescription>
              <CardInfoValue>{totalLowSample}</CardInfoValue>
            </CardInfoContent>

            <CardInfoContent>
              <CardInfoDescription>
                Porcentagem média da blitz:
              </CardInfoDescription>
              <CardInfoValue>{blitzPercentage}%</CardInfoValue>
            </CardInfoContent>
          </StyledCardContent>
        </StyledCard>

        <StyledCard elevation={3}>
          <IconCardContainer>
            <IconCardContent>
              <DeleteForeverIcon />
            </IconCardContent>
          </IconCardContainer>
          <CardHeader title={"Waste"}></CardHeader>
          <StyledCardContent>
            <CardInfoContent>
              <CardInfoDescription>Total de produtos:</CardInfoDescription>
              <CardInfoValue>{batchProductQuantity}</CardInfoValue>
            </CardInfoContent>

            <CardInfoContent>
              <CardInfoDescription>Total de waste:</CardInfoDescription>
              <CardInfoValue>{batchWaste}</CardInfoValue>
            </CardInfoContent>

            <CardInfoContent>
              <CardInfoDescription>Porcentagem waste:</CardInfoDescription>
              <CardInfoValue>{batchWastePercentage}%</CardInfoValue>
            </CardInfoContent>
          </StyledCardContent>
        </StyledCard>
      </DashCardContent>
    </DashCardContainer>
  );
};
