import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  styled
} from "@material-ui/core";
import { Search } from "shared/Search";

export const MainContent = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: `
    'cards side'
    'storage side'
    'batches side'
  `,
  gridTemplateColumns: "80% 20%",
  [theme.breakpoints.down("sm")]: {
    display: "block"
  }
}));

export const SectionTitle = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "1.125rem",
  color: "#353535",
  fontWeight: 500,
  wordWrap: "break-word"
});

export const Content = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "2rem 4rem 2rem 4rem",
  gap: "1rem",
  [theme.breakpoints.down("sm")]: {
    padding: "2rem 0 2rem 0"
  }
}));

export const CustomTableContainer = styled(TableContainer)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "1rem"
});

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "1rem",
  gap: "1rem",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "start"
  }
}));

export const ButtonReportContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}));

export const SearchInput = styled(Search)(({ theme }) => ({
  width: "100%",
  maxWidth: "21.5rem",
  height: "3.5rem",
  [theme.breakpoints.between(0, 700)]: {
    marginLeft: 0
  }
}));

export const TableContent = styled(TableContainer)({
  display: "flex",
  flexDirection: "column",
  height: "24rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "10px",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "19px"
  }
});

export const StyledTableHead = styled(TableHead)({
  background: "#F3F8E6"
});

export const HeadCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem",
  background: "#F3F8E6"
});

export const BodyRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const BodyCell = styled(TableCell)({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1rem",
  padding: "1.25rem 2.5rem"
});

export const BatchTableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  background: "#fafafa",
  margin: "1rem",
  borderRadius: "0.5rem",
  padding: "1rem",
  minHeight: "24rem",
  maxHeight: "24rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "10px",
    background: "transparent"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#A1CA4F",
    borderRadius: "19px"
  }
});

export const BatchTableContent = styled(Box)({
  display: "grid",
  gridTemplateColumns:
    "minmax(7rem, 10rem) 1fr minmax(14rem, 1fr) 1fr minmax(7rem, 1fr) minmax(7rem, 1fr)",
  rowGap: "1rem",
  padding: "1rem"
});

export const BatchTitle = styled(Typography)({
  display: "flex",
  fontFamily: "Roboto",
  fontSize: "0.875rem",
  fontWeight: 500,
  wordWrap: "break-word",
  color: "#757575",
  padding: "1rem",
  justifyContent: "center"
});

export const BatchDueInfo = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export const BatchInfo = styled(Box)({
  display: "flex",
  background: "#FFF",
  alignItems: "center",
  padding: "1rem",
  color: "#353535",
  fontSize: "1rem",
  fontFamily: "Roboto",
  fontWeight: 400,
  wordWrap: "break-word",
  justifyContent: "center"
});

export const useStyles = makeStyles({
  button: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    borderRadius: "0.75rem"
  }
});
