import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

export const SellerOrderStatus = Object.freeze({
  PENDING: 1,
  CONFIRMED: 2,
  FINISHED: 3,
  CANCELED: 4
});
class SellerOrderService {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async postSellerOrder(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/order`;
    return await api.post(url, data);
  }

  async updateSellerOrder(data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/order/${data.id}`;
    return await api.patch(url, data);
  }

  async deleteSellerOrderPackaging(ids) {
    const url = `${
      this.url
    }/api/blueberry/v1/warehouse/seller-order/packaging?${ids
      .map(id => `ids=${id}`)
      .join("&")}`;
    return await api.delete(url);
  }

  async deleteSellerOrderItems(ids) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller-order/item?${ids
      .map(id => `ids=${id}`)
      .join("&")}`;
    return await api.delete(url);
  }

  async cancelSellerOrder(id) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/order/${id}`;
    return await api.delete(url);
  }

  async getSellerOrder(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller/order`;
    return await api.get(url, { params });
  }

  async exportSellerOrderSheet(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/seller-order/file`;
    return await api.get(url, { responseType: "arraybuffer", params });
  }
}

const sellerOrderService = new SellerOrderService();
export default sellerOrderService;
