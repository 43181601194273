import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: "20rem",
    padding: "1.5rem 1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
  }
});
