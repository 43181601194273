import * as Yup from "yup";

const validationDefault = {
  source_product_base: Yup.object()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório"),
  destination_product_base: Yup.object()
    .typeError("Campo obrigatório")
    .required("Campo obrigatório")
};

export const validationSchemaEdit = Yup.object().shape(validationDefault);

export const validationSchemaCreate = Yup.object().shape(validationDefault);
