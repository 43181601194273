import { Box, TextField } from "@material-ui/core";

export const ReceiptDateFilter = ({ handleFilterDate }) => {
  return (
    <Box>
      <TextField
        variant="outlined"
        id="date"
        label="Data recebimento"
        type="date"
        InputLabelProps={{
          shrink: true
        }}
        onChange={handleFilterDate}
      />
    </Box>
  );
};
