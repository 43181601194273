import { Box, TableHead, TableRow, styled } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const SearchContainer = styled(Box)(({ theme }) => ({
  marginBottom: "2rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "1rem",
    alignItems: "flex-start"
  }
}));

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}));

export const StyledTableHead = styled(TableHead)({
  background: "#F3F8E6"
});

export const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(even)": {
    background: "#F3F8E6"
  }
});

export const PaginateContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingBlock: "1rem"
});

export const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Inter",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: "#858585",
  marginBlock: "2.5rem",
  textDecoration: "none"
}));
