import { Box, Typography, styled } from "@material-ui/core";

export const ConfirmContent = styled(Box)(({ isInMarginDiff }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  background: !isInMarginDiff ? "#F5F8F3" : "rgba(235, 0, 27, 0.05)",
  border: `2px solid ${!isInMarginDiff ? "#B5C9A8" : "rgba(255, 0, 0, 0.25)"}`,
  fontSize: "1rem",
  fontWeight: 500,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "1rem",
  padding: "1rem",
  gap: "1rem"
}));

export const LabelConfirmQuality = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#757575"
});

export const LabelConfirmLowQuality = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#D84315"
});

export const LabelSampleQuantity = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 600,
  fontSize: "1.1rem"
});
