import { AccordionSummary, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import {
  AccordionContent,
  AccordionTitle,
  StyledAccordion,
  StyledAccordionDetails
} from "../../style";

export const DashAccordion = ({ title, icon, table }) => {
  return (
    <AccordionContent>
      <Paper elevation={2} style={{ overflow: "hidden" }}>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-content-${"sellerBatch.id"}`}
            id={`panel-header-${"sellerBatch.id"}`}
          >
            <AccordionTitle>
              {icon} {title}
            </AccordionTitle>
          </AccordionSummary>
          <StyledAccordionDetails>{table}</StyledAccordionDetails>
        </StyledAccordion>
      </Paper>
    </AccordionContent>
  );
};
