import { Box, CircularProgress } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useMemo, useRef, useState } from "react";
import purchaseForecastService from "services/purchaseForecastService";
import { Drawer } from "shared";

import { Form } from "../Form";
import { refusalInstructions } from "../utils/refusalInstructions";

const formatInitialValues = data => {
  const [yyyy, mm, dd] = data.to_date?.split("-") ?? [];
  const blitzPercentage = data.blitz_percentage
    ? (data.blitz_percentage * 100).toFixed(2)
    : data.blitz_percentage;
  return {
    productBase: data.product_base,
    seller: data.seller,
    prediction: data.prediction,
    combinedPrice: data.combined_price,
    toDate: new Date(yyyy, mm - 1, dd),
    blitzPercentage,
    refusalInstructions: data.refusal_instructions?.[0],
    deadlineForAnalysis: data.deadline_for_analysis || null
  };
};

export const Edit = ({
  isOpen,
  setIsOpen,
  refresh,
  data,
  handleConfirmForecast
}) => {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addPopup } = usePopup();

  const initialValues = useMemo(() => formatInitialValues(data), [data]);

  const handleClose = () => {
    setIsOpen({ isOpen: false, data: {} });
    formRef.current?.resetForm();
  };

  const onSubmit = async dataForm => {
    try {
      setIsLoading(true);
      await purchaseForecastService.updatePurchaseForecast(data.id, {
        prediction: dataForm.prediction,
        seller: dataForm.seller.id,
        product_base: dataForm.productBase.id,
        combined_price: dataForm.combinedPrice,
        blitz_percentage: dataForm.blitzPercentage || 0.01,
        refusal_instructions: refusalInstructions[dataForm.refusalInstructions],
        deadline_for_analysis: dataForm.deadlineForAnalysis
      });
      addPopup({ type: "success", title: "Previsão atualizada com sucesso" });
      refresh();
      handleClose();
      await handleConfirmForecast();
    } catch (err) {
      if (err.response.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao atualizar previsão",
          description: err.response?.data?.msg ?? ""
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      title="Editar previsão"
      open={isOpen}
      onClose={handleClose}
    >
      {isLoading ? (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Form ref={formRef} initialValues={initialValues} onSubmit={onSubmit} />
      )}
    </Drawer>
  );
};
