import { TextField, Grid, Box } from "@material-ui/core";
import { Button } from "components";
import { useFormik } from "formik";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import selectionGroupsService from "services/selectionGroupsService";
import AutoCompleteUserFormik from "shared/AutoCompleteUsers";
import * as Yup from "yup";

import { DropdownButton } from "../Dropdown";

export const validationSchema = Yup.object().shape({
  user: Yup.object().test("is_user_valid", "Usuário é obrigatório", value => {
    return !!value.id && !!value.username;
  }),
  name: Yup.string().required("Nome do grupo é obrigatório"),
  group: Yup.number().when("action", {
    is: action => action === "update",
    then: Yup.number().required("O grupo é obrigatório")
  })
});

export const Form = ({
  handleClosePopupCreateGroup,
  refresh,
  typePopup,
  groupData,
  action,
  handleClose
}) => {
  const { addPopup } = usePopup();
  const [user, setUser] = useState({ id: null, username: "" });
  const [groupId, setGroupId] = useState(null);

  const onSubmit = async data => {
    try {
      const formData = {
        name: data.name,
        responsibleName: data.user.username,
        responsibleId: data.user.id
      };

      if (action === "create") {
        await selectionGroupsService.createGroup(formData);
      } else {
        await selectionGroupsService.updateGroup(groupId, formData);
      }
      addPopup({
        type: "success",
        title: "Grupo atualizado com sucesso"
      });
      formik.resetForm();
      refresh();
      handleClose();
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Não foi possível atualizar o grupo",
        description: errorMessage
      });
    }
  };

  const formik = useFormik({
    initialValues: { user, action },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    validateOnChange: false
  });

  useEffect(() => {
    setUser(() => {
      const group = groupData.find(group => group.id === groupId);
      if (!group) return {};
      formik.setFieldValue("name", group.name);
      return { id: group?.responsibleId, username: group?.responsibleName };
    });
  }, [groupId]);

  useEffect(() => {
    formik.setFieldValue("user", user);
  }, [user]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate>
      <Grid container xs={12}>
        {typePopup === "update" && (
          <Box width="100%" style={{ marginBottom: "1rem" }}>
            <span
              style={{
                color: "#7f7f7f",
                display: "block",
                marginBottom: "1rem"
              }}
            >
              Selecione o grupo que deseja editar
            </span>
            <DropdownButton
              typePopup={typePopup}
              groupData={groupData}
              handleClosePopupCreateGroup={handleClosePopupCreateGroup}
              refresh={refresh}
              setGroupId={setGroupId}
              formik={formik}
              name="group"
            />
          </Box>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            label="Nome"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
            value={formik.values?.name}
            error={
              (formik.touched.name || formik.submitCount > 0) &&
              !!formik.errors.name
            }
            helperText={
              (formik.touched.name || formik.submitCount > 0) &&
              formik.errors.name
            }
            InputLabelProps={{
              shrink: !!formik.values.name || formik.values.name === ""
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "1rem" }}>
          <AutoCompleteUserFormik formik={formik} name="user" />
        </Grid>

        <Grid
          item
          container
          justifyContent="flex-end"
          style={{ marginTop: "1rem" }}
        >
          <Button
            variant="contained"
            type="submit"
            isLoading={formik.isSubmitting}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
