import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export const DropDownComponent = ({
  label,
  data,
  value,
  onChange,
  noneTitle
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        variant="outlined"
      >
        <MenuItem value="">
          <em>{noneTitle || "Selecione"}</em>
        </MenuItem>
        {data?.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
