import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  chipCheck: {
    border: "1px solid #88BC23",
    color: "#ffffff",
    background: "#88BC23",
    cursor: "pointer"
  },
  chipCheckCeasa: {
    border: "1px solid #a7b1eb",
    color: "#ffffff",
    background: "#a7b1eb",
    cursor: "pointer"
  }
});
