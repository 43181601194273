import {
  alpha,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled
} from "@material-ui/core";
import { PermissionGate } from "components";
import { useState, useCallback, memo, useMemo } from "react";
import { CgZeit } from "react-icons/cg";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { matchPath, NavLink as Link, useLocation } from "react-router-dom";

const listItemStyles = ({ theme, active }) => ({
  height: 55,
  marginBottom: "0.3rem",
  position: "relative",
  textTransform: "capitalize",
  color: active && theme.palette.secondary.main,
  background: active && alpha(theme.palette.primary.main, 0.2),
  "&::before": {
    content: '""',
    position: "absolute",
    left: "0",
    top: "0",
    background: theme.palette.secondary.main,
    height: "100%",
    width: active ? "4px" : "0"
  },
  "& svg": {
    color: active ? theme.palette.secondary.main : "#5e5d5d"
  }
});

const ListItemLinkStyle = styled(props => (
  <ListItem disableGutters component={Link} {...props} />
))(listItemStyles);

const ListItemStyle = styled(props => <ListItem disableGutters {...props} />)(
  listItemStyles
);

const ListItemSubStyle = styled(props => (
  <ListItem
    disableGutters
    component={Link}
    className={isActive => isActive && ".active"}
    {...props}
  />
))(({ theme }) => ({
  height: 50,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: "1rem",
  "&.active": {
    color: theme.palette.secondary.main,
    "& svg": {
      color: theme.palette.secondary.main
    }
  }
}));

const ListItemTextStyle = styled(ListItemText)({
  "& span": {
    width: "300px"
  }
});

const ListItemIconStyle = styled(ListItemIcon)({
  width: 60,
  height: 50,
  fontSize: 30,
  color: "#5e5d5d",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export const NavLink = ({ active, pathname, item }) => {
  const isActive = useMemo(() => {
    if (item?.children) {
      return item.children.some(itemSub => active(itemSub.path));
    }

    return active(item.path);
  }, [pathname]);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(state => !state);

  const { title, path, children, permissions, icon: Icon } = item;

  if (children) {
    return (
      <>
        <PermissionGate permissions={permissions}>
          <ListItemStyle
            onClick={handleOpen}
            active={isActive ? String(isActive) : undefined}
            button
          >
            <ListItemIconStyle>
              <Icon />
            </ListItemIconStyle>

            <ListItemTextStyle
              className="navItem"
              open={open}
              primary={title}
            />

            {isOpen ? (
              <MdKeyboardArrowUp size={40} />
            ) : (
              <MdKeyboardArrowDown size={40} />
            )}
          </ListItemStyle>

          <Collapse
            in={isOpen}
            unmountOnExit
            timeout="auto"
            className="collapse"
          >
            <List disablePadding>
              {children.map(itemSub => (
                <ListItemSubStyle key={itemSub.title} to={itemSub.path} button>
                  <ListItemIconStyle>
                    <CgZeit size={25} />
                  </ListItemIconStyle>
                  <ListItemText primary={itemSub.title} />
                </ListItemSubStyle>
              ))}
            </List>
          </Collapse>
        </PermissionGate>
      </>
    );
  }

  return (
    <PermissionGate permissions={permissions}>
      <ListItemLinkStyle
        to={path}
        active={isActive ? String(isActive) : undefined}
        button
      >
        <ListItemIconStyle>
          <Icon />
        </ListItemIconStyle>

        <ListItemTextStyle className="navItem" primary={title} />
      </ListItemLinkStyle>
    </PermissionGate>
  );
};

export const Component = ({ navConfig, isMobile, ...rest }) => {
  const { pathname } = useLocation();
  const match = useCallback(
    path => (path ? !!matchPath(pathname, { path }) : false),
    [pathname]
  );

  return (
    <Box {...rest}>
      <List>
        {navConfig.map(item => (
          <NavLink
            key={item.title}
            item={item}
            active={match}
            pathname={pathname}
          />
        ))}
      </List>
    </Box>
  );
};

export const NavSection = memo(Component);
