import {
  Dialog,
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress
} from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import { reasonMap } from "utils/";

import { useStyles } from "./styles";

export const DialogConfirmDelete = ({
  data,
  handleConfirm,
  open,
  handleClose,
  refresh
}) => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const handleClear = () => {
    handleClose({
      isOpen: false,
      data: {}
    });
  };

  const handleConfirmDialog = async () => {
    try {
      setIsLoading(true);
      await handleConfirm(data?.id);
      addPopup({
        type: "success",
        title: "Registro excluido com sucesso."
      });

      refresh();
      handleClear();
    } catch (error) {
      if (error.response.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao excluido registro."
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} className={classes.root}>
      <Paper className={classes.paper}>
        <Grid>
          <Grid item xs={12}>
            <Typography className={classes.title}>
              Deseja excluir esse registro?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.box}>
            <Typography>
              <strong>Produto base: </strong>
              {data?.productBaseName}
            </Typography>
            <Typography>
              <strong>Motivo: </strong>
              {reasonMap(data?.storageReasonName)}
            </Typography>
            <Typography>
              <strong>Movimentação: </strong>
              {data?.quantity}
            </Typography>
          </Grid>

          <Grid container item xs={12} className={classes.boxBtn}>
            <Grid item>
              <Button className={classes.buttonCancel} onClick={handleClear}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonConfirm}
                onClick={handleConfirmDialog}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} style={{ color: "#fff" }} />
                ) : (
                  "Excluir"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};
