import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: "650px"
    }
  },
  container: {
    width: "100%",
    padding: "1rem 1rem 2rem"
  },
  title: {
    textAlign: "center",
    margin: "1rem 0",
    fontSize: "clamp(1rem, 2.5vw, 1.4rem)",
    fontWeight: 600,
    color: "#88BC23"
  },
  closeBtn: {
    background: "#f1f1f5",
    position: "absolute",
    top: 8,
    right: 8,
    width: 30,
    height: 30
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px 25px"
  }
}));
