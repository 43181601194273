export const historyTitleMap = title => {
  return (
    {
      quantity: "Quantidade",
      stageName: "Estágio",
      destination: "Destino",
      source: "Origem"
    }[title] || title
  );
};
