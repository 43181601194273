import { Button } from "components";

import { storageReasonOptions } from "./storageReasonOptions";
import {
  ButtonsContent,
  ConfirmContent,
  LabelConfirmDescription,
  LabelConfirmValue,
  StyledBox
} from "./styles";

export const ConfirmPage = ({
  data,
  batchReasons,
  handleBack,
  handleSubmit,
  loading
}) => {
  const storageReason = storageReasonOptions.find(
    ({ id }) => id === data.storageReasonId
  );
  const batchReason = batchReasons.find(({ id }) => id === data.batchReasonId);

  return (
    <StyledBox>
      <ConfirmContent>
        <LabelConfirmDescription>Quantidade:</LabelConfirmDescription>
        <LabelConfirmValue>{data.quantity}</LabelConfirmValue>
      </ConfirmContent>
      <ConfirmContent>
        <LabelConfirmDescription>Tipo do waste:</LabelConfirmDescription>
        <LabelConfirmValue>{storageReason?.name}</LabelConfirmValue>
      </ConfirmContent>
      {storageReason?.id !== 28 && (
        <ConfirmContent>
          <LabelConfirmDescription>Tipo do waste:</LabelConfirmDescription>
          <LabelConfirmValue>{batchReason?.name}</LabelConfirmValue>
        </ConfirmContent>
      )}

      <ButtonsContent>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleBack}
          disabled={loading}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          isLoading={loading}
        >
          Confirmar
        </Button>
      </ButtonsContent>
    </StyledBox>
  );
};
