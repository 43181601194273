import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export const ClassificationFilter = ({
  selectedClassification,
  handleFilterClassification
}) => {
  return (
    <FormControl variant="outlined" style={{ minWidth: "10rem" }}>
      <InputLabel id="classification-label">Classificação</InputLabel>
      <Select
        labelId="classification-label"
        value={selectedClassification}
        onChange={handleFilterClassification}
        label="Classificação"
      >
        <MenuItem value={null}>
          <em>Todas</em>
        </MenuItem>
        <MenuItem value="A">A</MenuItem>
        <MenuItem value="B">B</MenuItem>
        <MenuItem value="C">C</MenuItem>
      </Select>
    </FormControl>
  );
};
