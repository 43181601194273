import { usePopup } from "hooks/usePopup";
import { useRef } from "react";
import migrationService from "services/migrationService";
import { Drawer } from "shared";

import { Form } from "../Form";

export const Create = ({ isOpen, onClose, refresh }) => {
  const formRef = useRef(null);
  const { addPopup } = usePopup();

  const handleClose = () => {
    onClose();
    formRef.current?.resetForm();
  };

  const onSubmit = async data => {
    try {
      const { destination_product_base, source_product_base } = data;
      await migrationService.createMigrationRules({
        source_product_base: source_product_base.id,
        destination_product_base: destination_product_base.id
      });
      addPopup({ type: "success", title: "Regra criada com sucesso!" });
      refresh();
      handleClose();
    } catch (err) {
      addPopup({ type: "error", title: "Erro ao criar regra" });
    }
  };

  return (
    <Drawer
      anchor="right"
      title="Nova regra de migração"
      open={isOpen}
      onClose={handleClose}
    >
      <Form ref={formRef} onSubmit={onSubmit} />
    </Drawer>
  );
};
