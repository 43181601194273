import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider
} from "@material-ui/core";
import { ptBR } from "@material-ui/core/locale";

import Overrides from "./overrides";
import palette from "./palette";
import shadows from "./shadows";

const ThemeProvider = ({ children }) => {
  const theme = createTheme(
    {
      palette,
      shadows
    },
    ptBR
  );
  theme.overrides = Overrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
