import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  successBox: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    width: "100%",
    height: "3rem",
    borderRadius: "16px",
    color: "#F5F7F5"
  },
  icon: {
    fontSize: "2rem",
    marginLeft: "1rem",
    marginRight: "1rem"
  },
  text: {
    fontSize: "1rem",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem"
    }
  }
}));
