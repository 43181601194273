import {
  Box,
  IconButton,
  InputAdornment,
  ListItemText,
  TextField,
  Typography
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ContainerPage, TitlePage } from "components";
import { ChipStatus } from "components/ChipStatus";
import { Paginate } from "components/Paginate";
import { usePopup } from "hooks/usePopup";
import { useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import batchConferenceService from "services/bachConferenceService";
import { MenuPopover } from "shared";
import { useDebaunce } from "utils";

import { childrenColumns, columns } from "./columns";
import { ModalUpdate } from "./components/ModalUpdate";
import { ReceiptConferenceTable } from "./components/ReceiptConferenceTable";
import { BoxPaginateContent, useStyles } from "./styles";

export const ReceiptConference = () => {
  const userRef = useRef(null);
  const toDayDate = new Date();
  const [search, setSearch] = useState("");
  const [searchDateFrom, setSearchDateFrom] = useState(
    new Date(toDayDate.setHours(0, 0, 0, 1))
  );
  const [searchDateTo, setSearchDateTo] = useState(() => {
    return new Date(toDayDate.setHours(23, 59, 59, 0));
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedStatusId, setSelectedStatusId] = useState(null);
  const [conferenceData, setConferenceData] = useState({});
  const [popover, setPopover] = useState(false);
  const [conferenceStatus, setConferenceStatus] = useState([]);
  const [batchConferenceData, setBatchConferenceData] = useState([]);

  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });
  const classes = useStyles();
  const { addPopup } = usePopup();

  function setPageToInitial() {
    page === 1 ? handleGetData() : setPage(1);
  }

  function handleSearch(event) {
    const { value } = event.target;

    setPageToInitial();
    setSearch(value);
  }

  const togglePopover = () => setPopover(state => !state);
  const toggleModal = () => setIsOpenModal(state => !state);

  const handleOpenModal = data => {
    toggleModal();
    setConferenceData(data);
  };

  const handleChangeDate = date => {
    const dateFrom = new Date(date.setHours(0, 0, 0, 1));
    const dateTo = new Date(date.setHours(23, 59, 59, 1));

    setPageToInitial();
    setSearchDateFrom(dateFrom);
    setSearchDateTo(dateTo);
  };

  const handleGetData = async () => {
    try {
      const { data } = await batchConferenceService.getBatchesConference({
        page,
        statusId: selectedStatusId,
        search,
        searchDateFrom,
        searchDateTo
      });

      setBatchConferenceData(data.results);
      setTotalPages(data.pageSize);
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao buscar dados"
      });
    }
  };

  const handleSelectSatus = event => {
    const value = event.currentTarget.getAttribute("value");

    setPageToInitial();
    setSelectedStatusId(value);
  };

  const getStatusData = async () => {
    try {
      const { data } = await batchConferenceService.getConferenceStatus();

      setConferenceStatus(data);
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao receber dados dos status."
      });
    }
  };

  const handleChangePage = page => {
    const anchor = document.querySelector("#back-to-top-anchor");
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth"
      });
    }
    setPage(page);
  };

  const formattedBatchConferenceData = useMemo(() => {
    return batchConferenceData.reduce((acc, batchConference) => {
      const batchInfos = batchConference.batch;
      const sellerId = batchConference.batch.seller.id;
      const seller = acc[sellerId] || {};
      const returnedQuantity = batchInfos.returned.reduce((acc, returned) => {
        return acc + returned?.quantity ?? 0;
      }, 0);
      const wasteQuantity = batchInfos.waste.reduce((acc, waste) => {
        return acc + waste?.quantity ?? 0;
      }, 0);
      const received = batchInfos.quantity + returnedQuantity + wasteQuantity;
      const receiptDate = new Date(
        batchInfos.receipt_date
      ).toLocaleDateString();
      const receiptTime = new Date(batchInfos.receipt_date).toLocaleTimeString(
        [],
        { hour: "2-digit", minute: "2-digit" }
      );

      acc[sellerId] = {
        conferences: [
          ...(seller.conferences?.length ? seller.conferences : []),
          {
            batchInfos: batchInfos,
            batchConferenceId: batchConference.id,
            quantityInvoice: batchConference.quantity_invoice,
            observation: batchConference.observation,
            invoice: batchConference.url_invoice,
            received,
            forecast: batchInfos.purchase_item?.quantity,
            returnedQuantity,
            wasteQuantity,
            receiptTime,
            status: batchConference.status
          }
        ],
        seller: batchInfos.seller,
        receiptDate
      };

      return acc;
    }, []);
  }, [batchConferenceData]);

  const statusName = useMemo(
    () =>
      conferenceStatus?.find(({ id }) => id === Number(selectedStatusId))?.name,
    [conferenceStatus, selectedStatusId]
  );

  useEffect(() => {
    getStatusData();
  }, []);

  useEffect(() => {
    handleGetData();
  }, [selectedStatusId, searchDateFrom, searchDateTo, search, page]);

  return (
    <ContainerPage id="back-to-top-anchor">
      <TitlePage>Conferência de recebimento</TitlePage>

      <Box className={classes.boxAlign}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsSearch color="#757575" />
              </InputAdornment>
            )
          }}
          onChange={debaunceSearch}
          placeholder="Pesquisar"
          variant="outlined"
          className={classes.input}
        />

        <KeyboardDatePicker
          disableToolbar
          format="dd/MM/yyyy"
          margin="normal"
          value={searchDateFrom}
          onChange={handleChangeDate}
          className={classes.dateField}
        />

        <Box style={{ gap: "1rem" }} className={classes.boxAlign}>
          <Typography className={classes.filterText}>Filtrar:</Typography>

          <ChipStatus
            label={statusName || "Todos"}
            statusId={selectedStatusId}
            style={{
              margin: "1rem 0 1.2rem 0",
              fontSize: "0.9rem",
              minWidth: "4rem"
            }}
          />

          <IconButton onClick={togglePopover} ref={userRef}>
            <BsChevronDown />
          </IconButton>

          <MenuPopover
            open={popover}
            onClose={togglePopover}
            anchorEl={userRef.current}
            style={{ marginTop: "1rem" }}
          >
            <Box
              value={null}
              className={classes.boxItemPopover}
              onClick={handleSelectSatus}
            >
              <ListItemText className={classes.listItemText}>
                Todos
              </ListItemText>
            </Box>

            {conferenceStatus?.map(status => (
              <Box
                className={classes.boxItemPopover}
                key={status.id}
                value={status.id}
                onClick={handleSelectSatus}
              >
                <ListItemText
                  className={classes.listItemText}
                  style={
                    status.id === 1
                      ? { color: "#C98400" }
                      : { color: "#0A892E" }
                  }
                >
                  {status.name}
                </ListItemText>
              </Box>
            ))}
          </MenuPopover>
        </Box>
      </Box>

      <ReceiptConferenceTable
        columns={columns}
        childrenColumns={childrenColumns}
        data={formattedBatchConferenceData}
        setConferenceData={handleOpenModal}
      />

      <BoxPaginateContent>
        {totalPages > 1 && (
          <Paginate
            breakLabel="..."
            nextLabel={<AiOutlineRight />}
            forcePage={page - 1}
            onPageChange={e => handleChangePage(e.selected + 1)}
            pageRangeDisplayed={3}
            pageCount={totalPages}
            previousLabel={<AiOutlineLeft />}
            renderOnZeroPageCount={null}
          />
        )}
      </BoxPaginateContent>

      <ModalUpdate
        isOpen={isOpenModal}
        close={toggleModal}
        conferenceStatus={conferenceStatus}
        conferenceData={conferenceData}
        setPageToInitial={setPageToInitial}
      />
    </ContainerPage>
  );
};
