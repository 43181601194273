import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ContainerPage, TitlePage } from "components";
import PageLoader from "components/PageLoad";
import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import batchService from "services/batchService";

import { HistoryTimeLine } from "../components/HistoryTimeLine";
import { HistoryContent, SubBatchIdContent, TitleDetail } from "../style";
import { formatterData } from "../utils/formatterData";
import { Detail } from "./Detail";
import { Movement } from "./Movement";

export const ProductSubBatchDetail = () => {
  const movementRef = useRef(null);
  const { subBatchId } = useParams();
  const history = useHistory();
  const [subBatchData, setSubBatchData] = useState({});
  const [loading, setLoading] = useState(false);

  const getInitData = async () => {
    try {
      setLoading(true);
      const { data } = await batchService.getSubBatchDetail(subBatchId);
      setSubBatchData(formatterData.subBatchDetail(data));
    } catch (error) {
      history.push("/app/404");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitData();
  }, [subBatchId]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <ContainerPage>
        <TitlePage>
          Rastreio de Sublote <NavigateNextIcon />
          <SubBatchIdContent>{subBatchData.id}</SubBatchIdContent>
        </TitlePage>
        <Detail
          subBatchData={subBatchData}
          handleOpenMovement={() => movementRef.current?.handleOpen()}
        />
        <HistoryContent>
          <TitleDetail>Histórico de movimentações</TitleDetail>
          <HistoryTimeLine historical={subBatchData.historical} />
        </HistoryContent>
      </ContainerPage>
      <Movement
        ref={movementRef}
        subbatch={subBatchData}
        refresh={getInitData}
      />
    </>
  );
};
