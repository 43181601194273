import { mapPurchaseType } from "utils";

const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return { date, time };
};

export const batchFormatter = (data, dataLedger) => {
  return (data || []).map(batch => {
    const dataLedgerByBatchId = dataLedger
      ?.filter(dataLedger => dataLedger.batchId === batch.id)
      .sort((a, b) => a.id - b.id);

    return {
      ...batch,
      id: batch.id,
      createdDate: formatDateTime(batch?.created).date,
      createdTime: formatDateTime(batch?.created).time,
      seller: batch.seller?.name,
      productBaseName: batch.product?.name,
      productBaseId: batch.product?.id,
      stage: batch.stage?.name,
      status: batch.status?.name,
      receiptDate: formatDateTime(batch.receipt_date).date,
      receiptTime: formatDateTime(batch.receipt_date).time,
      quantity: batch.quantity,
      availableQuantity: batch.available_quantity,
      blockQuantity: batch.block_quantity,
      purchaseType: mapPurchaseType(batch.product?.purchase_type),
      depot: dataLedgerByBatchId ? dataLedgerByBatchId[0]?.depot.name : "",
      warehouse: dataLedgerByBatchId
        ? dataLedgerByBatchId[0]?.warehouseType.name
        : ""
    };
  });
};
