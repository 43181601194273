import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { CustomLineProgress } from "components/LineProgress";

import { BodyCell, BodyRow, HeadCell, TableContent } from "../../style";

export const CategoriesTable = ({
  categoriesData,
  loading,
  handleOpenUpdate
}) => {
  return (
    <Box>
      {!!loading && <CustomLineProgress />}
      <TableContent component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <HeadCell>ID</HeadCell>
            <HeadCell>Categoria</HeadCell>
            <HeadCell>Sigla</HeadCell>
            <HeadCell></HeadCell>
          </TableHead>
          <TableBody>
            {categoriesData?.map(category => (
              <BodyRow key={category.id}>
                <BodyCell>{category.id}</BodyCell>
                <BodyCell>{category.name}</BodyCell>
                <BodyCell>{category.abbreviation?.toUpperCase()}</BodyCell>
                <BodyCell>
                  <IconButton onClick={() => handleOpenUpdate(category.id)}>
                    <EditIcon />
                  </IconButton>
                </BodyCell>
              </BodyRow>
            ))}
          </TableBody>
        </Table>
      </TableContent>
    </Box>
  );
};
