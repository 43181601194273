import {
  Box,
  IconButton,
  Drawer as DrawerMui,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

const Drawer = ({ children, title, ...rest }) => {
  const classes = useStyles(rest);

  return (
    <DrawerMui classes={{ paper: classes.paper }} {...rest}>
      <Box component="header" className={classes.header}>
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <IconButton className={classes.closeBtn} onClick={rest.onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent classes={{ root: classes.dialogContent }}>
        {children}
      </DialogContent>
    </DrawerMui>
  );
};

Drawer.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  anchor: PropTypes.oneOf(["right", "left"]).isRequired
};

export default Drawer;
