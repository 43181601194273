import { Box, CircularProgress } from "@material-ui/core";
import { useFormik } from "formik";
import { useMemo, useRef, useImperativeHandle } from "react";
import { Drawer } from "shared";

import { validationSchema } from "../../validationSchema";
import { OrderForm } from "../OrderForm";
import { OrderItemForm } from "../OrderItemForm";
import { PackagingForm } from "../packagingForm";

const formatInitialValues = data => {
  if (!data) return null;

  const scheduledTimeFrom = data.scheduledTimeFrom
    ? new Date(`2021-01-01T${data.scheduledTimeFrom}`)
    : null;
  const scheduledTimeTo = data.scheduledTimeTo
    ? new Date(`2021-01-01T${data.scheduledTimeTo}`)
    : null;

  return {
    ...data,
    address: data?.address?.id,
    scheduledDate: new Date(`${data.scheduledDate}T00:00:00`),
    scheduledTimeFrom,
    scheduledTimeTo,
    hasEmptyBoxes: data.hasEmptyBoxes ? "yes" : "no",
    hasReturnPending: data.hasReturnPending ? "yes" : "no",
    isCritical: data.isCritical ? "yes" : "no",
    finalizedByMenu: false
  };
};

export const Edit = ({
  isOpen,
  data,
  openConfirmationPopup,
  onSubmit,
  isLoading,
  removedItems,
  removedPackaging,
  handleClose,
  step,
  setStep
}) => {
  const formRef = useRef(null);

  useImperativeHandle(formRef, () => ({ resetForm: formik.resetForm }));

  const initialValues = useMemo(() => formatInitialValues(data), [data]);

  const formik = useFormik({
    initialValues: initialValues || {},
    validationSchema,
    onSubmit: onSubmit,
    validateOnChange: true,
    enableReinitialize: true
  });

  return (
    <Drawer
      anchor="right"
      title="Editar Pedido"
      open={isOpen}
      onClose={handleClose}
    >
      {isLoading ? (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <CircularProgress />
        </Box>
      ) : step === 1 ? (
        <OrderForm
          ref={formRef}
          formik={formik}
          setStep={setStep}
          onClose={handleClose}
        />
      ) : step === 2 ? (
        <PackagingForm
          ref={formRef}
          formik={formik}
          setStep={setStep}
          removedPackaging={removedPackaging}
          onClose={handleClose}
        />
      ) : (
        <OrderItemForm
          ref={formRef}
          formik={formik}
          setStep={setStep}
          onClose={handleClose}
          removedItems={removedItems}
          openConfirmationPopup={openConfirmationPopup}
        />
      )}
    </Drawer>
  );
};
