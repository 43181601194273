import { Box, CircularProgress } from "@material-ui/core";
import { useFormik } from "formik";
import { useImperativeHandle } from "react";
import { Drawer } from "shared";

import { validationSchema } from "../../validationSchema";
import { OrderForm } from "../OrderForm";
import { OrderItemForm } from "../OrderItemForm";
import { PackagingForm } from "../packagingForm";

export const Create = ({
  isOpen,
  openConfirmationPopup,
  refresh,
  onSubmit,
  isLoading,
  handleClose,
  formRef,
  step,
  setStep
}) => {
  useImperativeHandle(formRef, () => ({ resetForm: formik.resetForm }));

  const initialProduct = () => ({
    productBase: null,
    description: "",
    quantity: 0,
    uniqueKey: Math.random().toString(36)
  });

  const initialPackaging = () => ({
    packaging: null,
    packagingId: null,
    quantity: 0,
    packagingName: "",
    uniqueKey: Math.random().toString(36)
  });

  const initialValues = {
    isCritical: "no",
    hasEmptyBoxes: "no",
    hasReturnPending: "no",
    scheduledDate: new Date(Date.now() + 86400000),
    sellerOrderItems: [initialProduct()],
    sellerOrderPackaging: [initialPackaging()],
    finalizedByMenu: false
  };

  const formik = useFormik({
    initialValues: initialValues || {},
    validationSchema,
    onSubmit: onSubmit,
    validateOnChange: true
  });

  return (
    <Drawer
      anchor="right"
      title="Novo Pedido"
      open={isOpen}
      onClose={handleClose}
    >
      {isLoading ? (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <CircularProgress />
        </Box>
      ) : step === 1 ? (
        <OrderForm
          ref={formRef}
          formik={formik}
          setStep={setStep}
          onClose={handleClose}
        />
      ) : step === 2 ? (
        <PackagingForm
          ref={formRef}
          formik={formik}
          setStep={setStep}
          onClose={handleClose}
        />
      ) : (
        <OrderItemForm
          ref={formRef}
          formik={formik}
          setStep={setStep}
          onClose={handleClose}
          refresh={refresh}
          openConfirmationPopup={openConfirmationPopup}
        />
      )}
    </Drawer>
  );
};
