import { Box, TextField } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import batchServiceV3 from "services/batchServiceV3";
import blitzService from "services/blitzService";

import {
  ButtonsContent,
  FieldContainer,
  FieldContent,
  RuleContent,
  RuleInformation,
  RuleQty,
  StyledBox,
  Text,
  TextInfo,
  TextInfoAlert
} from "../../style";

export const InitialPage = ({
  boxSampleQty,
  packaging,
  observation,
  typeOfMeasure,
  sampleQualityQty,
  handleChangeSampleQualityQty,
  sampleLowQualityQty,
  handleChangeSampleLowQualityQty,
  setLoading,
  batchId,
  setActivePage,
  handleNext,
  setIsApproved,
  hasNoDevolution
}) => {
  const { addPopup } = usePopup();

  const validateBlitz = async () => {
    try {
      setLoading(true);

      const {
        data: { result }
      } = await blitzService.validateBlitz({
        sample: sampleQualityQty,
        rejected: sampleLowQualityQty,
        batchId
      });
      const isRejected = result === "rejected";
      const showResultPage = isRejected || hasNoDevolution;
      setIsApproved(!isRejected);

      const formData = {
        sample_quantity: sampleQualityQty,
        sample_low_quantity: sampleLowQualityQty
      };

      const action = {
        true: async () => {
          await batchServiceV3.batchReceipt(batchId, {
            ...formData,
            blitz_step: 1,
            status: 4
          });
        },
        false: () =>
          batchServiceV3.batchReceipt(batchId, { ...formData, blitz_step: 2 })
      }[isRejected];

      await action();

      showResultPage ? setActivePage(1) : handleNext();

      setLoading(false);
    } catch (error) {
      addPopup({
        type: "error",
        title: "Erro ao validar blitz",
        description:
          "Não foi possível validar a blitz, tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    const isWeight = typeOfMeasure === "Kg";
    const isValidSample =
      sampleQualityQty &&
      !isNaN(
        isWeight ? parseFloat(sampleQualityQty) : parseInt(sampleQualityQty)
      );
    const isValidLowSample = !isNaN(
      isWeight ? parseFloat(sampleLowQualityQty) : parseInt(sampleLowQualityQty)
    );

    if (!isValidSample || !isValidLowSample) {
      return addPopup({
        type: "error",
        title: "Informações obrigatórias",
        description: "Insira uma quantidade válida"
      });
    }
    if (sampleLowQualityQty > sampleQualityQty) {
      return addPopup({
        type: "error",
        title: "Valor inaceitável",
        description:
          "A quantidade ruim não pode ser maior que quantidade da amostra"
      });
    }
    validateBlitz();
  };

  return (
    <>
      <StyledBox>
        <RuleContent>
          <RuleQty>
            Amostra mínima:{" "}
            <strong>
              {boxSampleQty} {packaging}
            </strong>
          </RuleQty>
          <RuleInformation>
            <p>{observation}</p>
          </RuleInformation>
        </RuleContent>
        <Text>Insira a amostra e quantidade abaixo da qualidade</Text>
        <FieldContainer>
          <FieldContent>
            <StyledBox style={{ gap: 0 }}>
              <TextInfo>Qtd. da amostra ({typeOfMeasure})</TextInfo>
              <TextField
                variant="outlined"
                type="number"
                inputProps={{
                  type: "number",
                  min: "0",
                  step: "1"
                }}
                label={typeOfMeasure}
                value={sampleQualityQty}
                fullWidth
                onChange={handleChangeSampleQualityQty}
              />
            </StyledBox>
            <StyledBox style={{ gap: 0 }}>
              <TextInfoAlert>Qtd. abaixo da qualidade</TextInfoAlert>
              <TextField
                variant="outlined"
                type="number"
                inputProps={{
                  type: "number",
                  min: "0",
                  step: "1"
                }}
                label={typeOfMeasure}
                value={sampleLowQualityQty}
                onChange={handleChangeSampleLowQualityQty}
                fullWidth
              />
            </StyledBox>
          </FieldContent>
        </FieldContainer>
      </StyledBox>
      <ButtonsContent>
        <Box>
          <Button
            style={{ width: "50%", marginLeft: "auto" }}
            variant="contained"
            color="primary"
            onClick={validate}
            endIcon={<NavigateNextIcon />}
          >
            Avançar
          </Button>
        </Box>
      </ButtonsContent>
    </>
  );
};
