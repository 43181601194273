import {
  Grid,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormLabel
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { forwardRef, useImperativeHandle } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { SellerOrderStatus } from "services/sellerOrderService";
import {
  AutoCompleteSellerFormik,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "shared";

const FormStepOne = ({ setStep, onClose, formik }, ref) => {
  const { addPopup } = usePopup();

  useImperativeHandle(ref, () => ({ resetForm: formik.resetForm }));

  const handleSelectSeller = value => {
    if (!value) {
      formik.setFieldValue("seller", null);
      formik.setFieldValue("address", null);
      return;
    }

    if (value?.addresses?.length > 0) {
      value?.addresses?.length === 1 &&
        formik.setFieldValue("address", value.addresses[0].id);

      return;
    }

    onClose();

    addPopup({
      type: "error",
      title: "O fornecedor selecionado não possui um endereço cadastrado.",
      description:
        "Cadastre um endereço em fornecedores > endereços para poder criar um pedido."
    });
  };

  const isFinalizedOrder =
    formik?.values?.status?.id &&
    formik.values.status.id !== SellerOrderStatus.PENDING;

  return (
    <Box component="form" noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AutoCompleteSellerFormik
            name="seller"
            formik={formik}
            label={"Fornecedor *"}
            onChange={handleSelectSeller}
            error={formik.touched.seller && !!formik.errors.seller}
            disabled={isFinalizedOrder}
          />
          <FormHelperText>
            {formik.touched.seller && formik.errors.seller}
          </FormHelperText>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="address-label">Endereço *</InputLabel>
            <Select
              labelId="address-label"
              name="address"
              value={formik.values.address || ""}
              onChange={formik.handleChange}
              label="Endereço"
              disabled={!formik.values.seller || isFinalizedOrder}
            >
              {formik.values.seller?.addresses?.map(address => (
                <MenuItem key={address.id} value={address.id}>
                  {address.street}, {address.number} - {address.city}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {formik.touched.address && formik.errors.address}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <KeyboardTimePicker
            keyboardIcon={<AiOutlineClockCircle />}
            ampm={false}
            label="Horário Ideal *"
            views={["hours", "minutes"]}
            mask="__:__"
            fullWidth
            value={formik.values.scheduledTimeFrom || null}
            onChange={value => {
              formik.setFieldValue("scheduledTimeFrom", value);
            }}
            disabled={isFinalizedOrder}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <KeyboardTimePicker
            keyboardIcon={<AiOutlineClockCircle />}
            label="Horário Limite *"
            views={["hours", "minutes"]}
            mask="__:__"
            fullWidth
            value={formik.values.scheduledTimeTo || null}
            onChange={value => {
              formik.setFieldValue("scheduledTimeTo", value);
            }}
            disabled={isFinalizedOrder}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <KeyboardDatePicker
            label="Data de Agendamento *"
            fullWidth
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            inputVariant="outlined"
            isFromTomorrow
            views={["date"]}
            value={formik.values.scheduledDate}
            onChange={value => formik.setFieldValue("scheduledDate", value)}
            onBlur={formik.handleBlur}
            error={
              formik.touched.scheduledDate && !!formik.errors.scheduledDate
            }
            helperText={
              formik.touched.scheduledDate && formik.errors.scheduledDate
            }
            disabled={isFinalizedOrder}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Tem devolução para retornar? *
            </FormLabel>
            <RadioGroup
              name="hasReturnPending"
              value={formik.values.hasReturnPending}
              onChange={event => {
                formik.setFieldValue("hasReturnPending", event.target.value);
              }}
              row
              disabled={isFinalizedOrder}
            >
              <Box mr={3}>
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Sim"
                  disabled={isFinalizedOrder}
                />
              </Box>
              <Box ml={3}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="Não"
                  disabled={isFinalizedOrder}
                />
              </Box>
            </RadioGroup>
            <FormHelperText>
              {formik.touched.hasReturnPending &&
                formik.errors.hasReturnPending}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Produto crítico de entrega? *
            </FormLabel>
            <RadioGroup
              row
              name="isCritical"
              value={formik.values.isCritical}
              disabled={isFinalizedOrder}
              onChange={event => {
                formik.setFieldValue("isCritical", event.target.value);
              }}
            >
              <Box mr={3}>
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Sim"
                  disabled={isFinalizedOrder}
                />
              </Box>
              <Box ml={3}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="Não"
                  disabled={isFinalizedOrder}
                />
              </Box>
            </RadioGroup>
            <FormHelperText>
              {formik.touched.isCritical && formik.errors.isCritical}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Precisa levar caixa vazia? *
            </FormLabel>
            <RadioGroup
              name="hasEmptyBoxes"
              value={formik.values.hasEmptyBoxes}
              onChange={event => {
                formik.setFieldValue("hasEmptyBoxes", event.target.value);
              }}
              row
            >
              <Box mr={3}>
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Sim"
                  disabled={isFinalizedOrder}
                />
              </Box>
              <Box ml={3}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="Não"
                  disabled={isFinalizedOrder}
                />
              </Box>
            </RadioGroup>
            <FormHelperText>
              {formik.touched.hasEmptyBoxes && formik.errors.hasEmptyBoxes}
            </FormHelperText>
          </FormControl>
        </Grid>

        {formik.values.hasEmptyBoxes === "yes" && (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="emptyBoxesCount"
              label="Quantidade de Caixas Vazias *"
              type="number"
              value={formik.values.emptyBoxesCount || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isFinalizedOrder}
              error={
                formik.touched.emptyBoxesCount &&
                !!formik.errors.emptyBoxesCount
              }
              helperText={
                formik.touched.emptyBoxesCount && formik.errors.emptyBoxesCount
              }
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item container justifyContent="flex-end">
          <Button
            isLoading={formik.isSubmitting}
            variant="contained"
            style={{ width: "10rem", marginTop: "4rem" }}
            disabled={!formik.isValid}
            onClick={() => setStep(2)}
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export const OrderForm = forwardRef(FormStepOne);
