import {
  alpha,
  AppBar,
  Toolbar,
  styled,
  Button,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { Logo } from "components";
import { CgChevronDoubleRight } from "react-icons/cg";
import { RiMenu2Fill } from "react-icons/ri";

import configLayout from "./ConfigLayout";
import { UserNav } from "./UserNav";

const { APPBAR_DESKTOP, APPBAR_MOBILE } = configLayout;

const ButtonDesktop = styled(Button)(({ theme, open }) => ({
  width: "75px",
  background: "#FAFAFA",
  height: APPBAR_MOBILE,
  [theme.breakpoints.up("sm")]: {
    height: APPBAR_DESKTOP
  },
  "& svg": {
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}));

const ButtomMobile = styled(Button)({
  background: "#FAFAFA",
  marginLeft: "0.7rem",
  borderRadius: "4px",
  padding: "0.3rem 0.4rem",
  minWidth: "initial",
  border: "1px solid #C4C4C4"
});

const RootStyle = styled(AppBar)({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha("#ffffff", 0.72),
  width: "100vw",
  margin: 0
});

const ToolBarStyle = styled(Toolbar)(({ theme }) => ({
  height: APPBAR_MOBILE,
  padding: "0",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    height: APPBAR_DESKTOP,
    paddingRight: theme.spacing(5)
  }
}));

const CgChevronDoubleRightStyle = styled(CgChevronDoubleRight)(({ theme }) => ({
  "& path:first-of-type": {
    color: theme.palette.secondary.main
  },
  "& path:last-of-type": {
    color: theme.palette.primary.main
  }
}));

export const Navbar = ({ onSidebar, isOpenSidebar }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <RootStyle position="fixed">
      <ToolBarStyle>
        {isDesktop ? (
          <ButtonDesktop open={isOpenSidebar && isDesktop} onClick={onSidebar}>
            <CgChevronDoubleRightStyle size={30} />
          </ButtonDesktop>
        ) : (
          <ButtomMobile onClick={onSidebar}>
            <RiMenu2Fill color={theme.palette.secondary.main} size={35} />
          </ButtomMobile>
        )}

        {isDesktop && <Logo style={{ marginLeft: "4rem" }} />}

        <UserNav style={{ marginLeft: "auto" }} />
      </ToolBarStyle>
    </RootStyle>
  );
};
