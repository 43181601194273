import { forwardRef, useImperativeHandle, useState } from "react";
import { Drawer } from "shared";

import { Form } from "./Form";

const Component = ({ groups, refresh }, ref) => {
  const [{ open, action }, setDrawerData] = useState({
    open: false,
    action: ""
  });

  const handleClose = () => setDrawerData({ open: false, action: "" });

  const handleOpen = action => setDrawerData({ open: true, action });

  useImperativeHandle(ref, () => ({ handleOpen }));

  return (
    <Drawer
      title={action === "create" ? "Criar grupo" : "Editar grupo"}
      onClose={handleClose}
      open={open}
      anchor="right"
    >
      <Form
        refresh={refresh}
        typePopup={action}
        groupData={groups}
        action={action}
        handleClose={handleClose}
      />
    </Drawer>
  );
};

export const DrawerGroupForm = forwardRef(Component);
